import React from "react";
import styles from "./bullets.module.scss";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";

const Bullets = ({ className, items, select = () => null, active = 0 }) => {
  return (
    <div className={classnames("bullets", styles.container, className)}>
      <div className={styles.bullets}>
        {items.map((bullet, index) => {
          return (
            <div
              key={uuidv4()}
              className={classnames(styles.bullet, active === index ? styles.active : null)}
              onClick={() => select(index)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Bullets;
