import React, { useContext, useCallback } from "react";
import localStrings from "localization";
import { getFullName } from "utils";
import { UserContext } from "contexts";
import { onboardUser } from "api/user";
import { uploadImage } from "api/storage";
import { getUserRequestParam } from "components/users/user-form/user-request-form";
import { useApi } from "hooks";
import { UploadProfilePhoto } from "components/onboarding";
import { Toast } from "atomic/molecules";

const UploadProfilePhotoContainer = () => {
  const { user, refetchUser } = useContext(UserContext);
  const { firstName, lastName, avatar } = user;

  const { request: uploadImageRequest, loading: uploadingImage } = useApi({
    api: uploadImage,
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
  });

  const { request: onboardUserRequest, loading: onboardingUser } = useApi({ api: onboardUser });

  const beforeUpload = useCallback((file) => {
    const isJpgOrPng = ["image/jpeg", "image/png", "image/gif"].includes(file.type);
    const isLt1M = file.size / 1024;
    const limit = isLt1M >= 4096;
    if (!isJpgOrPng || limit) {
      Toast({
        content: !isJpgOrPng
          ? localStrings.youCanOnlyUploadJpgPngGifFile
          : localStrings.unbaleToUploadMoreThanMb,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
    return isJpgOrPng && !limit;
  }, []);

  const handleChangeAvatar = useCallback(
    async (data, name) => {
      const { status, response } = data.file;

      if (status === "done") {
        const img = response.distributionUrl;

        await onboardUserRequest({
          ...getUserRequestParam({
            avatar: img,
          }),
          isProfilePhoto: true,
        });
        Toast({
          content: localStrings.profileAvatarSaved,
          success: true,
        }).open();

        refetchUser();
      }
    },
    [onboardUserRequest, refetchUser]
  );

  const handleUploadAvatar = useCallback(
    async (res) => {
      const { file, onSuccess, onError } = res;

      try {
        const result = await uploadImageRequest({ file });
        onSuccess(result);
      } catch (error) {
        onError(error);
      }
    },
    [uploadImageRequest]
  );

  return (
    <UploadProfilePhoto
      fullName={getFullName({ firstName, lastName })}
      avatar={avatar}
      beforeUpload={beforeUpload}
      handleChangeAvatar={handleChangeAvatar}
      handleUploadAvatar={handleUploadAvatar}
      uploadingImage={uploadingImage}
      onboardingUser={onboardingUser}
    />
  );
};

export default UploadProfilePhotoContainer;
