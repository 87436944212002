const locale = {
  about: "",
  aboutYou: "",
  aboutYouDesc: "",
  acceptInvite: "",
  accessory: "",
  accessoryAlreadyPaired: "Aksesori telah terpasangkan",
  accessoryDetails: "Detail Aksesori",
  accessoryId: "ID aksesori",
  accessoryIdAlreadyPaired: "",
  accessoryIdDoesNotBelongToOrganization: "",
  accessoryIdIsntAvailable: "",
  accessoryOrMacAddress: "",
  accessoryRemoved: "",
  accessoryStatus: "",
  accessoryType: "Type aksesori",
  account: "",
  accountInformation: "",
  accountSettings: "",
  active: "Aktif",
  activeAndSyncing: "Aktif dan Sedang Sync",
  activeMembers: "",
  activeOraganization: "",
  add: "Tambah",
  addAMember: "",
  addANewMember: "",
  addANewUser: "Tambah pengguna baru",
  addAccessory: "Tambahkan aksesori",
  addAndManagerPouchBands: "",
  addMember: "",
  addMemberAndProfile: "",
  addMembers: "",
  addNew: "",
  addNewMembers: "",
  addPouchband: "",
  addUsers: "tambah pengguna",
  addYourContactDetails: "",
  addYourPouchBand: "",
  additionalInfo: "",
  address: "",
  all: "Semua",
  allSet: "",
  allTheInfoCollectedProfile: "",
  allowed: "",
  almostDoneEnterYourNewPassword: "",
  alreadyHaveAnAccount: "",
  alreadyInvitedAssignAsManager: "",
  alreadyInvitedPendingError: "",
  and: "",
  appStore: "",
  applyFilters: "Terapkan filter",
  areYouSure: "",
  area: "Area",
  areaDescription: "",
  areaDuplicateName: "Nama area ini sudah terdata.",
  areaLocation: "Lokasi / Area",
  areaLocationDesc: "",
  areaName: "",
  areas: "Area",
  asThePersonDescribed: "",
  askDeleteArea: "",
  askDeleteMember: "",
  askDeleteUser: "hapus data pengguna?",
  askRemoveAccessory: "",
  askRemoveManager: "",
  askRemoveMember: "",
  askSaveChanges: "",
  askTheUserToWearPouchBand: "",
  askYouToWearPouchBand: "",
  assignAPouchBandToEachUser: "",
  assignAPouchBandToStartMonitorTheirTemp: "",
  assignAsManager: "",
  authorization: "",
  back: "",
  backTo: "",
  backToHome: "",
  backToLogin: "",
  basicInfo: "",
  birthdate: "",
  birthday: "",
  bloodType: "",
  bodyAche: "",
  bodyTemperature: "",
  bodyTemperatureDays: "",
  browsingYourFiles: "",
  cancel: "Batal",
  celciusSymbol: "",
  celsius: "Celcius",
  change: "",
  changeAccessory: "Rubah Aksesori",
  changePhoto: "",
  changeYourEmail: "",
  changesSaved: "",
  checkCredentials: "Periksa pengenalan",
  checkEmploymentDetails: "",
  checkWithYourAdminForDetails: "",
  checkingInternet: "Memeriksa jaringan internet",
  chestPains: "",
  chooseBloodType: "",
  chooseGender: "",
  chooseTypeTest: "",
  chooseWhichInformationToShare: "",
  clear: "Bersihkan",
  clearAllFilters: "",
  cm: "",
  company: "",
  companyPlaceholder: "",
  completeProfile: "",
  confirmDeleteMember: "",
  confirmNewPassword: "",
  confirmPassword: "",
  confirmRemoveManager: "",
  confirmRemoveMember: "",
  confirmRevokeManagerInvite: "",
  conjunctivitis: "",
  connectAPouchBandDesc: "",
  contactAndPersonalInfo: "",
  contactHistory: "",
  contactHistoryDesc: "",
  contactInformation: "",
  contactNumber: "",
  contactPersonsName: "",
  contactPouchPass: "",
  contactRelationship: "",
  contactUs: "",
  cough: "",
  countryCurrentlyResidingIn: "",
  createAccount: "",
  createAndManageUser: "",
  createArea: "",
  createMember: "",
  createPPaccount: "",
  createUser: "",
  createWorryFree: "",
  createYourFirstArea: "",
  createYourFirstAreaDesc: "",
  currentPassword: "",
  currentResidentialAddress: "",
  date: "Tanggal",
  dateOfAvailabilityOfTestResults: "",
  dateOfContact: "",
  dateOfMedicalTest: "",
  decline: "",
  declineInvitation: "",
  declineInvitationDesc: "",
  delete: "",
  deleteArea: "",
  deleteLog: "",
  deleteMember: "",
  deleteSymptomsLog: "",
  deleteUser: "hapus data pengguna",
  deletedMemeber: "",
  description: "",
  details: "",
  deviceStatus: "Status perangkat",
  diarrhea: "",
  diary: "",
  didntGetACode: "",
  didntReceiveAnything: "",
  difficultyBreathing: "",
  discardChanges: "",
  discardChangesMessage: "",
  discardTitle: "",
  dismiss: "",
  doThisLater: "",
  doYouWantToCreateYourOwnOrg: "",
  dontHavePouchBandYet: "",
  duration: "",
  easilyIdentifyYourProfile: "",
  edit: "",
  editArea: "Edit area",
  editLogSymptoms: "",
  editManager: "",
  editMember: "",
  editProfile: "",
  editSettings: "",
  editUser: "",
  egMedicalClinic: "",
  emailAddress: "",
  emailAddressAlreadyTaken: "",
  emailAddressAndMobileNumber: "",
  emailAddresses: "",
  emailAlreadyAssociatedWithThisOrganization: "",
  emailAlreadyExists: "",
  emergencyContact: "",
  emergencyContactDesc: "",
  emergencyContactPerson: "",
  employed: "",
  employeeDetails: "",
  employmentDetails: "",
  employmentDetailsDesc: "",
  employmentStatus: "",
  enterAnEmailAddress: "",
  enterNewPassword: "",
  enterOtpVerifyYourEmailAddress: "",
  enterPassword: "",
  everyoneIsDoingWell: "",
  everyoneIsDoingWellDesc: "",
  everyoneIsSafe: "",
  everyoneIsSafeDesc: "",
  export: "",
  extremelyHigh: "",
  fAQsPage: "",
  fahrenheit: "Fahrenheit",
  failedToValidateEmail: "",
  fever: "Demam",
  feverCases: "Kasus Demam",
  feverTrend: "",
  feverTrendOvertime: "Kecenderungan demam selama waktu",
  fillInEmailAddresses: "",
  findOutHowCreateOrg: "",
  findOutHowNow: "",
  findOutMore: "",
  fingerToesDiscoloration: "",
  finishSettingUpAccount: "",
  firstName: "",
  forFurtherQuestions: "",
  forgotPassword: "",
  fullName: "",
  gateway: "",
  gatewayId: "",
  gatewayIdOrMacAddress: "",
  gatewayMacAddress: "",
  gatewayName: "",
  gatewayNamePlaceholder: "",
  gender: "",
  geolocation: "",
  geolocationDesc: "",
  goodJobAllUserActive: "",
  goodJobAllUserActiveDesc: "",
  great: "",
  hasNotBeenInContact: "",
  hasTheUserTakenAnyMedicalTest: "",
  headache: "",
  height: "",
  heightInCm: "",
  hereWhatHappening: "Yang terjadi dengan",
  highRisk: "",
  highRiskDesc: "",
  highTemperature: "",
  higherThanNormal: "",
  hypothermia: "Hipotermia",
  iAgreeToPouchTerms: "",
  iAuthorizeOrg: "",
  ifAny: "",
  iformationIsImportantNarrative: "",
  imageMustBeSmallerThan: "",
  in: "",
  inactive: "Tidak aktif",
  inactiveDesc: "Pengguna belum mendata temperatur selama 6 jam terakhir",
  individualAtRiskDesc: "",
  individualsAtRisk: "",
  inputAccessoryId: "",
  invalidCurrentPassword: "",
  inviteExpiredDesc: "",
  inviteHasBeenSentTo: "",
  inviteManager: "",
  inviteManagerDescription: "",
  inviteMembers: "",
  inviteNewManager: "",
  invitePeopleToCreate: "",
  invitePouchPassDesc: "",
  invitePouchPassNote: "",
  inviteRequest: "",
  inviteRequestDesc: "",
  inviteRequestDescManager: "",
  inviteRequestDescMember: "",
  inviteSent: "",
  inviteSentDetails: "",
  inviteSentMemberDesc: "",
  inviteTeamMembersAndCreateArea: "",
  inviteTeamMembersAndCreateAreaDescription: "",
  inviteToPouchPass: "",
  invitedMembersWouldHave: "",
  invitesSent: "",
  invitingAMember: "",
  joinedCommunities: "",
  joinedOn: "",
  keepThisOrg: "",
  kg: "",
  lastDateOfContact: "",
  lastName: "",
  lastSynced: "Terakhir Sync",
  lastUpdated: "Update terakhir",
  latestTemp: "Temperatur terakhir",
  learnMore: "",
  leave: "",
  leaveOTPMessage: "",
  leaveOrganization: "",
  leavePage: "",
  leavePageDesc: "",
  lessDetails: "",
  liveMap: "",
  location: "Lokasi",
  logIn: "",
  logOtherSymptoms: "",
  logSymptoms: "",
  logTheirSymptomsNow: "",
  login: "",
  loginNow: "",
  loginTo: "",
  logout: "",
  lossOfSmellOrTaste: "",
  lossOfSpeechOrMovement: "",
  low: "",
  lowRisk: "",
  lowRiskDesc: "",
  lowTemperature: "",
  manager: "",
  managerRequest: "",
  me: "",
  medicalTest: "",
  member: "",
  memberDetails: "",
  memberDetailsPendingMessage: "",
  memberFromUntil: "",
  memberLocationPrivate: "",
  memberProfileOnly: "",
  memberProfileOnlyDesc: "",
  memberRequest: "",
  memberSince: "",
  memberStatus: "",
  members: "",
  mobileNumber: "",
  moderateRisk: "",
  moderateRiskDesc: "",
  monitorAnySymptoms: "",
  monitorAnySymptomsDescription: "",
  monitorYourBodyTemp: "",
  monitorYourBodyTempDesc: "",
  myOrganizationDescription: "",
  myOrganizations: "",
  name: "Nama",
  nameAndProfilePhoto: "",
  nameOfClinicOrHospital: "",
  nameOfOtherTest: "",
  nameOfSpecificMedicalTest: "",
  nameRemoved: "",
  negative: "",
  networkErrorDesc: "Kesalahan pada jaringan",
  newPassword: "",
  newUserAdded: "",
  next: "",
  noAccessory: "",
  noAccessoryYet: "Belum ada aksesori",
  noContactDetected: "",
  noData: "TIdak ada data",
  noDevice: "Tidak ada perangkat",
  noLongerAManager: "",
  noMemberYet: "No members yet.",
  noMembersFound: "No members found.",
  noName: "TIdak ada nama",
  noNeedForApp: "",
  noRecordsYet: "",
  noResults: "",
  noSymptomsLogged: "",
  noUserFound: "TIdak ditemukan pengguna",
  noUserYet: "Belum ada pengguna",
  normal: "Normal",
  notAllowed: "",
  notAtRisk: "",
  notRequired: "",
  ohNoYouDontHaveAccess: "",
  okay: "",
  onceYouLeaveOrg: "",
  oopsIncorrectEmailOrPass: "Oops! alamat email atau password salah",
  oopsSomethinngWentWrong: "",
  optional: "",
  or: "",
  orCheck: "",
  orderOne: "",
  orgYouManage: "",
  organizationSettings: "",
  otherLocation: "",
  ourAvailablePlans: "",
  overview: "",
  pageNotFound: "",
  pageNotFoundDesc: "",
  paired: "Terpasang",
  pairingStatus: "Status Pemasangan",
  password: "",
  passwordReset: "",
  passwordsDoNotMatch: "",
  pastOrganizations: "",
  pastOrganizationsDesc: "",
  past13Days: "13 hari terakhir",
  pendingInvites: "",
  pendingInvitesDesc: "",
  pendingMemberConfirmation: "",
  permanentResidence: "",
  permissions: "",
  personalInformation: "",
  playStore: "",
  pleaseCheckForTheOneTimePasswordWeSentTo: "",
  pleaseCheckYourEmailAndClickMessage: "",
  pleaseContactOurSupport: "",
  pleaseContactPouchPass: "",
  pleaseEnterAnEmailInFormat: "",
  pleaseEnterAtLeastOneEmail: "",
  pleaseFillUpArea: "",
  pleaseFillUpSymptom: "",
  pleaseFillUpUser: "",
  pleaseInputCurrentPassword: "",
  pleaseRequestThePersonProfile: "",
  pleaseTryAgain: "",
  pleaseUseAtleastNthCharacters: "",
  poor: "",
  positive: "",
  pouchBand: "",
  pouchBandDescription: "",
  pouchBandForEveryone: "",
  pouchBandStep1: "",
  pouchBandStep2: "",
  pouchBands: "",
  pouchnationPhilippines: "",
  privacyPolicy: "",
  privacySymptomsWarning: "",
  private: "",
  privateInformation: "",
  privateOnlyMe: "",
  profile: "",
  profileAvatarSaved: "",
  profileOnly: "",
  profileSettings: "",
  rashes: "",
  recordAndMonitorYourSymptoms: "",
  recovingWithANormalTemp: "",
  refreshPage: "Perbarui halaman",
  registrationStart: "",
  relationshipPlaceholder: "",
  rememberMe: "",
  rememberYourPassword: "",
  rememberYourPasswordQuestion: "",
  remove: "Hapus",
  removeAccessory: "Hapus aksesori",
  removeAccessoryDesc: "",
  removeAsManager: "",
  removeFromOrganization: "",
  removeManagerSuccess: "",
  removeMember: "",
  removed: "",
  removedMember: "",
  replacePhoto: "",
  required: "",
  resendANewOtp: "",
  resendInvite: "",
  resendInviteSuccess: "",
  resendNow: "",
  resetLinkResent: "",
  resetLinkSent: "",
  resetPassword: "",
  resetTokenExpired: "",
  retry: "",
  retypePassword: "",
  reviewTheInformation: "",
  revoke: "",
  revokeInvite: "",
  revokeInviteSuccess: "",
  riskLevel: "",
  role: "",
  roleAndPermissions: "",
  sampleAreaDescription: "Contoh keterangan area",
  sampleAreaName: "Contoh nama area",
  save: "",
  saveAndSendNotif: "",
  saveChanges: "Simpan Perubahan",
  savePermissions: "",
  searchByUserOrLocation: "",
  searchMemberByNameOrAccessoryId: "",
  searchUserByNameOrAccessoryId: "Cari pengguna dengan nama atau ID aksesori",
  seconds: "",
  sections: "",
  seeMore: "",
  selectARole: "",
  selectBloodType: "",
  selectCountry: "",
  selectEmploymentStatus: "",
  selectFollowingSymptoms: "",
  selectGender: "",
  selectUserTestResult: "",
  selfEmployed: "",
  sendInvite: "",
  sendResetLink: "",
  settings: "",
  setupProfileLater: "",
  sharedInformation: "",
  shopNow: "",
  shopPouchBand: "",
  signIn: "",
  signingUpAggreement: "",
  someEmailsNotRecognized: "",
  somethingWentWrong: "",
  soreThroat: "",
  sorryYouAreNotAuthToAccessThisPage: "",
  startMonitorTeam: "Mulai memonitor team",
  startTour: "",
  startYourPouchBand: "",
  startbyUploading: "",
  statistics: "",
  stayOnThisPage: "",
  stayOnTopOfYourHealthAndGetNotified: "",
  stayOnTopOfYourHealthAndGetNotifiedDesc: "",
  stepNth: "",
  successChangedPassword: "",
  symptomLoggedToDiary: "",
  symptomNotFound: "",
  symptoms: "",
  symptomsDescription: "",
  takeNoteOfTheMedicalTestResultHere: "",
  teamAndRoles: "",
  teamManagers: "",
  tellUsMoreAboutYourself: "",
  temp: "",
  tempCases: "",
  tempHasBeenIncreasingForThePastDays: "",
  temperature: "Temperatur",
  temperatureCases: "",
  temperatureStatus: "",
  temperatureTrend: "",
  termsAndConditions: "",
  testResultSummaryInfo: "",
  testResults: "",
  testedNegativeWithCovid: "",
  testedPositiveWithCovid: "",
  theOTPYouEnteredIsInvalid: "",
  theyllBeAdded: "",
  thisEmailIsAlreadyAMemberOfYourOrg: "",
  thisMember: "",
  thisUserDoesNotExistOn: "",
  time: "",
  tiredness: "",
  to: "",
  toLearnAboutPouchPass: "",
  today: "Hari ini",
  totalMembers: "",
  trackEncounter: "",
  trackEncounterDescription: "",
  try: "Coba",
  trySearchingAgain: "",
  tryToSearchAgain: "Coba pencarian lagi",
  typeOfMedicalTest: "",
  uhOhEmailNotRegistered: "Email ini tidak terdaftar",
  unableToLocate: "",
  unableToLocateMember: "",
  unableToLocateUser: "",
  unableToRemoveAccessory: "",
  unableToRetrieveData: "",
  unableToRetrieveDataDesc: "",
  unauthorized: "Tidak ada otorisasi",
  unbaleToUploadMoreThanMb: "",
  unemployed: "",
  unfortunatelyThisInviteIsExpired: "",
  unknowUserContactInformation: "",
  unknowUserUserId: "",
  unverifiedMember: "",
  unverifiedMembersDesc: "",
  uploadAProfile: "",
  uploadCovidTestCerti: "",
  uploadFile: "",
  uploadPhoto: "",
  uploadPhotoDescription: "",
  uploadProfile: "",
  uploadTestCertificate: "",
  usePouchPassForYourFamilyOrBusiness: "",
  usePouchPassForYourFamilyOrBusinessDesc: "",
  userHasPendingInvite: "",
  userIsNowAManager: "",
  users: "Pengguna",
  usingAMixOfUppercaseAndLowercase: "",
  validateEmailAddress: "",
  verifiedMember: "",
  verifiedMembersDesc: "",
  verifyEmail: "",
  verifyEmailAddress: "",
  veryHigh: "",
  veryLowRisk: "",
  veryLowRiskDesc: "",
  view: "",
  viewAll: "Lihat Semua",
  viewAllAreas: "Lihat Semua Area",
  viewAndAddSymptoms: "",
  viewInvite: "",
  viewMyActiveOrg: "",
  viewMyOrgs: "",
  viewProfile: "",
  viewTestResults: "",
  viewYourContactInformation: "",
  viewYourNameAndProfilePhoto: "",
  visitOur: "",
  wantToCreateOrg: "",
  weShouldBack: "Silahkan kembali lagi, jika kesalahan berlanjut silahkan kontak server",
  weak: "",
  weight: "",
  weightInKg: "",
  welcomeDescription: "",
  welcomeTo: "",
  welcomeToPouchPass: "",
  wellEmbarassing: "Terdapat kesalahan pada sistem",
  wellEmbarassingDesc: "Kami sudah memahami dan sedang mempelajari permasalahan",
  whoCanView: "",
  withFever: "Demam",
  workAddress: "",
  workContactNumber: "",
  worker: "",
  wouldBeReceivingInfo: "",
  yesDecline: "",
  yesLeave: "",
  youAreNotAuthorized: "Anda tidak ada otorisasi",
  youAreNotInAnyOrg: "",
  youAreNotInAnyOrgDesc: "",
  youCanOnlyUploadJpgPngGifFile: "",
  youDontHaveOrg: "",
  youMay: "",
  youReInvitedTo: "",
  youReachMaximumOTPAttemps: "",
  yourEmailAddress: "",
  yourPasswordHasBeenChanged: "",
};
export default Object.freeze(locale);
