import React from "react";
import { Modal } from "atomic/atoms";
import { Avatar, Button } from "atomic/molecules";
import localStrings from "localization";
import styles from "./invite-request.module.scss";
import { AccountStatus } from "enums";

const ViewInviteRequestModal = ({
  visible,
  organizationName = "",
  role = "",
  organizationId = null,
  orgLogo,
  close,
  decline,
  accept,
}) => {
  return (
    <Modal className={styles.viewInviteModal} visible={visible} onCancel={close}>
      <Avatar image={orgLogo} className={styles.viewInviteAvatar} />
      <h2 className={styles.viewInviteTitle}>
        {localStrings.formatString(
          localStrings.youReInvitedTo,
          `${
            role === AccountStatus.MANAGER
              ? String(localStrings.manager).toLowerCase()
              : String(localStrings.member).toLowerCase()
          }`
        )}
      </h2>
      <h2 className={styles.viewInviteOrgName}>{organizationName}</h2>
      <p className={styles.viewInviteDesc}>
        {role === AccountStatus.MANAGER
          ? localStrings.formatString(localStrings.inviteRequestDescManager, organizationName)
          : localStrings.formatString(localStrings.inviteRequestDescMember, organizationName)}
      </p>
      <Button
        className={styles.acceptInvite}
        text={localStrings.acceptInvite}
        onClick={() => accept({ organizationId, organizationName })}
      />
      <Button
        tertiary
        text={localStrings.decline}
        onClick={() => decline({ organizationId, organizationName })}
      />
    </Modal>
  );
};

export default ViewInviteRequestModal;
