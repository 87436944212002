const DefaultQuestionFieldTypes = {
  EMAIL_ADDRESS: "EMAIL ADDRESS",
  MOBILE_NUMBER: "MOBILE NUMBER",
  FIRST_NAME: "FIRST NAME",
  LAST_NAME: "LAST NAME",
  BIRTHDATE: "BIRTHDATE",
  CURRENT_RESIDENTIAL_ADDRESS: "CURRENT RESIDENTIAL ADDRESS",
  GENDER: "GENDER",
  BLOOD_TYPE: "BLOOD TYPE",
  HEIGHT_IN_CM: "HEIGHT IN CM",
  WEIGHT_IN_KG: "WEIGHT IN KG",
  EMPLOYMENT_STATUS: "EMPLOYMENT STATUS",
  EMPLOYER_NAME_OR_COMPANY: "EMPLOYER NAME OR COMPANY",
  WORK_CONTACT_NUMBER: "WORK CONTACT NUMBER",
  WORK_ADDRESS: "WORK ADDRESS",
  EMERGENCY_CONTACT_PERSON_NAME: "EMERGENCY CONTACT PERSON NAME",
  RELATIONSHIP_WITH_CONTACT_PERSON: "RELATIONSHIP WITH CONTACT PERSON",
  CONTACT_PERSON_MOBILE_NUMBER: "CONTACT PERSON MOBILE NUMBER",
  BODY_TEMPERATURE: "BODY TEMPERATURE",
  SYMPTOMS: "SYMPTOMS",
  MEDICAL_TEST_RESULTS: "MEDICAL TEST RESULTS",
};

export default Object.freeze(DefaultQuestionFieldTypes);
