import { Field } from "enums";
import Validation from "services/validation.service";

const initialFormState = (initialState = {}) => {
  const { gatewayName = "", gatewayMacAddress = "" } = initialState;
  return {
    gatewayName: {
      value: gatewayName,
      type: Field.INPUT,
      maxLength: 75,
    },
    gatewayMacAddress: {
      value: gatewayMacAddress,
      type: Field.INPUT,
      validations: [Validation.required(), Validation.minlength(12)],
      required: true,
      maxLength: 14,
    },
  };
};

export default initialFormState;
