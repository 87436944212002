import React from "react";
import { Pagination as AntPagination } from "antd";
import styles from "./pagination.module.scss";
import classnames from "classnames";
const Pagination = ({
  className,
  onShowSizeChange = () => {},
  total,
  pageSize = 20,
  defaultPageSize = 20,
  onChange = () => {},
  currentPage,
  showSizeChanger,
}) => {
  return total >= defaultPageSize ? (
    <div className={classnames(styles.container, className)}>
      <AntPagination
        showSizeChanger={showSizeChanger}
        onShowSizeChange={onShowSizeChange}
        current={currentPage}
        total={total}
        pageSize={pageSize}
        onChange={onChange}
      />
    </div>
  ) : null;
};

export default Pagination;
