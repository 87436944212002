export const initialState = {
  loading: false,
  error: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "TOGGLE_LOADING":
      return { ...state, loading: action.loading };
    case "NETWORK_ERROR":
      return { ...state, error: "network", loading: false };
    case "SERVER_ERROR":
      return { ...state, error: "server", loading: false };
    case "UNAUTHORIZED_ERROR":
      return { ...state, error: "unauthorized", loading: false, redirectLink: action.redirectLink };
    case "INVITE_EXPIRED_ERROR":
      return { ...state, error: "invite", loading: false };
    default:
      throw new Error("No action type found");
  }
};

export default reducer;
