import React, { useContext } from "react";
import { Modal, Button, Icon } from "atomic/atoms";
import { useMount, useModal, useApi } from "hooks";
import { UserContext } from "contexts";
import { ConfirmLogoutModal } from "components/dashboard";
import { getWatcher } from "api/watchlist";
import localStrings from "localization";
import localTranslation from "localization/localization";
import { getFullName, getLocalItem, redirectTo } from "utils";
import styles from "./watchlist-accept-notifi-modal-container.module.scss";
import { Avatar } from "atomic/molecules";
import { removeLocalItem } from "utils";
import path from "path/path";

const WatchlistAcceptNotifiModal = () => {
  const { user } = useContext(UserContext);
  // const { history } = useRouter();
  const watchlistModal = useModal({});
  const confirmLogoutModal = useModal({});
  const redirectQueryParam = getLocalItem("redirectQueryParam");
  const query = redirectQueryParam ? JSON.parse(redirectQueryParam) : {};
  const { type, email: acceptingEmail, watcherId } = query;
  const { request, loading = true, result = {} } = useApi({
    api: getWatcher,
  });

  const { firstName, lastName, avatar = null, email = "" } = result;
  const name = getFullName({
    firstName,
    lastName,
    empty: email || "-",
  });

  useMount(() => {
    if (type === "ACTIVATE_WATCHLIST") {
      if (user.email === acceptingEmail) {
        watchlistModal.show();
        request({ watcherId });
      } else {
        confirmLogoutModal.show();
      }
    }
  });

  return (
    <>
      <Modal {...watchlistModal} title={""} className={styles.container} maskClosable={false}>
        <div className={styles.content}>
          <Avatar
            showDefault
            className={styles.avatar}
            phrases={[firstName, lastName]}
            image={avatar}
            loading={loading}
          />
          <h1>{localStrings.watchlistInviteAccepted}</h1>
          {loading ? (
            <Icon loading={loading} />
          ) : (
            <>
              <h4>{localTranslation("watcherCanNowWatchYou", [name])}</h4>
              <p>
                {localTranslation("toFollowBackWatchlist", [firstName || lastName || email || "-"])}
              </p>
              <Button
                tertiary
                className={styles.button}
                onClick={() => {
                  removeLocalItem("redirectQueryParam");
                  watchlistModal.close();
                }}
              >
                {localStrings.done}
              </Button>
            </>
          )}
        </div>
      </Modal>
      <ConfirmLogoutModal
        {...confirmLogoutModal}
        closeModal={() => {
          confirmLogoutModal.close();
          redirectTo(path.AUTH);
        }}
        email={acceptingEmail}
      />
    </>
  );
};

export default WatchlistAcceptNotifiModal;
