import React, { useContext, useMemo } from "react";
import localString from "localization";
import styles from "./edit-member-details.module.scss";
import { BackLink, Toast } from "atomic/molecules";
import { Modal } from "atomic/atoms";
import { useRouter } from "hooks";
import path from "path/path";
import { EditMemberDetailsForm } from "components/settings";
import { OrganizationContext } from "contexts";
import { getPrivacySettingsValue, setPrivacySettingsValue } from "api/organization";
import { LeaveModal } from "atomic/organisms";
import { useForm, useMount, useApi } from "hooks";
import initialFormState from "./initial-form-state";
import { Requirement } from "enums";
import { formatToDateTimeRequest, getBrowserTimezone } from "services";
import * as moment from "moment";
import { useModal } from "hooks";
import { ActionButton } from "components/common";
import localStrings from "localization";

const MemberDetailsContainer = () => {
  const { organizationId } = useContext(OrganizationContext);
  const leavePageModal = useModal({});
  const saveChangesModal = useModal({});
  const form = useForm(initialFormState);
  const { history } = useRouter();
  const keys = [
    "isNameAndPhotoRequired",
    "isEmailAndMobileNumberRequired",
    "isWristbandRequired",
    "isAboutYouRequired",
    "isEmploymentDetailRequired",
    "isEmergencyContactRequired",
    "isBodyTemperatureRequired",
    "isAreaLocationRequired",
    "isLocationRequired",
    "isSymptomRequired",
  ];

  const {
    setFormFieldValue,
    clearFormFieldsDirty,
    formFieldsValue,
    isFormDirty,
    submit: submitFormValue,
    isFormValid,
  } = form;

  const {
    request: initRequest,
    loading = true,
    result: data = {},
  } = useApi({
    api: () =>
      getPrivacySettingsValue({
        organizationId,
      }),
  });

  const { request, loading: submitting } = useApi({
    api: setPrivacySettingsValue,
  });

  const isFormChanged = useMemo(() => {
    for (let i in keys) {
      const key = keys[i];
      const value = formFieldsValue[key] === Requirement.Required;
      if (value !== data?.data?.[key]) {
        return true;
      }
    }
    return false;
  }, [formFieldsValue, data, keys]);

  const onCancel = () => {
    if (isFormDirty) {
      leavePageModal.show();
      return false;
    }
    leavePage();
  };

  const leavePage = () => {
    history.push(path.SETTINGS_MEMBER_DETAILS);
  };

  const submitForm = async () => {
    const params = {};
    Object.keys(formFieldsValue).forEach((k) => {
      params[k] = formFieldsValue[k] === Requirement.Required ? true : false;
    });
    params.startDate = formatToDateTimeRequest(formFieldsValue.startDate);
    params.timezone = getBrowserTimezone;
    await request({
      organizationId,
      form: params,
    });
    Toast({
      content: localString.changesSaved,
      success: true,
      visible: true,
      icon: "check-fill",
    }).open();
    leavePage();
  };

  const submit = () => {
    submitFormValue();
    if (isFormValid) {
      saveChangesModal.show();
    }
  };

  useMount(() => {
    const init = async () => {
      const data = await initRequest();
      Object.keys(data.data).forEach((key) => {
        if (keys.includes(key)) {
          setFormFieldValue(data.data[key] ? Requirement.Required : Requirement.NotRequired, key);
        }
      });
      setFormFieldValue(moment(data.data.startDate), "startDate");
      clearFormFieldsDirty();
    };
    init();
  });

  return (
    <div className={styles.container}>
      <Modal title={localStrings.askSaveChanges} {...saveChangesModal}>
        <div>
          Are you sure you want to continue to apply the changes you’ve made? Once you save, all
          members would receive a notification regarding the changes.
        </div>
        <ActionButton
          submitText={localStrings.saveAndSendNotif}
          cancelText={"Stay on this page"}
          disabled={!isFormDirty}
          loading={submitting || loading}
          onSubmit={submitForm}
          onCancel={() => {
            saveChangesModal.close();
          }}
        />
      </Modal>
      <LeaveModal
        {...leavePageModal}
        onClose={() => {
          leavePageModal.close();
        }}
        onLeave={() => {
          leavePageModal.close();
          leavePage();
        }}
      />
      <BackLink onClick={() => onCancel()} text={localString.back} />
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={styles.title}>{localStrings.memberDetails}</h2>
          <p className={styles.desc}>{localStrings.memberDetailsDesc}</p>
          <p className={styles.desc}>{localStrings.ifDetailIsRequiredDesc}</p>
        </div>
        <div className={styles.panel}>
          <EditMemberDetailsForm
            form={form}
            submit={submit}
            data={data}
            loading={loading}
            submitting={submitting}
            onCancel={onCancel}
            isFormChanged={isFormChanged}
          />
        </div>
      </div>
    </div>
  );
};

export default MemberDetailsContainer;
