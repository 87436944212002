import React, { memo, useContext } from "react";
import styles from "./overview-header.module.scss";
import { formatToLocalDate } from "utils";
import localStrings from "localization";
import { OrganizationContext } from "contexts";
import { Loading } from "atomic/atoms";
import { SelectTempType } from "atomic/molecules";

const OverviewHeader = ({ loading, lastUpdate, tempType, changeTempType }) => {
  const { organizationName } = useContext(OrganizationContext);

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>
        {loading ? (
          <Loading width={450} />
        ) : (
          `${localStrings.hereWhatHappening} ${organizationName}.`
        )}
      </h1>
      <p className={styles.lastUpdate}>
        {loading ? (
          <Loading width={200} />
        ) : (
          `${localStrings.lastUpdated} ${formatToLocalDate(lastUpdate)}`
        )}
      </p>
      <SelectTempType className={styles.selectTemp} value={tempType} onChange={changeTempType} />
    </div>
  );
};

export default memo(OverviewHeader);
