import React from "react";
import { SettingsLayout } from "layouts";
import localString from "localization";
import { Button } from "atomic/atoms";
import { Panel } from "atomic/molecules";
import styles from "./member-details.module.scss";
import { MemberDetails } from "components/settings";
import path from "path/path";
import { useRouter } from "hooks";

const MemberDetailsContainer = () => {
  const { history } = useRouter();
  return (
    <SettingsLayout title={localString.memberDetails}>
      <div className={styles.container}>
        <Panel className={styles.panel}>
          <div className={styles.header}>
            <div className={styles.title}>
              <h1 className={styles.panelTitle}>{localString.memberDetails}</h1>
              <div className={styles.description}>
                Manage which details are always shared by your community members.
              </div>
            </div>
            <div>
              <Button
                onClick={() => {
                  history.push(path.SETTINGS_MEMBER_DETAILS_EDIT);
                }}
              >
                Manage Details
              </Button>
            </div>
          </div>
          <MemberDetails />
        </Panel>
      </div>
    </SettingsLayout>
  );
};

export default MemberDetailsContainer;
