import React from "react";
import { Icon } from "atomic/atoms";
import styles from "./pill.module.scss";
import classnames from "classnames";

const Pill = ({ icon, error, text, onClick, children, className }) => {
  return (
    <div
      className={classnames(styles.pill, className, {
        [`${styles.error}`]: error,
      })}
    >
      {children || text}
      {icon && <Icon className={classnames(styles.icon, "icon")} name={icon} onClick={onClick} />}
    </div>
  );
};

export default Pill;
