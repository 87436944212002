import localStrings from "localization";

export const accountSettingsFormState = () => {
  return {
    email: {
      value: "",
      required: true,
      email: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 75,
      custom: [],
      customValidations: (value, custom) => {
        if (custom && custom.includes(value)) {
          return {
            error: true,
            message: localStrings.emailAddressAlreadyTaken,
          };
        }
      },
    },
    phone: {
      value: "",
      required: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 12,
      number: true,
      phone: true,
    },
    phoneCountryCode: {
      value: "",
      required: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 12,
    },
  };
};
