import React from "react";
import styles from "./privacy-changes.module.scss";
import classnames from "classnames";
import moment from "moment";

const PrivacyChanges = ({ privacyChanges }) => {
  const renderInfoContainer = (category, value) => {
    if (value === true || value === false) {
      return (
        <div className={styles.infoContainer}>
          <span className={classnames(styles.info, styles.prop)}>{category}</span>
          {value ? (
            <span className={classnames(styles.info, styles.required)}>Required</span>
          ) : (
            <span className={classnames(styles.info, styles.notRequired)}>Not Required</span>
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.container}>
      {privacyChanges && <span className={styles.date}>{moment().format("MMM DD, YYYY")}</span>}
      <h3 style={{ marginTop: "10px", marginBottom: "10px" }}>New Requirements</h3>
      <p className={styles.note}>
        This community made some changes to their member detail requirements.
      </p>
      {privacyChanges && (
        <div>
          {renderInfoContainer("Name and Profile Photo", privacyChanges.isNameAndPhotoRequired)}
          {renderInfoContainer(
            "Email Address and Mobile Number",
            privacyChanges.isEmailAndMobileNumberRequired
          )}
          {renderInfoContainer("PouchBAND", privacyChanges.isWristbandRequired)}
          {renderInfoContainer("About You", privacyChanges.isAboutYouRequired)}
          {renderInfoContainer("Employment Details", privacyChanges.isEmploymentDetailRequired)}
          {renderInfoContainer("Emergency Contact", privacyChanges.isEmergencyContactRequired)}
          {renderInfoContainer("Body Temperature", privacyChanges.isBodyTemperatureRequired)}
          {renderInfoContainer("Area Location", privacyChanges.isAreaLocationRequired)}
          {renderInfoContainer("Geolocation", privacyChanges.isLocationRequired)}
          {renderInfoContainer("Symptoms", privacyChanges.isSymptomRequired)}
        </div>
      )}
      <div className={styles.note} style={{ marginBottom: "20px" }}>
        These changes would be effective on{" "}
        <span className={styles.dateEffectivity}>
          {moment(privacyChanges.startDate).format("MMM DD, YYYY h:mm A")}
        </span>
        . Members would not be able to change the setting of the information set as required.
      </div>
      <div className={styles.note}>
        If you have any questions or concerns, please contact a manager of this community.
      </div>
    </div>
  );
};

export default PrivacyChanges;
