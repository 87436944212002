import React from "react";
import styles from "./unknown-user-modal.module.scss";
import localString from "localization";
import localTranslation from "localization/localization";
import { BusinessSites } from "enums";
import { Modal, Icon } from "atomic/atoms";
import { Button } from "atomic/molecules";

const UnknownUserModal = ({ setModal, modal }) => {
  const { visible, userId } = modal || {};

  return (
    <Modal
      title={
        <div className={styles.header}>
          <Icon name="lock" />
          <h2>{localString.privateInformation}</h2>
        </div>
      }
      visible={visible}
      onCancel={() => setModal({ visible: false, userId: 0 })}
    >
      <div className={styles.description}>
        {localTranslation("unknowUserContactInformation", [userId])}
      </div>
      <div className={styles.text}>{localString.pleaseContactPouchPass}</div>

      <div className={styles.submit}>
        <Button
          onClick={() => {
            window.open(BusinessSites.ContactUs, "_blank");
          }}
          primary
        >
          {localString.contactPouchPass}
        </Button>
      </div>
    </Modal>
  );
};

export default UnknownUserModal;
