import { Button } from "atomic/atoms";
import React from "react";
import styles from "./community-header.module.scss";
import { useRouter } from "hooks";
import path from "path/path";

const CommunityHeader = () => {
  const { history } = useRouter();

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>Communities</div>
        <Button
          text="Create New Community"
          onClick={() => {
            history.push(path.COMMUNITY_CREATE);
          }}
        />
      </div>
    </div>
  );
};

export default CommunityHeader;
