export const emergencyContactFormState = {
  contactName: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 75,
  },
  contactRelationship: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 75,
  },
  contactCountryCode: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  contactNumber: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 12,
    number: true,
  },
};
