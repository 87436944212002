import React, { useContext, useCallback } from "react";
import { Route, Switch } from "react-router-dom";
import { AccountStatus, Mixpanel, Routes } from "enums";
import { RoleContext } from "contexts";
import { useApi } from "hooks";
import { logout } from "api/auth";
import { ManagerHeader, MemberHeader } from "components/dashboard";
import { redirectTo } from "utils";
import mixpanel from "mixpanel-browser";
import AdminHeader from "components/dashboard/header/admin-header";

const HeaderContainer = () => {
  const role = useContext(RoleContext);

  const { request: logoutRequest, loading } = useApi({ api: logout });

  const logoutCb = useCallback(async () => {
    try {
      await logoutRequest();
      redirectTo(`/`);
      mixpanel.track(Mixpanel.LOGOUT);
    } catch (error) {
      console.log(error);
    }
  }, [logoutRequest]);

  return (
    <Switch>
      <Route
        path={`/${Routes.ADMIN}`}
        render={() => {
          return <AdminHeader logout={logoutCb} loading={loading} />;
        }}
      />
      <Route
        path="/"
        render={() => {
          if (role === AccountStatus.MANAGER) {
            return <ManagerHeader logout={logoutCb} loading={loading} />;
          }
          return <MemberHeader logout={logoutCb} loading={loading} />;
        }}
      />
    </Switch>
  );
};

export default HeaderContainer;
