import React, { useCallback, useEffect, useRef } from "react";
import styles from "./log-symptoms-form.module.scss";
import { DatePicker, TimePicker, Checkbox, Textarea } from "atomic/atoms";
import { Panel, Button, BackLink, Toast } from "atomic/molecules";
import { SymptomButton, MedicalTestForm } from "components/users";
import classnames from "classnames";
import moment from "moment";
import localStrings from "localization";
import initialFormState from "./initial-form-state";
import { useForm, useRouter } from "hooks";
import { v4 as uuidv4 } from "uuid";
import { Symptoms, Common } from "enums";
import path from "path/path";

const symptomsButtons = [
  {
    text: localStrings.cough,
    icon: "dry-cough",
    value: Symptoms.COUGH,
    key: uuidv4(),
  },
  {
    text: localStrings.tiredness,
    icon: "tiredness",
    value: Symptoms.TIREDNESS,
    key: uuidv4(),
  },
  {
    text: localStrings.bodyAche,
    icon: "bodyache",
    value: Symptoms.BODY_ACHE,
    key: uuidv4(),
  },
  {
    text: localStrings.soreThroat,
    icon: "sore-throat",
    value: Symptoms.SORE_THROAT,
    key: uuidv4(),
  },
  {
    text: localStrings.diarrhea,
    icon: "diarrhea",
    value: Symptoms.DIARRHEA,
    key: uuidv4(),
  },
  {
    text: localStrings.conjunctivitis,
    icon: "conjunctivitis",
    value: Symptoms.CONJUNCTIVITIS,
    key: uuidv4(),
  },
  {
    text: localStrings.headache,
    icon: "headache",
    value: Symptoms.HEADACHE,
    key: uuidv4(),
  },
  {
    text: localStrings.lossOfSmellOrTaste,
    icon: "loss-of-smell-or-taste",
    value: Symptoms.LOSS_OF_TASTE_SMELL,
    key: uuidv4(),
  },
  {
    text: localStrings.rashes,
    icon: "rashes",
    value: Symptoms.RASHES,
    key: uuidv4(),
  },
  {
    text: localStrings.chestPains,
    icon: "chest-pain",
    value: Symptoms.CHEST_PAINS,
    key: uuidv4(),
  },
  {
    text: localStrings.difficultyBreathing,
    icon: "breathing",
    value: Symptoms.DIFFICULTY_BREATHING,
    key: uuidv4(),
  },
  {
    text: localStrings.fingerToesDiscoloration,
    icon: "toes",
    value: Symptoms.FINGER_TOES_DISCOLORATION,
    key: uuidv4(),
  },
  {
    text: localStrings.lossOfSpeechOrMovement,
    icon: "loss-of-speech-movement",
    value: Symptoms.LOSS_OF_SPEECH_MOVEMENT,
    key: uuidv4(),
  },
];
const now = moment();
const LogSymptomsForm = ({
  edit,
  dispatch,
  loading,
  data,
  showDiscardModal,
  showDeleteModal,
  submitCallBack,
}) => {
  const {
    formFields,
    formFieldsValue,
    setFormFieldValue,
    setFormFieldValues,
    setFormFieldsRequired,
    clearFormFieldsError,
    isFormDirty,
    submit,
  } = useForm(initialFormState, submitCallBack);
  const { date, time, isMedicalTest, additionalInfo } = formFields;
  const {
    isMedicalTest: isMedicalTestValue,
    symptoms: symptomsValue,
    additionalInfo: additionaInfoValue,
    testTakenDate: testTakenDateValue,
  } = formFieldsValue;

  const { query, history, location } = useRouter();
  const userId = query?.userId || location?.state?.userId;
  const isMember = query?.userId;
  const initEditRef = useRef(true);

  const handleCancel = () => {
    if (isFormDirty) {
      showDiscardModal();
      return;
    }
    if (isMember) {
      history.push(path.MEMBER_PROFILE(userId));
    } else {
      history.push(path.USER_PROFILE);
    }
  };

  const handleOnClickSymptoms = useCallback(
    (symptom) => {
      const symptomExists = symptomsValue.includes(symptom);
      let value = [];
      if (symptomExists) {
        value = symptomsValue.filter((d) => d !== symptom);
      } else {
        value = [...symptomsValue, symptom];
      }
      dispatch({ type: "HIDE_TOAST" });
      setFormFieldValue(value, "symptoms");
      setFormFieldsRequired({
        additionalInfo: !value.length,
      });
    },
    [dispatch, setFormFieldValue, setFormFieldsRequired, symptomsValue]
  );

  const handleOnChangeMedicalTest = useCallback(
    (value, name) => {
      let requiredFields = {
        medicalTestType: false,
        testAddress: false,
        testTakenDate: false,
        testResult: false,
      };
      if (value) {
        requiredFields = {
          ...requiredFields,
          medicalTestType: true,
          testAddress: true,
          testTakenDate: true,
          testResult: !!testTakenDateValue,
        };
      }
      setFormFieldsRequired(requiredFields);
      setFormFieldValue(value, name);
    },
    [setFormFieldsRequired, setFormFieldValue, testTakenDateValue]
  );

  const handleOnChangeAdditionalInfo = useCallback(
    (value, name) => {
      dispatch({ type: "HIDE_TOAST" });
      setFormFieldsRequired({
        symptoms: !value,
      });
      setFormFieldValue(value, name);
    },
    [setFormFieldsRequired, setFormFieldValue, dispatch]
  );

  const handleSubmit = () => {
    if (!symptomsValue.length && !additionaInfoValue) {
      Toast({
        visible: true,
        error: true,
        content: localStrings.pleaseFillUpSymptom,
        icon: "info-fill",
      }).open();
      return;
    }
    submit();
  };

  useEffect(() => {
    if (!isMedicalTestValue) {
      setFormFieldsRequired({
        testTakenDate: false,
        testAddress: false,
        medicalTestType: false,
        otherTestName: false,
        testResultDate: false,
        testResult: false,
      });
      setFormFieldValues({
        testTakenDate: null,
        testAddress: null,
        medicalTestType: null,
        otherTestName: null,
        testResultDate: null,
        testResult: null,
      });
    }
  }, [isMedicalTestValue, setFormFieldValues, setFormFieldsRequired]);

  useEffect(() => {
    if (initEditRef.current && edit && !loading) {
      const dataKeys = Object.keys(data);
      const values = dataKeys.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: data[cur],
        };
      }, {});

      const {
        dateTime,
        symptoms,
        testTakenDate,
        testResultDate,
        additionalInfo,
        isMedicalTest,
        testResult,
        medicalTestType,
        ...rest
      } = values;

      // if (
      //   (!isMember && values?.userLoggedBy !== Number(userId)) ||
      //   (isMember && values?.userLoggedBy === Number(userId))
      // ) {
      //   history.push("/");
      // }

      setFormFieldsRequired({
        additionalInfo: !symptoms?.length,
        symptoms: !additionalInfo,
        testTakenDate: isMedicalTest,
        testAddress: isMedicalTest && !!testTakenDate,
        testResultDate: isMedicalTest && !!testResult,
        otherTestName: medicalTestType === Common.OTHER,
        testResult: !!testResultDate,
      });
      setFormFieldValues({
        date: moment(dateTime),
        time: moment(dateTime),
        symptoms: symptoms ? symptoms : [],
        testTakenDate: testTakenDate ? moment(testTakenDate) : null,
        testResultDate: testResultDate ? moment(testResultDate) : null,
        isMedicalTest,
        testResult: testResult?.toString().toUpperCase(),
        additionalInfo,
        medicalTestType,
        ...rest,
      });
      initEditRef.current = false;
    }
  }, [loading, edit, data, setFormFieldValues, setFormFieldsRequired, history, isMember, userId]);
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <BackLink className={styles.backLink} onClick={handleCancel} text={localStrings.back} />
        <h2 className={styles.title}>
          {edit ? localStrings.editLogSymptoms : localStrings.logSymptoms}
        </h2>
      </div>

      <h3 className={styles.panelTitle}>{localStrings.details}</h3>
      <Panel className={styles.detailsPanel}>
        <DatePicker
          name="date"
          allowClear={false}
          value={now}
          label={localStrings.date}
          onChange={setFormFieldValue}
          placeholder="MM/DD/YYYY"
          loading={loading}
          {...date}
        />
        <TimePicker
          name="time"
          value={now}
          label={localStrings.time}
          allowClear={false}
          onChange={setFormFieldValue}
          loading={loading}
          {...time}
        />
      </Panel>
      <h3 className={styles.panelTitle}>{localStrings.symptoms}</h3>
      <Panel>
        <h4 className={styles.sympTitle}>{localStrings.selectFollowingSymptoms}</h4>
        <div className={styles.symptoms}>
          {symptomsButtons.map((symptom, index) => {
            return (
              <SymptomButton
                {...symptom}
                className={classnames(styles.symptomButton, {
                  [`${styles.eleven}`]: index === 10,
                })}
                onClick={handleOnClickSymptoms}
                isChecked={symptomsValue.includes(symptom.value)}
              />
            );
          })}
        </div>
      </Panel>
      <h3 className={styles.panelTitle}>{localStrings.medicalTest}</h3>
      <Panel className={styles.medicalPanel}>
        <Checkbox
          name="isMedicalTest"
          className={styles.medicalCheckbox}
          label={localStrings.hasTheUserTakenAnyMedicalTest}
          onChange={handleOnChangeMedicalTest}
          {...isMedicalTest}
        />
        {isMedicalTestValue && (
          <MedicalTestForm
            formFields={formFields}
            setFormFieldValue={setFormFieldValue}
            setFormFieldValues={setFormFieldValues}
            setFormFieldsRequired={setFormFieldsRequired}
            clearFormFieldsError={clearFormFieldsError}
          />
        )}
      </Panel>
      <h3 className={styles.panelTitle}>{localStrings.additionalInfo}</h3>
      <Panel>
        <Textarea
          className={styles.logOtherSymptoms}
          placeholder={localStrings.logOtherSymptoms}
          name="additionalInfo"
          onChange={handleOnChangeAdditionalInfo}
          showCount
          {...additionalInfo}
        />
      </Panel>
      <div className={styles.actions}>
        {edit && (
          <Button
            danger
            text={localStrings.deleteLog}
            className={styles.delete}
            onClick={showDeleteModal}
          />
        )}
        <Button
          tertiary
          text={localStrings.cancel}
          className={styles.cancel}
          onClick={handleCancel}
        />
        <Button text={localStrings.saveChanges} onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default LogSymptomsForm;
