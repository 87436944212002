import React, { memo, useRef, useCallback } from "react";
import { Input as AntInput } from "antd";
import classnames from "classnames";
import styles from "./textarea.module.scss";
import { validateInput } from "utils";

const Textarea = ({
  placeholder,
  type,
  name,
  onChange = () => null,
  maxLength = 75,
  disabled,
  value = "",
  id,
  addonBefore,
  onFocus = () => null,
  onBlur = () => null,
  pattern = null,
  selectAllOnFocus,
  suffix = null,
  prefix = null,
  trim,
  showCount,
  autoSize,
  onPaste,
}) => {
  const { TextArea } = AntInput;
  const inputRef = useRef(null);
  // const [isReadOnly, setIsReadOnly] = useState(true);

  const handleOnFocus = useCallback(() => {
    if (selectAllOnFocus) {
      setTimeout(() => {
        inputRef.current.input.select();
      });
    }
    // setIsReadOnly(false);
    onFocus();
  }, [onFocus, selectAllOnFocus]);

  const handleOnBlur = useCallback(
    (e) => {
      const { value } = e.target;
      if (trim) {
        const trimmedVal = value.trim();
        onChange(trimmedVal, name);
        onBlur(trimmedVal);
        return;
      }
      onBlur(value);
    },
    [onBlur, onChange, name, trim]
  );

  const handleKeyPress = (e) => {
    if (pattern) {
      if (!validateInput({ pattern, event: e })) {
        e.preventDefault();
        return;
      }
    }
  };

  const handleOnChange = (e) => {
    const { value } = e.target;
    onChange(value, name);
  };

  const handleOnPaste = (e) => {
    if (onPaste) {
      onPaste(e);
    }
  };

  return (
    <TextArea
      autoSize={autoSize}
      ref={inputRef}
      id={id}
      addonbefore={addonBefore}
      disabled={disabled}
      className={classnames(styles.input, {
        [`${styles.hasCount}`]: showCount,
      })}
      placeholder={placeholder}
      onChange={handleOnChange}
      type={type}
      maxLength={maxLength}
      value={value}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onPaste={handleOnPaste}
      onKeyPress={handleKeyPress}
      suffix={suffix}
      prefix={prefix}
      // readOnly={isReadOnly} // Solution for autofill
    />
  );
};

export default memo(Textarea);
