import React, { useMemo } from "react";
import { Icon, Loading } from "atomic/atoms";
import { Panel } from "atomic/molecules";
import EmptyQuestion from "./empty-question/empty-question";
import styles from "./questions.module.scss";
import classnames from "classnames";
import DefaultQuestions from "./default-questions/default-questions";
import CustomQuestions from "./custom-questions/custom-questions";
import { CreateQuestionFormModalContainer, EditQuestionFormModalContainer } from "containers";

const Questions = ({
  loading,
  defaultQuestions,
  customQuestions,
  isDefaultQuestionVisible,
  isCustomQuestionVisible,
  toggleDefaultQuestion,
  toggleCustomQuestion,
  toggleAutoAddQuestion,
  toggleCreateFormModal,
  toggleEditFormModal,
  goBack,
  fetchQuestions,
  createFormModal,
  editFormModal,
  loadingQuestionId,
}) => {
  const renderDefaultQuestions = useMemo(() => {
    if (loading) {
      return <Loading isSkeleton={true} />;
    } else {
      return (
        <DefaultQuestions
          questions={defaultQuestions}
          toggleEditFormModal={toggleEditFormModal}
          toggleAutoAddQuestion={toggleAutoAddQuestion}
          loadingQuestionId={loadingQuestionId}
        />
      );
    }
  }, [defaultQuestions, loading, toggleAutoAddQuestion, toggleEditFormModal, loadingQuestionId]);

  const renderCustomQuestions = useMemo(() => {
    if (loading) {
      return <Loading isSkeleton={true} />;
    } else if (customQuestions.length > 0) {
      return (
        <CustomQuestions
          questions={customQuestions}
          toggleAutoAddQuestion={toggleAutoAddQuestion}
          toggleCreateFormModal={toggleCreateFormModal}
          toggleEditFormModal={toggleEditFormModal}
          loadingQuestionId={loadingQuestionId}
        />
      );
    } else {
      return <EmptyQuestion toggleFormModal={toggleCreateFormModal} />;
    }
  }, [
    customQuestions,
    loading,
    toggleAutoAddQuestion,
    toggleCreateFormModal,
    toggleEditFormModal,
    loadingQuestionId,
  ]);

  return (
    <div className={styles.container}>
      <div className={styles.backLinkContainer} onClick={goBack}>
        <Icon name="left-chevron" className={styles.icon} />
        <div className={styles.backLink}>Back</div>
      </div>
      <div className={styles.questionsContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.title}>Questions</div>
          <div style={{ marginBottom: "22px" }}>
            Create and manage all questions for all your forms.
          </div>
          <div>
            Select which questions would automatically be added whenever you create a new form.
          </div>
        </div>
        <div className={styles.rightContainer}>
          <Panel className={styles.panelContainer}>
            <div className={styles.panelHeader} onClick={toggleDefaultQuestion}>
              <div className={styles.defaultQuestionContainer}>
                <div className={styles.panelTitle}>Default Questions</div>
                <div>These information are data already being collected by PouchPASS.</div>
              </div>
              <Icon
                name="right-chevron"
                className={classnames(styles.icon, {
                  [`${styles.down}`]: isDefaultQuestionVisible,
                })}
              />
            </div>
            {isDefaultQuestionVisible && renderDefaultQuestions}
          </Panel>
          <Panel>
            <div>
              <div className={styles.panelHeader} onClick={toggleCustomQuestion}>
                <div>
                  <div className={styles.panelTitle}>Custom Questions</div>
                  <div>Collect more information from your members with custom questions.</div>
                </div>
                <Icon
                  name="right-chevron"
                  className={classnames(styles.icon, {
                    [`${styles.down}`]: isCustomQuestionVisible,
                  })}
                />
              </div>
              {isCustomQuestionVisible && renderCustomQuestions}
            </div>
          </Panel>
        </div>
      </div>
      <CreateQuestionFormModalContainer
        {...createFormModal}
        toggleFormModal={toggleCreateFormModal}
        fetchQuestions={fetchQuestions}
      />
      <EditQuestionFormModalContainer
        {...editFormModal}
        toggleFormModal={toggleEditFormModal}
        fetchQuestions={fetchQuestions}
      />
    </div>
  );
};

export default Questions;
