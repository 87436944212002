import React, { useContext } from "react";
import { OnboardingLayout, EmploymentHistoryForm } from "components/onboarding";
import BackgroundImage from "images/employment-details.svg";
import { UserContext } from "contexts";
import { onboardUser } from "api/user";
import { employmentHistoryFormState } from "states";
import { useApi, useForm, useMount, useRouter } from "hooks";
import { countryDialingCodeOptions } from "utils/countries";
import { getUserRequestParam } from "components/users/user-form/user-request-form";
import path from "path/path";

const EmploymentDetailsContainer = () => {
  const {
    isFormValid,
    formFieldsValue,
    formFields,
    setFormFieldValue,
    setFormFieldValues,
    submit,
  } = useForm(employmentHistoryFormState, submitCallback);

  const countryCodes = countryDialingCodeOptions();
  const { history } = useRouter();

  const { user, refetchUser } = useContext(UserContext);

  const { request: onboardUserRequest, loading } = useApi({ api: onboardUser });

  useMount(() => {
    setFormFieldValues({ ...user, ...user.userDetail });
  });

  async function submitCallback() {
    if (isFormValid) {
      const obj = () => {
        const params = {};
        ["employmentStatus", "company", "workCountryCode", "workPhone", "workAddress"].forEach(
          (key) => {
            params[key] = formFieldsValue[key] || "";
          }
        );
        return params;
      };

      await onboardUserRequest({
        ...getUserRequestParam({ ...user, ...user?.userDetail }),
        ...obj(),
        isEmploymentDetails: true,
      });
      refetchUser();

      history.push(path.ONBOARDING_EMERGENCY_CONTACT);
    }
  }

  return (
    <OnboardingLayout backgroundImage={BackgroundImage}>
      <EmploymentHistoryForm
        formFields={formFields}
        setFormFieldValue={setFormFieldValue}
        onSubmit={submit}
        countryCodes={countryCodes}
        loading={loading}
      />
    </OnboardingLayout>
  );
};

export default EmploymentDetailsContainer;
