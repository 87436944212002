import React, { Fragment } from "react";
import styles from "./no-user-panel.module.scss";
import { Image, Loading } from "atomic/atoms";
import { Button } from "atomic/molecules";
import NoUserImg from "images/no-users.svg";
import localStrings from "localization";
import classnames from "classnames";
import { useRouter } from "hooks";
import path from "path/path";

const NoUserPanel = ({ loading = false, className, hideCreate }) => {
  const { history } = useRouter();
  return (
    <div className={classnames(styles.container, className)}>
      {loading ? (
        <Loading isSkeleton width={100} />
      ) : (
        <Fragment>
          <Image className={styles.image} src={NoUserImg} />
          <div className={styles.action}>
            <h2 className={styles.title}>{localStrings.createAndManageUser}</h2>
            <p className={styles.desc}>{localStrings.assignAPouchBandToEachUser}</p>
            {hideCreate || (
              <Button
                onClick={() => history.push(path.MEMBER_CREATE)}
                text={localStrings.createMember}
              />
            )}
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default NoUserPanel;
