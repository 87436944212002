import React, { useEffect, useState, memo, useMemo, useContext, useRef, useCallback } from "react";
import styles from "./users-list.module.scss";
import localStrings from "localization";
import { debounced, getFullName, isBetweenDate } from "utils";
import UserListsItems from "./user-list-items";
import { LiveMapContext, OrganizationContext } from "contexts";
import { useApi } from "hooks";
import { searchUsers } from "api/user-data";
import { getLocation } from "utils/map";
import { Icon } from "atomic/atoms";
import { InputField } from "atomic/molecules";
import { NoUserPanel, FailedToLoad } from "atomic/organisms";

const UsersList = ({ defaultLocation }) => {
  const { organizationId } = useContext(OrganizationContext);
  const [searchBox, setSearchBox] = useState("");
  const {
    state: {
      users: { data, loading },
      currentLocation: {
        latitude: currentLat,
        longitude: currentLng,
        loading: currentLocLocation = true,
      },
      radius,
    },
    dispatch,
    isMiniMap,
  } = useContext(LiveMapContext);
  const [initializing, setInititializing] = useState(true);

  const { request: getUserRequest, error: errorMembers } = useApi({
    api: searchUsers,
    params: {
      organizationId,
      pageSize: null,
      page: null,
      searchKey: null,
      latitude: currentLat,
      longitude: currentLng,
      radius,
      orders: {
        distanceFromRadius: "ASC",
      },
    },
    handleOwnError: {
      server: true,
    },
  });
  
  const [searchKeyValue, setSearchKeyValue] = useState("");
  const [locations, setLocations] = useState({});
  const locationsRef = useRef({});

  useEffect(() => {
    const getLocations = async () => {
      for (const d of data) {
        const { latitude, longitude, userId } = d;
        let locationName = null;
        const userHasLocation = locationsRef.current.hasOwnProperty(userId);
        if (userHasLocation && locationsRef.current[userId] !== "-") continue;
        else {
          try {
            locationName = await getLocation({ lat: latitude, lng: longitude, delay: 3000 });
          } catch {
            locationName = "-";
          }
        }
        setLocations((prev) => {
          locationsRef.current = {
            ...prev,
            [userId]: locationName,
          };
          return { ...prev, [userId]: locationName };
        });
      }
    };

    getLocations();
  }, [data]);

  const fetchUsersData = useCallback(
    ({
      searchValue = searchKeyValue,
      latValue = null,
      lngValue = null,
      success = () => {},
      rad = radius,
    }) => {
      const prepareData = (users) => {
        return users
          .filter((user) => {
            const { latitude, longitude } = user;
            return latitude !== 0 && longitude !== 0 && latitude !== null && longitude !== null;
          })
          .map((user) => {
            const {
              firstName,
              lastName,
              lastDateTimeSynced,
              privateKeys = [],
              areaName,
              location,
              ...rest
            } = user;
            return {
              ...rest,
              fullName: privateKeys?.includes("firstName")
                ? null
                : getFullName({ firstName, lastName }),
              firstName,
              lastName,
              isActive: isBetweenDate(lastDateTimeSynced, 6, "hours"),
              isPrivateName: privateKeys?.includes("firstName"),
              isPrivateTemp: privateKeys?.includes("latestBodyTemp"),
              areaName,
              location,
            };
          });
      };
      dispatch({ type: "FETCH_USERS" });
      const fetchUsers = async () => {
        const { data } = await getUserRequest({
          longitude: searchValue ? null : lngValue,
          latitude: searchValue ? null : latValue,
          searchKey: searchValue,
          radius: rad > 4622 ? rad : 4622,
        });
        const pd = prepareData(data);
        if (success) {
          success(pd);
        }
        dispatch({
          type: "SUCCESS_FETCH_USERS",
          data: pd,
        });
      };
      fetchUsers();
    },
    [radius, dispatch, getUserRequest, searchKeyValue]
  );

  useEffect(() => {
    if (!initializing && searchKeyValue) {
      fetchUsersData({
        searchValue: searchKeyValue,
        success: (pd) => {
          if (pd[0]) {
            const member = pd[0];
            dispatch({
              type: "CHANGE_CURRENT_LOCATION",
              data: {
                latitude: member.latitude,
                longitude: member.longitude,
              },
            });
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchKeyValue, dispatch, initializing]);

  useEffect(() => {
    if (!currentLocLocation) {
      fetchUsersData({
        latValue: currentLat,
        lngValue: currentLng,
        rad: radius,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLng, currentLat, radius, currentLocLocation]);

  useEffect(() => {
    if (!currentLocLocation && initializing && defaultLocation.lng && defaultLocation.lat) {
      fetchUsersData({
        latValue: defaultLocation.lat,
        lngValue: defaultLocation.lng,
        rad: radius,
      });
      setInititializing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLocLocation, initializing, defaultLocation, radius]);

  useEffect(
    debounced(1000, () => {
      if (searchBox) {
        setSearchKeyValue(searchBox);
      }
    }),
    [searchBox]
  );

  const handleOnChange = (value) => {
    if (!value) {
      setSearchBox("");
      setSearchKeyValue("");
      fetchUsersData({
        latValue: defaultLocation.lat,
        lngValue: defaultLocation.lng,
        rad: radius,
        searchValue: "",
      });
      if (defaultLocation.lat && defaultLocation.lng) {
        dispatch({
          type: "CHANGE_CURRENT_LOCATION",
          data: {
            latitude: defaultLocation.lat,
            longitude: defaultLocation.lng,
          },
        });
      }
      return false;
    }
    setSearchBox(value);
    // if (!value) {
    //   clearLocation();
    // }
  };
  const renderUserList = useMemo(() => {
    if (errorMembers) {
      return <FailedToLoad />;
    }
    if (data.length || loading) {
      return <UserListsItems locations={locations} />;
    }
    if (!data.length && searchKeyValue) {
      return (
        <div className={styles.noResult}>
          <div>
            <h2>{localStrings.noResults}</h2>
            <p>{localStrings.trySearchingAgain}</p>
          </div>
        </div>
      );
    }

    return <NoUserPanel className={styles.noUserPanel} hideCreate />;
  }, [data, loading, locations, searchKeyValue, errorMembers]);

  return isMiniMap ? null : (
    <div className={styles.container}>
      <div className={styles.searchContainer}>
        <InputField
          prefix={{
            name: "search",
          }}
          value={searchBox}
          className={styles.search}
          placeholder={localStrings.searchMemberByNameOrAccessoryId}
          onChange={handleOnChange}
          renderSuffix={
            <Icon
              name="close"
              onClick={() => {
                setSearchBox("");
                setSearchKeyValue("");
                if (defaultLocation.lat === currentLat && defaultLocation.lng === currentLng) {
                  fetchUsersData({
                    latValue: defaultLocation.lat,
                    lngValue: defaultLocation.lng,
                    rad: radius,
                    searchValue: "",
                  });
                  dispatch({
                    type: "CHANGE_CURRENT_LOCATION",
                    state: {
                      zoom: 15,
                    },
                  });
                } else {
                  if (defaultLocation.lat && defaultLocation.lng) {
                    dispatch({
                      type: "CHANGE_CURRENT_LOCATION",
                      data: {
                        latitude: defaultLocation.lat,
                        longitude: defaultLocation.lng,
                      },
                      state: {
                        zoom: 15,
                      },
                    });
                  } else {
                    dispatch({
                      type: "CHANGE_CURRENT_LOCATION",
                      state: {
                        zoom: 15,
                      },
                    });
                  }
                }
                // clearLocation();
              }}
            />
          }
        />
      </div>
      {renderUserList}
    </div>
  );
};

export default memo(UsersList);
