const Common = {
  PAIRED: "PAIRED",
  NO_DEVICE: "NO_DEVICE",
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE",
  NORMAL: "NORMAL",
  FEVER: "FEVER",
  HYPOTHERMIA: "HYPOTHERMIA",
  ALL: "ALL",
  LOW_TEMPERATURE: "LOW_TEMPERATURE",
  HIGH_TEMPERATURE: "HIGH_TEMPERATURE",
  VERY_HIGH: "VERY_HIGH",
  EXTREMELY_HIGH: "EXTREMELY_HIGH",
  POSITIVE: "POSITIVE",
  NEGATIVE: "NEGATIVE",
  OTHER: "OTHER",
  HIGHER_THAN_NORMAL: "HIGHER_THAN_NORMAL",
};

export default Object.freeze(Common);
