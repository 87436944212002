import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AccountStatus, Routes } from "enums";
import { OrganizationContext, RoleContext } from "contexts";
import { useRouter } from "hooks";

const OverviewRoute = ({ component: Component, ...rest }) => {
  const organization = useContext(OrganizationContext);
  const role = useContext(RoleContext);

  const { location } = useRouter();

  return (
    <Route
      {...rest}
      render={(props) => {
        if (organization?.organizationId > 0) {
          if (role === AccountStatus.MANAGER) {
            return <Component {...props} />;
          }
          return <Redirect to={{ pathname: `/${Routes.PROFILE}`, state: location.state }} />;
        }
        return <Redirect to={{ pathname: `/${Routes.PROFILE}`, state: location.state }} />;
      }}
    />
  );
};

export default OverviewRoute;
