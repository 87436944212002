import React, { Fragment, useMemo, useState, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { Details } from "components/overview";
import localStrings from "localization";
import localTranslation from "localization/localization";
import { Link, Icon, Modal } from "atomic/atoms";
import styles from "./individual-high-risk.module.scss";
import classnames from "classnames";
import { convertTemperature, temperatureSymbol, isNormalTemp } from "utils";
import EveryoneIsDoingWell from "images/everyone-is-doing-well.svg";
import { RiskLevel, Routes } from "enums";

const data = [
  {
    name: "Drew",
    temperature: 39.1,
    riskLevel: RiskLevel.HIGH,
  },
];

const IndividualHighRisk = ({ className }) => {
  const [modal, setModal] = useState(false);

  const showModal = useCallback(() => {
    setModal(true);
  }, []);

  const hideModal = () => {
    setModal(false);
  };

  const tableColumns = useMemo(
    () => [
      {
        key: uuidv4(),
        dataIndex: "name",
        title: localStrings.name,
        width: 100,
      },
      {
        key: uuidv4(),
        dataIndex: "temperature",
        title: localStrings.temp,
        width: 100,
      },
      {
        key: uuidv4(),
        dataIndex: "riskLevel",
        title: (
          <Fragment>
            {localStrings.riskLevel}
            <Icon onClick={showModal} className={styles.learnMoreIcon} name="info-fill" />
          </Fragment>
        ),
        width: 100,
      },
    ],
    [showModal]
  );

  const preparedData = useMemo(() => {
    return data.map((d) => {
      const { temperature, riskLevel, ...rest } = d;
      return {
        temperature: (
          <span
            className={classnames(styles.temperature, {
              cDanger: !isNormalTemp(temperature),
            })}
          >
            {temperature} {temperatureSymbol()}
          </span>
        ),
        riskLevel: (
          <span
            className={classnames(styles.riskLevelPill, {
              [`${styles.highRisk}`]: riskLevel === RiskLevel.HIGH,
              [`${styles.moderateRisk}`]: riskLevel === RiskLevel.MODERATE,
              [`${styles.lowRisk}`]: riskLevel === RiskLevel.LOW,
            })}
          >
            {riskLevel.toLowerCase()}
          </span>
        ),
        ...rest,
      };
    });
  }, []);

  const noData = useMemo(() => {
    return {
      title: localStrings.everyoneIsDoingWell,
      description: localStrings.everyoneIsDoingWellDesc,
      image: EveryoneIsDoingWell,
      className: styles.noDataContainer,
    };
  }, []);

  return (
    <Fragment>
      <Details
        className={className}
        title={localStrings.individualsAtRisk}
        viewAllLink={`/${Routes.USERS}`}
        description={
          <Fragment>
            {localStrings.individualAtRiskDesc}
            <Link className={styles.learnMore} text={localStrings.learnMore} onClick={showModal} />
          </Fragment>
        }
        columns={tableColumns}
        data={preparedData}
        viewAllText={localStrings.viewAll}
        viewAllCount={preparedData.length}
        noData={noData}
      />
      <Modal
        className={styles.riskDetailsModal}
        visible={modal}
        title={localStrings.riskLevel}
        onCancel={hideModal}
      >
        <div className={styles.riskLevel}>
          <p className={classnames(styles.title, styles.highRisk)}>
            <Icon className={styles.cautionIcon} name="caution" />
            {localStrings.highRisk}
          </p>
          <p className={styles.desc}>
            {localTranslation("highRiskDesc", [
              `${convertTemperature(39.1)} ${temperatureSymbol()}`,
            ])}
          </p>
        </div>
        <div className={styles.riskLevel}>
          <p className={classnames(styles.title, styles.moderateRisk)}>
            <Icon className={styles.cautionIcon} name="caution" />
            {localStrings.moderateRisk}
          </p>
          <p className={styles.desc}>
            {localTranslation("moderateRiskDesc", [
              `${convertTemperature(37.6)} ${temperatureSymbol()}`,
              `${convertTemperature(39)} ${temperatureSymbol()}`,
            ])}
          </p>
        </div>
        <div className={styles.riskLevel}>
          <p className={classnames(styles.title, styles.lowRisk)}>
            <Icon className={styles.cautionIcon} name="caution" />
            {localStrings.lowRisk}
          </p>
          <p className={styles.desc}>{localStrings.lowRiskDesc}</p>
        </div>
        <div className={styles.riskLevel}>
          <p className={classnames(styles.title, styles.veryLowRisk)}>{localStrings.veryLowRisk}</p>
          <p className={styles.desc}>{localStrings.veryLowRiskDesc}</p>
        </div>
      </Modal>
    </Fragment>
  );
};

export default IndividualHighRisk;
