const initialFormState = {
  deviceUid: {
    required: true,
    value: "",
    maxLength: 14,
    min: {
      length: 12,
      message: "Minimum 12 characters.",
    },
    error: {
      status: false,
      message: "",
    },
  },
};

export default initialFormState;
