import React from "react";
import styles from "./image-slider.module.scss";
import classnames from "classnames";
import { Image } from "atomic/atoms";

const ImageSlider = ({ className, items, active = 0 }) => {
  const image = items && items[active] ? items[active] : {};
  return (
    <>
      <div className={classnames("image-slider", styles.container, className)}>
        <Image src={image.image} className={classnames(styles.image, image.className)} />
      </div>
    </>
  );
};

export default ImageSlider;
