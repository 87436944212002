import { useApi } from "hooks";
import { uploadImage } from "api/storage";

const useFileUploader = () => {
  const { request, loading, result } = useApi({ api: uploadImage });

  return { loading, result, request };
};

export default useFileUploader;
