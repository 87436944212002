const initialFormFields = {
  otp: {
    required: true,
    value: null,
    min: {
      length: 6,
    },
  },
};

export default initialFormFields;
