import React, { useMemo, memo, useContext, useState } from "react";
import { Loading } from "atomic/atoms";
import { Map } from "atomic/organisms";
import classnames from "classnames";
import styles from "./map-location.module.scss";
import UserPin from "images/user-pin.svg";
import EmptyImage from "images/empty.svg";
import { getInitials } from "utils";
import { OrganizationContext } from "contexts";
import { useMount } from "hooks";
import localStrings from "localization";
import { environment } from "../../../environments/environment";

const MapLocation = ({
  className,
  strings = {
    unableToLocate: localStrings.unableToLocate,
  },
  locationValue: location,
  loading,
  longitude: dLng,
  latitude: dLat,
  firstName,
  lastName,
  noMarker,
  setCoordinates,
  quarantineStatus,
  circle,
  zoom,
}) => {
  const [browserLocation, setBrowserLocation] = useState(null);

  const { organizationId, longitude: orgLong, latitude: orgLat } =
    useContext(OrganizationContext) || {};
  const longitude = useMemo(() => {
    if (dLng) {
      return dLng;
    }
    if (noMarker) {
      return 0;
    }
    return orgLong || 0;
  }, [dLng, noMarker, orgLong]);
  const latitude = useMemo(() => {
    if (dLat) {
      return dLat;
    }
    if (noMarker) {
      return 0;
    }
    return orgLat || 0;
  }, [dLat, noMarker, orgLat]);

  const lat = latitude;
  const lng = longitude;

  useMount(() => {
    if ((!longitude || !latitude) && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const {
          coords: { latitude: lat, longitude: lng },
        } = position;
        console.log("HeyyyBL", { lat, lng });
        setBrowserLocation({
          lat,
          lng,
        });
      });
      return;
    }
    console.warn("Geolocation is not supported by this browser.");
  });

  const initCenter = useMemo(() => {
    if (!loading) {
      if (browserLocation && !lat && !lng) {
        return {
          ...browserLocation,
        };
      }
      return {
        lat,
        lng,
      };
    }
  }, [lat, lng, loading, browserLocation]);

  const marker = useMemo(() => {
    const initial = getInitials([firstName, lastName]) || "-";
    if (!dLat && !dLng) {
      // return {
      //   position: { lat: latitude, lng: longitude },
      //   icon: EmptyImage,
      //   label: { text: "", color: "white" },
      // };
      return null;
    }
    if (!loading) {
      return {
        position: { lat: latitude, lng: longitude },
        icon: noMarker ? EmptyImage : UserPin,
        label: { text: initial, color: "white" },
      };
    }
  }, [firstName, lastName, loading, latitude, longitude, dLat, dLng, noMarker]);

  const renderMap = useMemo(() => {
    return (
      <Map
        className={styles.map}
        marker={marker}
        initCenter={initCenter}
        onCenterChange={(coordinates) => {
          if (setCoordinates) {
            setCoordinates(coordinates);
          }
        }}
        circle={circle}
        zoom={zoom}
      />
    );
  }, [initCenter, marker, setCoordinates, circle, zoom]);

  const render = useMemo(() => {
    if (!noMarker) {
      if (loading) {
        return (
          <div className={styles.lastLocation}>
            <Loading />
          </div>
        );
      }
      return (
        <div className={styles.lastLocation}>
          {location && location !== "-" ? location : strings.unableToLocate}
        </div>
      );
    }
    return null;
  }, [loading, location, noMarker, strings.unableToLocate]);

  const renderLocationContent = useMemo(() => {
    const { id } = environment;
    if (id === "dev" || id === "staging" || (id === "production" && organizationId === 88)) {
      return quarantineStatus;
    }
    return render;
  }, [organizationId, quarantineStatus, render]);

  return (
    <div className={classnames(styles.container, className)}>
      {renderMap}
      {renderLocationContent}
    </div>
  );
};

export default memo(MapLocation);
