import React, { useState, useContext, useEffect } from "react";
import styles from "./managers-guide.module.scss";
import ManagersGuideImage1 from "images/managers-guide1.png";
import ManagersGuideImage2 from "images/managers-guide2.png";
import ManagersGuideImage3 from "images/managers-guide3.png";
import ManagersGuideImage4 from "images/managers-guide4.png";
import WristbandImage from "images/wristband.png";
import IphoneImage from "images/iphone.png";
import localStrings from "localization";
import localTranslation, { LocalTranslation } from "localization/localization";
import { useForm, useMount } from "hooks";
import classnames from "classnames";
import { pairUserDevice } from "api/user-device";
import { getLocalItem, removeLocalItem } from "utils";
import { ResponseCode } from "enums";
import { UserContext } from "contexts";
import { v4 as uuidv4 } from "uuid";
import path from "path/path";
import { Image } from "atomic/atoms";
import { InputField, Button, Toast } from "atomic/molecules";
import { GuideModal } from "atomic/organisms";

const ManagersGuide = ({ isVisible, onClose = () => null }) => {
  const { user } = useContext(UserContext);

  const { userId } = user;

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(isVisible || getLocalItem("fromOnboarding") === "1");
  const form = useForm({
    deviceUid: {
      required: true,
      value: "",
      maxLength: 14,
      min: {
        length: 12,
        message: "Minimum 12 characters.",
      },
      error: {
        status: false,
        message: "",
      },
    },
  });
  const {
    isFormValid,
    formFields,
    setFormFieldValue,
    submit,
    setFormFieldValues,
    clearFormFields,
  } = form;

  useMount(() => {
    removeLocalItem("fromOnboarding");
  });

  useEffect(() => {
    if (isVisible) {
      setVisible(isVisible);
      clearFormFields();
    }
    // eslint-disable-next-line
  }, [isVisible]);

  // useEffect(() => {
  //   if (state?.onboarding) {
  //     const s = state || {};
  //     delete s.onboarding;
  //     history.push("/", s);
  //   }
  // }, [state, history]);

  const prepareErrorMessage = (code) => {
    switch (code) {
      case "8005":
        return localStrings.accessoryAlreadyPaired;
      case "8006":
        return localStrings.accessoryIsNotRegistered;
      default:
        return "";
    }
  };

  const submitDeviceUid = () => {
    submit();
    if (isFormValid) {
      setLoading(true);
      const orgId = getLocalItem("organizationId");
      const deviceUid = formFields.deviceUid.value;
      pairUserDevice({
        organizationId: orgId,
        deviceUid,
        userId,
      })
        .then(() => {
          setLoading(false);
          setVisible(false);
        })
        .catch((err) => {
          setLoading(false);
          const { status, metadata } = err;
          const { code } = metadata;
          if (status === ResponseCode.BAD_REQUEST) {
            setFormFieldValues({
              deviceUid: {
                value: deviceUid,
                error: {
                  status: true,
                  message: prepareErrorMessage(code),
                },
              },
            });
            return false;
          }
          Toast({
            content: localStrings.pleaseTryAgain,
            error: true,
            visible: true,
            icon: "caution",
          }).open();
        });
    }
  };
  return (
    <GuideModal
      visible={visible}
      close={() => {
        setVisible(false);
        onClose();
      }}
      exit={submitDeviceUid}
      className={classnames(styles.container)}
      items={[
        {
          image: ManagersGuideImage1,
          title: localStrings.welcomeToPouchPass,
          description: localStrings.welcomeDescription,
        },
        {
          image: ManagersGuideImage2,
          title: localStrings.inviteTeamMembersAndCreateArea,
          description: localStrings.inviteTeamMembersAndCreateAreaDescription,
        },
        {
          image: ManagersGuideImage3,
          title: localStrings.monitorAnySymptoms,
          description: localStrings.monitorAnySymptomsDescription,
        },
        {
          image: ManagersGuideImage4,
          title: localStrings.trackEncounter,
          description: localStrings.trackEncounterDescription,
        },
        {
          content: (
            <div className={styles.container}>
              <h1>{localStrings.startYourPouchBand}</h1>
              <div className={styles.pouchBands}>
                {[
                  {
                    image: WristbandImage,
                    description: localStrings.pouchBandStep1,
                  },
                  {
                    image: IphoneImage,
                    description: (
                      <LocalTranslation
                        text={localStrings.pouchBandStep2}
                        items={[
                          <a
                            rel="noopener noreferrer"
                            href={path.PLAY_STORE}
                            className={styles.link}
                            target="_blank"
                          >
                            {localStrings.playStore}
                          </a>,
                          <a
                            rel="noopener noreferrer"
                            href={path.APP_STORE}
                            className={styles.link}
                            target="_blank"
                          >
                            {localStrings.appStore}.
                          </a>,
                        ]}
                      />
                    ),
                  },
                ].map((d, i) => {
                  return (
                    <div className={styles.item} key={uuidv4()}>
                      <Image className={styles.image} src={d.image} />
                      <div className={styles.step}>{localTranslation("stepNth", [i + 1])}</div>
                      <div>{d.description}</div>
                    </div>
                  );
                })}
              </div>
              <div className={styles.deviceUid}>
                <InputField
                  className={styles.input}
                  name="deviceUid"
                  {...formFields.deviceUid}
                  onChange={setFormFieldValue}
                  placeholder={localStrings.inputAccessoryId}
                />
              </div>
              <p>
                <LocalTranslation
                  text={localStrings.dontHavePouchBandYet}
                  items={[
                    <a
                      rel="noopener noreferrer"
                      href={path.POUCH_SHOP}
                      className={styles.link}
                      target="_blank"
                    >
                      {localStrings.orderOne}.
                    </a>,
                  ]}
                />
              </p>
            </div>
          ),
          actions: ({ next, prev }) => {
            return (
              <div className={styles.submit}>
                <div>
                  <Button
                    tertiary
                    className={styles.textButton}
                    onClick={() => {
                      prev();
                    }}
                    icon="left-chevron"
                    right={false}
                    text={localStrings.back}
                  />
                </div>
                <div>
                  <Button
                    className={styles.submitButton}
                    loading={loading}
                    disabled={!formFields.deviceUid.value || formFields.deviceUid.error?.status}
                    onClick={() => {
                      submitDeviceUid();
                    }}
                    text={localStrings.allSet}
                  />
                </div>
                <div>
                  <Button
                    secondary
                    onClick={() => {
                      setVisible(false);
                      onClose();
                    }}
                    text={localStrings.doThisLater}
                  />
                </div>
              </div>
            );
          },
        },
      ]}
    />
  );
};

export default ManagersGuide;
