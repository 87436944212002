import React, { useContext, Fragment } from "react";
import { Link, Icon } from "atomic/atoms";
import { Panel, IconLink } from "atomic/molecules";
import localString from "localization";
import { NotificationContext, RoleContext, UserContext } from "contexts";
import classnames from "classnames";
import { AccountStatus } from "enums";
import styles from "./settings-nav.module.scss";
import path from "path/path";

const SettingsNav = ({ className }) => {
  const role = useContext(RoleContext);
  const notifications = useContext(NotificationContext);
  const { email } = useContext(UserContext);
  return (
    <Panel className={classnames(styles.container, className)}>
      <h4>{email}</h4>
      <h4>{localString.accountSettings}</h4>
      <Link className={styles.menu} activeClassName={styles.active} to={path.SETTINGS} exact>
        <div className={styles.menuIcon}>
          <Icon name="exhibitor" />
        </div>
        <div className={styles.menuText}>{localString.account}</div>
      </Link>
      <Link
        className={styles.menu}
        activeClassName={styles.active}
        to={path.SETTINGS_PROFILE}
        exact
      >
        <div className={styles.menuIcon}>
          <Icon name="profile-circle" />
        </div>
        <div className={styles.menuText}>{localString.profile}</div>
      </Link>
      <IconLink
        className={styles.menu}
        activeClassName={styles.active}
        to={path.SETTINGS_MY_ORGANIZATION}
        notif={notifications.unreadInviteRequestCount || notifications.hasOrganizationPrivacyUpdate}
      >
        <div className={styles.menuIcon}>
          <Icon name="building" />
        </div>
        <div className={styles.menuText}>{localString.joinedCommunities}</div>
      </IconLink>

      <Link
        className={styles.menu}
        activeClassName={styles.active}
        to={path.SETTINGS_PREFERENCE}
        exact
      >
        <div className={styles.menuIcon}>
          <Icon name="preference" />
        </div>
        <div className={styles.menuText}>Preferences</div>
      </Link>
      {role === AccountStatus.MANAGER ? (
        <Fragment>
          <h4>{localString.organizationSettings}</h4>
          <>
            <Link
              className={styles.menu}
              activeClassName={styles.active}
              to={path.SETTINGS_TEAM_AND_ROLES}
              exact
            >
              <div className={styles.menuIcon}>
                <Icon name="users" />
              </div>
              <div className={styles.menuText}>{localString.teamAndRoles}</div>
            </Link>
            <Link
              className={styles.menu}
              activeClassName={styles.active}
              to={path.SETTINGS_MEMBER_DETAILS}
              exact
            >
              <div className={styles.menuIcon}>
                <Icon name="lock" />
              </div>
              <div className={styles.menuText}>{localString.memberDetails}</div>
            </Link>
            {/* <Link
              className={styles.menu}
              activeClassName={styles.active}
              to={`/${Routes.SETTINGS}/${Routes.FORMS}`}
              exact
            >
              <div className={styles.menuIcon}>
                <Icon name="document" />
              </div>
              <div className={styles.menuText}>Forms</div>
            </Link> */}
          </>
        </Fragment>
      ) : null}
    </Panel>
  );
};

export default SettingsNav;
