import React from "react";
import styles from "./draggable-items.module.scss";
import classnames from "classnames";
import { Icon } from "atomic/atoms";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const DraggableItems = ({ title, className, items = [], setItems, disableTabIndex = false }) => {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: "none",
    boxShadow: isDragging ? "0px 0px 10px #9E9E9E" : "",
    borderRadius: isDragging ? "3px" : "",
    padding: isDragging ? "10px 20px" : "",
    ...draggableStyle,
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const reordered = reorder(items, result.source.index, result.destination.index);
    setItems(reordered);
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "" : "",
  });

  return (
    <div className={classnames("label-items", styles.container, className)}>
      <div className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" className={styles.droppable}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        className={classnames(
                          styles.draggableItem,
                          item.dragItemClassName,
                          "draggableItem"
                        )}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        tabIndex={disableTabIndex ? -1 : 0}
                      >
                        <Icon name="drag" className={classnames(styles.dragIcon, "dragIcon")} />{" "}
                        {item.content}
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default DraggableItems;
