import React, { useState, useMemo } from "react";
import styles from "./profile-info.module.scss";
import { Icon, Loading, Tooltip } from "atomic/atoms";
import { Panel, Avatar, LabelItems } from "atomic/molecules";
import { FailedToLoad } from "atomic/organisms";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import classnames from "classnames";
import localStrings from "localization";
import { PrivacyKey } from "enums";

const ProfileInfo = ({
  className,
  actionButton,
  device: DevicePouchBand,
  userInfo,
  loading,
  error,
  latestSync,
  menu,
  userPrivacyStatus,
  userPrivacyLoading,
}) => {
  const { firstName, lastName, avatar, userId, phrases } = userInfo || {};

  const fullName = userPrivacyStatus.isNameAndPhotoAllowed
    ? `${firstName || ""} ${lastName || ""}`.trim() || "-"
    : `#${userId}`;
  const phoneNumber = useMemo(() => {
    if (!userPrivacyStatus.isEmailAndMobileNumberAllowed) {
      return (
        <Tooltip title={localStrings.private}>
          {`${userInfo?.phoneCountryCode || ""} XXX XXXX`.trim()}
        </Tooltip>
      );
    }
    return `${userInfo?.phoneCountryCode || ""} ${userInfo?.phone || ""}`.trim();
  }, [userInfo, userPrivacyStatus]);

  const [active, setActive] = useState({});

  const items = useMemo(() => {
    let keys = [];
    keys = [
      ...keys,
      {
        key: "ABOUT",
        title: localStrings.about,
        labels: [
          {
            label: localStrings.birthday,
            key: "birthday",
          },
          {
            label: localStrings.gender,
            key: "gender",
          },
          {
            label: localStrings.bloodType,
            key: "bloodType",
          },
          {
            label: localStrings.height,
            key: "height",
          },
          {
            label: localStrings.weight,
            key: "weight",
          },
          {
            label: localStrings.currentResidentialAddress,
            key: "address",
          },
        ],
      },
      {
        key: "EMPLOYEE_DETAILS",
        title: localStrings.employeeDetails,
        labels: [
          {
            label: localStrings.employmentStatus,
            key: "employmentStatus",
          },
          {
            label: localStrings.company,
            key: "company",
          },
          {
            label: localStrings.workContactNumber,
            key: "companyPhone",
          },
          {
            label: localStrings.workAddress,
            key: "workAddress",
          },
        ],
      },
      {
        key: "EMERGENCY_DETAILS",
        title: localStrings.emergencyContactPerson,
        labels: [
          {
            label: localStrings.contactPersonsName,
            key: "contactName",
          },
          {
            label: localStrings.contactRelationship,
            key: "contactRelationship",
          },
          {
            label: localStrings.mobileNumber,
            key: "contactNumber",
          },
        ],
      },
      {
        key: "POUCHBAND",
        title: localStrings.pouchBands,
        content: DevicePouchBand,
      },
    ];
    const d = { ...userInfo, ...userInfo?.userDetail };
    if (d.dob) {
      d.birthday = moment(d.dob, "MM/DD/YYYY").format("MMMM DD, YYYY");
    }
    d.mobileNumber = d.phone ? `${d.phoneCountryCode || ""} ${d.phone}`.trim() : "";
    d.companyPhone = d.workPhone ? `${d.workCountryCode || ""} ${d.workPhone}`.trim() : "";
    d.phoneNumber = phoneNumber;
    d.contactNumber = d.contactNumber
      ? `${d.contactCountryCode || ""} ${d.contactNumber}`.trim()
      : "";
    if (d.height) {
      d.height = `${d.height} ${localStrings.cm}`;
    }
    if (d.weight) {
      d.weight = `${d.weight} ${localStrings.kg}`;
    }
    return keys.map((key) => ({
      title: key.title,
      items: !key.content
        ? key.labels.map((label) => ({
            label: label.label,
            item: d[label.key] || "-",
          }))
        : null,
      key: key.key,
      content: key.content,
    }));
  }, [userInfo, DevicePouchBand, phoneNumber]);

  return (
    <div className={classnames(className)}>
      <Panel className={styles.panel}>
        <div className={styles.header}>
          <div>{menu}</div>
          <Avatar
            loading={loading || userPrivacyLoading}
            className={styles.avatar}
            image={avatar}
            phrases={phrases}
          />

          {!error && (
            <>
              <div className={styles.name}>
                {loading || userPrivacyLoading ? <Loading /> : fullName}
              </div>
              {actionButton}
              {latestSync && <div className={styles.lastSync}>Active {latestSync}</div>}
            </>
          )}
        </div>
        {error ? (
          <FailedToLoad />
        ) : (
          <>
            <div className={styles.contactInfoContainer}>
              {userInfo?.email && !userPrivacyLoading && (
                <div className={styles.contactInfo}>
                  <div className={styles.infoIconContainer}>
                    <Icon name="email" className={styles.infoIcon} />
                  </div>{" "}
                  {userPrivacyStatus.isEmailAndMobileNumberAllowed ? (
                    userInfo?.email
                  ) : (
                    <Tooltip title={localStrings.private}>xxxx@xxx.com</Tooltip>
                  )}
                </div>
              )}
              {!userPrivacyLoading && phoneNumber && (
                <div className={styles.contactInfo}>
                  <div className={styles.infoIconContainer}>
                    <Icon name="mobile-phone" className={styles.infoIcon} />
                  </div>
                  {phoneNumber}
                </div>
              )}
            </div>

            <div className={styles.information}>
              <Items
                active={active}
                items={items}
                loading={loading}
                setActive={setActive}
                userPrivacyStatus={userPrivacyStatus}
                userPrivacyLoading={userPrivacyLoading}
              />
            </div>
          </>
        )}
      </Panel>
      {userInfo?.emailAddress}
    </div>
  );
};

const Items = ({ items, loading, setActive, active, userPrivacyStatus, userPrivacyLoading }) => {
  return items.map((item) => {
    const key = item.key;
    return (
      <div className={styles.info} key={uuidv4()}>
        <LabelItems
          className={styles.labelItems}
          loading={loading || userPrivacyLoading}
          title={item.title}
          items={item.items}
          value={item.key}
          onClick={() => {
            if (userPrivacyStatus[PrivacyKey[key]] || PrivacyKey[key] === PrivacyKey.POUCHBAND) {
              setActive((v) => {
                return {
                  ...v,
                  [key]: !active[key],
                };
              });
            }
          }}
          active={active[key]}
          content={item.content}
          lock={
            PrivacyKey[key] === PrivacyKey.POUCHBAND ? false : !userPrivacyStatus[PrivacyKey[key]]
          }
        ></LabelItems>
      </div>
    );
  });
};

export default ProfileInfo;
