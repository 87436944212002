import { isNumber } from "utils";
import { getOrgLocation } from "utils";

export const getLocation = async ({ lat: latitude, lng: longitude, delay = 1000 }) => {
  try {
    const google = window.google;
    const geocoder = new google.maps.Geocoder();

    const defaultLocation = getOrgLocation();
    const lat = latitude || defaultLocation.latitude;
    const lng = longitude || defaultLocation.longitude;

    if (isNumber(lat) && isNumber(lng) && lat !== 0 && lng !== 0) {
      await new Promise((r) => setTimeout(r, delay));
      return new Promise((resolve, reject) => {
        geocoder.geocode(
          {
            location: {
              lat,
              lng,
            },
          },
          (results, status) => {
            if (status === "OK") {
              if (results[0]) {
                resolve(results[0].formatted_address);
              } else {
                console.error("Something went wrong.", status);
                reject(`Something went wrong. ${status}`);
              }
            } else {
              console.error("Something went wrong.", status);
              reject(`Something went wrong. ${status}`);
            }
          }
        );
      });
    } else {
      return null;
    }
  } catch (err) {
    console.log(err);
  }
};
