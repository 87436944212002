import React, { useContext } from "react";
import styles from "./team-managers.module.scss";
import localString from "localization";
import classnames from "classnames";
import { useRouter } from "hooks";
import { SettingsLayout } from "layouts";
import { MemberList } from "components/settings";
import { UserContext } from "contexts";
import path from "path/path";
import { Modal } from "atomic/atoms";
import { Panel, Button } from "atomic/molecules";

const TeamManagers = ({
  loading,
  activeManagers,
  pendingManagers,
  actions,
  actionModal,
  showModal,
  loadingActionModal,
  closeActionModal,
  submitActionModal,
}) => {
  const { history } = useRouter();

  const { user } = useContext(UserContext);
  return (
    <SettingsLayout title={localString.settings}>
      <div className={classnames(styles.container)}>
        <Modal title={actionModal.title} visible={actionModal.visible} onCancel={closeActionModal}>
          <div
            className={styles.actionModal}
            dangerouslySetInnerHTML={{
              __html: actionModal.content,
            }}
          />
          <div className={styles.submit}>
            <Button tertiary onClick={closeActionModal}>
              {localString.cancel}
            </Button>
            <Button
              onClick={() => {
                submitActionModal(actionModal.action, actionModal.userId);
              }}
              danger
              loading={loadingActionModal}
            >
              {actionModal.submitText}
            </Button>
          </div>
        </Modal>
        <div>
          <div className={styles.content}>
            <Panel className={styles.panel}>
              <div className={styles.panelTitle}>
                <div>
                  <h3>{localString.teamManagers}</h3>
                  <p>{localString.inviteManagerDescription}</p>
                </div>
                <Button
                  text={localString.inviteManager}
                  onClick={() => history.push(path.SETTINGS_TEAM_AND_ROLES_INVITE)}
                />
              </div>
              <div className={styles.list}>
                <MemberList
                  members={activeManagers}
                  loading={loading}
                  actionLoading={loadingActionModal}
                  selected={actionModal.userId}
                  options={(d) => {
                    return d.userId === user.userId
                      ? [
                          {
                            onClick: () => {
                              history.push(path.SETTINGS_PROFILE);
                            },
                            label: localString.editProfile,
                          },
                        ]
                      : [
                          {
                            onClick: () => {
                              history.push(path.MEMBER_PROFILE(d.userId));
                            },
                            label: localString.viewProfile,
                          },
                          {
                            onClick: () => {
                              showModal(actions.Remove, d);
                            },
                            label: localString.remove,
                          },
                        ];
                  }}
                />
              </div>
              {pendingManagers.length > 0 ? (
                <div className={styles.list}>
                  <div className={styles.listTitle}>{localString.pendingInvites}</div>
                  <div className={styles.pendingInvites}>
                    <MemberList
                      members={pendingManagers}
                      pending
                      loading={loading}
                      actionLoading={loadingActionModal}
                      selected={actionModal.userId}
                      options={(d) => {
                        return [
                          {
                            onClick: () => {
                              submitActionModal(actions.Resend, d);
                            },
                            label: localString.resendInvite,
                          },
                          {
                            onClick: () => {
                              showModal(actions.Revoke, d);
                            },
                            label: localString.revokeInvite,
                          },
                        ];
                      }}
                    />
                  </div>
                </div>
              ) : null}
            </Panel>
          </div>
        </div>
      </div>
    </SettingsLayout>
  );
};

export default TeamManagers;
