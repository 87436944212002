import React, { useState, useMemo } from "react";
import { Table, Image, Links } from "atomic/atoms";
import { Panel, Button } from "atomic/molecules";
import { FailedToLoad } from "atomic/organisms";
import { CovidResult } from "components/users";
import styles from "./symptoms.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import { Common } from "enums";
import { v4 as uuidv4 } from "uuid";
import { searchSymptoms } from "api/symptom";
import NoSymptomImage from "images/no-symptoms.svg";
import moment from "moment";
import { useApi, useMount } from "hooks";
import useDeleteSymptoms from "./delete-symptoms/delete-symptoms";

const tableColumns = [
  {
    title: localStrings.date,
    dataIndex: "date",
    key: uuidv4(),
    hasColumnAction: true,
    width: 122,
  },
  {
    title: localStrings.symptoms,
    dataIndex: "symptoms",
    key: uuidv4(),
    width: 200,
  },
];

const NoSymptomsLogged = ({ className, visible, error }) => {
  if (error && visible) {
    return <FailedToLoad />;
  }
  return visible ? (
    <div className={classnames(styles.noSymptomsLogged, className)}>
      <Image src={NoSymptomImage} className={styles.noSymptomImage} />
      <h2 className={styles.noSymptomsLoggedTitle}>{localStrings.noSymptomsLogged}</h2>
    </div>
  ) : null;
};

const Symptoms = ({
  className,
  scroll,
  isMemberProfile = false,
  user,
  organizationId = null,
  strings,
  actions,
  ownAccount,
}) => {
  const [covidResult, setCovidResult] = useState({
    visible: false,
    result: {},
  });
  const userId = user?.userId;

  const { request, loading = true, result, error } = useApi({
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
    api: () =>
      searchSymptoms({
        userId,
        organizationId,
        showManagerCreated: true,
      }),
  });

  const { render: ConfirmDeleteSymptom, show: confirmDeleteSymptom } = useDeleteSymptoms({
    refresh: request,
  });

  const { data } = result || { data: [] };
  useMount(() => {
    const init = async () => {
      await request();
    };
    init();
  });

  const goToLogSymptoms = () => {
    actions.createSymptoms();
  };

  const tableData = useMemo(() => {
    let d = [...data];
    return d.map((d) => {
      const { dateTime, symptoms, additionalInfo, medicalTestType, testResult, symptomId } = d;
      const showMenuAction = () => {
        return true;
        // if (isMemberProfile && !ownAccount) {
        //   return d?.userLoggedBy !== d?.userId;
        // }
        // return d?.userLoggedBy === d?.userId;
      };
      console.log({
        isMemberProfile,
        ownAccount,
        value: showMenuAction(),
      });
      return {
        key: uuidv4(),
        date: (
          <div>
            <div>{dateTime ? moment(dateTime).format("MMM DD hh:mm A") : "-"}</div>
            {showMenuAction() && (
              <div>
                <Links
                  items={[
                    {
                      text: localStrings.edit,
                      className: styles.nameActions,
                      onClick: () => {
                        actions.editSymptoms(symptomId);
                      },
                    },
                    {
                      text: localStrings.delete,
                      danger: true,
                      className: classnames(styles.nameActions, "cDanger"),
                      onClick: () => {
                        confirmDeleteSymptom({
                          symptomId,
                        });
                      },
                    },
                  ]}
                />
              </div>
            )}
          </div>
        ),
        symptoms: (
          <>
            <div className={classnames(styles.symptoms)}>
              {symptoms &&
                symptoms.map((symptom) => {
                  return (
                    <div key={uuidv4()} className={classnames(styles.symptom)}>
                      <div className={classnames(styles.pill)}>
                        {symptom.toString().toLowerCase().replace(/_/g, " ")}
                      </div>
                    </div>
                  );
                })}
            </div>
            {additionalInfo && <p className={styles.note}>{additionalInfo}</p>}
            {medicalTestType !== Common.OTHER && testResult && (
              <div
                className={classnames(styles.testResult)}
                onClick={() => {
                  setCovidResult({
                    visible: true,
                    result: d || {},
                  });
                }}
              >
                {localStrings.viewTestResults}
              </div>
            )}
          </>
        ),
      };
    });
  }, [data, actions, confirmDeleteSymptom, isMemberProfile, ownAccount]);

  if (user.privacySymptoms) {
    return (
      <Panel className={classnames(styles.warning)}>
        <div>
          <h3 className={styles.title}>{localStrings.symptoms}</h3>
        </div>
        {localStrings.privacySymptomsWarning(user.name)}
      </Panel>
    );
  }

  return (
    <Panel className={classnames(styles.container, className)}>
      {ConfirmDeleteSymptom}
      <CovidResult
        visible={covidResult.visible}
        result={covidResult.result}
        onClose={() => {
          setCovidResult((prev) => ({ ...prev, visible: false }));
        }}
      />
      <div className={styles.tableHeader}>
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{localStrings.symptoms}</h3>
          <p>{strings.symptomsDescription}</p>
        </div>

        <Button
          className={classnames(styles.action, styles.tableButton)}
          prefixIcon={{ name: "plus" }}
          onClick={goToLogSymptoms}
        >
          {localStrings.logSymptoms}
        </Button>
      </div>
      {!error && (loading || !!data.length) && (
        <>
          <Table
            className={styles.table}
            columns={tableColumns}
            loading={loading}
            data={tableData}
            scroll={scroll}
          />
        </>
      )}
      <NoSymptomsLogged error={error} visible={!loading && !data.length} className={styles.table} />
    </Panel>
  );
};

export default Symptoms;
