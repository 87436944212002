import React from "react";
import ReactDOM from "react-dom";
import "sass/index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ErrorBoundary } from "pages";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { environment } from "./environments/environment";

(async () => {
  const LDProvider = await asyncWithLDProvider({
    clientSideID: environment.FEATURE_FLAG.clientSideID,
  });
  ReactDOM.render(
    <LDProvider>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </LDProvider>,
    document.getElementById("root")
  );
})();

// ReactDOM.render(

//   <ErrorBoundary>
//     <App />
//   </ErrorBoundary>,
//   document.getElementById("root")
// );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
