import React from "react";
import styles from "./time-picker.module.scss";
import { TimePicker as AntTimePicker } from "antd";

const TimePicker = ({
  use12Hours = true,
  format = "hh:mm A",
  onChange = () => null,
  disabled,
  placeholder,
  name,
  value,
  allowClear = true,
}) => {
  const handleOnChange = (moment) => {
    if (!moment || !moment?.isValid) {
      return false;
    }
    onChange(moment, name);
  };

  return (
    <AntTimePicker
      className={styles.timePicker}
      use12Hours={use12Hours}
      format={format}
      onChange={handleOnChange}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      allowClear={allowClear}
    />
  );
};

export default TimePicker;
