const locale = {
  about: "",
  aboutYou: "",
  aboutYouDesc: "",
  acceptInvite: "",
  accessory: "",
  accessoryAlreadyPaired: "此配件已与另一个用户配对。",
  accessoryDetails: "配件详细信息",
  accessoryId: "配件ID",
  accessoryIdAlreadyPaired: "",
  accessoryIdDoesNotBelongToOrganization: "",
  accessoryIdIsntAvailable: "",
  accessoryOrMacAddress: "",
  accessoryRemoved: "",
  accessoryStatus: "",
  accessoryType: "配件类型",
  account: "",
  accountInformation: "",
  accountSettings: "",
  active: "可以使用",
  activeAndSyncing: "允许使用和同步",
  activeMembers: "",
  activeOraganization: "",
  add: "增加",
  addAMember: "",
  addANewMember: "",
  addANewUser: "添加新用户",
  addAccessory: "添加配件",
  addAndManagerPouchBands: "",
  addMember: "",
  addMemberAndProfile: "",
  addMembers: "",
  addNew: "",
  addNewMembers: "",
  addPouchband: "",
  addUsers: "添加用户",
  addYourContactDetails: "",
  addYourPouchBand: "",
  additionalInfo: "",
  address: "",
  all: "所有",
  allSet: "",
  allTheInfoCollectedProfile: "",
  allowed: "",
  almostDoneEnterYourNewPassword: "",
  alreadyHaveAnAccount: "",
  alreadyInvitedAssignAsManager: "",
  alreadyInvitedPendingError: "",
  and: "",
  appStore: "",
  applyFilters: "应用过滤器",
  areYouSure: "",
  area: "区域",
  areaDescription: "",
  areaDuplicateName: "这个区域已经存在。请选择另外的用户民。",
  areaLocation: "位置/区域",
  areaLocationDesc: "",
  areaName: "",
  areas: "区域",
  asThePersonDescribed: "",
  askDeleteArea: "",
  askDeleteMember: "",
  askDeleteUser: "删除用户？",
  askRemoveAccessory: "",
  askRemoveManager: "",
  askRemoveMember: "",
  askSaveChanges: "",
  askTheUserToWearPouchBand: "",
  askYouToWearPouchBand: "",
  assignAPouchBandToEachUser: "",
  assignAPouchBandToStartMonitorTheirTemp: "",
  assignAsManager: "",
  authorization: "",
  back: "",
  backTo: "",
  backToHome: "",
  backToLogin: "",
  basicInfo: "",
  birthdate: "",
  birthday: "",
  bloodType: "",
  bodyAche: "",
  bodyTemperature: "",
  bodyTemperatureDays: "",
  browsingYourFiles: "",
  cancel: "取消",
  celciusSymbol: "",
  celsius: "摄氏度",
  change: "",
  changeAccessory: "更改配件",
  changePhoto: "",
  changeYourEmail: "",
  changesSaved: "",
  checkCredentials: "请检查您提供的凭据，如果错误仍然存​​在，请联系技术支持部门。",
  checkEmploymentDetails: "",
  checkWithYourAdminForDetails: "",
  checkingInternet: "检查您的网络或数据连接。",
  chestPains: "",
  chooseBloodType: "",
  chooseGender: "",
  chooseTypeTest: "",
  chooseWhichInformationToShare: "",
  clear: "明确",
  clearAllFilters: "",
  cm: "",
  company: "",
  companyPlaceholder: "",
  completeProfile: "",
  confirmDeleteMember: "",
  confirmNewPassword: "",
  confirmPassword: "",
  confirmRemoveManager: "",
  confirmRemoveMember: "",
  confirmRevokeManagerInvite: "",
  conjunctivitis: "",
  connectAPouchBandDesc: "",
  contactAndPersonalInfo: "",
  contactHistory: "",
  contactHistoryDesc: "",
  contactInformation: "",
  contactNumber: "",
  contactPersonsName: "",
  contactPouchPass: "",
  contactRelationship: "",
  contactUs: "",
  cough: "",
  countryCurrentlyResidingIn: "",
  createAccount: "",
  createAndManageUser: "",
  createArea: "",
  createMember: "",
  createPPaccount: "",
  createUser: "",
  createWorryFree: "",
  createYourFirstArea: "",
  createYourFirstAreaDesc: "",
  currentPassword: "",
  currentResidentialAddress: "",
  date: "日期",
  dateOfAvailabilityOfTestResults: "",
  dateOfContact: "",
  dateOfMedicalTest: "",
  decline: "",
  declineInvitation: "",
  declineInvitationDesc: "",
  delete: "",
  deleteArea: "",
  deleteLog: "",
  deleteMember: "",
  deleteSymptomsLog: "",
  deleteUser: "删除用户",
  deletedMemeber: "",
  description: "",
  details: "",
  deviceStatus: "设备状态",
  diarrhea: "",
  diary: "",
  didntGetACode: "",
  didntReceiveAnything: "",
  difficultyBreathing: "",
  discardChanges: "",
  discardChangesMessage: "",
  discardTitle: "",
  dismiss: "",
  doThisLater: "",
  doYouWantToCreateYourOwnOrg: "",
  dontHavePouchBandYet: "",
  duration: "",
  easilyIdentifyYourProfile: "",
  edit: "",
  editArea: "编辑区",
  editLogSymptoms: "",
  editManager: "",
  editMember: "",
  editProfile: "",
  editSettings: "",
  editUser: "",
  egMedicalClinic: "",
  emailAddress: "",
  emailAddressAlreadyTaken: "",
  emailAddressAndMobileNumber: "",
  emailAddresses: "",
  emailAlreadyAssociatedWithThisOrganization: "",
  emailAlreadyExists: "",
  emergencyContact: "",
  emergencyContactDesc: "",
  emergencyContactPerson: "",
  employed: "",
  employeeDetails: "",
  employmentDetails: "",
  employmentDetailsDesc: "",
  employmentStatus: "",
  enterAnEmailAddress: "",
  enterNewPassword: "",
  enterOtpVerifyYourEmailAddress: "",
  enterPassword: "",
  everyoneIsDoingWell: "",
  everyoneIsDoingWellDesc: "",
  everyoneIsSafe: "",
  everyoneIsSafeDesc: "",
  export: "",
  extremelyHigh: "",
  fAQsPage: "",
  fahrenheit: "华氏度",
  failedToValidateEmail: "",
  fever: "发热",
  feverCases: "发热病例",
  feverTrend: "",
  feverTrendOvertime: "发烧趋势",
  fillInEmailAddresses: "",
  findOutHowCreateOrg: "",
  findOutHowNow: "",
  findOutMore: "",
  fingerToesDiscoloration: "",
  finishSettingUpAccount: "",
  firstName: "",
  forFurtherQuestions: "",
  forgotPassword: "",
  fullName: "",
  gateway: "",
  gatewayId: "",
  gatewayIdOrMacAddress: "",
  gatewayMacAddress: "",
  gatewayName: "",
  gatewayNamePlaceholder: "",
  gender: "",
  geolocation: "",
  geolocationDesc: "",
  goodJobAllUserActive: "",
  goodJobAllUserActiveDesc: "",
  great: "",
  hasNotBeenInContact: "",
  hasTheUserTakenAnyMedicalTest: "",
  headache: "",
  height: "",
  heightInCm: "",
  hereWhatHappening: "这个是发生在…...",
  highRisk: "",
  highRiskDesc: "",
  highTemperature: "",
  higherThanNormal: "",
  hypothermia: "低温",
  iAgreeToPouchTerms: "",
  iAuthorizeOrg: "",
  ifAny: "",
  iformationIsImportantNarrative: "",
  imageMustBeSmallerThan: "",
  in: "",
  inactive: "暂停使用",
  inactiveDesc: "用户未在6小时内发送体温信息",
  individualAtRiskDesc: "",
  individualsAtRisk: "",
  inputAccessoryId: "",
  invalidCurrentPassword: "",
  inviteExpiredDesc: "",
  inviteHasBeenSentTo: "",
  inviteManager: "",
  inviteManagerDescription: "",
  inviteMembers: "",
  inviteNewManager: "",
  invitePeopleToCreate: "",
  invitePouchPassDesc: "",
  invitePouchPassNote: "",
  inviteRequest: "",
  inviteRequestDesc: "",
  inviteRequestDescManager: "",
  inviteRequestDescMember: "",
  inviteSent: "",
  inviteSentDetails: "",
  inviteSentMemberDesc: "",
  inviteTeamMembersAndCreateArea: "",
  inviteTeamMembersAndCreateAreaDescription: "",
  inviteToPouchPass: "",
  invitedMembersWouldHave: "",
  invitesSent: "",
  invitingAMember: "",
  joinedCommunities: "",
  joinedOn: "",
  keepThisOrg: "",
  kg: "",
  lastDateOfContact: "",
  lastName: "",
  lastSynced: "最后一次同步",
  lastUpdated: "最近一次更新",
  latestTemp: "最新的温度",
  learnMore: "",
  leave: "",
  leaveOTPMessage: "",
  leaveOrganization: "",
  leavePage: "",
  leavePageDesc: "",
  lessDetails: "",
  liveMap: "",
  location: "位置",
  logIn: "",
  logOtherSymptoms: "",
  logSymptoms: "",
  logTheirSymptomsNow: "",
  login: "",
  loginNow: "",
  loginTo: "",
  logout: "",
  lossOfSmellOrTaste: "",
  lossOfSpeechOrMovement: "",
  low: "",
  lowRisk: "",
  lowRiskDesc: "",
  lowTemperature: "",
  manager: "",
  managerRequest: "",
  me: "",
  medicalTest: "",
  member: "",
  memberDetails: "",
  memberDetailsPendingMessage: "",
  memberFromUntil: "",
  memberLocationPrivate: "",
  memberProfileOnly: "",
  memberProfileOnlyDesc: "",
  memberRequest: "",
  memberSince: "",
  memberStatus: "",
  members: "",
  mobileNumber: "",
  moderateRisk: "",
  moderateRiskDesc: "",
  monitorAnySymptoms: "",
  monitorAnySymptomsDescription: "",
  monitorYourBodyTemp: "",
  monitorYourBodyTempDesc: "",
  myOrganizationDescription: "",
  myOrganizations: "",
  name: "名称",
  nameAndProfilePhoto: "",
  nameOfClinicOrHospital: "",
  nameOfOtherTest: "",
  nameOfSpecificMedicalTest: "",
  nameRemoved: "",
  negative: "",
  networkErrorDesc: "可能是由于您的连接不稳定，或该设备拒绝您的访问，或者是出于我们的失误。",
  newPassword: "",
  newUserAdded: "",
  next: "",
  noAccessory: "",
  noAccessoryYet: "还没有附件",
  noContactDetected: "",
  noData: "没有数据。",
  noDevice: "无设备",
  noLongerAManager: "",
  noMemberYet: "No members yet.",
  noMembersFound: "No members found.",
  noName: "无名",
  noNeedForApp: "",
  noRecordsYet: "",
  noResults: "",
  noSymptomsLogged: "",
  noUserFound: "未找到相应的用户。",
  noUserYet: "还没有该用户。",
  normal: "正常",
  notAllowed: "",
  notAtRisk: "",
  notRequired: "",
  ohNoYouDontHaveAccess: "",
  okay: "",
  onceYouLeaveOrg: "",
  oopsIncorrectEmailOrPass: "哎呀！电子邮箱地址或密码不正确",
  oopsSomethinngWentWrong: "",
  optional: "",
  or: "",
  orCheck: "",
  orderOne: "",
  orgYouManage: "",
  organizationSettings: "",
  otherLocation: "",
  ourAvailablePlans: "",
  overview: "",
  pageNotFound: "",
  pageNotFoundDesc: "",
  paired: "已连接",
  pairingStatus: "配对状态",
  password: "",
  passwordReset: "",
  passwordsDoNotMatch: "",
  pastOrganizations: "",
  pastOrganizationsDesc: "",
  past13Days: "已经过去13天",
  pendingInvites: "",
  pendingInvitesDesc: "",
  pendingMemberConfirmation: "",
  permanentResidence: "",
  permissions: "",
  personalInformation: "",
  playStore: "",
  pleaseCheckForTheOneTimePasswordWeSentTo: "",
  pleaseCheckYourEmailAndClickMessage: "",
  pleaseContactOurSupport: "",
  pleaseContactPouchPass: "",
  pleaseEnterAnEmailInFormat: "",
  pleaseEnterAtLeastOneEmail: "",
  pleaseFillUpArea: "",
  pleaseFillUpSymptom: "",
  pleaseFillUpUser: "",
  pleaseInputCurrentPassword: "",
  pleaseRequestThePersonProfile: "",
  pleaseTryAgain: "",
  pleaseUseAtleastNthCharacters: "",
  poor: "",
  positive: "",
  pouchBand: "",
  pouchBandDescription: "",
  pouchBandForEveryone: "",
  pouchBandStep1: "",
  pouchBandStep2: "",
  pouchBands: "",
  pouchnationPhilippines: "",
  privacyPolicy: "",
  privacySymptomsWarning: "",
  private: "",
  privateInformation: "",
  privateOnlyMe: "",
  profile: "",
  profileAvatarSaved: "",
  profileOnly: "",
  profileSettings: "",
  rashes: "",
  recordAndMonitorYourSymptoms: "",
  recovingWithANormalTemp: "",
  refreshPage: "刷新此页面。",
  registrationStart: "",
  relationshipPlaceholder: "",
  rememberMe: "",
  rememberYourPassword: "",
  rememberYourPasswordQuestion: "",
  remove: "删除",
  removeAccessory: "删除配件",
  removeAccessoryDesc: "",
  removeAsManager: "",
  removeFromOrganization: "",
  removeManagerSuccess: "",
  removeMember: "",
  removed: "",
  removedMember: "",
  replacePhoto: "",
  required: "",
  resendANewOtp: "",
  resendInvite: "",
  resendInviteSuccess: "",
  resendNow: "",
  resetLinkResent: "",
  resetLinkSent: "",
  resetPassword: "",
  resetTokenExpired: "",
  retry: "",
  retypePassword: "",
  reviewTheInformation: "",
  revoke: "",
  revokeInvite: "",
  revokeInviteSuccess: "",
  riskLevel: "",
  role: "",
  roleAndPermissions: "",
  sampleAreaDescription: "例如“迭戈西朗大厦东座二楼”",
  sampleAreaName: "例如“2306” 室",
  save: "",
  saveAndSendNotif: "",
  saveChanges: "保存更改",
  savePermissions: "",
  searchByUserOrLocation: "",
  searchMemberByNameOrAccessoryId: "",
  searchUserByNameOrAccessoryId: "通过名称或ID配件搜索用户",
  seconds: "",
  sections: "",
  seeMore: "",
  selectARole: "",
  selectBloodType: "",
  selectCountry: "",
  selectEmploymentStatus: "",
  selectFollowingSymptoms: "",
  selectGender: "",
  selectUserTestResult: "",
  selfEmployed: "",
  sendInvite: "",
  sendResetLink: "",
  settings: "",
  setupProfileLater: "",
  sharedInformation: "",
  shopNow: "",
  shopPouchBand: "",
  signIn: "",
  signingUpAggreement: "",
  someEmailsNotRecognized: "",
  somethingWentWrong: "",
  soreThroat: "",
  sorryYouAreNotAuthToAccessThisPage: "",
  startMonitorTeam: "开始监测您的团队。",
  startTour: "",
  startYourPouchBand: "",
  startbyUploading: "",
  statistics: "",
  stayOnThisPage: "",
  stayOnTopOfYourHealthAndGetNotified: "",
  stayOnTopOfYourHealthAndGetNotifiedDesc: "",
  stepNth: "",
  successChangedPassword: "",
  symptomLoggedToDiary: "",
  symptomNotFound: "",
  symptoms: "",
  symptomsDescription: "",
  takeNoteOfTheMedicalTestResultHere: "",
  teamAndRoles: "",
  teamManagers: "",
  tellUsMoreAboutYourself: "",
  temp: "",
  tempCases: "",
  tempHasBeenIncreasingForThePastDays: "",
  temperature: "温度",
  temperatureCases: "",
  temperatureStatus: "",
  temperatureTrend: "",
  termsAndConditions: "",
  testResultSummaryInfo: "",
  testResults: "",
  testedNegativeWithCovid: "",
  testedPositiveWithCovid: "",
  theOTPYouEnteredIsInvalid: "",
  theyllBeAdded: "",
  thisEmailIsAlreadyAMemberOfYourOrg: "",
  thisMember: "",
  thisUserDoesNotExistOn: "",
  time: "时间",
  tiredness: "",
  to: "",
  toLearnAboutPouchPass: "",
  today: "今天",
  totalMembers: "",
  trackEncounter: "",
  trackEncounterDescription: "",
  try: "尝试",
  trySearchingAgain: "",
  tryToSearchAgain: "尝试再次搜索。",
  typeOfMedicalTest: "",
  uhOhEmailNotRegistered: "该电子邮箱未注册。",
  unableToLocate: "",
  unableToLocateMember: "",
  unableToLocateUser: "无法找到用户。",
  unableToRemoveAccessory: "",
  unableToRetrieveData: "",
  unableToRetrieveDataDesc: "",
  unauthorized: "未经授权",
  unbaleToUploadMoreThanMb: "",
  unemployed: "",
  unfortunatelyThisInviteIsExpired: "",
  unknowUserContactInformation: "",
  unknowUserUserId: "",
  unverifiedMember: "",
  unverifiedMembersDesc: "",
  uploadAProfile: "",
  uploadCovidTestCerti: "",
  uploadFile: "",
  uploadPhoto: "",
  uploadPhotoDescription: "",
  uploadProfile: "",
  uploadTestCertificate: "",
  usePouchPassForYourFamilyOrBusiness: "",
  usePouchPassForYourFamilyOrBusinessDesc: "",
  userHasPendingInvite: "",
  userIsNowAManager: "",
  users: "用户",
  usingAMixOfUppercaseAndLowercase: "",
  validateEmailAddress: "",
  verifiedMember: "",
  verifiedMembersDesc: "",
  verifyEmail: "",
  verifyEmailAddress: "",
  veryHigh: "",
  veryLowRisk: "",
  veryLowRiskDesc: "",
  view: "",
  viewAll: "查看全部",
  viewAllAreas: "查看所有地区",
  viewAndAddSymptoms: "",
  viewInvite: "",
  viewMyActiveOrg: "",
  viewMyOrgs: "",
  viewProfile: "",
  viewTestResults: "",
  viewYourContactInformation: "",
  viewYourNameAndProfilePhoto: "",
  visitOur: "",
  wantToCreateOrg: "",
  weShouldBack: "我们还会再回来，若还发生错误，请您联系技术支持部门。",
  weak: "",
  weight: "",
  weightInKg: "",
  welcomeDescription: "",
  welcomeTo: "",
  welcomeToPouchPass: "",
  wellEmbarassing: "嗯，这是令人尴尬的。",
  wellEmbarassingDesc: "该事件已经超出预期，我们已经接到通知，正在检测该问题。",
  whoCanView: "",
  withFever: "发烧",
  workAddress: "",
  workContactNumber: "",
  worker: "",
  wouldBeReceivingInfo: "",
  yesDecline: "",
  yesLeave: "",
  youAreNotAuthorized: "您无权访问此页面。",
  youAreNotInAnyOrg: "",
  youAreNotInAnyOrgDesc: "",
  youCanOnlyUploadJpgPngGifFile: "",
  youDontHaveOrg: "",
  youMay: "",
  youReInvitedTo: "",
  youReachMaximumOTPAttemps: "",
  yourEmailAddress: "",
  yourPasswordHasBeenChanged: "",
};
export default Object.freeze(locale);
