const locale = {
  about: "",
  aboutYou: "",
  aboutYouDesc: "",
  acceptInvite: "",
  accessory: "",
  accessoryAlreadyPaired: "มีอุปกรณ์เสริมนี้การเชื่อมต่อกับผู้ใช้งานอื่นแล้ว",
  accessoryDetails: "รายละเอียดอุปกรณ์เสริม",
  accessoryId: "ID อุปกรณ์เสริม",
  accessoryIdAlreadyPaired: "",
  accessoryIdDoesNotBelongToOrganization: "",
  accessoryIdIsntAvailable: "",
  accessoryOrMacAddress: "",
  accessoryRemoved: "",
  accessoryStatus: "",
  accessoryType: "ประเภทอุปกรณ์เสริม",
  account: "",
  accountInformation: "",
  accountSettings: "",
  active: "เปิดการใช้งาน",
  activeAndSyncing: "เปิดใช้งานและซิงค์",
  activeMembers: "",
  activeOraganization: "",
  add: "เพิ่ม",
  addAMember: "",
  addANewMember: "",
  addANewUser: "เพิ่มผู้ใช้ใหม่",
  addAccessory: "เพิ่มอุปกรณ์เสริม",
  addAndManagerPouchBands: "",
  addMember: "",
  addMemberAndProfile: "",
  addMembers: "",
  addNew: "",
  addNewMembers: "",
  addPouchband: "",
  addUsers: "เพิ่มผู้ใช้งาน",
  addYourContactDetails: "",
  addYourPouchBand: "",
  additionalInfo: "",
  address: "",
  all: "ทั้งหมด",
  allSet: "",
  allTheInfoCollectedProfile: "",
  allowed: "",
  almostDoneEnterYourNewPassword: "",
  alreadyHaveAnAccount: "",
  alreadyInvitedAssignAsManager: "",
  alreadyInvitedPendingError: "",
  and: "",
  appStore: "",
  applyFilters: "ใช้ตัวกรอง",
  areYouSure: "",
  area: "พื้นที่",
  areaDescription: "",
  areaDuplicateName: "ชื่อพื้นที่นี้มีอยู่ในระบบอยู่แล้ว กรุณาเลือกชื่อใหม่",
  areaLocation: "สถานที่ / พื้นที่",
  areaLocationDesc: "",
  areaName: "",
  areas: "พื้นที่",
  asThePersonDescribed: "",
  askDeleteArea: "",
  askDeleteMember: "",
  askDeleteUser: "ลบผู้ใช้งาน?",
  askRemoveAccessory: "",
  askRemoveManager: "",
  askRemoveMember: "",
  askSaveChanges: "",
  askTheUserToWearPouchBand: "",
  askYouToWearPouchBand: "",
  assignAPouchBandToEachUser: "",
  assignAPouchBandToStartMonitorTheirTemp: "",
  assignAsManager: "",
  authorization: "",
  back: "",
  backTo: "",
  backToHome: "",
  backToLogin: "",
  basicInfo: "",
  birthdate: "",
  birthday: "",
  bloodType: "",
  bodyAche: "",
  bodyTemperature: "",
  bodyTemperatureDays: "",
  browsingYourFiles: "",
  cancel: "ยกเลิก",
  celciusSymbol: "",
  celsius: "เซลเซียส",
  change: "",
  changeAccessory: "เปลี่ยนอุปกรณ์เสริม",
  changePhoto: "",
  changeYourEmail: "",
  changesSaved: "",
  checkCredentials:
    "ตรวจสอบความถูกต้องของข้อมูลที่คุณให้ แต่หากยังเกิดข้อผิดพลาดอยู่ กรุณาติดต่อฝ่ายสนับสนุน",
  checkEmploymentDetails: "",
  checkWithYourAdminForDetails: "",
  checkingInternet: "กำลังตรวจสอบการเชื่อมต่ออินเทอร์เน็ตของคุณ",
  chestPains: "",
  chooseBloodType: "",
  chooseGender: "",
  chooseTypeTest: "",
  chooseWhichInformationToShare: "",
  clear: "เคลียร์",
  clearAllFilters: "",
  cm: "",
  company: "",
  companyPlaceholder: "",
  completeProfile: "",
  confirmDeleteMember: "",
  confirmNewPassword: "",
  confirmPassword: "",
  confirmRemoveManager: "",
  confirmRemoveMember: "",
  confirmRevokeManagerInvite: "",
  conjunctivitis: "",
  connectAPouchBandDesc: "",
  contactAndPersonalInfo: "",
  contactHistory: "",
  contactHistoryDesc: "",
  contactInformation: "",
  contactNumber: "",
  contactPersonsName: "",
  contactPouchPass: "",
  contactRelationship: "",
  contactUs: "",
  cough: "",
  countryCurrentlyResidingIn: "",
  createAccount: "",
  createAndManageUser: "",
  createArea: "",
  createMember: "",
  createPPaccount: "",
  createUser: "",
  createWorryFree: "",
  createYourFirstArea: "",
  createYourFirstAreaDesc: "",
  currentPassword: "",
  currentResidentialAddress: "",
  date: "วันที่",
  dateOfAvailabilityOfTestResults: "",
  dateOfContact: "",
  dateOfMedicalTest: "",
  decline: "",
  declineInvitation: "",
  declineInvitationDesc: "",
  delete: "",
  deleteArea: "",
  deleteLog: "",
  deleteMember: "",
  deleteSymptomsLog: "",
  deleteUser: "ลบผู้ใช้งาน",
  deletedMemeber: "",
  description: "",
  details: "",
  deviceStatus: "สถานะอุปกรณ์",
  diarrhea: "",
  diary: "",
  didntGetACode: "",
  didntReceiveAnything: "",
  difficultyBreathing: "",
  discardChanges: "",
  discardChangesMessage: "",
  discardTitle: "",
  dismiss: "",
  doThisLater: "",
  doYouWantToCreateYourOwnOrg: "",
  dontHavePouchBandYet: "",
  duration: "",
  easilyIdentifyYourProfile: "",
  edit: "",
  editArea: "แก้ไขพื้นที่",
  editLogSymptoms: "",
  editManager: "",
  editMember: "",
  editProfile: "",
  editSettings: "",
  editUser: "",
  egMedicalClinic: "",
  emailAddress: "",
  emailAddressAlreadyTaken: "",
  emailAddressAndMobileNumber: "",
  emailAddresses: "",
  emailAlreadyAssociatedWithThisOrganization: "",
  emailAlreadyExists: "",
  emergencyContact: "",
  emergencyContactDesc: "",
  emergencyContactPerson: "",
  employed: "",
  employeeDetails: "",
  employmentDetails: "",
  employmentDetailsDesc: "",
  employmentStatus: "",
  enterAnEmailAddress: "",
  enterNewPassword: "",
  enterOtpVerifyYourEmailAddress: "",
  enterPassword: "",
  everyoneIsDoingWell: "",
  everyoneIsDoingWellDesc: "",
  everyoneIsSafe: "",
  everyoneIsSafeDesc: "",
  export: "",
  extremelyHigh: "",
  fAQsPage: "",
  fahrenheit: "ฟาเรนไฮต์",
  failedToValidateEmail: "",
  fever: "มีไข้",
  feverCases: "เคสคนที่มีไข้",
  feverTrend: "",
  feverTrendOvertime: "เทรนด์ของอุณหภูมิที่ผ่านมา",
  fillInEmailAddresses: "",
  findOutHowCreateOrg: "",
  findOutHowNow: "",
  findOutMore: "",
  fingerToesDiscoloration: "",
  finishSettingUpAccount: "",
  firstName: "",
  forFurtherQuestions: "",
  forgotPassword: "",
  fullName: "",
  gateway: "",
  gatewayId: "",
  gatewayIdOrMacAddress: "",
  gatewayMacAddress: "",
  gatewayName: "",
  gatewayNamePlaceholder: "",
  gender: "",
  geolocation: "",
  geolocationDesc: "",
  goodJobAllUserActive: "",
  goodJobAllUserActiveDesc: "",
  great: "",
  hasNotBeenInContact: "",
  hasTheUserTakenAnyMedicalTest: "",
  headache: "",
  height: "",
  heightInCm: "",
  hereWhatHappening: "นี่คือสิ่งที่เกิดขึ้นกับ...",
  highRisk: "",
  highRiskDesc: "",
  highTemperature: "",
  higherThanNormal: "",
  hypothermia: "อุณหภูมิต่ำกว่าปกติ",
  iAgreeToPouchTerms: "",
  iAuthorizeOrg: "",
  ifAny: "",
  iformationIsImportantNarrative: "",
  imageMustBeSmallerThan: "",
  in: "",
  inactive: "ปิดการใช้งาน",
  inactiveDesc: "ผู้ใช้งานที่ยังไม่ได้ส่งข้อมูลการวัดอุณหภูมิเมื่อ 6 ชั่วโมงที่ผ่านมา",
  individualAtRiskDesc: "",
  individualsAtRisk: "",
  inputAccessoryId: "",
  invalidCurrentPassword: "",
  inviteExpiredDesc: "",
  inviteHasBeenSentTo: "",
  inviteManager: "",
  inviteManagerDescription: "",
  inviteMembers: "",
  inviteNewManager: "",
  invitePeopleToCreate: "",
  invitePouchPassDesc: "",
  invitePouchPassNote: "",
  inviteRequest: "",
  inviteRequestDesc: "",
  inviteRequestDescManager: "",
  inviteRequestDescMember: "",
  inviteSent: "",
  inviteSentDetails: "",
  inviteSentMemberDesc: "",
  inviteTeamMembersAndCreateArea: "",
  inviteTeamMembersAndCreateAreaDescription: "",
  inviteToPouchPass: "",
  invitedMembersWouldHave: "",
  invitesSent: "",
  invitingAMember: "",
  joinedCommunities: "",
  joinedOn: "",
  keepThisOrg: "",
  kg: "",
  lastDateOfContact: "",
  lastName: "",
  lastSynced: "ซิงค์ครั้งสุดท้าย",
  lastUpdated: "อัพเดทครั้งล่าสุด",
  latestTemp: "อุุณหภูมิล่าสุด",
  learnMore: "",
  leave: "",
  leaveOTPMessage: "",
  leaveOrganization: "",
  leavePage: "",
  leavePageDesc: "",
  lessDetails: "",
  liveMap: "",
  location: "สถานที่",
  logIn: "",
  logOtherSymptoms: "",
  logSymptoms: "",
  logTheirSymptomsNow: "",
  login: "",
  loginNow: "",
  loginTo: "",
  logout: "",
  lossOfSmellOrTaste: "",
  lossOfSpeechOrMovement: "",
  low: "",
  lowRisk: "",
  lowRiskDesc: "",
  lowTemperature: "",
  manager: "",
  managerRequest: "",
  me: "",
  medicalTest: "",
  member: "",
  memberDetails: "",
  memberDetailsPendingMessage: "",
  memberFromUntil: "",
  memberLocationPrivate: "",
  memberProfileOnly: "",
  memberProfileOnlyDesc: "",
  memberRequest: "",
  memberSince: "",
  memberStatus: "",
  members: "",
  mobileNumber: "",
  moderateRisk: "",
  moderateRiskDesc: "",
  monitorAnySymptoms: "",
  monitorAnySymptomsDescription: "",
  monitorYourBodyTemp: "",
  monitorYourBodyTempDesc: "",
  myOrganizationDescription: "",
  myOrganizations: "",
  name: "ชื่อ",
  nameAndProfilePhoto: "",
  nameOfClinicOrHospital: "",
  nameOfOtherTest: "",
  nameOfSpecificMedicalTest: "",
  nameRemoved: "",
  negative: "",
  networkErrorDesc:
    "อาจเป็นเพราะการเชื่อมต่อของคุณไม่เสถียร หรือการเข้าถึงของคุณถูกปฏิเสธ หรือมีความผิดปกติทางเรา",
  newPassword: "",
  newUserAdded: "",
  next: "",
  noAccessory: "",
  noAccessoryYet: "ยังไม่มีอุปกรณ์เสริม",
  noContactDetected: "",
  noData: "ไม่มีข้อมูล",
  noDevice: "ไม่มีอุปกรณ์",
  noLongerAManager: "",
  noMemberYet: "No members yet.",
  noMembersFound: "No members found.",
  noName: "ไม่มีชื่อ",
  noNeedForApp: "",
  noRecordsYet: "",
  noResults: "",
  noSymptomsLogged: "",
  noUserFound: "ไม่พบผู้ใช้งาน",
  noUserYet: "ยังไม่มีผู้ใช้งาน",
  normal: "ปกติ",
  notAllowed: "",
  notAtRisk: "",
  notRequired: "",
  ohNoYouDontHaveAccess: "",
  okay: "",
  onceYouLeaveOrg: "",
  oopsIncorrectEmailOrPass: "อุ๊ปส์! อีเมลหรือพาสเวิร์ดไม่ถูกต้อง",
  oopsSomethinngWentWrong: "",
  optional: "",
  or: "",
  orCheck: "",
  orderOne: "",
  orgYouManage: "",
  organizationSettings: "",
  otherLocation: "",
  ourAvailablePlans: "",
  overview: "",
  pageNotFound: "",
  pageNotFoundDesc: "",
  paired: "เชื่อมต่อแล้ว",
  pairingStatus: "สถานะการเชื่อมต่อ",
  password: "",
  passwordReset: "",
  passwordsDoNotMatch: "",
  pastOrganizations: "",
  pastOrganizationsDesc: "",
  past13Days: "ผ่านมาแล้ว 13 วัน",
  pendingInvites: "",
  pendingInvitesDesc: "",
  pendingMemberConfirmation: "",
  permanentResidence: "",
  permissions: "",
  personalInformation: "",
  playStore: "",
  pleaseCheckForTheOneTimePasswordWeSentTo: "",
  pleaseCheckYourEmailAndClickMessage: "",
  pleaseContactOurSupport: "",
  pleaseContactPouchPass: "",
  pleaseEnterAnEmailInFormat: "",
  pleaseEnterAtLeastOneEmail: "",
  pleaseFillUpArea: "",
  pleaseFillUpSymptom: "",
  pleaseFillUpUser: "",
  pleaseInputCurrentPassword: "",
  pleaseRequestThePersonProfile: "",
  pleaseTryAgain: "",
  pleaseUseAtleastNthCharacters: "",
  poor: "",
  positive: "",
  pouchBand: "",
  pouchBandDescription: "",
  pouchBandForEveryone: "",
  pouchBandStep1: "",
  pouchBandStep2: "",
  pouchBands: "",
  pouchnationPhilippines: "",
  privacyPolicy: "",
  privacySymptomsWarning: "",
  private: "",
  privateInformation: "",
  privateOnlyMe: "",
  profile: "",
  profileAvatarSaved: "",
  profileOnly: "",
  profileSettings: "",
  rashes: "",
  recordAndMonitorYourSymptoms: "",
  recovingWithANormalTemp: "",
  refreshPage: "กำลังรีเฟรชหน้าเพจ",
  registrationStart: "",
  relationshipPlaceholder: "",
  rememberMe: "",
  rememberYourPassword: "",
  rememberYourPasswordQuestion: "",
  remove: "ลบ",
  removeAccessory: "ลบอุปกรณ์เสริม",
  removeAccessoryDesc: "",
  removeAsManager: "",
  removeFromOrganization: "",
  removeManagerSuccess: "",
  removeMember: "",
  removed: "",
  removedMember: "",
  replacePhoto: "",
  required: "",
  resendANewOtp: "",
  resendInvite: "",
  resendInviteSuccess: "",
  resendNow: "",
  resetLinkResent: "",
  resetLinkSent: "",
  resetPassword: "",
  resetTokenExpired: "",
  retry: "",
  retypePassword: "",
  reviewTheInformation: "",
  revoke: "",
  revokeInvite: "",
  revokeInviteSuccess: "",
  riskLevel: "",
  role: "",
  roleAndPermissions: "",
  sampleAreaDescription: "เช่น ชั้น 2 ฝั่ง East Wing อาคาร Diego Silang",
  sampleAreaName: "เช่น ห้อง 2306.",
  save: "",
  saveAndSendNotif: "",
  saveChanges: "บันทึกการเปลี่ยนแปลง",
  savePermissions: "",
  searchByUserOrLocation: "",
  searchMemberByNameOrAccessoryId: "",
  searchUserByNameOrAccessoryId: "ค้นหาผู้ใช้งานด้วยชื่อหรือ ID อุปกรณ์เสริม",
  seconds: "",
  sections: "",
  seeMore: "",
  selectARole: "",
  selectBloodType: "",
  selectCountry: "",
  selectEmploymentStatus: "",
  selectFollowingSymptoms: "",
  selectGender: "",
  selectUserTestResult: "",
  selfEmployed: "",
  sendInvite: "",
  sendResetLink: "",
  settings: "",
  setupProfileLater: "",
  sharedInformation: "",
  shopNow: "",
  shopPouchBand: "",
  signIn: "",
  signingUpAggreement: "",
  someEmailsNotRecognized: "",
  somethingWentWrong: "",
  soreThroat: "",
  sorryYouAreNotAuthToAccessThisPage: "",
  startMonitorTeam: "เพื่อเริ่มตรวจสอบสถานะทีมคุณ",
  startTour: "",
  startYourPouchBand: "",
  startbyUploading: "",
  statistics: "",
  stayOnThisPage: "",
  stayOnTopOfYourHealthAndGetNotified: "",
  stayOnTopOfYourHealthAndGetNotifiedDesc: "",
  stepNth: "",
  successChangedPassword: "",
  symptomLoggedToDiary: "",
  symptomNotFound: "",
  symptoms: "",
  symptomsDescription: "",
  takeNoteOfTheMedicalTestResultHere: "",
  teamAndRoles: "",
  teamManagers: "",
  tellUsMoreAboutYourself: "",
  temp: "",
  tempCases: "",
  tempHasBeenIncreasingForThePastDays: "",
  temperature: "อุณหถูมิ",
  temperatureCases: "",
  temperatureStatus: "",
  temperatureTrend: "",
  termsAndConditions: "",
  testResultSummaryInfo: "",
  testResults: "",
  testedNegativeWithCovid: "",
  testedPositiveWithCovid: "",
  theOTPYouEnteredIsInvalid: "",
  theyllBeAdded: "",
  thisEmailIsAlreadyAMemberOfYourOrg: "",
  thisMember: "",
  thisUserDoesNotExistOn: "",
  time: "เวลา",
  tiredness: "",
  to: "",
  toLearnAboutPouchPass: "",
  today: "วันนี้",
  totalMembers: "",
  trackEncounter: "",
  trackEncounterDescription: "",
  try: "ลองใหม่",
  trySearchingAgain: "",
  tryToSearchAgain: "ลองค้นหาใหม่อีกครั้ง",
  typeOfMedicalTest: "",
  uhOhEmailNotRegistered: "อีเมลนี้ยังไม่ได้ลงทะเบียน",
  unableToLocate: "",
  unableToLocateMember: "",
  unableToLocateUser: "ไม่พบผู้ใช้งาน",
  unableToRemoveAccessory: "",
  unableToRetrieveData: "",
  unableToRetrieveDataDesc: "",
  unauthorized: "ไม่ได้รับสิทธิ์",
  unbaleToUploadMoreThanMb: "",
  unemployed: "",
  unfortunatelyThisInviteIsExpired: "",
  unknowUserContactInformation: "",
  unknowUserUserId: "",
  unverifiedMember: "",
  unverifiedMembersDesc: "",
  uploadAProfile: "",
  uploadCovidTestCerti: "",
  uploadFile: "",
  uploadPhoto: "",
  uploadPhotoDescription: "",
  uploadProfile: "",
  uploadTestCertificate: "",
  usePouchPassForYourFamilyOrBusiness: "",
  usePouchPassForYourFamilyOrBusinessDesc: "",
  userHasPendingInvite: "",
  userIsNowAManager: "",
  users: "ผู้ใช้งาน",
  usingAMixOfUppercaseAndLowercase: "",
  validateEmailAddress: "",
  verifiedMember: "",
  verifiedMembersDesc: "",
  verifyEmail: "",
  verifyEmailAddress: "",
  veryHigh: "",
  veryLowRisk: "",
  veryLowRiskDesc: "",
  view: "",
  viewAll: "ดูทั้งหมด",
  viewAllAreas: "ดููพื้นที่ทั้งหมด",
  viewAndAddSymptoms: "",
  viewInvite: "",
  viewMyActiveOrg: "",
  viewMyOrgs: "",
  viewProfile: "",
  viewTestResults: "",
  viewYourContactInformation: "",
  viewYourNameAndProfilePhoto: "",
  visitOur: "",
  wantToCreateOrg: "",
  weShouldBack: "เราจะกลับมาใหม่อีกครั้ง แต่หากยังเกิดข้อผิดพลาดอยู่ กรุณาติดต่อฝ่ายสนับสนุน",
  weak: "",
  weight: "",
  weightInKg: "",
  welcomeDescription: "",
  welcomeTo: "",
  welcomeToPouchPass: "",
  wellEmbarassing: "นี่มันน่าอายจริงๆ",
  wellEmbarassingDesc:
    "เรารู้ว่าเหตุการณ์นี้ไม่ได้คาดไว้ แต่เราได้รับแจ้งและกำลังตรวจสอบปัญหาที่เกิดขึ้นแล้ว",
  whoCanView: "",
  withFever: "มีไข้",
  workAddress: "",
  workContactNumber: "",
  worker: "",
  wouldBeReceivingInfo: "",
  yesDecline: "",
  yesLeave: "",
  youAreNotAuthorized: "คุณไม่ได้รับสิทธิ์ให้เข้าหน้านี้",
  youAreNotInAnyOrg: "",
  youAreNotInAnyOrgDesc: "",
  youCanOnlyUploadJpgPngGifFile: "",
  youDontHaveOrg: "",
  youMay: "",
  youReInvitedTo: "",
  youReachMaximumOTPAttemps: "",
  yourEmailAddress: "",
  yourPasswordHasBeenChanged: "",
};
export default Object.freeze(locale);
