import React, { useMemo, useContext } from "react";
import { Details } from "components/overview";
import classnames from "classnames";
import localStrings from "localization";
import styles from "./past-days-details.module.scss";
import { formatTemperature, isNormalTemp } from "utils";
import { v4 as uuidv4 } from "uuid";
import { ViewUserContext } from "contexts";
import moment from "moment";
import Skeleton from "react-loading-skeleton";
const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "date",
    title: localStrings.date,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "temperature",
    title: localStrings.temperature,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "location",
    title: localStrings.areaLocation,
    width: 100,
    ellipsis: true,
  },
];

const PastDaysDetails = ({ className, locations }) => {
  const { state, dispatch } = useContext(ViewUserContext);
  const { pastDayDetails } = state;
  const { data, loading, error } = pastDayDetails;

  const tableData = useMemo(() => {
    return data.map((d) => {
      const { bodyTemp, day, latitude, longitude, location: areaLocation, areaId } = d;
      const location = (() => {
        const l = [latitude, longitude].join(",");
        const hasLocation = Boolean(latitude && longitude);
        if (areaId) {
          return areaLocation || "-";
        }
        if (!locations[l] || locations[l].loading) {
          if (hasLocation) {
            return <Skeleton />;
          } else {
            return "-";
          }
        } else {
          return locations[l].location;
        }
      })();
      return {
        key: uuidv4(),
        date: day ? moment(day, "MM/DD/YYYY").format("MMM DD, YYYY") : "-",
        temperature: (
          <div className={classnames({ cDanger: !isNormalTemp(bodyTemp) }, styles.temperature)}>
            {formatTemperature(bodyTemp)}
          </div>
        ),
        location,
      };
    });
  }, [data, locations]);

  const emptyRenderer = useMemo(() => {
    return {
      title: "No Data",
    };
  }, []);

  const onRowClick = (index) => {
    const { day } = data[index];
    const title = moment(day, "MM/DD/YYYY").format("MMM DD, YYYY");
    dispatch({
      type: "SHOW_PAST_DAY_MODAL",
      title,
      date: moment(day, "MM/DD/YYYY").format("MM/DD/YYYY"),
    });
  };

  return loading || data.length || error ? (
    <Details
      className={classnames(className, styles.container)}
      title={localStrings.past13Days}
      columns={tableColumns}
      loading={loading}
      data={tableData}
      scroll={{
        y: 520,
      }}
      emptyRenderer={emptyRenderer}
      onRowClick={onRowClick}
      error={error}
    />
  ) : null;
};

export default PastDaysDetails;
