import React, { useContext } from "react";
import { Avatar, IconLink } from "atomic/molecules";
import styles from "./user-menu.module.scss";
import { ellipsis } from "utils";
import { UserContext } from "contexts";
import classnames from "classnames";

const UserMenu = ({ visible, options = [], organizationName }) => {
  const { user } = useContext(UserContext);
  const { firstName, lastName, avatar } = user;
  return (
    <div
      className={classnames(styles.container, {
        [`${styles.visible}`]: visible,
      })}
    >
      <div className={styles.user}>
        <div>
          <Avatar className={styles.image} phrases={[firstName, lastName]} image={avatar} />
          <p className={styles.name}>{ellipsis(`${firstName} ${lastName}`, 20)}</p>
          {organizationName && <p className={styles.organization}>{organizationName}</p>}
        </div>
      </div>
      <div className={styles.menu}>
        {options.length > 0 &&
          options.map((o, i) => (
            <IconLink
              key={i}
              className={classnames(styles.link, {
                [`${styles.divider}`]: o.divide,
                [`${styles.highlighted}`]: o.highlighted,
              })}
              icon={o.icon}
              to={o.path}
              onClick={o.onClick}
              notif={o.notif}
            >
              <span
                className={classnames({
                  [`${styles.highlighted}`]: o.highlighted,
                })}
              >
                {o.text}
              </span>
            </IconLink>
          ))}
      </div>
    </div>
  );
};

export default UserMenu;
