import React, { useContext } from "react";
import { Routes } from "enums";
import { NotificationContext, UserContext } from "contexts";
import { HeaderNavigation } from "atomic/organisms";
import localStrings from "localization";

const MemberHeader = ({ logout, loading }) => {
  const notifications = useContext(NotificationContext);
  const user = useContext(UserContext);
  const isAdmin = user.user?.userPermission?.permissionNames.length;
  const tabs = [];

  const menuOptions = [
    { text: "My Dashboard", icon: "dashboard-file", path: "/" },
    {
      text: "Manage Communities",
      icon: "community-building",
      path: `/${Routes.ORGANIZATION}`,
      notif: notifications.notificationCount,
    },
    {
      text: `${localStrings.settings}`,
      icon: "settings",
      path: `/${Routes.SETTINGS}`,
      notif: notifications.unreadInviteRequestCount || notifications.hasOrganizationPrivacyUpdate,
    },
    ...(isAdmin
      ? [
          {
            text: "PouchPASS Admin",
            icon: "pouchnation",
            path: `/${Routes.ADMIN}`,
            divide: true,
            highlighted: true,
          },
        ]
      : []),
    { text: `${localStrings.logout}`, icon: "logout", onClick: logout, divide: true },
  ];

  return <HeaderNavigation tabs={tabs} menuOptions={menuOptions} loading={loading} />;
};

export default MemberHeader;
