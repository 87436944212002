import React from "react";
import styles from "./translation.module.scss";
import { environment } from "environments/environment";
// import classnames from "classnames";

import pending from "localization/pending";

const Translation = () => {
  const lang = Object.keys(pending);
  const keys = lang.map((l) => {
    return [l, pending[l], "No", "No", "Yes"];
  });

  console.log(keys);

  const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
  console.log(environment);
  if (environment.id !== "dev") {
    return null;
  }
  return (
    <div className={styles.container}>
      <table>
        {lang.map((l) => {
          return (
            <tr className={styles.row}>
              <td>{camelToSnakeCase(l)}</td>
              <td>{pending[l]}</td>
              <td>No</td>
              <td>No</td>
              <td>Yes</td>
            </tr>
          );
        })}
      </table>
    </div>
  );
};

export default Translation;
