import React, { useState, useEffect, useContext, useCallback } from "react";
import styles from "./register-user-form.module.scss";
import localStrings from "localization";
import PouchPass from "images/pouch-pass-logo.svg";
import initialFormState from "./initial-form-state";
import { useForm, useRouter } from "hooks";
import { countryDialingCodeOptions, getCountry } from "utils/countries";
import classnames from "classnames";
import { LocalTranslation } from "localization/localization";
import { login, logout } from "api/auth";
import { validateEmail, validateOtp } from "api/user";
import { validateActivateToken, activateUser } from "api/user-organization";
import { AppContext } from "contexts";
import { OTPFormModal } from "components/auth";
import { debounced, isLoggedIn, redirectTo } from "utils";
import path from "path/path";
import { Image, Icon, PasswordStrength } from "atomic/atoms";
import { InputField, SelectField, Button, InputFieldPassword } from "atomic/molecules";

const Suffix = ({ loading, emailForm, retry }) => {
  if (loading) {
    return <Icon loading={loading} />;
  }
  if (emailForm.custom.error) {
    return (
      <div className={styles.retry} onClick={retry}>
        {localStrings.retry}
      </div>
    );
  }

  return null;
};

const RegisterUserForm = () => {
  const form = useForm(initialFormState());
  const { dispatch: appDispatch } = useContext(AppContext);
  const {
    isFormValid,
    formFieldsValue,
    formFields,
    setFormFieldValue,
    setCustomField,
    submit,
  } = form;
  const { query, history } = useRouter();
  const { token } = query || {};
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const countryCodes = countryDialingCodeOptions();
  const [otpModal, setOtpModal] = useState(false);
  const [validatingEmail, setValidatingEmail] = useState(false);

  const validateEmailSubmit = (email = false) => {
    setValidatingEmail(true);
    setCustomField(
      {
        existing: [],
        error: false,
      },
      "email"
    );
    validateEmail({
      email: `${email || formFieldsValue.email || ""}`.trim(),
    })
      .then((d) => {
        setValidatingEmail(false);
        if (d) {
          setCustomField(
            {
              existing: [formFieldsValue.email.trim()],
              error: false,
            },
            "email"
          );
        }
      })
      .catch(() => {
        setValidatingEmail(false);
        setCustomField(
          {
            existing: [],
            error: true,
          },
          "email"
        );
      });
  };

  useEffect(
    debounced(1000, () => {
      if (formFieldsValue.email && formFields.email.dirty && !formFields.email.error?.status) {
        validateEmailSubmit();
      }
    }),
    [formFieldsValue.email, formFields.email.dirty]
  );

  const showError = useCallback(
    (err) => {
      const { networkError, serverError, metadata } = err;

      if (metadata) {
        if (metadata.code === "8024") {
          appDispatch({ type: "INVITE_EXPIRED_ERROR" });
          return false;
        }
      }

      if (serverError) {
        appDispatch({ type: "SERVER_ERROR" });
      }
      if (networkError) {
        appDispatch({ type: "NETWORK_ERROR" });
      }
    },
    [appDispatch]
  );

  useEffect(() => {
    if (query.email && !formFieldsValue.email && !formFields.email?.dirty) {
      setFormFieldValue(query.email, "email");
    }
  }, [query.email, setFormFieldValue, formFieldsValue.email, formFields.email, appDispatch]);

  const handleCloseOTPModal = () => {
    setOtpModal(false);
  };

  const handleOnPhoneCodeChange = useCallback(
    (value, name) => {
      const { code } = getCountry(value);
      setFormFieldValue(value, name);
      setFormFieldValue(code, "countryCode");
    },
    [setFormFieldValue]
  );

  const otpCallback = useCallback(
    async (otpRes) => {
      const {
        firstName,
        lastName,
        email,
        password,
        phoneCountryCode,
        phone,
        countryCode,
      } = formFieldsValue;
      if (otpRes === "PROFILE_CREATED") {
        await login({ email, password });
      }
      if (otpRes === "approved" || otpRes === "VALIDATED_ONLY") {
        await activateUser({
          token,
          email,
          firstName,
          lastName,
          phoneCountryCode,
          phone,
          passwordRequest: {
            password,
          },
          validated: true,
          validationMethod: ["EMAIL"],
          countryCode,
        });
      }
      if (isLoggedIn()) {
        await logout();
        history.push(`${path.AUTH}?email=${email}`);
      } else {
        await login({ email: formFieldsValue.email, password: formFieldsValue.password });
        redirectTo("/");
      }
    },
    [formFieldsValue, history, token]
  );

  return (
    <div className={styles.container}>
      <OTPFormModal
        onClose={handleCloseOTPModal}
        visible={otpModal}
        email={formFieldsValue.email}
        firstName={formFieldsValue.firstName}
        successOtpCallback={otpCallback}
      />
      <div className={styles.header}>
        <h1>{localStrings.welcomeTo}</h1>
        <Image src={PouchPass} className={styles.logo} />
      </div>
      <p>{localStrings.registrationStart}</p>
      <div className={styles.content}>
        <div className={styles.field}>
          <InputField
            name="email"
            placeholder={localStrings.emailAddress}
            className={styles.input}
            onChange={(value, name) => {
              setFormFieldValue(value, name);
            }}
            {...formFields.email}
            renderSuffix={
              <Suffix
                loading={validatingEmail}
                emailForm={formFields.email}
                retry={() => validateEmailSubmit()}
              />
            }
          />
        </div>
        <div className={styles.field}>
          <InputField
            name="firstName"
            placeholder={localStrings.firstName}
            className={styles.input}
            onChange={setFormFieldValue}
            {...formFields.firstName}
          />
        </div>
        <div className={styles.field}>
          <InputField
            name="lastName"
            placeholder={localStrings.lastName}
            className={styles.input}
            onChange={setFormFieldValue}
            {...formFields.lastName}
          />
        </div>
        <div className={styles.field}>
          <div className={styles.inputMobileNumber}>
            <div>
              <SelectField
                classNameContainer={classnames(styles.mobileNumber)}
                data={countryCodes}
                name="phoneCountryCode"
                onChange={handleOnPhoneCodeChange}
                {...formFields.phoneCountryCode}
                placeholder="+63"
                showSearch
              />
            </div>
            <div>
              <InputField
                name="phone"
                onChange={setFormFieldValue}
                {...formFields.phone}
                placeholder="916 4567 890"
              />
            </div>
          </div>
        </div>
        <div className={styles.field}>
          <InputFieldPassword
            name="password"
            placeholder={localStrings.enterNewPassword}
            className={classnames(styles.input)}
            {...formFields.password}
            onChange={(value, name) => {
              setFormFieldValue(value, name);
              setCustomField(value, "confirmPassword");
            }}
            onShowToggleChange={(value) => {
              setShowPassword(value);
            }}
            shouldShowToggle
          />
        </div>
        <PasswordStrength className={styles.passwordStrength} password={formFieldsValue.password} />
        <div className={styles.field}>
          <InputFieldPassword
            name="confirmPassword"
            placeholder={localStrings.confirmPassword}
            className={styles.input}
            {...formFields.confirmPassword}
            onChange={setFormFieldValue}
            showPassword={showPassword}
          />
        </div>
      </div>
      <div className={styles.terms}>
        <LocalTranslation
          text={localStrings.signingUpAggreement}
          items={[
            <a
              rel="noopener noreferrer"
              href={path.BUSINESS_TERMS}
              className={styles.termsLink}
              target="_blank"
            >
              {localStrings.termsAndConditions}
            </a>,
            <a
              rel="noopener noreferrer"
              href={path.BUSINESS_PRIVACY}
              className={styles.termsLink}
              target="_blank"
            >
              {localStrings.privacyPolicy}.
            </a>,
          ]}
        />
      </div>
      <div className={styles.submit}>
        <Button
          loading={loading}
          onClick={() =>
            submitForm({
              submit,
              setLoading,
              setOtpModal,
              showError,
              isFormValid,
              formFieldsValue,
              token,
              history,
            })
          }
          text={localStrings.createAccount}
        />
      </div>
      <div className={styles.loginNow}>
        {localStrings.alreadyHaveAnAccount}
        <a href="/">{localStrings.loginNow}</a>
      </div>
    </div>
  );
};

const submitForm = async ({
  submit,
  setLoading,
  setOtpModal,
  showError,
  isFormValid,
  token,
  formFieldsValue,
  history,
}) => {
  const {
    email,
    firstName,
    lastName,
    phoneCountryCode,
    phone,
    password,
    countryCode,
  } = formFieldsValue;
  submit();
  if (isFormValid) {
    setLoading(true);
    try {
      const { data: isSameEmail } = await validateOtp({ token, email });
      if (isSameEmail) {
        await activateUser({
          token,
          email,
          firstName,
          lastName,
          phoneCountryCode,
          phone,
          passwordRequest: {
            password,
          },
          validated: true,
          validationMethod: ["EMAIL"],
          countryCode,
        });
        await login({ email, password });
        history.push("/");
      } else {
        await validateActivateToken({ token });
        setOtpModal(true);
      }
    } catch (e) {
      showError(e);
    } finally {
      setLoading(false);
    }
  }
};

export default RegisterUserForm;
