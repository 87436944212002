import { ApiRoutes, Routes } from "enums";
import { Delete, Get, Post } from "./api";

export const createSymptomLog = async ({ userId, params }) => {
  const res = await Post(`${ApiRoutes.SYMPTOM}/${Routes.USER}/${userId}`, params);
  return res;
};

export const updateSymptomLog = async ({ symptomLogId, userId, params }) => {
  const res = await Post(`${ApiRoutes.SYMPTOM}/${symptomLogId}/${Routes.USER}/${userId}`, params);
  return res;
};

export const deleteSymptomLog = async ({ symptomId }) => {
  const res = await Delete(`${ApiRoutes.SYMPTOM}/${symptomId}`);
  return res;
};

export const getSymptomLog = async ({ symptomId }) => {
  const res = await Get(`${ApiRoutes.SYMPTOM}/${symptomId}`);
  return res;
};

export const searchSymptoms = async ({ userId, organizationId, showManagerCreated }) => {
  const res = await Post(`${ApiRoutes.SYMPTOM}/${Routes.SEARCH}/${Routes.USER}/${userId}`, {
    organizationId,
    showManagerCreated,
  });
  return res;
};
