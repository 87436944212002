import enumeration from "./enumeration";

const TimeUnit = {
  SECOND: "SECOND",
  MINUTE: "MINUTE",
  HOUR: "HOUR",
  DAY: "DAY",
  WEEK: "WEEK",
  MONTH: "MONTH",
  YEAR: "YEAR",
  CUSTOM: "CUSTOM",
  ANYTIME: "ANYTIME",
};

export default enumeration(TimeUnit);
