import React, { useEffect, useRef, memo } from "react";
import classnames from "classnames";
import initMap from "./init-map";
import styles from "./map.module.scss";

const Map = ({
  className,
  marker,
  markers,
  controls,
  initCenter,
  initMarkerCluster,
  initInfoWindow,
  setCenter,
  onCenterChange = null,
  onZoomChange = null,
  draggable = true,
  zoom,
  showClusterBreakdown,
  circle,
}) => {
  const googleMapRef = useRef(null);
  const preparedMarkersRef = useRef(null);
  const markerClusterRef = useRef(null);
  const infoWindowsRef = useRef(null);

  // Init map
  useEffect(() => {
    googleMapRef.current = initMap({
      controls,
      initCenter,
      onCenterChange,
      onZoomChange,
      draggable,
      circle,
      zoom,
    });
  }, [initCenter, controls, onCenterChange, onZoomChange, draggable, circle, zoom]);

  useEffect(() => {
    function clearMarkers() {
      if (preparedMarkersRef.current) {
        preparedMarkersRef.current.map((marker) => {
          return marker.setMap(null);
        });
      }
    }
    clearMarkers();

    const initSingleMarker = () => {
      const { map, Marker } = googleMapRef.current;
      const { lat, lng } = marker.position;

      if (lat && lng && marker) {
        new Marker({
          map,
          ...marker,
        });
        map.setCenter(marker.position);
      }
    };

    const initMultipleMark = () => {
      const { map, Marker, LatLng } = googleMapRef.current;
      preparedMarkersRef.current = markers.map((marker) => {
        const { lat, lng, icon, label, ...rest } = marker;
        return new Marker({
          position: new LatLng(lat, lng),
          icon,
          map,
          label,
          ...rest,
        });
      });
    };

    if (marker) {
      initSingleMarker();
    }
    if (markers && googleMapRef.current) {
      initMultipleMark();
    }
  }, [markers, marker]);

  // Init Marker Cluster
  useEffect(() => {
    if (initMarkerCluster) {
      const { map, MarkerClusterer, google } = googleMapRef.current;
      const { current: markerCluster } = markerClusterRef;
      if (markerCluster) {
        markerCluster.clearMarkers();
      }
      markerClusterRef.current = initMarkerCluster({
        map,
        MarkerClusterer,
        markers: preparedMarkersRef.current,
      });
      google.maps.event.addListener(markerClusterRef.current, "clusterclick", function (cluster) {
        if (cluster) {
          if (showClusterBreakdown) {
            showClusterBreakdown(cluster);
          }
        }
      });
    }
  }, [markers, initMarkerCluster, showClusterBreakdown]);

  useEffect(() => {
    const { map } = googleMapRef.current;
    if (zoom) {
      map.setZoom(zoom);
    }
  }, [zoom]);

  // Init Info Window
  useEffect(() => {
    const { InfoWindow, google, map } = googleMapRef.current;
    const closeAllInfoWindows = () => {
      if (infoWindowsRef.current) {
        infoWindowsRef.current.forEach((infoWindow) => {
          infoWindow.close();
        });
      }
    };

    if (initInfoWindow && preparedMarkersRef.current) {
      infoWindowsRef.current = initInfoWindow({
        markers: preparedMarkersRef.current,
        InfoWindow,
      });

      infoWindowsRef.current.forEach((infoWindow, i) => {
        google.maps.event.addListener(preparedMarkersRef.current[i], "click", function () {
          closeAllInfoWindows();
          infoWindow.open(map, preparedMarkersRef.current[i]);
        });
      });
    }
    map.addListener("center_changed", closeAllInfoWindows);
  }, [initInfoWindow, markers]);

  useEffect(() => {
    if (setCenter) {
      setCenter(googleMapRef.current.map);
    }
  }, [setCenter]);

  return <div id="map" className={classnames(className, styles.map)}></div>;
};

export default memo(Map);
