import React from "react";
import { Details } from "components/overview";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import { Common, Routes } from "enums";

import AllUserActive from "images/all-user-active-2.svg";

const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "name",
    title: localStrings.name,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "lastSynced",
    title: localStrings.lastSynced,
    width: 100,
  },
];

const InactiveDetails = ({ className, data, total, error, loading }) => {
  const noData = {
    title: localStrings.goodJobAllUserActive,
    description: localStrings.goodJobAllUserActiveDesc,
    image: AllUserActive,
  };

  return (
    <Details
      className={className}
      title={localStrings.inactive}
      viewAllText={localStrings.viewAll}
      viewAllCount={total}
      viewAllLink={`/${Routes.MEMBERS}`}
      viewAllLocationState={{ deviceStatus: Common.INACTIVE }}
      description={localStrings.inactiveDesc}
      columns={tableColumns}
      data={data}
      loading={loading}
      noData={noData}
      error={error}
    />
  );
};

export default InactiveDetails;
