import React, { Fragment, useContext, useMemo } from "react";
import { useRouter } from "hooks";
import styles from "./my-organization-details.module.scss";
import { formatToLocalDate } from "utils";
import localString from "localization";
import { RoleContext } from "contexts";
import path from "path/path";
import { Loading } from "atomic/atoms";
import PrivacySettingsForm from "../privacy-settings-form/privacy-settings-form";
import PrivacyChanges from "./privacy-changes/privacy-changes";
import { Panel, BackLink, Avatar, Button } from "atomic/molecules";
import moment from "moment";

const MyOrganizationDetails = ({
  organization,
  loading,
  orgLocation,
  leaveModal,
  privacyChanges,
}) => {
  const { history, query } = useRouter();
  const role = useContext(RoleContext);
  const { organizationId } = query || {};
  const { organizationName, organizationLogo, dateJoined, organizationContactNumber } =
    organization || {};

  const renderPrivacyChanges = useMemo(() => {
    if (privacyChanges && moment().isBefore(moment(privacyChanges?.startDate))) {
      return <PrivacyChanges privacyChanges={privacyChanges} />;
    }
    return null;
  }, [privacyChanges]);

  return (
    <Fragment>
      <Panel>
        <div className={styles.header}>
          <BackLink
            className={styles.backLink}
            onClick={() => history.push(path.SETTINGS_MY_ORGANIZATION)}
          />
          <Avatar className={styles.orgAvatar} image={organizationLogo} />
          <div>
            <div className={styles.orgName}>{organizationName}</div>
            <div className={styles.subHeader}>
              <div className={styles.role}>{String(role).toLowerCase()}</div>
              {dateJoined && <div className={styles.circleSeparator}></div>}
              {dateJoined && (
                <div className={styles.dateJoined}>
                  Joined on {formatToLocalDate(dateJoined, "MMM DD, YYYY")}
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {privacyChanges &&
          moment().isBefore(
            moment(privacyChanges?.startDate, "YYYY-MM-DD hh:mm:ss").startOf("day")
          ) && <PrivacyChanges privacyChanges={privacyChanges} />} */}
        {renderPrivacyChanges}

        <div className={styles.subInfoContainer}>
          {/* <div className={styles.subInfo}>
            <div className={styles.subInfoTitle}>{localString.details}</div>
            <div className={styles.subInfoDetail}>
              {localString.memberSince} {formatToLocalDate(dateJoined, "MMM DD, YYYY")}
            </div>
          </div> */}
          {organizationContactNumber && (
            <div className={styles.subInfo}>
              <div className={styles.subInfoTitle}>{localString.contactNumber}</div>
              <div className={styles.subInfoDetail}>
                {organizationContactNumber ? organizationContactNumber : ""}
              </div>
            </div>
          )}
          <div className={styles.subInfo}>
            <div className={styles.subInfoTitle}>{localString.location}</div>
            {loading ? (
              <Loading height="100%" width="100%" />
            ) : (
              <div className={styles.subInfoDetail}>{orgLocation}</div>
            )}
          </div>
        </div>
        <PrivacySettingsForm organizationId={organizationId} />
      </Panel>
      <Panel className={styles.leaveOrg}>
        <div className={styles.leaveOrgInfo}>
          <div className={styles.leaveOrgName}>{`Leave ${organizationName}`}</div>
          <div className={styles.leaveOrgDescription}>{localString.onceYouLeaveOrg}</div>
        </div>
        <Button
          className={styles.leaveButton}
          danger
          onClick={() => {
            leaveModal(true, organization);
          }}
        >
          {localString.leave}
        </Button>
      </Panel>
    </Fragment>
  );
};

export default MyOrganizationDetails;
