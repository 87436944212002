import React, { Fragment } from "react";
import styles from "./overview-status-count.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import { Loading } from "atomic/atoms";
import { UnableToRetrieve } from "atomic/organisms";
import { environment } from "../../../environments/environment";

const OverviewStatusCount = ({
  loading,
  className,
  error,
  usersWithFever,
  activeUsers,
  inactiveUsers,
  organizationId,
}) => {
  return (
    <>
      <div className={classnames(styles.container, className)}>
        {error ? (
          <UnableToRetrieve noDesc className={styles.unableToRetrieve} />
        ) : (
          <Fragment>
            <div className={classnames(styles.countContainer)}>
              <h2 className={styles.title}>{localStrings.active}</h2>
              <p className={styles.count}>{loading ? <Loading /> : activeUsers}</p>
            </div>
            <div className={classnames(styles.countContainer, styles.danger)}>
              <h2 className={styles.title}>{localStrings.tempCases}</h2>
              <p className={styles.count}>{loading ? <Loading /> : usersWithFever}</p>
            </div>
            <div className={classnames(styles.countContainer, styles.inactive)}>
              {environment.id === "dev" ||
              environment.id === "staging" ||
              (environment.id === "production" && organizationId === 88) ? (
                <h2 className={styles.title}>{localStrings.violationsCases}</h2>
              ) : (
                <h2 className={styles.title}>{localStrings.inactive}</h2>
              )}
              <p className={styles.count}>{loading ? <Loading /> : inactiveUsers}</p>
            </div>
          </Fragment>
        )}
      </div>
    </>
  );
};

export default OverviewStatusCount;
