export const profileSettingsFormState = {
  firstName: {
    value: "",
    required: true,
  },
  lastName: {
    value: "",
    required: true,
  },
  dob: {
    value: null,
    required: false,
  },
  gender: {
    value: "",
    required: false,
  },
  avatar: {
    value: "",
    required: false,
  },
  address: {
    value: "",
    required: false,
    maxLength: 255,
  },
  company: {
    value: "",
    required: false,
  },
  employmentStatus: {
    value: "",
    required: false,
  },
  workAddress: {
    value: "",
    required: false,
    maxLength: 255,
  },
  workCountryCode: {
    value: "",
    required: false,
  },
  workPhone: {
    value: "",
    required: false,
    maxLength: 12,
    number: true,
    phone: true,
  },
  bloodType: {
    value: "",
    required: false,
  },
  height: {
    value: "",
    required: false,
    number: true,
    max: 500.0,
    min: 1,
    decimal: 2,
  },
  weight: {
    value: "",
    required: false,
    number: true,
    max: 999.99,
    min: 1,
    decimal: 2,
  },
  contactName: {
    value: "",
    required: false,
  },
  contactRelationship: {
    value: "",
    required: false,
  },
  contactNumber: {
    value: "",
    required: false,
    maxLength: 12,
    number: true,
    phone: true,
  },
  contactCountryCode: {
    value: "",
    required: false,
  },
};
