import React from "react";
import { v4 as uuidv4 } from "uuid";
import styles from "../team-managers.module.scss";
import { MenuOption, Loading } from "atomic/atoms";
import { Avatar } from "atomic/molecules";

const MemberList = ({ loading, pending, members, actionLoading, selected, options }) => {
  if (loading) {
    return (
      <div className={styles.item} key={uuidv4()}>
        <Loading height={50} width={50} circle />
        <div className={styles.name}>
          <h5>
            {" "}
            <Loading width={300} />
          </h5>
          <p>
            <Loading width={200} />
          </p>
          <Loading width={200} />
        </div>
      </div>
    );
  }
  return members.map((d) => {
    return (
      <div className={styles.item} key={uuidv4()}>
        {!pending && (
          <Avatar image={d.avatar} className={styles.avatar} phrases={[d.firstName, d.lastName]} />
        )}

        <div className={styles.name}>
          <h5>{d.label}</h5>
          <p>{d.value}</p>
        </div>
        <MenuOption options={options(d)} loading={actionLoading && selected === d.userId} />
      </div>
    );
  });
};

export default MemberList;
