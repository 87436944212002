import React, { useEffect, useState, useMemo } from "react";
import GatewayModalFormContainer from "../gateway-modal-form/gateway-modal-form.container";
import { GatewayList } from "components/area";
import { environment } from "environments/environment";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import { GatewayStatus } from "enums";
import { useApi, useMount } from "hooks";
import { activateAreaDeviceUid } from "api/area";

const AreaGatewayListContainer = ({
  areaGatewayModal,
  submitGatewayForm,
  gateways,
  maxGateway,
  removeGateway,
  handleErrorInGatewayUid,
  areaId = null,
}) => {
  const [gatewayStatusState, setGatewayStatusState] = useState();
  const [subscribing, setSubscribing] = useState(false);

  const { request: requestReconfigure, loading: reconfiguring } = useApi({
    api: activateAreaDeviceUid,
    handleOwnError: {
      badrequest: true,
    },
  });

  const gatewayValueUid = useMemo(() => {
    return gateways[0]?.macAddress;
  }, [gateways]);

  const initGatewayStatus = async (gatewayUid) => {
    try {
      const db = firebase.database();
      await firebase.auth().signInAnonymously();
      const gatewayStatus = db.ref(
        `msHealth/${environment.id}/gws/${gatewayUid.toString().toLowerCase()}`
      );
      setSubscribing(false);
      if (gatewayStatus) {
        gatewayStatus.on("value", (snapshot) => {
          const val = snapshot.val();
          console.log(`msHealth/${environment.id}/gws/${gatewayUid.toString().toLowerCase()}`, val);
          if (val?.status) {
            setGatewayStatusState(val.status);
          } else {
            setGatewayStatusState(GatewayStatus.NOT_CONFIGURED);
          }
        });
      } else {
        setGatewayStatusState(GatewayStatus.NOT_CONFIGURED);
      }
    } catch (err) {
      setSubscribing(false);
      console.error(err);
    }
  };

  const reConfigureDeviceUid = async () => {
    try {
      await requestReconfigure({
        gatewayUid: gatewayValueUid,
        areaId,
      });
    } catch (error) {
      handleErrorInGatewayUid(error);
    }
  };

  const submitFormGateway = async (value, handleError) => {
    try {
      await requestReconfigure({
        gatewayUid: value().gatewayMacAddress,
        areaId,
      });
      submitGatewayForm(value);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    if (gatewayValueUid) {
      setSubscribing(true);
      initGatewayStatus(gatewayValueUid);
    }
  }, [gatewayValueUid]);

  useMount(() => {
    if (gateways.length) {
      setSubscribing(true);
    }
  });

  return (
    <>
      <GatewayModalFormContainer
        modal={areaGatewayModal}
        gateways={gateways}
        submitFormValue={submitFormGateway}
        loading={reconfiguring}
      />
      <GatewayList
        showCreateGateway={areaGatewayModal.show}
        gateways={gateways}
        maxGateway={maxGateway}
        removeGateway={removeGateway}
        gatewayStatusState={gatewayStatusState}
        reConfigureDeviceUid={reConfigureDeviceUid}
        reconfiguring={reconfiguring || subscribing}
      />
    </>
  );
};

export default AreaGatewayListContainer;
