const Routes = {
  AUTH: "auth",
  USER: "user",
  USERS: "users",
  MEMBER: "member",
  MEMBERS: "members",
  LOGIN: "login",
  LOGOUT: "logout",
  LEAVE: "leave",
  ORGANIZATION: "organization",
  ORGANIZATION_ID: ":organizationId(\\d+)",
  HEALTH: "health",
  OVERVIEW: "overview",
  USER_DATA: "user-data",
  SEARCH: "search",
  EXPORT: "export",
  AREA: "area",
  AREA_ID: ":areaId(\\d+)",
  AREA_GROUP_LEVEL: "area-group-level",
  AREAS: "areas",
  USER_ID: ":userId(\\d+)",
  USER_DEVICE: "user-device",
  EDIT: "edit",
  CREATE: "create",
  PAIR: "pair",
  UNPAIR: "unpair",
  EMPLOYMENT_DETAILS: "employment-details",
  EMERGENCY_CONTACT: "emergency-contact",
  DAY: "day",
  SYMPTOMS_LOG: "symptoms-log",
  SYMPTOM: "symptom",
  SYMPTOMS: "symptoms",
  SYMPTOMS_LOG_ID: ":symptomLogId(\\d+)",
  SETTINGS: "settings",
  PROFILE: "profile",
  PASSWORD: "password",
  ACCOUNT: "account",
  UPLOAD: "upload",
  STORAGE: "storage",
  FORGOT_PASSWORD: "forgot-password",
  RESET_PASSWORD: "reset-password",
  FEVER_CASE: "fever-case",
  INACTIVE: "inactive",
  COUNT: "count",
  CONTACT_TRACING: "contact-tracing",
  TEAMS_AND_ROLES: "teams-and-roles",
  INVITE_MANAGER: "invite-manager",
  INVITE: "invite",
  REVOKE: "revoke",
  ROLE: "role",
  REMOVE: "remove",
  EMAIL: "email",
  VALIDATE: "validate",
  REGISTER: "register",
  ACTIVATE: "activate",
  VERIFY: "verify",
  ONBOARDING: "onboarding",
  ABOUT_YOU: "about-you",
  MY_ORGANIZATION: "my-organization",
  ONBOARD: "onboard",
  PAST_ORGANIZATION: "past-organization",
  RESEND: "resend",
  PRIVACY: "privacy",
  INVITE_REQUEST: "invite-request",
  BULK: "bulk",
  NOTIFICATION: "notification",
  PRIVACY_SETTINGS: "privacy-settings",
  LIVE_MAP: "live-map",
  REDIRECT: "redirect",
  TEMPERATURE: "temperature",
  CONTACT_HISTORY: "contact-history",
  CHANGES: "changes",
  FORMS: "forms",
  QUESTIONS: "questions",
  PREFERENCE: "preference",
  MEMBER_DETAILS: "member-details",
  QUESTION: "question",
  FORM: "form",
  FORM_ID: ":formId",
  COMMUNITIES: "communities",
  ADMIN: "admin",
  COMMUNITY_ID: ":communityId",
  REGISTRY: "registry",
  DELETE: "delete",
  ADVANCED_MEMBERS: "advanced-members",
};

export default Object.freeze(Routes);
