import React, { useState } from "react";
import { Panel, Toast } from "atomic/molecules";
import styles from "./pouchband-registry-list-container.module.scss";
import localStrings from "localization";
import { PouchBandRegistryList, PouchBandRegistrySearch } from "components/pouchband-registry";
import { deleteDevices } from "api/user-device";
import { useApi, useMount, useModal } from "hooks";
import { ConfirmationModal } from "atomic/organisms";
import localTranslation from "localization/localization";

const PouchBandRegistryListContainer = ({
  request,
  loading,
  data,
  submittedSearchKey,
  setSubmittedSearchKey,
}) => {
  const [searchKey, setSearchKey] = useState("");

  const { request: deleteDevice, loading: deleting } = useApi({
    api: deleteDevices,
  });
  const result = data.data;
  const removeDeviceModal = useModal({});

  useMount(() => {
    request({
      searchKey: "",
    });
  });

  return (
    <Panel
      className={styles.container}
      title={localStrings.pouchBandRegistry}
      description={localStrings.pouchBandDescription}
    >
      <ConfirmationModal
        {...removeDeviceModal}
        title={localStrings.unlistAccessoryId}
        active={removeDeviceModal.visible}
        onOkText={localStrings.unlist}
        loading={deleting}
        onConfirm={async () => {
          try {
            const deviceUid = removeDeviceModal.state;
            await deleteDevice({ accessoryIds: [deviceUid] });
            removeDeviceModal.close();
            request({
              searchKey: submittedSearchKey,
            });
            Toast({
              icon: "check-fill",
              content: localTranslation("accessoryUnlisted", [deviceUid]),
              success: true,
            }).open();
          } catch (e) {
            console.log(e);
          }
        }}
      >
        <p className={styles.message}>{localStrings.areYouSureUnlistAccessoryId}</p>
      </ConfirmationModal>
      <PouchBandRegistrySearch
        searchKey={searchKey}
        setSearchKey={setSearchKey}
        loading={loading}
        total={data?.metadata?.total}
        search={(searchKey) => {
          request({
            searchKey,
          });
          setSubmittedSearchKey(searchKey);
        }}
      />
      <PouchBandRegistryList
        data={result}
        searchKey={submittedSearchKey}
        loading={loading}
        removeDeviceUid={removeDeviceModal.show}
      />
    </Panel>
  );
};

export default PouchBandRegistryListContainer;
