import enumeration from "./enumeration";

const Field = {
  INPUT: "Input",
  DROPDOWN: "Dropdown",
  MULTIPLE_DROPDOWN: "Multiple Dropdown",
  CHECKBOX: "Checkbox",
  MULTIPLE_CHECKBOX: "Multiple Checkbox",
  RADIO: "Radio",
  ANY: "Any",
  DATE_TIME: "Date Time",
};

export default enumeration(Field);
