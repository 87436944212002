export const employmentHistoryFormState = {
  employmentStatus: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  company: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 75,
  },
  workCountryCode: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  workPhone: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 12,
    number: true,
  },
  workAddress: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 255,
  },
};
