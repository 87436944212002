import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { validateEmail } from "utils";

const useForm = (initialFields = {}, submitCallback = null) => {
  const [formFields, setFormFields] = useState(initialFields);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const formFieldsRef = useRef({});
  const [initializing, setInitializing] = useState(true);

  const setFieldKeyAndValue = useCallback((key, value, name) => {
    setFormFields((prev) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          [key]: value,
        },
      };
    });
  }, []);

  useEffect(() => {
    formFieldsRef.current = formFields;
    if (initializing) {
      setInitializing(false);
      for (let form in formFields) {
        setFormFields((prev) => {
          return {
            ...prev,
            [form]: {
              ...prev[form],
              enable: () => {
                setFieldKeyAndValue("disabled", false, form);
              },
              disable: () => {
                setFieldKeyAndValue("disabled", true, form);
              },
            },
          };
        });
      }
    }
  }, [formFields, initializing, setFieldKeyAndValue]);

  const validateFormField = (formField, value, name = "") => {
    const { required, min, email, max, customValidations, custom } = formField;
    const isArray = Array.isArray(value);
    const error = {
      status: false,
      message: "",
    };

    if (required) {
      if (!value) {
        return {
          status: true,
          message: required.message,
        };
      }
      if (isArray && !value.length) {
        return {
          status: true,
        };
      }
    }
    if (email) {
      if (!validateEmail(value)) {
        return {
          status: true,
          message: email.message,
        };
      }
    }
    if (min) {
      if (value?.length < min.length) {
        return {
          message: min.message,
          status: true,
        };
      }
    }
    if (max) {
      if (Number(value) > max) {
        return {
          message: max.message,
          status: true,
        };
      }
    }
    if (customValidations) {
      const result = customValidations(value, custom, name, formField);
      if (result) {
        return {
          message: result.message,
          status: true,
        };
      }
    }
    return error;
  };

  const setCustomField = useCallback((custom, name) => {
    const { current: formFields } = formFieldsRef;
    setFormFields((prev) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          custom,
          error:
            formFields[name].dirty &&
            validateFormField(
              {
                ...prev[name],
                custom,
              },
              prev[name].value,
              name
            ),
        },
      };
    });
  }, []);

  const setFormFieldsRequired = useCallback(
    (fields, hasToCheckIsSubmit = true) => {
      let toSetValues = {};
      const { current: formFields } = formFieldsRef;
      const error = (cb) => {
        if (hasToCheckIsSubmit) {
          return isSubmit && cb();
        }
        return cb();
      };
      for (const name in fields) {
        if (formFields.hasOwnProperty(name)) {
          toSetValues = {
            ...toSetValues,
            [name]: {
              ...formFields[name],
              required: fields[name],
              error: error(() => {
                return validateFormField(
                  {
                    ...formFields[name],
                    required: fields[name],
                  },
                  formFields[name].value
                );
              }),
            },
          };
        }
      }
      formFieldsRef.current = {
        ...formFields,
        ...toSetValues,
      };
      setFormFields((prev) => ({
        ...prev,
        ...toSetValues,
      }));
    },
    [isSubmit]
  );

  const setFormFieldValue = useCallback(
    (value, name, properties = {}) => {
      const f = formFields[name];
      if (f.phone && value.includes(".")) {
        return false;
      }
      if (f.number && !Number(value) && value) {
        return false;
      }
      if (f.decimal) {
        const decimal = f.decimal;
        const v = value?.toString() || "";
        if (v.includes(".")) {
          const places = v.split(".");
          if (places[1] && places[1].length > decimal) {
            return false;
          }
        }
      }
      formFieldsRef.current = {
        ...formFields,
        [name]: {
          ...formFields[name],
          value,
          error: validateFormField(formFields[name], value),
          dirty: true,
          ...properties,
        },
      };
      setFormFields((prev) => {
        return {
          ...prev,
          [name]: {
            ...prev[name],
            value,
            error: validateFormField(prev[name], value),
            dirty: true,
            ...properties,
          },
        };
      });
      setIsFormDirty(true);
    },
    [formFields]
  );

  const submit = useCallback(() => {
    let errors = {};
    let isFormValid = true;
    setIsSubmit(true);
    const { current: formFields } = formFieldsRef;

    for (const fieldName in formFields) {
      const { value, ...rest } = formFields[fieldName];
      errors[fieldName] = validateFormField(rest, value);
    }
    let newFormFields = formFields;
    for (const fieldName in errors) {
      if (errors[fieldName].status) {
        isFormValid = false;
      }
      newFormFields = {
        ...newFormFields,
        [fieldName]: {
          ...newFormFields[fieldName],
          error: { ...errors[fieldName] },
        },
      };
    }
    if (isFormValid) {
      if (submitCallback) {
        submitCallback(getFormFieldValues(formFields));
      }
    } else {
      setFormFields(newFormFields);
    }
    setTimeout(() => {
      let error = document.querySelector(".error");
      if (error) {
        error.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
      error = null;
    }, 500);
  }, [submitCallback]);

  const setFormFieldValues = useCallback((newValues, options = { validate: true }) => {
    let toSetValues = {};
    const { current: formFields } = formFieldsRef;
    for (const i in newValues) {
      const value =
        newValues[i] && newValues[i].hasOwnProperty("value") ? newValues[i].value : newValues[i];
      const error =
        newValues[i] && newValues[i].hasOwnProperty("error") ? newValues[i].error : null;
      if (formFields.hasOwnProperty(i)) {
        toSetValues = {
          ...toSetValues,
          [i]: {
            ...formFields[i],
            value,
            error: error || validateFormField(formFields[i], value),
          },
        };
      }
    }
    setFormFields((prev) => ({
      ...prev,
      ...toSetValues,
    }));
  }, []);

  const clearFormFields = useCallback(() => {
    setFormFields(initialFields);
  }, [initialFields]);

  // const isFormDirty = Object.keys(formFields).some((field) => {
  //   return formFields[field].dirty;
  // });

  const isEnabled = Object.keys(formFields).some((field) => {
    return !formFields[field].disabled;
  });

  const getFormFieldValues = (formFields) => {
    return Object.keys(formFields).reduce((acc, cur) => {
      const value = formFields[cur].value;
      acc = {
        ...acc,
        [cur]: value,
      };
      return acc;
    }, {});
  };

  const setRequiredField = useCallback((required = true, name) => {
    setFormFields((prev) => {
      return {
        ...prev,
        [name]: {
          ...prev[name],
          required,
          error: validateFormField(
            {
              ...prev[name],
              required,
            },
            prev[name].value
          ),
        },
      };
    });
  }, []);

  const disable = useCallback(() => {
    for (let form in formFields) {
      setFieldKeyAndValue("disabled", true, form);
    }
  }, [formFields, setFieldKeyAndValue]);

  const clearFormFieldsError = useCallback((names) => {
    names.forEach((name) => {
      setFormFields((prev) => {
        return {
          ...prev,
          [name]: {
            ...prev[name],
            error: {
              status: false,
              message: "",
            },
          },
        };
      });
    });
  }, []);

  const clearFormFieldsDirty = useCallback(() => {
    setIsFormDirty(false);
  }, []);

  useEffect(() => {
    const isValidForm = Object.keys(formFields).every((field) => {
      const { status } = validateFormField(formFields[field], formFields[field].value);
      return !status;
    });
    setIsFormValid(isValidForm);
  }, [formFields]);

  return useMemo(() => {
    return {
      formFields,
      formFieldsValue: getFormFieldValues(formFields),
      setFormFieldValue,
      setFormFieldValues,
      clearFormFields,
      isFormValid,
      isFormDirty,
      isSubmit,
      submit,
      setCustomField,
      setRequiredField,
      disable,
      isEnabled,
      setFormFieldsRequired,
      clearFormFieldsError,
      clearFormFieldsDirty,
    };
  }, [
    clearFormFields,
    formFields,
    isFormDirty,
    isFormValid,
    isSubmit,
    setFormFieldValue,
    setFormFieldValues,
    submit,
    setCustomField,
    setRequiredField,
    disable,
    isEnabled,
    setFormFieldsRequired,
    clearFormFieldsError,
    clearFormFieldsDirty,
  ]);
};

export default useForm;
