import React, { Fragment } from "react";
import { Prompt } from "react-router-dom";
import styles from "./profile-settings.module.scss";
import localStrings from "localization";
import classnames from "classnames";
import { countryDialingCodeOptions } from "utils/countries";
import { Gender, BloodType } from "enums";
import { Message, Icon } from "atomic/atoms";
import {
  Panel,
  Button,
  Avatar,
  UploadField,
  InputField,
  DatePickerField,
  SelectField,
  TextareaField,
} from "atomic/molecules";
import { LeaveModal } from "atomic/organisms";

const ProfileSettings = ({
  formFields,
  formFieldsValue,
  user,
  isFormDirty,
  navigate,
  leaveModal,
  closeLeaveModal,
  message,
  beforeUpload,
  uploadAvatar,
  changeAvatar,
  setFormFieldValue,
  submit,
  updatingUser,
  uploadingImage,
  leave,
  setFieldRequire,
}) => {
  const { firstName: userFirstName, lastName: userLastName } = user;
  const {
    firstName,
    lastName,
    dob,
    gender,
    address,
    company,
    employmentStatus,
    workAddress,
    workCountryCode,
    workPhone,
    bloodType,
    height,
    weight,
    contactName,
    contactRelationship,
    contactNumber,
    contactCountryCode,
  } = formFields;
  const { avatar } = formFieldsValue;

  return (
    <Fragment>
      <Prompt when={isFormDirty} message={navigate} />
      <LeaveModal visible={leaveModal} onClose={closeLeaveModal} onLeave={leave} />
      <Panel className={styles.panel}>
        <h2 className={styles.panelTitle}>{localStrings.basicInformation}</h2>
        <Message className={styles.message} visible={!!message} error>
          <Icon className={styles.warningIcon} name="info-fill" />
          {message}
        </Message>

        <div className={styles.avatarContainer}>
          <Avatar
            className={styles.avatar}
            phrases={[userFirstName, userLastName]}
            image={avatar}
            loading={uploadingImage}
          />
          <div className={styles.avatarActions}>
            <UploadField
              className={styles.uploadContainer}
              beforeUpload={beforeUpload}
              onChange={changeAvatar}
              customRequest={uploadAvatar}
            >
              <Button
                className={styles.uploadButton}
                text={avatar ? localStrings.changePhoto : localStrings.uploadPhoto}
              />
            </UploadField>
            {avatar && (
              <Button
                tertiary
                text={localStrings.remove}
                onClick={() => {
                  setFormFieldValue("", "avatar");
                }}
              />
            )}
            <p className={styles.uploadGuide}>JPG, GIF or PNG. Max size of 4MB</p>
          </div>
        </div>
        <InputField
          name="firstName"
          className={styles.field}
          label={localStrings.firstName}
          placeholder="John"
          onChange={setFormFieldValue}
          {...firstName}
        />
        <InputField
          name="lastName"
          className={styles.field}
          label={localStrings.lastName}
          placeholder="Doe"
          onChange={setFormFieldValue}
          {...lastName}
        />
        <DatePickerField
          name="dob"
          className={styles.field}
          label={localStrings.birthdate}
          placeholder="MM / DD / YYYY"
          onChange={setFormFieldValue}
          {...dob}
        />
        <InputField
          name="address"
          className={styles.field}
          label={localStrings.permanentResidence}
          placeholder={localStrings.addressPlaceholder}
          onChange={setFormFieldValue}
          {...address}
        />
      </Panel>
      <Panel className={styles.panel}>
        <h2 className={styles.panelTitle}>{localStrings.personalInformation}</h2>
        <SelectField
          name="gender"
          data={genderOptions}
          className={styles.field}
          label={localStrings.gender}
          placeholder={localStrings.chooseGender}
          onChange={setFormFieldValue}
          {...gender}
        />
        <SelectField
          name="bloodType"
          data={bloodTypeOptions}
          className={styles.field}
          label={localStrings.bloodType}
          placeholder={localStrings.selectBloodType}
          onChange={setFormFieldValue}
          {...bloodType}
        />
        <InputField
          name="height"
          className={styles.field}
          label={localStrings.heightInCm}
          renderSuffix={<span>cm</span>}
          onChange={setFormFieldValue}
          {...height}
        />
        <InputField
          name="weight"
          className={styles.field}
          label={localStrings.weightInKg}
          renderSuffix={<span>kg</span>}
          onChange={setFormFieldValue}
          {...weight}
        />
      </Panel>
      <Panel className={styles.panel}>
        <h2 className={styles.panelTitle}>{localStrings.employeeDetails}</h2>
        <SelectField
          name="employmentStatus"
          className={styles.field}
          data={employeeStatus}
          label={localStrings.employmentStatus}
          placeholder={localStrings.selectEmploymentStatus}
          onChange={setFormFieldValue}
          {...employmentStatus}
        />
        <InputField
          name="company"
          className={styles.field}
          label={localStrings.company}
          placeholder={localStrings.companyPlaceholder}
          onChange={setFormFieldValue}
          {...company}
        />
        <div className={classnames(styles.inputMobileNumber, styles.field)}>
          <span className={classnames(styles.inputMobileNumberLabel, "compLabel")}>
            {localStrings.workContactNumber}
          </span>
          <SelectField
            name="workCountryCode"
            classNameContainer={styles.mobileNumber}
            data={countryCodes}
            placeholder="+63"
            onChange={setFormFieldValue}
            onBlur={() => setFieldRequire(formFieldsValue)}
            {...workCountryCode}
          />
          <InputField
            name="workPhone"
            className={styles.workPhone}
            placeholder="916 4567 890"
            onChange={setFormFieldValue}
            onBlur={() => setFieldRequire(formFieldsValue)}
            {...workPhone}
          />
        </div>
        <TextareaField
          name="workAddress"
          className={styles.field}
          label={localStrings.workAddress}
          placeholder={localStrings.addressPlaceholder}
          onChange={setFormFieldValue}
          {...workAddress}
        />
      </Panel>
      <Panel className={styles.panel}>
        <h2 className={styles.panelTitle}>{localStrings.emergencyContactPerson}</h2>
        <InputField
          name="contactName"
          className={styles.field}
          label={localStrings.contactPersonsName}
          placeholder={localStrings.fullName}
          onChange={setFormFieldValue}
          onBlur={() => setFieldRequire(formFieldsValue)}
          {...contactName}
        />
        <InputField
          name="contactRelationship"
          className={styles.field}
          label={localStrings.contactRelationship}
          placeholder={localStrings.relationshipPlaceholder}
          onChange={setFormFieldValue}
          onBlur={() => setFieldRequire(formFieldsValue)}
          {...contactRelationship}
        />
        <div className={classnames(styles.inputMobileNumber, styles.field)}>
          <span className={classnames(styles.inputMobileNumberLabel, "compLabel")}>
            {localStrings.mobileNumber}
          </span>
          <SelectField
            name="contactCountryCode"
            classNameContainer={styles.mobileNumber}
            data={countryCodes}
            placeholder="+63"
            onChange={setFormFieldValue}
            {...contactCountryCode}
          />
          <InputField
            name="contactNumber"
            className={styles.workPhone}
            placeholder="916 4567 890"
            onChange={setFormFieldValue}
            onBlur={() => setFieldRequire(formFieldsValue)}
            {...contactNumber}
          />
        </div>
      </Panel>
      <div className={styles.actions}>
        <Button loading={updatingUser} text={localStrings.saveChanges} onClick={submit} />
      </div>
    </Fragment>
  );
};

const countryCodes = countryDialingCodeOptions();
const employeeStatus = [
  {
    text: localStrings.worker,
    value: localStrings.worker,
  },
  {
    text: localStrings.employed,
    value: localStrings.employed,
  },
  {
    text: localStrings.selfEmployed,
    value: localStrings.selfEmployed,
  },
  {
    text: localStrings.unemployed,
    value: localStrings.unemployed,
  },
];

const genderOptions = [
  {
    text: "Male",
    value: Gender.Male,
  },
  {
    text: "Female",
    value: Gender.Female,
  },
  {
    text: "Other",
    value: Gender.Other,
  },
];

const bloodTypeOptions = Object.keys(BloodType).map((bloodType) => {
  return {
    value: BloodType[bloodType],
    text: BloodType[bloodType],
  };
});

export default ProfileSettings;
