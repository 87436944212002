import React from "react";
import { Modal } from "atomic/atoms";
import { ActionButton } from "components/common";

const ConfirmationModal = ({
  active,
  close,
  className,
  title,
  closable,
  loading,
  onCancelText,
  onOkText,
  onConfirm,
  value,
  children,
}) => {
  return (
    <Modal
      visible={active}
      onCancel={close}
      className={className}
      title={title}
      closable={closable}
    >
      {children}
      <ActionButton
        loading={loading}
        cancelText={onCancelText}
        submitText={onOkText}
        onSubmit={() => onConfirm(value)}
        onCancel={close}
        type={"danger"}
      />
    </Modal>
  );
};

export default ConfirmationModal;
