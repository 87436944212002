import React, { useReducer, Fragment } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { Routes } from "enums";
import { AuthorizedRoute, AuthRoute, ActivateRoute } from "routes";
import { AuthContainer, AuthorizedContainer, ActivateContainer } from "containers";
import { Error } from "pages";
import { AppContext } from "contexts";
import AppReducer, { initialState } from "reducers/app.reducer";
import localStrings from "localization";
import * as Sentry from "@sentry/react";
import { environment } from "./environments/environment";
import { useBeforeMount } from "hooks";
import mixpanel from "mixpanel-browser";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { LoadingBlock } from "atomic/molecules";
import firebase from "firebase/app";

function App() {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const { error, loading, redirectLink = "/" } = state;

  useBeforeMount(() => {
    if (environment.id === "prod") {
      Sentry.init({
        dsn: "https://dc66af73204145c997b540ecd80694cf@o431184.ingest.sentry.io/5381483",
        environment: environment.id,
      });
    }

    firebase.initializeApp(environment.FIREBASE);

    mixpanel.init(environment.MIXPANEL_TOKEN);

    localStrings.setLanguage(localStrings.getLanguage());
  });

  return (
    <AppContext.Provider value={{ state, dispatch }}>
      {error ? (
        <Error error={error} redirectLink={redirectLink} />
      ) : (
        <Fragment>
          <LoadingBlock fixed visible={loading} />
          <Router>
            <Switch>
              <ActivateRoute path={`/${Routes.ACTIVATE}`} component={ActivateContainer} />
              <AuthRoute path={`/${Routes.AUTH}`} component={AuthContainer} />
              <AuthorizedRoute path="/" component={AuthorizedContainer} />
            </Switch>
          </Router>
        </Fragment>
      )}
    </AppContext.Provider>
  );
}

// export default withLDConsumer()(App);
export default withLDConsumer()(App);
