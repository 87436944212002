import localStrings from "localization";

// const validateEmail = (value, custom) => {
//   if (custom && custom.hasOwnProperty("existing")) {
//     return {
//       status: true,
//       message: localStrings.thisEmailIsAlreadyAMemberOfYourOrg,
//     };
//   }

//   if (custom && custom.hasOwnProperty("pending")) {
//     return {
//       status: true,
//       message: localStrings.userHasPendingInvite,
//     };
//   }
// };

export const inviteMemberFormState = {
  email: {
    required: true,
    email: {
      message: localStrings.pleaseEnterAnEmailInFormat,
    },
    maxLength: 75,
    // customValidations: validateEmail,
  },
};
