import React, { useMemo, useCallback } from "react";
import { Icon, Checkbox } from "atomic/atoms";
import { Button, InputField, DraggableItems } from "atomic/molecules";
import styles from "./field-options.module.scss";
import { Radio } from "antd";
import classnames from "classnames";
import localStrings from "localization";

const FieldOptions = ({
  options,
  addOption = () => null,
  removeOption = () => null,
  changeOptionLabel = () => null,
  optionType,
  modifyField,
  hasError,
}) => {
  const setItems = useCallback(
    (item) => {
      const value = item.map(({ optionName, organizationQuestionOptionId }, index) => {
        return {
          optionName,
          organizationQuestionOptionId,
          sort: index + 1,
        };
      });
      modifyField("organizationQuestionOptions", {
        value,
      });
    },
    [modifyField]
  );

  const preparedFieldOptions = useMemo(() => {
    const hasManyOption = options.length > 1;
    return options.map(({ optionName, isDuplicate, ...rest }, index) => {
      const isDupError = hasError && isDuplicate;
      const isEmptyError = !optionName.trim();
      return {
        id: `draggable-${index}`,
        optionName,
        dragItemClassName: classnames({
          [`${styles.dragItemError}`]: hasError && isDuplicate,
        }),
        content: (
          <div
            key={index}
            className={classnames(styles.formFieldOption, {
              [`${styles.error}`]: isDupError,
            })}
          >
            {["CHECKBOX", "DROPDOWN"].includes(optionType) ? (
              <Checkbox disabled className={styles.dummyField} />
            ) : (
              <Radio disabled className={styles.dummyField} />
            )}
            <InputField
              maxLength={75}
              className={classnames(styles.optionFieldLabel, "optionFieldLabel", {
                [`${styles.error} error`]: isDupError || isEmptyError,
              })}
              error={{
                status: isDupError,
                message: isDupError && localStrings.thisOptionAlreadyExists,
              }}
              value={optionName}
              onChange={(value) => changeOptionLabel(value, index)}
              selectAllOnFocus
            />
            {hasManyOption && (
              <Icon
                onClick={() => {
                  if (hasManyOption) {
                    removeOption(index);
                  }
                }}
                className={styles.optionFieldRemove}
                name="close"
              />
            )}
          </div>
        ),
        ...rest,
      };
    });
  }, [options, removeOption, optionType, changeOptionLabel, hasError]);

  return (
    <div className={styles.formFieldOptionsContainer}>
      <p className={styles.title}>{localStrings.options}</p>
      <div
        className={classnames(styles.formFieldsOptions, {
          [`${styles.scrollable}`]: preparedFieldOptions.length > 5,
        })}
      >
        {
          <DraggableItems
            className={styles.draggableItems}
            items={preparedFieldOptions}
            setItems={setItems}
            disableTabIndex
          />
        }
      </div>
      <Button
        tertiary
        text={localStrings.addAnotherOption}
        className={styles.addOption}
        onClick={addOption}
      />
    </div>
  );
};

export default FieldOptions;
