import React, { useMemo } from "react";
import styles from "./contact-history-table.module.scss";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import EmptyImage from "images/empty-contact-history.svg";
import { Pagination, Table, Image } from "atomic/atoms";
import { UnableToRetrieve } from "atomic/organisms";

const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "name",
    title: localStrings.name,
    width: 250,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "dateOfContact",
    title: localStrings.dateOfContact,
  },
  {
    key: uuidv4(),
    dataIndex: "duration",
    width: 90,
    title: localStrings.duration,
  },
  {
    key: uuidv4(),
    dataIndex: "locationArea",
    title: `${localStrings.location}/${localStrings.area}`,
  },
];

const ContactHistoryTable = ({
  error,
  searchKey,
  data,
  onRowClick,
  loading,
  total,
  defaultPageSize,
  pageSize,
  showSizeChanger,
  onChange,
  currentPage,
  fullName,
}) => {
  const emptyRenderer = useMemo(() => {
    return {
      description: (
        <div className={styles.emptyTable}>
          <Image className={styles.emptyImage} src={EmptyImage} />
          <h3 className={styles.emptyTitle}>{localStrings.noContactDetected}</h3>
          <p className={styles.emptyDesc}>
            {localStrings.formatString(localStrings.hasNotBeenInContact, fullName)}
          </p>
        </div>
      ),
    };
  }, [fullName]);

  if (searchKey && !data.length) {
    return (
      <div className={styles.noData}>
        <div>
          <h3>{localStrings.noMembersFound}</h3>
          <p>Try searching again.</p>
        </div>
      </div>
    );
  }
  return error ? (
    <UnableToRetrieve className={styles.unableToRetrieve} />
  ) : (
    <>
      <Table
        className={styles.table}
        columns={tableColumns}
        data={data}
        emptyRenderer={emptyRenderer}
        onRowClick={onRowClick}
        loading={loading}
      />
      <Pagination
        className={styles.pagination}
        total={total}
        defaultPageSize={defaultPageSize}
        pageSize={pageSize}
        showSizeChanger={showSizeChanger}
        onChange={onChange}
        currentPage={currentPage}
      />{" "}
    </>
  );
};

export default ContactHistoryTable;
