import React, { useMemo, useCallback } from "react";
import { LayoutB } from "layouts";
import AreaForm from "components/area/area-form/area-form";
import { AreaGatewayInfoModal } from "components/area";
import { Toast } from "atomic/molecules";
import { useFormV2, useRouter, useModal } from "hooks";
import initialFormState from "./initial-form-state";
import { ActionButton } from "components/common";
import path from "path/path";
import { DiscardModal } from "atomic/organisms";
import localStrings from "localization";
import styles from "./area-form.module.scss";

const AreaFormContainer = ({
  initialState = undefined,
  onSubmit,
  strings,
  submitting,
  deleteArea,
  areaId,
}) => {
  const { history } = useRouter();
  const discardChangesModal = useModal({});
  const formState = useMemo(() => {
    return initialFormState(initialState);
  }, [initialState]);
  const areaGatewayModal = useModal({});

  const form = useFormV2({
    initialState: formState,
  });

  const { dirty, submitForm, getFormValues, applyFieldErrors, isFormSubmittable } = form;
  const areGatewayModal = useModal({});

  const onCancel = () => {
    if (dirty) {
      discardChangesModal.show();
      return false;
    }
    leavePage();
  };

  const leavePage = useCallback(() => {
    history.push(path.AREAS);
  }, [history]);

  const submit = useCallback(async () => {
    const params = getFormValues();
    const gateway = params.gateways[0];
    const values = {
      areaName: params.areaName,
      areaDescription: params.areaDescription,
      gatewayUid: gateway?.macAddress.toString().toLowerCase() || null,
      gatewayName: gateway?.label || "",
      level: params.areaLevel,
    };
    onSubmit(
      values,
      (data, d) => {
        Toast({
          content: d.message,
          success: true,
          visible: true,
          icon: "check-fill",
        }).open();
        leavePage();
      },
      (error) => {
        const { metadata } = error;
        const errors = {
          8013: () => {
            applyFieldErrors({
              areaName: localStrings.areaDuplicateName,
            });
          },
          8017: () => {
            applyFieldErrors({
              gateways: localStrings.gatewayAlreadyAdded,
            });
          },
        };
        if (errors[metadata.code]) {
          errors[metadata.code]();
        } else if (error.badrequest) {
          error.dispatchError();
        }
      }
    );
  }, [applyFieldErrors, getFormValues, leavePage, onSubmit]);

  return (
    <>
      <LayoutB
        title={strings.title}
        onBackPage={{
          onClick: () => {
            onCancel();
          },
        }}
        description={
          <div>
            {localStrings.assignGatewayDescription}
            <span className={styles.learnMore} onClick={() => areGatewayModal.show()}>
              {localStrings.learnMore}
            </span>
          </div>
        }
        footer={
          <div className={styles.actionButton}>
            {deleteArea}
            <ActionButton
              submitText={strings.submitText}
              disabled={!isFormSubmittable}
              onSubmit={() => submitForm(submit)}
              loading={submitting}
              onCancel={onCancel}
            />
          </div>
        }
      >
        <AreaGatewayInfoModal modal={areGatewayModal} />
        <DiscardModal
          {...discardChangesModal}
          setDiscardModal={() => {
            discardChangesModal.close();
          }}
          discardChanges={() => {
            discardChangesModal.close();
            leavePage();
          }}
          show={discardChangesModal.visible}
        />
        <AreaForm {...form} areaGatewayModal={areaGatewayModal} areaId={areaId} />
      </LayoutB>
    </>
  );
};

export default AreaFormContainer;
