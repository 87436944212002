import React from "react";
import styles from "./auth-layout.module.scss";
const AuthLayout = ({ children }) => {
  return (
    <div className={styles.container}>
      {children} <div className={styles.gradient}></div>
    </div>
  );
};

export default AuthLayout;
