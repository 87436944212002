import { editCommunity } from "api/organization";
import { Toast } from "atomic/molecules";
import DeactivateCommunityModal from "components/community/deactivate-community-modal/deactivate-community-modal";
import { useApi } from "hooks";
import React, { useCallback } from "react";
import localStrings from "localization";

const DeactivateCommunityModalContainer = ({ visible, close, community, fetchCommunities }) => {
  const { request: deactivateCommunityRequest, loading } = useApi({ api: editCommunity });

  const deactivateCommunity = useCallback(async () => {
    const { organizationId, latitude, longitude, assignedManager } = community;
    await deactivateCommunityRequest({
      communityId: organizationId,
      params: {
        ...community,
        email: assignedManager?.email,
        isActive: false,
        latitude: latitude ? latitude : 0,
        longitude: longitude ? longitude : 0,
      },
    });
    Toast({
      content: localStrings.changesSaved,
      error: false,
      success: true,
    }).open();
    close();
    fetchCommunities();
  }, [close, deactivateCommunityRequest, community, fetchCommunities]);

  return (
    <DeactivateCommunityModal
      submit={deactivateCommunity}
      loading={loading}
      visible={visible}
      close={close}
    />
  );
};

export default DeactivateCommunityModalContainer;
