import React from "react";
import { IconLink } from "atomic/molecules";
import styles from "./back-link.module.scss";
import classnames from "classnames";

const BackLink = ({ onClick, text, className }) => {
  return (
    <IconLink
      icon="left-chevron-thick"
      onClick={onClick}
      className={classnames(styles.backLink, className)}
    >
      {text}
    </IconLink>
  );
};

export default BackLink;
