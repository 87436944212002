import React, { useMemo } from "react";
import { Modal, Tooltip, Icon } from "atomic/atoms";
import localStrings from "localization";
import initialFormState from "./initial-form-state";
import { useFormV2 } from "hooks";
import { InputField } from "atomic/molecules";
import styles from "./gateway-modal-form.module.scss";
import { Button } from "atomic/molecules";

const GatewayModalFormContainer = ({ modal, gateways, submitFormValue, loading }) => {
  const { state: initialState = {} } = modal;
  const formState = useMemo(() => {
    return initialFormState(initialState || {});
  }, [initialState]);
  const form = useFormV2({
    initialState: formState,
  });
  const {
    submitForm,
    getFormValues,
    isFormSubmittable,
    fields,
    modifyField,
    applyFieldErrors,
  } = form;
  const { gatewayName, gatewayMacAddress } = fields || {};

  const setFormFieldValue = (v, name) => {
    let value = v;
    if (name === "gatewayMacAddress") {
      value = value.trim();
    }
    modifyField(name, {
      value,
    });
  };

  const submit = () => {
    submitFormValue(getFormValues, (error) => {
      const { metadata = {} } = error;
      const { code } = metadata;
      const errors = {
        8017: () => {
          applyFieldErrors({
            gatewayMacAddress: localStrings.gatewayAlreadyAdded,
          });
        },
      };
      if (errors[code]) {
        errors[code]();
      } else if (error.badrequest) {
        error.dispatchError();
      }
    });
  };

  return (
    <Modal {...modal} title={localStrings.assignGateway}>
      <div className={styles.input}>
        <InputField
          name="gatewayName"
          label={localStrings.gatewayName}
          onChange={setFormFieldValue}
          placeholder={localStrings.gatewayNameDescription}
          optional
          {...gatewayName}
        />
      </div>
      <div className={styles.input}>
        <InputField
          name="gatewayMacAddress"
          label={
            <>
              {localStrings.gatewayMacAddress}
              <span className={styles.tooltip}>
                <Tooltip title={localStrings.gatewayTooltip}>
                  <Icon name="info-fill" className={styles.icon} />
                </Tooltip>
              </span>
            </>
          }
          onChange={setFormFieldValue}
          placeholder={localStrings.gatewayMacAddressDescription}
          {...gatewayMacAddress}
        />
      </div>
      <div className={styles.actionButton}>
        <Button loading={loading} disabled={!isFormSubmittable} onClick={() => submitForm(submit)}>
          {localStrings.assignGateway}
        </Button>
      </div>
    </Modal>
  );
};

export default GatewayModalFormContainer;
