import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import {
  CommunityListContainer,
  CreateCommunityContainer,
  EditCommunityContainer,
} from "containers";
import { Routes } from "enums";

const CommunityContainer = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact component={CommunityListContainer} path={path} />
      <Route
        exact
        component={EditCommunityContainer}
        path={`${path}/${Routes.EDIT}/${Routes.COMMUNITY_ID}`}
      />
      <Route exact component={CreateCommunityContainer} path={`${path}/${Routes.CREATE}`} />
      <Route path="*" render={() => <Redirect to={path} />} />
    </Switch>
  );
};

export default CommunityContainer;
