import moment from "moment";

const initialFormState = {
  date: {
    required: true,
    value: moment(),
    error: {
      status: false,
    },
  },
  time: {
    required: true,
    value: moment(),
    error: {
      status: false,
    },
  },
  symptoms: {
    required: true,
    value: [],
    error: {
      status: false,
    },
  },
  isMedicalTest: {
    value: false,
    required: false,
  },
  medicalTestType: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  otherTestName: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  testTakenDate: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  testAddress: {
    value: "",
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  testResultDate: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  testResult: {
    value: null,
    required: false,
    maxLength: 75,
    error: {
      status: false,
      message: "",
    },
  },
  additionalInfo: {
    required: true,
    value: "",
    maxLength: 255,
    error: {
      status: false,
    },
  },
};

export default initialFormState;
