import React from "react";
import { AuthLayout } from "layouts";
import { ForgotPasswordForm } from "components/auth";

const ForgotPassword = () => {
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
};

export default ForgotPassword;
