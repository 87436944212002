import React, { useState, useContext, useRef, useCallback } from "react";
import { useForm } from "hooks";
import styles from "./update-password.module.scss";
import localString from "localization";
import initialFormState from "./initial-form-state";
import { AppContext } from "contexts";
import { updatePassword } from "api/user";
import { PasswordStrength } from "atomic/atoms";
import { InputFieldPassword, Button, Toast } from "atomic/molecules";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "enums";

const UpdatePassword = ({ className, onCancel }) => {
  const { dispatch: appDispatch } = useContext(AppContext);
  const form = useForm(initialFormState());
  const {
    isFormValid,
    formFieldsValue,
    formFields,
    setFormFieldValue,
    setCustomField,
    submit,
  } = form;
  const [submitting, setSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const passwordStrengthRef = useRef("POOR");
  const { current: passwordStrength } = passwordStrengthRef;

  const handleOnTogglePassword = (visible) => {
    setShowPassword(visible);
  };

  const onSubmit = () => {
    submit();
    if (isFormValid && !["POOR"].includes(passwordStrength)) {
      setSubmitting(true);
      updatePassword({
        password: formFieldsValue.password,
        oldPassword: formFieldsValue.oldPassword,
      })
        .then((d) => {
          setSubmitting(false);

          mixpanel.track(Mixpanel.UPDATE_PASSWORD);

          Toast({
            active: true,
            content: localString.yourPasswordHasBeenChanged,
            success: true,
            icon: "check-fill",
          }).open();
          onCancel();
        })
        .catch((err) => {
          setSubmitting(false);
          if (err.metadata && err.metadata.code) {
            const errors = {
              2001: () => {
                setCustomField({ invalid: true }, "oldPassword");
              },
            };
            if (errors[err.metadata.code]) {
              return errors[err.metadata.code]();
            }
          }
          if (err.serverError) {
            appDispatch({ type: "SERVER_ERROR" });
          }
          appDispatch({ type: "NETWORK_ERROR" });
        });
    }
  };

  const handleOnChangePasswordStrength = useCallback((value) => {
    passwordStrengthRef.current = value;
  }, []);

  return (
    <>
      <div className={className}>
        <InputFieldPassword
          name="oldPassword"
          label={localString.currentPassword}
          onChange={(value, name) => {
            setFormFieldValue(value, name);
            setCustomField({ invalid: false }, "oldPassword");
          }}
          showPassword={showPassword}
          {...formFields.oldPassword}
        />
      </div>
      <div className={className}>
        <InputFieldPassword
          shouldShowToggle
          name="password"
          label={localString.newPassword}
          onChange={(value, name) => {
            setFormFieldValue(value, name);
            setCustomField(value, "retypePassword");
          }}
          onShowToggleChange={handleOnTogglePassword}
          {...formFields.password}
        />
        {formFieldsValue.password && (
          <PasswordStrength
            className={styles.passwordStrength}
            password={formFieldsValue.password}
            onChange={handleOnChangePasswordStrength}
          />
        )}
      </div>
      <div className={className}>
        <InputFieldPassword
          name="retypePassword"
          label={localString.retypePassword}
          onChange={setFormFieldValue}
          showPassword={showPassword}
          {...formFields.retypePassword}
        />
      </div>
      <div className={styles.submit}>
        <Button tertiary onClick={onCancel}>
          {localString.cancel}
        </Button>
        <Button loading={submitting} onClick={onSubmit} className={styles.submitButton}>
          {localString.saveChanges}
        </Button>
      </div>
    </>
  );
};

export default UpdatePassword;
