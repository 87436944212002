import React from "react";
import InviteExpired from "images/invite-expired.svg";
import localStrings from "localization";
import styles from "./invite.module.scss";
import path from "path/path";
import { Image, Link } from "atomic/atoms";

const InviteExpiredError = () => {
  return (
    <div className={styles.container}>
      <Image className={styles.image} src={InviteExpired} />

      <h2 className={styles.title}>{localStrings.unfortunatelyThisInviteIsExpired}</h2>
      <p className={styles.description}>{localStrings.inviteExpiredDesc}</p>
      <p className={styles.description}>
        {localStrings.forFurtherQuestions}, {localStrings.youMay}{" "}
        <Link href={path.BUSINESS_CONTACT} target="_blank" text={localStrings.contactUs} />,{" "}
        {localStrings.visitOur}{" "}
        <Link href={path.BUSINESS_FAQS} target="_blank" text={localStrings.FAQsPage} />,{" "}
        {localStrings.orCheck}{" "}
        <Link href={path.BUSINESS_PRICING} target="_blank" text={localStrings.ourAvailablePlans} />
      </p>
      <div className={styles.loginNowContainer}>
        {localStrings.alreadyHaveAnAccount}{" "}
        <Link href="/" className={styles.loginNow} text={localStrings.loginNow} />
      </div>
    </div>
  );
};

export default InviteExpiredError;
