import React from "react";
import styles from "./account-settings-form.module.scss";
import localString from "localization";
import { Icon } from "atomic/atoms";
import { InputField, Button, LabelItem } from "atomic/molecules";
import localStrings from "localization";

const AccountSettingsForm = ({
  name,
  state,
  label,
  onSubmit,
  placeholder,
  onCancel,
  submitting,
  activeForm,
  setActiveForm,
  value,
  saveChangesText,
  input,
  disabled,
  isFormFieldDirty,
  error,
  onChange,
}) => {
  if (activeForm === name) {
    return (
      <div className={styles.input}>
        <div>
          {input || (
            <InputField
              name={name}
              label={label}
              onChange={(value, name) => {
                if (onChange) {
                  onChange(value, name);
                }
              }}
              placeholder={placeholder}
              {...state}
            />
          )}
          {error && <p className={styles.error}>{error}</p>}
        </div>
        <div>
          <div className={styles.submit}>
            <div className={styles.submitSuccess}>
              <Button tertiary onClick={onCancel}>
                {localString.cancel}
              </Button>
              <Button loading={submitting} onClick={onSubmit} disabled={!isFormFieldDirty}>
                {saveChangesText || localString.saveChanges}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.info}>
      <div>
        <LabelItem label={<div className={styles.formLabel}>{label}</div>}>
          <div className={styles.labelValue}>
            {value ? (
              <>
                <span> {value}</span>
                <Icon name="check-fill" />
              </>
            ) : (
              "-"
            )}
          </div>
        </LabelItem>
      </div>
      {!disabled && (
        <div
          className={styles.editButton}
          onClick={() => {
            setActiveForm(name);
          }}
        >
          {localStrings.edit}
        </div>
      )}
    </div>
  );
};

export default AccountSettingsForm;
