import pending from "./pending";

const locale = {
  about: "About",
  aboutYou: "About You",
  aboutYouDesc: "Birthdate, current residential address, gender, blood type, height and weight",
  acceptInvite: "Accept Invite",
  accessory: "Accessory",
  accessoryAlreadyPaired: "This accessory is already paired with another user.",
  accessoryDetails: "Accessory Details",
  accessoryId: "Accessory ID",
  accessoryIdAlreadyPaired: "This accessory ID is already paired with another user.",
  accessoryIdDoesNotBelongToOrganization: "This accessory ID does not belong to this community.",
  accessoryIdIsntAvailable: "This accessory isn't available. Please try another ID.",
  accessoryOrMacAddress: "Accessory ID or MAC Address",
  accessoryRemoved: "PouchBAND device is removed.",
  accessoryStatus: "Accessory Status",
  accessoryType: "Accessory Type",
  account: "Account",
  accountInformation: "Account Information",
  accountSettings: "Account Settings",
  active: "Active",
  activeAndSyncing: "Active and Syncing",
  activeMembers: "Active Members",
  activeOraganization: "Active Communities",
  add: "Add",
  addAMember: "Add a member",
  addANewMember: "Add a new member",
  addANewUser: "Add a new user",
  addAccessory: "Add PouchBAND",
  addAndManagerPouchBands: "Manage this member's PouchBANDs.",
  addMember: "Add Member",
  addMemberAndProfile:
    "Add a member and their profile details without a need for PouchPASS account.",
  addMembers: "Add members",
  addNew: "Add New",
  addNewMembers: "Add New Members",
  addPouchband: "Add PouchBAND",
  addUsers: "Add users",
  addYourContactDetails: "Almost done! Add your emergency contact just in case!",
  addYourPouchBand: "Add and manage your PouchBANDs.",
  additionalInfo: "Additional Info",
  address: "Unit/Lot number, street name, subdivision or village, city, province/state",
  all: "All",
  allSet: "All Set",
  allTheInfoCollectedProfile:
    "All the information collected and/or added to this profile would only be accessible by the managers of this community. The person described in this profile would not have any access to the community and/or all the information collected in this profile. Should you wish for this person to access and manage this profile, they would have to be invited to PouchPASS to create an account. ",
  allowed: "Allowed",
  almostDoneEnterYourNewPassword: "Almost done. Enter your new password.",
  alreadyHaveAnAccount: "Already have an account?",
  alreadyInvitedAssignAsManager:
    "Already invited. Assign as {0} later once they have accepted their invitation.",
  alreadyInvitedPendingError:
    "Already invited. Assign as Manager later once they have accepted their invitation.",
  and: "and",
  appStore: "App Store",
  applyFilters: "Apply Filters",
  areYouSure: "Are you sure?",
  area: "Area",
  areaDescription: "Area Description",
  areaDuplicateName: "Area name already exists, please enter different name.",
  areaLocation: "Location / Area",
  areaLocationDesc: "Location when you are in the area of the community.",
  areaName: "Area Name",
  areas: "Areas",
  asThePersonDescribed: "As the person described in this profile,",
  askDeleteArea: "Delete Area?",
  askDeleteMember: "Delete Member?",
  askDeleteUser: "Delete User?",
  askRemoveAccessory: "Remove PouchBAND?",
  askRemoveManager: "Remove Manager?",
  askRemoveMember: "Remove Member?",
  askSaveChanges: "Save changes?",
  askTheUserToWearPouchBand: "Ask the member to wear their PouchBAND.",
  askYouToWearPouchBand: "Wear your PouchBAND to start monitoring your temperature!",
  assignAPouchBandToEachUser:
    "Assign a PouchBAND to each member to monitor their body temperatures and locations.",
  assignAPouchBandToStartMonitorTheirTemp:
    "Assign a PouchBAND to start monitoring their temperature.",
  assignAsManager: "Assign as Manager",
  authorization: "Authorization",
  back: "Back",
  backTo: "Back to",
  backToHome: "Back to Home",
  backToLogin: "Back to Login",
  basicInfo: "Basic Info",
  birthdate: "Birthdate",
  birthday: "Birthday",
  bloodType: "Blood Type",
  bodyAche: "Body ache",
  bodyTemperature: "Body Temperature",
  bodyTemperatureDays: "Body temperature records for 14 days",
  browsingYourFiles: "browsing your files",
  cancel: "Cancel",
  celciusSymbol: "\\u00B0C",
  celsius: "Celsius",
  change: "change",
  changeAccessory: "Change Accessory",
  changePhoto: "Change Photo",
  changeYourEmail: "change your email address",
  changesSaved: "Changes saved.",
  checkCredentials:
    "Check the credentials that you supplied. If the error persists, please contact support.",
  checkEmploymentDetails: "Check these details about your company or employer.",
  checkWithYourAdminForDetails: " Check with your admin for details or contact PouchPASS support.",
  checkingInternet: "Checking your internet or data connectivity.",
  chestPains: "Chest Pains",
  chooseBloodType: "Choose Blood Type",
  chooseGender: "Choose Gender",
  chooseTypeTest: "Choose type of test",
  chooseWhichInformationToShare: "Choose which information to share.",
  clear: "Clear",
  clearAllFilters: "Clear All Filters",
  cm: "cm",
  company: "Employment Name or Company",
  companyPlaceholder: "e.g. ‟Apple”",
  completeProfile: "Complete Profile",
  confirmDeleteMember:
    "Are you sure you want to delete <strong>{0}</strong> from your community? All their information will be deleted. This action cannot be undone.",
  confirmNewPassword: "Confirm New Password",
  confirmPassword: "Confirm Password",
  confirmRemoveManager:
    "Are you sure you want to remove <b>{0}</b> as a manager? They will still be a member of your community, but will lose managerial access.",
  confirmRemoveMember:
    "Are you sure you want to remove <b>{0}</b> from your community? You will no longer be able to access their profile or any of their information.",
  confirmRevokeManagerInvite: "Are you sure you want to revoke the invite for <b>{0}</b>?",
  conjunctivitis: "Conjunctivitis",
  connectAPouchBandDesc:
    "Connect a PouchBAND by entering the accessory ID found on the back of the device",
  contactAndPersonalInfo: "Contact and Personal Info",
  contactHistory: "Contact History",
  contactHistoryDesc:
    "Monitor the people that {0} interacted with for 15 mins or more at a close distance for the last 30 days.",
  contactInformation: "Contact Information",
  contactNumber: "Contact Number",
  contactPersonsName: "Contact Person’s Full Name",
  contactPouchPass: "Contact PouchPASS",
  contactRelationship: "Relationship with Contact Person",
  contactUs: "contact us",
  cough: "Cough",
  countryCurrentlyResidingIn: "Country Currently Residing In",
  createAccount: "Create Account",
  createAndManageUser: "Create and Manage Members",
  createArea: "Create Area",
  createMember: "Create Member",
  createPPaccount: "create a PouchPASS account.",
  createUser: "Create User",
  createWorryFree: "Create a worry-free environment for your family or business!",
  createYourFirstArea: "Create your first area!",
  createYourFirstAreaDesc:
    "Add a gateway per area in your home, office, or any location to monitor members efficiently.",
  currentPassword: "Current Password",
  currentResidentialAddress: "Current Residential Address",
  date: "Date",
  dateOfAvailabilityOfTestResults: "Date of Availability of Test Results",
  dateOfContact: "Date of Contact",
  dateOfMedicalTest: "Date of Medical Test",
  decline: "Decline",
  declineInvitation: "Decline Invitation",
  declineInvitationDesc:
    "Are you sure you want to decline the invitation from {0}? This action cannot be undone. You won’t be able to join this community without a new invite.",
  delete: "Delete",
  deleteArea: "Delete Area",
  deleteLog: "Delete Log",
  deleteMember: "Delete Member",
  deleteSymptomsLog: "Delete Symptoms Log?",
  deleteUser: "Delete User",
  deletedMemeber: "Deleted member",
  description: "Description",
  details: "Details",
  deviceStatus: "Device Status",
  diarrhea: "Diarrhea",
  diary: "Diary",
  didntGetACode: "Didn’t get a code?",
  didntReceiveAnything: "Didn’t receive anything?",
  difficultyBreathing: "Difficulty Breathing",
  discardChanges: "Discard Changes",
  discardChangesMessage:
    "If you leave this page, all unsaved changes will be lost. Are you sure you want to discard changes?",
  discardTitle: "You have unsaved changes on this page",
  dismiss: "Dismiss",
  doThisLater: "Do this later",
  doYouWantToCreateYourOwnOrg: "Do you want to create your own community?",
  dontHavePouchBandYet:
    "Don’t have a PouchBAND yet? Request one from your administrator or {0} now!",
  duration: "Duration",
  easilyIdentifyYourProfile: "Easily identify your profile when you see your picture. ",
  edit: "Edit",
  editArea: "Edit Area",
  editLogSymptoms: "Edit Log Symptoms",
  editManager: "Edit Manager",
  editMember: "Edit Member",
  editProfile: "Edit Profile",
  editSettings: "Edit Settings",
  editUser: "Edit User",
  egMedicalClinic: "eg. “Medical Clinic”",
  emailAddress: "Email Address",
  emailAddressAlreadyTaken: "This email is already a member of your community.",
  emailAddressAndMobileNumber: "Email Address and Mobile Number",
  emailAddresses: "Email Addresses",
  emailAlreadyAssociatedWithThisOrganization:
    "This email is already associated with an account in this community.",
  emailAlreadyExists: "This email already exists",
  emergencyContact: "Emergency Contact",
  emergencyContactDesc: "Contact person, relationship, and contact number",
  emergencyContactPerson: "Emergency Contact Person",
  employed: "Employed",
  employeeDetails: "Employment Details",
  employmentDetails: "Employment Details",
  employmentDetailsDesc: "Status, employer/company, contact details, and address",
  employmentStatus: "Employment Status",
  enterAnEmailAddress: "Enter an email address",
  enterNewPassword: "Enter New password",
  enterOtpVerifyYourEmailAddress: "Enter the OTP to verify your email address.",
  enterPassword: "Enter password",
  everyoneIsDoingWell: "Everyone is doing well.",
  everyoneIsDoingWellDesc: "All member’s risk level is at very low to no risk. Keep it up!",
  everyoneIsSafe: "Everyone is safe and healthy!",
  everyoneIsSafeDesc:
    "All members have their body temperatures within the normal range. Keep it up and stay safe!",
  export: "Export",
  extremelyHigh: "Extremely High",
  fAQsPage: "FAQs page",
  fahrenheit: "Fahrenheit",
  failedToValidateEmail: "Failed to validate email address.",
  fever: "Fever",
  feverCases: "Fever Cases",
  feverTrend: "Fever Trend",
  feverTrendOvertime: "Fever trend over time",
  fillInEmailAddresses:
    "Fill in the email addresses of the people you’d like to join your community.",
  findOutHowCreateOrg: "Find out how to",
  findOutHowNow: "Find out how now.",
  findOutMore: "to find out more!",
  fingerToesDiscoloration: "Fingers/Toes Discoloration",
  finishSettingUpAccount: "Finish setting up your profile in just a few clicks.",
  firstName: "First Name",
  forFurtherQuestions: "For further questions",
  forgotPassword: "Forgot Password",
  fullName: "Full name",
  gateway: "Gateway",
  gatewayId: "Gateway ID",
  gatewayIdOrMacAddress: "Gateway Id or MAC Address",
  gatewayMacAddress: "Gateway MAC Address",
  gatewayName: "Gateway Name",
  gatewayNamePlaceholder: "e.g. ‟Door 1 Gateway”",
  gender: "Gender",
  geolocation: "Geolocation",
  geolocationDesc: "Your location in Google Maps.",
  goodJobAllUserActive: "Good job! All members are active.",
  goodJobAllUserActiveDesc:
    "Everyone has their accessories on. Keep on monitoring their temperatures to maintain a safe and healthy environment.",
  great: "Awesome! A strong password means a more secure account",
  hasNotBeenInContact: "{0} has not been in contact with anyone.",
  hasTheUserTakenAnyMedicalTest: "Has the member taken any medical tests recently?",
  headache: "Headache",
  height: "Height",
  heightInCm: "Height in CM",
  hereWhatHappening: "Here’s what’s happening with",
  highRisk: "High Risk",
  highRiskDesc: "Users that have increasing temperatures from {0} and above for 3 or more days.",
  highTemperature: "High Temperature",
  higherThanNormal: "Higher than Normal",
  hypothermia: "Hypothermia",
  iAgreeToPouchTerms: "I agree to the PouchPASS {0} and {1}.",
  iAuthorizeOrg:
    "I authorize <b>{0}</b> to be able to view, edit, and monitor my PouchPASS profile. I may revoke this authorization from my settings at a later stage.",
  ifAny: "if any.",
  iformationIsImportantNarrative:
    "Information is important and we care about your privacy. Choose which information to share with the communities you join.",
  imageMustBeSmallerThan: "Image must be smaller than",
  in: "in",
  inactive: "Inactive",
  inactiveDesc: "Members who haven’t submitted temperatures in the past 6 hours.",
  individualAtRiskDesc: "Users that might be at risk with actively increasing temperatures.",
  individualsAtRisk: "Individual at Risk",
  inputAccessoryId: "Input Accessory ID",
  invalidCurrentPassword: "Invalid current password.",
  inviteExpiredDesc:
    "Please check your email for new invites sent. If not, kindly contact the community administrator.",
  inviteHasBeenSentTo: "Invite has been sent to {0}",
  inviteManager: "Invite Manager",
  inviteManagerDescription: "Invite others to manage this community as a team.",
  inviteMembers: "Invite members",
  inviteNewManager: "Invite New Manager",
  invitePeopleToCreate:
    "Invite people to create their own PouchPASS account and join your community.",
  invitePouchPassDesc:
    "Assign this profile to a PouchPASS account! Invite a person to join PouchPASS and they’ll be assigned this profile and collected information once they create an account.",
  invitePouchPassNote:
    "Once the invite is accepted, you will no longer be able to add, edit, delete any of their data. They would have full control of the information they choose to share with you and other communities.",
  inviteRequest: "Invite Request",
  inviteRequestDesc: "The following communities are requesting you to join their community.",
  inviteRequestDescManager:
    "By accepting the invite, you will join and become a manager of {0}. They would be able to view your profile and all the information you choose to share with them. You will be able to access their dashboard and manage their members.",
  inviteRequestDescMember:
    "By accepting the invite, you will join and become a member of {0}. They would be able to view your profile and all the information you choose to share with them.",
  inviteSent: "Invite Sent",
  inviteSentDetails: "Invite Sent on {0} by {1}",
  inviteSentMemberDesc: "The member is now unverified until the invite is accepted.",
  inviteTeamMembersAndCreateArea: "Invite Members and Create Areas.",
  inviteTeamMembersAndCreateAreaDescription:
    "Assign members PouchBANDs to record their body temperature in real time. Track their locations easily with <b>Areas</b> or <b>Live Map</b>",
  inviteToPouchPass: "Invite to PouchPASS",
  invitedMembersWouldHave:
    "Invited members would have to accept your invite before you can access their data.",
  invitesSent: "Invites Sent",
  invitingAMember: "Inviting a member would require them to",
  joinedCommunities: "Joined Communities",
  joinedOn: "Joined on {0}",
  keepThisOrg:
    "Keep these communities safe by managing their members and monitoring their temperatures, symptoms, and contact history.",
  kg: "kg",
  lastDateOfContact: "Last Date of Contact",
  lastName: "Last Name",
  lastSynced: "Last Synced",
  lastUpdated: "Last updated",
  latestTemp: "Latest Temp",
  learnMore: "Learn more.",
  leave: "Leave",
  leaveOTPMessage:
    "You have not verified your new email address yet. Leaving this page would not verify your account.",
  leaveOrganization: "Leave Community",
  leavePage: "Leave page",
  leavePageDesc: "You have some changes unsaved. Are you sure you want to leave this page?",
  lessDetails: "Less Details",
  liveMap: "Live Map",
  location: "Location",
  logIn: "Log in",
  logOtherSymptoms: "Log other symptoms or notes",
  logSymptoms: "Log Symptoms",
  logTheirSymptomsNow: "log their symptoms now.",
  login: "Login",
  loginNow: "Login Now",
  loginTo: "Login to",
  logout: "Logout",
  lossOfSmellOrTaste: "Loss of Taste / Smell",
  lossOfSpeechOrMovement: "Loss of Speech / Movement",
  low: "Low",
  lowRisk: "Low Risk",
  lowRiskDesc:
    "Users that were at high to moderate risk, but are recovering with decreasing temperatures back to the normal range.",
  lowTemperature: "Low Temperature",
  manager: "Manager",
  managerRequest: "Manager Request",
  me: "me",
  medicalTest: "Medical Test",
  member: "Member",
  memberDetails: "Member Details",
  memberDetailsPendingMessage:
    "All changes made would be effective on {0} at {1}. If you have any concerns, please contact PouchPASS support for assistance.",
  memberFromUntil: "Member from {0} until {1}",
  memberLocationPrivate: "This member's locations are private.",
  memberProfileOnly: "Member Profile only",
  memberProfileOnlyDesc:
    "Created profiles by managers. These members do not have any access to their profiles. To gain access to their profiles, they would have to be invited to PouchPASS to create an account.",
  memberRequest: "Member Request",
  memberSince: "Member since",
  memberStatus: "Member Status",
  members: "Members",
  mobileNumber: "Mobile Number",
  moderateRisk: "Moderate Risk",
  moderateRiskDesc:
    "Users that have consisent and increasing temperatures between {0} to {1} for 3 or more days.",
  monitorAnySymptoms: "Monitor fever cases, trends, and symptoms.",
  monitorAnySymptomsDescription:
    "Quickly see fever cases for immediate action. Stay on top of everyone’s health by <b>logging any symptoms</b> experienced.",
  monitorYourBodyTemp: "Monitor your body temperature.",
  monitorYourBodyTempDesc:
    "Download the PouchPASS app and always wear your PouchBAND to record your body temperature in real time.",
  myOrganizationDescription: "Manage the communities that can receive your PouchPASS data.",
  myOrganizations: "My Communities",
  name: "Name",
  nameAndProfilePhoto: "Name and Profile Photo",
  nameOfClinicOrHospital: "Name of Clinic or Hospital",
  nameOfOtherTest: "Name of “Other” test",
  nameOfSpecificMedicalTest: "Name of specific medical test",
  nameRemoved: "“{0}” removed.",
  negative: "Negative",
  networkErrorDesc:
    "It’s either poor network connectivity or something went wrong on our side. Sorry about that!",
  newPassword: "New Password",
  newUserAdded: "New member added.",
  next: "Next",
  noAccessory: "No Accessory",
  noAccessoryYet: "No PouchBAND connected",
  noContactDetected: "No Contact Detected",
  noData: "No Data.",
  noDevice: "No Device",
  noLongerAManager: "{0} is no longer a manager.",
  noMemberYet: "No members yet.",
  noMembersFound: "No members found.",
  noName: "no name",
  noNeedForApp: "*No need for app use, but gateways are required to receive PouchBAND data.",
  noRecordsYet: "No records yet",
  noResults: "No results",
  noSymptomsLogged: "No symptoms logged",
  noUserFound: "No users found.",
  noUserYet: "No users yet.",
  normal: "Normal",
  notAllowed: "Not Allowed",
  notAtRisk: "Not at Risk",
  notRequired: "Not Required",
  ohNoYouDontHaveAccess: "Oh no, you don’t have access!",
  okay: "Okay",
  onceYouLeaveOrg:
    "Once you leave a community, you will be removed from their member list and they will no longer receive any of your PouchPASS information.",
  oopsIncorrectEmailOrPass: "Oops! Incorrect email address or password.",
  oopsSomethinngWentWrong: "Oops, something went wrong.",
  optional: "Optional",
  or: "or",
  orCheck: "or check",
  orderOne: "order one",
  orgYouManage: "Communities You Manage",
  organizationSettings: "Community Settings",
  otherLocation: "Other Location",
  ourAvailablePlans: "our available plans.",
  overview: "Overview",
  pageNotFound: "Uh-oh! Page not found.",
  pageNotFoundDesc: "Whoops, you might’ve lost your way. Let’s get you back on track.",
  paired: "Paired",
  pairingStatus: "Pairing Status",
  password: "Password",
  passwordReset: "Password Reset",
  passwordsDoNotMatch: "Passwords do not match.",
  pastOrganizations: "Past Communities",
  pastOrganizationsDesc:
    "These communities no longer have any access to profile or any of your PouchPASS data.",
  past13Days: "Last 13 Days",
  pendingInvites: "Pending Invites",
  pendingInvitesDesc: "People invited to join a community, but haven’t confirmed their invite",
  pendingMemberConfirmation: "Pending member confirmation",
  permanentResidence: "Current Residential Address",
  permissions: "Permissions",
  personalInformation: "Personal Information",
  playStore: "Play Store",
  pleaseCheckForTheOneTimePasswordWeSentTo:
    "Please check for the One-Time Password (OTP) we’ve sent to",
  pleaseCheckYourEmailAndClickMessage:
    "Please check your email and click in the received link to reset your password.",
  pleaseContactOurSupport: "Please contact our PouchPASS Support Team to find out more.",
  pleaseContactPouchPass:
    "Please contact PouchPASS Support Team to learn about their details if needed.",
  pleaseEnterAnEmailInFormat: "Please enter an email address in the format: yourname@example.com",
  pleaseEnterAtLeastOneEmail: "Please enter at least one email address.",
  pleaseFillUpArea: "Please fill up at least one (1) field to create an area.",
  pleaseFillUpSymptom: "Please fill up at least one (1) field to log symptoms.",
  pleaseFillUpUser: "Please fill up at least one (1) field to create a member.",
  pleaseInputCurrentPassword: "Please input your current password.",
  pleaseRequestThePersonProfile:
    "Please request the person to be described in this profile to personally fill out this form with their information.",
  pleaseTryAgain: "Please try again",
  pleaseUseAtleastNthCharacters: "Please use at least {0} characters.",
  poor: "A strong password needs 8 or more characters",
  positive: "Positive",
  pouchBand: "PouchBAND",
  pouchBandDescription: "Add, edit, remove any of your PouchBAND accessories",
  pouchBandForEveryone: "PouchPASS For Family",
  pouchBandStep1:
    "Connect your PouchBAND by entering the accessory ID found on the back of your device.",
  pouchBandStep2: "Download the PouchPASS app in the {0} or {1} to always be connected!",
  pouchBands: "PouchBANDs",
  pouchnationPhilippines: "PouchNATION Philippines",
  privacyPolicy: "Privacy Policy",
  privacySymptomsWarning: "Symptoms of {0} are private.",
  private: "Private",
  privateInformation: "Private Information",
  privateOnlyMe: "Private (only me)",
  profile: "Profile",
  profileAvatarSaved: "Profile Avatar Saved.",
  profileOnly: "Profile Only",
  profileSettings: "Profile Settings",
  rashes: "Rashes",
  recordAndMonitorYourSymptoms: "Record and monitor your symptoms and/or medical test results.",
  recovingWithANormalTemp: "Recovering with a normal temperature. Still, it’s best to check and",
  refreshPage: "Refreshing this page.",
  registrationStart: "Complete the form below to create an account!",
  relationshipPlaceholder: "e.g. ‟Mother, Husband. Guardian”",
  rememberMe: "Remember me",
  rememberYourPassword: "Remember your password",
  rememberYourPasswordQuestion: "Remember your password?",
  remove: "Remove",
  removeAccessory: "Remove PouchBAND",
  removeAccessoryDesc:
    "Are you sure you want to remove this PouchBAND? You will no longer be able to receive any temperature information from this device.",
  removeAsManager: "Remove as Manager",
  removeFromOrganization: "Remove from Community ",
  removeManagerSuccess: "{0} is no longer a manager.",
  removeMember: "Remove Member",
  removed: "removed",
  removedMember: "Removed member.",
  replacePhoto: "Replace Photo",
  required: "Required",
  resendANewOtp: "Resend a New OTP",
  resendInvite: "Resend Invite",
  resendInviteSuccess: "New invite link has been sent to “{0}”.",
  resendNow: "Resend Now",
  resetLinkResent: "Reset link re-sent.",
  resetLinkSent: "Reset link sent",
  resetPassword: "Reset Password",
  resetTokenExpired: "Your password reset request has expired, please start the process again.",
  retry: "Retry",
  retypePassword: "Retype Password",
  reviewTheInformation: "Review the information your sharing",
  revoke: "Revoke",
  revokeInvite: "Revoke Invite",
  revokeInviteSuccess: "Revoked invite for {0}.",
  riskLevel: "Risk Level",
  role: "Role",
  roleAndPermissions: "Role and Permissions",
  sampleAreaDescription: "e.g. ‟2nd Floor East Wing Diego Silang Building”",
  sampleAreaName: "e.g. ‟Room 2306”",
  save: "Save",
  saveAndSendNotif: "Save and Send Notification",
  saveChanges: "Save Changes",
  savePermissions: "Save Permissions",
  searchByUserOrLocation: "Search by name or location",
  searchMemberByNameOrAccessoryId: "Search member by name or accessory ID",
  searchUserByNameOrAccessoryId: "Search user by name or accessory ID",
  seconds: "seconds",
  sections: "Sections",
  seeMore: "See More",
  selectARole: "Select a Role",
  selectBloodType: "Select Blood Type",
  selectCountry: "Select Country",
  selectEmploymentStatus: "Select Employment Status",
  selectFollowingSymptoms: "Select the following symptoms the member has been experiencing:",
  selectGender: "Select Gender",
  selectUserTestResult: "Select the member’s test result",
  selfEmployed: "Self-Employed",
  sendInvite: "Send Invite",
  sendResetLink: "Send Reset Link",
  settings: "Settings",
  setupProfileLater: "Set up profile later",
  sharedInformation: "Shared Information",
  shopNow: "Shop Now",
  shopPouchBand:
    "Shop PouchBANDs for you, your family, and friends. Track <b>multiple PouchBANDs</b> in the PouchPASS app.",
  signIn: "sign-in",
  signingUpAggreement: "By signing up, you agree to the PouchPASS {0} and {1}.",
  someEmailsNotRecognized: "Some emails are not recognized. Please check for errors and try again.",
  somethingWentWrong: "Something Went Wrong.",
  soreThroat: "Sore Throat",
  sorryYouAreNotAuthToAccessThisPage: "Sorry, you are not authorized to access this page.",
  startMonitorTeam: "to start monitoring your team.",
  startTour: "Start Tour",
  startYourPouchBand: "Set up your PouchBAND to start!",
  startbyUploading: "Start by uploading a profile photo!",
  statistics: "Statistics",
  stayOnThisPage: "Stay on this page",
  stayOnTopOfYourHealthAndGetNotified: "Stay on top of your health and get notified.",
  stayOnTopOfYourHealthAndGetNotifiedDesc:
    "Get notifications when things get hot and log any symptoms experienced so you can take precautions and avoid getting sick.",
  stepNth: "Step {0}",
  successChangedPassword:
    "Your password has been changed successfully! You can now use your new password to login to your account.",
  symptomLoggedToDiary: "Symptoms logged to diary.",
  symptomNotFound: "Symptom not found.",
  symptoms: "Symptoms",
  symptomsDescription: "Record and monitor the member’s symptoms and/or medical test results.",
  takeNoteOfTheMedicalTestResultHere: "Take note of the medical test result here",
  teamAndRoles: "Team & Roles",
  teamManagers: "Team Managers",
  tellUsMoreAboutYourself:
    "Tell us a little bit more about yourself by completing your profile about your details, employment status, and emergency contact.",
  temp: "Temp.",
  tempCases: "Temp Cases",
  tempHasBeenIncreasingForThePastDays:
    "Temperature has been increasing for the past 3 days or more. Check and",
  temperature: "Temperature",
  temperatureCases: "Temperature Cases",
  temperatureStatus: "Temperature Status",
  temperatureTrend: "Temperature Trend",
  termsAndConditions: "Terms And Conditions",
  testResultSummaryInfo: "{0} test taken on {1} at {2}.",
  testResults: "Test Results",
  testedNegativeWithCovid: "Tested Negative with Covid-19.",
  testedPositiveWithCovid: "Tested Positive with Covid-19.",
  theOTPYouEnteredIsInvalid: "The OTP you entered is invalid. Please enter the correct code.",
  theyllBeAdded: " They’ll be added to your community once they accept your invite.",
  thisEmailIsAlreadyAMemberOfYourOrg: "This email is already a member of your community.",
  thisMember: "this member",
  thisUserDoesNotExistOn: "This member does not exist on",
  time: "Time",
  tiredness: "Tiredness",
  to: "to",
  toLearnAboutPouchPass: "To learn more how PouchPASS uses your information, view our {0}.",
  today: "Today",
  totalMembers: "Total Members",
  trackEncounter: "Track every encounter with Contact History.",
  trackEncounterDescription:
    "Keep your community safe and slow down the spread of infections by <b>monitoring each member’s close contact interactions.</b>",
  try: "Try",
  trySearchingAgain: "Try searching again.",
  tryToSearchAgain: "or try searching again.",
  typeOfMedicalTest: "Type of Medical Test",
  uhOhEmailNotRegistered: "Uh-oh, that email isn’t registered.",
  unableToLocate: "Unable to locate.",
  unableToLocateMember: "Unable to locate member.",
  unableToLocateUser: "Unable to locate user.",
  unableToRemoveAccessory: "Unable to remove accessory.",
  unableToRetrieveData: "Unable to retrieve data",
  unableToRetrieveDataDesc: "It’s not you.. it’s us. Please check back again in a few minutes.",
  unauthorized: "Unauthorized",
  unbaleToUploadMoreThanMb: "Unable to upload a photo more than 4mb.",
  unemployed: "Unemployed",
  unfortunatelyThisInviteIsExpired: "Unfortunately, this invite is expired.",
  unknowUserContactInformation: "Unknown User {0} is a part of a different community.",
  unknowUserUserId: "Unknown User {0}",
  unverifiedMember: "Unverified Member",
  unverifiedMembersDesc: "Previously created profiles that are now invited to join PouchPASS.",
  uploadAProfile: "Upload a Profile",
  uploadCovidTestCerti: "JPG, PNG, or PDF. Max size of 1MB",
  uploadFile: "Upload File",
  uploadPhoto: "Upload Photo",
  uploadPhotoDescription: "Upload an JPEG or PNG file by {0}. Max 4mb file size.",
  uploadProfile: "Upload Profile",
  uploadTestCertificate: "Upload Test Certificate",
  usePouchPassForYourFamilyOrBusiness: "Use PouchPass for your Family or Business",
  usePouchPassForYourFamilyOrBusinessDesc:
    "Monitor loved ones with PouchPASS. Quickly see {0} for immediate action and track them with {1}. {2}",
  userHasPendingInvite: "User has pending invite.",
  userIsNowAManager: "{0} is now a manager.",
  users: "Users",
  usingAMixOfUppercaseAndLowercase:
    "Using a mix of uppercase and lowercase letters guarantees more security",
  validateEmailAddress: "Validate Email Address",
  verifiedMember: "Verified Member",
  verifiedMembersDesc:
    "People invited that have their own PouchPASS account. They can customize the information shared with communities.",
  verifyEmail: "Verify New Email",
  verifyEmailAddress: "Verify Email Address",
  veryHigh: "Very High",
  veryLowRisk: "Very Low Risk",
  veryLowRiskDesc:
    "Users that have normal to elevated body temperature but not for more then 2 days.",
  view: "View",
  viewAll: "View All",
  viewAllAreas: "View all areas",
  viewAndAddSymptoms: "View and add symptoms and test results.",
  viewInvite: "View Invite",
  viewMyActiveOrg: "View my active communities.",
  viewMyOrgs: "View My Communities",
  viewProfile: "View Profile",
  viewTestResults: "View Test Results",
  viewYourContactInformation: "View your email address and mobile number",
  viewYourNameAndProfilePhoto: "View your full name and profile photo",
  visitOur: "visit our",
  wantToCreateOrg:
    "Want to create your own community? PouchPASS offers multiple plans and packages that would suit your needs! Visit",
  weShouldBack:
    "We’re working on it and we should be back later. If the <s>symptoms persists</s> error persists, please contact PouchPASS support.",
  weak: "Try a combination of letters, numbers, and special characters",
  weight: "Weight",
  weightInKg: "Weight in KG",
  welcomeDescription:
    "Track body temperature, symptoms, contact history, and so much more! Take a quick tour around the PouchPASS Dashboard!",
  welcomeTo: "Welcome to",
  welcomeToPouchPass: "Welcome to PouchPass",
  wellEmbarassing: "Well... this is embarrassing.",
  wellEmbarassingDesc: "Something went wrong on our end. It’s not you - it’s us.",
  whoCanView: "Who can view my profile?",
  withFever: "With Fever",
  workAddress: "Work Address",
  workContactNumber: "Work Contact Number",
  worker: "Worker / Contractual or Part-time",
  wouldBeReceivingInfo: "would be receiving the following information:",
  yesDecline: "Yes, Decline",
  yesLeave: "Yes, leave",
  youAreNotAuthorized: "You are not authorized to access this page.",
  youAreNotInAnyOrg: "You are not in any communities.",
  youAreNotInAnyOrgDesc:
    "Joining a community is by invite only. If you’re a part of a group that has a PouchPASS community, please send them a request to invite you.",
  youCanOnlyUploadJpgPngGifFile: "File not accepted. Please upload a jpeg or png file.",
  youDontHaveOrg: "You don’t have any communities to manage",
  youMay: "you may",
  youReInvitedTo: "You're invited to be a {0} of",
  youReachMaximumOTPAttemps: "You’ve reached  the maximum OTP attempts.",
  yourEmailAddress: "Your email address",
  yourPasswordHasBeenChanged: "Your password has been changed",
  ...pending,
};
export default Object.freeze(locale);
