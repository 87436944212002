import React from "react";
import { AuthLayout } from "layouts";
import { ResetPasswordForm } from "components/auth";

const ResetPassword = () => {
  return (
    <AuthLayout>
      <ResetPasswordForm />
    </AuthLayout>
  );
};

export default ResetPassword;
