import moment from "moment";
import momentTimezone from "moment-timezone";
import { Temperature, AccountStatus } from "enums";

export const isLoggedIn = () => {
  return localStorage.getItem("accessToken");
};

export const validateEmail = (email) => {
  if (!email) {
    return true;
  }
  const regex = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/
  );
  return regex.test(email.toLowerCase().trim());
};

export const isEmailValid = (email) => {
  const regex = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/
  );
  return regex.test(email.toLowerCase().trim());
};

export const isObjectEmpty = (obj) => {
  if (obj && typeof obj === "object") {
    return !Object.keys(obj).length;
  }
  return true;
};

export const pluralize = ({ count, singular, plural }) => {
  return count >= 2 ? plural : singular;
};

export const formatDate = (date, format) => {
  return date && moment(date).format(format);
};

export const formatToLocalDate = (date, format = "MMM DD, YYYY, hh:mm A") => {
  return date && moment(date).local().format(format);
};

export const isBetweenDate = (date, amount, unit) => {
  const a = moment().subtract(amount, unit);
  const b = moment().add(amount, unit);
  return moment(date).isBetween(a, b);
};

export const tellTime = (
  dateTime,
  separateDateAndTime = false,
  dateFormat = "MMMM DD, YYYY",
  timeFormat = "h:mm A"
) => {
  const currentTimeZone = momentTimezone.tz.guess();
  const date = momentTimezone(dateTime).tz(currentTimeZone);
  const timeValue = momentTimezone(date).tz(currentTimeZone).format(timeFormat);
  const dateValue = momentTimezone(date).tz(currentTimeZone).format(dateFormat);

  const today = momentTimezone().tz(currentTimeZone);

  const seconds = moment(today).utc().diff(moment(date).utc()) / 1000;
  let d = "";
  let t = "";
  if (!date.isValid()) {
    return "Invalid Date";
  }

  if (seconds < 0) {
    d = dateValue === moment().format(dateFormat) ? "Today" : dateValue;
    t = timeValue;
  } else if (seconds < 2) {
    d = "Just now";
  } else if (seconds < 60) {
    d = `${Number(seconds).toFixed(0)} second${seconds > 1 ? "s" : ""} ago`;
  } else if (seconds < 3600) {
    const minutes = seconds / 60;
    d = `${Number(minutes).toFixed(0)} minute${Number(minutes.toFixed(0)) > 1 ? "s" : ""} ago`;
  } else if (seconds < 7200) {
    d = "an hour ago";
  } else if (dateValue === moment().format(dateFormat)) {
    d = "Today";
    t = timeValue;
  } else if (dateValue === moment().subtract(1, "days").format(dateFormat)) {
    d = "Yesterday";
    t = timeValue;
  }
  if (d && t) {
    if (!separateDateAndTime) {
      return `${d} at ${t}`;
    }
    return {
      date: d,
      time: t,
    };
  } else if (d) {
    if (!separateDateAndTime) {
      return d;
    }
    return {
      date: d,
      time: "",
    };
  }
  if (!separateDateAndTime) {
    return `${dateValue} ${timeValue}`;
  }
  return {
    date: dateValue,
    time: timeValue,
  };
};

export const ellipsis = (text, max) => {
  if (text && text.length > max) {
    const ellipText = `${text.substring(0, max)}...`;
    return ellipText;
  }
  return text.trim();
};

export const arrayObjectToSelectData = (data = [], name, value) => {
  return data.map((d) => {
    return {
      text: d[name],
      value: d[value],
    };
  });
};

export const getLocalItem = (key) => {
  return localStorage.getItem(key);
};

export const setLocalItem = (key, value) => {
  localStorage.setItem(key, value);
};

export const setLocalItems = (keyVal) => {
  Object.keys(keyVal).forEach((key) => {
    localStorage.setItem(key, keyVal[key]);
  });
};

export const removeLocalItem = (key) => {
  if (Array.isArray(key)) {
    for (let x = 0; x < key.length; x++) {
      localStorage.removeItem(key[x]);
    }
  } else {
    localStorage.removeItem(key);
  }
};

export const getTemperature = () => {
  return getLocalItem("temperatureType") || Temperature.Celsius;
};

export const temperatureSymbol = (temp = "") => {
  if (!temp) {
    temp = getTemperature();
  }
  switch (temp) {
    case Temperature.Celsius:
      return "°C";
    case Temperature.Fahrenheit:
      return "°F";
    default:
      return "°C";
  }
};

export const convertTemperature = (t = 0, type = "") => {
  const temp = Number(t);
  if (!type) {
    type = getTemperature();
  }
  switch (type) {
    case Temperature.Celsius:
      if (temp) {
        return Number(temp).toFixed(1);
      }
      return "";
    case Temperature.Fahrenheit:
      if (temp) {
        return Number((temp * 9) / 5 + 32).toFixed(1);
      }
      return "";
    default:
      return "";
  }
};

export const isNormalTemp = (t, type = getTemperature()) => {
  const temp = Number(t);
  if (type === Temperature.Fahrenheit) {
    return temp >= 95.1 && temp <= 99.5;
  }
  return temp >= 35.1 && temp <= 37.5;
};

export const formatTemperature = (temp = null, type = getTemperature()) => {
  if (!type) {
    type = getTemperature();
  }
  return `${temp ? convertTemperature(temp) : "--"} ${temperatureSymbol()}`;
};

export const sortByExcelLogic = (key = null) => {
  // Sort by numbers first, then special characters, then alphabet.
  // Refer to: https://pouchnation.atlassian.net/wiki/spaces/POUCHNATIO/pages/5570789/Sorting?focusedCommentId=8781924#comment-8781924

  const orderAlpha = "abcdefghijklmnopqrstuvwxyz";
  const orderNumber = "0123456789";
  const ap = Array.prototype;

  let totalNumbers = 0;
  const orderMapNum = {};
  ap.forEach.call(orderNumber, (char, idx) => {
    orderMapNum[char] = idx + 1;
    totalNumbers++;
  });

  const orderMapAlpha = {};
  ap.forEach.call(orderAlpha, (char, idx) => {
    orderMapAlpha[char] = idx + totalNumbers + 2;
  });

  function compareChars(l, r) {
    const lOrder = orderMapNum[l] || orderMapAlpha[l] || totalNumbers + 1;
    const rOrder = orderMapNum[r] || orderMapAlpha[r] || totalNumbers + 1;

    return lOrder - rOrder;
  }

  function compareStrings(l, r) {
    l = key ? l[key].toLowerCase() : l;
    r = key ? r[key].toLowerCase() : r;
    const minLength = Math.min(l.length, r.length);
    const result = ap.reduce.call(
      l.substring(0, minLength),
      (prev, _, i) => {
        return prev || compareChars(l[i], r[i]);
      },
      0
    );

    return result || l.length - r.length;
  }

  return compareStrings;
};

export const computeTableScrollY = (nodeList, maxCount) => {
  var arr = Array.from(nodeList);
  if (!maxCount || arr.length <= maxCount) {
    return 0;
  }
  return arr.slice(0, maxCount).reduce((acc, cur) => {
    return acc + cur.clientHeight;
  }, 0);
};

export const getInitials = (phrases = []) => {
  if (!phrases || !phrases?.length) {
    return "";
  }
  return phrases
    .map((phrase) => {
      return phrase?.[0] || "";
    })
    .join("");
};

export const getFullName = ({ firstName, lastName, empty }) => {
  empty = empty ? empty : "-";
  return firstName || lastName
    ? `${firstName ? firstName : ""} ${lastName ? lastName : ""}`.trim()
    : `${empty}`.trim();
};

export const getOrgLocation = () => {
  const l = getLocalItem("organizationLocation")
    ? getLocalItem("organizationLocation").split(",")
    : [0, 0];
  return {
    latitude: Number(l[0]),
    longitude: Number(l[1]),
  };
};

const timerIds = [];

export const debounced = (delay, fn) => {
  let timerId;
  return function (...args) {
    if (timerId) {
      clearTimeout(timerId);
    }

    timerId = setTimeout(() => {
      // trigger function only on last hit
      if (timerIds[timerIds.length - 1] === timerId) {
        fn(...args);
      }
      timerId = null;
    }, delay);
    timerIds.push(timerId);
  };
};

export const metersPerPixel = (zoom, lat) => {
  return Math.floor(
    ((Math.cos((lat * Math.PI) / 180) * 2 * Math.PI * 6378137) / (256 * Math.pow(2, zoom))) * 1000
  );
};

export const tellDuration = (
  seconds,
  labels = {
    day: ["day", "days"],
    hour: ["hr", "hrs"],
    minute: ["min", "mins"],
  }
) => {
  const duration = moment.duration(seconds, "seconds");
  const hours = duration.asHours();
  const minutes = duration.asMinutes();
  const days = duration.asDays();
  if (seconds < 0) {
    return null;
  }
  if (days >= 1) {
    return `${Number(days).toFixed(0)} ${pluralize({
      count: days,
      singular: labels.day[0],
      plural: labels.day[1],
    })}`;
  } else if (hours >= 1) {
    return `${Number(hours).toFixed(0)} ${pluralize({
      count: hours,
      singular: labels.hour[0],
      plural: labels.hour[1],
    })}`;
  } else {
    return `${Number(minutes).toFixed(0)} ${pluralize({
      count: minutes,
      singular: labels.minute[0],
      plural: labels.minute[1],
    })}`;
  }
};

export const chunkArray = (myArray, chunk_size) => {
  let index = 0;
  let arrayLength = myArray.length;
  let tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    let myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const getUserRole = (userRoles = []) => {
  const managerRole = userRoles.find((ur) => {
    return ur.role === AccountStatus.MANAGER;
  });

  if (managerRole) {
    return AccountStatus.MANAGER;
  }
  return AccountStatus.MEMBER;
};

export const isInt = (n) => {
  return Number(n) === n && n % 1 === 0;
};

export const isFloat = (n) => {
  return Number(n) === n && n % 1 !== 0;
};

export const isNumber = (num) => {
  if (typeof num === "string") {
    num = Number(num);
  }
  return typeof num === "number" && !isNaN(num);
};

export const capitalize = (s) => {
  if (!s) {
    return s;
  }
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

export const redirectTo = (url) => {
  window.location.href = url;
};

export const reload = () => {
  window.location.reload();
};

export const clearLocalStorage = () => {
  localStorage.clear();
};

export const validateInput = ({ pattern, event }) => {
  const { target, keyCode, which } = event;
  const currentKey = String.fromCharCode(keyCode || which);
  let currentValue = target.value;

  const start = target.selectionStart;
  const end = target.selectionEnd;
  const firstPart = currentValue.toString().substring(0, start);
  const lastPart = currentValue.toString().substring(end);
  currentValue = `${firstPart}${currentKey}${lastPart}`;

  const isValidKey = pattern.test(currentKey);
  const isValidValue = pattern.test(currentValue);
  return isValidKey || (currentValue && isValidValue);
};

export const protectEmail = (email) => {
  const [username, rootDomain] = email.split("@");
  const [domName, domExt] = rootDomain.split(".");

  const stringAvg = (str) => {
    return Math.ceil(str.length / 2);
  };
  const hiddenPart = (str, avg) => {
    return str.substr(1, str.length - avg);
  };
  const hiddenPartMask = (str) => {
    return str
      .split("")
      .map(() => {
        return "*";
      })
      .join("");
  };

  const userNameAvg = stringAvg(username);
  const domNameAvg = stringAvg(domName);
  const hiddenPartUserName = hiddenPart(username, userNameAvg);
  const hiddenPartDomName = hiddenPart(domName, domNameAvg);
  const maskUserName = hiddenPartMask(hiddenPartUserName);
  const maskDomName = hiddenPartMask(hiddenPartDomName);

  const hiddenUserName = username.replace(hiddenPartUserName, maskUserName);
  const hiddenDomName = domName.replace(hiddenPartDomName, maskDomName);
  return `${hiddenUserName}@${hiddenDomName}.${domExt}`;
};

export const checkIfObjectValuesAreEqual = (obj1, obj2) => {
  // sorting of keys must match, else this will not work
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};

export const sortArrObject = (arr = [], propName) => {
  const arrayToSort = [...arr];
  arrayToSort.sort((a, b) => a[propName] - b[propName]);
  return arrayToSort;
};

export const formatNumberToOrdinal = (num = 0) => {
  let lastDigit = Number(num.toString().charAt(num.toString().length - 1));
  if ([11, 12, 13].includes(num)) {
    lastDigit = null;
  }
  switch (lastDigit) {
    case 1:
      return `${num}st`;
    case 2:
      return `${num}nd`;
    case 3:
      return `${num}rd`;
    default:
      return `${num}th`;
  }
};
