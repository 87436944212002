import React from "react";
import { Modal } from "atomic/atoms";
import localStrings from "localization";
import styles from "./deactivate-community-modal.module.scss";
import { ActionButton } from "components/common";

const DeactivateCommunityModal = ({ visible, close, submit, loading }) => {
  return (
    <Modal
      className={styles.modal}
      visible={visible}
      title={`${localStrings.deactivateCommunity}?`}
      onCancel={close}
    >
      <p className={styles.message}>{localStrings.areYouSureYouWantToDeactivateCommunity}</p>
      <ActionButton
        loading={loading}
        onCancel={close}
        submitText={localStrings.deactivate}
        isSubmitDanger={true}
        onSubmit={submit}
      />
    </Modal>
  );
};

export default DeactivateCommunityModal;
