import { v4 as uuidv4 } from "uuid";
import localStrings from "localization";

export const communityTableColumns = [
  {
    key: uuidv4(),
    dataIndex: "name",
    title: localStrings.name,
    width: 220,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "location",
    title: localStrings.location,
    width: 220,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "dateCreated",
    title: localStrings.dateCreated,
    width: 80,
  },
  {
    key: uuidv4(),
    dataIndex: "status",
    title: localStrings.status,
    width: 60,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "menu",
    title: "",
    width: 50,
    ellipsis: true,
  },
];
