export const userState = {
  firstName: "",
  lastName: "",
  dob: "",
  gender: "",
  avatar: "",
  address: "",
  company: "",
  employmentStatus: "",
  workAddress: "",
  workCountryCode: "",
  workPhone: "",
  bloodType: "",
  height: "",
  weight: "",
  contactName: "",
  contactRelationship: "",
  contactNumber: "",
  contactCountryCode: "",
  isOnboarded: null,
};
