import { Symptoms } from "enums";
import localStrings from "localization";

// export const convertSymptomEnumToString = (enumeration) => {

// };

export const symptomString = {
  [Symptoms.COUGH]: localStrings.cough,
  [Symptoms.TIREDNESS]: localStrings.tiredness,
  [Symptoms.BODY_ACHE]: localStrings.bodyAche,
  [Symptoms.SORE_THROAT]: localStrings.soreThroat,
  [Symptoms.DIARRHEA]: localStrings.diarrhea,
  [Symptoms.CONJUNCTIVITIS]: localStrings.conjunctivitis,
  [Symptoms.HEADACHE]: localStrings.headache,
  [Symptoms.LOSS_OF_TASTE_SMELL]: localStrings.lossOfSmellOrTaste,
  [Symptoms.RASHES]: localStrings.rashes,
  [Symptoms.CHEST_PAINS]: localStrings.chestPains,
  [Symptoms.DIFFICULTY_BREATHING]: localStrings.difficultyBreathing,
  [Symptoms.FINGER_TOES_DISCOLORATION]: localStrings.fingerToesDiscoloration,
  [Symptoms.LOSS_OF_SPEECH_MOVEMENT]: localStrings.lossOfSpeechOrMovement,
};
