import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "./vfs_fonts";
pdfMake.fonts = {
  rubik: {
    normal: "Rubik-Regular.ttf",
    bold: "Rubik-Medium.ttf",
  },
};
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfMake.tableLayouts = {
  contactHistoryLayout: {
    hLineWidth: function (i, node) {
      if (i === 0 || i === node.table.body.length) {
        return 0;
      }
      return i === node.table.headerRows ? 0 : 1;
    },
    vLineWidth: function (i) {
      return 0;
    },
    hLineColor: function (i, node) {
      return i === node.table.headerRows ? "" : "#DBE9ED";
    },
  },
};

export const prepareDocDefinition = ({
  title,
  tableColumns = ["NAME", "DATE OF CONTACT", "DURATION", "LOCATION/AREA"],
  tableColumsWidths = ["*", 80, 60, "*"],
  tableData,
  startDate: sDate,
  endDate: eDate,
}) => {
  const startDate = sDate.format("MMMM DD, YYYY");
  const endDate = eDate.format("MMMM DD, YYYY");
  const columns = tableColumns.map((text) => {
    return {
      text,
      fontSize: 10,
      color: "#7A7984",
      margin: [0, 0, 8, 0],
      bold: true,
    };
  });
  return {
    pageSize: "A4",
    pageMargins: [32, 40, 32, 40],
    defaultStyle: {
      font: "rubik",
    },
    content: [
      // if you don't need styles, you can use a simple string to define a paragraph
      {
        text: "Contact History",
        fontSize: 13,
        margin: [0, 0, 0, 8],
        bold: true,
      },
      {
        text: title,
        fontSize: 20,
        bold: true,
        color: "#0A3637",
        margin: [0, 0, 0, 8],
      },
      // using a { text: '...' } object lets you set styling properties
      {
        text: `from ${startDate} to ${endDate}`,
        fontSize: 12,
        margin: [0, 0, 0, 18],
      },

      // if you set the value of text to an array instead of a string, you'll be able
      // to style any part individually
      {
        layout: "contactHistoryLayout",

        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          dontBreakRows: true,
          widths: tableColumsWidths,
          body: [columns, ...tableData],
        },
      },
    ],
    footer: function (currentPage, pageCount) {
      return {
        columns: [
          {
            stack: [`Contact History of ${title}`, `${startDate} to ${endDate}`],
            fontSize: 10,
            color: "#AAADB9",
            margin: [32, 0, 0, 0],
          },
          {
            alignment: "right",
            color: "#AAADB9",
            fontSize: 10,
            stack: ["PouchPASS", `Page ${currentPage} of ${pageCount}`],
            margin: [0, 0, 32, 0],
          },
        ],
      };
    },
  };
};

export const downloadPDF = (docDefinition) => {
  const win = window.open("", "_blank");
  return pdfMake.createPdf(docDefinition).open({}, win);
};
