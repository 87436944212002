import React, { useMemo } from "react";
import { Table, Link, Image } from "atomic/atoms";
import styles from "./details.module.scss";
import classnames from "classnames";
import { UnableToRetrieveError } from "pages";
import { useRouter } from "hooks";

const Details = ({
  title,
  description,
  linkText,
  linkTo,
  columns,
  data,
  className,
  viewAllCount,
  viewAllText,
  loading,
  emptyRenderer,
  viewAllLink,
  removeCount,
  scroll,
  onRowClick,
  noData = {
    title: "",
    description: "",
    image: "",
    className: "",
  },
  error,
}) => {
  const { history } = useRouter();
  const hasNoData = useMemo(() => {
    return !loading && data.length <= 0;
  }, [loading, data]);

  const renderContent = useMemo(() => {
    if (error) {
      return <UnableToRetrieveError />;
    }
    if (hasNoData) {
      const { title, description, image, className } = noData;
      return (
        <div className={classnames(styles.noDataContainer, className)}>
          <h2 className={classnames(styles.noDataTitle, "noDataTitle")}>{title}</h2>
          <div className={classnames(styles.noDataDescription, "noDataDescription")}>
            {description}
          </div>
          <Image src={image} className={classnames(styles.noDataImage, "noDataImage")} />
        </div>
      );
    }
    return (
      <Table
        loading={loading}
        className={styles.table}
        columns={columns}
        data={data}
        emptyRenderer={emptyRenderer}
        scroll={scroll}
        onRowClick={onRowClick}
      />
    );
  }, [columns, data, emptyRenderer, error, hasNoData, loading, noData, onRowClick, scroll]);

  return (
    <div
      className={classnames(styles.container, className, {
        [`${styles.noData}`]: hasNoData && !error,
      })}
    >
      {title ? (
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <h3 className={styles.title}>{title}</h3>
            {linkText && <Link text={linkText} to={linkTo} />}
          </div>

          <p className={styles.description}>{description}</p>
        </div>
      ) : null}

      {renderContent}
      {viewAllText && viewAllCount > 0 && !loading && !error && (
        <div className={styles.footer}>
          <Link className={styles.viewAll} onClick={() => history.push(viewAllLink)}>
            {viewAllText} {!removeCount && viewAllCount ? `(${viewAllCount})` : null}
          </Link>
        </div>
      )}
    </div>
  );
};

export default Details;
