import React, { useReducer, useContext, useState } from "react";
import { UsersLocation, UsersList } from "components/live-map";
import { LiveMapContext, OrganizationContext } from "contexts";
import styles from "./live-map.module.scss";
import LiveMapReducer, { initialState } from "reducers/live-map-reducer";
import classnames from "classnames";
import { useMount } from "hooks";

const LiveMapContainer = ({ isMiniMap }) => {
  const { latitude: orgLat, longitude: orgLng } = useContext(OrganizationContext);
  const [state, dispatch] = useReducer(LiveMapReducer, initialState);
  const {
    currentLocation: { currentLocLoading },
  } = state;
  const [browserLocation, setBrowserLocation] = useState({});
  useMount(() => {
    if (orgLat && orgLng) {
      dispatch({
        type: "CHANGE_CURRENT_LOCATION",
        data: {
          latitude: orgLat,
          longitude: orgLng,
        },
      });
      return;
    }
    if ((!orgLat || !orgLng) && navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const {
          coords: { latitude: lat, longitude: lng },
        } = position;
        setBrowserLocation({
          lat,
          lng,
        });
        dispatch({
          type: "CHANGE_CURRENT_LOCATION",
          data: {
            latitude: lat,
            longitude: lng,
          },
        });
      });
      return;
    }
    console.warn("Geolocation is not supported by this browser.");
  });
  return (
    <LiveMapContext.Provider
      value={{
        state,
        dispatch,
        isMiniMap,
      }}
    >
      <div
        className={classnames(styles.container, {
          [styles.minimap]: isMiniMap,
        })}
      >
        <UsersList
          defaultLocation={{
            lat: orgLat || browserLocation.lat,
            lng: orgLng || browserLocation.lng,
          }}
        />
        {currentLocLoading || <UsersLocation />}
      </div>
    </LiveMapContext.Provider>
  );
};

export default LiveMapContainer;
