import React from "react";
import classnames from "classnames";
import styles from "./image.module.scss";
import PropTypes from "prop-types";

const Image = ({ src, alt, className, onClick }) => {
  return (
    <img
      onClick={onClick}
      className={classnames(className, styles.image, "image")}
      src={src}
      alt={alt}
    />
  );
};

Image.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Image.defaultProps = {
  alt: "",
};

export default Image;
