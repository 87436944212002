import React, { useEffect, useMemo } from "react";
import { Select as AntSelect } from "antd";
import { Common } from "enums";
import styles from "./select.module.scss";
import classnames from "classnames";
import { Icon } from "atomic/atoms";

const { Option } = AntSelect;

const Select = ({
  data = [],
  value,
  disabled,
  placeholder,
  children,
  multiple,
  onChange = () => null,
  selectAllText = "All",
  name,
  dropdownRender,
  suffixIcon,
  maxTagCount,
  maxTagTextLength,
  dropdownClassName,
  showSearch,
  className,
  loading,
  onBlur = () => null,
}) => {
  useEffect(() => {
    if (value !== null && multiple) {
      const isAllDataSelected = value.length === data.length && !value.includes(Common.ALL);
      if (isAllDataSelected) {
        onChange([Common.ALL], name);
      }
    }
  }, [value, data, onChange, name, multiple]);

  const handleOnChange = (value) => {
    let onChangeValue = value;
    if (multiple) {
      const lastIndex = value.length - 1;
      const isAllDataSelected = value.length === data.length && !value.includes(Common.ALL);
      if (isAllDataSelected || value[lastIndex] === Common.ALL) {
        onChangeValue = [Common.ALL];
      } else if (value[0] === Common.ALL) {
        onChangeValue = value.filter((d) => d !== Common.ALL);
      } else if (!value.length) {
        onChangeValue = [Common.ALL];
      }
    }
    onChange(onChangeValue, name);
  };

  const handleOnBlur = (value) => {
    onBlur(value);
  };

  const selectValue = useMemo(() => {
    let selectValue = value;
    if (multiple && value && value.length < 0) {
      selectValue = null;
    }
    return typeof selectValue === "number" || selectValue ? selectValue : undefined;
  }, [multiple, value]);

  return (
    <AntSelect
      className={classnames(styles.select, className)}
      showSearch={showSearch}
      disabled={disabled}
      placeholder={placeholder}
      showArrow={true}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      mode={multiple && "multiple"}
      value={selectValue}
      suffixIcon={loading ? <Icon loading={loading} /> : suffixIcon}
      dropdownRender={dropdownRender}
      defaultActiveFirstOption={false}
      maxTagCount={maxTagCount}
      maxTagTextLength={maxTagTextLength}
      dropdownClassName={dropdownClassName}
    >
      {multiple && <Option value={Common.ALL}>{selectAllText}</Option>}
      {children ||
        data.map((d, i) => {
          return (
            <Option key={i} value={d.value}>
              {d.text}
            </Option>
          );
        })}
    </AntSelect>
  );
};

export default Select;
