import React, { useMemo } from "react";
import styles from "./diary.module.scss";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import * as moment from "moment";
import { Temp } from "atomic/atoms";
import { Details, TemperatureAverage } from "atomic/molecules";

const DiaryToday = ({ result, loading, error }) => {
  const tableColumns = [
    {
      key: uuidv4(),
      dataIndex: "time",
      title: localStrings.time,
      width: 25,
    },
    {
      key: uuidv4(),
      dataIndex: "temperature",
      title: localStrings.temperature,
      width: 20,
    },
    {
      key: uuidv4(),
      dataIndex: "location",
      title: localStrings.areaLocation,
      width: 100,
      ellipsis: true,
    },
  ];

  const tableData = useMemo(() => {
    if (!result) {
      return [];
    }
    return result.map((d) => {
      const { hour, bodyTemp, location } = d || {};
      return {
        key: uuidv4(),
        // time: hour ? moment(`${hour}Z`, "hhAZ").format("hh A") : "-",
        time: hour ? moment(hour, "hhA").format("hh A") : "-",
        temperature: <Temp temp={bodyTemp} />,
        location: <div className={styles.locationColumn}>{location}</div>,
      };
    });
  }, [result]);

  return !loading && !result?.length && !error ? null : (
    <>
      <div className={styles.titleToday}>
        <h2>{localStrings.today}</h2>
      </div>
      <TemperatureAverage data={result} />
      <Details
        className={styles.table}
        columns={tableColumns}
        loading={loading}
        data={tableData}
        scroll={{
          y: 520,
        }}
        error={error}
      />
    </>
  );
};

export default DiaryToday;
