import React from "react";
import styles from "./member-list-header.module.scss";
import localStrings from "localization";
import { SelectTempType, Button } from "atomic/molecules";
import { DropdownMemberReports } from "..";

const MemberListHeader = ({ changeTempType, tempType, setShowAddMemberModal }) => {
  return (
    <div className={styles.header}>
      <h2 className={styles.title}>{localStrings.members}</h2>
      <Button
        className={styles.addNew}
        text={localStrings.addNew}
        onClick={() => setShowAddMemberModal(true)}
      />

      <div className={styles.generateButton}>
        <DropdownMemberReports />
      </div>

      <SelectTempType className={styles.selectTemp} value={tempType} onChange={changeTempType} />
    </div>
  );
};

export default MemberListHeader;
