import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext, OrganizationContext } from "contexts";

const OnboardingRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(UserContext);
  const organization = useContext(OrganizationContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.userId > 0 && organization !== undefined) {
          if (user.isOnboarded) {
            if ((organization && !organization.isPrivacySet) || !organization) {
              return <Component {...props} />;
            }
            return <Redirect to="/" />;
          }
          return <Component {...props} />;
        }
        return null;
      }}
    />
  );
};

export default OnboardingRoute;
