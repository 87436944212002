import React from "react";
import { CommunityFormContainer } from "containers";
import localStrings from "localization";
import { createCommunity } from "api/organization";
import { useApi } from "hooks";

const CreateCommunityContainer = () => {
  const { request, loading } = useApi({
    api: createCommunity,
    handleOwnError: {
      badrequest: true,
    },
  });

  const submit = async (params, success, error) => {
    try {
      const data = await request(params);
      success(data, {
        message: `${params.params.organizationName} has been created.`,
      });
    } catch (e) {
      error(e);
    }
  };
  return (
    <CommunityFormContainer
      title={localStrings.createACommunity}
      strings={{
        submitText: "Create Community",
      }}
      saveSubmit={submit}
      loading={loading}
      isActiveManagerEditable={true}
    />
  );
};

export default CreateCommunityContainer;
