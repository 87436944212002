import localStrings from "localization";

const Requirement = {
  Required: "REQUIRED",
  NotRequired: "NOTREQUIRED",
};

export const RequirementOptions = [
  {
    text: localStrings.required,
    value: Requirement.Required,
  },
  {
    text: localStrings.notRequired,
    value: Requirement.NotRequired,
  },
];

export default Object.freeze(Requirement);
