import React, {
  useState,
  Fragment, //useCallback,
  useContext,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import EnvelopeImage from "images/envelope.svg";
import PageFormImage from "images/page-form.svg";
import localStrings from "localization";
import styles from "./member-add-new-modal.module.scss";
import { useApi, useRouter } from "hooks";
import SelectButton from "./select-button";
import {
  bulkInvite,
  bulkValidateInvite,
  // bulkValidateInvite
} from "api/user-organization";
import localString from "localization";
import { AppContext, OrganizationContext } from "contexts";
import path from "path/path";
import mixpanel from "mixpanel-browser";
import { AccountStatus, Mixpanel } from "enums";
import { Modal, Icon } from "atomic/atoms";
import { Toast, Button, InputPill } from "atomic/molecules";
import { validateEmail } from "utils";

const MemberAddNewModal = ({ visible, closeModal, fetchUsers }) => {
  const { history } = useRouter();
  const [value, setValue] = useState("");
  const { organizationId } = useContext(OrganizationContext);
  const [triggerRequiredError, setTriggerRequiredError] = useState(false);

  const { request: validateEmailRequest, loading: validatingEmails } = useApi({
    api: bulkValidateInvite,
    handleOwnError: { badrequest: true },
  });

  const { dispatch: appDispatch } = useContext(AppContext);
  const [isSelectionPage, setIsSelectionPage] = useState(true);
  const [emailField, setEmailField] = useState({
    errorInput: false,
    errorInputMessage: "",
  });
  const [pills, setPills] = useState([]);

  const checkIfPillsHasError = useMemo(() => {
    return pills.some((p) => {
      return p.error === true;
    });
  }, [pills]);

  useEffect(() => {
    if (visible && !isSelectionPage) {
      setPills([]);
      setValue("");
      setTriggerRequiredError(false);
    }
  }, [visible, isSelectionPage]);

  const validatePillsFromApi = useCallback(
    async (validatedPills, validEmailsFiltered) => {
      try {
        await validateEmailRequest({
          organizationId,
          emails: validEmailsFiltered,
          roles: [AccountStatus.MEMBER],
        });
        setPills(validatedPills);
      } catch (err) {
        if (err.status === 400) {
          const errors = err.metadata.response.map((e) => {
            return {
              email: e.additionalInformation.email,
              errorMessage: e.message.replace("user", e.additionalInformation.email),
            };
          });
          for (let error of errors) {
            let validatedPill = validatedPills.find((vp) => {
              return vp.value === error.email;
            });
            if (validatedPill) {
              validatedPill.error = true;
              if (validatedPill.errorMessage) {
                validatedPill.errorMessage = [validatedPill.errorMessage, error.errorMessage];
              } else {
                validatedPill.errorMessage = error.errorMessage;
              }
            }
          }
          setPills(validatedPills);
        }
      }
    },
    [organizationId, validateEmailRequest]
  );

  const validatePills = useCallback(
    async (pills) => {
      if (pills.length > 0) {
        const validatedPills = pills.map((p) => {
          const invalidEmail = !validateEmail(p.value);

          return {
            ...p,
            error: invalidEmail,
            errorMessage: invalidEmail ? `${p.value} is not a valid email address.` : "",
          };
        });

        const validEmailsFiltered = validatedPills
          .filter((vp) => {
            return vp.error === false;
          })
          .map((fp) => {
            return fp.value;
          });
        if (validEmailsFiltered.length > 0) {
          await validatePillsFromApi(validatedPills, validEmailsFiltered);
        } else {
          setPills(validatedPills);
        }
      } else {
        setPills([]);
      }
      return pills;
    },
    [validatePillsFromApi]
  );

  const submit = useCallback(async () => {
    setTriggerRequiredError(false);
    let p = null;
    // if (validatingEmails) {
    //   setToSubmit(true);
    //   return false;
    // }

    if (value) {
      p = await validatePills([
        ...pills,
        {
          value,
        },
      ]);
    }

    const values = p || pills;

    if (values.length <= 0) {
      setEmailField({
        ...emailField,
        errorInputMessage: localStrings.pleaseEnterAtLeastOneEmail,
      });
      setTriggerRequiredError(true);
      return false;
    }

    try {
      await bulkInvite({
        organizationId: localStorage.getItem("organizationId"),
        emails: values.map((p) => {
          return p.value;
        }),
        roles: ["MEMBER"],
      });
      mixpanel.track(Mixpanel.INVITE_MEMBERS);
      Toast({
        content: localString.invitesSent,
        error: false,
        success: true,
      }).open();
      closeModal();
      setTimeout(() => {
        setIsSelectionPage(true);
      }, 500);
      fetchUsers({ currentPage: 1 });
    } catch (err) {
      const { networkError, serverError } = err;
      if (networkError) {
        appDispatch({ type: "NETWORK_ERROR" });
      }
      if (serverError) {
        appDispatch({ type: "SERVER_ERROR" });
      }

      if (err.status === 400) {
        const errors = err.metadata.response.map((e) => {
          return {
            email: e.additionalInformation.email,
            errorMessage: e.message.replace("user", e.additionalInformation.email),
          };
        });
        for (let error of errors) {
          let validatedPill = values.find((vp) => {
            return vp.value === error.email;
          });
          if (validatedPill) {
            validatedPill.error = true;
            if (validatedPill.errorMessage) {
              validatedPill.errorMessage = [validatedPill.errorMessage, error.errorMessage];
            } else {
              validatedPill.errorMessage = error.errorMessage;
            }
          }
        }
      }
    }
  }, [value, appDispatch, closeModal, emailField, fetchUsers, pills, validatePills]);

  return (
    <Modal
      visible={visible}
      title={isSelectionPage ? `${localStrings.addNewMembers}` : `${localStrings.inviteMembers}`}
      onCancel={() => {
        closeModal();
        setTimeout(() => {
          setIsSelectionPage(true);
        }, 500);
      }}
      closable={true}
      className={styles.modal}
    >
      <div>
        {isSelectionPage && (
          <Fragment>
            <SelectButton
              image={EnvelopeImage}
              title={localStrings.inviteMembers}
              description={localStrings.invitePeopleToCreate}
              note={localStrings.invitedMembersWouldHave}
              onClick={() => {
                setIsSelectionPage(false);
              }}
            ></SelectButton>
            <SelectButton
              image={PageFormImage}
              title={localStrings.addAMember}
              description={localStrings.addMemberAndProfile}
              note={localStrings.noNeedForApp}
              onClick={() => {
                history.push(path.MEMBER_CREATE);
              }}
            ></SelectButton>
          </Fragment>
        )}
        {!isSelectionPage && (
          <div>
            <div className={styles.subheader}>{localStrings.fillInEmailAddresses}</div>
            <div className={styles.warning}>
              <Icon className={styles.info} name="info-fill" />
              <div className={styles.warningText}>
                <div>
                  {localString.invitingAMember} <strong>{localString.signIn}</strong>{" "}
                  {localString.or} <strong>{localString.createPPaccount}</strong>{" "}
                  {localString.theyllBeAdded}
                </div>
              </div>
            </div>
            <div className={styles.field}>
              <div className={styles.fieldTitle}>{localStrings.emailAddresses}</div>
              <InputPill
                loading={validatingEmails}
                required={true}
                requiredMessage={localString.pleaseEnterAtLeastOneEmail}
                pills={pills}
                onPillChange={(pills) => validatePills(pills)}
                value={value}
                setValue={setValue}
                triggerRequiredError={triggerRequiredError}
              />
            </div>
            <div className={styles.actions}>
              <Button
                text={localStrings.back}
                tertiary
                onClick={() => {
                  setIsSelectionPage(true);
                }}
              />
              <Button
                primary
                text={localStrings.sendInvite}
                onClick={(e) => {
                  submit();
                  e.preventDefault();
                }}
                disabled={checkIfPillsHasError}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default MemberAddNewModal;
