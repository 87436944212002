import React from "react";
import { Avatar, Button } from "atomic/molecules";
import styles from "./invite-request.module.scss";
import localStrings from "localization";
import { AccountStatus } from "enums";
import Skeleton from "react-loading-skeleton";

const InviteRequestItem = ({
  organizationId,
  organizationLogo,
  organizationName,
  role,
  viewRequest,
  loading,
}) => {
  return (
    <div className={styles.inviteRequestItem}>
      <Avatar loading={loading} image={organizationLogo} className={styles.orgLogo} />
      <p className={styles.orgName}>
        {loading ? <Skeleton width={250} /> : organizationName}
        {loading ? (
          <Skeleton width={130} />
        ) : (
          <span className={styles.orgPosition}>
            {role === AccountStatus.MANAGER
              ? localStrings.managerRequest
              : localStrings.memberRequest}
          </span>
        )}
      </p>
      {!loading && (
        <Button
          className={styles.viewInvite}
          text={localStrings.viewInvite}
          onClick={() => viewRequest(organizationId)}
        />
      )}
    </div>
  );
};

export default InviteRequestItem;
