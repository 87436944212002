import React, { useContext } from "react";
import styles from "./privacy-settings-form.module.scss";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import classnames from "classnames";
import localStrings from "localization";
import { useForm, useApi, useMount } from "hooks";
import { UserContext, OrganizationContext } from "contexts";
import { setPrivacyStatusOrganization, getPrivacyStatusOrganization } from "api/organization";
import { Permission } from "enums";
import initialFormState from "./initial-form-state";
import { Button, Avatar } from "atomic/molecules";
import PrivacyForm from "components/settings/privacy-form/privacy-form";
import { getPrivacySettingsValue } from "api/organization";

const PrivacySettingsForm = ({ acceptPrivacySettings, organizationName }) => {
  const { user } = useContext(UserContext);
  const { organizationId, organizationLogo } = useContext(OrganizationContext);
  const { userId } = user || {};
  const form = useForm(initialFormState);
  const { isFormValid, formFieldsValue, submit, setFormFieldValue, clearFormFieldsDirty } = form;

  const requirementKeys = {
    isNameAndPhotoAllowed: "isNameAndPhotoRequired",
    isEmailAndMobileNumberAllowed: "isEmailAndMobileNumberRequired",
    isWristbandAllowed: "isWristbandRequired",
    isAboutYouAllowed: "isAboutYouRequired",
    isEmploymentDetailAllowed: "isEmploymentDetailRequired",
    isEmergencyContactAllowed: "isEmergencyContactRequired",
    isBodyTemperatureAllowed: "isBodyTemperatureRequired",
    isAreaLocationAllowed: "isAreaLocationRequired",
    isLocationAllowed: "isLocationRequired",
    isSymptomAllowed: "isSymptomRequired",
  };

  const { request, loading } = useApi({
    api: setPrivacyStatusOrganization,
  });

  const { request: initRequest } = useApi({
    api: () => getPrivacyStatusOrganization({ userId, organizationId }),
  });

  const {
    request: initPrivacySettings,
    loading: loadingPrivacySettings = true,
    result: requirementData,
  } = useApi({
    api: () =>
      getPrivacySettingsValue({
        organizationId,
        currentPrivacy: true,
      }),
  });

  const requirementDataResult = requirementData?.data || {};

  const onSubmit = async () => {
    submit();
    if (isFormValid) {
      const params = {};
      Object.keys(formFieldsValue).forEach((key) => {
        params[key] = formFieldsValue[key] === Permission.Allowed;
      });
      const submitForm = async () => {
        await request({
          userId,
          organizationId,
          form: { ...params, isPrivacySet: true },
        });
        acceptPrivacySettings();
      };
      await submitForm();
      return false;
    }
  };

  useMount(() => {
    const init = async () => {
      const data = await initRequest();
      const requirement = await initPrivacySettings();
      const d = requirement.data;

      Object.keys(data).forEach((key) => {
        if (
          [
            "isLocationAllowed",
            "isSymptomAllowed",
            "isAboutYouAllowed",
            "isEmploymentDetailAllowed",
            "isEmergencyContactAllowed",
            "isWristbandAllowed",
            "isEmailAndMobileNumberAllowed",
            "isNameAndPhotoAllowed",
            "isBodyTemperatureAllowed",
            "isAreaLocationAllowed",
          ].includes(key)
        ) {
          setFormFieldValue(
            data[key] || d[requirementKeys[key]] ? Permission.Allowed : Permission.NotAllowed,
            key
          );
        }
      });
      clearFormFieldsDirty();
    };
    init();
  });

  return (
    <>
      <div className={classnames(styles.container)}>
        <div class={styles.headerContainer}>
          <Avatar className={styles.avatar} image={organizationLogo} />
          <h1>{organizationName}</h1>
        </div>

        <p>{localStrings.wouldBeReceivingInfo}</p>
        <div className={styles.content}>
          <PrivacyForm
            form={form}
            required={requirementDataResult}
            requirementKeys={requirementKeys}
          />
        </div>
      </div>
      <div className={styles.submit}>
        <div>
          <Button
            className={styles.submitButton}
            loading={loading || loadingPrivacySettings}
            onClick={onSubmit}
            text={localStrings.savePermissions}
          />
        </div>
      </div>
    </>
  );
};

export default withLDConsumer()(PrivacySettingsForm);
