import React, { useMemo } from "react";
import { Image, Icon } from "atomic/atoms";
import { Panel, Button } from "atomic/molecules";
import { FailedToLoad } from "atomic/organisms";
import { Accessories } from "enums";
import styles from "./accessory-details.module.scss";
import { tellTime, isBetweenDate } from "utils";
import localStrings from "localization";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import Skeleton from "react-loading-skeleton";
const { WristbandEmpty } = Accessories;

const Loading = () => {
  return (
    <div>
      <Skeleton width={150} />
      <Skeleton width={230} />
    </div>
  );
};

const AccessoryDetails = ({
  className,
  loading,
  hasDevice,
  showAdd,
  showRemove,
  error,
  isMember,
  isManageable,
  accessoryDetails,
  ownAccount,
}) => {
  const preparedItems = useMemo(() => {
    if (!accessoryDetails?.data) {
      return [];
    }
    return accessoryDetails?.data.map((d) => {
      const date = d.lastSyncDateTime;
      return {
        ...d,
        lastSyncDateTime: date ? (
          <span className={styles.lastSynced}>
            {!isBetweenDate(date, 6, "hours") && (
              <Icon className={styles.warningIcon} name="exclamation-fill" />
            )}
            {`${localStrings.lastSynced} ${tellTime(date)}`}
          </span>
        ) : null,
      };
    });
  }, [accessoryDetails]);

  return (
    <Panel className={classnames(styles.container, className)}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>{localStrings.pouchBands}</h3>
        {isManageable && (
          <p>
            {isMember && !ownAccount
              ? localStrings.addAndManagerPouchBands
              : localStrings.addYourPouchBand}
          </p>
        )}
      </div>
      {loading && <Loading />}
      {!hasDevice && (
        <Image
          className={classnames(styles.accessoryImage, {
            [`${styles.noAccessory}`]: !hasDevice,
            [`${styles.errorImage}`]: error,
          })}
          src={WristbandEmpty}
        />
      )}

      {!hasDevice && <h2 className={styles.noAccessoryTitle}>{localStrings.noAccessoryYet}</h2>}
      {!loading && hasDevice && !error && isManageable && (
        <Button
          className={classnames(styles.action, styles.noAccessory)}
          prefixIcon={{ name: "plus" }}
          text={localStrings.addPouchband}
          onClick={showAdd}
        />
      )}
      {!loading && !hasDevice && isManageable && (
        <Button
          className={classnames(styles.action, styles.noAccessory)}
          prefixIcon={{ name: "plus" }}
          text={localStrings.addAccessory}
          onClick={showAdd}
        />
      )}
      {error && <FailedToLoad image={false} />}
      {!error && (
        <div className={styles.listContainer}>
          {preparedItems.map((item) => {
            return (
              <div className={styles.list} key={uuidv4()}>
                <div>
                  <h4>{item.deviceUid}</h4>
                  {item.lastSyncDateTime && <p>{item.lastSyncDateTime}</p>}
                </div>
                {isManageable && (
                  <div>
                    <Icon
                      name="close"
                      className={styles.remove}
                      onClick={() => {
                        showRemove(item.deviceUid);
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </Panel>
  );
};

export default AccessoryDetails;
