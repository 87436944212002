import React from "react";
import { Details } from "components/overview";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import { Common, Routes } from "enums";
import EveryoneIsSafe from "images/everyone-is-safe-2.svg";

const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "name",
    title: localStrings.name,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "temperature",
    title: localStrings.temperature,
    width: 100,
  },
];

const FeverDetails = ({ className, data, total, error, loading }) => {
  const noData = {
    title: localStrings.everyoneIsSafe,
    description: localStrings.everyoneIsSafeDesc,
    image: EveryoneIsSafe,
  };

  return (
    <Details
      className={className}
      title={localStrings.feverCases}
      viewAllText={localStrings.viewAll}
      viewAllCount={total}
      viewAllLink={`/${Routes.USERS}?bodyTemp=${Common.FEVER}`}
      columns={tableColumns}
      data={data}
      loading={loading}
      noData={noData}
      error={error}
    />
  );
};

export default FeverDetails;
