import React, { useEffect, useState } from "react";
import InputField from "../input-field/input-field";
import styles from "./input-suggestion.module.scss";

const InputSuggestion = ({
  suggestionLoading,
  suggestions,
  onBlur,
  onFocus,
  showSuggestionOnFocus = true,
  ...props
}) => {
  const [visible, setVisible] = useState(false);

  const { value } = props;

  useEffect(() => {
    if (showSuggestionOnFocus) {
      return;
    }
    if (!value || suggestions?.length <= 0) {
      setVisible(false);
      return;
    }
    if (suggestionLoading || suggestions?.length > 0) {
      setVisible(true);
      return;
    }
  }, [suggestionLoading, suggestions, value, showSuggestionOnFocus]);

  return (
    <div
      className={styles.container}
      onBlur={() => {
        if (showSuggestionOnFocus) {
          setTimeout(() => {
            setVisible(false);
          }, 200);
        }
      }}
    >
      <InputField
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
          // setVisible(false);
        }}
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
          if (value || showSuggestionOnFocus) {
            setVisible(true);
          }
        }}
        {...props}
      />
      {visible && suggestions?.length ? (
        <div className={styles.suggestionContainer}>
          {suggestionLoading ? (
            <div className={styles.suggestionItem}>Loading...</div>
          ) : (
            <div>{suggestions}</div>
          )}
        </div>
      ) : null}
    </div>
  );
};

export default InputSuggestion;
