import React, { useState, useEffect, useCallback } from "react";
import styles from "./menu-option.module.scss";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { Icon } from "atomic/atoms";

const MenuOption = ({
  options,
  loading,
  children,
  itemMenuClassNameContainer,
  active: initActive,
  onClose,
}) => {
  const [active, setActive] = useState(initActive);

  const close = useCallback(() => {
    setActive(false);
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  useEffect(() => {
    if (loading) {
      close();
    }
  }, [loading, close]);

  return (
    <div className={children ? styles.containerEmpty : styles.container}>
      {children ? (
        <div
          className={styles.textContainer}
          onClick={(e) => {
            setActive(true);
            // e.stopPropagation();
            // e.nativeEvent.stopImmediatePropagation();
          }}
        >
          {children}
        </div>
      ) : (
        <Icon
          loading={loading}
          name={loading ? "loading" : "meat-balls"}
          onClick={(e) => {
            setActive(true);
            // e.stopPropagation();
            // e.nativeEvent.stopImmediatePropagation();
          }}
        />
      )}

      {active ? (
        <div
          className={styles.blur}
          onClick={(e) => {
            setTimeout(() => {
              close();
            });
            // e.stopPropagation();
            // e.nativeEvent.stopImmediatePropagation();
          }}
        ></div>
      ) : null}
      {!loading && active ? (
        <span className={classnames(styles.itemMenu, "item-menu", itemMenuClassNameContainer)}>
          {options.map((o) => {
            if (o) {
              return (
                <div
                  className={classnames(styles.menuItem, {
                    [`${styles.noBorder}`]: o.noBorder,
                  })}
                  onClick={(e) => {
                    close();
                    if (o.onClick) {
                      o.onClick(e);
                    }
                  }}
                  key={uuidv4()}
                >
                  {o.label}
                </div>
              );
            }
            return null;
          })}
        </span>
      ) : null}
    </div>
  );
};

export default MenuOption;
