import localStrings from "localization";

const Permission = {
  Allowed: "ALLOWED",
  NotAllowed: "NOTALLOWED",
};

export const PermissionOptions = [
  {
    text: localStrings.allowed,
    value: Permission.Allowed,
  },
  {
    text: localStrings.privateOnlyMe,
    value: Permission.NotAllowed,
  },
];

export default Object.freeze(Permission);
