import React, { useContext } from "react";
import { OnboardingLayout, EmergencyContactForm } from "components/onboarding";
import BackgroundImage from "images/emergency-contact.svg";
import { UserContext, UserOrganizationsContext } from "contexts";
import { useApi, useForm, useMount, useRouter } from "hooks";
import { emergencyContactFormState } from "states";
import { markOnboardUser, onboardUser } from "api/user";
import { countryDialingCodeOptions } from "utils/countries";
import { getUserRequestParam } from "components/users/user-form/user-request-form";
import path from "path/path";

const EmergencyContactContainer = () => {
  const { userOrganizations } = useContext(UserOrganizationsContext);
  const {
    isFormValid,
    formFieldsValue,
    formFields,
    setFormFieldValue,
    setFormFieldValues,
    submit,
    setRequiredField,
  } = useForm(emergencyContactFormState, submitCallback);
  const countryCodes = countryDialingCodeOptions();
  const { history } = useRouter();

  const { request: onboardUserRequest, loading } = useApi({ api: onboardUser });

  const { user, refetchUser } = useContext(UserContext);

  useMount(() => {
    setFormFieldValues({ ...user, ...user.userDetail });
  });

  async function submitCallback() {
    if (isFormValid) {
      const obj = () => {
        const params = {};
        ["contactName", "contactRelationship", "contactCountryCode", "contactNumber"].forEach(
          (key) => {
            params[key] = formFieldsValue[key] || "";
          }
        );
        return params;
      };

      await onboardUserRequest({
        ...getUserRequestParam({ ...user, ...user?.userDetail }),
        ...obj(),
        isEmergencyContact: true,
      });

      await markOnboardUser();

      await refetchUser();

      if (userOrganizations.length > 0) {
        history.push(path.ONBOARDING_PRIVACY_SETTINGS);
      } else {
        history.push(path.USER_PROFILE);
      }
    }
  }

  const handleChangeEmergency = (value = "", name = "contactName") => {
    const obj = {};

    const keys = ["contactName", "contactRelationship", "contactCountryCode", "contactNumber"];
    keys.forEach((key) => {
      if (formFieldsValue[key]) {
        obj[key] = formFieldsValue[key];
      }
    });
    if (value) {
      obj[name] = value;
    } else {
      delete obj[name];
    }
    keys.forEach((key) => {
      setRequiredField(Object.keys(obj).length, key);
    });
  };

  return (
    <OnboardingLayout backgroundImage={BackgroundImage}>
      <EmergencyContactForm
        formFields={formFields}
        setFormFieldValue={setFormFieldValue}
        handleChangeEmergency={handleChangeEmergency}
        countryCodes={countryCodes}
        loading={loading}
        onSubmit={submit}
      />
    </OnboardingLayout>
  );
};

export default EmergencyContactContainer;
