const BloodType = {
  Unsure: "Unsure",
  AP: "A+",
  AN: "A-",
  BP: "B+",
  BN: "B-",
  ABP: "AB+",
  ABN: "AB-",
  OP: "O+",
  ON: "O-",
};

export default Object.freeze(BloodType);
