import React from "react";
import styles from "./edit-member-details-form.module.scss";
import { Select } from "atomic/atoms";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { RequirementOptions } from "enums";

const InfoContainer = ({
  title = "",
  description = "",
  permission = "",
  name,
  formFields,
  setFormFieldValue,
  loading,
}) => {
  return (
    <div className={styles.infoContainer}>
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.permission}>
        {name ? (
          <Select
            className={styles.select}
            data={RequirementOptions}
            name={name}
            {...formFields[name]}
            onChange={setFormFieldValue}
            loading={loading}
          />
        ) : (
          permission
        )}
      </div>
    </div>
  );
};

export default withLDConsumer()(InfoContainer);
