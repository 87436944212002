import React, { useMemo, useState, useCallback, Fragment } from "react";
import styles from "./contact-history-tab.module.scss";
import { v4 as uuidv4 } from "uuid";
import { getFullName, formatDate, getLocalItem, tellDuration } from "utils";
import { Tooltip as AntTooltip } from "antd";
import { contactTracing } from "api/contact-tracing";
import moment from "moment";
import momentTimezone from "moment-timezone";
import localTranslation from "localization/localization";
import { prepareDocDefinition, downloadPDF } from "services";
import { useApi, useMount } from "hooks";
import { Routes } from "enums";
import UnknownUserModal from "./unknown-user-modal/unknown-user-modal";
import ContactHistoryFilter from "./contact-history-filter/contact-history-filter";
import ContactHistoryTable from "./contact-history-table/contact-history-table";
import ContactHistoryHeader from "./contact-history-header/contact-history-header";
import ContactHistoryDetailsModal from "./contact-history-details-modal/contact-history-details-modal";
import { Avatar } from "atomic/molecules";
import { Loading } from "atomic/atoms";

const pageSize = 10;

const ContactHistoryTab = ({ userId, user, userPrivacyStatus, userPrivacyLoading }) => {
  // const { state } = useContext(ViewUserContext);
  // const { userInfo } = user;
  const { name, nickname } = user;
  const [modal, setModal] = useState({
    active: false,
    data: null,
  });
  const [dataSet, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dates, setDates] = useState([moment().subtract(30, "days"), moment()]);
  const [searchKey, setSearchKey] = useState("");
  const startDate = useMemo(() => {
    return dates[0];
  }, [dates]);
  const endDate = useMemo(() => {
    return dates[1];
  }, [dates]);
  const [unknownUserModal, setUnknownUserModal] = useState({
    visible: false,
    userId: 0,
  });
  const { request: contactTracingRequest, loading, error } = useApi({
    api: contactTracing,
    params: {
      userId,
      organizationId: getLocalItem("organizationId"),
      timezone: momentTimezone.tz.guess(),
      startDate: startDate.format("MM/DD/YYYY"),
      endDate: endDate.format("MM/DD/YYYY"),
      page: 1,
      pageSize,
      searchKey: "",
    },
  });
  // const [hasData, setHasData] = useState(false);

  const handleFilterHistory = useCallback(
    async (s, d) => {
      setDates(d);
      setSearchKey(s);
      const result = await contactTracingRequest({
        startDate: d[0].format("MM/DD/YYYY"),
        endDate: d[1].format("MM/DD/YYYY"),
        searchKey: s,
      });

      const {
        data: dd,
        metadata: { total },
      } = result;
      setData(dd);
      setTotal(total);
    },
    [contactTracingRequest]
  );

  const formatData = (dataArray) => {
    return dataArray.map((dd) => {
      const {
        contactFirstName,
        contactLastName,
        organizationId: userOrganizationId,
        privateKeys,
        contactUserId,
      } = dd;
      const otherOrg = !userOrganizationId;
      const privateNameTag = privateKeys?.includes("contactFirstName");
      const hiddenNameTag = otherOrg || privateNameTag;
      const name = () => {
        if (otherOrg) {
          return localTranslation("unknowUserUserId", [contactUserId]);
        }
        if (privateNameTag) {
          return `#${contactUserId}`;
        }
        return getFullName({ firstName: contactFirstName, lastName: contactLastName });
      };
      const phrases = () => {
        if (hiddenNameTag) {
          return [];
        }
        return [contactFirstName, contactLastName];
      };
      return {
        ...dd,
        contactFullName: name(),
        phrases: phrases(),
        contactFirstName: hiddenNameTag ? "" : contactFirstName,
        contactLastName: hiddenNameTag ? "" : contactLastName,
        avatar: hiddenNameTag ? "" : dd.avatar,
      };
    });
  };

  const data = useMemo(() => {
    if (!dataSet) {
      return [];
    }
    return formatData(dataSet);
  }, [dataSet]);

  const hasData = useMemo(() => {
    return Boolean(data.length);
  }, [data]);

  useMount(() => {
    const init = async () => {
      const result = await contactTracingRequest();
      const {
        data,
        metadata: { total },
      } = result;
      // setHasData(data.length);
      setData(data);
      setTotal(total);
    };
    init();
  });

  const preparedData = useMemo(() => {
    return data.map((d) => {
      const {
        contactUserId,
        contactFirstName: firstName,
        contactLastName: lastName,
        contactStartDate,
        durationInSeconds,
        areaName: locationArea,
        avatar,
        organizationId: userOrganizationId,
        privateKeys,
        contactFullName,
        phrases,
        ...rest
      } = d;
      const otherOrg = !userOrganizationId;
      return {
        ...rest,
        key: uuidv4(),
        name: (
          <div className={styles.contactNameColumn}>
            <Avatar
              image={otherOrg ? "" : avatar}
              phrases={phrases}
              className={styles.contactAvatar}
            />
            <div className={styles.contactNameContainer}>
              <AntTooltip title={contactFullName}>
                <div
                  className={styles.contactName}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                    if (otherOrg) {
                      setUnknownUserModal({
                        visible: true,
                        userId: contactUserId,
                      });
                      return;
                    }
                    window.open(`/${Routes.MEMBERS}/${contactUserId}`, "_blank");
                  }}
                >
                  {contactFullName}
                </div>
              </AntTooltip>
            </div>
          </div>
        ),
        dateOfContact: formatDate(contactStartDate, "MMMM DD, YYYY h:mm a"),
        duration: tellDuration(durationInSeconds) || "-",
        locationArea: userPrivacyLoading ? (
          <Loading />
        ) : userPrivacyStatus?.isAreaLocationAllowed ? (
          locationArea || "-"
        ) : (
          "-"
        ),
      };
    });
  }, [data, userPrivacyLoading, userPrivacyStatus]);

  const handleOnClickExport = async () => {
    const { data: toExportData } = await contactTracingRequest({
      startDate: dates[0].format("MM/DD/YYYY"),
      endDate: dates[1].format("MM/DD/YYYY"),
      searchKey,
      pageSize: null,
    });
    const tableData = formatData(toExportData).map((d) => {
      const { contactStartDate, durationInSeconds, areaName, contactFullName: name } = d;

      const dateOfContact = formatDate(contactStartDate, "MMM DD, YYYY hh:mm a");
      const duration = tellDuration(durationInSeconds);
      return [
        { text: name, margin: [0, 16] },
        { text: dateOfContact, margin: [0, 16] },
        { text: duration, margin: [0, 16] },
        { text: areaName, margin: [0, 16] },
      ];
    });
    const def = prepareDocDefinition({
      title: name,
      tableData,
      startDate,
      endDate,
    });
    downloadPDF(def);
  };

  const getData = (index) => {
    const d = { ...data[index] };
    // if (!d.organizationId) {
    //   d.contactFirstName = "";
    //   d.contactLastName = "";
    //   d.avatar = "";
    //   // setUnknownUserModal((prev) => {
    //   //   return {
    //   //     ...prev,
    //   //     visible: true,
    //   //     userId: d.contactUserId,
    //   //   };
    //   // });
    //   // return false;
    // }
    return d;
  };

  const showDetailedContactHistory = (index) => {
    const d = getData(index);
    setModal({
      active: true,
      data: d,
      total: data.length,
      place: index + 1,
      index,
    });
  };

  const onChangePage = useCallback(
    (currentPage) => {
      contactTracingRequest({ page: currentPage });
      setCurrentPage(currentPage);
    },
    [contactTracingRequest]
  );

  return (
    <Fragment>
      <div className={styles.container}>
        <UnknownUserModal setModal={setUnknownUserModal} modal={unknownUserModal} />
        <ContactHistoryDetailsModal
          user={user}
          dates={dates}
          modal={modal}
          setModal={setModal}
          showUnknowUser={setUnknownUserModal}
          userPrivacyStatus={userPrivacyStatus}
          prev={() => {
            const index = modal.index - 1;
            const d = getData(index);
            setModal({
              active: true,
              data: d,
              total: data.length,
              place: index + 1,
              index,
            });
          }}
          next={() => {
            const index = modal.index + 1;
            const d = getData(index);
            setModal({
              active: true,
              data: d,
              total: data.length,
              place: index + 1,
              index,
            });
          }}
        />
        <ContactHistoryHeader
          nickname={nickname}
          hasData={hasData}
          loading={userPrivacyLoading}
          onClickExport={handleOnClickExport}
        />
        <ContactHistoryFilter
          data={data.length}
          search={handleFilterHistory}
          errorPage={error}
          loading={loading}
          searchKeyValue={searchKey}
        />
        <ContactHistoryTable
          error={error}
          searchKey={searchKey}
          data={preparedData}
          onRowClick={showDetailedContactHistory}
          loading={loading}
          fullName={name}
          total={total}
          defaultPageSize={pageSize}
          pageSize={pageSize}
          showSizeChanger={false}
          onChange={onChangePage}
          currentPage={currentPage}
        />
      </div>
    </Fragment>
  );
};

export default ContactHistoryTab;
