import { Field, Validation } from "enums";
import { useCallback, useEffect, useMemo, useState } from "react";
// import useMount from "./useMount";

const useFormV2 = ({ initialState = {}, validation = null }) => {
  const [fields, setFields] = useState({ ...initialState });

  const [dirty, setDirty] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    validateFields(initialState);
    setFields(setDefaultFields(initialState));
    checkIfFormHasError(initialState, () => {
      setError(true);
    });
  }, [initialState]);

  const modifyForm = useCallback(
    (fs) => {
      if (!dirty) {
        setDirty(true);
      }

      const cleanedFields = {
        ...removeUninitializedFields(fs, initialState),
      };

      const mergedFields = setDefaultFields(mergeFields(cleanedFields, fields));

      const newFields = runFieldValidations(mergedFields);
      setFields(newFields);

      // Error checking below

      const hasError = checkIfFormHasError(newFields, () => {
        setError(true);
      });

      if (hasError) return { fields: newFields, dirty, error: true };

      // Validate based on custom validation
      if (validation) {
        const { error } = validation(fields);
        if (error) {
          setError(true);
          return { fields: newFields, dirty, error };
        }
      }

      return { fields: newFields, dirty, error: false };
    },
    [fields, initialState, dirty, validation]
  );

  const modifyField = useCallback(
    (name, obj) => {
      return modifyForm({ [name]: obj });
    },
    [modifyForm]
  );

  const clearForm = useCallback(() => {
    setDirty(false);
    setError(false);
    setFields(initialState);

    return { fields: initialState, dirty, error };
  }, [initialState, dirty, error]);

  const validateForm = useCallback(() => {
    const dirtyFields = makeFormDirty(fields, () => {
      setDirty(true);
    });
    const validatedFields = runFieldValidations(dirtyFields);

    setFields(validatedFields);

    const hasError = checkIfFormHasError(validatedFields, () => {
      setError(true);
    });
    if (hasError) return { fields: validatedFields, dirty: true, error: true };

    return { fields: validatedFields, dirty: true, error: false };
  }, [fields]);

  const submitForm = useCallback(
    async (callback) => {
      let response = null;
      const { fields, error } = validateForm();

      if (callback && !error) {
        response = await callback(fields, error);
      }
      return { fields, error, response };
    },
    [validateForm]
  );

  const applyFieldErrors = useCallback(
    (fieldErrors) => {
      let newFields = { ...fields };

      for (const [k, fieldError] of Object.entries(fieldErrors)) {
        if (typeof fieldError !== "string") {
          console.warn(`Error message must be string.`);
          continue;
        }

        if (!fields.hasOwnProperty(k)) {
          console.warn(`Property ${k} is not existing on form. Will disregard.`);
          continue;
        }

        newFields = { ...newFields, [k]: { ...newFields[k], error: true, message: fieldError } };
      }
      setFields(newFields);
      setError(true);
      return { fields: newFields };
    },
    [fields]
  );

  const getFormValues = useCallback(() => {
    let formValues = {};
    for (const [k, formValue] of Object.entries(fields)) {
      formValues[k] = formValue.value;
    }

    return formValues;
  }, [fields]);

  const isFormSubmittable = useMemo(() => {
    let isRequiredFieldsFilled = true;
    for (const [, formValue] of Object.entries(fields)) {
      if (formValue.required && !formValue.value) {
        isRequiredFieldsFilled = false;
      }
    }

    let hasError = false;
    for (const [, formValue] of Object.entries(fields)) {
      if (formValue.error) {
        hasError = true;
      }
    }

    return dirty && isRequiredFieldsFilled && !hasError;
  }, [dirty, fields]);

  return {
    fields,
    dirty,
    error,
    modifyForm,
    clearForm,
    modifyField,
    validateForm,
    submitForm,
    applyFieldErrors,
    getFormValues,
    isFormSubmittable,
  };
};

const validateFields = (fields) => {
  if (Object.keys(fields) <= 0) {
    throw new Error("Fields must have atleast one property");
  }

  for (const [, field] of Object.entries(fields)) {
    if (!field.hasOwnProperty("type")) {
      throw new Error("Field must have a type");
    }

    if (!Field.isValid(field.type)) {
      throw new Error(`${field.type} is not a valid field type`);
    }

    if (field.hasOwnProperty("error") && typeof field.error !== "boolean") {
      throw new Error(`Error must be a boolean`);
    }

    if (field.hasOwnProperty("message") && typeof field.message !== "string") {
      throw new Error(`Message must be a string`);
    }

    if (field.hasOwnProperty("dirty") && typeof field.error !== "boolean") {
      throw new Error(`Dirty must be a boolean`);
    }

    if (field.hasOwnProperty("validations") && !Array.isArray(field.validations)) {
      throw new Error(`Validations must be an array`);
    }

    if (field.validations) {
      for (const validation of field?.validations) {
        if (!Validation.isValid(field.validation) && typeof validation !== "function") {
          throw new Error(`Invalid field validation`);
        }
      }
    }
  }
  return fields;
};

const removeUninitializedFields = (fields, initialState) => {
  let newFields = {};
  for (const [k, field] of Object.entries(fields)) {
    if (!initialState.hasOwnProperty(k)) {
      console.warn(`Field ${k} is not declared on initial field state. Will disregard this field.`);
    } else {
      newFields[k] = field;
    }
  }
  return newFields;
};

const setDefaultFields = (fields) => {
  let fieldsWithDefault = {};

  for (let [k, field] of Object.entries(fields)) {
    let fieldWithDefault = {
      ...field,
      error: field.error ? field.error : false,
      message: field.message ? field.message : "",
      dirty: field.dirty ? field.dirty : false,
      validations: field.validations ? field.validations : [],
    };

    if (!field.hasOwnProperty("value")) {
      fieldWithDefault = { ...fieldWithDefault, ...setDefaultFieldValues(field) };
    }
    fieldsWithDefault = { ...fieldsWithDefault, [k]: fieldWithDefault };
  }

  return { ...fieldsWithDefault };
};

const setDefaultFieldValues = (field) => {
  const { type } = field;

  if (type === Field.INPUT) {
    field = { ...field, value: null };
  }

  if (type === Field.DROPDOWN) {
    field = { ...field, value: null };
  }

  if (type === Field.MULTIPLE_DROPDOWN) {
    field = { ...field, value: [] };
  }

  if (type === Field.CHECKBOX) {
    field = { ...field, value: null };
  }

  if (type === Field.MULTIPLE_CHECKBOX) {
    field = { ...field, value: [] };
  }

  if (type === Field.RADIO) {
    field = { ...field, value: null };
  }

  return { ...field, value: null };
};

const mergeFields = (newFields, currentFields) => {
  let newlyMergedFields = {};

  for (const [k, newField] of Object.entries(newFields)) {
    if (currentFields.hasOwnProperty(k)) {
      newlyMergedFields[k] = { ...currentFields[k], ...newField, dirty: true };
    }
  }
  return { ...currentFields, ...newlyMergedFields };
};

const validateField = (field) => {
  if (field.validations?.length > 0) {
    for (const validation of field.validations) {
      const { error, message, ...additionalProperties } = validation(field);
      if (error) {
        return { ...field, error, message, ...additionalProperties };
      }
    }
  }
  return { ...field, error: false, message: "" };
};

const checkIfFormHasError = (fields, callback) => {
  let hasError = false;
  for (const [, field] of Object.entries(fields)) {
    if (field.error) {
      callback();
      hasError = true;
      break;
    }
  }
  return hasError;
};

const runFieldValidations = (fields) => {
  let newFields = {};

  if (validateFields(fields)) {
    for (const [k, field] of Object.entries(fields)) {
      if (field.dirty) {
        newFields = { ...newFields, [k]: validateField(field) };
      } else {
        newFields = { ...newFields, [k]: field };
      }
    }
  }
  return newFields;
};

const makeFormDirty = (fields, callback) => {
  let dirtyFields = { ...fields };

  for (const [, df] of Object.entries(dirtyFields)) {
    if (!df.dirty) {
      df.dirty = true;
    }
  }

  callback();

  return dirtyFields;
};

export default useFormV2;
