import React from "react";
import styles from "./area-form.module.scss";
import localString from "localization";
import { InputField, Panel, Field, TextareaField } from "atomic/molecules";
import { Select } from "atomic/atoms";
import localTranslation from "localization/localization";
import { AreaGatewayListContainer } from "containers";

const AreaForm = ({ modifyField, fields, areaGatewayModal, applyFieldErrors, areaId }) => {
  const gateways = fields.gateways.value;

  const setFormFieldValue = (value, name) => {
    modifyField(name, {
      value,
    });
  };

  const levelOptions = new Array(10).fill("level").map((arr, index) => {
    const level = index + 1;
    return {
      text: localTranslation("nthLevel", [level]),
      value: level,
    };
  });

  const submitGatewayForm = (field) => {
    const values = field();
    setFormFieldValue(
      [
        ...gateways,
        {
          label: values.gatewayName,
          macAddress: values.gatewayMacAddress,
          status: "CONNECTED",
        },
      ],
      "gateways"
    );
    areaGatewayModal.close();
  };

  const removeGateway = () => {
    setFormFieldValue([], "gateways");
  };

  return (
    <div className={styles.form}>
      <Panel className={styles.panel} title={localString.areaInformation}>
        <div className={styles.input}>
          <InputField
            name="areaName"
            label={localString.areaName}
            onChange={setFormFieldValue}
            placeholder={localString.areaNamePlaceholder}
            {...fields.areaName}
          />
        </div>
        <div className={styles.input}>
          <TextareaField
            name="areaDescription"
            label={localString.description}
            placeholder={localString.areaDescription}
            onChange={setFormFieldValue}
            {...fields.areaDescription}
          />
        </div>
        <div className={styles.input}>
          <Field {...fields.areaLevel} label={localString.level}>
            <Select
              name="areaLevel"
              data={levelOptions}
              placeholder={localString.levelPlaceholder}
              onChange={setFormFieldValue}
              {...fields.areaLevel}
            />
          </Field>
        </div>
        <h3>{localString.gateways}</h3>
        <AreaGatewayListContainer
          areaId={areaId}
          areaGatewayModal={areaGatewayModal}
          gateways={gateways}
          submitGatewayForm={submitGatewayForm}
          maxGateway={1}
          removeGateway={removeGateway}
          handleErrorInGatewayUid={(error) => {
            const { metadata = {} } = error;
            const { code } = metadata;
            const errors = {
              8017: () => {
                applyFieldErrors({
                  gateways: localString.gatewayAlreadyAdded,
                });
              },
            };
            if (errors[code]) {
              errors[code]();
            } else if (error.badrequest) {
              error.dispatchError();
            }
          }}
        />
        {fields.gateways.error && fields.gateways.message ? (
          <p className={styles.error}>{fields.gateways.message}</p>
        ) : (
          ""
        )}
      </Panel>
    </div>
  );
};

export default AreaForm;
