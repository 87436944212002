import React from "react";
import classnames from "classnames";
import { Loading } from "atomic/atoms";
import styles from "./field.module.scss";

const Field = ({ className, loading, error, children, label, message }) => {
  return (
    <div className={classnames(className, styles.field, { [`${styles.error}`]: error })}>
      <label className={styles.label}>{label}</label>
      {loading ? <Loading /> : children}
      {message && <span className={styles.message}>{message}</span>}
    </div>
  );
};

export default Field;
