import React from "react";
import styles from "./about-you-form.module.scss";
import localStrings from "localization";
import { SubmitStep } from "components/onboarding";
import { BloodType, Gender } from "enums";
import { InputField, DatePickerField, SelectField } from "atomic/molecules";

const AboutYouForm = ({ onBack, formFields, setFormFieldValue, submit, loading }) => {
  return (
    <div className={styles.container}>
      <h2 className={styles.title}>{localStrings.aboutYou}</h2>
      <p className={styles.desc}>Just a bit of personal details about you.</p>
      <div className={styles.formContainer}>
        <DatePickerField
          {...formFields.birthDate}
          onChange={setFormFieldValue}
          format="MM/DD/YYYY"
          name="birthDate"
          label={localStrings.birthdate}
          placeholder="Enter Birthdate"
        />
        <SelectField
          {...formFields.gender}
          onChange={setFormFieldValue}
          name="gender"
          label={localStrings.gender}
          placeholder={localStrings.selectGender}
          data={genderOptions}
        />
        <SelectField
          {...formFields.bloodType}
          onChange={setFormFieldValue}
          name="bloodType"
          className={styles.fullColumn}
          label={localStrings.bloodType}
          placeholder={localStrings.selectBloodType}
          data={bloodTypeOptions}
        />
        <InputField
          {...formFields.height}
          onChange={setFormFieldValue}
          name="height"
          label={localStrings.height}
          maxLength={4}
          renderSuffix={<div>cm</div>}
        />
        <InputField
          {...formFields.weight}
          onChange={setFormFieldValue}
          name="weight"
          label={localStrings.weight}
          maxLength={4}
          renderSuffix={<div>kg</div>}
        />
        <InputField
          {...formFields.currentAddress}
          onChange={setFormFieldValue}
          name="currentAddress"
          className={styles.fullColumn}
          label={localStrings.currentResidentialAddress}
          placeholder={localStrings.addressPlaceholder}
        />
      </div>
      <SubmitStep onBack={onBack} onSubmit={submit} loading={loading} />
    </div>
  );
};

const bloodTypeOptions = Object.keys(BloodType).map((bloodType) => {
  return {
    value: BloodType[bloodType],
    text: BloodType[bloodType],
  };
});

const genderOptions = [
  {
    text: "Male",
    value: Gender.Male,
  },
  {
    text: "Female",
    value: Gender.Female,
  },
  {
    text: "Other",
    value: Gender.Other,
  },
];

export default AboutYouForm;
