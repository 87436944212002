import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styles from "./input-pill.module.scss";
import { Icon } from "atomic/atoms";
import { Pill } from "atomic/molecules";
import classnames from "classnames";
import localTranslation from "localization/localization";

const InputPill = ({
  pills,
  onPillChange,
  loading,
  required,
  requiredMessage,
  value,
  setValue,
  triggerRequiredError = false,
}) => {
  const [focused, setFocused] = useState(false);
  const [showRequiredError, setShowRequiredError] = useState(false);

  useEffect(() => {
    setShowRequiredError(triggerRequiredError);
  }, [triggerRequiredError]);

  const inputEl = useRef(null);

  const focusOnInput = useCallback(
    (e) => {
      inputEl.current.focus();
      setFocused(true);
      if (e) {
        e.preventDefault();
      }
      setShowRequiredError(false);
    },
    [inputEl, setShowRequiredError]
  );

  const isValueOnInputAlreadyExist = useMemo(() => {
    if (
      pills.some((p) => {
        return value.trim().replace(",", "") === p.value;
      })
    ) {
      return true;
    }
    return false;
  }, [value, pills]);

  const onInputChange = useCallback(
    (e) => {
      setValue(e.target.value);
    },
    [setValue]
  );

  const addPill = useCallback(
    async (value) => {
      if (!value) {
        return false;
      }
      if (!isValueOnInputAlreadyExist) {
        await onPillChange([...pills, { value, error: false }]);
        setValue("");
      }
      focusOnInput();
    },
    [onPillChange, pills, isValueOnInputAlreadyExist, setValue, focusOnInput]
  );

  const onInputBlur = useCallback(
    (e) => {
      if (value) {
        addPill(e.target.value);
      }
      if (required && pills <= 0 && !value) {
        setShowRequiredError(true);
      }
      setFocused(false);
    },
    [value, addPill, pills, required, setShowRequiredError]
  );

  const removePill = useCallback(
    (index) => {
      const newPills = [...pills];
      newPills.splice(index, 1);
      onPillChange(newPills);
    },
    [pills, onPillChange]
  );

  const onKeyDownCb = useCallback(
    (e) => {
      // keyCode 8 is backspace, additional code from onKeyUp backspace to delete pill properly
      if (e.keyCode === 8 && value <= 0) {
        removePill(pills.length - 1);
      }
    },
    [removePill, pills, value]
  );

  const onKeyUpCb = useCallback(
    async (e) => {
      const trimmedInputVal = value.trim();
      // handler for enter and spacebar
      if (e.key === "Enter" || e.keyCode === 32 || trimmedInputVal.includes(",")) {
        const cleanInputVal = value.replace(",", "");
        if (!cleanInputVal.length) {
          e.preventDefault();
        }
        addPill(cleanInputVal);
        // onPillChange([...pills, trimmedInputVal]);
      }
    },
    [value, addPill]
  );

  const onPasteCb = useCallback(
    async (e) => {
      // handling of copy paste from sheets
      let pastedString = e.clipboardData.getData("text");

      if (/\t/.test(pastedString)) {
        pastedString = pastedString.split(/\t/).join("-");
      }
      if (/\n/.test(pastedString)) {
        pastedString = pastedString.split(/\n/).join("-");
      }

      // below code will remove duplicates from the pills
      const splittedString = pastedString.split("-");
      let newPillsArray = splittedString.map((ss) => {
        return { value: ss.trim(), error: false };
      });
      e.preventDefault();
      //   if (onMultiplePillChange) {
      //     newPillsArray = await onMultiplePillChange(newPillsArray, pills);
      //   }
      const combinedPills = [...pills, ...newPillsArray];
      const deduplicatePillsArray = dedupeArray(combinedPills);
      const newPills = [...deduplicatePillsArray];
      onPillChange(newPills);
    },
    [pills, onPillChange]
  );

  const dedupeArray = (arr) => {
    const a = arr.reduce((acc, current) => {
      const x = acc.find((item) => item.value === current.value);
      if (!x) {
        return acc.concat([current]);
      } else {
        return acc;
      }
    }, []);
    return a;
  };

  return (
    <div>
      <div
        className={classnames(styles.inputPillContainer, {
          [`${styles.inputPillContainerFocused}`]: focused,
        })}
        onClick={(e) => focusOnInput(e)}
      >
        {pills.length > 0 || value.length > 0 || focused ? (
          pills.map((p, i) => {
            return (
              <Pill
                className={styles.pill}
                key={i}
                error={p.error}
                text={p.value}
                onClick={() => {
                  removePill(i);
                }}
                icon="close"
              />
            );
          })
        ) : (
          <div className={styles.placeholder}>Enter one or many email addresses</div>
        )}
        <div className={classnames(styles.inputContainer)}>
          <input
            ref={inputEl}
            type="text"
            className={classnames(styles.input)}
            value={value}
            maxLength={75}
            onBlur={(e) => onInputBlur(e)}
            onKeyUp={(e) => onKeyUpCb(e)}
            onKeyDown={(e) => onKeyDownCb(e)}
            onPaste={(e) => onPasteCb(e)}
            onChange={(e) => onInputChange(e)}
            size={value.length + 1}
            disabled={loading}
          ></input>
          {/* {value.length > 0 && (
            <Icon
              className={styles.inputClose}
              name="close"
              onClick={(e) => {
                // onInputChange(e);
                setValue("");
                e.preventDefault();
              }}
            />
          )} */}
        </div>
        <Icon className={styles.loading} loading={loading}></Icon>
      </div>
      <div className={styles.errorContainer}>
        {showRequiredError && <div className={styles.errorMessage}>{requiredMessage}</div>}
        {/* {duplicate.length > 0 && <div className={styles.errorMessage}>{duplicate}</div>} */}
        {isValueOnInputAlreadyExist && !focused && (
          <div className={styles.errorMessage}>
            {localTranslation("valueAlreadyExists", [value])}
          </div>
        )}
        {pills.length > 0 &&
          pills.map((p, i) => {
            if (p.errorMessage) {
              if (typeof p.errorMessage === "string") {
                return (
                  <div key={i} className={styles.errorMessage}>
                    {p.errorMessage}
                  </div>
                );
              } else {
                p.errorMessage.map((err, i) => {
                  return (
                    <div key={i} className={styles.errorMessage}>
                      {err}
                    </div>
                  );
                });
              }
            }
            return null;
          })}
      </div>
    </div>
  );
};

export default InputPill;
