import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { ManageOrganizationsContainer } from "containers";

const OrganizationContainer = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact component={ManageOrganizationsContainer} path={path}></Route>
      <Route path="*" render={() => <Redirect to={path} />} />
    </Switch>
  );
};

export default OrganizationContainer;
