import axios from 'axios';

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (localStorage.getItem('accessToken')) {
      config = {
        ...config,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          ...config.headers
        }
      };
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);
