import React from "react";
import styles from "./custom-question.module.scss";
import classnames from "classnames";
import { Checkbox, Icon } from "atomic/atoms";
import { Button, Pill } from "atomic/molecules";
import { v4 as uuidv4 } from "uuid";
import { isBetweenDate } from "utils";

const CustomQuestions = ({
  questions,
  toggleAutoAddQuestion,
  toggleCreateFormModal,
  toggleEditFormModal,
  loadingQuestionId,
}) => {
  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <p className={styles.columnName}>Questions</p>
          <p className={classnames(styles.columnName)}>Auto Add</p>
        </div>
        {questions.map(
          ({
            questionName,
            isAutoAdd,
            organizationQuestionId,
            isDefault,
            loading,
            createdDate,
          }) => {
            return (
              <div key={uuidv4()} className={styles.questionContainer}>
                <div
                  className={styles.question}
                  onClick={() =>
                    toggleEditFormModal({
                      organizationQuestionId,
                      visible: true,
                      isDefaultQuestion: false,
                    })
                  }
                >
                  {questionName}
                  <Pill
                    className={classnames(styles.pill, {
                      [`${styles.show}`]: isBetweenDate(createdDate, 4, "seconds"),
                    })}
                    text="New"
                  />
                </div>
                {loadingQuestionId && loadingQuestionId === organizationQuestionId ? (
                  <Icon loading={loading} name="loading" />
                ) : (
                  <Checkbox
                    className={styles.autoAdd}
                    value={isAutoAdd}
                    onChange={(checked) => {
                      toggleAutoAddQuestion(checked, organizationQuestionId, isDefault);
                    }}
                    disabled={loadingQuestionId}
                  />
                )}
              </div>
            );
          }
        )}
      </div>
      <Button
        onClick={() => {
          toggleCreateFormModal({ visible: true });
        }}
        text={"Create Custom Question"}
      />
    </>
  );
};

export default CustomQuestions;
