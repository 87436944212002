import React, { useMemo } from "react";
import styles from "./symptoms-table.module.scss";
import NoSymptomsLogged from "./no-symptoms-logged";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import moment from "moment";
import { Common, Mixpanel } from "enums";
import mixpanel from "mixpanel-browser";
import { Table, Links } from "atomic/atoms";
import { symptomString } from "services";

const tableColumns = [
  {
    title: localStrings.date,
    dataIndex: "date",
    key: uuidv4(),
    hasColumnAction: true,
    width: 122,
  },
  {
    title: localStrings.symptoms,
    dataIndex: "symptoms",
    key: uuidv4(),
    width: 200,
  },
];

const SymptomsTable = ({
  error,
  loading,
  data,
  scroll,
  confirmDeleteSymptom,
  isMemberProfile,
  ownAccount,
  actions,
  setCovidResult,
}) => {
  const tableData = useMemo(() => {
    let d = [...data];
    return d.map((d) => {
      const { dateTime, symptoms, additionalInfo, medicalTestType, testResult, symptomId } = d;
      const showMenuAction = () => {
        return true;
        // if (isMemberProfile && !ownAccount) {
        //   return d?.userLoggedBy !== d?.userId;
        // }
        // return d?.userLoggedBy === d?.userId;
      };
      console.log({
        isMemberProfile,
        ownAccount,
        value: showMenuAction(),
      });
      return {
        key: uuidv4(),
        date: (
          <div>
            <div>{dateTime ? moment(dateTime).format("MMM DD hh:mm A") : "-"}</div>
            {showMenuAction() && (
              <div>
                <Links
                  items={[
                    {
                      text: localStrings.edit,
                      className: styles.nameActions,
                      onClick: () => {
                        mixpanel.track(Mixpanel.VIEW_EDIT_SYMPTOMS);
                        actions.editSymptoms(symptomId);
                      },
                    },
                    {
                      text: localStrings.delete,
                      danger: true,
                      className: classnames(styles.nameActions, "cDanger"),
                      onClick: () => {
                        confirmDeleteSymptom({
                          symptomId,
                        });
                      },
                    },
                  ]}
                />
              </div>
            )}
          </div>
        ),
        symptoms: (
          <>
            <div className={classnames(styles.symptoms)}>
              {symptoms &&
                symptoms.map((symptom) => {
                  return (
                    <div key={uuidv4()} className={classnames(styles.symptom)}>
                      <div className={classnames(styles.pill)}>{symptomString[symptom]}</div>
                    </div>
                  );
                })}
            </div>
            {additionalInfo && <p className={styles.note}>{additionalInfo}</p>}
            {medicalTestType !== Common.OTHER && testResult && (
              <div
                className={classnames(styles.testResult)}
                onClick={() => {
                  setCovidResult({
                    visible: true,
                    result: d || {},
                  });
                }}
              >
                {localStrings.viewTestResults}
              </div>
            )}
          </>
        ),
      };
    });
  }, [data, actions, confirmDeleteSymptom, isMemberProfile, ownAccount, setCovidResult]);

  return !error && (loading || tableData.length) ? (
    <>
      <Table
        className={styles.table}
        columns={tableColumns}
        loading={loading}
        data={tableData}
        scroll={scroll}
      />
    </>
  ) : (
    <NoSymptomsLogged error={error} className={styles.table} />
  );
};

export default SymptomsTable;
