const Symptoms = {
  COUGH: "COUGH",
  TIREDNESS: "TIREDNESS",
  BODY_ACHE: "BODY_ACHE",
  SORE_THROAT: "SORE_THROAT",
  DIARRHEA: "DIARRHEA",
  CONJUNCTIVITIS: "CONJUNCTIVITIS",
  HEADACHE: "HEADACHE",
  LOSS_OF_TASTE_SMELL: "LOSS_OF_TASTE_SMELL",
  RASHES: "RASHES",
  CHEST_PAINS: "CHEST_PAINS",
  DIFFICULTY_BREATHING: "DIFFICULTY_BREATHING",
  FINGER_TOES_DISCOLORATION: "FINGER_TOES_DISCOLORATION",
  LOSS_OF_SPEECH_MOVEMENT: "LOSS_OF_SPEECH_MOVEMENT",
};

export default Object.freeze(Symptoms);
