import React from "react";
import ReactDOM from "react-dom";
import styles from "./style.json";
import MapControls from "./map-controls/map-controls";
import { debounced, metersPerPixel } from "utils";
import MarkerClusterer from "@googlemaps/markerclustererplus";

const defaultCenter = { lat: 14.563630799999999, lng: 121.0118986 }; // PH Office Coordinate
const defaultZoom = 15;
const google = window.google;

const initMap = ({ initCenter, controls, onCenterChange, onZoomChange, circle, zoom, ...rest }) => {
  const map = new google.maps.Map(document.getElementById("map"), {
    // center: { lat: 14.5823600867413, lng: 121.06883445762655 },
    center: initCenter,
    zoom: zoom || defaultZoom,
    styles,
    mapTypeControl: false,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    ...rest,
  });
  map.setOptions({ minZoom: 11 });

  if (circle) {
    new google.maps.Circle({
      strokeColor: circle.color,
      strokeOpacity: 0.4,
      strokeWeight: 2,
      fillColor: circle.color,
      fillOpacity: 0.1,
      map,
      center: { lat: circle.lat, lng: circle.lng },
      radius: circle.radius,
    });
  }

  const LatLng = google.maps.LatLng;
  const InfoWindow = google.maps.InfoWindow;
  const Marker = google.maps.Marker;

  if (controls) {
    var centerControlDiv = document.createElement("div");
    centerControlDiv.index = 1;
    ReactDOM.render(
      <MapControls map={map} initCenter={initCenter} defaultCenter={defaultCenter} />,
      centerControlDiv
    );
    map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(centerControlDiv);
  }

  if (onCenterChange) {
    const dHandler = debounced(1000, () => {
      const { lat: latitude, lng: longitude } = map.getCenter().toJSON();
      const zoom = map.getZoom();
      onCenterChange({
        latitude,
        longitude,
        meters: metersPerPixel(zoom, latitude),
      });
    });

    map.addListener("center_changed", dHandler);
    // map.addListener("zoom_changed", dHandler);
  }

  if (onZoomChange) {
    const dHandler = debounced(1000, () => {
      onZoomChange(map);
      console.log("Map Current Zoom:", map.getZoom());
    });
    map.addListener("zoom_changed", dHandler);
  }

  return {
    map,
    google,
    LatLng,
    MarkerClusterer,
    InfoWindow,
    Marker,
  };
};

export default initMap;
