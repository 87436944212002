import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Routes } from "enums";
import { useMount } from "hooks";
import { clearLocalStorage, getLocalItem, setLocalItem } from "utils";
import { SignIn, ForgotPassword, ResetPassword, RegisterUser } from "pages";

const AuthContainer = () => {
  let { path } = useRouteMatch();
  useMount(() => {
    const queryParam = getLocalItem("redirectQueryParam");

    clearLocalStorage();
    if (queryParam) {
      setLocalItem("redirectQueryParam", queryParam);
    }
  });

  return (
    <Switch>
      <Route exact component={RegisterUser} path={`/${Routes.ACTIVATE}/${Routes.USER}`} />
      <Route exact component={ForgotPassword} path={`${path}/${Routes.FORGOT_PASSWORD}`} />
      <Route exact component={ResetPassword} path={`${path}/${Routes.RESET_PASSWORD}`} />
      <Route exact component={SignIn} path={`${path}`} />
      <Route path="*" render={() => <Redirect to={`/${Routes.AUTH}`} />} />
    </Switch>
  );
};

export default AuthContainer;
