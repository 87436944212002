import React from "react";
import styles from "./users-location.module.scss";
import { convertTemperature, temperatureSymbol, isNormalTemp, getLocalItem } from "utils";
import classnames from "classnames";
import { Routes } from "enums";
import { Link } from "atomic/atoms";

const InfoWindow = ({ isPrivateName, isPrivateTemp, fullName, temp, isActive, userId }) => {
  const tempType = getLocalItem("tempType");
  return (
    <div className={styles.markerInfoWindow}>
      <Link
        href={`/${Routes.MEMBERS}/${userId}`}
        target="_blank"
        className={styles.markerInfoFullName}
      >
        {isPrivateName ? `#${userId}` : fullName}
      </Link>
      {!isPrivateTemp && (
        <p
          className={classnames(styles.markerInfoTemp, {
            cDanger: isActive && !isNormalTemp(convertTemperature(temp)),
            cInactive: !isActive,
          })}
        >
          {convertTemperature(temp, tempType)} {temperatureSymbol(tempType)}
        </p>
      )}
    </div>
  );
};

export default InfoWindow;
