import React, { useEffect, memo } from "react";
import styles from "./line-graph.module.scss";
import classnames from "classnames";
import Chart from "chart.js";
import { UnableToRetrieveError } from "pages";

const LineGraph = ({
  className,
  data,
  scales,
  labels,
  datasets,
  title,
  tooltip,
  loading,
  error,
  displayVerticalLine = true,
  yAxes,
}) => {
  useEffect(() => {
    if (!error) {
      var ctx = document.getElementById("Chart");
      new Chart(ctx, {
        scaleLineColor: "rgba(0,0,0,0)",
        type: "line",
        data: {
          labels,
          datasets: [
            {
              lineTension: 0,
              fill: false,
              label: "",
              data,
              backgroundColor: [
                "rgba(255, 99, 132, 0.2)",
                "rgba(54, 162, 235, 0.2)",
                "rgba(255, 206, 86, 0.2)",
                "rgba(75, 192, 192, 0.2)",
                "rgba(153, 102, 255, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ],
              borderColor: ["rgba(119,197,221, 1)"],
              borderWidth: 4,
              ...datasets,
            },
          ],
        },
        options: {
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display: displayVerticalLine,
                },
              },
            ],
            yAxes: [
              {
                ...yAxes,
                gridLines: {
                  drawBorder: displayVerticalLine,
                },
              },
            ],
          },
          tooltips: {
            titleFontStyle: "normal",
            titleFontSize: 12,
            bodyFontSize: 14,
            bodyAlign: "center",
            xPadding: 12,
            yPadding: 12,
            displayColors: false,
            callbacks: {
              title: (tooltipItem, data) => {
                return tooltip[tooltipItem[0].index];
              },
              label: (tooltipItem) => {
                return tooltipItem.value;
              },
            },
          },
        },
      });
    }
  }, [data, datasets, labels, scales, tooltip, error, displayVerticalLine, yAxes]);

  const getBody = () => {
    if (error) {
      return <UnableToRetrieveError className={styles.unableToRetrieve} />;
    }
    return <canvas id="Chart" className="chart"></canvas>;
  };
  return (
    <div className={classnames(styles.container, className)}>
      {title && <h2 className={styles.title}>{title}</h2>}
      {getBody()}
    </div>
  );
};

export default memo(LineGraph);
