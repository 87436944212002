import React from "react";
import styles from "./invite-manager-form.module.scss";
import { getFullName } from "utils";
import classnames from "classnames";
import { AccountStatus } from "enums";

const Suggestion = ({ firstName, lastName, email, accountStatus, onClick }) => {
  return (
    <div
      className={classnames(styles.suggestionItem, {
        [`${styles.unverified}`]:
          accountStatus === AccountStatus.UNVERIFIED_MEMBER ||
          accountStatus === AccountStatus.PENDING_MEMBER,
      })}
      onClick={(e) => {
        const isVerified =
          accountStatus !== AccountStatus.UNVERIFIED_MEMBER &&
          accountStatus !== AccountStatus.PENDING_MEMBER;
        onClick({ email, isVerified });
        e.preventDefault();
      }}
    >
      {accountStatus !== AccountStatus.PENDING_MEMBER && (
        <p className={styles.detail}>{getFullName({ firstName, lastName })}</p>
      )}
      <p className={classnames(styles.detail, styles.email)}>{email}</p>
    </div>
  );
};

export default Suggestion;
