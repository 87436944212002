import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { CreateUser, EditUser } from "pages";
import { MemberProfileContainer, MemberSymptomContainer } from "containers";
import { Routes } from "enums";
import { ManagerRoute } from "routes";
import { MemberListContainer } from "containers";

const MemberContainer = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <ManagerRoute
        path={`${path}/${Routes.USER_ID}/${Routes.SYMPTOMS_LOG}`}
        component={MemberSymptomContainer}
      />
      <ManagerRoute exact component={CreateUser} path={`${path}/${Routes.CREATE}`} />
      <ManagerRoute exact component={EditUser} path={`${path}/${Routes.EDIT}/${Routes.USER_ID}`} />

      <Route exact component={MemberProfileContainer} path={`${path}/${Routes.USER_ID}/*`} />
      <Route exact component={MemberProfileContainer} path={`${path}/${Routes.USER_ID}`} />
      <ManagerRoute exact component={MemberListContainer} path={path} />

      <Route path="*" render={() => <Redirect to={path} />} />
    </Switch>
  );
};

export default MemberContainer;
