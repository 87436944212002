import React, { Fragment, useContext, useMemo } from "react";
import styles from "./active-organization-list.module.scss";
import { v4 as uuidv4 } from "uuid";
import localStrings from "localization";
import EmptyOrg from "images/empty-org.svg";
import ActiveOrganizationItem from "./active-organization-item/active-organization-item";
import { NotificationContext } from "contexts";
import { pluralize } from "utils";
import { Routes } from "enums";
import { useRouteMatch } from "react-router-dom";
import { getUserRole } from "services";
import { Image, Link } from "atomic/atoms";

const ActiveOrganizationList = ({ organizations = [], loading, leaveModal }) => {
  const { inviteRequestCount } = useContext(NotificationContext);

  let { path } = useRouteMatch();

  const renderOrganizationListItem = useMemo(() => {
    if (loading) {
      return loadingOrganizationItems;
    }
    if (organizations.length) {
      return organizations.map((d) => {
        return (
          <ActiveOrganizationItem
            {...d}
            loading={false}
            key={uuidv4()}
            leaveModal={() => leaveModal(true, d)}
            userRole={getUserRole(d)}
          />
        );
      });
    }
    return <EmptyList />;
  }, [organizations, leaveModal, loading]);

  return (
    <Fragment>
      <div className={styles.activeOrg}>
        <p className={styles.orgCountTitle}>
          {localStrings.activeOraganization}{" "}
          {!loading && <span className={styles.orgCount}>{organizations.length}</span>}
        </p>
        {!!inviteRequestCount && (
          <Link
            className={styles.inviteRequestLink}
            text={`${pluralize({
              count: inviteRequestCount,
              singular: localStrings.inviteRequest,
              plural: `${localStrings.inviteRequest}s`,
            })} ${inviteRequestCount ? "(" + inviteRequestCount + ")" : ""}`}
            to={`${path}/${Routes.INVITE_REQUEST}`}
          />
        )}
      </div>
      {renderOrganizationListItem}
    </Fragment>
  );
};

const loadingOrganizationItems = Array(5)
  .fill(null)
  .map(() => <ActiveOrganizationItem loading={true} key={uuidv4()} />);

const EmptyList = () => {
  return (
    <div className={styles.emptyOrgList}>
      <Image className={styles.image} src={EmptyOrg} />
      <h2 className={styles.emptyOrgListTitle}>{localStrings.youAreNotInAnyOrg}</h2>
      <p className={styles.emptyOrgListDesc}>{localStrings.youAreNotInAnyOrgDesc}</p>
      <p className={(styles.emptyOrgListDesc, styles.doYouWant)}>
        {localStrings.doYouWantToCreateYourOwnOrg}
      </p>
      <Link
        href="mailto:support@pouchpass.com"
        target="_blank"
        text={localStrings.pleaseContactOurSupport}
      />
    </div>
  );
};

export default ActiveOrganizationList;
