import React, { useContext } from "react";
import { UserForm } from "components/users/user-form";
import { createUser } from "api/user";
import { useApi } from "hooks";
import { OrganizationContext } from "contexts";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "enums";

const CreateUser = () => {
  const { organizationId } = useContext(OrganizationContext);
  const { request, loading } = useApi({
    api: createUser,
    params: {
      organizationId,
    },
    handleOwnError: {
      badrequest: true,
    },
  });
  async function onSubmit(form, success, error) {
    try {
      mixpanel.track(Mixpanel.ADD_MEMBER);
      await request(form);
      success();
    } catch (e) {
      error(e);
    }
  }

  return <UserForm onSubmit={onSubmit} submitting={loading} create></UserForm>;
};

export default CreateUser;
