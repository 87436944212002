import React, { useEffect } from "react";
import styles from "./profile-nav-link.module.scss";
import localStrings from "localization";
import classnames from "classnames";
import { useRouter } from "hooks";
import { Mixpanel, PrivacyKey } from "enums";
import { Icon, Tooltip, Link } from "atomic/atoms";
import mixpanel from "mixpanel-browser";

const ProfileNavLink = ({
  isMemberProfile = false,
  userPrivacyStatus,
  userPrivacyLoading,
  links,
  changeTab,
}) => {
  const { history } = useRouter();

  useEffect(() => {
    if (!userPrivacyLoading && !userPrivacyStatus.isSymptomAllowed) {
      history.push(history.location.pathname);
    }
  }, [userPrivacyStatus, userPrivacyLoading, history]);

  return (
    <div className={styles.menuTab}>
      {[
        [
          links.temperature,
          localStrings.temperature,
          !userPrivacyStatus[PrivacyKey.BODY_TEMPERATURE],
        ],
        [links.symptoms, localStrings.symptoms, !userPrivacyStatus[PrivacyKey.SYMPTOMS]],
        ...(isMemberProfile ? [[links.contactHistory, localStrings.contactHistory, false]] : []),
      ].map((t) => {
        const link = (children) => {
          return (
            <Link
              activeClassName={styles.activeTab}
              className={classnames(styles.link, {
                [`${styles.private}`]: t[2],
                [`${styles.forbidden}`]: t[2],
              })}
              exact
              to={t[0]}
              onClick={() => {
                if (changeTab && window.location.pathname !== t[0]) {
                  changeTab({ to: t[0] });
                }

                if (t[0].includes("symptoms")) {
                  mixpanel.track(Mixpanel.VIEW_SYMPTOMS);
                }
                if (t[0].includes("contact-history")) {
                  mixpanel.track(Mixpanel.VIEW_CONTACT_HISTORY);
                }
              }}
            >
              <div
                className={classnames({
                  [`${styles.private}`]: t[2],
                  [`${styles.forbidden}`]: t[2],
                })}
              >
                {children}
              </div>
            </Link>
          );
        };

        if (t[2]) {
          return link(
            <Tooltip title={localStrings.private}>
              <div>
                <Icon name="lock" className={styles.icon} loading={userPrivacyLoading} />
                {t[1]}
              </div>
            </Tooltip>
          );
        }
        return link(t[1]);
      })}
    </div>
  );
};

export default ProfileNavLink;
