import React from "react";
import { CommunityTableContainer } from "containers";
import CommunityHeader from "../community-header/community-header";

const CommunityList = ({ deactivateCommunityModal }) => {
  return (
    <div>
      <CommunityHeader />
      <CommunityTableContainer
        showDeactivateModal={deactivateCommunityModal.show}
        isDeactivateModalVisible={deactivateCommunityModal.visible}
        closeDeactivateModal={deactivateCommunityModal.close}
        deactivateModalState={deactivateCommunityModal.state}
      />
    </div>
  );
};

export default CommunityList;
