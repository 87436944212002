const pending = {
  allowUserManualInputTemperature:
    "Allow the user to manually input their own temperature if there is no paired PouchBAND.",
  createAForm: "Create a Form",
  createForm: "Create Form",
  editForm: "Edit Form",
  collectDailyInformation: "Collect daily information from your members.",
  formTitle: "Form Title",
  whatWouldYouNameForm: "What would you name this form?",
  activeStatus: "Active Status",
  activateThisForm:
    "Activate this form to be available for members to answer in the PouchPASS app.",
  askDeleteForm: "Delete Form?",
  confirmingDeleteForm:
    "Are you sure you want to remove this form? Try deactivating the form first so that it won’t be seen by your members. You won’t be able to recover the form once removed.",
  removedForm: "Removed form.",
  createQuestionFromSearch: "Create <b>“{0}”</b>",
  searchingString: "Searching <i>“{0}”</i>....",
  loading: "Loading...",
  savedNewQuestion: "Saved New Question.",
  linkedQuestionNotif:
    "Upon saving, the changes would be reflected in every form that this question is included.",
  questionFormPlaceholderTooltip:
    "Add a placeholder text to further help people answer.  eg. “Enter your answer here.”",
  autoAdd: "Auto-add?",
  chooseFieldType: "Choose a field type",
  typeYourQuestionHere: "Type your question here",
  labelName: "Label Name",
  saveQuestion: "Save Question",
  editQuestion: "Edit Question",
  newQuestion: "New Question",
  fieldType: "Field Type",
  placeholderText: "Placeholder Text",
  thisQuestionAlreadyExists: "This question already exist.",
  options: "Options",
  addAnotherOption: "Add Another Option",
  customFields: "Custom Fields",
  defaultFields: "Default Fields",
  whatWouldYouLikeToAsk: "What would you like to ask?",
  typeYourQuestion: "Type to add your question...",
  formInformation: "Form Information",
  thisPageHasSomeChanges: "This page has some changes.",
  thereAreSomeChanges:
    "There are some changes made to this page. Please review the form after reloading the page.",
  reloadPage: "Reload Page",
  removedQuestion: "Removed Question.",
  thisQuestionIsIncluded: "This question is included in {0} active {1}.",
  askToRemoveQuestion: "Are you sure you want to remove this question?",
  removeQuestionIncludedOnFormNotice:
    "This question would be removed from all the forms it is included.",
  thisActionCannotBeUndone: "This action cannot be undone.",
  removeQuestion: "Remove Question",
  removeQuestionAnyways: "Remove Question Anyways",
  askRemoveQuestion: "Remove Question?",
  questions: "Questions",
  questionsDescription: "Add and select the questions to be included in your form.",
  formSubmission: "Form Submission",
  setHowOftenMembers: "Set how often members are required to submit a response.",
  require: "Require",
  deadlineOfSubmission: "Deadline of Submission",
  availability: "Availability",
  formAvailability: "Form Availability",
  requireToSubmit: "Require to Submit",
  thisOptionAlreadyExists: "This option already exist.",
  memberDetailsDesc:
    "Any detail set as required would overrule a member’s privacy setting as long as they are a member of your community.",
  ifDetailIsRequiredDesc:
    "If a detail is required, it would always be available to the community and its managers.",
  minutes: "minutes",
  hours: "hours",
  days: "days",
  weeks: "weeks",
  anytime: "Anytime",
  once: "Once",
  everyDay: "Everyday",
  everyWeekOn: "Every Week on {0}",
  everyWeekDay: "Every Weekday (Monday to Friday)",
  monthlyEvery: "Monthly on the {0} {1}",
  annualyEvery: "Annually on {0}",
  askViewHere: "View Here.",
  accessoryIsNotRegistered: "This accessory isn't registered to PouchPASS",
  tempTrendDesc:
    "Total number of Members per day with higher than normal to extremely high temperatures.",
  tempCasesDesc: "Members with higher than normal to extremely high temperatures.",
  form: "Form",
  forms: "Forms",
  addAndSaveQuestion: "Add and Save Question",
  pleaseAddQuestion: "Please add question.",
  valueAlreadyExists: "“{0}” already exists.",
  admin: "Admin",
  communities: "Communities",
  dateCreated: "Date Created",
  status: "Status",
  createACommunity: "Create a Community",
  communityStatus: "Community Status",
  activeCommunity: "Active Community",
  activateThisCommunity: "Activate this community to be managed by the team of managers.",
  communityInformation: "Community Information",
  communityName: "Community Name",
  whatWouldYouLikeToNameThisComm: "What would you name this community?",
  latitude: "Latitude",
  longitude: "Longitude",
  assignManager: "Assign Manager",
  assignAManagerOfThisComm: "Assign a manager of this community.",
  editCommunity: "Edit Community",
  deactivate: "Deactivate",
  activateCommunity: "Activate Community",
  deactivateCommunity: "Deactivate Community",
  areYouSureYouWantToDeactivateCommunity:
    "Are you sure you want to deactivate this community? The managers of this community would not be able to access their community dashboard.",
  watchlistInviteAccepted: "Watchlist Invite Accepted",
  watcherCanNowWatchYou: "{0} can now view you in their personal watchlist.",
  toFollowBackWatchlist:
    "To follow back {0}, change your share information, or view and create your personal watchlist, please use the PouchPASS app on your mobile device.",
  done: "Done",
  enterEmailAddress: "Enter email address",
  communityAlreadyExist: "This community already exists.",
  high: "High",
  areaInformation: "Area Information",
  areaDescription: "What usually is this area for?",
  areaNamePlaceholder: "Choose a name that suits this area best",
  level: "Level",
  levelPlaceholder: "What level is this area located?",
  nthLevel: "Level {0}",
  gateways: "Gateways",
  assignAGateway: "Assign a Gateway",
  createAnArea: "Create an Area",
  assignGatewayDescription:
    "Assign a gateway per area to easily monitor all the active members and temperature cases within an area.",
  learnMore: "Learn more.",
  assignGateway: "Assign Gateway",
  gatewayName: "Gateway Name",
  gatewayNameDescription: "Give this gateway a name for easy tracking",
  gatewayMacAddressDescription: "Add the mac address of the gateway in this area",
  gatewayMacAddress: "Gateway Mac Address",
  gatewayTooltip:
    "Your gateway’s mac address is found on your PouchPASS gateway. (eg. D21CFDBEC8F6)",
  gatewayAlreadyAdded: "Gateway already added in your area",
  areaCreated: "{0} area created.",
  reConfigure: "RE-CONFIGURE",
  connected: "Connected",
  disconnected: "Disconnected",
  notConfigured: "Not Configured",
  learnMoreTitle: "Learn More",
  whatAreGateways: "What are gateways?",
  gatewayDescription:
    "A PouchPASS Gateway is a Bluetooth receiver that connects to your PouchBAND to your PouchPASS Dashboard for real time temperature monitoring.",
  findOutPouchPass: "Find out more about PouchPASS Gateway",
  howToSetUpPouchPass: "How to setup a PouchPASS Gateway",
  purchasePouchPass: "Purchase PouchPASS Gateways",
  varDeleted: "{0} deleted.",
  pouchPassSettings: "PouchPASS Settings",
  pouchBandRegistry: "PouchBAND Registry",
  pouchPassAdmin: "PouchPASS Admin",
  pouchBandDescription: "Manage all the listed PouchBAND Accessory IDs.",
  listUnlistAccessoryId: "List or Unlist Accessory IDs",
  listUnlistPouchBand: "List or unlist PouchBAND Accessory IDs into the PouchPASS servers.",
  copyPasteAccessoryId:
    "Copy and paste a list of Accessory IDs here from your CSV or Excel file...",
  list: "List",
  unlist: "Unlist",
  unlistAccessoryIds: "Unlist Accessory IDs?",
  unlistAccessoryId: "Unlist Accessory ID?",
  areYouSureUnlistAccessoryIds:
    "Are you sure you want to unlist these Accessory ID? These PouchBANDs would not be recognized by any of our products once unlisted.",
  search: "Search",
  searchPouchBandPlaceholder: "Search for a listed Accessory ID...",
  noResults: "No results",
  tryAnotherSearch: "Try another search...",
  totalListedAccessory: "Total Listed Accessory IDs",
  areYouSureUnlistAccessoryId:
    "Are you sure you want to unlist this Accessory ID? This PouchBAND would not be recognized by any of our products once unlisted.",
  accessoryUnlisted: "“{0}” unlisted.",
  unlistedAccessoryIds: "Unlisted {0} Accessory ID(s)",
  adminSettings: "Admin Settings",
  violationsCases: "Violation Cases",
};

export default Object.freeze(pending);
