import React, { useContext } from "react";
import localStrings from "localization";
import { Routes } from "enums";
import { NotificationContext, OrganizationContext } from "contexts";
import { HeaderNavigation } from "atomic/organisms";
import path from "path/path";

const AdminHeader = ({ logout, loading }) => {
  const notifications = useContext(NotificationContext);
  const organization = useContext(OrganizationContext);

  const tabs = [
    { text: localStrings.communities, path: path.COMMUNITY_LIST },
    { text: localStrings.adminSettings, path: path.ADMIN_SETTINGS },
  ];

  const menuOptions = [
    {
      text: "My Dashboard",
      icon: "dashboard-file",
      path: `/${Routes.PROFILE}`,
    },
    {
      text: "Manage Communities",
      icon: "community-building",
      path: `/${Routes.ORGANIZATION}`,
      notif: notifications.notificationCount,
    },
    {
      text: `${localStrings.settings}`,
      icon: "settings",
      path: `/${Routes.SETTINGS}`,
      notif: notifications.unreadInviteRequestCount || notifications.hasOrganizationPrivacyUpdate,
    },
    {
      text: "PouchPASS Admin",
      icon: "pouchnation",
      path: `/${Routes.ADMIN}`,
      divide: true,
      highlighted: true,
    },
    { text: `${localStrings.logout}`, icon: "logout", onClick: logout, divide: true },
  ];

  return (
    <HeaderNavigation
      tabs={tabs}
      menuOptions={menuOptions}
      organizationName={organization?.organizationName}
      loading={loading}
    />
  );
};

export default AdminHeader;
