import React, { memo } from "react";
import { Modal as AntModal } from "antd";
import { Icon } from "atomic/atoms";
import classnames from "classnames";
import styles from "./modal.module.scss";

const Modal = ({
  children,
  visible,
  onCancel,
  className,
  title,
  closable = true,
  destroyOnClose,
  actions = null,
  maskClosable = true,
}) => {
  return (
    <AntModal
      className={className}
      visible={visible}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      centered={true}
      closable={false}
      footer={null}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
      maskClosable={maskClosable}
    >
      {(title || closable) && (
        <div className={classnames(styles.header, "header")}>
          {title ? <h2 className={classnames(styles.title, "title")}>{title}</h2> : <span />}
          {closable && (
            <Icon className={classnames(styles.close, "close")} name="close" onClick={onCancel} />
          )}
        </div>
      )}

      <div className="body">{children}</div>
      {actions && <div className={classnames(styles.actions, "actions")}>{actions}</div>}
    </AntModal>
  );
};

export default memo(Modal);
