import React, { Fragment, useContext, useMemo } from "react";
import styles from "./member-table.module.scss";
import { memberTableColumns, myQuarantineMemberTableColumns } from "./member-table-columns";
import { AccountStatus, Routes } from "enums";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import classnames from "classnames";
import { ellipsis, isBetweenDate } from "utils";
import { Tooltip } from "antd";
import { getFullname, getPrettyMemberStatus, formatToLocalDate } from "services";
import { MemberOptionMenu } from "components/member";
import { InviteMemberContainer } from "containers";
import { Link, Pagination, Table, Loading, Temp } from "atomic/atoms";
import { environment } from "../../../environments/environment";
import { OrganizationContext } from "contexts";

const MemberTable = ({
  loading,
  data,
  total,
  filters,
  changePage,
  changePageSize,
  locations,
  tempType,
  isFilterDirty,
  onRowClick,
  usersRequest,
  activeMenu,
  setActiveMenu,
  inviteMemberModalVisible,
  toggleInviteMemberModal,
}) => {
  const { organizationId } = useContext(OrganizationContext);

  const isMyQuarantineCommunity =
    environment.id === "dev" ||
    environment.id === "staging" ||
    (environment.id === "production" && organizationId === 88)
      ? true
      : false;

  const preparedData = useMemo(() => {
    const getName = ({ firstName, lastName, userId, privateKeys, email }) => {
      if (privateKeys?.includes("firstName")) {
        return `#${userId}`;
      }
      const fullName = getFullname({ firstName, lastName });
      return fullName || email || "-";
    };

    return data.map((d) => {
      const { latestBodyTemp, lastDateTimeSynced, deviceUid, accountStatus, userId } = d;

      const isSynced = isBetweenDate(lastDateTimeSynced, 6, "hours");

      const name = getName(d);
      const prettyAccountStatus = getPrettyMemberStatus(accountStatus);
      const getAreaLocation = (userId) => {
        if (locations.hasOwnProperty(userId)) {
          if (locations[userId]) {
            return locations[userId];
          }
          return "-";
        }
        return <Loading />;
      };
      return {
        key: uuidv4(),
        accessoryStatus: (
          <Tooltip title={isSynced ? "Active" : "Inactive"}>
            <span
              className={classnames(styles.status, {
                [`${styles.notSync}`]: !isSynced,
              })}
            ></span>
          </Tooltip>
        ),
        name: (
          <span className="nameColumn">
            {<span className={"semi-bold"}>{ellipsis(name, 50)}</span>}
            <p className={"label"}>
              {accountStatus === AccountStatus.MANAGER ? (
                <Fragment>
                  <span className={styles.manager}>{prettyAccountStatus}</span>{" "}
                  <span className={styles.circle}></span> {localStrings.verifiedMember}
                </Fragment>
              ) : (
                prettyAccountStatus
              )}
            </p>
          </span>
        ),
        latestTemp: <Temp temp={latestBodyTemp} />,
        lastSynced: lastDateTimeSynced ? formatToLocalDate(lastDateTimeSynced) : "-",
        accessoryId: deviceUid ? deviceUid : "-",
        areaLocation: getAreaLocation(d.userId),
        menu: (
          <div
            onClick={(e) => {
              setActiveMenu(userId);
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
          >
            <MemberOptionMenu
              data={d}
              active={userId === activeMenu}
              setActive={setActiveMenu}
              callback={{
                revoke: () => usersRequest(),
                delete: () => usersRequest(),
                assign: () => usersRequest(),
                invite: () => usersRequest(),
                remove: () => usersRequest(),
                removeManager: () => usersRequest(),
              }}
              toggleInviteMemberModal={toggleInviteMemberModal}
            />
          </div>
        ),
      };
    });
  }, [data, locations, usersRequest, activeMenu, setActiveMenu, toggleInviteMemberModal]);

  const emptyRenderer = useMemo(() => {
    return {
      title: isFilterDirty ? localStrings.noMembersFound : localStrings.noUserYet,
      description: (
        <div className={styles.emptyTable}>
          <Fragment>
            <Link to={`/${Routes.MEMBERS}/${Routes.CREATE}`}>
              {isFilterDirty ? localStrings.addANewMember : localStrings.addMembers}
            </Link>{" "}
            {isFilterDirty ? localStrings.tryToSearchAgain : localStrings.startMonitorTeam}
          </Fragment>
        </div>
      ),
    };
  }, [isFilterDirty]);
  return (
    <Fragment>
      <Pagination
        className={styles.topPagination}
        total={total}
        pageSize={filters.pageSize}
        onChange={(page) => {
          changePage(page);
        }}
        onShowSizeChange={(_page, pageSize) => {
          changePageSize(pageSize);
        }}
        currentPage={filters.page}
        showSizeChanger={true}
      />
      <div className={styles.container}>
        <Table
          className={styles.table}
          columns={isMyQuarantineCommunity ? myQuarantineMemberTableColumns : memberTableColumns}
          loading={loading}
          data={preparedData}
          rawData={data}
          onRowClick={onRowClick}
          emptyRenderer={emptyRenderer}
          skeletonCount={15}
        />
      </div>
      <Pagination
        className={styles.bottomPagination}
        total={total}
        pageSize={filters.pageSize}
        onChange={(page) => {
          changePage(page);
        }}
        onShowSizeChange={(_page, pageSize) => {
          changePageSize(pageSize);
        }}
        currentPage={filters.page}
      />
      <InviteMemberContainer
        userId={activeMenu}
        visible={inviteMemberModalVisible}
        refetch={usersRequest}
        toggleInviteMemberModal={toggleInviteMemberModal}
      />
    </Fragment>
  );
};

export default MemberTable;
