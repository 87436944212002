import localStrings from "localization";
import localTranslation from "localization/localization";

const formFields = {
  newPassword: {
    value: "",
    error: {
      status: false,
      message: "",
    },
    required: true,
    maxLength: 20,
    customValidations: (value) => {
      if (value && value.length < 8) {
        return {
          status: true,
          message: localTranslation("pleaseUseAtleastNthCharacters", ["8"]),
        };
      }
    },
  },
  confirmPassword: {
    value: "",
    error: {
      status: false,
      message: "",
    },
    required: true,
    maxLength: 32,
    custom: "",
    customValidations: (value, custom, name, field) => {
      if (value !== custom && value && field.dirty) {
        return {
          status: true,
          message: localStrings.passwordsDoNotMatch,
        };
      }
    },
  },
};

export default formFields;
