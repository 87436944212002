import React, { useCallback, useState, useContext, useMemo } from "react";
import { AccountStatus, Mixpanel } from "enums";
import localTranslation from "localization/localization";
import {
  removeManager,
  revokeInviteManager,
  resendInvite,
  inviteUser,
} from "api/user-organization";
import { getFullName } from "utils";
import { useRouter, useApi } from "hooks";
import localStrings from "localization";
import { useConfirmationModal } from "components/common";
import styles from "./member-option-menu.module.scss";
import { deleteUser } from "api/user";
import { OrganizationContext } from "contexts";
import path from "path/path";
import mixpanel from "mixpanel-browser";
import { Toast } from "atomic/molecules";

const Container = ({ children }) => {
  return <div>{children}</div>;
};

const useUserActionOptions = ({ data, fromView, callback = {}, toggleInviteMemberModal }) => {
  const { organizationId } = useContext(OrganizationContext);
  const { history } = useRouter();
  const { accountStatus: aS, firstName, lastName, userId, email } = data || {};
  const accountStatus = aS || AccountStatus.PROFILE_ONLY;
  const name = getFullName({
    firstName,
    lastName,
  });
  const [loading, setLoading] = useState(false);
  const [inviteModalActive, setInviteModalActive] = useState(false);

  const request = ({ toast, api, callback }) => {
    setLoading(true);
    const submit = async () => {
      await api();
      setLoading(false);
      Toast({
        content: toast.message,
        success: true,
        visible: true,
        icon: "check-fill",
      }).open();
      if (callback) {
        callback();
      }
    };
    submit();
  };

  const { request: requestDeleteUser, loading: loadingUser } = useApi({
    api: deleteUser,
    params: {
      organizationId,
      userId,
    },
  });

  const { request: requestRevokeInviteManager, loading: loadingRevokeInviteManager } = useApi({
    api: revokeInviteManager,
    params: {
      organizationId,
      userId,
      roles: ["MANAGER"],
    },
  });

  const { request: requestRemoveAsManager, loading: loadingRemoveAsManager } = useApi({
    api: removeManager,
    params: {
      organizationId,
      userId,
      roles: ["MANAGER"],
    },
  });

  const { request: requestRemoveAsMember, loading: loadingRemoveAsMember } = useApi({
    api: removeManager,
    params: {
      organizationId,
      userId,
      roles: ["MEMBER"],
    },
  });

  const { request: requestResendInvite } = useApi({
    api: resendInvite,
    params: {
      organizationId,
      userId,
    },
  });

  const { request: requestInviteAsManager } = useApi({
    api: inviteUser,
    params: {
      organizationId,
      userId,
      email,
      roles: ["MANAGER"],
    },
  });

  const { show: showDeleteUserModal, render: renderDeleteUserModal } = useConfirmationModal({
    loading: loadingUser,
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: localStrings.formatString(
            localStrings.confirmDeleteMember,
            name === "-" ? localStrings.thisMember : name
          ),
        }}
      ></div>
    ),
    onOkText: localStrings.delete,
    title: `${localStrings.deleteMember}?`,
    onConfirm: () => {
      request({
        toast: {
          message: `“${name}” removed.`,
        },
        api: requestDeleteUser,
        callback: () => {
          callback.delete();
        },
      });
    },
  });

  const {
    show: showRevokeInvite,
    render: renderRevokeInvite,
    close: closeRevokeInvite,
  } = useConfirmationModal({
    loading: loadingRevokeInviteManager,
    title: localStrings.revokeInvite,
    onOkText: localStrings.revoke,
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: localTranslation("confirmRevokeManagerInvite", [email || name || ""]),
        }}
      ></div>
    ),
    onConfirm: () => {
      request({
        toast: {
          message: localTranslation("revokeInviteSuccess", [email || name || ""]),
        },
        api: requestRevokeInviteManager,
        callback: () => {
          callback.revoke();
          closeRevokeInvite();
        },
      });
    },
  });

  const {
    show: showRemoveAsManager,
    render: renderRemoveAsManager,
    close: closeRemoveManager,
  } = useConfirmationModal({
    loading: loadingRemoveAsManager,
    title: localStrings.askRemoveManager,
    onOkText: localStrings.remove,
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: localTranslation("confirmRemoveManager", [name]),
        }}
      ></div>
    ),
    onConfirm: () => {
      request({
        toast: {
          message: localTranslation("noLongerAManager", [name]),
        },
        api: requestRemoveAsManager,
        callback: () => {
          callback.removeManager();
          closeRemoveManager();
        },
      });
    },
  });

  const {
    show: showRemoveAsMember,
    render: renderRemoveAsMember,
    close: closeRemoveMember,
  } = useConfirmationModal({
    loading: loadingRemoveAsMember,
    title: localStrings.askRemoveMember,
    onOkText: localStrings.remove,
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: localTranslation("confirmRemoveMember", [name]),
        }}
      ></div>
    ),
    onConfirm: () => {
      request({
        toast: {
          message: localStrings.removedMember,
        },
        api: requestRemoveAsMember,
        callback: () => {
          callback.remove();
          closeRemoveMember();
        },
      });
    },
  });

  const getMenuOptionAccount = useCallback(() => {
    const viewProfile = fromView
      ? null
      : {
          onClick: () => {
            history.push(path.MEMBER_PROFILE(data.userId));
          },
          label: localStrings.viewProfile,
        };
    const editProfile = {
      onClick: () => {
        history.push(path.MEMBER_EDIT(data.userId));
      },
      label: localStrings.editProfile,
    };
    const removeAsManager = {
      label: localStrings.removeAsManager,
      onClick: () => showRemoveAsManager(),
    };
    const removeMember = {
      text: <div className={styles.danger}>{localStrings.removeFromOrganization}</div>,
      label: localStrings.removeMember,
      onClick: () => showRemoveAsMember(),
    };
    const assignManager = {
      label: localStrings.assignAsManager,
      onClick: () => {
        request({
          toast: {
            message: localTranslation("userIsNowAManager", [name || email]),
          },
          api: requestInviteAsManager,
          callback: () => {
            callback.assign();
          },
        });
      },
    };
    const resendEmailInvite = {
      label: localStrings.resendInvite,
      onClick: () => {
        request({
          toast: {
            message: localTranslation("resendInviteSuccess", [email]),
          },
          api: requestResendInvite,
          callback: () => {
            mixpanel.track(Mixpanel.RESEND_INVITE);
          },
        });
      },
    };
    const revokeInvite = {
      label: localStrings.revokeInvite,
      onClick: () => showRevokeInvite(),
      callback: () => {
        mixpanel.track(Mixpanel.REVOKE_INVITE);
      },
    };
    const deleteMember = {
      text: <div className={styles.danger}>{localStrings.delete}</div>,
      label: localStrings.deleteMember,
      onClick: () => {
        showDeleteUserModal();
      },
      callback: () => {
        mixpanel.track(Mixpanel.REMOVE_MEMBER);
      },
    };
    const inviteToPouchPass = {
      label: localStrings.inviteToPouchPass,
      onClick: () => {
        toggleInviteMemberModal(true);
        setInviteModalActive(true);
      },
    };
    return (
      {
        [AccountStatus.MANAGER]: [
          viewProfile,
          { ...removeAsManager, noBorder: true },
          removeMember,
        ],
        [AccountStatus.VERIFIED_MEMBER]: [
          viewProfile,
          { ...assignManager, noBorder: true },
          removeMember,
        ],
        [AccountStatus.PENDING_MEMBER]: [resendEmailInvite, { ...revokeInvite, noBorder: true }],
        [AccountStatus.UNVERIFIED_MEMBER]: [
          viewProfile,
          { ...editProfile, noBorder: true },
          { ...resendEmailInvite, noBorder: true },
          { ...revokeInvite, noBorder: true },
          deleteMember,
        ],
        [AccountStatus.PROFILE_ONLY]: [
          viewProfile,
          { ...editProfile, noBorder: true },
          { ...inviteToPouchPass, noBorder: true },
          deleteMember,
        ],
      }[accountStatus] || []
    );
  }, [
    history,
    data,
    fromView,
    showRemoveAsManager,
    showDeleteUserModal,
    showRemoveAsMember,
    showRevokeInvite,
    accountStatus,
    callback,
    email,
    name,
    requestInviteAsManager,
    requestResendInvite,
    toggleInviteMemberModal,
  ]);

  const render = useMemo(() => {
    return (
      <Container>
        {renderDeleteUserModal}
        {renderRevokeInvite}
        {renderRemoveAsManager}
        {renderRemoveAsMember}
      </Container>
    );
  }, [renderDeleteUserModal, renderRevokeInvite, renderRemoveAsManager, renderRemoveAsMember]);
  return {
    options: getMenuOptionAccount(data),
    render,
    loading,
    inviteModal: {
      userId: userId,
      visible: inviteModalActive,
      emailAddress: email,
      onRefetch: () => callback.invite(),
      onClose: () => {
        setInviteModalActive(false);
      },
    },
  };
};

export default useUserActionOptions;
