import React from "react";
import styles from "./select-button.module.scss";
import { Image } from "atomic/atoms";

const SelectButton = ({ image, title, description, note, onClick }) => {
  return (
    <div className={styles.buttonContainer} onClick={onClick}>
      <Image className={styles.image} src={image}></Image>
      <div className={styles.textContent}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
        <div className={styles.note}>{note}</div>
      </div>
    </div>
  );
};

export default SelectButton;
