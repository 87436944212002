import React, { useState, useMemo, useCallback, useEffect } from "react";
import localStrings from "localization";
import ManagersGuideImage1 from "images/managers-guide1.png";
import MemberGuideImage2 from "images/member-guide2.png";
import MemberGuideImage3 from "images/member-guide3.png";
import MemberGuideImage4 from "images/member-guide4.png";
import WristbandImage from "images/wristband.png";
import IphoneImage from "images/iphone.png";
import localTranslation, { LocalTranslation } from "localization/localization";
import styles from "./member-guide.module.scss";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { useApi, useForm, useMount } from "hooks";
import { ResponseCode } from "enums";
import { getOwnDevice, pairOwnDevice } from "api/user-device";
import { getLocalItem, removeLocalItem } from "utils";
import path from "path/path";
import { Image } from "atomic/atoms";
import { InputField, Button, Toast } from "atomic/molecules";
import { GuideModal } from "atomic/organisms";

const MemberGuide = ({ isVisible, onClose = () => null }) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(isVisible || getLocalItem("fromOnboarding") === "1");

  const { request: getOwnDeviceRequest } = useApi({ api: getOwnDevice });

  const {
    isFormValid,
    formFields,
    setFormFieldValue,
    submit,
    setFormFieldValues,
    clearFormFields,
  } = useForm({
    deviceUid: {
      required: true,
      value: "",
      maxLength: 14,
      min: {
        length: 12,
        message: "Minimum 12 characters.",
      },
      error: {
        status: false,
        message: "",
      },
    },
  });

  useMount(() => {
    removeLocalItem("fromOnboarding");
  });

  const fetchOwnDevice = async () => {
    const res = await getOwnDeviceRequest();
    if (res.length > 0) {
      setFormFieldValue(res[0].deviceUid, "deviceUid");
    }
  };

  useEffect(() => {
    if (isVisible) {
      setVisible(isVisible);
      clearFormFields();
      fetchOwnDevice();
    }
    // eslint-disable-next-line
  }, [isVisible]);

  const prepareErrorMessage = (code) => {
    switch (code) {
      case "8005":
        return localStrings.accessoryAlreadyPaired;
      case "8006":
        return localStrings.accessoryIsNotRegistered;
      default:
        return "";
    }
  };

  const submitDeviceUid = useCallback(() => {
    submit();
    if (isFormValid) {
      setLoading(true);
      const deviceUid = formFields.deviceUid.value;
      pairOwnDevice({ deviceUid })
        .then(() => {
          window.location.reload();
          // setLoading(false);
          // setVisible(false);
        })
        .catch((err) => {
          setLoading(false);
          const { status, metadata } = err;
          const { code } = metadata;
          if (status === ResponseCode.BAD_REQUEST) {
            setFormFieldValues({
              deviceUid: {
                value: deviceUid,
                error: {
                  status: true,
                  message: prepareErrorMessage(code),
                },
              },
            });
            return false;
          }
          Toast({
            content: localStrings.pleaseTryAgain,
            error: true,
            visible: true,
            icon: "caution",
          }).open();
        });
    }
  }, [isFormValid, formFields, setFormFieldValues, submit]);

  const itemsMemo = useMemo(() => {
    return [
      ...guidesItems,
      {
        content: (
          <ConnectPouchBandGuide setFormFieldValue={setFormFieldValue} formFields={formFields} />
        ),
        actions: ({ next, prev }) => {
          return (
            <div className={styles.submit}>
              <div>
                <Button
                  tertiary
                  className={styles.textButton}
                  onClick={() => {
                    prev();
                  }}
                  right={false}
                  icon="left-chevron"
                  text={localStrings.back}
                />
              </div>
              <div>
                <Button
                  className={styles.submitButton}
                  loading={loading}
                  disabled={!formFields.deviceUid.value || formFields.deviceUid.error?.status}
                  onClick={() => {
                    submitDeviceUid();
                  }}
                  text={localStrings.allSet}
                />
              </div>
              <div>
                <Button
                  secondary
                  onClick={() => {
                    setVisible(false);
                    onClose();
                  }}
                  text={localStrings.doThisLater}
                />
              </div>
            </div>
          );
        },
      },
    ];
  }, [loading, setFormFieldValue, formFields, submitDeviceUid, onClose]);

  return (
    <GuideModal
      close={() => {
        setVisible(false);
        onClose();
      }}
      visible={visible}
      className={classnames(styles.container)}
      modalClassName={styles.modal}
      items={itemsMemo}
    />
  );
};

const guidesItems = [
  {
    image: ManagersGuideImage1,
    title: localStrings.welcomeToPouchPass,
    description: localStrings.welcomeDescription,
  },
  {
    image: MemberGuideImage2,
    title: localStrings.monitorYourBodyTemp,
    description: localStrings.monitorYourBodyTempDesc,
  },
  {
    image: MemberGuideImage3,
    title: localStrings.stayOnTopOfYourHealthAndGetNotified,
    description: localStrings.stayOnTopOfYourHealthAndGetNotifiedDesc,
  },
  {
    image: MemberGuideImage4,
    title: localStrings.usePouchPassForYourFamilyOrBusiness,
    description: localStrings.formatString(
      localStrings.usePouchPassForYourFamilyOrBusinessDesc,
      `<span class=${styles.medium}>
                ${localStrings.temperatureCases}
                </span>`,
      `<span class=${styles.medium}>
                ${localStrings.geolocation}
                </span>`,
      `<a
                rel="noopener noreferrer"
                href="${path.BUSINESS_LAUNCH}"
                class=${styles.link}
                target="_blank"
                >
                ${localStrings.learnMore}
                </a>`
    ),
  },
];

const ConnectPouchBandGuide = ({ setFormFieldValue, formFields }) => {
  const contentMemo = useMemo(() => {
    return [
      {
        image: WristbandImage,
        description: localStrings.pouchBandStep1,
      },
      {
        image: IphoneImage,
        description: (
          <LocalTranslation
            text={localStrings.pouchBandStep2}
            items={[
              <a
                rel="noopener noreferrer"
                href={path.PLAY_STORE}
                className={styles.link}
                target="_blank"
              >
                {localStrings.playStore}
              </a>,
              <a
                rel="noopener noreferrer"
                href={path.APP_STORE}
                className={styles.link}
                target="_blank"
              >
                {localStrings.appStore}.
              </a>,
            ]}
          />
        ),
      },
    ].map((d, i) => {
      return (
        <div className={styles.item} key={uuidv4()}>
          <Image className={styles.image} src={d.image} />
          <div className={styles.step}>{localTranslation("stepNth", [i + 1])}</div>
          <div>{d.description}</div>
        </div>
      );
    });
  }, []);
  return (
    <div className={styles.container}>
      <h1>{localStrings.startYourPouchBand}</h1>
      <div className={styles.pouchBands}>{contentMemo}</div>
      <div className={styles.deviceUid}>
        <InputField
          className={styles.input}
          name="deviceUid"
          {...formFields.deviceUid}
          onChange={setFormFieldValue}
          placeholder={localStrings.inputAccessoryId}
        />
      </div>
      <p>
        <LocalTranslation
          text={localStrings.dontHavePouchBandYet}
          items={[
            <a
              rel="noopener noreferrer"
              href={path.POUCH_SHOP}
              className={styles.link}
              target="_blank"
            >
              {localStrings.orderOne}.
            </a>,
          ]}
        />
      </p>
    </div>
  );
};

export default MemberGuide;
