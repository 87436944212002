import { useState, useEffect } from "react";
import { getLocation } from "utils/map";

const useLocations = ({ initialize = false, data = [] }) => {
  const [locations, setLocation] = useState({});
  const [initializing, setInitializing] = useState(true);

  useEffect(() => {
    if (initialize && initializing) {
      setInitializing(false);
      getLocationAsync({
        data,
        setLocation,
        locations,
      });
    }
  }, [initialize, data, locations, initializing]);

  return {
    locations,
  };
};

const getLocationAsync = async ({ data, setLocation, locations }) => {
  const dd = [];

  data.forEach((e) => {
    if (e.latitude && e.longitude) {
      dd.push(e);
    }
  });
  for (const [index, elem] of dd.entries()) {
    const { latitude: lat, longitude: lng } = elem;
    const key = [lat, lng].join(",");
    setLocation((prev) => ({
      ...prev,
      [key]: {
        loading: true,
        index,
      },
    }));
    if (locations.hasOwnProperty(`${lat}, ${lng}`)) {
      setLocation((prev) => {
        return {
          ...prev,
          [key]: {
            loading: false,
            location: locations[`${lat}, ${lng}`],
          },
        };
      });

      continue;
    }
    await getLocation({ lat, lng })
      .then((res) => {
        locations[`${lat}, ${lng}`] = res;
        const key = [lat, lng].join(",");
        setLocation((prev) => {
          return {
            ...prev,
            [key]: {
              loading: false,
              location: res,
            },
          };
        });
      })
      .catch(() => {
        locations[`${lat}, ${lng}`] = "-";
        setLocation((prev) => {
          return {
            ...prev,
            [key]: {
              loading: false,
              location: "-",
            },
          };
        });
      });
  }
};

export default useLocations;
