import React, { useCallback, useContext } from "react";
import { OnboardingLayout, PrivacySettingsForm } from "components/onboarding";
import { LocalTranslation } from "localization/localization";
import styles from "./privacy-settings.module.scss";
import BackgroundImage from "images/lock.svg";
import localStrings from "localization";
import { useMount } from "hooks";
import { OrganizationContext, UserOrganizationsContext } from "contexts";
import { Toast } from "atomic/molecules";
import { removeLocalItem, setLocalItem } from "utils";
import path from "path/path";

const PrivacySettingsContainer = () => {
  const { organizationName } = useContext(OrganizationContext);

  const { refetchUserOrganizations } = useContext(UserOrganizationsContext);

  useMount(() => {
    removeLocalItem("isUserOnboarding");
  });

  const acceptPrivacySettingsCb = useCallback(async () => {
    try {
      await refetchUserOrganizations();
      setLocalItem("fromOnboarding", 1);
      window.location.replace("/");
    } catch (error) {
      Toast({
        content: localStrings.pleaseTryAgain,
        error: true,
        visible: true,
        icon: "caution",
      }).open();
    }
  }, [refetchUserOrganizations]);

  return (
    <OnboardingLayout
      backgroundImage={BackgroundImage}
      content={{
        description: localStrings.iformationIsImportantNarrative,
        header: localStrings.chooseWhichInformationToShare,
        content: (
          <span className={styles.privacyPolicy}>
            <LocalTranslation
              text={localStrings.toLearnAboutPouchPass}
              items={[
                <a
                  rel="noopener noreferrer"
                  href={path.BUSINESS_PRIVACY}
                  className={styles.termsLink}
                  target="_blank"
                >
                  {localStrings.privacyPolicy}.
                </a>,
              ]}
            />
          </span>
        ),
      }}
    >
      <div className={styles.container}>
        <PrivacySettingsForm
          organizationName={organizationName}
          acceptPrivacySettings={acceptPrivacySettingsCb}
        />
      </div>
    </OnboardingLayout>
  );
};

export default PrivacySettingsContainer;
