import React, { useContext, memo, Fragment } from "react";
import styles from "./users-list.module.scss";
import UserListItem from "./user-list-item";
import { LiveMapContext } from "contexts";
import classnames from "classnames";
import { Loading, Icon } from "atomic/atoms";

const UserListsItems = ({ locations }) => {
  const {
    state: {
      users: { loading, data },
    },
  } = useContext(LiveMapContext);
  console.log(data);
  const render = (() => {
    if (loading) {
      const items = [];
      for (let i = 0; i <= 9; i++) {
        items[i] = <UserListItem loading={loading} key={i} />;
      }
      return <Fragment>{items}</Fragment>;
    }
    return (
      <Fragment>
        {data.map(({ userId, isActive, areaName, location, ...user }) => {
          const getAreaLocation = (userId) => {
            if (locations.hasOwnProperty(userId)) {
              if (locations[userId] || areaName || location) {
                if (isActive) {
                  return areaName || locations[userId] || location || "-";
                }
                return (
                  <>
                    <Icon className={styles.inactiveIcon} name="exclamation-fill" />
                    {`Last seen at ${areaName || locations[userId] || location || "-"}`}
                  </>
                );
              }
              return areaName || location || "-";
            }
            return <Loading height="100%" width="100%" />;
          };
          return (
            <UserListItem
              {...user}
              location={getAreaLocation(userId)}
              userId={userId}
              key={userId}
              isActive={isActive}
            />
          );
        })}
      </Fragment>
    );
  })();

  return (
    <div
      className={classnames(styles.userListItems, {
        // [`${styles.loading}`]: loading,
      })}
    >
      {render}
    </div>
  );
};

export default memo(UserListsItems);
