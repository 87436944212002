import React, { useState, useMemo, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { getFeverDetails } from "api/user-data";
import { useMount } from "hooks";
import { convertTemperature, isNormalTemp, temperatureSymbol } from "utils";
import classnames from "classnames";
import { OrganizationContext } from "contexts";
import useApi from "hooks/useApi";
import styles from "../overview.module.scss";
import EveryoneIsSafe from "images/everyone-is-safe-2.svg";
import { Common, Routes } from "enums";
import localStrings from "localization";
import { Details } from "atomic/molecules";

const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "name",
    title: localStrings.name,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "temperature",
    title: localStrings.temperature,
    width: 100,
  },
];
const noData = {
  title: localStrings.everyoneIsSafe,
  description: localStrings.everyoneIsSafeDesc,
  image: EveryoneIsSafe,
};

const FeverDetailsContainer = ({ tempType }) => {
  const [hasError, setHasError] = useState(false);
  const { organizationId } = useContext(OrganizationContext);

  const { request, loading, result = { data: [], total: 0 } } = useApi({
    api: getFeverDetails,
  });

  useMount(() => {
    const init = async () => {
      try {
        await request({ organizationId });
      } catch (error) {
        setHasError(true);
      }
    };
    init();
  });

  const preparedData = useMemo(() => {
    const { data } = result;

    return data.map((d) => {
      const { firstName, lastName, latestBodyTemp, privateKeys, userId, email } = d;
      const tempVal = convertTemperature(latestBodyTemp, tempType);
      const isPrivateName = privateKeys?.includes("firstName");
      const name = () => {
        const fullName = `${firstName || ""} ${lastName || ""}`.trim();
        if (isPrivateName) {
          return `#${userId}`;
        }
        if (fullName) {
          return fullName;
        }
        if (email) {
          return email;
        }
        return "-";
      };
      return {
        key: uuidv4(),
        name: name(),
        temperature: tempVal ? (
          <span
            className={classnames("bold", {
              cDanger: !isNormalTemp(tempVal, tempType),
            })}
          >
            {`${tempVal} ${temperatureSymbol(tempType)}`}
          </span>
        ) : null,
      };
    });
  }, [result, tempType]);

  return (
    <Details
      className={styles.detail}
      title={localStrings.temperatureCases}
      description={localStrings.tempCasesDesc}
      viewAllText={localStrings.viewAll}
      viewAllCount={result.total}
      viewAllLink={`/${Routes.MEMBERS}`}
      viewAllLocationState={{ bodyTemp: Common.FEVER }}
      loading={loading}
      data={preparedData}
      error={hasError}
      noData={noData}
      columns={tableColumns}
    />
  );
};

export default FeverDetailsContainer;
