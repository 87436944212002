import { forgotPassword } from "api/auth";
import localStrings from "localization";
import { ResponseCode, MetadataCode } from "enums";

export const submitForgotPassword = ({
  formFields,
  appDispatch,
  Toast,
  isSubmitted,
  setIsSubmitted,
  setErrorMsg,
  setLoading,
}) => {
  console.log(formFields);
  const { email } = formFields;
  setLoading(true);
  forgotPassword(email)
    .then((res) => {
      Toast({
        content: localStrings.resetLinkResent,
        success: true,
      }).open();
      setIsSubmitted(true);
    })
    .catch((e) => {
      const { networkError, serverError, status, metadata } = e;
      if (networkError) {
        appDispatch({ type: "NETWORK_ERROR" });
      }
      if (serverError) {
        appDispatch({ type: "SERVER_ERROR" });
      }

      if (status === ResponseCode.BAD_REQUEST) {
        if (metadata.code === MetadataCode.EMAIL_IS_NOT_FOUND) {
          setErrorMsg(localStrings.uhOhEmailNotRegistered);
          return;
        }
      }
    })
    .finally(() => {
      setLoading(false);
    });
};
