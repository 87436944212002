import React from "react";
import styles from "./network.module.scss";
import localStrings from "localization";
import { ReactComponent as NetworkErr } from "images/network-error.svg";

const NetworkError = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <NetworkErr className={styles.image} />
        <div className={styles.message}>
          <h2 className={styles.title}>{localStrings.oopsSomethinngWentWrong}</h2>
          <p className={styles.description}>{localStrings.networkErrorDesc}</p>
          <p className={styles.description}>{localStrings.try}</p>
          <ul className={styles.trySteps}>
            <li>{localStrings.checkingInternet}</li>
            <li>{localStrings.refreshPage}</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NetworkError;
