import React from "react";
import classnames from "classnames";
const Text = ({
  strong,
  size = "text-sm",
  color = "text-black",
  children,
  className,
  fontWeight = "font-normal",
}) => {
  const classStyle = classnames(className);

  return (
    <p className={classnames(`${strong ? "" : fontWeight} ${size} ${color}`, classStyle)}>
      {children}
    </p>
  );
};

export default Text;
