import React from "react";
import { useModal } from "components/common";
import styles from "./add-device.module.scss";
import { useForm } from "hooks";
import initialFormState from "./initial-form-state";
import localStrings from "localization";
import Wristband from "images/wristband.png";
import { useApi } from "hooks";
import { Image } from "atomic/atoms";
import { InputField, Button, Toast } from "atomic/molecules";

const useAddDevice = ({ userId, organizationId = null, api, refresh }) => {
  const {
    formFields,
    setFormFieldValue,
    submit,
    clearFormFieldsError,
    setCustomField,
    setFormFieldValues,
  } = useForm(initialFormState, submitCallBack);
  const { deviceUid } = formFields;

  const { request, loading } = useApi({
    handleOwnError: {
      code: {
        8005: () => {
          setCustomField([deviceUid.value], "deviceUid");
        },
        8006: () => {
          setFormFieldValues({
            deviceUid: {
              value: deviceUid.value,
              error: {
                status: true,
                message: localStrings.accessoryIsNotRegistered,
              },
            },
          });
        },
      },
    },
    api,
    params: {
      userId,
      organizationId,
    },
  });

  const { render, show, close } = useModal({
    title: localStrings.addAccessory,
    content: (
      <>
        <div className={styles.body}>
          <Image src={Wristband} className={styles.wristbandImage} />
          <p>{localStrings.connectAPouchBandDesc}</p>
          <div>
            <InputField
              className={styles.input}
              placeholder={localStrings.inputAccessoryId}
              name="deviceUid"
              onChange={setFormFieldValue}
              {...deviceUid}
            />
          </div>
        </div>

        <div className={styles.actions}>
          <Button
            onClick={() => closeModal()}
            className={styles.cancel}
            tertiary
            text={localStrings.cancel}
          />
          <Button
            loading={loading}
            className={styles.save}
            text={localStrings.addAccessory}
            onClick={submit}
          />
        </div>
      </>
    ),
  });

  const closeModal = () => {
    close();
    setFormFieldValue("", "deviceUid");
    clearFormFieldsError(["deviceUid"]);
  };

  function submitCallBack(formValue) {
    const { deviceUid } = formValue;
    const pair = async (d) => {
      try {
        await request(d);
        closeModal();
        Toast({
          visible: true,
          content: localStrings.changesSaved,
          icon: "check-fill",
          success: true,
        }).open();
      } catch (e) {
        // setCustomField([deviceUid], "deviceUid");
        console.log(e);
        // 8005
      }

      await refresh();
    };
    pair({ deviceUid });
  }

  return { show, render, close };
};

export default useAddDevice;
