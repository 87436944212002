import { resetPassword } from "api/auth";
import { MetadataCode } from "enums";

export const submitForm = async ({ newPassword: password, token, setIsSuccess, setLoading }) => {
  try {
    setLoading(true);
    const res = await resetPassword({ token, password });
    if (res.status === "success") {
      setIsSuccess(true);
    }
  } catch (e) {
    if (e.metadata.code === MetadataCode.RESET_TOKEN_NOT_EXISTS) {
      setIsSuccess(false);
    }
  } finally {
    setLoading(false);
  }
};
