import React, { useState } from "react";
import styles from "./account-settings.module.scss";
import localString from "localization";
import AccountSettingsForm from "./account-settings-form/account-settings-form";
import UpdatePassword from "./update-password/update-password";
import classnames from "classnames";
import { countryDialingCodeOptions } from "utils/countries";
import { OTPFormModal } from "components/auth";
import { InputField, Panel, SelectField } from "atomic/molecules";
import { DiscardModal } from "atomic/organisms";
import localStrings from "localization";

const AccountSettings = ({
  formFields,
  formFieldValues,
  setFormFieldValue,
  onSubmit,
  user,
  activeForm,
  setActiveForm,
  submitting,
  forms,
  otpModal,
  showOtpModal,
  handleCloseOTPModal,
  otpCallback,
  error,
  onChange,
}) => {
  const [discardModal, setDiscardModal] = useState(false);

  const countryCodes = countryDialingCodeOptions();

  return (
    <div className={styles.container}>
      <DiscardModal show={discardModal} setDiscardModal={setDiscardModal} />
      <OTPFormModal
        onClose={handleCloseOTPModal}
        visible={otpModal}
        successOtpCallback={otpCallback}
        email={formFieldValues.email}
        firstName={user.firstName}
      />
      <div>
        <div className={styles.form}>
          <Panel className={styles.panel}>
            <div className={styles.panelTitle}>
              <h3>{localString.accountInformation}</h3>
            </div>
            <div className={styles.formContainer}>
              <div className={styles.formContent}>
                <AccountSettingsForm
                  name={forms.Email}
                  label={localString.emailAddress}
                  placeholder={localString.emailPlaceholder}
                  state={formFields.email}
                  onCancel={() => {
                    setFormFieldValue(user[forms.Email], forms.Email);
                    setActiveForm(null);
                  }}
                  submitting={submitting}
                  activeForm={activeForm}
                  setActiveForm={setActiveForm}
                  value={user?.email}
                  onSubmit={showOtpModal}
                  onChange={(value, name) => {
                    if (onChange) {
                      onChange(value, name);
                    }
                  }}
                  isFormFieldDirty={formFields.email.dirty}
                  error={error}
                />
              </div>
              <div className={styles.formContent}>
                <AccountSettingsForm
                  name={forms.Mobile}
                  label={localString.mobileNumber}
                  onCancel={() => {
                    setFormFieldValue(user.phone, "phone");
                    setFormFieldValue(user.phoneCountryCode, "phoneCountryCode");
                    setActiveForm(null);
                  }}
                  submitting={submitting}
                  activeForm={activeForm}
                  setActiveForm={setActiveForm}
                  value={`${user?.phoneCountryCode || ""} ${user?.phone || ""}`.trim()}
                  onSubmit={onSubmit}
                  setFormFieldValue={setFormFieldValue}
                  isFormFieldDirty={formFields.phone.dirty || formFields.phoneCountryCode.dirty}
                  input={
                    <div className={styles.input}>
                      <span className={classnames(styles.formLabel)}>
                        {localString.mobileNumber}
                      </span>
                      <div className={styles.inputMobileNumber}>
                        <div>
                          <SelectField
                            classNameContainer={classnames(styles.mobileNumber)}
                            data={countryCodes}
                            name="phoneCountryCode"
                            onChange={setFormFieldValue}
                            {...formFields.phoneCountryCode}
                            placeholder="+63"
                          />
                        </div>
                        <div>
                          <InputField
                            name="phone"
                            onChange={setFormFieldValue}
                            {...formFields.phone}
                            placeholder="916 4567 890"
                          />
                        </div>
                      </div>
                    </div>
                  }
                />
              </div>
            </div>
          </Panel>
        </div>
        <div className={styles.form}>
          <Panel className={styles.panel}>
            <div
              className={classnames(
                styles.panelTitle,
                activeForm === forms.Password ? "" : styles.noBorder
              )}
            >
              <h3>{localString.password}</h3>
              {activeForm !== forms.Password && (
                <div
                  className={styles.editButton}
                  onClick={() => {
                    setActiveForm(forms.Password);
                  }}
                >
                  {localStrings.edit}
                </div>
              )}
            </div>
            {activeForm === forms.Password && (
              <div className={styles.formContainer}>
                <UpdatePassword
                  className={classnames(styles.formContent, styles.passwordForm)}
                  onCancel={() => {
                    setActiveForm(null);
                  }}
                />
              </div>
            )}
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default AccountSettings;
