import React, { useState } from "react";
import styles from "./symptoms.module.scss";
import classnames from "classnames";
// import localStrings from "localization";
import { searchSymptoms } from "api/symptom";
import { useApi, useMount } from "hooks";
import useDeleteSymptoms from "./delete-symptoms/delete-symptoms";
import CovidResultModal from "./covid-result-modal/covid-result-modal";
import SymptomsHeader from "./symptoms-header/symptoms-header";
import SymptomsTable from "./symptoms-table/symptoms-table";
import { Panel } from "atomic/molecules";

const SymptomsTab = ({
  className,
  scroll,
  isMemberProfile = false,
  user,
  organizationId = null,
  strings,
  actions,
  ownAccount,
  // userPrivacyStatus,
  // userPrivacyLoading,
}) => {
  const [covidResult, setCovidResult] = useState({
    visible: false,
    result: {},
  });
  const userId = user?.userId;

  const { request, loading = true, result = { data: { symptoms: [] } }, error } = useApi({
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
    api: () =>
      searchSymptoms({
        userId,
        organizationId,
        showManagerCreated: true,
      }),
  });

  const { render: ConfirmDeleteSymptom, show: confirmDeleteSymptom } = useDeleteSymptoms({
    refresh: request,
  });

  const data = result.data.symptoms;
  useMount(() => {
    const init = async () => {
      await request();
    };
    init();
  });

  const goToLogSymptoms = () => {
    actions.createSymptoms();
  };

  // if (!userPrivacyStatus.isSymptomAllowed && !userPrivacyLoading) {
  //   return (
  //     <Panel className={classnames(styles.warning)}>
  //       <div>
  //         <h3 className={styles.title}>{localStrings.symptoms}</h3>
  //       </div>
  //       {localStrings.privacySymptomsWarning(user.name)}
  //     </Panel>
  //   );
  // }

  return (
    <Panel className={classnames(styles.container, className)}>
      {ConfirmDeleteSymptom}
      <CovidResultModal
        visible={covidResult.visible}
        result={covidResult.result}
        onClose={() => {
          setCovidResult((prev) => ({ ...prev, visible: false }));
        }}
      />
      <SymptomsHeader strings={strings} goToLogSymptoms={goToLogSymptoms} />
      <SymptomsTable
        data={data}
        error={error}
        loading={loading}
        scroll={scroll}
        setCovidResult={setCovidResult}
        actions={actions}
        ownAccount={ownAccount}
        confirmDeleteSymptom={confirmDeleteSymptom}
        isMemberProfile={isMemberProfile}
      />
    </Panel>
  );
};

export default SymptomsTab;
