import React from "react";
import { Modal } from "atomic/atoms";
import { Button } from "atomic/molecules";
import localStrings from "localization";
import styles from "./leave-organization-modal.module.scss";

const LeaveOrganizationModal = ({ organizationName, visible, onCancel, loading, leaveOrg }) => {
  return (
    <Modal visible={visible} title={`${localStrings.leaveOrganization}?`} onCancel={onCancel}>
      <div className={styles.description}>
        Are you sure you want to leave <span className={styles.orgName}>{organizationName}</span>?
        This action cannot be undone. You won’t be able to rejoin this community without a new
        invite.
      </div>
      <div className={styles.submit}>
        <Button tertiary onClick={onCancel}>
          {localStrings.cancel}
        </Button>
        <Button onClick={leaveOrg} danger loading={loading}>
          {localStrings.yesLeave}
        </Button>
      </div>
    </Modal>
  );
};

export default LeaveOrganizationModal;
