import React, { memo, useContext, useCallback, useEffect, useMemo, useState, useRef } from "react";
import styles from "./member-reports.module.scss";
import { Dropdown, Icon, Text } from "atomic/atoms";
import { OrganizationContext } from "contexts";
import { searchUsers, searchUserSymptoms, searchUserAdvancedExport } from "api/user-data";
import { useApi } from "hooks";
import { CSVLink } from "react-csv";
import { Button, Toast } from "atomic/molecules";

const DropdownMemberReports = () => {
  const { organizationId } = useContext(OrganizationContext);

  const {
    request: basicReportRequest,
    loading: basicReportRequestLoading,
    result: basicMemberReport = { data: [], total: 0, status: "" },
  } = useApi({
    api: searchUsers,
  });

  const {
    request: symptomsRequest,
    loading: symptomsRequestLoading,
    result: symptomsReport = { data: [], total: 0 },
  } = useApi({
    api: searchUserSymptoms,
  });

  const {
    request: advancedReportRequest,
    loading: advancedReportRequestLoading,
    result: advancedMemberReport = { data: [], total: 0 },
  } = useApi({
    api: searchUserAdvancedExport,
  });

  const isLoading = useMemo(() => {
    return basicReportRequestLoading || symptomsRequestLoading || advancedReportRequestLoading;
  }, [basicReportRequestLoading, symptomsRequestLoading, advancedReportRequestLoading]);

  const fetchBasicReport = useCallback(async () => {
    try {
      let exportState = { organizationId, page: null, pageSize: null };
      await basicReportRequest(exportState);
    } catch (error) {
      console.log(error);
    }
  }, [organizationId, basicReportRequest]);

  const generateAdvancedReport = useCallback(async () => {
    try {
      let exportState = { organizationId, page: null, pageSize: null };
      await searchUserAdvancedExport({ organizationId });
      await advancedReportRequest(exportState);
    } catch (error) {
      console.log(error);
      Toast({
        content: "Failed to Fetch Advanced Member Reports",
        error: true,
        visible: true,
        icon: "caution",
      }).open();
    }
  }, [organizationId, advancedReportRequest]);

  const generateSymptomsReport = useCallback(async () => {
    try {
      let exportState = { organizationId, page: null, pageSize: null };
      await symptomsRequest(exportState);
    } catch (error) {
      console.log(error);
    }
  }, [organizationId, symptomsRequest]);

  const csvLinkRef = useRef(null);

  const { total: basicMemberReportTotal, data: basicMemberReportData } = basicMemberReport;
  const { total: advancedMemberReportTotal, data: advancedMemberReportData } = advancedMemberReport;
  const { data: symptomsReportData } = symptomsReport;
  const symptomsReportTotal = symptomsReport.data.length;

  const [downloadAdvancedReport, setDownloadAdvancedReport] = useState(advancedMemberReportTotal);
  const [downloadSymptomsReport, setDownloadSymptomsReport] = useState(symptomsReportTotal);

  const [downloadBasicReport, setDownloadBasicReport] = useState(basicMemberReportTotal);
  const [renderCsvLink, setRenderCsvLink] = useState(false);

  const [newBasicMemberReportData, setNewBasiCMemberReportData] = useState([]);

  const [newBasicMemberReportTotal, setNewBasicMemberReportTotal] = useState(
    newBasicMemberReportData.length
  );

  const test = newBasicMemberReportData.length;
  console.log(newBasicMemberReportData);
  console.log(newBasicMemberReportTotal, test);

  // Update Basic Members Report Data
  const changeData = () => {
    let newData = [];
    basicMemberReportData.forEach((data) => {
      newData.push({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        latestBodyTemp: data.latestBodyTemp,
        latestAmbientTemp: data.latestAmbientTemp,
        deviceUid: data.deviceUid,
        deviceType: data.deviceType,
        lastDateTimeSynced: data.lastDateTimeSynced,
        areaName: data.areaName,
        gatewayUid: data.gatewayUid,
        location: data.location,
        latitude: data.latitude,
        longitude: data.longitude,
        profileId: data.profileId,
        accountStatus: data.accountStatus,
        privateKeys: data.privateKeys,
      });
    });
    setNewBasiCMemberReportData(newData);
  };

  // Update Basic Members Report Data After fetch
  useEffect(() => {
    try {
      if (downloadBasicReport > 0) {
        changeData();
        setNewBasicMemberReportTotal(newBasicMemberReportTotal + 1);
      }
    } catch (error) {
      console.log(error);
      Toast({
        content: "Failed to Download Basic Member Reports",
        error: true,
        visible: true,
        icon: "caution",
      }).open();
    }
    // eslint-disable-next-line
  }, [downloadBasicReport]);

  // download data if available
  useEffect(() => {
    try {
      if (newBasicMemberReportTotal > 0) {
        setTimeout(() => {
          csvLinkRef.current.link.click();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      Toast({
        content: "Failed to Download Basic Member Reports",
        error: true,
        visible: true,
        icon: "caution",
      }).open();
    }
  }, [newBasicMemberReportTotal]);

  useEffect(() => {
    if (advancedMemberReportTotal > 0) {
      try {
        setTimeout(() => {
          console.log(true);
          window.open(advancedMemberReportData, "Advanced_Members_Report");
        }, 2000);
      } catch (error) {
        console.log(error);
        Toast({
          content: "Failed to Download Advanced Member Reports",
          error: true,
          visible: true,
          icon: "caution",
        }).open();
      }
    }
    // eslint-disable-next-line
  }, [advancedMemberReportTotal, downloadAdvancedReport]);

  useEffect(() => {
    try {
      if (symptomsReportTotal > 0) {
        window.open(symptomsReportData, "_blank");
      }
    } catch (error) {
      console.log(error);
      Toast({
        content: "Failed to Download Symptoms Report",
        error: true,
        visible: true,
        icon: "caution",
      }).open();
    }
    // eslint-disable-next-line
  }, [symptomsReportTotal, downloadSymptomsReport]);

  return (
    <>
      <Dropdown
        trigger="click"
        placement="bottomRight"
        className=""
        isDisabled={false}
        optionsMenu={styles.dropdownOptions}
        menuItems={[
          {
            value: "0",
            text: (
              <div
                className={styles.menuItems}
                onClick={() => {
                  fetchBasicReport().then(() => {
                    setDownloadBasicReport(downloadBasicReport + 1);
                  });
                  setRenderCsvLink(true);
                }}
              >
                <Text className={styles.p2}>Basic Members Report</Text>
              </div>
            ),
          },
          {
            value: "1",
            text: (
              <div
                className={styles.menuItems}
                onClick={() => {
                  generateAdvancedReport();
                  setDownloadAdvancedReport(downloadAdvancedReport + 1);
                }}
              >
                <Text className={styles.p2}>Advanced Members Report</Text>
              </div>
            ),
          },
          {
            value: "2",
            text: (
              <div
                className={styles.menuItems}
                onClick={() => {
                  generateSymptomsReport();
                  setDownloadSymptomsReport(downloadSymptomsReport + 1);
                }}
              >
                <Text className={styles.p2}>Symptoms Report</Text>
              </div>
            ),
          },
        ]}
      >
        <Button className={styles.dropdownReport} onClick={() => {}} loading={isLoading}>
          <Text className={styles.p1}>Download Members Report</Text>
          <Icon name="down-chevron-thick" className={styles.arrowIcon} />
        </Button>
      </Dropdown>
      {renderCsvLink && (
        <CSVLink
          data={newBasicMemberReportData}
          filename={"Basic Members Report.csv"}
          ref={csvLinkRef}
        />
      )}
    </>
  );
};

export default memo(DropdownMemberReports);
