const locale = {
  about: "Acerca de",
  aboutYou: "Sobre ti",
  aboutYouDesc:
    "Fecha de nacimiento, dirección residencial actual, género, tipo de sangre, estatura y peso",
  acceptInvite: "Aceptar invitación",
  accessory: "Accesorio",
  accessoryAlreadyPaired: "Este accesorio ya está emparejado con otro usuario.",
  accessoryDetails: "Detalles del accesorio",
  accessoryId: "Identificación de accesorios",
  accessoryIdAlreadyPaired: "Este ID de accesorio ya está emparejado con otro usuario.",
  accessoryIdDoesNotBelongToOrganization: "Este ID de accesorio no pertenece a esta comunidad.",
  accessoryIdIsntAvailable:
    "Este accesorio no está disponible. Por favor, prueba otra identificación.",
  accessoryOrMacAddress: "ID. de accesorio o dirección MAC",
  accessoryRemoved: "Se quita el dispositivo PouchBAND.",
  accessoryStatus: "Estado del Accesorio",
  accessoryType: "Tipo De Accesorio",
  account: "Cuenta",
  accountInformation: "Información de la Cuenta",
  accountSettings: "Configuración de la Cuenta",
  active: "Activo",
  activeAndSyncing: "Activo y Sincronizado",
  activeMembers: "Miembros Activos",
  activeOraganization: "Comunidades Activas",
  add: "Agregar",
  addAMember: "Añadir un miembro",
  addANewMember: "Añadir un nuevo miembro",
  addANewUser: "Añadir un nuevo usuario",
  addAccessory: "Añadir PouchBAND",
  addAndManagerPouchBands: "Administre los PouchBAND de este miembro.",
  addMember: "Añadir miembro",
  addMemberAndProfile:
    "Agregue un miembro y sus datos de perfil sin necesidad de cuenta POUCHPASS.",
  addMembers: "Agregar miembros",
  addNew: "Añadir nuevo",
  addNewMembers: "Añadir nuevos miembros",
  addPouchband: "Añadir PouchBAND",
  addUsers: "Agregar usuarios",
  addYourContactDetails: "¡Ya casi termina! ¡Añade tu contacto de emergencia por si acaso!",
  addYourPouchBand: "Agregue y administre sus PouchBANDs.",
  additionalInfo: "Información adicional",
  address: "Unidad/Número de lote, nombre de calle, subdivisión o pueblo, ciudad, provincia/estado",
  all: "Todo",
  allSet: "Todo listo",
  allTheInfoCollectedProfile:
    "Toda la información recopilada y/o añadida a este perfil sólo sería accesible por los gerentes de esta comunidad. La persona descrita en este perfil no tendría acceso a la comunidad y/o a toda la información recopilada en este perfil. Si desea que esta persona acceda y administre este perfil, tendría que ser invitado a PouchPASS para crear una cuenta. ",
  allowed: "Permitido",
  almostDoneEnterYourNewPassword: "Ya casi termino. Introduzca su nueva contraseña.",
  alreadyHaveAnAccount: "¿Ya tienes una cuenta?",
  alreadyInvitedAssignAsManager:
    "Ya está invitado. Asigne como {0} más tarde una vez que hayan aceptado su invitación.",
  alreadyInvitedPendingError:
    "Ya está invitado. Asigne como Gerente más tarde una vez que hayan aceptado su invitación.",
  and: "y",
  appStore: "Tienda App",
  applyFilters: "Aplicar filtros",
  areYouSure: "¿Estás seguro?",
  area: "Área",
  areaDescription: "Descripción del área",
  areaDuplicateName: "Esta área ya existe. Por favor, elija un nombre diferente.",
  areaLocation: "Ubicación / área",
  areaLocationDesc: "Ubicación cuando se encuentran en el área de la comunidad.",
  areaName: "Nombre del área",
  areas: "Áreas",
  asThePersonDescribed: "Como la persona descrita en este perfil,",
  askDeleteArea: "¿Eliminar área?",
  askDeleteMember: "¿Eliminar miembro?",
  askDeleteUser: "¿Eliminar usuario?",
  askRemoveAccessory: "¿Quitar PouchBAND?",
  askRemoveManager: "¿Quitar Administrador?",
  askRemoveMember: "¿Eliminar miembro?",
  askSaveChanges: "¿Guardar cambios?",
  askTheUserToWearPouchBand: "Pida al miembro que use su PouchBAND.",
  askYouToWearPouchBand: "¡Usa tu PouchBAND para empezar a monitorear tu temperatura!",
  assignAPouchBandToEachUser:
    "Asigne una PouchBAND a cada miembro para supervisar sus temperaturas corporales y ubicaciones.",
  assignAPouchBandToStartMonitorTheirTemp:
    "Asigne una PouchBAND para comenzar a monitorear su temperatura.",
  assignAsManager: "Asignar como gerente",
  authorization: "Autorización",
  back: "Atrás",
  backTo: "De vuelta a",
  backToHome: "De vuelta a Home",
  backToLogin: "Volver al inicio de sesión",
  basicInfo: "Información básica",
  birthdate: "Nacimiento",
  birthday: "Cumpleaños",
  bloodType: "Grupo sanguíneo",
  bodyAche: "Dolor de cuerpo",
  bodyTemperature: "Temperatura del cuerpo",
  bodyTemperatureDays: "Registros de temperatura corporal durante 14 días",
  browsingYourFiles: "Navegar por sus archivos",
  cancel: "Cancelar",
  celciusSymbol: "\\u00b0c",
  celsius: "Celsius",
  change: "Cambio",
  changeAccessory: "Cambiar accesorio",
  changePhoto: "Cambiar foto",
  changeYourEmail: "Cambiar su dirección de correo electrónico",
  changesSaved: "Cambios guardados.",
  checkCredentials:
    "Compruebe las credenciales que proporcionó. Si el error persiste, póngase en contacto con el soporte técnico.",
  checkEmploymentDetails: "Consulte estos detalles sobre su empresa o empleador.",
  checkWithYourAdminForDetails:
    " Consulte con su administrador para obtener más información o póngase en contacto con el soporte técnico de PouchPASS.",
  checkingInternet: "Comprobación de su internet o conectividad a datos.",
  chestPains: "Dolores en el pecho",
  chooseBloodType: "Elija Tipo de sangre",
  chooseGender: "Elegir género",
  chooseTypeTest: "Elija el tipo de prueba",
  chooseWhichInformationToShare: "Elija la información que desea compartir.",
  clear: "Claro",
  clearAllFilters: "Borrar todos los filtros",
  cm: "cm",
  company: "Nombre del empleo o empresa",
  companyPlaceholder: 'Por ejemplo, "Apple"',
  completeProfile: "Perfil completo",
  confirmDeleteMember:
    "¿Está seguro de que desea eliminar <strong>{0}</strong> de su comunidad? Toda su información será eliminada. Esta acción no se puede deshacer.",
  confirmNewPassword: "Confirmar nueva contraseña",
  confirmPassword: "Confirmar contraseña",
  confirmRemoveManager:
    "¿Está seguro de que desea eliminar <b>{0}</b> como gerente? Seguirán siendo miembros de su comunidad, pero perderán el acceso gerencial.",
  confirmRemoveMember:
    "¿Está seguro de que desea eliminar <b>{0}</b> de su comunidad? Ya no podrá acceder a su perfil ni a ninguna de sus informaciones.",
  confirmRevokeManagerInvite: "¿Está seguro de que desea revocar la invitación para <b>{0}</b>?",
  conjunctivitis: "Conjuntivitis",
  connectAPouchBandDesc:
    "Conecte un PouchBAND introduciendo el ID de accesorio que se encuentra en la parte posterior del dispositivo",
  contactAndPersonalInfo: "Contacto e Información Personal",
  contactHistory: "Historial de contactos",
  contactHistoryDesc:
    "Monitoree a las personas con las que {0} interactuado durante 15 minutos o más a una distancia cercana durante los últimos 30 días.",
  contactInformation: "Información de contacto",
  contactNumber: "Número de contacto",
  contactPersonsName: "Nombre completo de la persona de contacto",
  contactPouchPass: "Contacto PouchPASS",
  contactRelationship: "Relación con la persona de contacto",
  contactUs: "Contáctenos",
  cough: "Tos",
  countryCurrentlyResidingIn: "País que actualmente reside en",
  createAccount: "Crear cuenta",
  createAndManageUser: "Crear y administrar miembros",
  createArea: "Crear área",
  createMember: "Crear miembro",
  createPPaccount: "Crear una cuenta PouchPASS.",
  createUser: "Crear usuario",
  createWorryFree: "¡Cree un entorno sin inquietudes para su familia o negocio!",
  createYourFirstArea: "¡Crea tu primera área!",
  createYourFirstAreaDesc:
    "Agregue una puerta de enlace por área en su casa, oficina o cualquier ubicación para supervisar a los miembros de manera eficiente.",
  currentPassword: "Contraseña actual",
  currentResidentialAddress: "Dirección residencial actual",
  date: "Fecha",
  dateOfAvailabilityOfTestResults: "Fecha de disponibilidad de los resultados de las pruebas",
  dateOfContact: "Fecha de contacto",
  dateOfMedicalTest: "Fecha de la prueba médica",
  decline: "Rechazar",
  declineInvitation: "Rechazar Invitación",
  declineInvitationDesc:
    "¿Estás seguro de que quieres rechazar la invitación de {0}? Esta acción no se puede deshacer. No podrás unirte a esta comunidad sin una nueva invitación.",
  delete: "Borrar",
  deleteArea: "Eliminar área",
  deleteLog: "Eliminar registro",
  deleteMember: "Eliminar miembro",
  deleteSymptomsLog: "¿Eliminar registro de síntomas?",
  deleteUser: "Eliminar usuario",
  deletedMemeber: "Miembro eliminado",
  description: "Descripción",
  details: "Detalles",
  deviceStatus: "Estado del dispositivo",
  diarrhea: "Diarrea",
  diary: "Diario",
  didntGetACode: "¿No obtiene un código?",
  didntReceiveAnything: "¿No recibió nada?",
  difficultyBreathing: "Dificultad para respirar",
  discardChanges: "Descartar cambios",
  discardChangesMessage:
    "Si abandona esta página, se perderán todos los cambios no guardados. ¿Está seguro de que desea descartar cambios?",
  discardTitle: "Tiene cambios no guardados en esta página",
  dismiss: "Despedir",
  doThisLater: "Haz esto más tarde",
  doYouWantToCreateYourOwnOrg: "¿Quieres crear tu propia comunidad?",
  dontHavePouchBandYet:
    "¿Aún no tienes un PouchBAND? ¡Solicite uno a su administrador o {0} ahora!",
  duration: "Duración",
  easilyIdentifyYourProfile: "Identifique fácilmente su perfil cuando vea su imagen. ",
  edit: "Editar",
  editArea: "Editar área",
  editLogSymptoms: "Editar síntomas de registro",
  editManager: "Gestor de edición",
  editMember: "Editar miembro",
  editProfile: "Editar perfil",
  editSettings: "Editar configuración",
  editUser: "Editar usuario",
  egMedicalClinic: 'Eg. "clínica médica"',
  emailAddress: "Dirección de correo electrónico",
  emailAddressAlreadyTaken: "Este correo electrónico ya es miembro de su comunidad.",
  emailAddressAndMobileNumber: "Dirección de correo electrónico y número de teléfono móvil",
  emailAddresses: "Direcciones de correo electrónico",
  emailAlreadyAssociatedWithThisOrganization:
    "Este correo electrónico ya está asociado a una cuenta en esta comunidad.",
  emailAlreadyExists: "Este correo electrónico ya existe",
  emergencyContact: "Contacto de emergencia",
  emergencyContactDesc: "Persona de contacto, relación y número de contacto",
  emergencyContactPerson: "Persona de contacto de emergencia",
  employed: "Empleado",
  employeeDetails: "Detalles del empleo",
  employmentDetails: "Detalles del empleo",
  employmentDetailsDesc: "Estado, empleador/empresa, datos de contacto y dirección",
  employmentStatus: "Situación laboral",
  enterAnEmailAddress: "Introduzca una dirección de correo electrónico",
  enterNewPassword: "Introducir nueva contraseña",
  enterOtpVerifyYourEmailAddress:
    "Ingrese el OTP para verificar su dirección de correo electrónico.",
  enterPassword: "Introduzca la contraseña",
  everyoneIsDoingWell: "Todos están bien.",
  everyoneIsDoingWellDesc:
    "El nivel de riesgo de todos los miembros es de muy bajo o ningún riesgo. ¡Sigue así!",
  everyoneIsSafe: "¡Todos están sanos y salvos!",
  everyoneIsSafeDesc:
    "Todos los miembros tienen sus temperaturas corporales dentro del rango normal. ¡Sigue así y mantente a salvo!",
  export: "Exportar",
  extremelyHigh: "Extremadamente alta",
  fAQsPage: "Página de preguntas frecuentes",
  fahrenheit: "Fahrenheit",
  failedToValidateEmail: "No se pudo validar la dirección de correo electrónico.",
  fever: "Fiebre",
  feverCases: "Casos de fiebre",
  feverTrend: "Tendencia febril",
  feverTrendOvertime: "Tendencia febril con el tiempo",
  fillInEmailAddresses:
    "Rellene las direcciones de correo electrónico de las personas a las que desea unirse a su comunidad.",
  findOutHowCreateOrg: "Averigüe cómo",
  findOutHowNow: "Averigua cómo ahora.",
  findOutMore: "Para obtener más información!",
  fingerToesDiscoloration: "Decoloración de los dedos/Halluxs",
  finishSettingUpAccount: "Termina de configurar tu perfil con solo unos clics.",
  firstName: "Nombre",
  forFurtherQuestions: "Para más preguntas",
  forgotPassword: "Contraseña olvidada",
  fullName: "Nombre completo",
  gateway: "Entrada",
  gatewayId: "ID de puerta de enlace",
  gatewayIdOrMacAddress: "ID. De puerta de enlace o dirección MAC",
  gatewayMacAddress: "Dirección MAC de puerta de enlace",
  gatewayName: "Nombre de la puerta de enlace",
  gatewayNamePlaceholder: 'Por ejemplo, "Puerta 1 Gateway"',
  gender: "Género",
  geolocation: "Geolocalización",
  geolocationDesc: "Tu ubicación en Google Maps.",
  goodJobAllUserActive: "¡Buen trabajo! Todos los miembros están activos.",
  goodJobAllUserActiveDesc:
    "Todo el mundo tiene sus accesorios encendidos. Siga monitoreando sus temperaturas para mantener un ambiente seguro y saludable.",
  great: "¡Impresionante! Una contraseña segura significa una cuenta más segura",
  hasNotBeenInContact: "{0} no ha estado en contacto con nadie.",
  hasTheUserTakenAnyMedicalTest: "¿El miembro ha tomado alguna prueba médica recientemente?",
  headache: "Jaqueca",
  height: "Altura",
  heightInCm: "Altura en cm",
  hereWhatHappening: "Esto es lo que está pasando con",
  highRisk: "Alto riesgo",
  highRiskDesc:
    "Usuarios que tienen temperaturas crecientes desde {0} y superiores durante 3 o más días.",
  highTemperature: "Alta temperatura",
  higherThanNormal: "Más alto de lo normal",
  hypothermia: "Hipotermia",
  iAgreeToPouchTerms: "Estoy de acuerdo con la {0} y {1} de PouchPASS.",
  iAuthorizeOrg:
    "Autorizo <b>{0}</b> para poder ver, editar y supervisar mi perfil de PouchPASS. Puedo revocar esta autorización de mi configuración en una etapa posterior.",
  ifAny: "Si lo hubiera.",
  iformationIsImportantNarrative:
    "La información es importante y nos preocupamos por su privacidad. Elija la información que desea compartir con las comunidades a las que se une.",
  imageMustBeSmallerThan: "La imagen debe ser más pequeña que",
  in: "Dentro",
  inactive: "Inactivo",
  inactiveDesc: "Miembros que no han presentado temperaturas en las últimas 6 horas.",
  individualAtRiskDesc:
    "Usuarios que podrían estar en riesgo con temperaturas que aumentan activamente.",
  individualsAtRisk: "Individuo en riesgo",
  inputAccessoryId: "ID de accesorio de entrada",
  invalidCurrentPassword: "Contraseña actual no válida.",
  inviteExpiredDesc:
    "Por favor, compruebe su correo electrónico para nuevas invitaciones enviadas. Si no es así, póngase en contacto con el administrador de la comunidad.",
  inviteHasBeenSentTo: "La invitación ha sido enviada a {0}",
  inviteManager: "Invitar al Gerente",
  inviteManagerDescription: "Invite a otros a administrar esta comunidad en equipo.",
  inviteMembers: "Invitar a los miembros",
  inviteNewManager: "Invitan a nuevo gerente",
  invitePeopleToCreate:
    "Invite a las personas a crear su propia cuenta de PouchPASS y unirse a su comunidad.",
  invitePouchPassDesc:
    "¡Asigne este perfil a una cuenta de PouchPASS! Invite a una persona a unirse a PouchPASS y se le asignará este perfil y se le recopilará información una vez que cree una cuenta.",
  invitePouchPassNote:
    "Una vez aceptada la invitación, ya no podrá agregar, editar, eliminar ninguno de sus datos. Tendrían el control total de la información que eligen compartir con usted y otras comunidades.",
  inviteRequest: "Solicitud de invitación",
  inviteRequestDesc: "Las siguientes comunidades le están solicitando que se una a su comunidad.",
  inviteRequestDescManager:
    "Al aceptar la invitación, se unirá y se convertirá en gerente de {0}. Ellos podrían ver su perfil y toda la información que elija compartir con ellos. Podrá acceder a su panel y administrar a sus miembros.",
  inviteRequestDescMember:
    "Al aceptar la invitación, se unirá y se convertirá en miembro de {0}. Ellos podrían ver su perfil y toda la información que elija compartir con ellos.",
  inviteSent: "Invitación enviada",
  inviteSentDetails: "Invitan a enviar {0} por {1}",
  inviteSentMemberDesc: "El miembro ahora no está verificado hasta que se acepte la invitación.",
  inviteTeamMembersAndCreateArea: "Invite a los miembros y cree áreas.",
  inviteTeamMembersAndCreateAreaDescription:
    "Asigne a los miembros PouchBAND que registren su temperatura corporal en tiempo real. Realice un seguimiento de sus ubicaciones fácilmente con <b>Areas</b> o <b>Live Map</b>",
  inviteToPouchPass: "Invitan a PouchPASS",
  invitedMembersWouldHave:
    "Los miembros invitados tendrían que aceptar su invitación antes de poder acceder a sus datos.",
  invitesSent: "Invitaciones enviadas",
  invitingAMember: "Invitar a un miembro requeriría que",
  joinedCommunities: "Comunidades unidas",
  joinedOn: "Unidos en {0}",
  keepThisOrg:
    "Mantenga a estas comunidades seguras administrando a sus miembros y monitoreando sus temperaturas, síntomas e historial de contacto.",
  kg: "kg",
  lastDateOfContact: "Última fecha de contacto",
  lastName: "Apellido",
  lastSynced: "Último sincronizado",
  lastUpdated: "Última actualización",
  latestTemp: "Última temperatura",
  learnMore: "Aprende más.",
  leave: "Salir",
  leaveOTPMessage:
    "Aún no ha verificado su nueva dirección de correo electrónico. Salir de esta página no verificaría su cuenta.",
  leaveOrganization: "Salir de la comunidad",
  leavePage: "Salir de la página",
  leavePageDesc:
    "Tienes algunos cambios sin guardar. ¿Estás seguro de que quieres dejar esta página?",
  lessDetails: "Menos detalles",
  liveMap: "Mapa en vivo",
  location: "Ubicación",
  logIn: "Inicia sesión",
  logOtherSymptoms: "Registrar otros síntomas o notas",
  logSymptoms: "Síntomas del registro",
  logTheirSymptomsNow: "Registrar sus síntomas ahora.",
  login: "Iniciar sesión",
  loginNow: "Iniciar sesión ahora",
  loginTo: "Inicio de sesión a",
  logout: "Cerrar sesión",
  lossOfSmellOrTaste: "Pérdida de Gusto / Olfato",
  lossOfSpeechOrMovement: "Pérdida del habla / movimiento",
  low: "Bajo",
  lowRisk: "Bajo riesgo",
  lowRiskDesc:
    "Usuarios que estaban en riesgo alto a moderado, pero se están recuperando con temperaturas decrecientes de nuevo al rango normal.",
  lowTemperature: "Baja temperatura",
  manager: "Director",
  managerRequest: "Solicitud del gerente",
  me: "yo",
  medicalTest: "Prueba médica",
  member: "Miembro",
  memberDetails: "Detalles de los miembros",
  memberDetailsPendingMessage:
    "Todos los cambios realizados serían efectivos en {0} en {1}. Si tiene alguna inquietud, póngase en contacto con el soporte técnico de PouchPASS para obtener ayuda.",
  memberFromUntil: "Miembro desde {0} hasta {1}",
  memberLocationPrivate: "Las ubicaciones de este miembro son privadas.",
  memberProfileOnly: "Perfil de miembro solamente",
  memberProfileOnlyDesc:
    "Perfiles creados por los administradores. Estos miembros no tienen acceso a sus perfiles. Para acceder a sus perfiles, tendrían que ser invitados a PouchPASS para crear una cuenta.",
  memberRequest: "Solicitud de miembro",
  memberSince: "Miembro desde entonces",
  memberStatus: "Estatus de miembro",
  members: "Miembros",
  mobileNumber: "Número de móvil",
  moderateRisk: "Riesgo moderado",
  moderateRiskDesc:
    "Los usuarios que tienen temperaturas consistentes y crecientes entre {0} a {1} durante 3 o más días.",
  monitorAnySymptoms: "Supervise los casos, tendencias y síntomas de la fiebre.",
  monitorAnySymptomsDescription:
    "Vea rápidamente los casos de fiebre para tomar medidas inmediatas. Manténgase al tanto de la salud de todos al registrar cualquier síntoma</b> experimentado.",
  monitorYourBodyTemp: "Controla la temperatura corporal.",
  monitorYourBodyTempDesc:
    "Descarga la aplicación PouchPASS y usa siempre tu PouchBAND para registrar tu temperatura corporal en tiempo real.",
  myOrganizationDescription: "Gestione las comunidades que pueden recibir sus datos de PouchPASS.",
  myOrganizations: "Mis comunidades",
  name: "Nombre",
  nameAndProfilePhoto: "Foto de nombre y perfil",
  nameOfClinicOrHospital: "Nombre de clínica u hospital",
  nameOfOtherTest: 'Nombre de la prueba "Otros"',
  nameOfSpecificMedicalTest: "Nombre de la prueba médica específica",
  nameRemoved: '"{0}" eliminado.',
  negative: "Negativo",
  networkErrorDesc:
    "Es una conectividad de red deficiente o algo salió mal de nuestro lado. ¡Lo siento!",
  newPassword: "Nueva contraseña",
  newUserAdded: "Se ha añadido un nuevo miembro.",
  next: "Próximo",
  noAccessory: "Sin accesorio",
  noAccessoryYet: "Sin bolsa conectada",
  noContactDetected: "Sin contacto detectado",
  noData: "Sin datos.",
  noDevice: "Sin dispositivo",
  noLongerAManager: "{0} ya no es gerente.",
  noMemberYet: "Aún no hay miembros.",
  noMembersFound: "No se encontraron miembros.",
  noName: "Sin nombre",
  noNeedForApp:
    "*No es necesario usar aplicaciones, pero se requieren puertas de enlace para recibir datos de PouchBAND.",
  noRecordsYet: "Aún no hay registros",
  noResults: "Sin resultados",
  noSymptomsLogged: "Sin síntomas registrados",
  noUserFound: "No se han encontrado usuarios.",
  noUserYet: "Aún no hay usuarios.",
  normal: "Normal",
  notAllowed: "No permitido",
  notAtRisk: "No en riesgo",
  notRequired: "No es necesario",
  ohNoYouDontHaveAccess: "¡Oh no, no tienes acceso!",
  okay: "Bien",
  onceYouLeaveOrg:
    "Una vez que salga de una comunidad, será eliminado de su lista de miembros y ya no recibirán ninguna de su información de PouchPASS.",
  oopsIncorrectEmailOrPass: "¡Vaya! Dirección de correo electrónico o contraseña incorrectas.",
  oopsSomethinngWentWrong: "Vaya, algo salió mal.",
  optional: "Opcional",
  or: "o",
  orCheck: "o comprobar",
  orderOne: "Ordenar uno",
  orgYouManage: "Comunidades que administra",
  organizationSettings: "Configuración de la comunidad",
  otherLocation: "Otra ubicación",
  ourAvailablePlans: "Nuestros planes disponibles.",
  overview: "Visión general",
  pageNotFound: "¡Oh! Página no encontrada.",
  pageNotFoundDesc: "Vaya, podrías haber perdido el rumbo. Vamos a ponerte de nuevo en marcha.",
  paired: "Emparejado",
  pairingStatus: "Estado de emparejamiento",
  password: "Contraseña",
  passwordReset: "Restablecimiento de contraseña",
  passwordsDoNotMatch: "Las contraseñas no coinciden.",
  pastOrganizations: "Comunidades pasadas",
  pastOrganizationsDesc:
    "Estas comunidades ya no tienen acceso al perfil ni a ninguno de sus datos de PouchPASS.",
  past13Days: "Últimos 13 días",
  pendingInvites: "Invitaciones pendientes",
  pendingInvitesDesc:
    "Personas invitadas a unirse a una comunidad, pero no han confirmado su invitación",
  pendingMemberConfirmation: "Confirmación pendiente de los miembros",
  permanentResidence: "Dirección residencial actual",
  permissions: "Permisos",
  personalInformation: "Información personal",
  playStore: "Play store",
  pleaseCheckForTheOneTimePasswordWeSentTo:
    "Por favor, compruebe si la contraseña única (OTP) que hemos enviado a",
  pleaseCheckYourEmailAndClickMessage:
    "Por favor, compruebe su correo electrónico y haga clic en el enlace recibido para restablecer su contraseña.",
  pleaseContactOurSupport:
    "Póngase en contacto con nuestro equipo de soporte técnico de PouchPASS para obtener más información.",
  pleaseContactPouchPass:
    "Póngase en contacto con el equipo de soporte técnico de PouchPASS para obtener más información sobre sus datos si es necesario.",
  pleaseEnterAnEmailInFormat:
    "Introduzca una dirección de correo electrónico en el formato: yourname@example.com",
  pleaseEnterAtLeastOneEmail: "Introduzca al menos una dirección de correo electrónico.",
  pleaseFillUpArea: "Rellene al menos un (1) campo para crear un área.",
  pleaseFillUpSymptom: "Rellene al menos un (1) campo para registrar los síntomas.",
  pleaseFillUpUser: "Rellene al menos un (1) campo para crear un miembro.",
  pleaseInputCurrentPassword: "Introduzca su contraseña actual.",
  pleaseRequestThePersonProfile:
    "Por favor, solicite a la persona que se describa en este perfil que rellene personalmente este formulario con su información.",
  pleaseTryAgain: "Por favor, inténtelo de nuevo",
  pleaseUseAtleastNthCharacters: "Utilice al menos {0} caracteres.",
  poor: "Una contraseña segura necesita 8 o más caracteres",
  positive: "Positivo",
  pouchBand: "PouchBAND",
  pouchBandDescription: "Añadir, editar, eliminar cualquiera de sus accesorios PouchBAND",
  pouchBandForEveryone: "PouchPASS para la familia",
  pouchBandStep1:
    "Conecta tu PouchBAND introduciendo el ID de accesorio que se encuentra en la parte posterior del dispositivo.",
  pouchBandStep2: "Descargar la aplicación PouchPASS en el {0} o {1} para estar siempre conectado!",
  pouchBands: "PouchBANDs",
  pouchnationPhilippines: "PouchNATION Filipinas",
  privacyPolicy: "Política de privacidad",
  privacySymptomsWarning: "Los síntomas de {0} son privados.",
  private: "Privado",
  privateInformation: "Información privada",
  privateOnlyMe: "Privado (sólo yo)",
  profile: "Perfil",
  profileAvatarSaved: "Avatar de perfil guardado.",
  profileOnly: "Solo perfil",
  profileSettings: "Configuración del perfil",
  rashes: "Erupciones",
  recordAndMonitorYourSymptoms:
    "Registre y supervise los síntomas y/o los resultados de las pruebas médicas.",
  recovingWithANormalTemp:
    "Recuperándose con una temperatura normal. Aún así, es mejor comprobar y",
  refreshPage: "Refrescando esta página.",
  registrationStart: "¡Completa el siguiente formulario para crear una cuenta!",
  relationshipPlaceholder: 'Por ejemplo, "Madre, Marido. Guardián"',
  rememberMe: "Recuérdame",
  rememberYourPassword: "Recuerda tu contraseña",
  rememberYourPasswordQuestion: "¿Recuerdas tu contraseña?",
  remove: "Eliminar",
  removeAccessory: "Quitar PouchBAND",
  removeAccessoryDesc:
    "¿Estás seguro de que quieres quitar esta Bolsa? Ya no podrá recibir ninguna información de temperatura de este dispositivo.",
  removeAsManager: "Eliminar como gerente",
  removeFromOrganization: "Retirar de la comunidad ",
  removeManagerSuccess: "{0} ya no es gerente.",
  removeMember: "Eliminar miembro",
  removed: "retirado",
  removedMember: "Miembro eliminado.",
  replacePhoto: "Reemplazar foto",
  required: "Obligatorio",
  resendANewOtp: "Reenviar una nueva fiscalía",
  resendInvite: "Invitación de reenvío",
  resendInviteSuccess: 'Se ha enviado un nuevo enlace de invitación a "{0}".',
  resendNow: "Reenviar ahora",
  resetLinkResent: "Restablecer el enlace reenviado.",
  resetLinkSent: "Restablecer enlace enviado",
  resetPassword: "Restablecer contraseña",
  resetTokenExpired:
    "Su solicitud de restablecimiento de contraseña ha caducado, inicie el proceso de nuevo.",
  retry: "Reintentar",
  retypePassword: "Reescribir contraseña",
  reviewTheInformation: "Revise la información que comparte",
  revoke: "Revocar",
  revokeInvite: "Revocar invitación",
  revokeInviteSuccess: "Invitación revocada para {0}.",
  riskLevel: "Nivel de riesgo",
  role: "Rol",
  roleAndPermissions: "Rol y permisos",
  sampleAreaDescription: 'Por ejemplo, "Edificio Diego Silang del Ala Este del Segundo Piso"',
  sampleAreaName: 'Por ejemplo, "Habitación 2306"',
  save: "Salvar",
  saveAndSendNotif: "Guardar y enviar notificación",
  saveChanges: "Guardar cambios",
  savePermissions: "Guardar permisos",
  searchByUserOrLocation: "Buscar por nombre o ubicación",
  searchMemberByNameOrAccessoryId: "Miembro de búsqueda por nombre o ID.",
  searchUserByNameOrAccessoryId: "Buscar usuario por nombre o ID de accesorio",
  seconds: "segundos",
  sections: "Secciones",
  seeMore: "Ver más",
  selectARole: "Seleccione un rol",
  selectBloodType: "Seleccionar tipo de sangre",
  selectCountry: "Seleccionar país",
  selectEmploymentStatus: "Seleccionar estado de empleo",
  selectFollowingSymptoms:
    "Seleccione los siguientes síntomas que ha estado experimentando el miembro:",
  selectGender: "Seleccionar género",
  selectUserTestResult: "Seleccione el resultado de la prueba del miembro",
  selfEmployed: "Autónomo",
  sendInvite: "Enviar invitación",
  sendResetLink: "Enviar enlace de restablecimiento",
  settings: "Configuración",
  setupProfileLater: "Configurar el perfil más adelante",
  sharedInformation: "Información compartida",
  shopNow: "Comprar ahora",
  shopPouchBand:
    "Compra bolsas para ti, tu familia y tus amigos. Pista <b>varios PouchBAND</b> en la aplicación PouchPASS.",
  signIn: "Inicio de sesión",
  signingUpAggreement: "Al registrarse, usted acepta el {0} y {1} de PouchPASS.",
  someEmailsNotRecognized:
    "Algunos correos electrónicos no se reconocen. Compruebe si hay errores e inténtelo de nuevo.",
  somethingWentWrong: "Algo salió mal.",
  soreThroat: "Dolor de garganta",
  sorryYouAreNotAuthToAccessThisPage: "Lo sentimos, no está autorizado a acceder a esta página.",
  startMonitorTeam: "Para empezar a monitorear a su equipo.",
  startTour: "Inicio del Tour",
  startYourPouchBand: "¡Prepara tu PouchBAND para empezar!",
  startbyUploading: "¡Comienza subiendo una foto de perfil!",
  statistics: "Estadística",
  stayOnThisPage: "Manténgase en esta página",
  stayOnTopOfYourHealthAndGetNotified: "Manténgase al tanto de su salud y reciba una notificación.",
  stayOnTopOfYourHealthAndGetNotifiedDesc:
    "Recibe notificaciones cuando las cosas se calientan y registra los síntomas experimentados para que puedas tomar precauciones y evitar enfermarte.",
  stepNth: "Paso {0}",
  successChangedPassword:
    "¡Tu contraseña ha sido cambiada con éxito! Ahora puede usar su nueva contraseña para iniciar sesión en su cuenta.",
  symptomLoggedToDiary: "Los síntomas se registraron en el diario.",
  symptomNotFound: "Síntoma no encontrado.",
  symptoms: "Síntomas",
  symptomsDescription:
    "Registre y supervise los síntomas del miembro y/o los resultados de las pruebas médicas.",
  takeNoteOfTheMedicalTestResultHere: "Tome nota del resultado de la prueba médica aquí",
  teamAndRoles: "Equipo y roles",
  teamManagers: "Gerentes de equipo",
  tellUsMoreAboutYourself:
    "Cuéntanos un poco más sobre ti completando tu perfil sobre tus datos, estado laboral y contacto de emergencia.",
  temp: "Temp.",
  tempCases: "Casos temporales",
  tempHasBeenIncreasingForThePastDays:
    "La temperatura ha estado aumentando durante los últimos 3 días o más. Comprobar y",
  temperature: "Temperatura",
  temperatureCases: "Casos de temperatura",
  temperatureStatus: "Estado de temperatura",
  temperatureTrend: "Tendencia a la temperatura",
  termsAndConditions: "Términos y condiciones",
  testResultSummaryInfo: "{0} prueba realizada el {1} en {2}.",
  testResults: "Resultados de las pruebas",
  testedNegativeWithCovid: "Dio negativo con Covid-19.",
  testedPositiveWithCovid: "Dio positivo con Covid-19.",
  theOTPYouEnteredIsInvalid: "El OTP que usted ingresó es inválido. Introduzca el código correcto.",
  theyllBeAdded: " Se agregarán a tu comunidad una vez que acepten tu invitación.",
  thisEmailIsAlreadyAMemberOfYourOrg: "Este correo electrónico ya es miembro de su comunidad.",
  thisMember: "Este miembro",
  thisUserDoesNotExistOn: "Este miembro no existe en",
  time: "Hora",
  tiredness: "Cansancio",
  to: "para",
  toLearnAboutPouchPass:
    "Para obtener más información sobre cómo PouchPASS utiliza su información, consulte nuestro {0}.",
  today: "Hoy",
  totalMembers: "Total de Miembros",
  trackEncounter: "Realice un seguimiento de cada encuentro con historial de contactos.",
  trackEncounterDescription:
    "Mantenga a su comunidad segura y ralentice la propagación de infecciones al monitorear las interacciones de contacto cercano de cada miembro.</b>",
  try: "Probar",
  trySearchingAgain: "Intenta buscar de nuevo.",
  tryToSearchAgain: "O intente buscar de nuevo.",
  typeOfMedicalTest: "Tipo de prueba médica",
  uhOhEmailNotRegistered: "Ese correo electrónico no está registrado.",
  unableToLocate: "No se puede localizar.",
  unableToLocateMember: "No se puede localizar miembro.",
  unableToLocateUser: "No se puede localizar al usuario.",
  unableToRemoveAccessory: "No se puede quitar el accesorio.",
  unableToRetrieveData: "No se pueden recuperar datos",
  unableToRetrieveDataDesc:
    "No eres tú.. Somos nosotros. Por favor, vuelva a comprobarlo en unos minutos.",
  unauthorized: "Desautorizado",
  unbaleToUploadMoreThanMb: "No se puede subir una foto de más de 4mb.",
  unemployed: "Desempleado",
  unfortunatelyThisInviteIsExpired: "Desafortunadamente, esta invitación ha caducado.",
  unknowUserContactInformation: "Usuario desconocido {0} es parte de una comunidad diferente.",
  unknowUserUserId: "Usuario desconocido {0}",
  unverifiedMember: "Miembro no verificado",
  unverifiedMembersDesc:
    "Perfiles creados anteriormente que ahora están invitados a unirse a PouchPASS.",
  uploadAProfile: "Subir un perfil",
  uploadCovidTestCerti: "JPG, PNG o PDF. Tamaño máximo de 1MB",
  uploadFile: "Cargar archivo",
  uploadPhoto: "Subir foto",
  uploadPhotoDescription: "Cargue un archivo JPEG o PNG por {0}. Tamaño máximo de archivo de 4 mb.",
  uploadProfile: "Perfil de carga",
  uploadTestCertificate: "Cargar certificado de prueba",
  usePouchPassForYourFamilyOrBusiness: "Utilice PouchPASS para su familia o negocio",
  usePouchPassForYourFamilyOrBusinessDesc:
    "Monitoree a sus seres queridos con PouchPASS. Vea rápidamente {0} para una acción inmediata y rastrearlos con {1}. {2}",
  userHasPendingInvite: "El usuario tiene pendiente la invitación.",
  userIsNowAManager: "{0} ahora es gerente.",
  users: "Usuarios",
  usingAMixOfUppercaseAndLowercase:
    "El uso de una mezcla de letras mayúsculas y minúsculas garantiza más seguridad",
  validateEmailAddress: "Validar dirección de correo electrónico",
  verifiedMember: "Miembro verificado",
  verifiedMembersDesc:
    "Personas invitadas que tienen su propia cuenta PouchPASS. Pueden personalizar la información compartida con las comunidades.",
  verifyEmail: "Verificar nuevo correo electrónico",
  verifyEmailAddress: "Verificar la dirección de correo electrónico",
  veryHigh: "Muy alto",
  veryLowRisk: "Muy bajo riesgo",
  veryLowRiskDesc: "Usuarios que tienen temperatura corporal normal pero no durante más de 2 días.",
  view: "Vista",
  viewAll: "Ver todo",
  viewAllAreas: "Ver todas las áreas",
  viewAndAddSymptoms: "Ver y agregar síntomas y resultados de las pruebas.",
  viewInvite: "Ver invitación",
  viewMyActiveOrg: "Vea mis comunidades activas.",
  viewMyOrgs: "Ver mis comunidades",
  viewProfile: "Ver perfil",
  viewTestResults: "Ver resultados de las pruebas",
  viewYourContactInformation: "Ver su dirección de correo electrónico y número de teléfono móvil",
  viewYourNameAndProfilePhoto: "Ver su nombre completo y foto de perfil",
  visitOur: "Visitar nuestro",
  wantToCreateOrg:
    "¿Quieres crear tu propia comunidad? PouchPASS ofrece múltiples planes y paquetes que se adapten a sus necesidades! Visitar",
  weShouldBack:
    "Estamos trabajando en ello y deberíamos volver más tarde. Si persiste el error <s>síntomas</s>, póngase en contacto con el soporte técnico de PouchPASS.",
  weak: "Prueba una combinación de letras, números y caracteres especiales",
  weight: "Peso",
  weightInKg: "Peso en KG",
  welcomeDescription:
    "Rastrear la temperatura corporal, síntomas, historial de contacto, y mucho más! ¡Haga un recorrido rápido por el tablero de PouchPASS!",
  welcomeTo: "Bienvenido a",
  welcomeToPouchPass: "Bienvenido a PouchPASS",
  wellEmbarassing: "Pozo... Esto es vergonzoso.",
  wellEmbarassingDesc: "Algo salió mal de nuestra parte. No somos tú, somos nosotros.",
  whoCanView: "¿Quién puede ver mi perfil?",
  withFever: "Con fiebre",
  workAddress: "Dirección de trabajo",
  workContactNumber: "Número de contacto de trabajo",
  worker: "Trabajador / Contractual o a tiempo parcial",
  wouldBeReceivingInfo: "Recibiría la siguiente información:",
  yesDecline: "Sí, declive",
  yesLeave: "Sí, vete",
  youAreNotAuthorized: "No está autorizado a acceder a esta página.",
  youAreNotInAnyOrg: "No estás en ninguna comunidad.",
  youAreNotInAnyOrgDesc:
    "Unirse a una comunidad es solo por invitación. Si usted es parte de un grupo que tiene una comunidad PouchPASS, por favor envíeles una solicitud para invitarlo.",
  youCanOnlyUploadJpgPngGifFile:
    "No se acepta ningún archivo. Por favor, cargue un archivo jpeg o png.",
  youDontHaveOrg: "No tienes comunidades que administrar",
  youMay: "Usted puede",
  youReInvitedTo: "Estás invitado a ser un {0} de",
  youReachMaximumOTPAttemps: "Ha alcanzado el máximo de intentos de OTP.",
  yourEmailAddress: "Su dirección de correo electrónico",
  yourPasswordHasBeenChanged: "Su contraseña ha sido cambiada",
};
export default Object.freeze(locale);
