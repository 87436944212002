import React, { memo } from "react";
import classnames from "classnames";
import styles from "./icon.module.scss";
import { Icon as AntIcon } from "antd";
import PropTypes from "prop-types";

const Icon = ({ name, className, onClick, type, loading, ...rest }) => {
  const icon = name && `ico-${name}`;
  if (loading) {
    return <AntIcon className={className} type="loading" />;
  }

  if (type) {
    return <AntIcon className={className} type={type} />;
  }

  return (
    <i
      className={classnames(className, icon, "icon", {
        [`${styles.clickable}`]: onClick,
      })}
      onClick={onClick}
      {...rest}
    ></i>
  );
};

Icon.propTypes = {
  alt: PropTypes.string,
  src: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  name: null,
};

export default memo(Icon);
