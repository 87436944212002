import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Routes } from "enums";
import { AreaListContainer } from "containers";
import { CreateAreaContainer, EditAreaContainer } from "containers";

const AreaContainer = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact component={CreateAreaContainer} path={`${path}/${Routes.CREATE}`}></Route>
      <Route
        exact
        component={EditAreaContainer}
        path={`${path}/${Routes.EDIT}/${Routes.AREA_ID}`}
      ></Route>
      <Route exact component={AreaListContainer} path={path}></Route>
      <Route path="*" render={() => <Redirect to={path} />} />
    </Switch>
  );
};

export default AreaContainer;
