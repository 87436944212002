import localStrings from "localization";

const emailValidation = (value, custom) => {
  if (custom && custom.includes(value)) {
    return {
      error: true,
      message: localStrings.emailAddressAlreadyTaken,
    };
  }
};

const deviceValidation = (value, custom) => {
  if (!custom || !custom.existing || !custom.notExisting) {
    return false;
  }
  const existing = custom.existing.includes(value);
  const notExisting = custom.notExisting.includes(value);
  const noOrganization = custom.noOrganization.includes(value);
  const message = noOrganization
    ? localStrings.accessoryIdDoesNotBelongToOrganization
    : existing
    ? localStrings.accessoryIdAlreadyPaired
    : localStrings.accessoryIdIsntAvailable;
  if (existing || notExisting || noOrganization) {
    return {
      error: true,
      message,
    };
  }
};

const initialFormState = {
  avatar: {
    value: "",
    required: false,
  },
  lastName: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 75,
  },
  firstName: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 75,
  },
  email: {
    value: "",
    required: false,
    error: {
      status: false,
      message: "",
    },
    email: true,
    maxLength: 75,
    customValidations: emailValidation,
    custom: [],
  },
  phoneCountryCode: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 75,
  },
  phone: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 12,
    number: true,
    phone: true,
  },
  address: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 255,
  },
  birthdate: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  gender: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  bloodType: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  height: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    number: true,
    max: 500,
    decimal: 2,
  },
  weight: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    number: true,
    max: 999,
    decimal: 2,
  },
  employmentStatus: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  company: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 75,
  },
  workCountryCode: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  workPhone: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 12,
    number: true,
    phone: true,
  },
  workAddress: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 255,
  },
  contactName: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 75,
  },
  contactRelationship: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 75,
  },
  contactCountryCode: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
  },
  contactNumber: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    maxLength: 12,
    number: true,
    phone: true,
  },
  deviceUid: {
    value: null,
    required: false,
    error: {
      status: false,
      message: "",
    },
    customValidations: deviceValidation,
    custom: {
      existing: [],
      notExisting: [],
      noOrganization: [],
    },
    maxLength: 14,
    min: {
      length: 12,
    },
  },
};

export default initialFormState;
