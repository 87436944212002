import React, { useEffect, useState } from "react";
import { useRouter, useApi } from "hooks";
import mixpanel from "mixpanel-browser";
import { logout } from "api/auth";
import styles from "./confirm-logout-modal.module.scss";
import { protectEmail } from "utils";
import { Mixpanel } from "enums";
import { Modal } from "atomic/atoms";
import { Button } from "atomic/molecules";

const ConfirmLogoutModal = ({
  visible,
  closeModal,
  email,
  isEmailExisting,
  redirectExistingEmail,
}) => {
  const { history } = useRouter();
  const [protectedEmail, setProtectedEmail] = useState("");

  useEffect(() => {
    if (visible && email) {
      setProtectedEmail(protectEmail(email));
    }
  }, [email, visible]);

  const { request: logOutRequest, loading } = useApi({
    api: () => logout(),
  });

  const handleLogout = async () => {
    try {
      await logOutRequest();
      mixpanel.track(Mixpanel.LOGOUT);
      if (isEmailExisting) {
        redirectExistingEmail();
      } else {
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleContinue = () => {
    history.push("/");
  };

  return (
    <Modal title=" " visible={visible} className={styles.modal} onCancel={handleContinue}>
      <h2 className={styles.title}>
        Is this you, {protectedEmail}? <br />
        Another account is trying to sign-in.
      </h2>
      <p className={styles.message}>
        We’ve detected a different account currently logged in to PouchPASS. Logout of this account
        and proceed to login or create your account.
      </p>
      <Button
        className={styles.create}
        text="Login or create a new account"
        onClick={handleLogout}
        loading={loading}
      />
      <Button
        tertiary
        className={styles.continue}
        text={`Continue as ${protectedEmail}`}
        onClick={handleContinue}
      />
    </Modal>
  );
};

export default ConfirmLogoutModal;
