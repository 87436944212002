import React, { useMemo, memo } from "react";
import { Map } from "atomic/organisms";
import classnames from "classnames";
import styles from "./map-location.module.scss";
import Skeleton from "react-loading-skeleton";
import UserPin from "images/user-pin.svg";
import { getInitials } from "utils";

const MapLocation = ({
  className,
  strings,
  location,
  loading,
  longitude,
  latitude,
  firstName,
  lastName,
}) => {
  const { lat = 1.898, lng = 1.898 } = {};
  const initCenter = useMemo(() => {
    if (!loading) {
      return {
        lat,
        lng,
      };
    }
  }, [lat, lng, loading]);

  const marker = useMemo(() => {
    const initial = getInitials([firstName, lastName]);

    if (!loading) {
      return {
        position: { lat: latitude, lng: longitude },
        icon: UserPin,
        label: { text: initial, color: "white" },
      };
    }
  }, [firstName, lastName, loading, latitude, longitude]);

  const render = (() => {
    if (loading) {
      return <Skeleton />;
    }
    if (!location) {
      return strings.unableToLocate;
    }
    return location || strings.unableToLocate;
  })();

  return (
    <div className={classnames(styles.container, className)}>
      <Map className={styles.map} marker={marker} initCenter={initCenter} />
      <div className={styles.lastLocation}>{render}</div>
    </div>
  );
};

export default memo(MapLocation);
