import React, { useCallback, useContext } from "react";
import AreaFormContainer from "../area-form/area-form.container";
import { OrganizationContext } from "contexts";
import { useApi } from "hooks";
import { createArea } from "api/area";
import localStrings from "localization";
import localTranslation from "localization/localization";

const CreateAreaContainer = () => {
  const { organizationId } = useContext(OrganizationContext);

  const { request, loading } = useApi({
    api: createArea,
    handleOwnError: {
      badrequest: true,
    },
  });

  const submitCb = useCallback(
    async (form, success, error) => {
      try {
        const response = await request({ ...form, organizationId });
        success(response, {
          message: localTranslation("areaCreated", [form.areaName]),
        });
      } catch (e) {
        error(e);
      }
    },
    [organizationId, request]
  );

  return (
    <AreaFormContainer
      onSubmit={submitCb}
      create
      strings={{
        submitText: localStrings.createArea,
        title: localStrings.createAnArea,
      }}
      submitting={loading}
    ></AreaFormContainer>
  );
};

export default CreateAreaContainer;
