import React from "react";
import { SettingsLayout } from "layouts";
import { useApis, useMount } from "hooks";
import { getOrganizations, getPastOrganizations } from "api/user-organization";
import localStrings from "localization";
import { MyOrganizationList } from "components/settings";
// import { NotificationContext } from "contexts";

const MyOrganizationsListContainer = ({ leaveModal }) => {
  const {
    request: getUserOrganizationRequest,
    loading,
    results: [activeOrganizations = [], pastOrganizations = []],
  } = useApis({ apis: [getOrganizations, getPastOrganizations] });

  // const notifications = useContext(NotificationContext);

  useMount(() => {
    const init = async () => {
      await getUserOrganizationRequest();
    };
    init();
  });

  // useEffect(() => {
  //   const refetchOrganizations = async () => {
  //     await getUserOrganizationRequest();
  //   };
  //   refetchOrganizations();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [notifications.hasOrganizationPrivacyUpdate]);

  return (
    <SettingsLayout title={localStrings.myOrganizations}>
      <MyOrganizationList
        loading={loading}
        activeOrganizations={activeOrganizations}
        pastOrganizations={pastOrganizations}
        leaveModal={leaveModal}
      />
    </SettingsLayout>
  );
};

export default MyOrganizationsListContainer;
