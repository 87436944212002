import React, { useMemo, useEffect, useState, useCallback } from "react";
import { getLocalItem, tellDuration, formatDate } from "utils";
import styles from "./contact-history-details-modal.module.scss";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import classnames from "classnames";
import { contactTracingIndividual } from "api/contact-tracing";
import { prepareDocDefinition, downloadPDF } from "services";
import { useApi } from "hooks";
import { Routes } from "enums";
import { Modal, Table, Icon, Pagination } from "atomic/atoms";
import { Avatar, Button } from "atomic/molecules";
import { FailedToLoad } from "atomic/organisms";

const columns = [
  {
    key: "dateOfContact",
    text: localStrings.dateOfContact,
  },
  {
    key: "duration",
    text: localStrings.duration,
  },
  {
    key: "location",
    text: localStrings.areaLocation,
  },
].map((c) => {
  return {
    key: uuidv4(),
    dataIndex: c.key,
    title: c.text,
  };
});
const pageSize = 10;

const ContactHistoryDetails = ({
  modal,
  setModal,
  next,
  prev,
  dates,
  showUnknowUser,
  user,
  userPrivacyStatus,
}) => {
  // const { state } = useContext(ViewUserContext);
  const { active: modalActive } = modal;
  const data = modal?.data || {};
  const { firstName, lastName, avatar, userId, name: userFullName } = user;

  const place = modal?.place;
  const totalUsers = modal?.total;
  const first = place === 1;
  const last = place === totalUsers;
  const [contactData, setContactData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    contactUserId,
    contactFirstName,
    contactLastName,
    organizationId: userOrganizationId,
    contactFullName,
  } = data;
  const otherOrg = !userOrganizationId;
  const paramsMemo = useMemo(() => {
    return {
      contactId: contactUserId,
      userId,
      organizationId: getLocalItem("organizationId"),
      page: currentPage,
      startDate: dates[0].format("MM/DD/YYYY"),
      endDate: dates[1].format("MM/DD/YYYY"),
      pageSize,
    };
  }, [contactUserId, dates, userId, currentPage]);
  const { request: contactTracingIndividualRequest, loading, error } = useApi({
    api: contactTracingIndividual,
    params: paramsMemo,
  });

  useEffect(() => {
    const init = async () => {
      const result = await contactTracingIndividualRequest();
      const {
        data,
        metadata: { total },
      } = result;
      setContactData(data);
      setTotal(total);
    };
    if (modalActive) {
      init();
    }
  }, [contactTracingIndividualRequest, modalActive]);

  const tableData = useMemo(() => {
    return contactData.map((t) => {
      const { durationInSeconds, areaName, contactStartDate } = t;
      return {
        key: uuidv4(),
        duration: tellDuration(durationInSeconds),
        location: userPrivacyStatus?.isAreaLocationAllowed ? areaName || "-" : "-",
        dateOfContact: moment(contactStartDate).format("MMMM DD, YYYY h:mm a"),
      };
    });
  }, [contactData, userPrivacyStatus]);

  const onChangePage = useCallback((currentPage) => {
    setCurrentPage(currentPage);
  }, []);

  const handleOnClickExport = useCallback(async () => {
    const { data } = await contactTracingIndividualRequest({
      pageSize: null,
    });
    const tableColumns = ["DATE OF CONTACT", "DURATION", "LOCATION/AREA"];
    const startDate = moment().subtract(30, "days");
    const endDate = moment();
    const tableColumsWidths = ["*", 80, "*"];
    const tableData = data.map((d) => {
      const { contactStartDate, durationInSeconds, areaName } = d;
      const dateOfContact = formatDate(contactStartDate, "MMM DD, YYYY hh:mm a");
      const duration = tellDuration(durationInSeconds);
      return [
        { text: dateOfContact, margin: [0, 16] },
        { text: duration, margin: [0, 16] },
        { text: areaName, margin: [0, 16] },
      ];
    });
    const def = prepareDocDefinition({
      title: `${userFullName} & ${contactFullName}`,
      tableColumns,
      tableColumsWidths,
      tableData,
      startDate,
      endDate,
    });
    downloadPDF(def);
  }, [contactTracingIndividualRequest, contactFullName, userFullName]);

  return (
    <Modal
      className={styles.modal}
      title={localStrings.contactHistory}
      visible={modal.active}
      onCancel={() => {
        setModal(() => ({
          active: false,
          data: null,
        }));
      }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          <Button
            tertiary
            className={styles.export}
            text={"Export"}
            icon="export"
            onClick={handleOnClickExport}
          />
          <div className={styles.users}>
            <div className={styles.usersContainer}>
              <div>
                <Avatar
                  className={styles.avatar}
                  image={userPrivacyStatus?.isNameAndPhotoAllowed ? avatar : null}
                  phrases={userPrivacyStatus?.isNameAndPhotoAllowed ? [firstName, lastName] : []}
                />
                <div className={styles.userName}>{userFullName}</div>
              </div>
            </div>
            <div className={styles.and}>{localStrings.and}</div>
            <div className={styles.usersContainer}>
              <div className={classnames(styles.left, first ? "" : styles.active)}>
                <Icon
                  name="left-chevron"
                  onClick={
                    !first
                      ? () => {
                          setCurrentPage(1);
                          prev();
                        }
                      : null
                  }
                />
              </div>
              <div>
                <Avatar
                  className={styles.avatar}
                  image={data.avatar}
                  phrases={[contactFirstName, contactLastName]}
                />
                <div
                  className={classnames(styles.userName, styles.contactUsers)}
                  onClick={() => {
                    if (otherOrg) {
                      showUnknowUser({
                        visible: true,
                        userId: contactUserId,
                      });
                      return false;
                    }
                    window.location.href = `/${Routes.MEMBERS}/${contactUserId}`;
                  }}
                >
                  {contactFullName || "-"}
                </div>
              </div>
              <div className={classnames(styles.right, last ? "" : styles.active)}>
                <Icon
                  name="right-chevron"
                  onClick={
                    !last
                      ? () => {
                          setCurrentPage(1);
                          next();
                        }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          {error ? (
            <FailedToLoad />
          ) : (
            <>
              <Table columns={columns} data={tableData} loading={loading} />
              <Pagination
                className={styles.pagination}
                total={total}
                defaultPageSize={pageSize}
                pageSize={pageSize}
                showSizeChanger={false}
                onChange={onChangePage}
                currentPage={currentPage}
              />
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ContactHistoryDetails;
