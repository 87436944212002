import { useState } from "react";

const useModal = ({ onCancel = () => {} }) => {
  const [active, setActive] = useState(false);
  const [state, setState] = useState(null);

  const show = (s = null) => {
    setActive(true);
    setState(s);
  };

  const close = () => {
    if (onCancel) {
      onCancel({
        close: () => {
          setActive(false);
        },
      });
    }
    setActive(false);
    setState(null);
  };

  return { show, close, visible: active, onCancel: () => close(), state };
};

export default useModal;
