import React from "react";
import styles from "./privacy-form.module.scss";
import InfoContainer from "./info-container";
import localStrings from "localization";

const PrivacyForm = ({ form, title, required, requirementKeys }) => {
  const basicInfoContents = basicInfos.map((bi) => {
    return (
      <InfoContainer
        key={bi.componentKey}
        {...bi}
        {...form}
        required={required}
        requirementKeys={requirementKeys}
      />
    );
  });

  const statisticInfoContents = statisticInfos.map((si) => {
    return (
      <InfoContainer
        key={si.componentKey}
        {...si}
        {...form}
        required={required}
        requirementKeys={requirementKeys}
      />
    );
  });

  const profileInfoContents = profileInfos.map((pi) => {
    return (
      <InfoContainer
        key={pi.componentKey}
        {...pi}
        {...form}
        required={required}
        requirementKeys={requirementKeys}
      />
    );
  });

  return (
    <div className={styles.sharedInformation}>
      {title && <div className={styles.header}>{title}</div>}

      <div>{basicInfoContents}</div>
      <div className={styles.subheaderContainer}>
        <div className={styles.subheader}>{localStrings.personalInformation}</div>
        {profileInfoContents}
      </div>
      <div className={styles.subheaderContainer}>
        <div className={styles.subheader}>{localStrings.statistics}</div>
        {statisticInfoContents}
      </div>
    </div>
  );
};

// METHODS

const basicInfos = [
  {
    componentKey: 0,
    title: localStrings.nameAndProfilePhoto,
    permission: "Required",
    description: localStrings.viewYourNameAndProfilePhoto,
    name: "isNameAndPhotoAllowed",
  },
  {
    componentKey: 1,
    title: localStrings.emailAddressAndMobileNumber,
    permission: "Required",
    description: localStrings.viewYourContactInformation,
    name: "isEmailAndMobileNumberAllowed",
  },
  {
    componentKey: 2,
    title: localStrings.pouchBand,
    description: localStrings.pouchBandDescription,
    permission: "Required",
    name: "isWristbandAllowed",
  },
];

const statisticInfos = [
  {
    componentKey: 3,
    title: localStrings.bodyTemperature,
    description: localStrings.bodyTemperatureDays,
    permission: "Required",
    name: "isBodyTemperatureAllowed",
  },
  {
    componentKey: 4,
    title: localStrings.areaLocation,
    description: localStrings.areaLocationDesc,
    permission: "Required",
    name: "isAreaLocationAllowed",
  },
  {
    componentKey: 5,
    title: localStrings.geolocation,
    description: localStrings.geolocationDesc,
    permission: "Allowed",
    name: "isLocationAllowed",
  },
  {
    componentKey: 6,
    title: localStrings.symptoms,
    description: localStrings.viewAndAddSymptoms,
    permission: "Allowed",
    name: "isSymptomAllowed",
  },
];

const profileInfos = [
  {
    componentKey: 7,
    title: localStrings.aboutYou,
    description: localStrings.aboutYouDesc,
    permission: "Allowed",
    name: "isAboutYouAllowed",
  },
  {
    componentKey: 8,
    title: localStrings.employeeDetails,
    description: localStrings.employmentDetailsDesc,
    permission: "Allowed",
    name: "isEmploymentDetailAllowed",
  },
  {
    componentKey: 9,
    title: localStrings.emergencyContact,
    description: localStrings.emergencyContactDesc,
    permission: "Allowed",
    name: "isEmergencyContactAllowed",
  },
];

export default PrivacyForm;
