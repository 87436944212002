import React from "react";
import styles from "./action-button.module.scss";
import classnames from "classnames";
import localString from "localization";
import { Button } from "atomic/molecules";

const ActionButton = ({
  className,
  onSubmit,
  onCancel,
  submitText = localString.save,
  cancelText = localString.cancel,
  type = "primary",
  loading,
  disabled,
  submitClass,
  cancelClass,
  isSubmitDanger,
  danger,
}) => {
  return (
    <div className={classnames("action-button", styles.container, className)}>
      <Button className={cancelClass} onClick={onCancel} tertiary text={cancelText} />
      <Button
        className={submitClass}
        onClick={onSubmit}
        type={type}
        loading={loading}
        text={submitText}
        disabled={disabled || loading}
        danger={isSubmitDanger}
      />
    </div>
  );
};

export default ActionButton;
