import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { Routes } from "enums";
import { UserContext, OrganizationContext } from "contexts";

const DashboardRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(UserContext);
  const organization = useContext(OrganizationContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.userId > 0 && organization !== undefined) {
          if (!user.isOnboarded) {
            return <Redirect to={`/${Routes.ONBOARDING}`} />;
          }
          // profile only handling
          if (organization === null) {
            return <Component {...props} />;
          }
          if (!organization.isPrivacySet) {
            return <Redirect to={`/${Routes.ONBOARDING}/${Routes.PRIVACY_SETTINGS}`} />;
          }
          // if (redirectQueryParam && !watcherId) {
          //   return <Redirect to={`/${redirectQueryParam}`} />;
          // }
          return <Component {...props} />;
        }
        return null;
      }}
    />
  );
};

export default DashboardRoute;
