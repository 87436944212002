import { useMemo } from "react";

/**
 * A hook that runs one time only before render
 */
const useBeforeMount = (callback) => {
  useMemo(callback, []);
};

export default useBeforeMount;
