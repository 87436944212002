import { Image } from "atomic/atoms";
import { Button } from "atomic/molecules";
import React from "react";
import styles from "./empty-question.module.scss";
import QuestionsImage from "images/questions.svg";

const EmptyQuestion = ({ toggleFormModal }) => {
  return (
    <div className={styles.emptyQuestion}>
      <Image className={styles.image} src={QuestionsImage}></Image>
      <div className={styles.text}>You don’t have any custom questions yet.</div>
      <Button
        onClick={() => {
          toggleFormModal({ visible: true });
        }}
        text={"Create Custom Question"}
      />
    </div>
  );
};

export default EmptyQuestion;
