import { Modal, Input } from "atomic/atoms";
import { Button } from "atomic/molecules";
import React from "react";
import localStrings from "localization";
import styles from "./invite-member-modal.module.scss";

const InviteMemberModal = ({
  visible,
  closeModal,
  submit,
  setFormFieldValue,
  formFields,
  loading,
  error,
  changeEmail,
}) => {
  return (
    <Modal
      visible={visible}
      title={localStrings.inviteToPouchPass}
      onCancel={closeModal}
      className={styles.modal}
    >
      <p className={styles.desc}>{localStrings.invitePouchPassDesc}</p>
      <Input
        {...formFields.email}
        name="email"
        className={styles.email}
        label={localStrings.emailAddress}
        placeholder={localStrings.enterAnEmailAddress}
        onKeyUp={(e) => {
          if (e.key === "Enter") {
            submit();
          }
        }}
        onBlur={() => {
          submit();
        }}
        // onFocus={handleOnFocus}
        onChange={changeEmail}
      />
      <p className={styles.error}>{error}</p>
      <p className={styles.note}>{localStrings.invitePouchPassNote}</p>
      <div className={styles.actions}>
        <Button tertiary text={localStrings.cancel} onClick={closeModal} />
        <Button text={localStrings.sendInvite} onClick={submit} loading={loading} />
      </div>
    </Modal>
  );
};

export default InviteMemberModal;
