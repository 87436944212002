import React, { useMemo } from "react";
import { Modal, Icon, Link } from "atomic/atoms";
import styles from "./remove-question-modal.module.scss";
import { v4 as uuid } from "uuid";
import path from "path/path";
import localStrings from "localization";
import { ActionButton } from "components/common";
import { pluralize } from "utils";

const RemoveQuestionModal = ({
  visible,
  loading,
  closeModal,
  removeQuestion,
  isLinked,
  linkedForms,
}) => {
  const content = useMemo(() => {
    const count = linkedForms.length;
    const includedNotice = (
      <div className={styles.includedMessage}>
        <Icon name="info-fill" />
        {localStrings.formatString(
          localStrings.thisQuestionIsIncluded,
          `${count}`,
          `${pluralize({ count, singular: localStrings.form, plural: localStrings.forms })}`
        )}
      </div>
    );
    return isLinked ? (
      <>
        {localStrings.askToRemoveQuestion} {localStrings.removeQuestionIncludedOnFormNotice}{" "}
        {localStrings.thisActionCannotBeUndone}
        {includedNotice}
      </>
    ) : (
      <>
        {localStrings.askToRemoveQuestion} {localStrings.thisActionCannotBeUndone}
      </>
    );
  }, [isLinked, linkedForms]);

  const includedInLinks = useMemo(
    () => (
      <div className={styles.includedInLinks}>
        {linkedForms.map(({ formName, organizationFormId }) => (
          <Link key={uuid()} href={path.SETTINGS_FORM_EDIT(organizationFormId)} target="_blank">
            {formName} <Icon name="new-tab" />
          </Link>
        ))}
      </div>
    ),
    [linkedForms]
  );

  return (
    <Modal
      className={styles.modal}
      title={localStrings.askRemoveQuestion}
      visible={visible}
      onCancel={closeModal}
      actions={
        <ActionButton
          className={styles.actionButton}
          cancelClass={styles.cancel}
          submitClass={styles.remove}
          onCancel={closeModal}
          submitText={isLinked ? localStrings.removeQuestionAnyways : localStrings.removeQuestion}
          isSubmitDanger
          onSubmit={removeQuestion}
          loading={loading}
        />
      }
    >
      {content}
      {includedInLinks}
    </Modal>
  );
};

export default RemoveQuestionModal;
