import React, { useMemo, useState, useEffect } from "react";
import { InputField } from "atomic/molecules";
import PropTypes from "prop-types";

const InputPassword = ({
  shouldShowToggle,
  onShowToggleChange = () => null,
  showPassword,
  ...props
}) => {
  const [isVisiblePassword, setIsVisiblePassword] = useState(showPassword);
  const passwordSuffix = useMemo(() => {
    return {
      name: isVisiblePassword ? "eye-open" : "eye-closed",
      className: "passwordSuffix",
      onClick: () => {
        setIsVisiblePassword(!isVisiblePassword);
        onShowToggleChange(!isVisiblePassword);
      },
    };
  }, [isVisiblePassword, onShowToggleChange]);

  useEffect(() => {
    setIsVisiblePassword(showPassword);
  }, [showPassword]);

  return (
    <InputField
      type={isVisiblePassword ? "text" : "password"}
      suffix={shouldShowToggle && passwordSuffix}
      {...props}
    />
  );
};

InputPassword.propTypes = {
  shouldShowToggle: PropTypes.bool,
  onShowToggleChange: PropTypes.func,
};

export default InputPassword;
