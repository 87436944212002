import React, { useMemo, useState, useRef } from "react";
import styles from "./diary.module.scss";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import { Temp, Icon, Modal } from "atomic/atoms";
import { Details } from "atomic/molecules";
import * as moment from "moment";
// import classnames from "classnames";
// import Skeleton from "react-loading-skeleton";
// import { formatTemperature, isNormalTemp } from "utils";
import DiaryBreakdown from "./diary-breakdown";
import { useModal, useApi } from "hooks";
import { getTodayHourly } from "api/user-data";

const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "date",
    title: localStrings.date,
    width: 25,
  },
  {
    key: uuidv4(),
    dataIndex: "temperature",
    title: localStrings.temperature,
    width: 20,
  },
  {
    key: uuidv4(),
    dataIndex: "location",
    title: localStrings.areaLocation,
    width: 100,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "icon",
    title: "",
    width: 10,
  },
];

const DiaryPastDays = ({
  loading,
  result,
  error,
  userId,
  organizationId,
  userPrivacyStatus,
  userPrivacyLoading,
}) => {
  const [active, setActive] = useState({});
  const { day } = active;
  const currentRowRef = useRef(null);
  const {
    request: getTodayHourlyReq,
    result: getTodayHourlyRes = [],
    loading: getTodayHourlyLoading = true,
    error: getTodayHourlyErr,
  } = useApi({
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
    api: getTodayHourly,
  });

  const diaryBreakdownModal = useModal({});
  console.log(result);
  const onRowClick = (row) => {
    const d = result[row];
    currentRowRef.current = row;
    console.log(d);
    setActive(d);
    diaryBreakdownModal.show();
  };

  const tableData = useMemo(() => {
    if (!result) {
      return [];
    }
    return result.map((d) => {
      const { bodyTemp, day, location } = d;
      return {
        key: uuidv4(),
        date: day ? moment(day, "MM/DD/YYYY").format("MMM DD, YYYY") : "-",
        temperature: <Temp temp={bodyTemp} />,
        location: <div className={styles.locationColumn}>{location}</div>,
        icon: <Icon name="right-chevron-medium" className={styles.icon} />,
      };
    });
  }, [result]);

  const renderTitle = useMemo(() => {
    const resultCount = result.length;
    const handleSetActive = (currentRow) => {
      setActive((prevActive) => ({
        ...prevActive,
        ...result[currentRow],
      }));
    };

    const handlePrevRow = () => {
      if (currentRowRef.current > 0) {
        currentRowRef.current = currentRowRef.current - 1;
        handleSetActive(currentRowRef.current);
      }
    };

    const handleNextRow = () => {
      if (currentRowRef.current < resultCount - 1) {
        currentRowRef.current = currentRowRef.current + 1;
        handleSetActive(currentRowRef.current);
      }
    };

    return (
      <div className={styles.titleNavContainer}>
        <Icon className={styles.nav} name="left-chevron-medium" onClick={handlePrevRow} />
        <span className={styles.currentData}>
          {moment(day, "MM/DD/YYYY").format("MMMM DD, YYYY")}
        </span>
        <Icon className={styles.nav} name="right-chevron-medium" onClick={handleNextRow} />
      </div>
    );
  }, [day, result]);

  return !loading && !error && !result?.length ? null : (
    <>
      <Modal className={styles.diaryPastDaysModal} {...diaryBreakdownModal} title={renderTitle}>
        {diaryBreakdownModal.visible ? (
          <DiaryBreakdown
            organizationId={organizationId}
            userId={userId}
            data={active}
            userPrivacyStatus={userPrivacyStatus}
            userPrivacyLoading={userPrivacyLoading}
            result={getTodayHourlyRes}
            request={getTodayHourlyReq}
            loading={getTodayHourlyLoading}
            error={getTodayHourlyErr}
          ></DiaryBreakdown>
        ) : null}
      </Modal>
      <Details
        className={styles.table}
        title={localStrings.past13Days}
        columns={tableColumns}
        loading={loading}
        data={tableData}
        onRowClick={onRowClick}
        scroll={{
          y: 520,
        }}
        error={error}
      />
    </>
  );
};

export default DiaryPastDays;
