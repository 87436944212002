import React from "react";
import { Modal } from "atomic/atoms";
import { Button } from "atomic/molecules";
import localStrings from "localization";
import styles from "./invite-request.module.scss";
import classnames from "classnames";

const DeclineInviteModal = ({ visible, organizationName = "", organizationId, close, decline }) => {
  return (
    <Modal
      className={styles.declineInviteModal}
      title={`${localStrings.declineInvitation}`}
      visible={visible}
      onCancel={close}
    >
      <p
        className={styles.message}
        dangerouslySetInnerHTML={{
          __html: localStrings.formatString(
            localStrings.declineInvitationDesc,
            `<b>${organizationName}</b>`
          ),
        }}
      ></p>
      <div className={styles.actions}>
        <Button
          tertiary
          text={localStrings.cancel}
          className={classnames(styles.action, styles.cancel)}
          onClick={close}
        />
        <Button
          danger
          text={localStrings.yesDecline}
          className={styles.action}
          onClick={() => decline(organizationId)}
        />
      </div>
    </Modal>
  );
};

export default DeclineInviteModal;
