import React from "react";
import styles from "./label-item.module.scss";
import classnames from "classnames";
import { Loading } from "atomic/atoms";

const LabelItem = ({ label, children, classname, loading, text }) => {
  return (
    <div className={classnames("label-item", styles.labelItem, styles.container, classname)}>
      <label className={styles.label}>{label}</label>
      {loading ? <Loading /> : children || text}
    </div>
  );
};

export default LabelItem;
