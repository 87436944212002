import React, { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { Switch } from "react-router-dom";
import { Routes } from "enums";
import DashboardContainer from "./dashboard/dashboard.container";
import OnboardingContainer from "./onboarding/onboarding.container";
import { useMount, useApis, useApi } from "hooks";
import { getUser } from "api/user";
import { getUserOrg } from "api/user-organization";
import {
  UserContext,
  OrganizationContext,
  UserOrganizationsContext,
  AppContext,
  RoleContext,
} from "contexts";
import { userState } from "../states/user.state";
import OnboardingRoute from "routes/onboarding.route";
import DashboardRoute from "routes/dashboard.route";
import RedirectRoute from "routes/redirect.route";
import { setLocalItem } from "utils";
import { getCurrentOrganization, getUserRole } from "services";
import mixpanel from "mixpanel-browser";

const AuthorizedContainer = () => {
  const { request: userRequest } = useApi({ api: getUser });
  const { request: userOrganizationsRequest } = useApi({ api: getUserOrg });

  const { request, loading } = useApis({ apis: [getUser, getUserOrg] });

  const [user, setUser] = useState(userState);
  const [userOrganizations, setUserOrganizations] = useState(undefined);

  const { dispatch } = useContext(AppContext);

  useEffect(() => {
    dispatch({ type: "TOGGLE_LOADING", loading });
  }, [loading, dispatch]);

  useMount(() => {
    const init = async () => {
      const [user, organizations] = await request();
      setUser(user);
      setUserOrganizations(organizations);

      mixpanel.identify(user.userId);
      mixpanel.people.set({
        $email: user.email,
        $avatar: user.avatar,
        Birthdate: user.dob,
        $first_name: user.firstName,
        $last_name: user.lastName,
        $name: `${user.firstName} ${user.lastName}`,
        Gender: user.gender,
        $phone: `${user.phoneCountryCode} ${user.phone}`,
      });

      // const currentOrg = getCurrentOrganization(userOrganizations);
      // if (currentOrg.organizationId > 0) {
      //   setLocalItem("organizationId", currentOrg.organizationId);
      // }
      // setOrganization(currentOrg);
    };

    init();
  });

  const updateUser = useCallback((user) => {
    setUser(user);
  }, []);

  const refetchUser = useCallback(async () => {
    setUser(await userRequest());
  }, [userRequest]);

  const refetchUserOrganizations = useCallback(async () => {
    setUserOrganizations(await userOrganizationsRequest());
  }, [userOrganizationsRequest]);

  const organization = useMemo(() => {
    if (userOrganizations) {
      if (userOrganizations.length > 0) {
        const currentOrg = getCurrentOrganization(userOrganizations);

        // if (!currentOrg) {
        //   setLocalItem("organizationId", getFallbackOrganization(userOrganizations).organizationId);
        //   redirectTo(`${path.UNAUTHORIZED}?page=profile`);
        //   return;
        // }
        if (currentOrg?.organizationId > 0) {
          setLocalItem("organizationId", currentOrg.organizationId);
        }
        return currentOrg;
      }
      return null; // profile only
    }
    return undefined;
  }, [userOrganizations]);

  const role = useMemo(() => {
    return getUserRole(organization);
  }, [organization]);

  return (
    <UserContext.Provider value={{ user, updateUser, refetchUser }}>
      <UserOrganizationsContext.Provider value={{ userOrganizations, refetchUserOrganizations }}>
        <OrganizationContext.Provider value={organization}>
          <RoleContext.Provider value={role}>
            <Switch>
              <RedirectRoute path={`/${Routes.REDIRECT}`} />
              <OnboardingRoute component={OnboardingContainer} path={`/${Routes.ONBOARDING}`} />
              <DashboardRoute component={DashboardContainer} path="/" />
            </Switch>
          </RoleContext.Provider>
        </OrganizationContext.Provider>
      </UserOrganizationsContext.Provider>
    </UserContext.Provider>
  );
};

export default AuthorizedContainer;
