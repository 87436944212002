import React from "react";
import { AuthLayout } from "layouts";
import { RegisterUserForm } from "components/users";
const RegisterUser = () => {
  return (
    <AuthLayout>
      <RegisterUserForm />
    </AuthLayout>
  );
};

export default RegisterUser;
