import { Post, Get, Delete } from "./api";
import { ApiRoutes, Routes } from "enums";

export const getAreas = async (params) => {
  const res = await Post(`${ApiRoutes.AREA_V2}/search`, params);
  return res;
};

export const getArea = async (params) => {
  const res = await Get(`${ApiRoutes.AREA}/${params.areaId}`, params);
  return res.data;
};

export const createArea = async (params) => {
  const res = await Post(`${ApiRoutes.AREA}`, params);
  return res.data;
};

export const editArea = async ({ areaId, params }) => {
  const res = await Post(`${ApiRoutes.AREA}/${areaId}`, params);
  return res.data;
};

export const deleteArea = async ({ areaId }) => {
  const res = await Delete(`${ApiRoutes.AREA}/${areaId}`);
  return res.data;
};

export const getAreaDetails = async ({ organizationId }) => {
  const res = await Post(`${ApiRoutes.AREA}/${Routes.SEARCH}`, { organizationId });
  const { data } = res;
  return {
    data,
    total: data.length,
  };
};

export const getAreaDetailsV2 = async ({ organizationId }) => {
  const res = await Post(`${ApiRoutes.AREA_V2}/${Routes.SEARCH}`, { organizationId });
  const { data } = res;
  return {
    data,
    total: data.length,
  };
};

export const getAreaFilters = async ({ organizationId }) => {
  const res = await Get(`${ApiRoutes.AREA}/${Routes.ORGANIZATION}/${organizationId}`);
  return res.data;
};

export const getAreaLevels = async ({ organizationId }) => {
  const res = await Post(`${ApiRoutes.AREA_GROUP_LEVEL}/${Routes.SEARCH}`, {
    organizationId,
  });
  return res.data;
};

export const activateAreaDeviceUid = async (params) => {
  const res = await Get(`${ApiRoutes.AREA}/gateway/activate`, params);
  return res.data;
};
