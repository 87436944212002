import React, { useMemo, useEffect } from "react";
import styles from "./diary.module.scss";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import { Temp } from "atomic/atoms";
import { Details, TemperatureAverage } from "atomic/molecules";
import * as moment from "moment";
import Skeleton from "react-loading-skeleton";
import useLocations from "services/location.service";
import momentTimezone from "moment-timezone";

const DiaryBreakdown = ({
  userPrivacyStatus,
  userPrivacyLoading,
  request,
  loading,
  error,
  result,
  userId,
  data,
  organizationId,
}) => {
  const dateFormat = "MM/DD/YYYY";
  const { day } = data;
  const { locations } = useLocations({
    data: result,
    initialize: !loading && !userPrivacyLoading && userPrivacyStatus.isLocationAllowed,
  });

  useEffect(() => {
    const init = async () => {
      await request({
        userId,
        timezone: momentTimezone.tz.guess(),
        date: moment(day, dateFormat).format(dateFormat),
        organizationId,
      });
    };
    init();
    // eslint-disable-next-line
  }, [day, userId, organizationId]);

  const tableColumns = [
    {
      key: uuidv4(),
      dataIndex: "time",
      title: localStrings.time,
      width: 40,
    },
    {
      key: uuidv4(),
      dataIndex: "temperature",
      title: localStrings.temperature,
      width: 40,
    },
    {
      key: uuidv4(),
      dataIndex: "location",
      title: localStrings.areaLocation,
      width: 100,
      ellipsis: true,
    },
  ];

  const tableData = useMemo(() => {
    if (!result) {
      return [];
    }
    return result.map((d) => {
      const { hour, bodyTemp, latitude, longitude, location: areaLocation, areaId } = d || {};
      const location = (() => {
        const l = [latitude, longitude].join(",");
        const hasLocation = Boolean(latitude && longitude);
        if (userPrivacyLoading) {
          return <Skeleton />;
        }
        if (!userPrivacyStatus.isLocationAllowed) {
          return "-";
        }
        if (areaId) {
          return areaLocation || "-";
        }
        if (!locations[l] || locations[l].loading) {
          if (hasLocation) {
            return <Skeleton />;
          } else {
            return "-";
          }
        } else {
          return locations[l].location;
        }
      })();
      return {
        key: uuidv4(),
        // time: hour ? moment(`${hour}Z`, "hhAZ").format("hh A") : "-",
        time: hour ? moment(hour, "hhA").format("hh A") : "-",
        temperature: <Temp temp={bodyTemp} />,
        location: <div className={styles.locationColumn}>{location}</div>,
      };
    });
  }, [result, locations, userPrivacyStatus.isLocationAllowed, userPrivacyLoading]);

  return !loading && !result?.length && !error ? null : (
    <>
      <TemperatureAverage className={styles.diaryBreakdownTempAvg} data={result} />
      <Details
        className={styles.diaryBreakdown}
        classNameTable={styles.diaryBreakdownTable}
        columns={tableColumns}
        loading={loading}
        data={tableData}
        scroll={{
          y: 520,
        }}
        error={error}
      />
    </>
  );
};

export default DiaryBreakdown;
