import React from "react";
import styles from "./temp.module.scss";
import classnames from "classnames";
import { getTemperatureStatus } from "services";
import localStrings from "localization";

const TempStatus = ({
  temp = null,
  className = {
    container: "",
    normal: "",
  },
}) => {
  const tempStatus = getTemperatureStatus(temp);
  const status = {
    LOW: localStrings.low,
    NORMAL: localStrings.normal,
    [`HIGHER THAN NORMAL`]: localStrings.higherThanNormal,
    HIGH: localStrings.high,
    [`VERY HIGH`]: localStrings.veryHigh,
    [`EXTREMELY HIGH`]: localStrings.extremelyHigh,
  }[tempStatus];
  if (!temp || isNaN(temp)) {
    return "--";
  }
  return (
    <div
      className={classnames("temp", styles.tempStatusContainer, className.container, {
        [`${styles.danger}`]:
          ["HIGH", "HIGHER THAN NORMAL", "VERY HIGH", "EXTREMELY HIGH"].includes(tempStatus) &&
          temp,
      })}
    >
      {status?.toString().toLowerCase()}
    </div>
  );
};

export default TempStatus;
