import React, { memo } from "react";
import { Modal } from "atomic/atoms";
import { Button } from "atomic/molecules";
import styles from "./discard-modal.module.scss";
import localStrings from "localization";

const DiscardModal = ({ discardChanges = () => null, show, setDiscardModal }) => {
  const onCancel = () => {
    setDiscardModal(false);
  };

  const discard = () => {
    setDiscardModal(false);
    discardChanges();
  };

  return (
    <Modal title={localStrings.discardTitle} visible={show} onCancel={onCancel}>
      {localStrings.discardChangesMessage}
      <div className={styles.submit}>
        <Button text={localStrings.cancel} tertiary onClick={onCancel} />
        <Button text={localStrings.discardChanges} onClick={discard} />
      </div>
    </Modal>
  );
};

export default memo(DiscardModal);
