import React, { useMemo } from "react";
import styles from "./area-list.module.scss";
import localStrings from "localization";
import EmptyArea from "../empty-area/empty-area";
import { Button } from "atomic/molecules";
import AreaCard from "../area-card/area-card";
import { v4 as uuidv4 } from "uuid";
import LevelTabs from "../level-tabs/level-tabs";
import { useRouter } from "hooks";
import path from "path/path";
import { PaginatedTable } from "atomic/organisms";

const AreaList = ({
  areas,
  loading,
  changeLevel,
  areaLevels,
  currentLevel,
  getMenuOptions,
  page,
  pageSize,
  total,
  changePage,
  changePageSize,
}) => {
  const { history } = useRouter();
  const content = useMemo(() => {
    if (loading) {
      return [
        <AreaCard key={uuidv4()} loading={true} />,
        <AreaCard key={uuidv4()} loading={true} />,
        <AreaCard key={uuidv4()} loading={true} />,
      ];
    }
    if (areas.length > 0) {
      return areas.map((area) => {
        return <AreaCard getMenuOptions={getMenuOptions} key={uuidv4()} area={area} />;
      });
    }
    return <EmptyArea />;
  }, [areas, loading, getMenuOptions]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{localStrings.areas}</h2>
        {areas.length > 0 && (
          <Button
            className={styles.createButton}
            onClick={() => history.push(path.AREAS_CREATE)}
            text={localStrings.createArea}
          />
        )}
      </div>
      <div>
        {areas.length > 0 && (
          <LevelTabs
            areaLevels={areaLevels}
            changeLevel={changeLevel}
            currentLevel={currentLevel}
          />
        )}
      </div>
      <PaginatedTable
        page={page}
        pageSize={pageSize}
        total={total}
        changePage={changePage}
        changePageSize={changePageSize}
        defaultPageSize={10}
      >
        {content}
      </PaginatedTable>
    </div>
  );
};

export default AreaList;
