import React, { memo, useState } from "react";
import { Modal } from "atomic/atoms";
import { Button } from "atomic/molecules";
import styles from "./discard-modal.module.scss";
import localStrings from "localization";

const DiscardModal = ({ discardChanges, show, setDiscardModal }) => {
  const onCancel = () => {
    if (setDiscardModal) {
      setDiscardModal(false);
    }
  };

  const discard = () => {
    if (setDiscardModal) {
      setDiscardModal(false);
    }
    discardChanges();
  };

  return (
    <Modal title={localStrings.discardTitle} visible={show} onCancel={onCancel}>
      {localStrings.discardChangesMessage}
      <div className={styles.submit}>
        <Button text={localStrings.cancel} tertiary onClick={onCancel} />
        <Button text={localStrings.discardChanges} onClick={discard} />
      </div>
    </Modal>
  );
};

export const useDiscardModal = ({ discardChanges }) => {
  const [active, setActive] = useState(false);
  const show = () => {
    setActive(true);
  };
  const close = () => {
    setActive(false);
    discardChanges();
  };
  const render = <DiscardModal show={active} discardChanges={discardChanges} />;

  return {
    render,
    show,
    close,
  };
};

export default memo(DiscardModal);
