import React from "react";
import styles from "./unauthorized.module.scss";
import localStrings from "localization";
import Unauth from "images/unauthorized.svg";
import { Image } from "atomic/atoms";
import { Button } from "atomic/molecules";

const UnauthorizedError = ({ redirectLink = "/" }) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Image src={Unauth} className={styles.image} />
        <div className={styles.message}>
          <h2 className={styles.title}>{localStrings.ohNoYouDontHaveAccess}</h2>
          <p className={styles.description}>
            {localStrings.sorryYouAreNotAuthToAccessThisPage}
            <span className={styles.checkWithAdmin}>
              {localStrings.checkWithYourAdminForDetails}
            </span>
          </p>
          <Button
            className={styles.action}
            text={`${localStrings.backTo} pouchpass.com`}
            onClick={() => {
              window.location.replace(redirectLink);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UnauthorizedError;
