import React, { useState, useCallback, Fragment, useEffect } from "react";
import styles from "./otp-form.module.scss";
import localStrings from "localization";
import { InputOTP } from "atomic/atoms";
import { Button } from "atomic/molecules";

const optFieldsLength = 6;

const OTPForm = ({
  onChangeOTP = () => null,
  isFormValid,
  submit,
  isSubmit,
  incorrectOTP,
  isMaxRetryError,
  isClearField,
}) => {
  const [activeInput, setActiveInput] = useState(0);
  const [otpValues, setOTPValues] = useState(Array(optFieldsLength).fill(""));

  const focusInput = useCallback((inputIndex) => {
    const selectedIndex = Math.max(Math.min(optFieldsLength - 1, inputIndex), 0);
    setActiveInput(selectedIndex);
  }, []);

  const focusNextInput = useCallback(() => {
    focusInput(activeInput + 1);
  }, [activeInput, focusInput]);

  const focusPrevInput = useCallback(() => {
    focusInput(activeInput - 1);
  }, [activeInput, focusInput]);

  const getRightValue = useCallback((str) => {
    let changedValue = str;
    return !changedValue || /\d/.test(changedValue) ? changedValue : "";
  }, []);

  const handleOtpChange = useCallback(
    (otp) => {
      const otpValue = otp.join("");
      onChangeOTP(otpValue);
    },
    [onChangeOTP]
  );

  // Change OTP value at focussing input
  const changeCodeAtFocus = useCallback(
    (str) => {
      const updatedOTPValues = [...otpValues];
      updatedOTPValues[activeInput] = str[0] || "";
      setOTPValues(updatedOTPValues);
      handleOtpChange(updatedOTPValues);
    },
    [activeInput, handleOtpChange, otpValues]
  );

  const handleOnChange = (e) => {
    const val = getRightValue(e.currentTarget.value);
    console.log(val);
    if (!val) {
      e.preventDefault();
      return;
    }
    changeCodeAtFocus(val);
    focusNextInput();
  };

  const handleOnFocus = useCallback(
    (index) => {
      focusInput(index);
    },
    [focusInput]
  );

  const onBlur = useCallback(() => {
    setActiveInput(-1);
  }, []);

  const handleOnKeyDown = useCallback(
    (e) => {
      switch (e.key) {
        case "Backspace":
        case "Delete": {
          e.preventDefault();
          if (otpValues[activeInput]) {
            changeCodeAtFocus("");
          } else {
            focusPrevInput();
          }
          break;
        }
        case "ArrowLeft": {
          e.preventDefault();
          focusPrevInput();
          break;
        }
        case "ArrowRight": {
          e.preventDefault();
          focusNextInput();
          break;
        }
        case " ": {
          e.preventDefault();
          break;
        }
        default:
          break;
      }
    },
    [activeInput, changeCodeAtFocus, focusNextInput, focusPrevInput, otpValues]
  );

  const handleOnPaste = useCallback(
    (e) => {
      e.preventDefault();
      const pastedData = e.clipboardData
        .getData("text/plain")
        .trim()
        .slice(0, optFieldsLength - activeInput)
        .split("");
      if (pastedData) {
        let nextFocusIndex = 0;
        const updatedOTPValues = [...otpValues];
        updatedOTPValues.forEach((val, index) => {
          if (index >= activeInput) {
            const changedValue = getRightValue(pastedData.shift() || val);
            if (changedValue) {
              updatedOTPValues[index] = changedValue;
              nextFocusIndex = index;
            }
          }
        });
        setOTPValues(updatedOTPValues);
        handleOtpChange(updatedOTPValues);
        setActiveInput(Math.min(nextFocusIndex + 1, optFieldsLength - 1));
      }
    },
    [activeInput, getRightValue, otpValues, handleOtpChange]
  );

  useEffect(() => {
    if (isClearField) {
      setOTPValues(Array(optFieldsLength).fill(""));
    }
  }, [isClearField]);

  return (
    <Fragment>
      <div className={styles.otpCode}>
        {Array(optFieldsLength)
          .fill("")
          .map((fieldId, index) => {
            const fieldName = `optField${index + 1}`;
            return (
              <InputOTP
                key={`OTPField-${index}`}
                focus={activeInput === index}
                autoFocus
                value={otpValues && otpValues[index]}
                name={fieldName}
                onChange={handleOnChange}
                onFocus={() => handleOnFocus(index)}
                onKeyDown={handleOnKeyDown}
                onPaste={handleOnPaste}
                onBlur={onBlur}
                placeholder="0"
                error={isFormValid && incorrectOTP}
                className={styles.otpInput}
              />
            );
          })}
      </div>
      {isFormValid && incorrectOTP && (
        <p className={styles.messageError}>{localStrings.theOTPYouEnteredIsInvalid}</p>
      )}
      <Button
        onClick={submit}
        disabled={!isFormValid || isSubmit || isMaxRetryError}
        className={styles.submit}
        text={localStrings.validateEmailAddress}
      />
    </Fragment>
  );
};

export default OTPForm;
