import React from "react";
import styles from "./not-found.module.scss";
import NotFound from "images/404.svg";
import localStrings from "localization";
import { Image } from "atomic/atoms";
import { Button } from "atomic/molecules";
import classnames from "classnames";
import { useRouter } from "hooks";

const NotFoundError = ({ className }) => {
  const { history } = useRouter();
  return (
    <div className={classnames(styles.container, className)}>
      <Image className={styles.image} src={NotFound} />
      <h2 className={styles.title}>{localStrings.pageNotFound}</h2>
      <p className={styles.desc}>{localStrings.pageNotFoundDesc}</p>
      <Button
        className={styles.action}
        text={localStrings.backToHome}
        onClick={() => history.replace("/")}
      />
    </div>
  );
};

export default NotFoundError;
