import React from "react";
import SearchField from "atomic/molecules/search-field/search-field";
import localStrings from "localization";
import styles from "./pouchband-registry-search.module.scss";
// import { formatNumberWithCommas } from "services/number.service";

const PouchBandRegistrySearch = ({ loading, search, searchKey, setSearchKey, total = 0 }) => {
  return (
    <div className={styles.container}>
      <h4>
        {localStrings.totalListedAccessory}
        <span>{total.toLocaleString()}</span>
      </h4>
      <SearchField
        button
        loading={loading}
        onChange={(value) => {
          setSearchKey(value.trim().replace(/[^a-z0-9\n]/gi, ""));
        }}
        searchKey={searchKey}
        placeholder={localStrings.searchPouchBandPlaceholder}
        onSearch={(value) => {
          search(value);
        }}
        maxLength={12}
      />
    </div>
  );
};

export default PouchBandRegistrySearch;
