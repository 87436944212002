import React, { useState, useMemo, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { getAreaDetailsV2 } from "api/area";
import { useMount } from "hooks";
import classnames from "classnames";
import { OrganizationContext } from "contexts";
import useApi from "hooks/useApi";
import styles from "../overview.module.scss";
import localStrings from "localization";
import CreateFirstArea from "images/create-first-area-2.svg";
import path from "path/path";
import { Link } from "atomic/atoms";
import { Details } from "atomic/molecules";

const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "name",
    title: localStrings.name,
    width: 150,
  },
  {
    key: uuidv4(),
    dataIndex: "active",
    title: localStrings.active,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "feverCase",
    title: localStrings.temperatureCases,
    width: 100,
  },
];

const noData = {
  className: styles.noDataContainer,
  title: localStrings.createYourFirstArea,
  description: (
    <>
      {localStrings.createYourFirstAreaDesc}
      <Link
        href={path.BUSINESS_GATEWAYS}
        target="_blank"
        className={styles.learnMore}
        text={localStrings.learnMore}
      />
      {/* <Button
          onClick={() => history.push(`/${Routes.AREAS}/${Routes.CREATE}`)}
          text={localStrings.createArea}
        /> */}
    </>
  ),
  image: CreateFirstArea,
};

const AreaDetailsContainer = () => {
  const [hasError, setHasError] = useState(false);
  const { organizationId } = useContext(OrganizationContext);

  const {
    request,
    loading,
    result = { data: [], total: 0 },
  } = useApi({
    api: getAreaDetailsV2,
  });

  useMount(() => {
    const init = async () => {
      try {
        try {
          await request({ organizationId });
        } catch (error) {
          setHasError(true);
        }
      } catch (error) {
        setHasError(true);
      }
    };
    init();
  });

  const preparedData = useMemo(() => {
    const { data } = result;
    return data
      .map((d) => {
        const {
          areaGroupName,
          totalUsersWithFever: feverCase,
          totalActiveUsers: active,
          areas,
        } = d;
        const gatewayUids = [];
        for (const area of areas) {
          gatewayUids.push(area.gatewayUid);
        }
        return {
          key: uuidv4(),
          name: areaGroupName || gatewayUids.join(", "),
          feverCase: <span className={classnames({ cDanger: feverCase > 0 })}>{feverCase}</span>,
          active,
        };
      })
      .slice(0, 5);
  }, [result]);

  return (
    <Details
      className={styles.detail}
      title={localStrings.area}
      columns={tableColumns}
      loading={loading}
      data={preparedData}
      total={result.total}
      error={hasError}
      noData={noData}
      removeCount
      viewAllCount={result.total}
      viewAllText={localStrings.viewAllAreas}
      viewAllLink={path.AREAS}
    />
  );
};

export default AreaDetailsContainer;
