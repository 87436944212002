import React, { useMemo } from "react";
import classnames from "classnames";
import { useRouter } from "hooks";
import styles from "./organization-card.module.scss";
import localStrings from "localization";
import { redirectTo } from "utils";
import path from "path/path";
import { Icon, Link, Tooltip, Loading } from "atomic/atoms";
import { Avatar } from "atomic/molecules";

const OrganizationCard = ({ organization, loading }) => {
  const { history } = useRouter();
  return (
    <div className={styles.orgCard}>
      {loading ? (
        <Loading isSkeleton height="100" width="100%" />
      ) : (
        <OrgCardContent organization={organization} history={history} />
      )}
    </div>
  );
};

const OrgCardContent = ({ organization, history }) => {
  const {
    organizationName,
    organizationId,
    organizationLogo,
    totalMembers,
    totalActiveMembers,
    totalFeverCases,
    isCurrent,
    isNew,
  } = organization;

  const cardStatus = useMemo(() => {
    const cardStatusList = [];

    if (isNew) {
      cardStatusList.push(
        <div
          key={1}
          className={classnames(styles.orgStatus, {
            [`${styles.orgStatusNew}`]: true,
          })}
        >
          New
        </div>
      );
    }
    if (isCurrent) {
      cardStatusList.push(
        <div
          key={2}
          className={classnames(styles.orgStatus, {
            [`${styles.orgStatusCurrent}`]: true,
          })}
        >
          Current
        </div>
      );
    }
    return cardStatusList;
  }, [isCurrent, isNew]);

  return [
    <div key={1} className={styles.orgCardContent}>
      <div className={styles.avatarContainer}>
        <Avatar className={styles.userAvatar} image={organizationLogo} />
      </div>
      <div>
        <div className={styles.orgNameContainer}>
          <div className={styles.orgName}>{organizationName}</div>
          {cardStatus}
        </div>
        <div className={styles.orgCounts}>
          <Tooltip title={localStrings.totalMembers}>
            <div className={styles.orgCountContainer}>
              <Icon className={styles.orgCountIcons} name="member" />
              <div className={styles.orgCount}>{totalMembers}</div>
            </div>
          </Tooltip>
          <Tooltip title={localStrings.activeMembers}>
            <div className={styles.orgCountContainer}>
              <Icon className={styles.orgCountIcons} name="lightning" />
              <div className={styles.orgCount}>{totalActiveMembers}</div>
            </div>
          </Tooltip>
          <Tooltip title={localStrings.feverCases}>
            <div className={styles.orgCountContainer}>
              <Icon className={styles.orgCountIcons} name="thermometer-plus" />
              <div className={styles.orgCount}>{totalFeverCases}</div>
            </div>
          </Tooltip>
        </div>
        <div className={styles.manageOrgContainer}>
          <Link
            className={styles.manageOrg}
            text="Manage Community"
            onClick={() => {
              localStorage.setItem("organizationId", organizationId);
              redirectTo("/");
            }}
          />
          <Icon className={styles.rightArrow} name="right-arrow" />
        </div>
      </div>
    </div>,
    <div key={2}>
      <Icon
        className={styles.orgCardMeatBall}
        name="meat-balls"
        onClick={() => {
          history.push(path.SETTINGS_MY_ORGANIZATION);
        }}
      />
    </div>,
  ];
};

export default OrganizationCard;
