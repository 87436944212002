import { editArea, getArea } from "api/area";
import AreaFormContainer from "../area-form/area-form.container";
import { DeleteAreaModalContainer } from "containers";
import { OrganizationContext } from "contexts";
import { useApi, useMount, useRouter, useModal } from "hooks";
import React, { useCallback, useContext, useMemo } from "react";
import localStrings from "localization";
import { LayoutB } from "layouts";
import { Button } from "atomic/atoms";
import styles from "./edit-area.module.scss";
import path from "path/path";

const EditAreaContainer = () => {
  const { organizationId } = useContext(OrganizationContext);
  const deleteAreaModal = useModal({});

  const { query, history } = useRouter();
  const { areaId } = query || {};

  const { request: getAreaRequest, loading, result: data = {} } = useApi({ api: getArea });
  const { request: editAreaRequest, loading: submitting } = useApi({
    api: editArea,
    handleOwnError: {
      badrequest: true,
    },
  });

  useMount(() => {
    const init = async () => {
      if (organizationId) {
        await getAreaRequest({ organizationId, areaId });
      }
    };

    init();
  }, [query, organizationId]);

  const onSubmit = useCallback(
    async (form, success, error) => {
      try {
        const res = await editAreaRequest({
          areaId,
          params: { ...form, organizationId },
        });
        success(res, {
          message: localStrings.changesSaved,
        });
      } catch (e) {
        error(e);
      }
    },
    [editAreaRequest, organizationId, areaId]
  );

  const initialState = useMemo(() => {
    const { areaName, areaDescription, level, gatewayUid, gatewayName } = data;
    return {
      areaName,
      areaDescription,
      areaLevel: level,
      gateways: gatewayUid
        ? [
            {
              macAddress: gatewayUid,
              label: gatewayName || "",
            },
          ]
        : [],
    };
  }, [data]);

  if (loading) {
    return <LayoutB title={localStrings.editArea} loading />;
  }

  return (
    <>
      <DeleteAreaModalContainer
        {...deleteAreaModal}
        areaId={areaId}
        area={{ areaName: data.areaName }}
        fetchAreas={() => {
          history.push(path.AREAS);
        }}
      />
      <AreaFormContainer
        areaId={areaId}
        onSubmit={onSubmit}
        strings={{
          submitText: localStrings.saveChanges,
          title: localStrings.editArea,
        }}
        submitting={loading}
        loading={submitting}
        initialState={initialState}
        deleteArea={
          <div className={styles.deleteArea}>
            <Button
              danger
              onClick={() => {
                deleteAreaModal.show();
              }}
            >
              {localStrings.deleteArea}
            </Button>
          </div>
        }
      ></AreaFormContainer>
    </>
  );
};

export default EditAreaContainer;
