import React, { useMemo } from "react";
import styles from "./level-tabs.module.scss";
import classnames from "classnames";
import { Fragment } from "react";
import { v4 as uuidv4 } from "uuid";

const LevelTabs = ({ areaLevels, changeLevel, currentLevel }) => {
  const areaLevelsContent = useMemo(() => {
    return areaLevels.map((al) => {
      return (
        <div
          key={uuidv4()}
          className={classnames(styles.level, {
            [`${styles.active}`]: currentLevel === al,
            [`${styles.inactive}`]: currentLevel !== al,
          })}
          onClick={() => changeLevel(al)}
        >
          Level {al}
        </div>
      );
    });
  }, [areaLevels, changeLevel, currentLevel]);

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.level, {
          [`${styles.active}`]: currentLevel === null,
          [`${styles.inactive}`]: currentLevel !== null,
        })}
        onClick={() => changeLevel(null)}
      >
        All Levels
      </div>
      <Fragment>{areaLevelsContent}</Fragment>
    </div>
  );
};

export default LevelTabs;
