import React, { useEffect } from "react";
import styles from "./medical-test-form.module.scss";
import localStrings from "localization";
import { Common, MedicalTest } from "enums";
import { SelectField, DatePickerField, InputField } from "atomic/molecules";

const typeOfMedicalTest = [
  {
    text: "RT-PCR Test",
    value: MedicalTest.RT_PCR,
  },
  {
    text: "Antibody Test",
    value: MedicalTest.ANTIBODY,
  },
  {
    text: "Others",
    value: Common.OTHER,
  },
];

const userResults = [
  {
    text: localStrings.positive,
    value: Common.POSITIVE,
  },
  {
    text: localStrings.negative,
    value: Common.NEGATIVE,
  },
];

const MedicalTestForm = ({
  formFields,
  setFormFieldsRequired,
  setFormFieldValue,
  setFormFieldValues,
  clearFormFieldsError,
}) => {
  const {
    medicalTestType,
    testTakenDate,
    testAddress,
    testResultDate,
    testResult,
    otherTestName,
  } = formFields;

  const medTestType = medicalTestType.value;

  useEffect(() => {
    clearFormFieldsError([
      "medicalTestType",
      "testTakenDate",
      "testAddress",
      "testResultDate",
      "testResult",
      "otherTestName",
    ]);
  }, [clearFormFieldsError]);

  const handleWhenResultGiven = (value, name) => {
    setFormFieldValue(value, name);
    let requiredFields = {
      testResult: false,
    };
    if (value) {
      requiredFields = {
        ...requiredFields,
        testResult: true,
      };
    }
    setFormFieldsRequired(requiredFields);
  };

  const handleWhatIsResultOfTest = (value, name) => {
    setFormFieldValue(value, name);
    let requiredFields = {
      testResultDate: false,
    };
    if (value) {
      requiredFields = {
        ...requiredFields,
        testResultDate: true,
      };
    }
    setFormFieldsRequired(requiredFields);
  };

  const handleTypeOfMedical = (value, name) => {
    let requiredFields = {
      otherTestName: false,
    };
    setFormFieldValue(value, name);
    if (value === Common.OTHER) {
      requiredFields = {
        ...requiredFields,
        otherTestName: true,
      };
    }
    setFormFieldsRequired(requiredFields);
    setFormFieldValues({
      otherTestName: "",
      testResult: "",
    });
  };

  return (
    <div className={styles.container}>
      <DatePickerField
        name="testTakenDate"
        label={localStrings.dateOfMedicalTest}
        placeholder="MM/DD/YYYY"
        format="MM/DD/YYYY"
        onChange={setFormFieldValue}
        {...testTakenDate}
      />
      <InputField
        name="testAddress"
        label={localStrings.nameOfClinicOrHospital}
        placeholder={localStrings.egMedicalClinic}
        onChange={setFormFieldValue}
        {...testAddress}
      />
      <SelectField
        name="medicalTestType"
        label={localStrings.typeOfMedicalTest}
        data={typeOfMedicalTest}
        placeholder={localStrings.chooseTypeTest}
        onChange={handleTypeOfMedical}
        {...medicalTestType}
      />
      {medTestType === Common.OTHER && (
        <InputField
          name="otherTestName"
          label={localStrings.nameOfSpecificMedicalTest}
          placeholder={localStrings.nameOfOtherTest}
          onChange={setFormFieldValue}
          {...otherTestName}
        />
      )}

      {!!medTestType && (
        <DatePickerField
          name="testResultDate"
          label={localStrings.dateOfAvailabilityOfTestResults}
          placeholder="MM/DD/YYYY"
          format="MM/DD/YYYY"
          onChange={handleWhenResultGiven}
          {...testResultDate}
        />
      )}
      {medTestType === Common.OTHER && (
        <InputField
          name="testResult"
          label={localStrings.testResults}
          onChange={handleWhatIsResultOfTest}
          placeholder={localStrings.takeNoteOfTheMedicalTestResultHere}
          {...testResult}
        />
      )}
      {!!medTestType && medTestType !== Common.OTHER && (
        <SelectField
          name="testResult"
          label={localStrings.testResults}
          data={userResults}
          placeholder={localStrings.selectUserTestResult}
          onChange={handleWhatIsResultOfTest}
          {...testResult}
        />
      )}

      {/* <div className={styles.uploadContainer}>
        <Upload className={styles.upload}>
          <Button text="Upload" />
        </Upload>
        <p className={styles.uploadDesc}>{localStrings.uploadCovidTestCerti}</p>
      </div> */}
    </div>
  );
};

export default MedicalTestForm;
