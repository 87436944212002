import localStrings from "localization";

const errorMessages = {
  1001: localStrings.uhOhEmailNotRegistered,
  1002: localStrings.oopsIncorrectEmailOrPass,
  0: localStrings.oopsSomethinngWentWrong,
};

export const getErrorMessage = (code) => {
  if (errorMessages.hasOwnProperty(code)) {
    return errorMessages[code];
  }
  return errorMessages[0];
};
