import React, { useContext, useCallback, useMemo } from "react";
import { useFormV2, useApi } from "hooks";
import { TextareaField, InputField, SelectField, CheckboxField } from "atomic/molecules";
import { Icon, Tooltip, Loading } from "atomic/atoms";
import { Button } from "atomic/molecules";
import FieldOptions from "./field-options/field-options";
import styles from "./question-form-container.module.scss";
import { searchQuestions } from "api/organization";
import { OrganizationContext } from "contexts";
import localization from "localization";
import { capitalize } from "utils";
import { ActionButton } from "components/common";
import { questionFormState as initialFormState } from "states";

const QuestionForm = ({
  initialValue = undefined,
  children,
  organizationQuestionId,
  fieldTypeOptions,
  isDefaultQuestion,
  isEdit,
  toggleFormModal,
  toggleRemoveModal,
  successSubmit,
  fetchLoading = false,
  submitApi,
  submitText,
}) => {
  const initialState = useMemo(() => {
    return initialFormState(initialValue);
  }, [initialValue]);

  const {
    fields,
    modifyField,
    modifyForm,
    applyFieldErrors,
    dirty: isFormDirty,
    submitForm,
    getFormValues,
  } = useFormV2({
    initialState,
  });
  const { organizationId } = useContext(OrganizationContext);
  const { questionName, fieldType, isAutoAdd, placeholder, organizationQuestionOptions } = fields;
  const hasPlaceholder = fieldType && ["TEXTBOX", "DROPDOWN", "COUNTRY"].includes(fieldType.value);
  const hasFieldOptions =
    fieldType && ["RADIO_BUTTON", "DROPDOWN", "CHECKBOX"].includes(fieldType.value);

  const fieldTypeOptionsData = useMemo(() => {
    const options = Object.entries(fieldTypeOptions).map(([key, value]) => {
      return {
        text: capitalize(value),
        value: key,
      };
    });
    return options;
  }, [fieldTypeOptions]);

  const { request: searchDupQuestReq } = useApi({
    api: searchQuestions,
    params: {
      organizationId,
    },
  });

  const { request: submitRequest, loading: submitLoading } = useApi({
    api: submitApi,
    handleOwnError: {
      code: {
        8051: () => {
          applyFieldErrors({
            questionName: localization.thisQuestionAlreadyExists,
          });
        },
      },
    },
  });

  const handleSearchDupQuestion = useCallback(
    async (searchKey) => {
      try {
        const { customQuestions } = await searchDupQuestReq({
          searchKey: searchKey.trim(),
        });
        const hasDup = customQuestions
          .filter((i) => {
            return i.organizationQuestionId !== organizationQuestionId;
          })
          .some((i) => i.questionName === searchKey);

        if (hasDup) {
          applyFieldErrors({
            questionName: localization.thisQuestionAlreadyExists,
          });
        }
      } catch (e) {
        console.log(e);
      }
    },
    [searchDupQuestReq, applyFieldErrors, organizationQuestionId]
  );

  const handleQuestionOnBlur = useCallback(
    (value) => {
      const trimmedVal = value.trim();
      const { questionName: initialQuestionName } = initialValue || {};
      const isSameValue = trimmedVal === initialQuestionName;
      if (!trimmedVal) {
        modifyField("questionName", { value: "" });
        return;
      }
      if (!isFormDirty || isSameValue) {
        return;
      }
      handleSearchDupQuestion(value);
    },
    [handleSearchDupQuestion, modifyField, initialValue, isFormDirty]
  );

  const handleAddOption = useCallback(() => {
    const count = organizationQuestionOptions.value.length + 1;
    modifyField("organizationQuestionOptions", {
      value: [
        ...organizationQuestionOptions.value,
        {
          optionName: `Option ${count}`,
          sort: count,
        },
      ],
    });
  }, [modifyField, organizationQuestionOptions]);

  const handleRemoveOption = useCallback(
    (removeIndex) => {
      const value = organizationQuestionOptions.value
        .filter((option, index) => index !== removeIndex)
        .map(({ ...rest }, index) => {
          return {
            ...rest,
            sort: index + 1,
          };
        });
      modifyField("organizationQuestionOptions", { value });
    },
    [modifyField, organizationQuestionOptions]
  );

  const handleChangeOptionLabel = useCallback(
    (value, index) => {
      const options = [...organizationQuestionOptions.value];
      options[index].optionName = value;
      modifyField("organizationQuestionOptions", { value: options });
    },
    [modifyField, organizationQuestionOptions]
  );

  const handleChangeFieldType = useCallback(
    (value) => {
      const skipValidation = ["TEXTBOX", "COUNTRY"].includes(value);
      modifyForm({
        fieldType: {
          value,
        },
        organizationQuestionOptions: {
          skipValidation,
        },
      });
    },
    [modifyForm]
  );

  const preparedQuestionOptions = useCallback(
    (formOptions) => {
      if (isEdit) {
        const {
          organizationQuestionOptions: { value: initOptions },
        } = initialState;
        const initOptionsName = initOptions.map(({ optionName }) => optionName);
        const newFormOption = formOptions.map((item) => {
          const index = initOptionsName.indexOf(item.optionName);
          const { sort } = item;
          if (index >= 0) {
            return { ...initOptions[index], sort };
          }
          return item;
        });
        return newFormOption;
      }
      return formOptions;
    },
    [initialState, isEdit]
  );

  const handleSubmitForm = async () => {
    const { organizationQuestionOptions, ...formValues } = getFormValues();
    try {
      const d = await submitRequest({
        ...formValues,
        ...(!!organizationQuestionId && { id: organizationQuestionId }),
        ...(hasFieldOptions && {
          organizationQuestionOptions: preparedQuestionOptions(organizationQuestionOptions),
        }),
        organizationId,
      });
      successSubmit(d);
    } catch (e) {
      console.log(e);
    }
  };

  return fetchLoading ? (
    <Loading isSkeleton className={styles.loading} />
  ) : (
    <>
      <TextareaField
        autoSize
        placeholder={localization.typeYourQuestionHere}
        value={questionName.value}
        onChange={(value) => modifyField("questionName", { value })}
        onBlur={handleQuestionOnBlur}
        error={{
          status: questionName.error,
          message: questionName.message,
        }}
        label={localization.labelName}
        showRequired
        className={styles.questionField}
        maxLength={255}
      />
      <SelectField
        disabled={isDefaultQuestion}
        name="fieldType"
        placeholder={localization.chooseFieldType}
        error={{
          status: fieldType.error,
          message: fieldType.message,
        }}
        value={fieldType.value}
        data={fieldTypeOptionsData}
        onChange={handleChangeFieldType}
        className={styles.fieldTypeField}
        label={localization.fieldType}
        dropdownClassName={styles.fieldTypeDropdown}
        showRequired
      />
      {hasFieldOptions && (
        <FieldOptions
          options={organizationQuestionOptions.value}
          hasError={organizationQuestionOptions.error}
          addOption={handleAddOption}
          removeOption={handleRemoveOption}
          changeOptionLabel={handleChangeOptionLabel}
          modifyField={modifyField}
          optionType={fieldType.value}
        />
      )}
      {hasPlaceholder && (
        <InputField
          onChange={(value) => modifyField("placeholder", { value })}
          value={placeholder.value}
          className={styles.placeholderField}
          label={
            <>
              {localization.placeholderText}{" "}
              <Tooltip
                placement="right"
                title={localization.questionFormPlaceholderTooltip}
                overlayClassName={styles.placeholderTooltip}
              >
                <div className={styles.icon}>
                  <Icon name="info-fill" />
                </div>
              </Tooltip>
            </>
          }
          maxLength={75}
        />
      )}

      <CheckboxField
        value={isAutoAdd.value}
        className={styles.autoAdd}
        label={localization.autoAdd}
        onChange={(value) => {
          modifyField("isAutoAdd", { value });
        }}
      />
      {children}
      <div className={styles.actionsContainer}>
        {isEdit && !isDefaultQuestion && (
          <Button
            className={styles.remove}
            danger
            text={localization.remove}
            onClick={() => toggleRemoveModal({ visible: true })}
            loading={submitLoading}
          />
        )}
        <ActionButton
          className={styles.actionButton}
          submitText={submitText}
          onSubmit={() => submitForm(handleSubmitForm)}
          onCancel={() => toggleFormModal({ visible: false })}
          loading={submitLoading}
          disabled={!isFormDirty}
        />
      </div>
    </>
  );
};

export default QuestionForm;
