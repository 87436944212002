import { Routes } from "enums";

const AUTH = `/${Routes.AUTH}`;
const MEMBERS = `/${Routes.MEMBERS}`;
const USER_PROFILE = `/${Routes.PROFILE}`;
const MEMBER_PROFILE = (userId) => {
  return `${MEMBERS}/${userId}`;
};
const SYMPTOM_LOG = `/${Routes.SYMPTOMS_LOG}`;
const EDIT_SYMPTOMS = (symptomId) => {
  return `${SYMPTOM_LOG}/${Routes.EDIT}/${symptomId}`;
};
const CREATE_SYMPTOMS = `${SYMPTOM_LOG}/${Routes.CREATE}`;
const SETTINGS = `/${Routes.SETTINGS}`;
const SETTINGS_TEAM_AND_ROLES = `${SETTINGS}/${Routes.TEAMS_AND_ROLES}`;
const MY_ORGANIZATIONS = `/${Routes.MY_ORGANIZATION}`;
const AREAS = `/${Routes.AREAS}`;
const LIVE_MAP = `/${Routes.LIVE_MAP}`;
const ONBOARDING = `/${Routes.ONBOARDING}`;
const ADMIN_SETTINGS = `/${Routes.ADMIN}/${Routes.SETTINGS}`;
const BUSINESS_SITE = "https://pouchpass.com/";
const POUCH_SHOP = "https://shop.pouchpass.com/";
const APP_STORE = "https://apps.apple.com/us/app/pouchpass-monitor/id1519648804";
const PLAY_STORE = "https://play.google.com/store/apps/details?id=com.pouchpass.app&hl=de";
const FORMS = `/${Routes.FORMS}`;
const ARTICLES = "http://help.pouchpass.com/support/solutions/articles";

const path = {
  // AUTH
  AUTH,
  // DASHBOARD
  OVERVIEW: "/",
  // AREAS
  AREAS,
  AREAS_CREATE: `${AREAS}/${Routes.CREATE}`,
  AREAS_EDIT: (areaId) => `${AREAS}/${Routes.EDIT}/${areaId}`,
  // MEMBERS
  MEMBERS,
  MEMBER_CREATE: `${MEMBERS}/${Routes.CREATE}`,
  MEMBER_EDIT: (userId) => `${MEMBERS}/${Routes.EDIT}/${userId}`,
  MEMBER_PROFILE: (userId) => MEMBER_PROFILE(userId),
  MEMBER_PROFILE_DIARY: (userId) => `${MEMBER_PROFILE(userId)}/${Routes.TEMPERATURE}`,
  MEMBER_PROFILE_SYMPTOMS: (userId) => `${MEMBER_PROFILE(userId)}/${Routes.SYMPTOMS}`,
  MEMBER_PROFILE_CONTACT_HISTORY: (userId) => `${MEMBER_PROFILE(userId)}/${Routes.CONTACT_HISTORY}`,
  MEMBER_EDIT_SYMPTOMS: (userId, symptomId) =>
    `${MEMBER_PROFILE(userId)}${EDIT_SYMPTOMS(symptomId)}`,
  MEMBER_CREATE_SYMPTOMS: (userId) => `${MEMBER_PROFILE(userId)}${CREATE_SYMPTOMS}`,
  // USER PROFILE
  USER_PROFILE,
  USER_PROFILE_DIARY: `${USER_PROFILE}/${Routes.TEMPERATURE}`,
  USER_PROFILE_SYMPTOMS: `${USER_PROFILE}/${Routes.SYMPTOMS}`,
  USER_PROFILE_CONTACT_HISTORY: `${USER_PROFILE}/${Routes.CONTACT_HISTORY}`,
  CREATE_SYMPTOMS,
  EDIT_SYMPTOMS: (symptomId) => {
    return EDIT_SYMPTOMS(symptomId);
  },
  // SETTINGS
  SETTINGS,
  SETTINGS_TEAM_AND_ROLES,
  SETTINGS_TEAM_AND_ROLES_INVITE: `${SETTINGS_TEAM_AND_ROLES}/${Routes.INVITE_MANAGER}`,
  SETTINGS_PROFILE: `${SETTINGS}${USER_PROFILE}`,
  SETTINGS_MY_ORGANIZATION: `${SETTINGS}${MY_ORGANIZATIONS}`,
  FORMS: `${SETTINGS}${FORMS}`,
  SETTINGS_PREFERENCE: `${SETTINGS}/${Routes.PREFERENCE}`,
  SETTINGS_MEMBER_DETAILS: `${SETTINGS}/${Routes.MEMBER_DETAILS}`,
  SETTINGS_MEMBER_DETAILS_EDIT: `${SETTINGS}/${Routes.MEMBER_DETAILS}/${Routes.EDIT}`,
  SETTINGS_FORM: `${SETTINGS}/${Routes.FORMS}`,
  SETTINGS_FORM_CREATE: `${SETTINGS}/${Routes.FORMS}/${Routes.CREATE}`,
  SETTINGS_FORM_EDIT: (formId) => `${SETTINGS}/${Routes.FORMS}/${Routes.EDIT}/${formId}`,
  SETTINGS_FORM_QUESTIONS: `${SETTINGS}/${Routes.FORMS}/${Routes.QUESTIONS}`,
  // LIVE MAP
  LIVE_MAP,
  // ONBOARDING
  ONBOARDING,
  ONBOARDING_EMPLOYMENT: `${ONBOARDING}/${Routes.EMPLOYMENT_DETAILS}`,
  ONBOARDING_ABOUT_YOU: `${ONBOARDING}/${Routes.ABOUT_YOU}`,
  ONBOARDING_EMERGENCY_CONTACT: `${ONBOARDING}/${Routes.EMERGENCY_CONTACT}`,
  ONBOARDING_PRIVACY_SETTINGS: `${ONBOARDING}/${Routes.PRIVACY_SETTINGS}`,
  // REGISTRATION
  ACTIVATE_USER: `/${Routes.ACTIVATE}/${Routes.USER}`,
  // BUSINESS SITE
  BUSINESS_SITE,
  BUSINESS_LAUNCH: `${BUSINESS_SITE}/launch`,
  BUSINESS_GATEWAYS: `${BUSINESS_SITE}/gateways`,
  BUSINESS_CONTACT: `${BUSINESS_SITE}/contact`,
  BUSINESS_FAQS: `${BUSINESS_SITE}/launch/faqs`,
  BUSINESS_PRICING: `${BUSINESS_SITE}/pricing`,
  BUSINESS_FOR_BUSINESS: `${BUSINESS_SITE}/for-business`,
  BUSINESS_TERMS: `${BUSINESS_SITE}/legal/terms`,
  BUSINESS_PRIVACY: `${BUSINESS_SITE}/legal/privacy`,
  APP_STORE,
  PLAY_STORE,
  POUCH_SHOP,
  COMMUNITY_LIST: `/${Routes.ADMIN}/${Routes.COMMUNITIES}`,
  COMMUNITY_CREATE: `/${Routes.ADMIN}/${Routes.COMMUNITIES}/${Routes.CREATE}`,
  COMMUNITY_EDIT: (communityId) =>
    `/${Routes.ADMIN}/${Routes.COMMUNITIES}/${Routes.EDIT}/${communityId}`,
  ADMIN_SETTINGS,
  ADMIN_SETTINGS_REGISTRY: `${ADMIN_SETTINGS}/registry`,
  UNAUTHORIZED: `/unauthorize`,
  ARTICLES,
  ARTICLE_GATEWAY: `${BUSINESS_SITE}gateways`,
  ARTICLE_SETUP_GATEWAY: `${ARTICLES}/67000036969-setting-up-wireless-gateway-minew-gateway-`,
};
export default path;

// import path from "path/path";
