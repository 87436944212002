import React, { useContext, memo } from "react";
import styles from "./users-list.module.scss";
import { getLocalItem, convertTemperature, isNormalTemp, temperatureSymbol } from "utils";
import { Routes } from "enums";
import classnames from "classnames";
import { LiveMapContext } from "contexts";
import { Link, Loading, Tooltip } from "atomic/atoms";

const UserListItem = ({
  loading,
  fullName,
  latestBodyTemp,
  latitude: lat,
  longitude: lng,
  location,
  isActive,
  userId,
  isPrivateTemp,
  isPrivateName,
}) => {
  const tempType = getLocalItem("tempType");
  const { dispatch } = useContext(LiveMapContext);
  const handleOnClick = ({ latestBodyTemp, lat, lng }) => {
    console.log("focus user", lat, lng);
    dispatch({
      type: "CHANGE_CURRENT_LOCATION",
      data: {
        latitude: lat,
        longitude: lng,
      },
      state: {
        zoom: 24,
      },
    });
  };

  return (
    <div
      className={classnames(styles.userListItem, {
        [`${styles.loading}`]: loading,
      })}
      onClick={() =>
        handleOnClick({
          latestBodyTemp,
          lat,
          lng,
        })
      }
    >
      <div
        className={classnames(styles.userDetails, {
          [`${styles.loading}`]: loading,
        })}
      >
        <Tooltip title={fullName}>
          <p
            className={classnames(styles.userName, {
              [`${styles.loading}`]: loading,
            })}
          >
            {loading ? (
              <Loading height="100%" width="100%" />
            ) : (
              <Link target="_blank" to={`${Routes.MEMBERS}/${userId}`}>
                {isPrivateName ? `#${userId}` : fullName}
              </Link>
            )}
          </p>
        </Tooltip>
        <Tooltip title={location}>
          <p
            className={classnames(styles.location, {
              [`${styles.inactive}`]: !isActive,
              [`${styles.loading}`]: loading,
            })}
          >
            {location}
          </p>
        </Tooltip>
      </div>
      <p
        className={classnames(styles.temp, {
          [`${styles.loading}`]: loading,
          cDanger: latestBodyTemp && !isNormalTemp(convertTemperature(latestBodyTemp)),
        })}
      >
        {loading && <Loading height="100%" width="100%" />}
        {!loading &&
          !isPrivateTemp &&
          `${convertTemperature(latestBodyTemp)} ${temperatureSymbol(tempType)}`}
      </p>
    </div>
  );
};
export default memo(UserListItem);
