import { Button, Panel } from "atomic/molecules";
import React from "react";
import GatewayPlaceholder from "images/gateway-placeholder.svg";
import { Image } from "atomic/atoms";
import styles from "./empty-area.module.scss";
import { useRouter } from "hooks";
import path from "path/path";
import localString from "localization";

const EmptyArea = () => {
  const { history } = useRouter();
  return (
    <Panel>
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.title}>Create and Monitor Areas with Gateways</div>
          <div className={styles.description}>{localString.createYourFirstAreaDesc}</div>
          <Button
            className={styles.createButton}
            text={localString.createArea}
            onClick={() => history.push(path.AREAS_CREATE)}
          />
        </div>
        <div>
          <Image className={styles.image} src={GatewayPlaceholder}></Image>
        </div>
      </div>
    </Panel>
  );
};

export default EmptyArea;
