import React from "react";
import { Menu, Dropdown as AntDropdown } from "antd";
import classnames from "classnames";
import styles from "./dropdown.module.scss";
import { v4 as uuidv4 } from "uuid";
const Dropdown = ({
  className,
  children,
  trigger = ["hover"],
  menuItems = [],
  selectedItem,
  onClick,
  customMenu,
  index,
  meatBalls,
  isDisabled = false,
  optionsMenu = styles.options,
}) => {
  const menu = () => {
    if (!menuItems.length) {
      return (
        <Menu className={className}>
          <Menu.Item>No Item</Menu.Item>
        </Menu>
      );
    }
    return (
      <Menu
        className={classnames(className, {
          [`${styles.meatBalls}`]: meatBalls,
        })}
      >
        {menuItems.map((item) => {
          if (item) {
            const { value, component = () => null, text, label } = item;
            return (
              <Menu.Item
                // onMouseDown={(e) => {}}
                // onClick={() => {
                //   // e.stopPropagation();
                //   // e.nativeEvent.stopImmediatePropagation();
                //   if (item.onClick) {
                //     item.onClick({ value, index });
                //   } else {
                //     onClick({ value, index });
                //   }
                // }}
                onClick={item.onClick}
                className={classnames(optionsMenu, {
                  [`${styles.selectedItem} selectedItem`]: value && selectedItem === value,
                })}
                key={uuidv4()}
              >
                {text || label || component()}
              </Menu.Item>
            );
          }
          return null;
        })}
      </Menu>
    );
  };

  return (
    <AntDropdown overlay={customMenu || menu} trigger={trigger} disabled={isDisabled}>
      {children}
    </AntDropdown>
  );
};

export { Menu };
export default Dropdown;
