import React from "react";
import { Routes } from "enums";
import { NotFoundError } from "pages";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { PouchBandRegistryContainer } from "containers";
import { AdminSettingsNav } from "components/admin-settings";
import styles from "./admin-settings-container.module.scss";
import localStrings from "localization";

const AdminSettingsContainer = () => {
  let { path } = useRouteMatch();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.header}>
          <h2 className={styles.headerTitle}>{localStrings.pouchPassSettings}</h2>
        </div>
        <AdminSettingsNav className={styles.settingsNav} />
        <div className={styles.formContainer}>
          <Switch>
            <Route path={`${path}/${Routes.REGISTRY}`} component={PouchBandRegistryContainer} />
            <Redirect exact from={`${path}`} to={`${path}/${Routes.REGISTRY}`} />
            <Route render={() => <NotFoundError />} />
          </Switch>
        </div>
      </div>
    </>
  );
};

export default AdminSettingsContainer;
