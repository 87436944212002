import React from "react";
import styles from "./settings-layout.module.scss";
import { SettingsNav } from "components/settings";

const SettingsLayout = ({ title, children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.headerTitle}>{title}</h2>
      </div>
      <SettingsNav className={styles.settingsNav} />
      <div className={styles.formContainer}>{children}</div>
    </div>
  );
};

export default SettingsLayout;
