const initialFormFields = (permissions) => {
  return permissions.reduce((acc, cur) => {
    const { name, add = true, edit = true, remove = true, view = true } = cur;
    const fields = (() => {
      let f = {};
      if (add) {
        f = {
          ...f,
          [`${name}Add`]: {
            value: true,
            disabled: true,
          },
        };
      }
      if (edit) {
        f = {
          ...f,
          [`${name}Edit`]: {
            value: true,
            disabled: true,
          },
        };
      }
      if (remove) {
        f = {
          ...f,
          [`${name}Remove`]: {
            value: true,
            disabled: true,
          },
        };
      }
      if (view) {
        f = {
          ...f,
          [`${name}View`]: {
            value: true,
            disabled: true,
          },
        };
      }
      return f;
    })();
    return {
      ...acc,
      ...fields,
    };
  }, {});
};
export default initialFormFields;
