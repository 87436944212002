import React, { useState, useMemo, useCallback, useEffect } from "react";
import classnames from "classnames";
// import { v4 as uuidv4 } from "uuid";
import { ImageSlider, Bullets, Modal } from "atomic/atoms";
import styles from "./guide-modal.module.scss";
import SubmitStep from "./submit-step/submit-step";
import localStrings from "localization";

const GuideModal = ({ modalClassName, className, items, visible, close = () => null }) => {
  const [active, setActive] = useState(0);

  const selected = useMemo(() => {
    return items && items[active] ? items[active] : {};
  }, [active, items]);

  useEffect(() => {
    // Reset active
    if (visible) {
      setActive(0);
    }
  }, [visible]);

  const next = useCallback(() => {
    if (items.length - 1 !== active) {
      setActive(active + 1);
    }
  }, [active, items, setActive]);

  const prev = useCallback(() => {
    if (active !== 0) {
      setActive(active - 1);
    }
  }, [active]);

  if (selected.content) {
    return (
      <Modal title={" "} visible={visible} className={styles.customModal} onCancel={close}>
        {selected.content}
        {selected.actions({
          next,
          prev,
          close: () => setActive(false),
        })}
      </Modal>
    );
  }
  return (
    <Modal
      title={" "}
      visible={visible}
      className={classnames(styles.modal, modalClassName)}
      onCancel={close}
    >
      <div className={classnames("guide-modal", styles.container, className)}>
        <ImageSlider items={items} active={active} select={setActive} />
        <div className={styles.content}>
          <Bullets items={items} active={active} select={setActive} />
          <h1>{selected.title}</h1>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: selected.description,
            }}
          />
        </div>

        <SubmitStep
          onBackContent={active === 0 ? localStrings.dismiss : null}
          onBack={() => {
            active === 0 ? close() : prev();
          }}
          onSubmit={() => next()}
          submitText={active === 0 ? localStrings.startTour : ""}
        />
      </div>
    </Modal>
  );
};

export default GuideModal;
