import locale from "localization";

const deviceUid = (value, custom) => {
  if (custom && custom.includes(value)) {
    return {
      error: true,
      message: locale.accessoryAlreadyPaired,
    };
  }
};

const initialFormState = {
  deviceUid: {
    required: true,
    value: "",
    maxLength: 14,
    min: {
      length: 12,
      message: "Minimum 12 characters.",
    },
    error: {
      status: false,
      message: "",
    },
    customValidations: deviceUid,
    custom: [],
  },
};

export default initialFormState;
