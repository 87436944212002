import React, { useState, useEffect, useMemo, memo } from "react";
import { Table as AntTable, Tooltip } from "antd";
import { v4 as uuidv4 } from "uuid";
import styles from "./table.module.scss";
import classnames from "classnames";
import Skeleton from "react-loading-skeleton";

const Table = ({
  columns,
  data,
  draggable,
  className,
  emptyRenderer = {
    image: null,
    description: "",
  },
  rowDropdown,
  rowSelection,
  scroll,
  onRowClick,
  loading = false,
  showHeader = true,
  relaxedSide = false,
  borderless = false,
  noHover = false,
  skeletonCount = 5,
}) => {
  const [hasData, setHasData] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (loading) {
      setDataSource([]);
      for (let x = 0; x < skeletonCount; x++) {
        const loadingData = Object.keys(columns).reduce(
          (acc, cur) => {
            const { dataIndex } = columns[cur];
            acc = {
              ...acc,
              [dataIndex]: <Skeleton />,
            };
            return acc;
          },
          { key: uuidv4() }
        );
        setDataSource((prev) => [...prev, loadingData]);
      }
    } else {
      setHasData(data && data.length > 0);
      setDataSource(data);
    }
  }, [columns, loading, data, skeletonCount]);

  const preparedColumns = useMemo(() => {
    return columns.map((column = {}, index, arr) => {
      const isLast = index === arr.length - 1;
      const { alignRight, title, ellipsis } = column;
      if (isLast && rowDropdown) {
        return {
          ...column,
          title: (
            <div
              className={classnames({
                [`${styles.lastTableColumnHeader}`]: alignRight,
              })}
            >
              {title}
            </div>
          ),
        };
      }
      if (ellipsis) {
        return {
          ...column,
          title: (
            <Tooltip title={title}>
              <span className="title">{title}</span>
            </Tooltip>
          ),
        };
      }
      return column;
    });
  }, [columns, rowDropdown]);

  return (
    <div
      className={classnames(styles.tableContainer, className, {
        [`${styles.draggable}`]: draggable,
        [`${styles.empty}`]: !hasData,
        [`${styles.loading}`]: loading,
        [`${styles.relaxedSide}`]: relaxedSide,
        [`${styles.borderless}`]: borderless,
        [`${styles.noHover}`]: noHover,
      })}
    >
      {render({
        data,
        dataSource,
        preparedColumns,
        showHeader,
        scroll,
        rowSelection,
        loading,
        emptyRenderer,
        onRowClick,
      })}
    </div>
  );
};

const render = ({
  data,
  dataSource,
  preparedColumns,
  showHeader,
  scroll,
  rowSelection,
  loading,
  emptyRenderer,
  onRowClick,
}) => {
  if ((!Array.isArray(data) || !data.length) && !loading) {
    return (
      <div className={classnames(styles.emptyContainer)}>
        <h2 className={styles.emptyTableTitle}>{emptyRenderer.title}</h2>
        {emptyRenderer.description || "Empty Table"}
      </div>
    );
  }

  return (
    <AntTable
      onRow={(record, rowIndex) => {
        return {
          onClick: () => onRowClick && onRowClick(rowIndex),
        };
      }}
      rowClassName={() => {
        return !!onRowClick && `${styles.clickableRow}`;
      }}
      showHeader={showHeader}
      scroll={scroll}
      rowSelection={rowSelection}
      columns={preparedColumns}
      pagination={false}
      dataSource={dataSource}
    />
  );
};

export default memo(Table);
