import { Field } from "enums";
import Validation from "services/validation.service";

const initialFormState = (initialState = {}) => {
  const { areaLevel = 1, areaName = "", areaDescription = "", gateways = [] } = initialState;
  console.log(initialState);
  return {
    areaName: {
      value: areaName,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
    },
    areaDescription: {
      value: areaDescription,
      type: Field.INPUT,
      maxLength: 255,
    },
    areaLevel: {
      value: areaLevel,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
    },
    gateways: {
      value: gateways,
      type: Field.ANY,
    },
  };
};

export default initialFormState;
