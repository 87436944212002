import React from "react";
import styles from "./symptom-buttons.module.scss";
import SymptomButton from "./symptom-button";
import classnames from "classnames";
import symptoms from "./symptoms";

const SymptomButtons = ({ handleOnClickSymptoms, symptomsValue }) => {
  return (
    <div className={styles.symptoms}>
      {symptoms.map((symptom, index) => {
        return (
          <SymptomButton
            {...symptom}
            className={classnames(styles.symptomButton, {
              [`${styles.eleven}`]: index === 10,
            })}
            onClick={handleOnClickSymptoms}
            isChecked={symptomsValue.includes(symptom.value)}
          />
        );
      })}
    </div>
  );
};

export default SymptomButtons;
