import React, { memo, useState } from "react";
import { Modal as AntModal } from "antd";
import { Icon } from "atomic/atoms";
import classnames from "classnames";
import styles from "./modal.module.scss";
const Modal = ({
  children,
  visible,
  onCancel,
  className,
  title,
  closable = true,
  destroyOnClose,
}) => {
  return (
    <AntModal
      className={className}
      visible={visible}
      okButtonProps={{ disabled: true }}
      cancelButtonProps={{ disabled: true }}
      centered={true}
      closable={false}
      footer={null}
      onCancel={onCancel}
      destroyOnClose={destroyOnClose}
    >
      {(title || closable) && (
        <div className={classnames(styles.header, "header")}>
          {title && <h2 className={classnames(styles.title, "title")}>{title}</h2>}
          {closable && (
            <Icon className={classnames(styles.close, "close")} name="close" onClick={onCancel} />
          )}
        </div>
      )}

      <div className="body">{children}</div>
    </AntModal>
  );
};

export const useModal = ({ content, title, onCancel, className }) => {
  const [active, setActive] = useState(false);

  const show = () => {
    setActive(true);
  };

  const close = () => {
    if (onCancel) {
      onCancel({
        close: () => {
          setActive(false);
        },
      });
    }
    setActive(false);
  };

  const render = (
    <Modal title={title} onCancel={close} className={className} visible={active}>
      {content}
    </Modal>
  );
  return { render, show, close };
};

export default memo(Modal);
