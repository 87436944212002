import React, { useCallback, useContext, useMemo, useEffect, useState } from "react";
import { getQuestion, searchForms, updateQuestion, removeQuestion } from "api/organization";
import { useApi } from "hooks";
import { OrganizationContext } from "contexts";
import localization from "localization";
import { Toast } from "atomic/molecules";
import QuestionForm from "../question-form/question-form-container";
import { QuestionFieldTypes, DefaultQuestionFieldTypes } from "enums";
import { Icon, Modal } from "atomic/atoms";
import styles from "../questions-container.module.scss";
import RemoveQuestionModal from "components/settings/questions/remove-question-modal/remove-question-modal";

const EditQuestionFormModalContainer = ({
  visible,
  fetchQuestions,
  organizationQuestionId,
  toggleFormModal,
  isDefaultQuestion,
  submitText = localization.saveChanges,
}) => {
  const { organizationId } = useContext(OrganizationContext);
  const [linkedForms, setLinkedForms] = useState([]);
  const [removeQuestionModal, setRemoveQuestionModal] = useState({ visible: false });
  const { request: getQuestionReq, loading: getQuestionLoading, result: getQuestionRes } = useApi({
    api: getQuestion,
    params: { organizationId },
  });
  const { request: removeQuestionReq, loading: removeQuestionLoading } = useApi({
    api: removeQuestion,
  });
  const { request: searchFormQuestReq } = useApi({
    api: searchForms,
    params: {
      organizationId,
    },
  });

  const isLinked = useMemo(() => {
    return linkedForms.length > 0;
  }, [linkedForms]);

  useEffect(() => {
    const initFormValues = async () => {
      await getQuestionReq({ id: organizationQuestionId });
    };
    const checkQuestionLinked = async () => {
      const result = await searchFormQuestReq({ organizationQuestionId });
      setLinkedForms(result.filter((i) => i.isActive));
    };

    if (visible) {
      initFormValues();
      checkQuestionLinked();
    }
    // eslint-disable-next-line
  }, [visible, organizationQuestionId]);

  const toggleRemoveModal = useCallback((config) => {
    setRemoveQuestionModal((prev) => ({ ...prev, ...config }));
  }, []);

  const handleSucessSubmit = useCallback(async () => {
    toggleFormModal({ visible: false });
    Toast({
      content: localization.changesSaved,
      icon: "check-fill",
      success: true,
    }).open();
    fetchQuestions();
  }, [toggleFormModal, fetchQuestions]);

  const handleRemoveQuestion = useCallback(async () => {
    try {
      await removeQuestionReq({ id: organizationQuestionId, organizationId });
      toggleFormModal({ visible: false });
      toggleRemoveModal({ visible: false });
      fetchQuestions();
      Toast({
        content: localization.removedQuestion,
        icon: "check-fill",
        success: true,
      }).open();
    } catch (e) {
      console.log(e);
    }
  }, [
    removeQuestionReq,
    organizationId,
    toggleFormModal,
    toggleRemoveModal,
    fetchQuestions,
    organizationQuestionId,
  ]);

  return (
    <>
      <Modal
        title={localization.editQuestion}
        className={styles.formModal}
        visible={visible}
        onCancel={() => toggleFormModal({ visible: false })}
        destroyOnClose
      >
        <QuestionForm
          initialValue={getQuestionRes}
          submitApi={updateQuestion}
          successSubmit={handleSucessSubmit}
          fetchLoading={getQuestionLoading}
          fieldTypeOptions={isDefaultQuestion ? DefaultQuestionFieldTypes : QuestionFieldTypes}
          toggleFormModal={toggleFormModal}
          toggleRemoveModal={toggleRemoveModal}
          organizationQuestionId={organizationQuestionId}
          isDefaultQuestion={isDefaultQuestion}
          isEdit
          submitText={submitText}
        >
          {isLinked && !isDefaultQuestion && (
            <div className={styles.linkedQuestion}>
              <Icon name="info-fill" />
              {localization.linkedQuestionNotif}
            </div>
          )}
        </QuestionForm>
      </Modal>
      <RemoveQuestionModal
        {...removeQuestionModal}
        isLinked={isLinked}
        linkedForms={linkedForms}
        loading={removeQuestionLoading}
        organizationQuestionId={organizationQuestionId}
        removeQuestion={handleRemoveQuestion}
        closeModal={() => toggleRemoveModal({ visible: false })}
      />
    </>
  );
};

export default EditQuestionFormModalContainer;
