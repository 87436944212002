import { CommunityList } from "components/community";
import { useModal } from "hooks";
import { LayoutA } from "layouts";
import React from "react";

const CommunityListContainer = () => {
  const deactivateCommunityModal = useModal({});

  return (
    <LayoutA>
      <CommunityList deactivateCommunityModal={deactivateCommunityModal} />
    </LayoutA>
  );
};

export default CommunityListContainer;
