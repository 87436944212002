import { Loading, MenuOption, Table } from "atomic/atoms";
import { Avatar, Panel, SearchField } from "atomic/molecules";
import React, { useMemo } from "react";
import { pluralize } from "utils";
import EmptyCommunity from "../empty-community/empty-community";
import styles from "./community-table.module.scss";
import { communityTableColumns } from "./community-table-columns";
import { v4 as uuidv4 } from "uuid";
import localStrings from "localization";
import classnames from "classnames";
import moment from "moment";
import { formatNumberWithCommas } from "services";

const CommunityTable = ({
  loading,
  total,
  communities,
  locations,
  searchKey,
  search,
  changeSearch,
  clear,
  getMenuOptions,
  activeMenu,
  setActiveMenu,
}) => {
  const data = useMemo(() => {
    const getAreaLocation = (organizationId) => {
      if (locations.hasOwnProperty(organizationId)) {
        if (locations[organizationId]) {
          return locations[organizationId];
        }
        return "-";
      }
      return <Loading />;
    };

    return communities.map((community) => {
      const {
        organizationId,
        organizationLogo,
        organizationName,
        isActive,
        createdDate,
      } = community;

      return {
        key: uuidv4(),
        name: (
          <div className={styles.nameContainer}>
            <Avatar image={organizationLogo} className={styles.avatar} />
            <div className={styles.name}>{organizationName}</div>
          </div>
        ),
        location: getAreaLocation(organizationId),
        dateCreated: createdDate ? moment(createdDate).format("MMM D, YYYY") : "-",
        status: isActive ? (
          <div className={classnames(styles.status, styles.active)}>{localStrings.active}</div>
        ) : (
          <div className={classnames(styles.status, styles.inactive)}>{localStrings.inactive}</div>
        ),
        menu: (
          <div
            onClick={(e) => {
              setActiveMenu(organizationId);
              console.log("Heyy");
              e.stopPropagation();
              e.nativeEvent.stopImmediatePropagation();
            }}
          >
            <MenuOption
              options={getMenuOptions(community)}
              active={activeMenu?.toString() === organizationId?.toString()}
              onClose={() => setActiveMenu(null)}
              itemMenuClassNameContainer={styles.menuOption}
            />
          </div>
        ),
      };
    });
  }, [communities, locations, activeMenu, setActiveMenu, getMenuOptions]);

  const content = useMemo(() => {
    if (loading) {
      return (
        <div>
          <Table columns={communityTableColumns} loading={true} />
        </div>
      );
    }
    if (total > 0) {
      return (
        <div>
          <Table columns={communityTableColumns} loading={false} data={data} />
        </div>
      );
    }
    return <EmptyCommunity />;
  }, [loading, total, data]);

  return (
    <Panel>
      <div className={styles.headerContainer}>
        <div className={styles.total}>
          {loading ? (
            <Loading />
          ) : (
            `${formatNumberWithCommas(total)} Total ${pluralize({
              count: total,
              singular: "Community",
              plural: "Communities",
            })}`
          )}
        </div>
        <div>
          <SearchField
            placeholder="Search..."
            maxLength={75}
            disabled={loading}
            searchKey={searchKey}
            onSearch={search}
            onChange={changeSearch}
            onClear={clear}
          />
        </div>
      </div>
      {content}
    </Panel>
  );
};

export default CommunityTable;
