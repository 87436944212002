import React, { Fragment, useCallback, useContext, useMemo, useState } from "react";
import useApi from "hooks/useApi";
import { getTotals, getUserCount } from "api/user-data";
import { useMount } from "hooks";
import { OrganizationContext } from "contexts";
import styles from "./overview.module.scss";
import { totalsState } from "states/totals.state";
import { Temperature } from "enums";
import { NoUserPanel } from "atomic/organisms";
import {
  FeverDetailsContainer,
  InactiveDetailsContainer,
  FeverTrendsContainer,
  AreaDetailsContainer,
  LiveMapContainer,
} from "containers";
import { OverviewHeader, OverviewStatusCount } from "components/overview";

const OverviewContainer = () => {
  const [tempType, setTempType] = useState(Temperature.Celsius);
  const [totalsHasError, setTotalsHasError] = useState(false);

  const { organizationId } = useContext(OrganizationContext);
  const {
    request: totalsRequest,
    loading: totalsLoading,
    result: getTotalsResult = totalsState,
  } = useApi({
    api: getTotals,
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
  });
  const {
    request: userCountRequest,
    loading: userCountLoading,
    result: userCountResult = 0,
  } = useApi({
    api: getUserCount,
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
  });

  useMount(() => {
    const initTotals = async () => {
      try {
        await totalsRequest({ organizationId });
      } catch (error) {
        setTotalsHasError(true);
      }
    };
    const initUserCount = async () => {
      try {
        await userCountRequest({ organizationId });
      } catch (error) {
        setTotalsHasError(true);
      }
    };
    initTotals();
    initUserCount();
  });

  const changeTempTypeCb = useCallback((tempType) => {
    setTempType(tempType);
  }, []);

  const statusCount = useMemo(() => {
    if (totalsLoading || userCountLoading || userCountResult > 0) {
      return (
        <Fragment>
          <OverviewStatusCount
            loading={totalsLoading}
            className={styles.detail}
            error={totalsHasError}
            organizationId={organizationId}
            {...getTotalsResult}
          />
          <FeverDetailsContainer tempType={tempType} />
          <InactiveDetailsContainer />
        </Fragment>
      );
    } else {
      return <NoUserPanel />;
    }
  }, [
    getTotalsResult,
    totalsHasError,
    totalsLoading,
    userCountLoading,
    userCountResult,
    tempType,
    organizationId,
  ]);

  return (
    <div className={styles.container}>
      <OverviewHeader
        loading={totalsLoading}
        lastUpdate={getTotalsResult.lastUpdatedDateTime}
        tempType={tempType}
        changeTempType={changeTempTypeCb}
      />
      <div className={styles.column}>{statusCount}</div>
      <div className={styles.column}>
        <LiveMapContainer isMiniMap />
        <FeverTrendsContainer />
        <AreaDetailsContainer />
      </div>
    </div>
  );
};

export default OverviewContainer;
