import { Post } from "./api";
import { ApiRoutes } from "enums";
import { v1 as uuidv1 } from "uuid";

export const uploadImage = async ({ file }) => {
  const formData = new FormData();
  const fileName = uuidv1();
  formData.append("files", file, fileName);
  console.log(formData);
  const res = await Post(ApiRoutes.UPLOAD, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
  return res.data[0];
};
