import React, { useEffect, memo } from "react";
import styles from "./accessory-form-modal.module.scss";
import { useForm } from "hooks";
import initialFormState from "./initial-form-state";
import localStrings from "localization";
import Wristband from "images/wristband.png";
import { Modal, Image } from "atomic/atoms";
import { InputField, Button } from "atomic/molecules";

const AccessoryFormModal = ({ visible, onClose, onSubmit, userId }) => {
  const { formFields, setFormFieldValue, setFormFieldValues, clearFormFields, submit } = useForm(
    initialFormState,
    submitCallBack
  );
  const { deviceUid } = formFields;

  function submitCallBack(formValue) {
    const { deviceUid } = formValue;
    onSubmit(
      {
        deviceUid,
        userId,
      },
      setFormFieldValues
    );
  }

  useEffect(() => {
    if (visible) {
      clearFormFields();
    }
  }, [visible, clearFormFields]);

  return (
    <Modal
      className={styles.modal}
      title={localStrings.addAccessory}
      visible={visible}
      onCancel={onClose}
    >
      <div className={styles.body}>
        <Image src={Wristband} className={styles.wristbandImage} />
        <p>{localStrings.connectAPouchBandDesc}</p>
        <div>
          <InputField
            className={styles.input}
            placeholder={localStrings.inputAccessoryId}
            name="deviceUid"
            onChange={setFormFieldValue}
            {...deviceUid}
          />
        </div>
      </div>

      <div className={styles.actions}>
        <Button onClick={onClose} className={styles.cancel} tertiary text={localStrings.cancel} />
        <Button className={styles.save} text={localStrings.addAccessory} onClick={submit} />
      </div>
    </Modal>
  );
};

export default memo(AccessoryFormModal);
