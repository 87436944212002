import { v4 as uuidv4 } from "uuid";
import localStrings from "localization";

export const myQuarantineMemberTableColumns = [
  {
    key: uuidv4(),
    dataIndex: "name",
    title: localStrings.name,
    width: 120,
  },
  {
    key: uuidv4(),
    dataIndex: "latestTemp",
    title: localStrings.latestTemp,
    width: 60,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "areaLocation",
    title: localStrings.areaLocation,
    width: 100,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "lastSynced",
    title: localStrings.lastSynced,
    width: 120,
  },
  {
    key: uuidv4(),
    dataIndex: "accessoryId",
    title: localStrings.accessoryId,
    width: 80,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "menu",
    title: "",
    width: 50,
    ellipsis: true,
  },
];

export const memberTableColumns = [
  {
    key: uuidv4(),
    dataIndex: "accessoryStatus",
    title: localStrings.accessoryStatus,
    width: 65,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "name",
    title: localStrings.name,
    width: 120,
  },
  {
    key: uuidv4(),
    dataIndex: "latestTemp",
    title: localStrings.latestTemp,
    width: 60,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "areaLocation",
    title: localStrings.areaLocation,
    width: 100,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "lastSynced",
    title: localStrings.lastSynced,
    width: 120,
  },
  {
    key: uuidv4(),
    dataIndex: "accessoryId",
    title: localStrings.accessoryId,
    width: 80,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "menu",
    title: "",
    width: 50,
    ellipsis: true,
  },
];
