import React, { useState, Fragment, useEffect, useCallback } from "react";
import styles from "./reset-password-form.module.scss";
import localStrings from "localization";
import { useForm } from "hooks";
import initialFormFields from "./initial-form-field";
import { useRouter } from "hooks";
import { submitForm } from "./reset-password-service";
import { Link, PasswordStrength } from "atomic/atoms";
import { Button, InputFieldPassword } from "atomic/molecules";

const ResetPasswordForm = () => {
  const { query, history } = useRouter();
  const [isSuccess, setIsSuccess] = useState(null);
  const { token } = query;

  useEffect(() => {
    if (!token) {
      history.replace("/");
    }
  }, [token, history]);

  return (
    <div className={styles.container}>
      <Title isSuccess={isSuccess} />
      <Description isSuccess={isSuccess} />
      <Form isSuccess={isSuccess} setIsSuccess={setIsSuccess} history={history} token={token} />
    </div>
  );
};

const Title = ({ isSuccess }) => (
  <h2 className={styles.title}>
    {isSuccess ? `${localStrings.passwordReset}!` : localStrings.resetPassword}
  </h2>
);

const Description = ({ isSuccess }) => {
  let desc = null;
  if (typeof isSuccess === "boolean") {
    desc = isSuccess ? localStrings.successChangedPassword : localStrings.resetTokenExpired;
  } else {
    desc = localStrings.almostDoneEnterYourNewPassword;
  }
  return <p className={styles.desc}>{desc}</p>;
};

const Form = ({ token, isSuccess, setIsSuccess, history }) => {
  const [loading, setLoading] = useState(false);
  const [isVisiblePassword, setIsVisiblePassword] = useState(false);
  const { formFields, formFieldsValue, setFormFieldValue, setCustomField, submit } = useForm(
    initialFormFields,
    (values) => {
      submitForm({ ...values, token, setIsSuccess, setLoading });
    }
  );
  const { newPassword, confirmPassword } = formFields;
  const { newPassword: newPasswordValue } = formFieldsValue;

  const onShowPasswordToggleChange = useCallback((value) => {
    setIsVisiblePassword(value);
  }, []);

  return (
    <form
      className={styles.form}
      onSubmit={(e) => {
        e.preventDefault();
        submit();
      }}
    >
      {!isSuccess && (
        <Fragment>
          <InputFieldPassword
            label={localStrings.newPassword}
            placeholder={localStrings.enterPassword}
            className={styles.newPassword}
            name="newPassword"
            onChange={(value, name) => {
              setFormFieldValue(value, name);
              setCustomField(value, "confirmPassword");
            }}
            onShowToggleChange={onShowPasswordToggleChange}
            shouldShowToggle
            {...newPassword}
          />
          {newPasswordValue && (
            <PasswordStrength className={styles.passwordStrength} password={newPasswordValue} />
          )}
          <InputFieldPassword
            label={localStrings.confirmNewPassword}
            placeholder={localStrings.enterPassword}
            className={styles.confirmPassword}
            name="confirmPassword"
            onChange={setFormFieldValue}
            shouldShowToggle={false}
            type={isVisiblePassword ? "text" : "password"}
            {...confirmPassword}
          />
          <Button
            loading={loading}
            className={styles.resetPassword}
            text={localStrings.resetPassword}
            htmlType="submit"
          />
          <p className={styles.rememberPassword}>
            {`${localStrings.rememberYourPasswordQuestion}`}{" "}
            <Link className={styles.loginNow} to="/" text={localStrings.loginNow} />
          </p>{" "}
        </Fragment>
      )}

      {isSuccess && (
        <Button
          className={styles.backToLogin}
          onClick={() => history.replace("/")}
          text={localStrings.backToLogin}
        />
      )}
    </form>
  );
};

export default ResetPasswordForm;
