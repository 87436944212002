import React from "react";
import { Switch } from "atomic/atoms";
import styles from "./switch-field.module.scss";
import classnames from "classnames";

const SwitchField = ({ className, children, loading, description, title, ...switchProps }) => {
  return (
    <div className={classnames(styles.field, className)}>
      <Switch loading={loading} {...switchProps} />
      {children || (
        <div className={styles.title}>
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      )}
    </div>
  );
};

export default SwitchField;
