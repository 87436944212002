import React from "react";
import styles from "./panel.module.scss";
import classnames from "classnames";
import { Loading } from "atomic/atoms";

const Panel = ({ children, loading, className, title, description }) => {
  return (
    <div className={classnames("panel", styles.container, className)}>
      {title && (
        <div className={styles.header}>
          {title && <h4>{title}</h4>}
          {description}
        </div>
      )}
      {loading ? <Loading /> : children}
    </div>
  );
};

export default Panel;
