import React from "react";
import styles from "./empty-community.module.scss";
import QuestionNote from "images/question-note.svg";
import { Image } from "atomic/atoms";

const EmptyCommunity = () => {
  return (
    <div className={styles.container}>
      <Image className={styles.image} src={QuestionNote}></Image>
      <div className={styles.primaryText}>No results</div>
      <div className={styles.secondaryText}>Try another search...</div>
    </div>
  );
};

export default EmptyCommunity;
