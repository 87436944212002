import React, { useContext, useState, useMemo } from "react";
import { getFeverTrends } from "api/user-data";
import { useMount, useApi } from "hooks";
import { OrganizationContext } from "contexts";
import * as momentTimezone from "moment-timezone";
import moment from "moment";
import localStrings from "localization";
import styles from "../overview.module.scss";
import classnames from "classnames";
import { LineGraph } from "atomic/organisms";

const FeverTrendsContainer = () => {
  const [hasError, setHasError] = useState(false);

  const { organizationId } = useContext(OrganizationContext);
  const { request, loading = true, result = [] } = useApi({
    api: getFeverTrends,
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
  });

  useMount(() => {
    const init = async () => {
      try {
        await request({ organizationId, timezone: momentTimezone.tz.guess() });
      } catch (error) {
        setHasError(true);
      }
    };
    init();
  });

  const [graphData, graphLabel, graphTooltip] = useMemo(() => {
    const totals = [];
    const labels = [];
    const tooltip = [];

    result.reverse().forEach((d) => {
      const date = moment(d.date, "YYYY-MM-DD");
      totals.push(d.total || 0);
      labels.push(date.format("MMM DD"));
      tooltip.push(date.format("MMM DD"));
    });
    return [totals, labels, tooltip];
  }, [result]);

  const yAxes = useMemo(() => {
    const max = (() => {
      const m = Math.max(25, ...graphData);
      const r = m % 5;
      if (r !== 0) {
        return m - r + 5;
      }
      return m;
    })();
    return {
      ticks: {
        min: 0,
        max,
        stepSize: 5,
      },
    };
  }, [graphData]);

  return (
    <LineGraph
      className={classnames(styles.graph, styles.detail)}
      title={localStrings.temperatureTrend}
      desc={localStrings.tempTrendDesc}
      loading={loading}
      data={graphData}
      labels={graphLabel}
      tooltip={graphTooltip}
      displayVerticalLine={false}
      yAxes={yAxes}
      error={hasError}
    />
  );
};

export default FeverTrendsContainer;
