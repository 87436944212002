import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Routes } from "enums";
import { NotFoundError } from "pages";
import {
  ProfilePhotoContainer,
  AboutYouContainer,
  EmploymentDetailsContainer,
  EmergencyContactContainer,
  PrivacySettingsContainer,
} from "containers";

const OnboardingContainer = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact component={ProfilePhotoContainer} path={`${path}`} />
      <Route exact component={AboutYouContainer} path={`${path}/${Routes.ABOUT_YOU}`} />
      <Route
        exact
        component={EmploymentDetailsContainer}
        path={`${path}/${Routes.EMPLOYMENT_DETAILS}`}
      />
      <Route
        exact
        component={EmergencyContactContainer}
        path={`${path}/${Routes.EMERGENCY_CONTACT}`}
      />
      <Route
        exact
        component={PrivacySettingsContainer}
        path={`${path}/${Routes.PRIVACY_SETTINGS}`}
      />
      <Route render={() => <NotFoundError />} />
    </Switch>
  );
};

export default OnboardingContainer;
