import React, { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { useRouter, useForm } from "hooks";
import styles from "./user-form.module.scss";
import localStrings from "localization";
import initialFormState from "./initial-form-state";
import classnames from "classnames";
import { Gender, BloodType, Mixpanel } from "enums";
import { OrganizationContext } from "contexts";
import * as moment from "moment";
import { countryDialingCodeOptions } from "utils/countries";
import { uploadImage } from "api/storage";
import { AccountStatus } from "enums";
import localTranslation, { LocalTranslation } from "localization/localization";
import path from "path/path";
import mixpanel from "mixpanel-browser";
import { Icon, Checkbox, Modal } from "atomic/atoms";
import {
  BackLink,
  InputField,
  Button,
  DatePickerField,
  SelectField,
  Panel,
  TextareaField,
  Toast,
  Avatar,
  UploadField,
} from "atomic/molecules";
import { DiscardModal } from "atomic/organisms";

const UserForm = ({ onSubmit, data, loading, create, deleteUser, submitting }) => {
  const { organizationName } = useContext(OrganizationContext);
  const [existingEmails, setExistingEmails] = useState([]);
  const [existingDeviceUid, setExistingDeviceUid] = useState({
    existing: [],
    notExisting: [],
    noOrganization: [],
  });
  const form = useForm(initialFormState);
  const {
    isFormValid,
    formFieldsValue,
    formFields,
    setFormFieldValue,
    setFormFieldValues,
    submit,
    setCustomField,
    isFormDirty,
    setRequiredField,
    disable: disableForm,
    isEnabled,
  } = form;
  const { history } = useRouter();
  const genderOptions = [
    {
      text: "Male",
      value: Gender.Male,
    },
    {
      text: "Female",
      value: Gender.Female,
    },
    {
      text: "Other",
      value: Gender.Other,
    },
  ];
  const countryCodes = countryDialingCodeOptions();
  const { firstName, lastName, avatar } = formFieldsValue;
  const [avatarLoading, setAvatarLoading] = useState(false);

  const bloodTypeOptions = Object.keys(BloodType).map((bloodType) => {
    return {
      value: BloodType[bloodType],
      text: BloodType[bloodType],
    };
  });
  const [discardModal, setDiscardModal] = useState(false);
  const [discardDeviceUidModal, setDiscardDeviceUidModal] = useState(false);
  const [iAgree, setIAgree] = useState(false);
  const [iAuthorize, setIAuthorize] = useState(false);

  const onSave = () => {
    submit();
    if (isFormValid) {
      const obj = () => {
        const params = {};
        [
          "avatar",
          "firstName",
          "lastName",
          "email",
          "phoneCountryCode",
          "phone",
          "address",
          "gender",
          "bloodType",
          "height",
          "weight",
          "employmentStatus",
          "company",
          "workCountryCode",
          "workPhone",
          "workAddress",
          "contactName",
          "contactRelationship",
          "contactCountryCode",
          "contactNumber",
          "deviceUid",
        ].forEach((key) => {
          if (formFieldsValue[key]) {
            params[key] = formFieldsValue[key];
          }
        });
        if (formFieldsValue.birthdate) {
          params.dob = moment(formFieldsValue.birthdate).format("MM/DD/YYYY");
        }
        if (params.weight) {
          params.weight = Number(params.weight);
        }
        if (params.height) {
          params.height = Number(params.height);
        }
        return params;
      };
      const params = obj();
      if (!Object.keys(params).length) {
        Toast({
          content: localStrings.pleaseFillUpUser,
          error: true,
        }).open();
        return false;
      }
      onSubmit(
        params,
        () => {
          Toast({
            content: create ? localStrings.newUserAdded : localStrings.changesSaved,
            success: true,
          }).open();
          returnToUsers();
        },
        (err) => {
          const code = err && err.metadata ? err.metadata.code : "";
          if (code === "8014") {
            return setExistingEmails((prev) => [...prev, formFields.email.value]);
          }
          if (code === "8006") {
            return setExistingDeviceUid((prev) => ({
              ...prev,
              notExisting: [...prev.notExisting, formFields.deviceUid.value],
            }));
          }
          if (code === "8012") {
            return setExistingDeviceUid((prev) => ({
              ...prev,
              noOrganization: [...prev.noOrganization, formFields.deviceUid.value],
            }));
          }
          if (code === "8005") {
            return setExistingDeviceUid((prev) => ({
              ...prev,
              existing: [...prev.existing, formFields.deviceUid.value],
            }));
          }
        }
      );
    }
  };

  const handleUploadAvatar = (res) => {
    const { file, onSuccess, onError } = res;
    uploadImage({ file })
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  };

  useEffect(() => {
    setCustomField(existingDeviceUid, "deviceUid");
  }, [existingDeviceUid, setCustomField]);

  useEffect(() => {
    setCustomField(existingEmails, "email");
  }, [existingEmails, setCustomField]);

  const onCancel = () => {
    if (isFormDirty) {
      setDiscardModal(true);
      return false;
    }
    mixpanel.track(Mixpanel.CANCEL_ADD_MEMBER);
    returnToUsers();
  };

  const returnToUsers = () => {
    history.push(path.MEMBERS);
  };

  const handleChangeEmergency = (value = "", name) => {
    const obj = {};

    const keys = ["contactName", "contactRelationship", "contactCountryCode", "contactNumber"];
    keys.forEach((key) => {
      if (formFieldsValue[key]) {
        obj[key] = formFieldsValue[key];
      }
    });
    if (value) {
      obj[name] = value;
    } else {
      delete obj[name];
    }
    keys.forEach((key) => {
      setRequiredField(Object.keys(obj).length, key);
    });
  };

  const beforeUpload = useCallback((file) => {
    const isJpgOrPng = ["image/jpeg", "image/png", "image/gif"].includes(file.type);
    const isLt1M = file.size / 1024;
    const limit = isLt1M >= 4096;
    if (!isJpgOrPng || limit) {
      Toast({
        content: !isJpgOrPng
          ? localStrings.youCanOnlyUploadJpgPngGifFile
          : localStrings.unbaleToUploadMoreThanMb,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
    return isJpgOrPng && !limit;
  }, []);

  const handleChangeAvatar = (data, name) => {
    const { status, response } = data.file;

    if (status === "uploading") {
      setAvatarLoading(true);
      return;
    }

    if (status === "done") {
      const avatar = response.distributionUrl;
      setAvatarLoading(false);
      // Get this url from response in real world.
      setFormFieldValue(avatar, name);
    }
  };

  const handleRemoveAvatar = () => {
    setFormFieldValue("", "avatar");
  };

  useEffect(() => {
    if (data) {
      const { avatar, lastName, firstName, email, phoneCountryCode, phone, dob, gender } = data;
      const userDetail = data.userDetail || {};
      const {
        address,
        employmentStatus,
        company,
        workAddress,
        workPhone,
        contactName,
        contactRelationship,
        workCountryCode,
        contactNumber,
        height,
        weight,
        bloodType,
        contactCountryCode,
      } = userDetail;
      setFormFieldValues({
        avatar,
        lastName,
        firstName,
        email,
        phoneCountryCode,
        phone,
        birthdate: dob ? moment(dob, "MM/DD/YYYY") : null,
        address,
        gender,
        employmentStatus,
        company,
        workAddress,
        workPhone,
        contactName,
        contactRelationship,
        workCountryCode,
        contactNumber,
        height,
        weight,
        bloodType,
        contactCountryCode,
        deviceUid: data?.userDevice?.deviceUid,
      });
    }
  }, [data, setFormFieldValues]);

  const isEditable = useMemo(() => {
    return (
      data &&
      [AccountStatus.PROFILE_ONLY, AccountStatus.UNVERIFIED_MEMBER].includes(data.accountStatus)
    );
  }, [data]);

  useEffect(() => {
    if (!isEditable && data && isEnabled && !create && !loading) {
      disableForm();
    }
  }, [disableForm, isEditable, isEnabled, data, create, loading]);

  return (
    <div className={styles.container}>
      <DiscardModal
        show={discardModal}
        discardChanges={returnToUsers}
        setDiscardModal={setDiscardModal}
      />
      <BackLink onClick={onCancel} text={localStrings.members} />
      <div className={styles.header}>
        <h2 className={styles.title}>
          {create ? localStrings.addMember : localStrings.editMember}
        </h2>
        <p>{localStrings.pleaseRequestThePersonProfile}</p>
      </div>

      <div>
        <div className={styles.form}>
          <div className="title">
            <h3>{localStrings.basicInfo}</h3>
          </div>
          <Panel className={styles.panel} loading={loading}>
            {/* <Message className={styles.message} visible={!!message} error>
              <Icon className={styles.warningIcon} name="info-fill" />
              {message}
            </Message> */}
            <div className={styles.avatarContainer}>
              <Avatar
                className={styles.avatar}
                phrases={[firstName, lastName]}
                image={avatar}
                loading={avatarLoading}
              />
              <div className={styles.avatarActions}>
                <UploadField
                  name="avatar"
                  className={styles.uploadContainer}
                  beforeUpload={beforeUpload}
                  onChange={handleChangeAvatar}
                  customRequest={handleUploadAvatar}
                >
                  <Button
                    className={styles.uploadButton}
                    disabled={formFields.firstName.disabled}
                    text={avatar ? localStrings.changePhoto : localStrings.uploadPhoto}
                  />
                </UploadField>
                {avatar && (
                  <Button
                    className={styles.removePhoto}
                    onClick={handleRemoveAvatar}
                    tertiary
                    text={localStrings.remove}
                  />
                )}
                <p className={styles.uploadGuide}>JPG, GIF or PNG. Max size of 4MB</p>
              </div>
            </div>
            <div className={styles.input}>
              <InputField
                name="firstName"
                label={localStrings.firstName}
                onChange={setFormFieldValue}
                {...formFields.firstName}
                placeholder="John"
              />
            </div>
            <div className={styles.input}>
              <InputField
                name="lastName"
                label={localStrings.lastName}
                onChange={setFormFieldValue}
                {...formFields.lastName}
                placeholder="Doe"
              />
            </div>
          </Panel>
        </div>
        <div className={styles.form}>
          <div className="title">
            <h3>{localStrings.about}</h3>
          </div>
          <Panel className={styles.panel} loading={loading}>
            <div className={styles.input}>
              <InputField
                trim
                name="email"
                label={localStrings.emailAddress}
                onChange={setFormFieldValue}
                {...formFields.email}
                placeholder="johndoe@example.com"
              />
            </div>
            <div className={styles.input}>
              <span className={classnames(styles.compLabel, "compLabel")}>
                {localStrings.mobileNumber}
              </span>
              <div className={styles.inputMobileNumber}>
                <div>
                  <SelectField
                    showSearch
                    classNameContainer={styles.mobileNumber}
                    data={countryCodes}
                    name="phoneCountryCode"
                    onChange={(value, name) => {
                      setFormFieldValue(value, name);
                      setRequiredField(value, "phone");
                    }}
                    {...formFields.phoneCountryCode}
                    placeholder="+63"
                  />
                </div>
                <div>
                  <InputField
                    name="phone"
                    onChange={(value, name) => {
                      setFormFieldValue(value, name);
                      setRequiredField(value, "phoneCountryCode");
                    }}
                    {...formFields.phone}
                    placeholder="916 4567 890"
                  />
                </div>
              </div>
            </div>
            <div className={classnames(styles.input, styles.address)}>
              <TextareaField
                name="address"
                label={localStrings.permanentResidence}
                onChange={setFormFieldValue}
                {...formFields.address}
                placeholder={localStrings.addressPlaceholder}
              />
            </div>
            <div className={styles.input}>
              <DatePickerField
                name="birthdate"
                label={localStrings.birthdate}
                onChange={setFormFieldValue}
                {...formFields.birthdate}
                placeholder="MM / DD / YYYY"
              />
            </div>
            <div className={styles.input}>
              <SelectField
                data={genderOptions}
                name="gender"
                label={localStrings.gender}
                onChange={setFormFieldValue}
                {...formFields.gender}
                placeholder={localStrings.chooseGender}
              />
            </div>
            <div className={styles.input}>
              <SelectField
                data={bloodTypeOptions}
                name="bloodType"
                label={localStrings.bloodType}
                onChange={setFormFieldValue}
                {...formFields.bloodType}
                placeholder={localStrings.chooseBloodType}
              />
            </div>
            <div className={styles.input}>
              <InputField
                name="height"
                label={localStrings.heightInCm}
                onChange={setFormFieldValue}
                {...formFields.height}
                renderSuffix={<div>cm</div>}
                maxLength={6}
              />
            </div>
            <div className={styles.input}>
              <InputField
                name="weight"
                label={localStrings.weightInKg}
                onChange={setFormFieldValue}
                {...formFields.weight}
                renderSuffix={<div>kg</div>}
                maxLength={6}
              />
            </div>
          </Panel>
        </div>
        <div className={styles.form}>
          <div className="title">
            <h3>{localStrings.employeeDetails}</h3>
          </div>
          <Panel className={styles.panel} loading={loading}>
            <div className={styles.input}>
              <SelectField
                data={[
                  {
                    text: localStrings.worker,
                    value: localStrings.worker,
                  },
                  {
                    text: localStrings.employed,
                    value: localStrings.employed,
                  },
                  {
                    text: localStrings.selfEmployed,
                    value: localStrings.selfEmployed,
                  },
                  {
                    text: localStrings.unemployed,
                    value: localStrings.unemployed,
                  },
                ]}
                name="employmentStatus"
                label={localStrings.employmentStatus}
                onChange={setFormFieldValue}
                {...formFields.employmentStatus}
                placeholder={localStrings.selectEmploymentStatus}
              />
            </div>
            <div className={styles.input}>
              <InputField
                name="company"
                label={localStrings.company}
                onChange={setFormFieldValue}
                {...formFields.company}
                placeholder={localStrings.companyPlaceholder}
              />
            </div>
            <div className={styles.input}>
              <span className={classnames(styles.compLabel, "compLabel")}>
                {localStrings.workContactNumber}
              </span>
              <div className={styles.inputMobileNumber}>
                <SelectField
                  showSearch
                  classNameContainer={classnames(styles.mobileNumber)}
                  data={countryCodes}
                  name="workCountryCode"
                  onChange={(value, name) => {
                    setFormFieldValue(value, name);
                    setRequiredField(value, "workPhone");
                  }}
                  {...formFields.workCountryCode}
                  placeholder="+63"
                />
                <InputField
                  name="workPhone"
                  onChange={(value, name) => {
                    setFormFieldValue(value, name);
                    setRequiredField(value, "workCountryCode");
                  }}
                  {...formFields.workPhone}
                  placeholder="916 4567 890"
                />
              </div>
            </div>
            <div className={classnames(styles.input, styles.address)}>
              <TextareaField
                name="workAddress"
                label={localStrings.workAddress}
                onChange={setFormFieldValue}
                {...formFields.workAddress}
                placeholder={localStrings.addressPlaceholder}
              />
            </div>
          </Panel>
        </div>
        <div className={styles.form}>
          <div className="title">
            <h3>{localStrings.emergencyContactPerson}</h3>
          </div>
          <Panel className={styles.panel} loading={loading}>
            <div className={styles.input}>
              <InputField
                name="contactName"
                label={localStrings.contactPersonsName}
                onChange={(value, name) => {
                  setFormFieldValue(value, name);
                  handleChangeEmergency(value, name);
                }}
                {...formFields.contactName}
                placeholder={localStrings.fullName}
              />
            </div>
            <div className={styles.input}>
              <InputField
                name="contactRelationship"
                label={localStrings.contactRelationship}
                onChange={(value, name) => {
                  setFormFieldValue(value, name);
                  handleChangeEmergency(value, name);
                }}
                {...formFields.contactRelationship}
                placeholder={localStrings.relationshipPlaceholder}
              />
            </div>
            <div className={styles.input}>
              <span className={classnames(styles.compLabel, "compLabel")}>
                {localStrings.mobileNumber}
              </span>
              <div className={styles.inputMobileNumber}>
                <SelectField
                  showSearch
                  classNameContainer={classnames(styles.mobileNumber)}
                  data={countryCodes}
                  name="contactCountryCode"
                  onChange={(value, name) => {
                    setFormFieldValue(value, name);
                    handleChangeEmergency(value, name);
                  }}
                  {...formFields.contactCountryCode}
                  placeholder="+63"
                />
                <InputField
                  name="contactNumber"
                  onChange={(value, name) => {
                    setFormFieldValue(value, name);
                    handleChangeEmergency(value, name);
                  }}
                  {...formFields.contactNumber}
                  placeholder="916 4567 890"
                />
              </div>
            </div>
          </Panel>
        </div>
        {create && (
          <div className={styles.form}>
            <div className="title">
              <h3>{localStrings.accessory}</h3>
            </div>
            <Panel className={styles.panel} loading={loading}>
              <div className={styles.input}>
                <InputField
                  name="deviceUid"
                  label={localStrings.accessoryOrMacAddress}
                  onChange={setFormFieldValue}
                  {...formFields.deviceUid}
                  placeholder="e.g. ‟000a959d6816”"
                  renderSuffix={
                    data?.userDevice?.deviceUid && !data?.profileId ? (
                      <Icon
                        name={formFields.deviceUid.value ? "close" : ""}
                        onClick={() => {
                          setDiscardDeviceUidModal(true);
                        }}
                      />
                    ) : null
                  }
                />
              </div>
            </Panel>
          </div>
        )}
        {create && (
          <div className={styles.form}>
            <div className="title">
              <h3>{localStrings.authorization}</h3>
            </div>
            <Panel className={styles.panel} loading={loading}>
              <div className={styles.authorizationHeader}>{localStrings.asThePersonDescribed}</div>
              <div>
                <div className={styles.authField}>
                  <Checkbox
                    className={styles.authCheckbox}
                    onChange={() => {
                      setIAgree(!iAgree);
                    }}
                  />
                  <div className={styles.authDesc}>
                    <LocalTranslation
                      text={localStrings.iAgreeToPouchTerms}
                      items={[
                        <a
                          rel="noopener noreferrer"
                          href={path.BUSINESS_TERMS}
                          className={styles.termsLink}
                          target="_blank"
                        >
                          {localStrings.termsAndConditions}
                        </a>,
                        <a
                          rel="noopener noreferrer"
                          href={path.BUSINESS_PRIVACY}
                          className={styles.termsLink}
                          target="_blank"
                        >
                          {localStrings.privacyPolicy}.
                        </a>,
                      ]}
                    />
                  </div>
                </div>
                <div className={styles.authField}>
                  <Checkbox
                    className={styles.authCheckbox}
                    onChange={() => {
                      setIAuthorize(!iAuthorize);
                    }}
                  />
                  <div
                    className={styles.authDesc}
                    dangerouslySetInnerHTML={{
                      __html: localTranslation("iAuthorizeOrg", [organizationName || "-"]),
                    }}
                  ></div>
                </div>
              </div>
            </Panel>
          </div>
        )}
        <div className={styles.form}>
          <div className={styles.blockquote}>{localStrings.allTheInfoCollectedProfile}</div>
        </div>
      </div>
      <div className={styles.submit}>
        {!create && isEditable && (
          <Button danger onClick={deleteUser}>
            {localStrings.deleteMember}
          </Button>
        )}
        <Button tertiary className={styles.cancel} onClick={onCancel}>
          {localStrings.cancel}
        </Button>
        <Button
          onClick={onSave}
          disabled={(!isEditable && !create) || (create && (!iAgree || !iAuthorize))}
          loading={submitting}
        >
          {create ? localStrings.addMember : localStrings.saveChanges}
        </Button>
      </div>
      <Modal
        title={localStrings.askRemoveAccessory}
        visible={discardDeviceUidModal}
        onCancel={() => {
          setDiscardDeviceUidModal(false);
        }}
      >
        {localStrings.removeAccessoryDesc}
        <div className={styles.submitModal}>
          <Button
            text={localStrings.cancel}
            tertiary
            onClick={() => {
              setDiscardDeviceUidModal(false);
            }}
          />
          <Button
            danger
            text={localStrings.removeAccessory}
            onClick={() => {
              setFormFieldValue("", "deviceUid");
              setDiscardDeviceUidModal(false);
            }}
          />
        </div>
      </Modal>
    </div>
  );
};

export default UserForm;
