import React from "react";
import { Select, Icon, Loading } from "atomic/atoms";
import styles from "./select-field.module.scss";
import classnames from "classnames";

const SelectField = ({
  className,
  label,
  error = {},
  message,
  children,
  basic,
  classNameContainer,
  suffixIcon,
  loading,
  showRequired,
  dropdownClassName,
  ...selectProps
}) => {
  return (
    <div
      className={classnames(styles.field, className, {
        [`${styles.error}`]: error.status,
        [`${styles.basic}`]: basic,
        error: error.status,
      })}
    >
      {label && <label className={classnames(styles.label, "label")}>{label}</label>}
      {loading ? (
        <Loading />
      ) : (
        <div className={classnames(classNameContainer, styles.container, "selectContainer")}>
          <Select
            dropdownClassName={classnames(styles.dropdown, dropdownClassName)}
            suffixIcon={suffixIcon || <Icon name="down-chevron-thick" />}
            {...selectProps}
          >
            {children}
          </Select>
          {showRequired && (
            <span className={styles.required}>
              <Icon className={styles.requiredIcon} name="asterisk" />
            </span>
          )}
        </div>
      )}
      {error.message || message ? (
        <span className={styles.message}>{error.message || message}</span>
      ) : null}
    </div>
  );
};

export default SelectField;
