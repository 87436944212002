import React, { useCallback, useContext, useState } from "react";
import { UserContext } from "contexts";
import { useApi, useForm, useMount } from "hooks";
import { validateEmail, updateAccount } from "api/user";
import { accountSettingsFormState } from "states";
import { SettingsLayout } from "layouts";
import localString from "localization";
import { AccountSettings } from "components/settings";
import { Toast } from "atomic/molecules";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "enums";

const AccountSettingsContainer = () => {
  const [activeForm, setActiveForm] = useState(null);
  const [otpModal, setOtpModal] = useState(false);
  const [error, setError] = useState(false);

  const { user, updateUser } = useContext(UserContext);
  const {
    isFormValid,
    formFieldsValue,
    formFields,
    setFormFieldValue,
    setFormFieldValues,
    setCustomField,
  } = useForm(accountSettingsFormState());

  const { request, loading } = useApi({
    api: updateAccount,
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
  });

  const forms = {
    Email: "email",
    Mobile: "mobile",
    Password: "password",
  };

  useMount(() => {
    setFormFieldValues(
      {
        email: user.email,
        phone: user.phone,
        phoneCountryCode: user.phoneCountryCode,
      },
      { validate: false }
    );
  });

  const showOtpModal = useCallback(async () => {
    if (isFormValid && formFieldsValue.email !== user.email) {
      try {
        const res = await validateEmail({ email: formFieldsValue.email });
        if (res) {
          setError(localString.emailAlreadyExists);
        } else {
          setOtpModal(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [isFormValid, formFieldsValue.email, user.email]);

  const handleCloseOTPModal = useCallback(() => {
    setOtpModal(false);
  }, []);

  const handleEmailChange = useCallback(
    (value, name) => {
      setFormFieldValue(value, name);
      setError(false);
    },
    [setFormFieldValue]
  );

  const submitCb = useCallback(async () => {
    if (isFormValid) {
      const obj = () => {
        const params = {};
        ["email", "phoneCountryCode", "phone"].forEach((key) => {
          if (formFieldsValue[key]) {
            params[key] = formFieldsValue[key];
          }
        });
        return params;
      };
      const params = obj();
      if (activeForm !== forms.Email) {
        params.email = user.email;
      }

      try {
        const res = await request(params);
        updateUser(res);

        if (activeForm === forms.Email) {
          mixpanel.track(Mixpanel.UPDATE_EMAIL);
        }

        if (activeForm === forms.Mobile) {
          mixpanel.track(Mixpanel.UPDATE_MOBILE_NUMBER);
        }

        Toast({
          active: true,
          content: localString.changesSaved,
          success: true,
          icon: "check-fill",
        }).open();
        setActiveForm(null);
      } catch (err) {
        console.log(err);
        if (err.metadata && err.metadata.code) {
          const errors = {
            2004: () => {
              setCustomField([formFieldsValue.email], "email");
            },
          };
          if (errors[err.metadata.code]) {
            return errors[err.metadata.code]();
          }
        }
      }
    }
  }, [activeForm, formFieldsValue, forms, isFormValid, request, setCustomField, user, updateUser]);

  const otpCallback = useCallback(() => {
    setOtpModal(false);
    submitCb();
  }, [submitCb]);

  return (
    <SettingsLayout title={localString.accountSettings}>
      <AccountSettings
        onChange={handleEmailChange}
        user={user}
        formFieldValues={formFieldsValue}
        activeForm={activeForm}
        setActiveForm={setActiveForm}
        formFields={formFields}
        setFormFieldValue={setFormFieldValue}
        onSubmit={submitCb}
        submitting={loading}
        forms={forms}
        otpModal={otpModal}
        showOtpModal={showOtpModal}
        handleCloseOTPModal={handleCloseOTPModal}
        otpCallback={otpCallback}
        error={error}
      />
    </SettingsLayout>
  );
};

export default AccountSettingsContainer;
