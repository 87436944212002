import * as moment from "moment";

export const getUserRequestParam = (form) => {
  const params = {};
  [
    "avatar",
    "firstName",
    "lastName",
    "email",
    "phoneCountryCode",
    "phone",
    "address",
    "gender",
    "bloodType",
    "height",
    "weight",
    "employmentStatus",
    "company",
    "workCountryCode",
    "workPhone",
    "workAddress",
    "contactName",
    "contactRelationship",
    "contactCountryCode",
    "contactNumber",
    "deviceUid",
  ].forEach((key) => {
    if (form[key]) {
      params[key] = form[key];
    }
  });
  if (form.birthdate) {
    params.dob = moment(form.birthdate).format("MM/DD/YYYY");
  }
  if (params.weight) {
    params.weight = Number(params.weight);
  }
  if (params.height) {
    params.height = Number(params.height);
  }
  return params;
};
