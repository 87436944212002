import React, { useMemo } from "react";
import styles from "./invite-manager-form.module.scss";
import localStrings from "localization";
import classnames from "classnames";
import Suggestion from "./suggestion";
import { AccountStatus, Routes } from "enums";
import { v4 as uuidv4 } from "uuid";
import { Button, SelectField } from "atomic/molecules";
import PermissionTable from "./permission-table/permission-table";
import { useRouter } from "hooks";
import { Icon } from "atomic/atoms";
import { Panel, BackLink, InputSuggestion } from "atomic/molecules";

const InviteManagerForm = ({
  create = true,
  goBack,
  changeEmail,
  formFields,
  suggestions,
  fetchingUsers,
  setFormFieldValue,
  submit,
  clickSuggestion,
  validatingEmail,
  invitingUser,
  onEmailBlur,
  onEmailFocus,
  error,
}) => {
  const { history } = useRouter();

  const preparedSuggestion = useMemo(() => {
    return suggestions
      ?.filter((suggestion) => {
        const { accountStatus } = suggestion;
        return (
          accountStatus === AccountStatus.VERIFIED_MEMBER ||
          accountStatus === AccountStatus.UNVERIFIED_MEMBER ||
          accountStatus === AccountStatus.PENDING_MEMBER
        );
      })
      .map((data) => {
        return <Suggestion onClick={() => clickSuggestion(data)} key={uuidv4()} {...data} />;
      });
  }, [suggestions, clickSuggestion]);

  return (
    <div className={styles.container}>
      <BackLink onClick={goBack} text={localStrings.teamAndRoles} />
      <div className={styles.header}>
        <h2 className={styles.title}>
          {create ? localStrings.inviteNewManager : localStrings.editManager}
        </h2>
      </div>
      <div className={styles.form}>
        <h3 className={styles.panelTitle}>{localStrings.basicInformation}</h3>
        <Panel className={styles.panel}>
          <InputSuggestion
            {...formFields.email}
            showSuggestionOnFocus={false}
            showRequired
            name="email"
            className={classnames(styles.formField, styles.formFieldEmail)}
            label={localStrings.emailAddress}
            placeholder={localStrings.enterAnEmailAddress}
            onChange={changeEmail}
            suggestions={preparedSuggestion}
            suggestionLoading={fetchingUsers || validatingEmail}
            renderSuffix={fetchingUsers ? <Icon loading={true} /> : <span />}
            onBlur={onEmailBlur}
            onFocus={onEmailFocus}
          />
          {error && <p className={styles.error}>{error}</p>}
        </Panel>
      </div>
      <div className={styles.form}>
        <h3 className={styles.panelTitle}>{localStrings.roleAndPermissions}</h3>
        <Panel className={styles.panel}>
          <SelectField
            {...formFields.role}
            name="role"
            className={classnames(styles.formField, styles.roleField)}
            label={localStrings.role}
            placeholder={localStrings.selectARole}
            onChange={setFormFieldValue}
            data={[
              {
                text: "Admin",
                value: "MANAGER",
              },
            ]}
          />
          <PermissionTable />
        </Panel>
      </div>
      <div className={styles.actions}>
        <Button
          tertiary
          text={localStrings.cancel}
          onClick={() => {
            history.push(`/${Routes.SETTINGS}/${Routes.TEAMS_AND_ROLES}`);
          }}
        />
        <Button
          text={localStrings.sendInvite}
          onClick={submit}
          loading={invitingUser || validatingEmail}
          disabled={validatingEmail || error}
        />
      </div>
    </div>
  );
};

export default InviteManagerForm;
