import React, { useState, useMemo, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { getInactiveDetails } from "api/user-data";
import { useMount } from "hooks";
import { OrganizationContext } from "contexts";
import useApi from "hooks/useApi";
import { formatToLocalDate, ellipsis } from "utils";
import styles from "../overview.module.scss";
import localStrings from "localization";
import AllUserActive from "images/all-user-active-2.svg";
import { Common, Routes } from "enums";
import { Details } from "atomic/molecules";
import { environment } from "../../../environments/environment";

const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "name",
    title: localStrings.name,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "lastSynced",
    title: localStrings.lastSynced,
    width: 100,
  },
];
const noData = {
  title: localStrings.goodJobAllUserActive,
  description: localStrings.goodJobAllUserActiveDesc,
  image: AllUserActive,
};
const InactiveDetailsContainer = ({ tempType }) => {
  const [hasError, setHasError] = useState(false);
  const { organizationId } = useContext(OrganizationContext);

  const { request, loading, result = { data: [], total: 0 } } = useApi({
    api: getInactiveDetails,
  });

  useMount(() => {
    const init = async () => {
      try {
        await request({ organizationId });
      } catch (error) {
        setHasError(true);
      }
    };
    init();
  });

  const preparedData = useMemo(() => {
    const { data } = result;

    return data.map((d) => {
      const { firstName, lastName, lastDateTimeSynced, privateKeys, userId, email } = d;
      const isPrivateName = privateKeys?.includes("firstName");
      const name = () => {
        const fullName = `${firstName || ""} ${lastName || ""}`.trim();
        if (isPrivateName) {
          return `#${userId}`;
        }
        if (fullName) {
          return ellipsis(fullName, 50);
        }
        if (email) {
          return email;
        }
        return "-";
      };
      return {
        key: uuidv4(),
        name: name(),
        lastSynced: formatToLocalDate(lastDateTimeSynced),
      };
    });
  }, [result]);

  const title = useMemo(() => {
    if (
      environment.id === "dev" ||
      environment.id === "staging" ||
      (environment.id === "production" && organizationId === 88)
    ) {
      return localStrings.violationsCases;
    }
    return localStrings.inactive;
  }, [organizationId]);

  return (
    <Details
      className={styles.detail}
      title={title}
      viewAllText={localStrings.viewAll}
      viewAllCount={result.total}
      viewAllLink={`/${Routes.MEMBERS}`}
      viewAllLocationState={{ deviceStatus: Common.INACTIVE, pairingStatus: Common.PAIRED }}
      loading={loading}
      data={preparedData}
      error={hasError}
      noData={noData}
      columns={tableColumns}
    />
  );
};

export default InactiveDetailsContainer;
