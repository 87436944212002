import React from "react";
import { deleteSymptomLog } from "api/symptom";
import { useConfirmationModal } from "components/common";
import { useApi } from "hooks";
import localStrings from "localization";

const useDeleteSymptoms = ({ refresh }) => {
  const { request: deleteSymptomLogApi, loading: deleting } = useApi({
    api: deleteSymptomLog,
  });
  const { show, render, close: closeConfirmDeleteSymptom } = useConfirmationModal({
    title: localStrings.deleteSymptomsLog,
    content: <div>{localStrings.removeAccessoryDesc}</div>,
    onOkText: localStrings.delete,
    loading: deleting,
    onConfirm: ({ symptomId }) => {
      const deleteLog = async (symptomId) => {
        await deleteSymptomLogApi({ symptomId });
        await refresh();
      };
      try {
        deleteLog(symptomId);
      } finally {
        closeConfirmDeleteSymptom();
      }
    },
  });

  return {
    render,
    show,
  };
};

export default useDeleteSymptoms;
