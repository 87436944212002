import React from "react";
import styles from "./upload-profile-photo.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import { LocalTranslation } from "localization/localization";
import { Avatar, UploadField } from "atomic/molecules";

const UploadProfilePhoto = ({
  fullName,
  avatar,
  beforeUpload,
  handleChangeAvatar,
  handleUploadAvatar,
  uploadingImage,
  onboardingUser,
}) => {
  const uploadPhotoRender = (item) => {
    return (
      <UploadField
        className={styles.uploadStyle}
        beforeUpload={beforeUpload}
        onChange={handleChangeAvatar}
        customRequest={handleUploadAvatar}
      >
        {item}
      </UploadField>
    );
  };

  return (
    <div className={classnames(styles.container)}>
      <h1>{localStrings.startbyUploading}</h1>
      <p>{localStrings.easilyIdentifyYourProfile}</p>
      <div className={styles.content}>
        <div className={styles.upload}>
          {uploadPhotoRender(
            <Avatar
              loading={uploadingImage || onboardingUser}
              className={styles.avatar}
              image={avatar}
            />
          )}
          <div className={styles.uploadHover}>
            <p id="uploadAvatar">{avatar ? localStrings.replacePhoto : localStrings.uploadPhoto}</p>
          </div>
        </div>

        <h1>{fullName}</h1>
        {uploadPhotoRender(
          <p className={styles.description}>
            <LocalTranslation
              text={localStrings.uploadPhotoDescription}
              items={[<span className={styles.link}>{localStrings.browsingYourFiles}</span>]}
            />
          </p>
        )}
      </div>
    </div>
  );
};

export default UploadProfilePhoto;
