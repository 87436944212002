import React, { useState, Fragment, useContext } from "react";
import styles from "./forgot-password-form.module.scss";
import localStrings from "localization";
import classnames from "classnames";
import { useForm, useRouter } from "hooks";
import initialFormFields from "./initial-form-field";
import { AppContext } from "contexts";
import { submitForgotPassword } from "./forgot-password-service";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "enums";
import { Message, Link } from "atomic/atoms";
import { InputField, Button, Toast } from "atomic/molecules";

const Title = ({ isSubmitted }) => (
  <h2 className={styles.title}>
    {isSubmitted ? `${localStrings.resetLinkSent}!` : `${localStrings.forgotPassword}?`}
  </h2>
);

const SubmittedForm = ({ submit, loading }) => {
  const { history } = useRouter();
  return (
    <Fragment>
      <p className={styles.desc}>{localStrings.pleaseCheckYourEmailAndClickMessage}</p>
      <Button
        className={classnames(styles.action, styles.backToLoginSubmitted)}
        text={localStrings.backToLogin}
        onClick={() => history.replace("/")}
      />
      <p>
        {localStrings.didntReceiveAnything}{" "}
        <Link
          loading={loading}
          className={classnames(styles.action, styles.resendNow)}
          text={localStrings.resendNow}
          onClick={submit}
        />
      </p>
    </Fragment>
  );
};

const DefaultForm = ({ errorMsg, formFields, setFormFieldValue, loading }) => {
  return (
    <Fragment>
      <Message error className={styles.message} visible={!!errorMsg} text={errorMsg} />
      <InputField
        label={localStrings.emailAddress}
        placeholder={localStrings.yourEmailAddress}
        className={styles.email}
        name="email"
        onChange={setFormFieldValue}
        {...formFields.email}
      />
      <Button
        loading={loading}
        className={classnames(styles.action, styles.sendResetLink)}
        text={localStrings.sendResetLink}
        htmlType="submit"
      />
      <Link
        className={classnames(styles.action, styles.backToLogin)}
        to="/"
        text={localStrings.backToLogin}
      />
    </Fragment>
  );
};

const ForgotPasswordForm = () => {
  const { dispatch: appDispatch } = useContext(AppContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const { formFields, setFormFieldValue, submit } = useForm(initialFormFields, (formFields) => {
    mixpanel.track(Mixpanel.FORGOT_PASSWORD);
    submitForgotPassword({
      formFields,
      appDispatch,
      Toast,
      setIsSubmitted,
      setErrorMsg,
      setLoading,
    });
  });

  return (
    <div className={styles.container}>
      <form
        className={styles.form}
        onSubmit={(e) => {
          e.preventDefault();
          submit();
        }}
      >
        <Title isSubmitted={isSubmitted} />
        {isSubmitted ? (
          <SubmittedForm loading={loading} submit={submit} />
        ) : (
          <DefaultForm
            loading={loading}
            errorMsg={errorMsg}
            formFields={formFields}
            setFormFieldValue={setFormFieldValue}
          />
        )}
      </form>
    </div>
  );
};

export default ForgotPasswordForm;
