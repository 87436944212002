import React, { useMemo } from "react";
import styles from "./gateway-list.module.scss";
import localStrings from "localization";
import { Icon, Tooltip } from "atomic/atoms";
import classnames from "classnames";
import { GatewayStatus } from "enums";

import { v4 as uuidv4 } from "uuid";

const GatewayItemList = ({ children, className, onClick, status }) => {
  const gatewayStatus = {
    [GatewayStatus.CONNECTED]: localStrings.connected,
    [GatewayStatus.DISCONNECTED]: localStrings.disconnected,
    [GatewayStatus.NOT_CONFIGURED]: localStrings.notConfigured,
  };
  return (
    <div className={classnames(styles.itemList, className)} onClick={onClick}>
      <div className={styles.status}>
        <Icon name="wifi" className={styles.icon} />
        {status && (
          <Tooltip title={gatewayStatus[status]}>
            <div
              className={classnames(styles.statusIndentifier, {
                [`${styles.connected}`]: status === GatewayStatus.CONNECTED,
                [`${styles.disconnected}`]: status === GatewayStatus.DISCONNECTED,
                [`${styles.notConfigured}`]: status === GatewayStatus.NOT_CONFIGURED,
              })}
            ></div>
          </Tooltip>
        )}
      </div>
      <div className={styles.itemContent}>{children}</div>
    </div>
  );
};

const GatewayList = ({
  showCreateGateway,
  gateways,
  removeGateway,
  maxGateway,
  gatewayStatusState,
  reConfigureDeviceUid,
  reconfiguring,
}) => {
  const showAssignGateway = useMemo(() => {
    return maxGateway > gateways.length;
  }, [maxGateway, gateways]);

  return (
    <div>
      {gateways.map((gateway) => {
        return (
          <GatewayItemList status={gatewayStatusState} key={uuidv4()}>
            <div className={styles.gatewayItem}>
              <div>
                <div className={styles.gatewayLabel}>{gateway.label || "-"}</div>
                <div className={styles.macAddress}>{gateway.macAddress}</div>
              </div>

              <div className={styles.gatewayStatus}>
                {!reconfiguring && gatewayStatusState === GatewayStatus.NOT_CONFIGURED && (
                  <div className={styles.reconfigure} onClick={() => reConfigureDeviceUid()}>
                    {localStrings.reConfigure}
                  </div>
                )}
                <Icon
                  name="close"
                  className={styles.icon}
                  onClick={() => {
                    removeGateway(gateway);
                  }}
                  loading={reconfiguring}
                />
              </div>
            </div>
          </GatewayItemList>
        );
      })}
      {showAssignGateway && (
        <GatewayItemList
          className={styles.clickable}
          onClick={() => {
            showCreateGateway({});
          }}
        >
          <div className={styles.addGateway}>{localStrings.assignAGateway}</div>
        </GatewayItemList>
      )}
    </div>
  );
};

export default GatewayList;
