import React, { memo } from "react";
import { Upload } from "atomic/atoms";
import classnames from "classnames";
import styles from "./upload-field.module.scss";

const UploadField = ({ className, children, error, label, ...uploadProps }) => {
  return (
    <div
      className={classnames(styles.uploadDropBox, className, {
        [`${styles.error}`]: error,
      })}
    >
      {label && <label className={styles.label}>{label}</label>}
      <Upload {...uploadProps}>{children}</Upload>
    </div>
  );
};

export default memo(UploadField);
