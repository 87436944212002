import React, { useMemo } from "react";
import { NetworkError, ServerError, UnauthorizedError, InviteExpiredError } from "pages";

const Error = ({ error, redirectLink }) => {
  const renderErrorPage = useMemo(() => {
    if (error === "network") {
      return <NetworkError />;
    }
    if (error === "server") {
      return <ServerError />;
    }
    if (error === "unauthorized") {
      return <UnauthorizedError error={error} redirectLink={redirectLink} />;
    }
    if (error === "invite") {
      return <InviteExpiredError />;
    }
    return null;
  }, [error, redirectLink]);

  return renderErrorPage;
};

export default Error;
