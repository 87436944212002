import React from "react";
import styles from "./block.module.scss";
import classnames from "classnames";

const Block = ({ fixed, children, onClick, className }) => {
  return (
    <div
      onClick={onClick}
      className={classnames(styles.container, className, { [`${styles.fixed}`]: fixed })}
    >
      {children}
    </div>
  );
};

export default Block;
