import React from "react";
import { IconLink } from "atomic/molecules";
import styles from "./navigation-menu.module.scss";

const NavigationMenu = ({ tabs }) => (
  <nav className={styles.menu}>
    {tabs.length > 0 &&
      tabs.map((tab, i) => (
        <IconLink
          key={i}
          activeClassName={styles.active}
          className={styles.link}
          text={tab.text}
          to={tab.path}
          exact={tab.exact}
        />
      ))}
  </nav>
);

export default NavigationMenu;
