import React, { useMemo, useEffect, useState, useCallback } from "react";
import {
  getLocalItem,
  tellDuration,
  // formatDate,
} from "utils";
import styles from "./contact-history-details.module.scss";
import localStrings from "localization";
import { Modal, Icon, Pagination, Table } from "atomic/atoms";
import { Avatar } from "atomic/molecules";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import classnames from "classnames";
import { contactTracingIndividual } from "api/contact-tracing";
import { getFullName } from "utils";

// import {
//   prepareDocDefinition,
//   downloadPDF,
// } from "../contact-history-print/contact-history-print";

const columns = [
  {
    key: "dateOfContact",
    text: localStrings.dateOfContact,
  },
  {
    key: "duration",
    text: localStrings.duration,
  },
  {
    key: "location",
    text: localStrings.areaLocation,
  },
].map((c) => {
  return {
    key: uuidv4(),
    dataIndex: c.key,
    title: c.text,
  };
});
const pageSize = 10;

const ContactHistoryDetails = ({ modal, setModal, next, prev, dates, showUnknowUser, user }) => {
  // const { state } = useContext(ViewUserContext);
  const [loading, setLoading] = useState(true);
  const data = modal?.data || {};
  const { firstName, lastName, avatar, userId } = user;
  const userFullName = getFullName({ firstName, lastName });
  const place = modal?.place;
  const totalUsers = modal?.total;
  const first = place === 1;
  const last = place === totalUsers;
  const [contactData, setContactData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    contactUserId,
    contactFirstName,
    contactLastName,
    organizationId: userOrganizationId,
  } = data;
  const contactFullName = `${contactFirstName || ""} ${contactLastName || ""}`.trim();
  const otherOrg = !userOrganizationId;

  const fetchContactHistoryIndividual = useCallback(
    (page = 0) => {
      setLoading(true);
      contactTracingIndividual(contactUserId, {
        userId,
        organizationId: getLocalItem("organizationId"),
        page: page || currentPage,
        pageSize,
        startDate: dates[0].format("MM/DD/YYYY"),
        endDate: dates[1].format("MM/DD/YYYY"),
      })
        .then((res) => {
          const {
            data,
            metadata: { total },
          } = res;
          setContactData(data.contactTracing);
          setTotal(total);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [contactUserId, userId, currentPage, dates]
  );

  useEffect(() => {
    if (modal.active) {
      fetchContactHistoryIndividual();
    }
  }, [fetchContactHistoryIndividual, modal]);

  const tableData = useMemo(() => {
    return contactData.map((t) => {
      const { durationInSeconds, areaName, contactStartDate } = t;

      return {
        key: uuidv4(),
        duration: tellDuration(durationInSeconds),
        location: areaName || "-",
        dateOfContact: moment(contactStartDate).format("MMMM DD, YYYY h:mm a"),
      };
    });
  }, [contactData]);

  const onChangePage = useCallback(
    (currentPage) => {
      setCurrentPage(currentPage);
      fetchContactHistoryIndividual(currentPage);
    },
    [fetchContactHistoryIndividual]
  );

  // const handleOnClickExport = useCallback(() => {
  //   contactTracingIndividual(contactUserId, {
  //     userId,
  //     organizationId: getLocalItem("organizationId"),
  //   }).then((res) => {
  //     const { data } = res;
  //     const tableColumns = [
  //       "LAST DATE OF CONTACT",
  //       "DURATION",
  //       "LOCATION/AREA",
  //     ];
  //     const startDate = moment().subtract(30, "days");
  //     const endDate = moment();
  //     const tableColumsWidths = ["*", 80, "*"];
  //     const tableData = data.map((d) => {
  //       const { lastDateOfContact, durationInSeconds, areaName } = d;
  //       const lastContactDate = formatDate(
  //         lastDateOfContact,
  //         "MMM DD, YYYY hh:mm a"
  //       );
  //       const duration = tellDuration(durationInSeconds);
  //       return [
  //         { text: lastContactDate, margin: [0, 16] },
  //         { text: duration, margin: [0, 16] },
  //         { text: areaName, margin: [0, 16] },
  //       ];
  //     });
  //     const def = prepareDocDefinition({
  //       title: `${getFullName({ firstName, lastName })} & ${contactFullName}`,
  //       tableColumns,
  //       tableColumsWidths,
  //       tableData,
  //       startDate,
  //       endDate,
  //     });
  //     downloadPDF(def);
  //   });
  // }, [firstName, lastName, contactFullName, contactUserId, userId]);

  return (
    <Modal
      className={styles.modal}
      title={localStrings.contactHistory}
      visible={modal.active}
      onCancel={() => {
        setModal(() => ({
          active: false,
          data: null,
        }));
      }}
    >
      <div className={styles.container}>
        <div className={styles.header}>
          {/* <Button
            tertiary
            className={styles.export}
            text={"Export"}
            icon={{
              name: "export",
            }}
            onClick={handleOnClickExport}
          /> */}
          <div className={styles.users}>
            <div className={styles.usersContainer}>
              <div>
                <Avatar className={styles.avatar} image={avatar} phrases={[firstName, lastName]} />
                <div className={styles.userName}>{userFullName}</div>
              </div>
            </div>
            <div className={styles.and}>{localStrings.and}</div>
            <div className={styles.usersContainer}>
              <div className={classnames(styles.left, first ? "" : styles.active)}>
                <Icon
                  name="left-chevron"
                  onClick={
                    !first
                      ? () => {
                          setCurrentPage(1);
                          prev();
                        }
                      : null
                  }
                />
              </div>
              <div>
                <Avatar
                  className={styles.avatar}
                  image={data.avatar}
                  phrases={[contactFirstName, contactLastName]}
                />
                <div
                  className={classnames(styles.userName, styles.contactUsers)}
                  onClick={() => {
                    if (otherOrg) {
                      showUnknowUser({
                        visible: true,
                        userId: contactUserId,
                      });
                      return false;
                    }
                    window.location.href = `/users/${contactUserId}`;
                  }}
                >
                  {contactFullName}
                </div>
              </div>
              <div className={classnames(styles.right, last ? "" : styles.active)}>
                <Icon
                  name="right-chevron"
                  onClick={
                    !last
                      ? () => {
                          setCurrentPage(1);
                          next();
                        }
                      : null
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className={styles.content}>
          <Table columns={columns} data={tableData} loading={loading} />
          <Pagination
            className={styles.pagination}
            total={total}
            defaultPageSize={pageSize}
            pageSize={pageSize}
            showSizeChanger={false}
            onChange={onChangePage}
            currentPage={currentPage}
          />
        </div>
      </div>
    </Modal>
  );
};

export default ContactHistoryDetails;
