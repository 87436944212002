import React, { useContext } from "react";
import { Redirect } from "react-router-dom";
// import { Routes } from "enums";
import { UserContext, OrganizationContext } from "contexts";
import { useRouter, useApi, useMount } from "hooks";
import { setLocalItem } from "utils";
import { validateActivateToken } from "api/user-organization";

const RedirectRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(UserContext);
  const organization = useContext(OrganizationContext);
  const { query } = useRouter();
  const { email, organizationId = "", token = "", type = "" } = query || {};

  const { request, loading = !!token, result = {} } = useApi({
    api: () => {
      validateActivateToken({
        token,
      });
    },
  });

  useMount(() => {
    if (token) {
      const init = async () => {
        try {
          await request();
          console.log(result);
        } catch (e) {
          console.log(e);
        }
      };
      init();
    }
  });
  if (!organization && loading) {
    return "";
  }

  if (
    user?.email === email &&
    organizationId &&
    ((type === "REGISTRATION" && !result.isActive) || type === "ACCEPT_COMMUNITY")
  ) {
    setLocalItem("organizationId", organizationId);
    return <Redirect to={`/`} />;
  }

  if (result.isActive) {
    // setLocalItem("registrationResult", JSON.stringify(result));
    return <Redirect to={`/activate/user?token=${token}&email=${email}`} />;
  }
  return "";
  // return <Redirect to={`/`} />;
};

export default RedirectRoute;
