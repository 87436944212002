const Mixpanel = {
  LOGIN: "Login",
  FORGOT_PASSWORD: "Forgot Password",
  LOGOUT: "Logout",
  APPLY_FILTER: "Apply Filter",
  SEARCH_FILTER: "Search Filter",
  ADD_MEMBER: "Add Member",
  CANCEL_ADD_MEMBER: "Cancel Add Member",
  CHANGE_TEMPERATURE_UNIT: "Change Temperature Unit",
  INVITE_MEMBER: "Invite Member",
  INVITE_MEMBERS: "Invite Members",
  CANCEL_INVITE_MEMBER: "Cancel Invite Member",
  CANCEL_INVITE_MEMBERS: "Cancel Invite Members",
  CLICK_MEMBER_STATUS_FILTER_INFO: "Click Member Status Filter Info",
  VIEW_MEMBER_PROFILE: "View Member Profile",
  RESEND_INVITE: "Resend Invite",
  REMOVE_MEMBER: "Remove Member",
  REVOKE_INVITE: "Revoke Invite",
  VIEW_SYMPTOMS: "View Symptoms",
  VIEW_EDIT_SYMPTOMS: "View Edit Symptoms",
  VIEW_CONTACT_HISTORY: "View Contact History",
  CREATE_SYMPTOMS: "Create Symptoms",
  UPDATE_SYMPTOMS: "Update Symptoms",
  INVITE_MANAGER: "Invite Manager",
  REMOVE_MANAGER: "Remove Manager",
  RESEND_MANAGER_INVITE: "Resend Manager Invite",
  REVOKE_MANAGER_INVITE: "Revoke Manager Invite",
  UPDATE_EMAIL: "Update Email",
  UPDATE_MOBILE_NUMBER: "Update Mobile Number",
  UPDATE_PASSWORD: "Update Password",
  UPDATE_PROFILE: "Update Profile",
  LEAVE_COMMUNITY: "Leave Community",
  CHANGE_TEMPERATURE_UNIT_PREFERENCE: "Change Temperature Unit Preference",
};

export default Object.freeze(Mixpanel);
