import { validateEmail } from "utils";

const Validation = {
  required: (customMessage = null) => {
    return (field) => {
      if (!field.value) {
        return { error: true, message: customMessage ? customMessage : "This field is required." };
      }
      return { error: false, message: null };
    };
  },
  maxlength: (length = 0, customMessage = null) => {
    return (field) => {
      if (field.value > length) {
        return {
          error: true,
          message: customMessage ? customMessage : `Maximum ${length} character(s).`,
        };
      }
      return { error: false, message: null };
    };
  },
  minlength: (length = 0, customMessage = null) => {
    return (field) => {
      console.log(length);
      console.log(field);
      if (field.value.length < length) {
        return {
          error: true,
          message: customMessage ? customMessage : `Minimum ${length} character(s).`,
        };
      }
      return { error: false, message: null };
    };
  },
  requiredFieldOptions: () => {
    return (field) => {
      const { value: fieldValue, skipValidation } = field;
      const error = fieldValue.some(({ optionName }) => !optionName.trim());
      const newValue = fieldValue.map(({ optionName, ...rest }) => ({
        ...rest,
        optionName,
        isDuplicate: false,
      }));

      if (skipValidation) {
        return { error: false, message: "" };
      }
      return { error, message: "", value: newValue };
    };
  },
  emailAddress: () => {
    return (field) => {
      const { value: fieldValue } = field;
      if (fieldValue && !validateEmail(fieldValue)) {
        return {
          error: true,
          message: "This email is invalid.",
        };
      }
      return {};
    };
  },
  checkFieldOptionsDuplicate: () => {
    return (field) => {
      const { value: fieldValue, skipValidation } = field;
      if (skipValidation) {
        return { error: false, message: "" };
      }
      const isDuplicateValue = (value, index) => {
        return fieldValue
          .filter((o, i) => i !== index)
          .some(
            ({ optionName }, i) => optionName.toLowerCase() === value.toLowerCase() && i < index
          );
      };

      const newValue = fieldValue.map((item, index) => {
        const { optionName, ...rest } = item;
        return {
          ...rest,
          optionName,
          isDuplicate: isDuplicateValue(optionName, index),
        };
      });

      const error = newValue.some((item) => item.isDuplicate);
      return { error, message: "", value: newValue };
    };
  },
  checkMapCoordinates: () => {
    return (field) => {
      const { value: fieldValue } = field;
      const parsedValue = parseFloat(fieldValue);
      if ((!parsedValue && parsedValue.toString() !== "0") || isNaN(parsedValue)) {
        return { error: true, message: "Invalid coordinate." };
      }
      return {};
    };
  },
};

export default Validation;
