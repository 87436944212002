import React, { useMemo, memo, useContext } from "react";
import { Details } from "components/overview";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import * as moment from "moment";
import { formatTemperature, isNormalTemp } from "utils";
import styles from "./today-details.module.scss";
import classnames from "classnames";
import { ViewUserContext } from "contexts";

import Skeleton from "react-loading-skeleton";

const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "time",
    title: localStrings.time,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "temperature",
    title: localStrings.temperature,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "location",
    title: localStrings.areaLocation,
    width: 100,
    ellipsis: true,
  },
];

const TodayDetails = ({ className, locations }) => {
  const { state } = useContext(ViewUserContext);
  const { todayDetails, pastDayDetails } = state;
  const { data, loading: todayLoading } = todayDetails;
  const { loading: pastLoading } = pastDayDetails;

  const tableData = useMemo(() => {
    return data.map((d) => {
      const { hour, bodyTemp, latitude, longitude, location: areaLocation, areaId } = d || {};
      const location = (() => {
        const l = [latitude, longitude].join(",");
        const hasLocation = Boolean(latitude && longitude);
        if (areaId) {
          return areaLocation || "-";
        }
        if (!locations[l] || locations[l].loading) {
          if (hasLocation) {
            return <Skeleton />;
          } else {
            return "-";
          }
        } else {
          return locations[l].location;
        }
      })();
      return {
        key: uuidv4(),
        time: hour ? moment(hour, "hhA").format("hh A") : "-",
        temperature: (
          <div className={classnames({ cDanger: !isNormalTemp(bodyTemp) }, styles.temperature)}>
            {formatTemperature(bodyTemp)}
          </div>
        ),
        location,
      };
    });
  }, [data, locations]);

  return todayLoading || pastLoading || data.length || todayDetails.error ? (
    <Details
      className={classnames(className, styles.container)}
      title={localStrings.today}
      columns={tableColumns}
      loading={todayLoading}
      data={tableData}
      scroll={{
        y: 520,
      }}
      error={todayDetails.error}
    />
  ) : null;
};

export default memo(TodayDetails);
