import React, { useRef, useLayoutEffect, useEffect, memo } from "react";
import styles from "./input-otp.module.scss";
import classnames from "classnames";

function usePrevious(value) {
  const ref = useRef();

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}

const InputOTP = ({ focus, autoFocus, error, className, ...rest }) => {
  const inputRef = useRef();
  const prevFocus = usePrevious(!!focus);
  useLayoutEffect(() => {
    if (inputRef.current) {
      if (focus && autoFocus) {
        inputRef.current.focus();
      }
      if (focus && autoFocus && focus !== prevFocus) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }
  }, [autoFocus, focus, prevFocus]);
  return (
    <input
      className={classnames(
        styles.input,
        {
          [`${styles.error}`]: error,
        },
        className
      )}
      text="text"
      ref={inputRef}
      maxLength={1}
      {...rest}
    />
  );
};

export default memo(InputOTP);
