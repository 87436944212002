import React from "react";
import styles from "./server.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import { Image } from "atomic/atoms";
import Error500 from "images/500.svg";
const ServerError = ({ className }) => {
  return (
    <div className={classnames(styles.container, className)}>
      <Image className={styles.image} src={Error500} />
      <h2 className={styles.title}>{localStrings.wellEmbarassing}</h2>
      <p className={styles.description}>
        {localStrings.wellEmbarassingDesc}
        <br />
        <span
          className={styles.innerDesc}
          dangerouslySetInnerHTML={{ __html: localStrings.weShouldBack }}
        />
      </p>
    </div>
  );
};

export default ServerError;
