import React, { useContext } from "react";
import {
  Route, // Redirect
} from "react-router-dom";
import { UserContext } from "contexts";

const AdminRoute = ({ component: Component, ...rest }) => {
  const { user } = useContext(UserContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user.userId > 0) {
          return <Component {...props} />;
        }
        return null;
      }}
    />
  );
};

export default AdminRoute;
