import React, { useContext, useMemo, useCallback, memo, useState } from "react";
import ReactDOMServer from "react-dom/server";
import styles from "./users-location.module.scss";
import { LiveMapContext } from "contexts";
import UserPin from "images/user-pin.svg";
import UserPinDanger from "images/user-pin-danger.svg";
import UserPinInactive from "images/user-pin-inactive.svg";
import PrivatePin from "images/private-user-pin.svg";
import PrivatePinDanger from "images/private-user-pin-danger.svg";
import PrivatePinInactive from "images/private-user-pin-inactive.svg";
import { getInitials, isNormalTemp, metersPerPixel, convertTemperature } from "utils";
import { useRouter, useModal } from "hooks";
import InfoWIndow from "./info-window";
import path from "path/path";
import { Map } from "atomic/organisms";
import RedMarker from "images/live-map-markers/red.png";
import GrayMarker from "images/live-map-markers/gray.png";
import BlueMarker from "images/live-map-markers/blue.png";
import classnames from "classnames";
import { Modal, Temp } from "atomic/atoms";
import { v4 as uuidv4 } from "uuid";

const UsersLocation = ({ containerMiniViewStyle, longitude, latitude }) => {
  const {
    state: {
      users: { data },
      currentLocation: { latitude: currentLat, longitude: currentLng },

      zoom,
      // focusUser,
    },
    dispatch,
    isMiniMap,
  } = useContext(LiveMapContext);
  const [clusterModalState, setClusterModalState] = useState([]);
  const [clusterLoading, setClusterLoading] = useState(true);
  const clusterBreakdownModal = useModal({
    onCancel: () => {
      setClusterModalState([]);
    },
  });
  const { history } = useRouter();
  const preparedMarkers = useMemo(() => {
    return data.map((user) => {
      const {
        firstName,
        lastName,
        latitude: lat,
        longitude: lng,
        latestBodyTemp: temp,
        fullName,
        isActive,
        userId,
        isPrivateName,
        isPrivateTemp,
      } = user;
      const initial = getInitials([firstName, lastName]) || "-";
      const hasFever = !isPrivateTemp && !isNormalTemp(convertTemperature(temp));
      const icon = (() => {
        if (!isActive) {
          return isPrivateName ? PrivatePinInactive : UserPinInactive;
        }
        if (hasFever) {
          return isPrivateName ? PrivatePinDanger : UserPinDanger;
        }
        return isPrivateName ? PrivatePin : UserPin;
      })();
      return {
        label: !isPrivateName ? { text: initial, color: "white" } : null,
        fullName: !isPrivateName ? fullName : `#${userId}`,
        temp,
        lat,
        lng,
        icon,
        hasFever,
        isActive,
        userId,
        isPrivateTemp,
        isPrivateName,
      };
    });
  }, [data]);

  const initMarkerCluster = useCallback(
    ({ map, MarkerClusterer, markers }) => {
      const styles = [GrayMarker, BlueMarker, RedMarker].map((url) => ({
        url,
        height: 54,
        width: 54,
        className: classnames("live-map-cluster-marker", {
          "is-mini-map-marker": isMiniMap,
        }),
      }));
      const markerClusters = new MarkerClusterer(map, markers, {
        styles,
        zoomOnClick: !isMiniMap,
      });
      markerClusters.setCalculator((markers) => {
        const isBlueMarker = markers.some(({ isActive }) => {
          return isActive;
        });

        const isRedMarker = markers.some(({ hasFever, isActive }) => {
          return hasFever && isActive;
        });

        const getIndex = () => {
          if (isRedMarker) {
            return 3;
          }
          if (isBlueMarker) {
            return 2;
          }
          return 1;
        };
        return {
          text: markers.length,
          index: getIndex(),
        };
      });
      return markerClusters;
    },
    [isMiniMap]
  );

  const initInfoWindow = useCallback(({ markers, InfoWindow }) => {
    return markers.map((marker) => {
      return new InfoWindow({
        content: ReactDOMServer.renderToStaticMarkup(<InfoWIndow {...marker} />),
      });
    });
  }, []);

  const initCenter = useMemo(() => {
    return {
      lat: currentLat,
      lng: currentLng,
    };
  }, [currentLat, currentLng]);

  const setCenter = useCallback(
    (map) => {
      if (latitude && longitude) {
        map.setCenter({ lat: Number(latitude), lng: Number(longitude) });
      }
      console.log(zoom);
      if (zoom) {
        map.setZoom(zoom);
      }
      if (isMiniMap) {
        map.setZoom(18);
      }
    },
    [longitude, latitude, zoom, isMiniMap]
  );

  const handleOnCenterChange = useCallback(
    (center) => {
      dispatch({
        type: "CHANGE_CURRENT_LOCATION",
        data: center,
      });
    },
    [dispatch]
  );
  const handleOnZoomChange = useCallback(
    (map) => {
      const zoom = map.getZoom();
      const { lat } = map.getCenter()?.toJSON() || {};
      const radius = metersPerPixel(zoom, lat);
      if (radius > 288) {
        dispatch({
          type: "CHANGE_RADIUS",
          data: radius,
          zoom,
        });
      } else {
        dispatch({
          type: "CHANGE_RADIUS",
          zoom,
        });
      }
    },
    [dispatch]
  );

  const handleShowClusterBreakdown = useCallback(
    (cluster) => {
      const markers = cluster.getMarkers();
      const clusters = [];
      for (let m of markers) {
        console.log(m);
        clusters.push(
          <div className={styles.clusterBreakdown} key={uuidv4()}>
            <div className={styles.name}>{m.fullName}</div>{" "}
            <div>
              <Temp temp={m.temp} />
            </div>
          </div>
        );
      }
      setClusterLoading(false);
      setClusterModalState(clusters);

      if (zoom >= 22 && !clusterLoading) {
        clusterBreakdownModal.show();
      }
    },
    // eslint-disable-next-line
    [zoom]
  );

  // useEffect(() => {
  //   if (clusterModalState?.length) {
  //     console.log(clusterModalState?.length);
  //     console.log(zoom);
  //     if (zoom >= 22) {
  //       clusterBreakdownModal.show();
  //     }
  //   }
  // }, [clusterModalState, clusterBreakdownModal, zoom]);

  return (
    <div
      className={isMiniMap ? containerMiniViewStyle : styles.container}
      onClick={() => {
        if (isMiniMap) {
          history.push(path.LIVE_MAP);
        }
      }}
    >
      <Modal {...clusterBreakdownModal}>{clusterModalState}</Modal>
      <Map
        controls={!isMiniMap}
        draggable={!isMiniMap}
        className={styles.map}
        markers={preparedMarkers}
        initInfoWindow={initInfoWindow}
        initCenter={initCenter}
        setCenter={setCenter}
        onCenterChange={handleOnCenterChange}
        onZoomChange={handleOnZoomChange}
        initMarkerCluster={initMarkerCluster}
        zoom={zoom}
        showClusterBreakdown={handleShowClusterBreakdown}
      />
    </div>
  );
};

export default memo(UsersLocation);
