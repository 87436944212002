import { Checkbox, Icon } from "atomic/atoms";
import React from "react";
import styles from "./default-questions.module.scss";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";

const DefaultQuestions = ({
  questions,
  toggleEditFormModal,
  toggleAutoAddQuestion,
  loadingQuestionId,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <p className={styles.columnName}>Questions</p>
        <p className={classnames(styles.columnName)}>Auto Add</p>
      </div>
      {questions.map(({ organizationQuestionId, questionName, isAutoAdd, isDefault, loading }) => (
        <div key={uuidv4()} className={styles.questionContainer}>
          <p
            className={styles.question}
            onClick={() =>
              toggleEditFormModal({
                organizationQuestionId,
                isDefaultQuestion: true,
                visible: true,
              })
            }
          >
            {questionName}
          </p>
          {loadingQuestionId && loadingQuestionId === organizationQuestionId ? (
            <Icon loading={true} name="loading" />
          ) : (
            <Checkbox
              className={styles.autoAdd}
              value={isAutoAdd}
              onChange={(checked) => {
                toggleAutoAddQuestion(checked, organizationQuestionId, isDefault);
              }}
              disabled={loadingQuestionId}
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default DefaultQuestions;
