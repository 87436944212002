import React, { useCallback, useContext, useState } from "react";
import { AreaList } from "components/area";
import { OrganizationContext } from "contexts";
import { useApi, useFilter, useModal, useMount, useRouter } from "hooks";
import { getAreas, getAreaLevels } from "api/area";
import { areaListFilterState } from "states";
import { environment } from "../../../environments/environment";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import localStrings from "localization";
import { DeleteAreaModalContainer } from "containers";
import path from "path/path";
import { GatewayStatus } from "enums";
import { Fragment } from "react";

const AreaListContainer = () => {
  const { organizationId } = useContext(OrganizationContext);
  const [areas, setAreas] = useState([]);
  const [loadingFirebase, setLoadingFirebase] = useState(true);

  const { history } = useRouter();

  const {
    request: searchAreaRequest,
    loading,
    result = { data: [], metadata: { total: 0 } },
  } = useApi({
    api: getAreas,
    params: areaListFilterState({ organizationId }),
  });

  const { request: searchAreaLevels, result: areaLevels = [] } = useApi({
    api: getAreaLevels,
    params: { organizationId },
  });

  const deleteAreaModal = useModal({});

  const { modifyFilter, filterState } = useFilter(areaListFilterState({ organizationId }));

  useMount(() => {
    fetchAreas();
  });

  const subscribeToFirebase = useCallback(async (res) => {
    setLoadingFirebase(true);

    let gatewayUids = [];
    for (const area of res.data) {
      for (const a of area.areas) {
        if (!gatewayUids.includes(a.gatewayUid)) {
          gatewayUids.push(a?.gatewayUid?.toLowerCase());
        }
      }
    }

    const initFirebase = async () => {
      try {
        const db = firebase.database();
        await firebase.auth().signInAnonymously();
        const gwRef = db.ref(`msHealth/${environment.id}/gws`);

        let areasCopy = [...res.data];

        if (gwRef) {
          gwRef.on("value", (snapshot) => {
            snapshot.forEach((doc) => {
              if (gatewayUids.includes(doc.val().gatewayUid)) {
                for (const area of areasCopy) {
                  area.areas = area.areas.map((a) => {
                    if (a?.gatewayUid?.toLowerCase() === doc.val().gatewayUid) {
                      return { ...a, status: doc.val().status };
                    } else {
                      return { ...a };
                    }
                  });
                }
              }
            });

            for (const area of areasCopy) {
              area.areas = area.areas.map((a) => {
                if (!a.hasOwnProperty("status")) {
                  return { ...a, status: GatewayStatus.NOT_CONFIGURED };
                } else {
                  return { ...a };
                }
              });
            }
            setAreas([...areasCopy]);
          });
        }
        setAreas([...areasCopy]);
      } catch (err) {
        console.error(err);
      }
    };

    await initFirebase();
    setLoadingFirebase(false);
  }, []);

  const fetchAreas = useCallback(async () => {
    searchAreaLevels();
    const res = await searchAreaRequest();
    subscribeToFirebase(res);
  }, [searchAreaLevels, searchAreaRequest, subscribeToFirebase]);

  const getMenuOptions = useCallback(
    (area) => {
      return [
        {
          label: localStrings.editArea,
          onClick: (e) => {
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
            history.push(path.AREAS_EDIT(area.areas[0].areaId));
          },
        },
        {
          label: localStrings.deleteArea,
          onClick: (e) => {
            deleteAreaModal.show(area);
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          },
        },
      ];
    },
    [deleteAreaModal, history]
  );

  const changeLevelCb = useCallback(
    async (level) => {
      const { requestState } = modifyFilter({ level, page: 1 });
      const res = await searchAreaRequest(requestState);
      await subscribeToFirebase(res);
    },
    [modifyFilter, searchAreaRequest, subscribeToFirebase]
  );

  const changePageCb = useCallback(
    async (page) => {
      const { requestState } = modifyFilter({ page });
      const res = await searchAreaRequest(requestState);
      await subscribeToFirebase(res);
    },
    [modifyFilter, searchAreaRequest, subscribeToFirebase]
  );

  const changePageSizeCb = useCallback(
    async (pageSize) => {
      const { requestState } = modifyFilter({ pageSize, page: 1 });
      const res = await searchAreaRequest(requestState);
      await subscribeToFirebase(res);
    },
    [modifyFilter, searchAreaRequest, subscribeToFirebase]
  );

  return (
    <Fragment>
      <AreaList
        loading={loading || loadingFirebase}
        areas={areas}
        changeLevel={changeLevelCb}
        areaLevels={areaLevels}
        currentLevel={filterState.level}
        getMenuOptions={getMenuOptions}
        page={filterState.page}
        pageSize={filterState.pageSize}
        total={result.metadata?.total ? result.metadata.total : 0}
        changePage={changePageCb}
        changePageSize={changePageSizeCb}
      />
      <DeleteAreaModalContainer
        area={deleteAreaModal.state}
        visible={deleteAreaModal.visible}
        close={deleteAreaModal.close}
        fetchAreas={fetchAreas}
      />
    </Fragment>
  );
};

export default AreaListContainer;
