import React from "react";
import { Button } from "atomic/molecules";
import styles from "./submit-step.module.scss";
import localStrings from "localization";

const SubmitStep = ({
  onBack,
  onSubmit,
  loading,
  submitText = localStrings.next,
  onBackContent,
}) => {
  return (
    <div className={styles.submit}>
      <Button
        icon={onBackContent ? null : "left-chevron"}
        right={false}
        tertiary
        className={styles.back}
        text={onBackContent ? null : localStrings.back}
        onClick={onBack}
      >
        {onBackContent}
      </Button>
      <Button
        className={styles.submitButton}
        loading={loading}
        onClick={onSubmit}
        text={submitText || localStrings.next}
      />
    </div>
  );
};

export default SubmitStep;
