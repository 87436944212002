import React, { useMemo } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import {
  ProfileNavLink,
  useDevice,
  ContactHistoryTab,
  SymptomsTab,
  DairyTab,
  ProfileInfo,
  QuarantineStatus,
} from "components/profile";
import styles from "./profile-dashboard.module.scss";
import localStrings from "localization";
import { AccountStatus } from "enums";
import useAddDevice from "../device/add-device/add-device";
import useDiary from "services/diary.service";
import { MapLocation } from "atomic/molecules";

const ProfileDashboard = ({
  profileInfo,
  mapLocation,
  diary,
  userInfo,
  symptomsOverview,
  ownAccount,
  device,
  organization,
  isMemberProfile = false,
  userPrivacyStatus = {
    isAboutYouAllowed: true,
    isEmploymentDetailAllowed: true,
    isEmergencyContactAllowed: true,
    isWristbandAllowed: true,
    isSymptomAllowed: true,
    isLocationAllowed: true,
    isNameAndPhotoAllowed: true,
    isBodyTemperatureAllowed: true,
    isEmailAndMobileNumberAllowed: true,
    isAreaLocationAllowed: true,
  },
  userPrivacyLoading,
  links,
}) => {
  const { userId } = userInfo || {};
  const user = useMemo(() => {
    const { firstName, lastName } = userInfo || {};
    const userFullName = `${firstName || ""} ${lastName || ""}`.trim();
    const privateNameTag = !userPrivacyStatus.isNameAndPhotoAllowed;
    const name = () => {
      if (privateNameTag) {
        return `#${userId}`;
      }
      return userFullName || "-";
    };
    const phrases = () => {
      if (privateNameTag) {
        return [];
      }
      return [firstName, lastName];
    };
    return {
      ...userInfo,
      name: name(),
      avatar: privateNameTag ? null : userInfo?.avatar,
      firstName: privateNameTag ? "" : userInfo?.firstName,
      lastName: privateNameTag ? "" : userInfo?.lastName,
      phrases: phrases(),
      nickname: privateNameTag ? `#${userId}` : userInfo?.firstName,
    };
  }, [userInfo, userPrivacyStatus, userId]);

  const organizationId = organization?.organizationId;

  const isManageable =
    [
      AccountStatus.UNVERIFIED_MEMBER,
      AccountStatus.PENDING_MEMBER,
      AccountStatus.PROFILE_ONLY,
    ].includes(user?.accountStatus) || ownAccount;

  const { render: PairNewDevice, show: showPairNewDeviceModal } = useAddDevice({
    refresh: () => requestDevice(),
    api: device.pairApi,
    organizationId,
    userId,
  });
  const { render: Device, hasDevice, request: requestDevice, latestSync } = useDevice({
    user,
    ownAccount,
    ...device,
    isManageable,
    showPairNewDeviceModal,
    organizationId,
    userId,
    userPrivacyStatus,
    userPrivacyLoading,
    isMemberProfile,
  });

  const {
    latestData = {},
    loading,
    refreshDiary,
    today,
    pastDays,
    totalData: diaryTotalData,
  } = useDiary({
    user,
    ...diary,
    title: hasDevice ? localStrings.noRecordsYet : localStrings.noAccessory,
    organizationId,
    userPrivacyStatus,
    userPrivacyLoading,
  });

  const circle = useMemo(() => {
    console.log(userInfo);
    if (userInfo) {
      const {
        quarantineLocation,
        quarantineRadius,
        quarantineOutOfBounds,
        quarantineAddressLatitude,
        quarantineAddressLongitude,
      } = userInfo;

      if (quarantineLocation && quarantineRadius) {
        return {
          radius: quarantineRadius,
          color: quarantineOutOfBounds ? "#FC2F35" : "#00C366",
          lat: quarantineAddressLatitude,
          lng: quarantineAddressLongitude,
        };
      }
    }
    return null;
  }, [userInfo]);

  return (
    <>
      {PairNewDevice}
      <div className={styles.container}>
        <ProfileInfo
          {...profileInfo}
          className={styles.profileInfo}
          userId={user?.userId}
          device={Device}
          userInfo={user}
          latestSync={latestSync}
          organizationId={organizationId}
          userPrivacyStatus={userPrivacyStatus}
          userPrivacyLoading={userPrivacyLoading}
        />
        <div className={styles.content}>
          <MapLocation
            {...mapLocation}
            className={styles.mapLocation}
            location={latestData.location}
            latitude={latestData.latitude}
            longitude={latestData.longitude}
            firstName={user.firstName}
            lastName={user.lastName}
            loading={loading}
            locationValue={latestData.locationValue}
            userInfo={userInfo}
            quarantineStatus={
              <QuarantineStatus userInfo={userInfo} location={latestData.locationValue} />
            }
            circle={circle}
            zoom={20}
          />
          <ProfileNavLink
            isMemberProfile={isMemberProfile}
            userPrivacyStatus={userPrivacyStatus}
            userPrivacyLoading={userPrivacyLoading}
            links={links}
            changeTab={({ to }) => {
              if (to === links.temperature) {
                refreshDiary();
              }
            }}
          />
          <div className={styles.tabContent}>
            <Switch>
              <Route
                exact
                render={() => {
                  if (!userPrivacyLoading && !userPrivacyStatus.isSymptomAllowed) {
                    return <Redirect to={links.temperature} />;
                  }
                  return (
                    <SymptomsTab
                      {...symptomsOverview}
                      user={user}
                      ownAccount={ownAccount}
                      organizationId={organizationId}
                      isMemberProfile={isMemberProfile}
                      userPrivacyStatus={userPrivacyStatus}
                      userPrivacyLoading={userPrivacyLoading}
                    />
                  );
                }}
                path={links.symptoms}
              />
              <Route
                exact
                render={() => {
                  return (
                    <ContactHistoryTab
                      userId={userId}
                      user={user}
                      userPrivacyStatus={userPrivacyStatus}
                      userPrivacyLoading={userPrivacyLoading}
                    />
                  );
                }}
                path={links.contactHistory}
              />
              <Route
                exact
                render={() => {
                  return (
                    <DairyTab
                      today={today}
                      pastDays={pastDays}
                      {...diary}
                      totalData={diaryTotalData}
                      userId={userId}
                      organizationId={organizationId}
                      title={!hasDevice ? localStrings.noAccessory : localStrings.noRecordsYet}
                      description={
                        !hasDevice
                          ? localStrings.assignAPouchBandToStartMonitorTheirTemp
                          : diary.strings.wearTheBand
                      }
                      userPrivacyStatus={userPrivacyStatus}
                      userPrivacyLoading={userPrivacyLoading}
                    />
                  );
                }}
                path={links.temperature}
              />
            </Switch>
          </div>
        </div>
      </div>
      {/* <MemberGuide success={requestDevice} lastData={lastData} /> */}
    </>
  );
};

export default ProfileDashboard;
