import { useState, useCallback } from "react";
import { Common } from "enums";
import { useRouter } from "hooks";
import useMount from "./useMount";
import { checkIfObjectValuesAreEqual } from "utils";

/**
 * A hook for easier handling / processing of Table filter logic
 */
const useFilter = (initialState = undefined) => {
  const { location } = useRouter();

  const [filterState, setFilterState] = useState({ ...initialState, ...location.state });

  const [requestState, setRequestState] = useState(
    convertFilterStateToRequest({ ...initialState, ...location.state })
  );
  const [isFilterDirty, setIsFilterDirty] = useState(
    !checkIfObjectValuesAreEqual(initialState, filterState)
  );

  // Filter Hook usage validation
  useMount(() => {
    validateFilterState(initialState);
  });

  const modifyFilter = useCallback(
    (filters) => {
      setIsFilterDirty(true);
      const newFilters = {
        ...filterState,
        ...removeUninitializedFilters(filters, initialState),
      };

      if (validateFilters(newFilters)) {
        setFilterState(newFilters);

        const newRequestFilters = convertFilterStateToRequest(newFilters);
        setRequestState(newRequestFilters);
        return { filterState: newFilters, requestState: newRequestFilters };
      }
      return { filterState: newFilters, requestState: null };
    },
    [filterState, initialState]
  );

  const clearFilter = useCallback(() => {
    setIsFilterDirty(false);
    setFilterState(initialState);

    const newRequestFilters = convertFilterStateToRequest(initialState);
    setRequestState(newRequestFilters);

    return { filterState: initialState, requestState: newRequestFilters };
  }, [initialState]);

  return { modifyFilter, clearFilter, filterState, requestState, isFilterDirty };
};

// Add additional validations here in the future if any
const validateFilters = (filterState) => {
  try {
    validateFilterState(filterState);
    validatePaginationState(filterState);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

const validateFilterState = (filterState) => {
  if (!filterState) {
    throw new Error("Filter state is required when using filter hook");
  }
  if (typeof filterState !== "object") {
    throw new Error("Filter state must be an object");
  }
  if (Object.keys(filterState) <= 0) {
    throw new Error("Filter state must have atleast one property");
  }
};

const validatePaginationState = (state) => {
  if (state.hasOwnProperty("page") || state.hasOwnProperty("pageSize")) {
    if (!state?.page || typeof state?.page !== "number" || state?.page <= 0) {
      throw new Error(`Invalid page state with value ${state.page}`);
    }
    if (!state?.pageSize || typeof state?.pageSize !== "number" || state?.pageSize <= 0) {
      throw new Error(`Invalid page size state with value of ${state.pageSize}`);
    }
  }

  return true;
};

const removeUninitializedFilters = (filters, initialState) => {
  let newFilters = {};
  for (const [k, filter] of Object.entries(filters)) {
    if (!initialState.hasOwnProperty(k)) {
      console.warn(
        `Filter ${k} is not declared on initial filter state. Will disregard this filter.`
      );
    } else {
      newFilters[k] = filter;
    }
  }
  return newFilters;
};

const convertFilterStateToRequest = (filterState) => {
  let request = {};
  for (const [k, filter] of Object.entries(filterState)) {
    if (filter !== Common.ALL) {
      request[k] = filter;
    }
  }
  return request;
};

export default useFilter;
