import React, { useCallback, useContext, useState } from "react";
import { SettingsLayout } from "layouts";
import localStrings from "localization";
import { ProfileSettings } from "components/settings";
import { useMount, useApi, useForm, useRouter } from "hooks";
import { UserContext } from "contexts";
import moment from "moment";
import { profileSettingsFormState } from "states";
import { updateOwnUser } from "api/user";
import { uploadImage } from "api/storage";
import { Toast } from "atomic/molecules";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "enums";

const ProfileSettingsContainer = () => {
  const [leaveModal, setLeaveModal] = useState(false);
  const [nextLocation, setNextLocation] = useState(null);
  const [message, setMessage] = useState(null);

  const { user, updateUser: updateUserContext } = useContext(UserContext);
  const { email, phone, phoneCountryCode } = user;

  const { request: updateUserRequest, loading: updatingUser } = useApi({ api: updateOwnUser });
  const { request: uploadImageRequest, loading: uploadingImage } = useApi({ api: uploadImage });

  const { history } = useRouter();

  const {
    formFields,
    formFieldsValue,
    setFormFieldValues,
    setFormFieldValue,
    submit,
    isFormDirty,
    clearFormFieldsDirty,
    setFormFieldsRequired,
  } = useForm(profileSettingsFormState, submitCb);

  async function submitCb(formValues) {
    const { dob, weight, height, ...rest } = formValues;
    try {
      const res = await updateUserRequest({
        dob: dob ? moment(dob).format("MM/DD/YYYY") : null,
        email,
        phone,
        phoneCountryCode,
        weight: weight ? Number(weight) : "",
        height: height ? Number(height) : "",
        ...rest,
      });
      updateUserContext(res);
      clearFormFieldsDirty();

      mixpanel.track(Mixpanel.UPDATE_PROFILE);
      Toast({
        content: localStrings.changesSaved,
        success: true,
      }).open();
    } catch (error) {}
  }

  useMount(() => {
    const { dob, userDetail, ...rest } = user;
    const {
      workCountryCode,
      contactName,
      contactRelationship,
      contactNumber,
      contactCountryCode,
      workPhone,
    } = userDetail || {};

    setFieldRequire({
      workPhone,
      workCountryCode,
      contactName,
      contactRelationship,
      contactNumber,
      contactCountryCode,
    });

    setFormFieldValues({
      dob: dob ? moment(dob, "MM/DD/YYYY") : null,
      ...userDetail,
      ...rest,
    });
  });

  const navigateCb = useCallback(
    (nextLocation) => {
      if (isFormDirty) {
        if (leaveModal) {
          return true;
        } else {
          setLeaveModal(true);
          setNextLocation(nextLocation.pathname);
        }
        return false;
      }
      return true;
    },
    [isFormDirty, leaveModal]
  );

  const closeLeaveModalCb = useCallback(() => {
    setLeaveModal(false);
  }, []);

  const beforeUploadCb = useCallback((file) => {
    const isJpgOrPng = ["image/jpeg", "image/png", "image/gif"].includes(file.type);
    const isLt1M = file.size / 1024;
    const limit = isLt1M >= 4096;
    if (!isJpgOrPng || limit) {
      Toast({
        content: !isJpgOrPng
          ? localStrings.youCanOnlyUploadJpgPngGifFile
          : localStrings.unbaleToUploadMoreThanMb,
        error: true,
        icon: "exclamation-fill",
      }).open();
    }
    return isJpgOrPng && !limit;
  }, []);

  const changeAvatarCb = useCallback(
    async (data) => {
      const { status, response } = data.file;

      if (status === "done") {
        const avatar = response.distributionUrl;

        try {
          const res = await updateUserRequest({ ...user.userDetail, ...user, avatar });
          setFormFieldValue(res.avatar, "avatar");
          Toast({
            content: localStrings.profileAvatarSaved,
            success: true,
          }).open();
          updateUserContext(res);
        } catch (error) {
          setMessage(localStrings.somethingWentWrong);
        }
      }

      if (status === "error") {
        setMessage(localStrings.somethingWentWrong);
      }
    },
    [user, updateUserRequest, updateUserContext, setFormFieldValue]
  );

  const uploadAvatarCb = useCallback(
    async (res) => {
      const { file, onSuccess, onError } = res;
      try {
        const result = await uploadImageRequest({ file });
        onSuccess(result);
      } catch (error) {
        onError(error);
      }
    },
    [uploadImageRequest]
  );

  const confirmLeaveCb = useCallback(() => {
    closeLeaveModalCb();
    history.push(nextLocation);
  }, [nextLocation, history, closeLeaveModalCb]);

  const setFieldRequire = useCallback(
    (fieldsVal = {}) => {
      const {
        contactName,
        contactRelationship,
        contactNumber,
        workPhone,
        workCountryCode,
      } = fieldsVal;
      const isEmergencyFieldRequire = [contactName, contactRelationship, contactNumber].some(
        (value) => value
      );
      const isWorkPhoneRequire = [workCountryCode, workPhone].some((value) => value);

      setFormFieldsRequired(
        {
          contactName: isEmergencyFieldRequire,
          contactRelationship: isEmergencyFieldRequire,
          contactNumber: isEmergencyFieldRequire,
          workPhone: isWorkPhoneRequire,
          workCountryCode: isWorkPhoneRequire,
        },
        false
      );
    },
    [setFormFieldsRequired]
  );

  return (
    <SettingsLayout title={localStrings.profileSettings}>
      <ProfileSettings
        formFields={formFields}
        formFieldsValue={formFieldsValue}
        isFormDirty={isFormDirty}
        navigate={navigateCb}
        leaveModal={leaveModal}
        closeLeaveModal={closeLeaveModalCb}
        message={message}
        user={user}
        beforeUpload={beforeUploadCb}
        uploadAvatar={uploadAvatarCb}
        changeAvatar={changeAvatarCb}
        setFormFieldValue={setFormFieldValue}
        setFieldRequire={setFieldRequire}
        submit={submit}
        updatingUser={updatingUser}
        uploadingImage={uploadingImage}
        leave={confirmLeaveCb}
      />
    </SettingsLayout>
  );
};

export default ProfileSettingsContainer;
