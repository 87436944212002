import React, { useState, memo, useEffect } from "react";
import { InputField, Button } from "atomic/molecules";
import { Icon } from "atomic/atoms";
import styles from "./filters.module.scss";
import classnames from "classnames";
import localStrings from "localization";

const Filters = ({
  children,
  className,
  onApplyFilter,
  onApplySearch,
  onClearFilter,
  initialSearchKey,
  hasFilters,
}) => {
  const [filter, setFilter] = useState(false);
  const [searchKey, setSearchKey] = useState(null);
  const toggleFilter = () => {
    setFilter((prev) => !prev);
  };

  const handleChangeSearchKey = (value) => {
    setSearchKey(value);
  };

  const handleApplySearch = (e) => {
    e.preventDefault();
    onApplySearch(searchKey);
  };

  const handleApplyFilter = () => {
    onApplyFilter(searchKey);
  };

  const handleClearSearch = () => {
    setSearchKey("");
    onApplySearch("");
  };

  const handleClearFilters = () => {
    onClearFilter(searchKey);
  };

  useEffect(() => {
    if (initialSearchKey) {
      setSearchKey(initialSearchKey);
    }
  }, [initialSearchKey]);

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.searchBar}>
        <div className={styles.filter} onClick={toggleFilter}>
          <Icon name="filter" className={styles.filterIco} />
          Filters
          <Icon className={styles.chevIco} name="down-chevron-thick" />
        </div>
        <form className={styles.searchForm} onSubmit={handleApplySearch}>
          <InputField
            className={styles.searchInput}
            placeholder={localStrings.searchMemberByNameOrAccessoryId}
            trim
            value={searchKey}
            selectAllOnFocus
            onChange={handleChangeSearchKey}
            suffix={{
              name: "search",
              onClick: handleApplySearch,
            }}
          />
          {searchKey && (
            <Button
              iconClassName={styles.clearInputIcon}
              icon="close"
              className={styles.clearInput}
              text={localStrings.clear}
              onClick={handleClearSearch}
            />
          )}
        </form>
      </div>

      <div
        className={classnames(styles.filtersContainer, {
          [`${styles.active}`]: filter,
        })}
      >
        {children || null}
        <div className={styles.actions}>
          {hasFilters && (
            <Button
              secondary
              className={styles.clearFilters}
              text={localStrings.clearAllFilters}
              onClick={handleClearFilters}
            />
          )}

          <Button
            className={styles.applyFilters}
            text={localStrings.applyFilters}
            onClick={handleApplyFilter}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Filters);
