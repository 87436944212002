import React from "react";
import { updateSymptomLog, getSymptomLog } from "api/symptom";
import SymptomsForm from "../symptoms-form/symptoms-form";
import { useApi, useMount, useRouter } from "hooks";
import localStrings from "localization";
import useDeleteSymptoms from "../delete-symptoms/delete-symptoms";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "enums";

const EditLogSymptoms = ({ organizationId, userId, goBack }) => {
  const { query } = useRouter();
  const { symptomLogId } = query;
  console.log(query);
  const { request, loading: submitting } = useApi({
    api: updateSymptomLog,
    params: {
      userId,
      symptomLogId,
    },
  });

  const { render: ConfirmDeleteSymptom, show: confirmDeleteSymptom } = useDeleteSymptoms({
    refresh: () => goBack(),
  });

  const { request: getSymptom, loading = true, result } = useApi({
    api: () => getSymptomLog({ symptomId: symptomLogId }),
  });

  useMount(() => {
    const init = async () => {
      await getSymptom();
    };
    init();
  });

  return (
    <>
      {ConfirmDeleteSymptom}
      <SymptomsForm
        title={localStrings.editLogSymptoms}
        userId={userId}
        organizationId={organizationId}
        loading={loading}
        submitting={submitting}
        data={result?.data?.symptom}
        submitCallBack={(params) => {
          const update = async () => {
            mixpanel.track(Mixpanel.UPDATE_SYMPTOMS);
            await request({ params: { ...params, organizationId } });
            goBack();
          };
          update();
        }}
        edit
        goBack={goBack}
        showDeleteModal={() => confirmDeleteSymptom({ symptomId: symptomLogId })}
      />
    </>
  );
};

export default EditLogSymptoms;
