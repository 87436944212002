import React, { useContext } from "react";
import localString from "localization";
import styles from "./member-details.module.scss";
import { useApi, useMount } from "hooks";
import { OrganizationContext } from "contexts";
import Skeleton from "react-loading-skeleton";
import { getPrivacySettingsValue } from "api/organization";
import { formatDate } from "utils";
import classnames from "classnames";
import { Message } from "atomic/atoms";
import { Icon } from "atomic/atoms";
import { LocalTranslation } from "localization/localization";
import * as moment from "moment";

const MemberDetails = () => {
  const { organizationId } = useContext(OrganizationContext);
  const {
    request,
    loading = true,
    result: data = {},
  } = useApi({
    api: () =>
      getPrivacySettingsValue({
        organizationId,
      }),
  });
  const {
    request: requestPending,
    loading: loadingPending = true,
    result: currentData = {},
  } = useApi({
    api: getPrivacySettingsValue,
    params: {
      organizationId,
      currentPrivacy: true,
    },
  });
  const result = data?.data || {};
  const currentResult = currentData?.data || {};
  const keys = [
    {
      key: "isNameAndPhotoRequired",
      label: localString.nameAndProfilePhoto,
    },
    {
      key: "isEmailAndMobileNumberRequired",
      label: localString.emailAddressAndMobileNumber,
    },
    {
      key: "isWristbandRequired",
      label: localString.pouchBand,
    },
    {
      key: "isAboutYouRequired",
      label: localString.aboutYou,
    },
    {
      key: "isEmploymentDetailRequired",
      label: localString.employeeDetails,
    },
    {
      key: "isEmergencyContactRequired",
      label: localString.emergencyContact,
    },
    {
      key: "isBodyTemperatureRequired",
      label: localString.bodyTemperature,
    },
    {
      key: "isAreaLocationRequired",
      label: localString.areaLocation,
    },
    {
      key: "isLocationRequired",
      label: localString.geolocation,
    },
    {
      key: "isSymptomRequired",
      label: localString.symptoms,
    },
  ];

  useMount(() => {
    request();
    requestPending();
  });
  const latestInfoDate = moment(result?.startDate);
  const latestDate = latestInfoDate.format("MMM D, YYYY");
  const latestTime = latestInfoDate.format("h:mm A");
  return (
    <div>
      <Message
        className={styles.message}
        visible={!loadingPending && currentResult?.startDate !== result?.startDate}
        notif
      >
        <div className={styles.notif}>
          <div>
            <Icon className={styles.icon} name="info-fill" />
          </div>
          <div>
            <LocalTranslation
              text={localString.memberDetailsPendingMessage}
              items={[<b>{latestDate}</b>, <b>{latestTime}.</b>]}
            />
          </div>
        </div>
      </Message>
      <div className={styles.item}>
        <label>Effective as of</label>
        <div>{loading ? <Skeleton /> : formatDate(result?.startDate, "MMMM D, YYYY h:mm A")}</div>
      </div>
      <div>
        <div className={styles.row}>
          <label>SECTIONS</label>
          <label className={styles.statusRequirementLabel}>STATUS</label>
        </div>
        {keys.map((k) => {
          return (
            <div className={styles.row}>
              <div className={styles.keys}>{k.label}</div>
              <div
                className={classnames(styles.statusRequirement, {
                  [`${styles.required}`]: result && result[k.key],
                })}
              >
                {loading ? (
                  <Skeleton />
                ) : result && result[k.key] ? (
                  localString.required
                ) : (
                  localString.notRequired
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MemberDetails;
