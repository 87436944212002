import React, { useState, useMemo } from "react";
import styles from "./manage-organizations.module.scss";
import OrganizationImage from "images/organization.png";
import localStrings from "localization";
import { Tooltip } from "antd";
import { useRouter, useApi, useMount } from "hooks";
import { getOrganizations, removeIsNewOrganization } from "api/user-organization";
import { OrganizationCard, EmptyOrganization } from "components/organization";
import { arrangeOrganizationByNewAndCurrent } from "services";
import path from "path/path";
import { Image, Icon, Link } from "atomic/atoms";

const ManagerOrganizations = () => {
  const { history } = useRouter();
  const [organizations, setOrganizations] = useState([]);

  const { request: organizationRequest, loading } = useApi({
    api: () => getOrganizations({ isManagerOnly: true }),
  });

  useMount(() => {
    const init = async () => {
      try {
        const organizations = await organizationRequest();
        const arrangedOrganizations = arrangeOrganizationByNewAndCurrent(organizations);

        setOrganizations(arrangedOrganizations);
        removeIsNewOrganization();
      } catch (error) {
        console.error(error);
      }
    };
    init();
  });

  const orgContent = useMemo(() => {
    if (loading) {
      return [
        <OrganizationCard key={1} loading={true} />,
        <OrganizationCard key={2} loading={true} />,
        <OrganizationCard key={3} loading={true} />,
      ];
    } else {
      return organizations.length > 0 ? (
        organizations.map((o) => {
          if (o) {
            return (
              <OrganizationCard key={o.organizationId} organization={o} isCurrent={o.isCurrent} />
            );
          }
          return null;
        })
      ) : (
        <EmptyOrganization />
      );
    }
  }, [loading, organizations]);

  return (
    <div className={styles.container}>
      <div className={styles.leftCol}>
        <div className={styles.intro}>
          <div className={styles.orgYouManageContainer}>
            <div className={styles.header}>{localStrings.orgYouManage}</div>
            <Tooltip title={localStrings.viewMyOrgs}>
              <div>
                <Icon
                  className={styles.settings}
                  name="settings"
                  onClick={() => {
                    history.push(path.SETTINGS_MY_ORGANIZATION);
                  }}
                />
              </div>
            </Tooltip>
          </div>
          <div className={styles.description}>{localStrings.keepThisOrg}</div>
          <div className={styles.viewOrganization}>
            <div>{localStrings.whoCanView}</div>
            <Link
              className={styles.viewMyActiveOrg}
              text={localStrings.viewMyActiveOrg}
              onClick={() => {
                history.push(path.SETTINGS_MY_ORGANIZATION);
              }}
            />
          </div>
        </div>
        <Image src={OrganizationImage}></Image>
      </div>
      <div className={styles.rightCol}>
        <div className={styles.createOrg}>
          <div className={styles.createWorryFree}>{localStrings.createWorryFree}</div>
          <div className={styles.wantToCreate}>
            {localStrings.wantToCreateOrg}
            <Link
              className={styles.pouchpassLink}
              text="pouchpass.com"
              href={path.BUSINESS_SITE}
              target="_blank"
            />
            {localStrings.findOutMore}
          </div>
        </div>
        {orgContent}
      </div>
    </div>
  );
};

export default ManagerOrganizations;
