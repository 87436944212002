import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import { Modal, Table } from "atomic/atoms";
import styles from "./past-day-modal.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import { AppContext, ViewUserContext } from "contexts";
import { v4 as uuidv4 } from "uuid";
import { getTodayHourly } from "api/user-data";
import momentTimezone from "moment-timezone";
import moment from "moment";
import { computeTableScrollY, getLocalItem, formatTemperature, isNormalTemp } from "utils";
import { getLocation } from "utils/map";
import Skeleton from "react-loading-skeleton";

const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "time",
    title: localStrings.time,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "temperature",
    title: localStrings.temperature,
    width: 100,
  },
  {
    key: uuidv4(),
    dataIndex: "location",
    title: localStrings.location,
    width: 100,
    ellipsis: true,
  },
];

const PastDayModal = ({ className, userId, isMember }) => {
  const { dispatch: appDispatch } = useContext(AppContext);
  const { state, dispatch } = useContext(ViewUserContext);
  const { viewUserLocations, pastDayModal, isPastDetailsLoading } = state;
  const { visible, title, loading, data, date } = pastDayModal;
  const [scroll, setScroll] = useState({ y: 0 });
  const [locations, setLocations] = useState({});
  const userLocationsRef = useRef({
    ...viewUserLocations,
  });

  useEffect(() => {
    if (visible) {
      dispatch({ type: "FETCH_PAST_DAY_DETAILS" });
      getTodayHourly(userId, {
        date,
        timezone: momentTimezone.tz.guess(),
        organizationId: isMember ? getLocalItem("organizationId") : null,
      })
        .then((data) => {
          dispatch({ type: "SUCCESS_FETCH_PAST_DAY_DETAILS", data });
          setScroll({
            y: computeTableScrollY(document.querySelectorAll(`.${styles.table} tbody tr`), 10),
          });
        })
        .catch((err) => {
          dispatch({ type: "ERROR_FETCH_PAST_DAY_DETAILS" });
          const { networkError, serverError } = err;
          if (networkError) {
            appDispatch({ type: "NETWORK_ERROR" });
            return;
          }
          if (serverError) {
            appDispatch({ type: "SERVER_ERROR" });
            return;
          }
        });
    }
  }, [date, visible, dispatch, appDispatch, userId, isMember]);

  useEffect(() => {
    async function getLocationAsync(data) {
      for (const elem of data) {
        const { latitude: lat, longitude: lng } = elem;
        const location = [lat, lng].join(",");
        setLocations((prev) => ({
          ...prev,
          [location]: {
            loading: true,
          },
        }));
        if (userLocationsRef.current.hasOwnProperty(`${lat}, ${lng}`)) {
          setLocations((prev) => {
            return {
              ...prev,
              [location]: {
                loading: false,
                location: userLocationsRef.current[`${lat}, ${lng}`],
              },
            };
          });

          continue;
        }
        await getLocation({ lat, lng })
          .then((res) => {
            userLocationsRef.current[`${lat}, ${lng}`] = res;
            const location = [lat, lng].join(",");
            setLocations((prev) => {
              return {
                ...prev,
                [location]: {
                  loading: false,
                  location: res,
                },
              };
            });
          })
          .catch((e) => {
            userLocationsRef.current[`${lat}, ${lng}`] = "-";
            setLocations((prev) => {
              return {
                ...prev,
                [location]: {
                  loading: false,
                  location: "-",
                },
              };
            });
          });
      }
    }
    if (!isPastDetailsLoading) {
      getLocationAsync(data);
    }
  }, [data, isPastDetailsLoading]);

  const tableData = useMemo(() => {
    return data.map((d) => {
      const { hour, bodyTemp, latitude, longitude, location: areaLocation, areaId } = d;
      const location = (() => {
        const l = [latitude, longitude].join(",");
        const hasLocation = Boolean(latitude && longitude);
        if (areaId) {
          return areaLocation || "-";
        }
        if (!locations[l] || locations[l].loading) {
          if (hasLocation) {
            return <Skeleton />;
          } else {
            return "-";
          }
        } else {
          return locations[l].location;
        }
      })();
      return {
        key: uuidv4(),
        time: hour ? moment(hour, "hhA").format("hh A") : "-",
        temperature: (
          <div className={classnames({ cDanger: !isNormalTemp(bodyTemp) }, styles.temperature)}>
            {formatTemperature(bodyTemp)}
          </div>
        ),
        location,
      };
    });
  }, [data, locations]);

  const handleOnCancel = () => {
    dispatch({ type: "HIDE_PAST_DAY_MODAL" });
  };

  return (
    <Modal
      className={classnames(styles.container, className)}
      visible={visible}
      title={title}
      onCancel={handleOnCancel}
    >
      <Table
        className={styles.table}
        columns={tableColumns}
        loading={loading}
        data={tableData}
        scroll={scroll}
      />
    </Modal>
  );
};

export default PastDayModal;
