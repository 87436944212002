import React from "react";
import styles from "./past-organization-list.module.scss";
import localStrings from "localization";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { Avatar } from "atomic/molecules";

const PastOrganizationList = ({ organizations = [] }) => {
  return (
    organizations.length > 0 && (
      <div className={styles.pastOganizations}>
        <p className={styles.orgCountTitle}>
          {localStrings.pastOrganizations}{" "}
          <span className={styles.orgCount}>{organizations.length}</span>
        </p>
        <p className={styles.pastDesc}>{localStrings.pastOrganizationsDesc}</p>
        {organizations.map((d) => {
          const phrases = d.organizationName?.split(" ");
          return (
            <div className={styles.item} key={uuidv4()}>
              {
                <Avatar
                  showDefault={false}
                  image={d.organizationLogo}
                  className={styles.avatar}
                  phrases={phrases}
                />
              }

              <div className={styles.details}>
                <h5 className={styles.orgName}>{d.organizationName}</h5>
                <div className={styles.positionDetails}>
                  <span className={styles.dateJoined}>
                    {localStrings.formatString(
                      localStrings.memberFromUntil,
                      moment(d.dateJoined).format("MMMM DD, YYYY"),
                      moment(d.dateLeft).format("MMMM DD, YYYY")
                    )}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    )
  );
};

export default PastOrganizationList;
