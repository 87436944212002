import { Temperature } from "enums";
import { getLocalItem, setLocalItem } from "utils";

export const convertTemperature = (t, type = "") => {
  const temp = Number(t);
  if (!type) {
    type = getTemperatureType();
  }
  switch (type) {
    case Temperature.Celsius:
      if (temp) {
        return Number(temp).toFixed(1);
      }
      return "";
    case Temperature.Fahrenheit:
      if (temp) {
        return Number((temp * 9) / 5 + 32).toFixed(1);
      }
      return "";
    default:
      return "";
  }
};

export const getTemperatureType = () => {
  return getLocalItem("temperatureType") || Temperature.Celsius;
};

export const setTemperatureType = (temperature) => {
  setLocalItem("temperatureType", temperature);
};

export const isLowTemp = (temp, type = getTemperatureType()) => {
  if (type === Temperature.Fahrenheit) {
    return temp <= 95.0;
  }
  return temp <= 35;
};

export const isNormalTemp = (temp, type = getTemperatureType()) => {
  if (type === Temperature.Fahrenheit) {
    return temp >= 95.1 && temp <= 99.5;
  }
  return temp >= 35.1 && temp <= 37.5;
};

export const isAboveNormalTemp = (temp, type = getTemperatureType()) => {
  if (type === Temperature.Fahrenheit) {
    return temp >= 99.6 && temp <= 100.6;
  }
  return temp >= 37.6 && temp <= 38.1;
};

export const isHighTemp = (temp, type = getTemperatureType()) => {
  if (type === Temperature.Fahrenheit) {
    return temp >= 100.7 && temp <= 102.2;
  }
  return temp >= 38.2 && temp <= 39.0;
};

export const isAboveHighTemp = (temp, type = getTemperatureType()) => {
  if (type === Temperature.Fahrenheit) {
    return temp >= 102.3 && temp <= 105.8;
  }
  return temp >= 39.1 && temp <= 41.0;
};

export const getTemperatureStatus = (temperature) => {
  const temp = Number(convertTemperature(temperature));

  if (isLowTemp(temp)) {
    return "LOW";
  }
  if (isNormalTemp(temp)) {
    return "NORMAL";
  }

  if (isAboveNormalTemp(temp)) {
    return "HIGHER THAN NORMAL";
  }

  if (isHighTemp(temp)) {
    return "HIGH";
  }

  if (isAboveHighTemp(temp)) {
    return "VERY HIGH";
  }

  return "EXTREMELY HIGH";
};

export const getTempSymbol = (temp = "") => {
  if (!temp) {
    temp = getTemperatureType();
  }
  switch (temp) {
    case Temperature.Celsius:
      return "°C";
    case Temperature.Fahrenheit:
      return "°F";
    default:
      return "°C";
  }
};

export const formatTemperature = (temp = null, type = getTemperatureType()) => {
  if (!type) {
    type = getTemperatureType();
  }
  return `${temp ? convertTemperature(temp) : "--"} ${getTempSymbol()}`;
};
