export const cleanTableFilterTracking = (filters) => {
  const keysToBeDeleted = ["page", "pageSize", "searchKey", "organizationId"];

  keysToBeDeleted.forEach((key) => {
    if (filters.hasOwnProperty(key)) {
      delete filters[key];
    }
  });

  return filters;
};
