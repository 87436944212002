import { getUser } from "api/user";
import { Button, InputField } from "atomic/molecules";
import { Field } from "enums";
import { useFormV2 } from "hooks";
import React, { useState } from "react";
import Validation from "services/validation.service";

const FormContainer = () => {
  const initialState = {
    email: { type: Field.INPUT, value: "", validations: [Validation.required()] },
    password: { value: "", type: Field.INPUT, validations: [Validation.required()] },
  };
  const [test, setTest] = useState(initialState);

  const { fields, modifyForm, modifyField, submitForm, applyFieldErrors } = useFormV2({
    initialState: test,
  });

  const submitCallback = (fields) => {
    console.log(fields);
    const res = getUser();
    console.log(res);
    return res;
  };

  return (
    <div>
      <Button
        text="Modify Form"
        onClick={() => {
          modifyForm({ email: { value: "dd@pouchnation.com" }, gender: { value: "M" } });
        }}
      />
      <Button
        text="Submit Form"
        onClick={async () => {
          await submitForm(submitCallback);
        }}
      />
      <Button
        text="Apply Field Error"
        onClick={() => {
          const { fields } = applyFieldErrors({ email: "Invalid email" });
          console.log(fields);
        }}
      />
      <Button
        text="Test"
        onClick={() => {
          // const { fields } = applyFieldErrors({ email: "Invalid email" });
          // console.log(fields);
          setTest({
            email: { type: Field.INPUT, value: "DOM", validations: [Validation.required()] },
            password: { value: "DOM", type: Field.INPUT, validations: [Validation.required()] },
          });
        }}
      />
      <InputField
        value={fields.email.value}
        error={fields.email.error}
        onChange={(val) => {
          modifyField("email", { value: val });
        }}
      />
      <InputField
        value={fields.password.value}
        error={fields.password.error}
        onChange={(val) => {
          modifyForm({ password: { value: val } });
        }}
      />
    </div>
  );
};

export default FormContainer;
