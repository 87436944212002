export const initialState = {
  users: {
    loading: true,
    error: false,
    data: [],
  },
  focusUser: null,
  currentLocation: {
    loading: true,
    latitude: null,
    longitude: null,
  },
  radius: 4622,
  zoom: 22,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "FETCH_USERS":
      return {
        ...state,
        loading: true,
        users: {
          ...state.users,
          loading: true,
          error: false,
        },
      };
    case "SUCCESS_FETCH_USERS": {
      const { users } = state;
      return {
        ...state,
        loading: false,
        users: {
          ...users,
          data: [...action.data],
          loading: false,
        },
      };
    }

    case "ERROR_FETCH_USERS":
      return {
        ...state,
        users: {
          ...state.users,
          error: true,
          loading: false,
        },
      };

    case "FOCUS_TO_USER":
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          ...action.data,
          loading: false,
        },
      };
    case "CHANGE_CURRENT_LOCATION":
      console.log(action);
      return {
        ...state,
        currentLocation: {
          ...state.currentLocation,
          ...action.data,
          loading: false,
        },
        radius: action.data?.meters || state.radius,
        ...action.state,
      };
    case "CHANGE_RADIUS":
      return {
        ...state,
        radius: action.data || state.radius,
        zoom: action.zoom,
      };
    default:
      throw new Error("No action type found");
  }
};

export default reducer;
