import { Post, Get } from "./api";
import { ApiRoutes, Routes } from "enums";
import { setLocalItem, removeLocalItem } from "utils";

export const login = async ({ email, password }) => {
  const res = await Post(ApiRoutes.LOGIN, {
    clientId: "CONSUMER_DASHBOARD",
    grantType: "email",
    email,
    password,
  });
  if (res.data && res.data.accessToken) {
    setLocalItem("accessToken", res.data.accessToken);
  }
  return res;
};

export const logout = async () => {
  const res = await Post(ApiRoutes.LOGOUT);
  removeLocalItem("accessToken");
  return res;
};

export const forgotPassword = async (email) => {
  return Get(`${Routes.PROFILE}/${Routes.FORGOT_PASSWORD}`, {
    email,
    platform: "HEALTH",
  });
};

export const resetPassword = async ({ token, password }) => {
  return Post(`${Routes.PROFILE}/${Routes.RESET_PASSWORD}`, {
    token,
    password,
  });
};

export const verifyEmailOTP = ({ email, passCode, version = "v2.1.1" }) => {
  return Post(`${Routes.PROFILE}/${Routes.AUTH}/${Routes.EMAIL}/${Routes.VERIFY}`, {
    email,
    passCode,
    version,
  });
};

export const sendEmailOTP = ({ email, firstName }) => {
  return Post(`${Routes.PROFILE}/${Routes.AUTH}/${Routes.EMAIL}`, {
    email,
    firstName,
  });
};
