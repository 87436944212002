import React, { useCallback, useMemo } from "react";
import { LayoutB } from "layouts";
import { CommunityForm } from "components/community";
import { Toast } from "atomic/molecules";
import { useFormV2, useFileUploader, useRouter, useModal, useApi } from "hooks";
import initialFormState from "./initial-form-state";
import { ActionButton } from "components/common";
import path from "path/path";
import { LeaveModal } from "atomic/organisms";
import localStrings from "localization";
import { searchOrganizations } from "api/organization";

const CommunityFormContainer = ({
  title,
  initialState = undefined,
  saveSubmit,
  strings,
  submitting,
  isActiveManagerEditable,
  isResendInviteAvailable,
  resendInvite,
  resendingInvite,
}) => {
  const { history } = useRouter();
  const leavePageModal = useModal({});
  const formState = useMemo(() => {
    return initialFormState(initialState, {
      isActiveManagerEditable,
    });
  }, [initialState, isActiveManagerEditable]);

  const { request: searchNameRequest, loading: validatingName } = useApi({
    api: searchOrganizations,
  });

  const form = useFormV2({
    initialState: formState,
  });

  const { request: requestFileUploader, loading: uploadingFile } = useFileUploader();

  const { dirty, submitForm, getFormValues, applyFieldErrors, isFormSubmittable } = form;

  const onCancel = () => {
    if (dirty) {
      leavePageModal.show();
      return false;
    }
    leavePage();
  };

  const leavePage = () => {
    history.push(path.COMMUNITY_LIST);
  };

  const submit = async () => {
    const params = getFormValues();

    const sf = (p = {}) => {
      saveSubmit(
        {
          params: {
            ...params,
            ...p,
            organizationName: params.communityName,
            email: params.email || null,
          },
        },
        (data, d) => {
          Toast({
            content: d.message,
            success: true,
            visible: true,
            icon: "check-fill",
          }).open();
          leavePage();
        },
        (error) => {
          const { metadata } = error;
          const errors = {
            8001: () => {
              applyFieldErrors({
                communityName: localStrings.communityAlreadyExist,
              });
            },
          };
          if (errors[metadata.code]) {
            errors[metadata.code]();
          }
        }
      );
    };
    if (params.communityAvatar.file) {
      try {
        const uploadedImage = await requestFileUploader({
          file: params.communityAvatar.file.originFileObj,
        });
        sf({
          communityAvatar: uploadedImage.permanentUrl,
          organizationLogo: uploadedImage.permanentUrl,
        });
      } catch (e) {
        console.log("Failed to upload image.", e);
      }
      return;
    }
    sf({
      communityAvatar: params.communityAvatar.image,
      organizationLogo: params.communityAvatar.image,
    });
  };

  const checkIfNameExists = useCallback(
    async (name) => {
      console.log(initialState);
      if (name && initialState?.communityName !== name) {
        const res = await searchNameRequest({ organizationName: name });
        if (res?.data?.length > 0) {
          applyFieldErrors({
            communityName: localStrings.communityAlreadyExist,
          });
        }
      }
    },
    [searchNameRequest, applyFieldErrors, initialState]
  );

  return (
    <LayoutB
      title={title}
      onBackPage={{
        onClick: () => {
          onCancel();
        },
      }}
    >
      <LeaveModal
        {...leavePageModal}
        onClose={() => {
          leavePageModal.close();
        }}
        onLeave={() => {
          leavePageModal.close();
          leavePage();
        }}
      />
      <CommunityForm
        isResendInviteAvailable={isResendInviteAvailable}
        resendInvite={resendInvite}
        resendingInvite={resendingInvite}
        isActiveManagerEditable={isActiveManagerEditable}
        checkIfNameExists={checkIfNameExists}
        validatingName={validatingName}
        {...form}
      />
      <ActionButton
        submitText={strings.submitText}
        disabled={!isFormSubmittable}
        onSubmit={() => submitForm(submit)}
        loading={uploadingFile || submitting}
        onCancel={onCancel}
      />
    </LayoutB>
  );
};

export default CommunityFormContainer;
