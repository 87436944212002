import { Icon, Textarea } from "atomic/atoms";
import { Button, Field, Panel } from "atomic/molecules";
import React, { useCallback, useState } from "react";
import styles from "./pouchband-registry-listing.module.scss";
import localStrings from "localization";
import { Fragment } from "react";
import { ConfirmationModal } from "atomic/organisms";
import { useModal, useApi } from "hooks";
import { createDevices, deleteDevices } from "api/user-device";
import { v4 as uuidv4 } from "uuid";

const PouchBandRegistryListingContainer = ({ refreshList }) => {
  const actionType = { list: "LIST", unlist: "UNLIST" };
  const initialResult = { active: false, type: null, errors: [], total: 0 };

  const [accessoryIds, setAccessoryIds] = useState("");
  const [resultStatus, setResultStatus] = useState(initialResult);
  const [processStatus, setProcessStatus] = useState({
    processing: false,
    progress: 0,
    total: 0,
    type: null,
  });

  const { request: unlistDevices } = useApi({
    api: deleteDevices,
  });

  const { request: listDevices } = useApi({
    api: createDevices,
  });

  const {
    show: showUnlistModal,
    close: closeUnlistModal,
    visible: isUnlistModalVisible,
  } = useModal({});

  const onChangeCb = useCallback((value) => {
    let formattedString = value;

    formattedString = formattedString.replace(/[^a-z0-9\n:, ]/gi, "");
    setAccessoryIds(formattedString);
  }, []);

  // const onPasteCb = useCallback(
  //   (e) => {
  //     let pastedString = e.clipboardData.getData("text");

  //     // pastedString = pastedString.replace(/\t/g, "\n");
  //     // pastedString = pastedString.replace(/,/g, "\n");
  //     e.preventDefault();

  //     // const newAccessoryIds = `${accessoryIds}${pastedString}`.substr(0, 255);

  //     // setAccessoryIds(newAccessoryIds);
  //     setAccessoryIds(pastedString);
  //   },
  //   [accessoryIds]
  // );

  const onBlurCb = useCallback((value) => {
    let formattedString = value;

    formattedString = formattedString.replace(/,/g, "\n");
    formattedString = formattedString.replace(/\t/g, "\n");
    formattedString = formattedString.replace(/ /g, "\n");
    formattedString = formattedString.replace(/  +/g, "\n");
    formattedString = formattedString.replace(/[^a-z0-9\n]/gi, "");
    formattedString = formattedString.replace(/\n\s*\n/g, "\n");
    formattedString = formattedString.toUpperCase();

    let arrString = formattedString.trim().split("\n");
    arrString = arrString
      .map((s) => {
        return s.substring(0, 12);
      })
      .slice(0, 50);

    setAccessoryIds(arrString.join("\n"));
  }, []);

  const getBatchResponseErrors = useCallback((result) => {
    let failedIds = [],
      invalidIds = [],
      duplicateIds = [],
      notFoundIds = [];

    if (result.hasOwnProperty("failed")) {
      failedIds = result.failed.map((id) => {
        return { id, error: "Failed" };
      });
    }

    if (result.hasOwnProperty("invalidFormat")) {
      invalidIds = result.invalidFormat.map((id) => {
        return { id, error: "Invalid" };
      });
    }

    if (result.hasOwnProperty("duplicate")) {
      duplicateIds = result.duplicate.map((id) => {
        return { id, error: "Duplicate" };
      });
    }

    if (result.hasOwnProperty("notFound")) {
      notFoundIds = result.notFound.map((id) => {
        return { id, error: "Not Found" };
      });
    }

    return [...failedIds, ...invalidIds, ...duplicateIds, ...notFoundIds];
  }, []);

  const batchRequest = useCallback(
    async (request, accessoryIds, batchSize = 10, actionType) => {
      let i,
        j,
        arr,
        chunk = batchSize;
      let batchErrors = [];

      for (i = 0, j = accessoryIds.length; i < j; i += chunk) {
        arr = accessoryIds.slice(i, i + chunk);
        const chunkCount = i + chunk;
        setProcessStatus({
          processing: true,
          progress: accessoryIds.length <= chunkCount ? accessoryIds.length : chunkCount,
          total: accessoryIds.length,
          type: actionType,
        });
        const res = await request({ accessoryIds: arr });
        batchErrors = [...batchErrors, ...getBatchResponseErrors(res)];
      }

      return batchErrors;
    },
    [setProcessStatus, getBatchResponseErrors]
  );

  const onListCb = useCallback(async () => {
    const accessoryIdsArr = accessoryIds.split("\n");

    try {
      const batchErrors = await batchRequest(listDevices, accessoryIdsArr, 20, actionType.list);

      setResultStatus({
        active: true,
        type: actionType.list,
        errors: batchErrors,
        total: accessoryIdsArr.length - batchErrors.length,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setProcessStatus({ ...processStatus, processing: false, progress: 0, total: 0 });
      refreshList();
      setAccessoryIds("");
    }
  }, [
    accessoryIds,
    batchRequest,
    listDevices,
    setProcessStatus,
    processStatus,
    actionType,
    refreshList,
  ]);

  const showUnlistModalCb = useCallback(() => {
    showUnlistModal();
  }, [showUnlistModal]);

  const onUnlistCb = useCallback(async () => {
    closeUnlistModal();
    const accessoryIdsArr = accessoryIds.split("\n");

    try {
      const batchErrors = await batchRequest(unlistDevices, accessoryIdsArr, 20, actionType.unlist);

      const unlistedCount = accessoryIdsArr.length - batchErrors.length;
      setResultStatus({
        active: true,
        type: actionType.unlist,
        errors: batchErrors,
        total: unlistedCount,
      });
    } catch (e) {
      console.log(e);
    } finally {
      setProcessStatus({ ...processStatus, processing: false, progress: 0, total: 0 });
      refreshList();
      setAccessoryIds("");
    }
  }, [
    accessoryIds,
    actionType,
    closeUnlistModal,
    unlistDevices,
    refreshList,
    batchRequest,
    processStatus,
  ]);

  const onCloseResultCb = useCallback(() => {
    setResultStatus(initialResult);
  }, [initialResult]);

  return (
    <Fragment>
      <Panel
        className={styles.container}
        title={localStrings.listUnlistAccessoryId}
        description={localStrings.listUnlistPouchBand}
      >
        {processStatus.processing ? (
          <div className={styles.loadingContainer}>
            <div className={styles.loadingText}>
              {processStatus.type === actionType.list ? "Listing" : "Unlisting"}{" "}
              {processStatus.progress} of {processStatus.total}...
            </div>
            <Icon loading={true} />
          </div>
        ) : (
          <div>
            {resultStatus.active && (
              <div className={styles.result}>
                <div className={styles.resultHeader}>
                  <div className={styles.titleContainer}>
                    <Icon name="check-fill" className={styles.titleIcon} />
                    <p className={styles.title}>
                      {resultStatus.type === actionType.list ? "Listed" : "Unlisted"}{" "}
                      {resultStatus.total} Accessory {resultStatus.total > 1 ? "IDs" : "ID"}
                    </p>
                  </div>
                  <Icon className={styles.close} name="close" onClick={onCloseResultCb} />
                </div>
                {resultStatus.errors.length > 0 && (
                  <Fragment>
                    <p className={styles.description}>
                      <b>
                        {resultStatus.errors.length}{" "}
                        {resultStatus.errors.length > 1 ? "entries" : "entry"}
                      </b>{" "}
                      could not be {resultStatus.type === actionType.list ? "listed" : "unlisted"}
                    </p>
                    <div className={styles.errorContainer}>
                      <table className={styles.errorTable}>
                        <thead>
                          <tr>
                            <th>PouchBAND Accessory ID</th>
                            <th>Error</th>
                          </tr>
                        </thead>
                        <tbody>
                          {resultStatus.errors.map((error) => {
                            return (
                              <tr key={uuidv4()}>
                                <td>{error.id.toUpperCase()}</td>
                                <td>{error.error}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </Fragment>
                )}
              </div>
            )}

            <Field>
              <Textarea
                maxLength={650}
                value={accessoryIds}
                // onPaste={onPasteCb}
                onChange={onChangeCb}
                onBlur={onBlurCb}
                placeholder={localStrings.copyPasteAccessoryId}
              ></Textarea>
            </Field>
            <div className={styles.actionButton}>
              <Button
                disabled={accessoryIds.length <= 0}
                onClick={onListCb}
                className={styles.list}
                text={localStrings.list}
              />
              <Button
                disabled={accessoryIds.length <= 0}
                onClick={showUnlistModalCb}
                danger
                text={localStrings.unlist}
              />
            </div>
          </div>
        )}
      </Panel>
      <ConfirmationModal
        close={closeUnlistModal}
        active={isUnlistModalVisible}
        title={localStrings.unlistAccessoryIds}
        onOkText={localStrings.unlist}
        onConfirm={onUnlistCb}
      >
        <p className={styles.message}>{localStrings.areYouSureUnlistAccessoryIds}</p>
      </ConfirmationModal>
    </Fragment>
  );
};

export default PouchBandRegistryListingContainer;
