import React, { useCallback, useEffect, useRef } from "react";
import styles from "./symptoms-form.module.scss";
import { useDiscardModal } from "components/common";
import { MedicalTestForm } from "components/users";
import { SymptomButtons } from "components/profile";
import moment from "moment";
import initialFormState from "./initial-form-state";
import { useForm, useRouter } from "hooks";
import { Common } from "enums";
import localStrings from "localization";
import { capitalize } from "utils";
import {
  Panel,
  DatePickerField,
  TimePickerField,
  Button,
  CheckboxField,
  TextareaField,
  BackLink,
  Toast,
} from "atomic/molecules";

const now = moment();

const SymptomsForm = ({
  edit,
  loading,
  data,
  showDeleteModal,
  submitCallBack,
  title,
  goBack,
  userId,
  submitting,
}) => {
  const {
    formFields,
    formFieldsValue,
    setFormFieldValue,
    setFormFieldValues,
    setFormFieldsRequired,
    clearFormFieldsError,
    isFormDirty,
    submit,
  } = useForm(initialFormState, (formValues) => {
    const { date, time, symptoms, testTakenDate, testResultDate, testResult, ...rest } = formValues;
    const datetime = moment(`${date.format("MM/DD/YYYY")} ${time.format("hh:mm A")}`);
    const zone = datetime.format("Z").replace(":", "");
    const dateTime = `${datetime.format("YYYY-MM-DDTHH:mm:00.000")}${zone}`;
    submitCallBack({
      dateTime,
      symptoms,
      testTakenDate: testTakenDate && testTakenDate.format("MM/DD/YYYY"),
      testResultDate: testResultDate && testResultDate.format("MM/DD/YYYY"),
      testResult: capitalize(testResult),
      ...rest,
    });
  });

  const { date, time, isMedicalTest, additionalInfo } = formFields;
  const {
    isMedicalTest: isMedicalTestValue,
    symptoms: symptomsValue,
    additionalInfo: additionaInfoValue,
    testTakenDate: testTakenDateValue,
  } = formFieldsValue;

  const { history } = useRouter();
  const initEditRef = useRef(true);

  const { render: DiscardModal, show: showDiscardModal } = useDiscardModal({
    discardChanges: () => goBack(),
  });

  const handleCancel = () => {
    if (isFormDirty) {
      showDiscardModal();
      return;
    }
    goBack();
  };

  const handleOnClickSymptoms = useCallback(
    (symptom) => {
      const symptomExists = symptomsValue.includes(symptom);
      let value = [];
      if (symptomExists) {
        value = symptomsValue.filter((d) => d !== symptom);
      } else {
        value = [...symptomsValue, symptom];
      }
      setFormFieldValue(value, "symptoms");
      setFormFieldsRequired({
        additionalInfo: !value.length,
      });
    },
    [setFormFieldValue, setFormFieldsRequired, symptomsValue]
  );

  const handleOnChangeMedicalTest = useCallback(
    (value, name) => {
      let requiredFields = {
        medicalTestType: false,
        testAddress: false,
        testTakenDate: false,
        testResult: false,
      };
      if (value) {
        requiredFields = {
          ...requiredFields,
          medicalTestType: true,
          testAddress: true,
          testTakenDate: true,
          testResult: !!testTakenDateValue,
        };
      }
      setFormFieldsRequired(requiredFields);
      setFormFieldValue(value, name);
    },
    [setFormFieldsRequired, setFormFieldValue, testTakenDateValue]
  );

  const handleOnChangeAdditionalInfo = useCallback(
    (value, name) => {
      setFormFieldsRequired({
        symptoms: !value,
      });
      setFormFieldValue(value, name);
    },
    [setFormFieldsRequired, setFormFieldValue]
  );

  const handleSubmit = () => {
    if (!symptomsValue.length && !additionaInfoValue) {
      Toast({
        visible: true,
        error: true,
        content: localStrings.pleaseFillUpSymptom,
        icon: "info-fill",
      }).open();
      return;
    }
    submit();
  };

  useEffect(() => {
    if (!isMedicalTestValue) {
      setFormFieldsRequired({
        testTakenDate: false,
        testAddress: false,
        medicalTestType: false,
        otherTestName: false,
        testResultDate: false,
        testResult: false,
      });
      setFormFieldValues({
        testTakenDate: null,
        testAddress: null,
        medicalTestType: null,
        otherTestName: null,
        testResultDate: null,
        testResult: null,
      });
    }
  }, [isMedicalTestValue, setFormFieldValues, setFormFieldsRequired]);

  useEffect(() => {
    if (initEditRef.current && edit && !loading) {
      const dataKeys = Object.keys(data);
      const values = dataKeys.reduce((acc, cur) => {
        return {
          ...acc,
          [cur]: data[cur],
        };
      }, {});

      const {
        dateTime,
        symptoms,
        testTakenDate,
        testResultDate,
        additionalInfo,
        isMedicalTest,
        testResult,
        medicalTestType,
        ...rest
      } = values;

      // if (
      //   (!isMember && values?.userLoggedBy !== Number(userId)) ||
      //   (isMember && values?.userLoggedBy === Number(userId))
      // ) {
      //   history.push("/");
      // }

      setFormFieldsRequired({
        additionalInfo: !symptoms?.length,
        symptoms: !additionalInfo,
        testTakenDate: isMedicalTest,
        testAddress: isMedicalTest && !!testTakenDate,
        testResultDate: isMedicalTest && !!testResult,
        otherTestName: medicalTestType === Common.OTHER,
        testResult: !!testResultDate,
      });
      setFormFieldValues({
        date: moment(dateTime),
        time: moment(dateTime),
        symptoms: symptoms ? symptoms : [],
        testTakenDate: testTakenDate ? moment(testTakenDate) : null,
        testResultDate: testResultDate ? moment(testResultDate) : null,
        isMedicalTest,
        testResult: testResult?.toString().toUpperCase(),
        additionalInfo,
        medicalTestType,
        ...rest,
      });
      initEditRef.current = false;
    }
  }, [loading, edit, data, setFormFieldValues, setFormFieldsRequired, history, userId]);
  return (
    <div className={styles.container}>
      {DiscardModal}
      <div className={styles.header}>
        <BackLink className={styles.backLink} onClick={handleCancel} text={localStrings.back} />
        <h2 className={styles.title}>{title}</h2>
      </div>

      <h3 className={styles.panelTitle}>{localStrings.details}</h3>
      <Panel className={styles.detailsPanel}>
        <DatePickerField
          name="date"
          allowClear={false}
          value={now}
          label={localStrings.date}
          onChange={setFormFieldValue}
          placeholder="MM/DD/YYYY"
          loading={loading}
          {...date}
        />
        <TimePickerField
          name="time"
          value={now}
          label={localStrings.time}
          allowClear={false}
          onChange={setFormFieldValue}
          loading={loading}
          {...time}
        />
      </Panel>
      <h3 className={styles.panelTitle}>{localStrings.symptoms}</h3>
      <Panel>
        <h4 className={styles.sympTitle}>{localStrings.selectFollowingSymptoms}</h4>
        <SymptomButtons
          handleOnClickSymptoms={handleOnClickSymptoms}
          symptomsValue={symptomsValue}
        />
      </Panel>
      <h3 className={styles.panelTitle}>{localStrings.medicalTest}</h3>
      <Panel className={styles.medicalPanel}>
        <CheckboxField
          name="isMedicalTest"
          className={styles.medicalCheckbox}
          label={localStrings.hasTheUserTakenAnyMedicalTest}
          onChange={handleOnChangeMedicalTest}
          {...isMedicalTest}
        />
        {isMedicalTestValue && (
          <MedicalTestForm
            formFields={formFields}
            setFormFieldValue={setFormFieldValue}
            setFormFieldValues={setFormFieldValues}
            setFormFieldsRequired={setFormFieldsRequired}
            clearFormFieldsError={clearFormFieldsError}
          />
        )}
      </Panel>
      <h3 className={styles.panelTitle}>{localStrings.additionalInfo}</h3>
      <Panel>
        <TextareaField
          className={styles.logOtherSymptoms}
          placeholder={localStrings.logOtherSymptoms}
          name="additionalInfo"
          onChange={handleOnChangeAdditionalInfo}
          showCount
          {...additionalInfo}
        />
      </Panel>
      <div className={styles.actions}>
        {edit && (
          <Button
            danger
            text={localStrings.deleteLog}
            className={styles.delete}
            onClick={showDeleteModal}
          />
        )}
        <Button
          tertiary
          text={localStrings.cancel}
          className={styles.cancel}
          onClick={handleCancel}
        />
        <Button text={localStrings.saveChanges} onClick={handleSubmit} loading={submitting} />
      </div>
    </div>
  );
};

export default SymptomsForm;
