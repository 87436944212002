import React from "react";
import styles from "./diary.module.scss";
import Thermometer from "images/thermometer-2.svg";
import { Image } from "atomic/atoms";
import { FailedToLoad } from "atomic/organisms";

const DiaryPlaceholder = ({ description, error, title, header }) => {
  return (
    <>
      <div className={styles.headerContainer}>
        {header && <h2 className={styles.header}>{header}</h2>}
      </div>

      <div className={styles.placeholderContainer}>
        {error ? (
          <FailedToLoad />
        ) : (
          <>
            <Image src={Thermometer} className={styles.image} />

            <h2 className={styles.title}>{title}</h2>
            <p className={styles.desc}>{description}</p>
          </>
        )}
      </div>
    </>
  );
};

export default DiaryPlaceholder;
