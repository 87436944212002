import React from "react";
import styles from "./unable-to-retrieve.module.scss";
import { ReactComponent as UnableToRet } from "images/unable-to-retrieve-data.svg";
import localStrings from "localization";
import classnames from "classnames";
const UnableToRetrieveError = ({ className, noDesc }) => {
  return (
    <div className={classnames(styles.container, className)}>
      <UnableToRet className={styles.image} />
      <div className={classnames(styles.message, "message")}>
        <h2 className={classnames(styles.title, "title")}>{localStrings.unableToRetrieveData}</h2>
        {!noDesc && (
          <p className={classnames(styles.desc, "desc")}>{localStrings.unableToRetrieveDataDesc}</p>
        )}
      </div>
    </div>
  );
};

export default UnableToRetrieveError;
