import React from "react";
import classnames from "classnames";
import { Button as SimpleButton, Icon } from "atomic/atoms";
import styles from "./button.module.scss";

const Button = ({
  className,
  right = true,
  loading,
  icon,
  iconClassName,
  children,
  text,
  disabled,
  ...buttonProps
}) => {
  const iconElement = (icon || loading) && (
    <Icon
      className={classnames(styles.icon, iconClassName, {
        loading: loading,
        [`${styles.right}`]: right,
      })}
      name={icon}
      loading={loading}
    />
  );
  return (
    <SimpleButton disabled={disabled || loading} className={className} {...buttonProps}>
      <>
        {!right && iconElement}
        {children || text}
        {right && iconElement}
      </>
    </SimpleButton>
  );
};

export default Button;
