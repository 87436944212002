import React, { useMemo, useEffect } from "react";
import styles from "./password-strength.module.scss";
import classnames from "classnames";
import localString from "localization";

const passwordStrengthKeys = {
  GREAT: localString.great,
  AVERAGE: localString.average,
  WEAK: localString.weak,
  POOR: localString.poor,
};

const average = (text) => {
  let t = 0;
  if (new RegExp("([a-z])").test(text) || new RegExp("([A-Z])").test(text)) {
    t++;
  }
  [new RegExp("[^a-z0-9A-Z]"), new RegExp("([0-9])")].forEach((reg) => {
    if (reg.test(text)) {
      t++;
    }
  });
  return t === 3 ? "AVERAGE" : null;
};

const great = (text) => {
  let t = 0;
  [
    new RegExp("[^a-z0-9]"),
    new RegExp("([a-z])"),
    new RegExp("([A-Z])"),
    new RegExp("([0-9])"),
  ].forEach((reg) => {
    if (reg.test(text)) {
      t++;
    }
  });
  return t === 4 ? "GREAT" : null;
};

const weak = (text) => {
  let t = 0;
  [new RegExp("([a-z])"), new RegExp("([0-9])")].forEach((reg) => {
    if (reg.test(text)) {
      t++;
    }
  });
  return t === 2 ? "WEAK" : null;
};

const PasswordStrength = ({ password, onChange = () => {}, className }) => {
  const passwordStrength = useMemo(() => {
    if (password) {
      if (password.length < 8) {
        return "POOR";
      }
      let status = null;

      [great, average, weak].forEach((t) => {
        const test = t(password);
        if (test && !status) {
          status = test;
        }
      });

      return status || "WEAK";
    }
  }, [password]);

  useEffect(() => {
    onChange(passwordStrength);
  }, [onChange, passwordStrength]);

  if (!password) {
    return "";
  }

  return (
    <div
      className={classnames(
        styles.passwordStrength,
        passwordStrength,
        className
      )}
    >
      <div className={styles.passwordBars}>
        <div className={classnames(styles.bar, styles[passwordStrength])}></div>
        {/* <div
          className={classnames(
            styles.bar,
            styles[passwordStrength],
            styles.second
          )}
        ></div>
        <div
          className={classnames(
            styles.bar,
            styles[passwordStrength],
            styles.third
          )}
        ></div>
        <div
          className={classnames(
            styles.bar,
            styles[passwordStrength],
            styles.fourth
          )}
        ></div> */}
      </div>
      <div
        className={classnames(
          styles[passwordStrength],
          styles.passwordStrengthLabel
        )}
      >
        {passwordStrengthKeys[passwordStrength]}
      </div>
    </div>
  );
};

export default PasswordStrength;
