import React from "react";
import styles from "./employment-history-form.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import { useRouter } from "hooks";
import { SubmitStep } from "components/onboarding";
import path from "path/path";
import { InputField, SelectField, TextareaField } from "atomic/molecules";

const EmploymentHistoryForm = ({
  className,
  formFields,
  setFormFieldValue,
  countryCodes,
  loading,
  onSubmit,
}) => {
  const { history } = useRouter();

  return (
    <div className={classnames(styles.container, className)}>
      <h1>{localStrings.employmentDetails}</h1>
      <p>{localStrings.checkEmploymentDetails}</p>
      <div className={styles.content}>
        <div className={styles.input}>
          <SelectField
            showSearch
            data={[
              {
                text: localStrings.worker,
                value: localStrings.worker,
              },
              {
                text: localStrings.employed,
                value: localStrings.employed,
              },
              {
                text: localStrings.selfEmployed,
                value: localStrings.selfEmployed,
              },
              {
                text: localStrings.unemployed,
                value: localStrings.unemployed,
              },
            ]}
            name="employmentStatus"
            label={localStrings.employmentStatus}
            onChange={setFormFieldValue}
            {...formFields.employmentStatus}
            placeholder={localStrings.selectEmploymentStatus}
          />
        </div>
        <div className={styles.input}>
          <InputField
            name="company"
            label={localStrings.company}
            onChange={setFormFieldValue}
            {...formFields.company}
            placeholder={localStrings.companyPlaceholder}
          />
        </div>
        <div className={styles.input}>
          <span className={classnames(styles.compLabel, "compLabel")}>
            {localStrings.workContactNumber}
          </span>
          <div className={styles.inputMobileNumber}>
            <SelectField
              showSearch
              classNameContainer={classnames(styles.mobileNumber)}
              data={countryCodes}
              name="workCountryCode"
              onChange={setFormFieldValue}
              {...formFields.workCountryCode}
              placeholder="+63"
            />
            <InputField
              name="workPhone"
              onChange={setFormFieldValue}
              {...formFields.workPhone}
              placeholder="916 4567 890"
            />
          </div>
        </div>
        <div className={classnames(styles.input, styles.address)}>
          <TextareaField
            name="workAddress"
            label={localStrings.workAddress}
            onChange={setFormFieldValue}
            {...formFields.workAddress}
            placeholder={localStrings.addressPlaceholder}
          />
        </div>
      </div>
      <SubmitStep
        loading={loading}
        onBack={() => {
          history.push(path.ONBOARDING_ABOUT_YOU);
        }}
        onSubmit={onSubmit}
      />
    </div>
  );
};

export default EmploymentHistoryForm;
