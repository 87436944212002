import { Requirement } from "enums";
import * as moment from "moment";

const initialFormState = {
  isNameAndPhotoRequired: {
    value: Requirement.Required,
  },
  isEmailAndMobileNumberRequired: {
    value: Requirement.Required,
  },
  isWristbandRequired: {
    value: Requirement.Required,
  },
  isAboutYouRequired: {
    value: Requirement.Required,
  },
  isEmploymentDetailRequired: {
    value: Requirement.Required,
  },
  isEmergencyContactRequired: {
    value: Requirement.Required,
  },
  isBodyTemperatureRequired: {
    value: Requirement.Required,
  },
  isAreaLocationRequired: {
    value: Requirement.Required,
  },
  isLocationRequired: {
    value: Requirement.Required,
  },
  isSymptomRequired: {
    value: Requirement.Required,
  },
  startDate: {
    value: moment(),
    customValidations: (value) => {
      if (moment(value).isBefore(moment().endOf("day"))) {
        return {
          status: true,
        };
      }
    },
  },
};

export default initialFormState;
