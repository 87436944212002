import React, { useCallback, useMemo } from "react";
import { CommunityFormContainer } from "containers";
import localStrings from "localization";
import { getCommunity, editCommunity } from "api/organization";
import { useApi, useMount, useRouter, useModal } from "hooks";
import { resendInvite } from "api/user-organization";
import { Toast } from "atomic/molecules";
import { AccountStatus } from "enums";
import { LayoutB } from "layouts";
import DeactivateCommunityModal from "components/community/deactivate-community-modal/deactivate-community-modal";

const EditCommunityContainer = () => {
  const { query } = useRouter();
  const { communityId } = query || {};

  const deactivateCommunityModal = useModal({});

  const { request, loading: submitting } = useApi({
    api: editCommunity,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: resendInviteRequest, loading: resendingInvite } = useApi({
    api: resendInvite,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: init, loading = true, result: data = {} } = useApi({
    api: () =>
      getCommunity({
        organizationId: communityId,
      }),
  });

  const initialState = useMemo(() => {
    const {
      organizationName,
      isActive = false,
      latitude,
      longitude,
      organizationLogo,
      assignedManager,
    } = data;
    return {
      communityName: organizationName,
      isActive,
      latitude: latitude || "0.0",
      longitude: longitude || "0.0",
      email: assignedManager?.email || "",
      communityAvatar: {
        image:
          organizationLogo ===
          "https://pouch-assets.s3-ap-southeast-1.amazonaws.com/email_icons/ic_default_org_logo.png"
            ? null
            : organizationLogo,
        file: null,
      },
    };
  }, [data]);

  useMount(() => {
    init();
  });

  const submit = async (params, success, error) => {
    console.log(params);
    if (initialState.isActive && !params.params.isActive) {
      deactivateCommunityModal.show([params, success, error]);
      return false;
    }
    await saveChanges(params, success, error);
  };

  const saveChanges = async (params, success, error) => {
    try {
      const data = await request({ params: params.params, communityId });
      success(data, {
        message: localStrings.changesSaved,
      });
    } catch (e) {
      error(e);
    }
  };

  const resendInviteCb = useCallback(async () => {
    await resendInviteRequest({ organizationId: communityId, userId: data.assignedManager.userId });
    Toast({
      content: "Invite has been sent.",
      success: true,
    }).open();
  }, [resendInviteRequest, communityId, data]);

  const isResendInviteAvailable = useMemo(() => {
    if (!data?.assignedManager || data?.assignedManager?.accountStatus === AccountStatus.MANAGER) {
      return false;
    }
    return true;
  }, [data]);

  if (loading) {
    return <LayoutB title={localStrings.editCommunity} loading />;
  }

  return (
    <>
      <DeactivateCommunityModal
        {...deactivateCommunityModal}
        submit={async () => {
          await saveChanges(...deactivateCommunityModal.state);
          deactivateCommunityModal.close();
        }}
      />
      <CommunityFormContainer
        title={localStrings.editCommunity}
        strings={{
          submitText: "Save Changes",
        }}
        saveSubmit={submit}
        submitting={submitting}
        initialState={initialState}
        loading={loading}
        isActiveManagerEditable={false}
        isResendInviteAvailable={isResendInviteAvailable}
        resendingInvite={resendingInvite}
        resendInvite={resendInviteCb}
      />
    </>
  );
};

export default EditCommunityContainer;
