import { AccountStatus } from "enums";
import localStrings from "localization";

export const getUserRole = (organization) => {
  if (organization?.organizationId > 0) {
    const isManager = organization?.userRoles.some((ur) => ur.role === AccountStatus.MANAGER);
    if (isManager) {
      return AccountStatus.MANAGER;
    } else {
      return AccountStatus.MEMBER;
    }
  } else {
    return null;
  }
};

export const hasRole = (userOrganizations = []) => {
  if (userOrganizations.length > 0) {
    return userOrganizations?.some((userOrg) => {
      return userOrg.userRoles.length === 1 || !userOrg.userRoles.length;
    });
  }
  return false;
};

export const getPrettyMemberStatus = (accountStatus) => {
  switch (accountStatus) {
    case AccountStatus.VERIFIED_MEMBER: {
      return localStrings.verifiedMember;
    }
    case AccountStatus.MANAGER: {
      return localStrings.manager;
    }
    case AccountStatus.PENDING_MEMBER: {
      return localStrings.pendingMemberConfirmation;
    }
    case AccountStatus.PROFILE_ONLY: {
      return localStrings.profileOnly;
    }
    case AccountStatus.UNVERIFIED_MEMBER: {
      return localStrings.unverifiedMember;
    }
    default:
      return null;
  }
};
