import React from "react";
import styles from "./links.module.scss";
import classnames from "classnames";
import { Link } from "atomic/atoms";
import { v4 as uuidv4 } from "uuid";

const Links = ({ items, className }) => {
  return (
    <div className={classnames("links", styles.container, className)}>
      {items.map((item) => {
        return (
          <div className={styles.item} key={uuidv4()}>
            <Link
              onClick={item.onClick}
              className={classnames({
                [`${styles.danger}`]: item.danger,
              })}
            >
              {item.text}
            </Link>
          </div>
        );
      })}
    </div>
  );
};

export default Links;
