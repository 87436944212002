import React from "react";
import { Switch as AntSwitch } from "antd";
// import classnames from "classnames";
// import styles from "./switch.module.scss";
import PropTypes from "prop-types";

const Switch = ({ value, onChange, name, disabled }) => {
  return <AntSwitch onChange={() => onChange(!value, name)} disabled={disabled} checked={value} />;
};

Switch.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.bool,
};

Switch.defaultProps = {
  disabled: false,
  value: false,
};

export default Switch;
