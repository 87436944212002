import React from "react";
import { Link, Icon } from "atomic/atoms";
import { Panel } from "atomic/molecules";
import localString from "localization";
import classnames from "classnames";
import styles from "./admin-settings-nav.module.scss";
import path from "path/path";

const AdminSettingsNav = ({ className }) => {
  return (
    <Panel className={classnames(styles.container, className)}>
      <h4>{localString.pouchPassAdmin}</h4>
      <Link
        className={styles.menu}
        activeClassName={styles.active}
        to={path.ADMIN_SETTINGS_REGISTRY}
        exact
      >
        <div className={styles.menuIcon}>
          <Icon name="scan" />
        </div>
        <div className={styles.menuText}>{localString.pouchBandRegistry}</div>
      </Link>
    </Panel>
  );
};

export default AdminSettingsNav;
