import React, { memo } from "react";
import { SelectField } from "atomic/molecules";
import classnames from "classnames";
import localStrings from "localization";
import { Temperature } from "enums";
import styles from "./select-temp-type.module.scss";

const tempTypes = [
  {
    text: localStrings.celsius,
    value: Temperature.Celsius,
  },
  {
    text: localStrings.fahrenheit,
    value: Temperature.Fahrenheit,
  },
];

const SelectTempType = ({ value, className, onChange }) => {
  return (
    <SelectField
      showSearch={false}
      className={classnames(styles.tempType, className)}
      value={value}
      data={tempTypes}
      onChange={onChange}
    />
  );
};

export default memo(SelectTempType);
