import React from "react";
import { Routes } from "enums";
import { NotFoundError } from "pages";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { CommunityContainer } from "containers";
import AdminSettingsContainer from "./admin-settings/admin-settings.container";

const AdminContainer = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/${Routes.COMMUNITIES}`} component={CommunityContainer} />
      <Route path={`${path}/${Routes.SETTINGS}`} component={AdminSettingsContainer} />
      <Redirect exact from={`${path}`} to={`${path}/${Routes.COMMUNITIES}`} />
      <Route render={() => <NotFoundError />} />
    </Switch>
  );
};

export default AdminContainer;
