import localStrings from "localization";
import localTranslation from "localization/localization";

const initialFormState = () => {
  return {
    email: {
      value: "",
      required: true,
      email: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 75,
      custom: {
        existing: [],
        error: false,
      },
      customValidations: (value, custom) => {
        if (custom && custom.existing.includes(value)) {
          return {
            error: true,
            message: localStrings.emailAlreadyExists,
          };
        }
        if (custom && custom.error) {
          return {
            error: true,
            message: localStrings.failedToValidateEmail,
          };
        }
      },
    },
    firstName: {
      value: "",
      required: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 75,
    },
    lastName: {
      value: "",
      required: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 75,
    },
    phone: {
      value: "",
      required: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 12,
      number: true,
      phone: true,
    },
    phoneCountryCode: {
      value: "",
      required: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 12,
    },
    password: {
      value: "",
      required: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 20,
      customValidations: (value) => {
        if (value && value.length < 8) {
          return {
            status: true,
            message: localTranslation("pleaseUseAtleastNthCharacters", ["8"]),
          };
        }
      },
    },
    confirmPassword: {
      value: "",
      required: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 20,
      custom: "",
      customValidations: (value, custom, name, field) => {
        if (value !== custom && value && field.dirty) {
          return {
            status: true,
            message: localStrings.passwordsDoNotMatch,
          };
        }
      },
    },
    countryCode: {
      value: "",
    },
  };
};

export default initialFormState;
