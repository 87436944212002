import Validation from "services/validation.service";
import { Field } from "enums";
import { sortArrObject } from "utils";
const initialFormState = (initialState = {}) => {
  const {
    questionName = "",
    fieldType = null,
    isAutoAdd = false,
    placeholder = null,
    organizationQuestionOptions = [],
  } = initialState;
  // const { questionName, fieldType, isAutoAdd, placeholder, organizationQuestionOptions } = obj;
  const hasOption = organizationQuestionOptions.length > 0;
  return {
    questionName: { type: Field.INPUT, value: questionName, validations: [Validation.required()] },
    fieldType: { type: Field.DROPDOWN, value: fieldType, validations: [Validation.required()] },
    isAutoAdd: { type: Field.CHECKBOX, value: isAutoAdd },
    placeholder: { type: Field.INPUT, value: placeholder },
    organizationQuestionOptions: {
      type: Field.ANY,
      skipValidation: false,
      value: hasOption
        ? sortArrObject(organizationQuestionOptions, "sort")
        : [
            {
              optionName: "Option 1",
              sort: 1,
            },
            {
              optionName: "Option 2",
              sort: 2,
            },
          ],
      validations: [Validation.requiredFieldOptions(), Validation.checkFieldOptionsDuplicate()],
    },
  };
};

export default initialFormState;
