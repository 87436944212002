import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "utils";

const AuthRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn() ? <Redirect to="/" /> : <Component {...props} />;
      }}
    />
  );
};

export default AuthRoute;
