import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "utils";
import { Routes } from "enums";

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        return isLoggedIn() ? <Component {...props} /> : <Redirect to={`/${Routes.AUTH}`} />;
      }}
    />
  );
};

export default AuthorizedRoute;
