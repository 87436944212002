import React, { useState, useCallback, useEffect } from "react";
import styles from "./header-navigation.module.scss";
import Logo from "images/pouch-pass-logo-white.svg";
import { Image, Link } from "atomic/atoms";
import { NavigationMenu, UserAvatar, UserMenu } from "atomic/molecules";
import classnames from "classnames";

const HeaderNavigation = ({ tabs = [], menuOptions, organizationName, loading }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const toggleMenu = useCallback(() => {
    setIsMenuVisible(() => !isMenuVisible);
  }, [isMenuVisible]);

  useEffect(() => {
    if (isMenuVisible) {
      window.addEventListener("click", toggleMenu);
    }
    return () => {
      window.removeEventListener("click", toggleMenu);
    };
  }, [isMenuVisible, toggleMenu]);

  return (
    <header className={classnames(styles.container)}>
      <Link className={styles.homeLink} to="/">
        <Image src={Logo} />
      </Link>
      <NavigationMenu tabs={tabs} />
      <UserAvatar toggleMenu={toggleMenu} loading={loading} />

      <UserMenu organizationName={organizationName} options={menuOptions} visible={isMenuVisible} />
    </header>
  );
};

export default HeaderNavigation;
