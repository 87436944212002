import React, { useCallback, useContext, useState } from "react";
import { Toast } from "atomic/molecules";
import { InviteManagerForm } from "components/settings";
import { OrganizationContext } from "contexts";
import { useApi, useForm, useRouter } from "hooks";
import { inviteManagerFormState } from "states";
import localStrings from "localization";
import { inviteUser, validateInvite } from "api/user-organization";
import { debounced } from "utils";
import { searchUsers } from "api/user-data";
import path from "path/path";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "enums";

const InviteManagerContainer = () => {
  const [suggestions, setSuggestions] = useState(null);
  const [error, setError] = useState(null);

  const { history } = useRouter();

  const { organizationId } = useContext(OrganizationContext);

  const { request: validateEmailRequest, loading: validatingEmail } = useApi({
    api: validateInvite,
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
  });
  const { request: inviteUserRequest, loading: invitingUser } = useApi({
    api: inviteUser,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { request: searchUsersRequest, loading: fetchingUsers } = useApi({ api: searchUsers });

  const {
    formFields,
    setFormFieldValue,
    //formFieldsValue,
    submit,
    setCustomField,
    isFormValid,
  } = useForm(inviteManagerFormState, submitCallback);

  const goBack = useCallback(() => {
    history.push(path.SETTINGS_TEAM_AND_ROLES);
  }, [history]);

  async function submitCallback(value) {
    const { email, role } = value;

    if (error || !isFormValid) {
      return false;
    }
    const selectedSuggestion = suggestions.find((suggestion) => {
      return suggestion.email === email;
    });

    await inviteUserRequest({
      organizationId,
      email: email.trim(),
      roles: [role],
      userId: selectedSuggestion?.userId,
    });
    mixpanel.track(Mixpanel.INVITE_MANAGER);
    Toast({
      content: localStrings.formatString(localStrings.inviteHasBeenSentTo, email),
      success: true,
    }).open();
    goBack();
  }

  const changeEmailCb = useCallback(
    (value, name) => {
      setFormFieldValue(value, name);
      debounced(1000, async () => {
        const res = await searchUsersRequest({ organizationId, searchKey: value });
        setSuggestions(res.data);
      })();
    },
    [searchUsersRequest, organizationId, setFormFieldValue]
  );

  const clickSuggestion = useCallback(
    ({ email, isVerified }) => {
      if (isVerified) {
        setFormFieldValue(email, "email");
      }
      setSuggestions([]);
    },
    [setFormFieldValue]
  );

  const onEmailFocus = useCallback(() => {
    setError(null);
  }, []);

  const onEmailBlur = useCallback(async () => {
    const { email, role } = formFields;

    if (email.value && email.hasOwnProperty("error") && !email.error.status) {
      setCustomField(
        {
          existing: false,
          pending: false,
        },
        "email"
      );
      try {
        await validateEmailRequest({
          organizationId,
          email: email.value.trim(),
          roles: [role.value],
        });
      } catch (err) {
        const { metadata } = err;
        if (metadata && metadata.code) {
          const errors = {
            8029: () => {
              setError(localStrings.emailAlreadyAssociatedWithThisOrganization);
            },
            8031: () => {
              setError(localStrings.alreadyInvitedPendingError);
            },
          };
          console.log("HEyyy");
          console.log(errors.hasOwnProperty(metadata.code));
          if (errors.hasOwnProperty(metadata.code)) {
            console.log("Test");
            errors[metadata.code]();
            return false;
          }
        }
      }
    }
  }, [formFields, organizationId, setCustomField, validateEmailRequest]);
  console.log(error);
  return (
    <InviteManagerForm
      goBack={goBack}
      changeEmail={changeEmailCb}
      formFields={formFields}
      fetchingUsers={fetchingUsers}
      suggestions={suggestions}
      setFormFieldValue={setFormFieldValue}
      submit={submit}
      clickSuggestion={clickSuggestion}
      validatingEmail={validatingEmail}
      invitingUser={invitingUser}
      onEmailBlur={onEmailBlur}
      onEmailFocus={onEmailFocus}
      error={error || !isFormValid}
    />
  );
};

export default InviteManagerContainer;
