import React from "react";
import { Icon, Loading } from "atomic/atoms";
import styles from "./input-field.module.scss";
import classnames from "classnames";
import { Input } from "atomic/atoms";
import localStrings from "localization";

const Suffix = ({ suffix, renderSuffix }) => {
  if (renderSuffix) {
    return renderSuffix;
  }
  if (suffix) {
    return <Icon {...suffix} />;
  }
  return null;
};

const Prefix = (props) => {
  return props ? <Icon {...props} /> : null;
};

const InputField = ({
  className,
  label,
  prefix,
  suffix,
  loading,
  disabled,
  error = {},
  message,
  showRequired,
  type,
  renderSuffix,
  dirty,
  optional,
  ...inputProps
}) => {
  return (
    <div
      className={classnames(className, styles.field, "inputField", {
        [`${styles.error}`]: error.status || (typeof error === "boolean" && error && dirty),
        [`${styles.suffixed}`]: loading || suffix,
        [`${styles.prefixed}`]: prefix,
        [`${styles.disabled}`]: disabled,
        [`${styles.loading}`]: loading,
      })}
    >
      {label && (
        <label className={styles.label}>
          {label} {optional && <span className={styles.optional}>({localStrings.optional})</span>}
        </label>
      )}
      {loading ? (
        <Loading />
      ) : (
        <div
          className={classnames(
            styles.container,
            {
              [`${styles.required}`]: showRequired,
            },
            "container"
          )}
        >
          <Input
            type={type}
            disabled={disabled}
            suffix={
              loading || suffix || renderSuffix ? (
                <Suffix
                  loading={loading}
                  suffix={suffix}
                  renderSuffix={renderSuffix}
                  {...inputProps}
                />
              ) : null
            }
            prefix={prefix ? <Prefix {...prefix} /> : null}
            {...inputProps}
          />
          {showRequired && (
            <span className={styles.required}>
              <Icon className={styles.requiredIcon} name="asterisk" />
            </span>
          )}
        </div>
      )}
      {error.message || message ? (
        <span className={styles.message}>{error.message || message}</span>
      ) : null}
    </div>
  );
};

export default InputField;
