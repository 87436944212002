import React, { useContext } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import CreateLogSymptoms from "components/profile/symptoms-tab/create-log-symptoms/create-log-symptoms";
import EditLogSymptoms from "components/profile/symptoms-tab/edit-log-symptoms/edit-log-symptoms";
import { Routes } from "enums";
import { UserContext } from "contexts";
import { useRouter } from "hooks";
import path from "path/path";

const SymptomContainer = () => {
  const { user } = useContext(UserContext);
  let { path: p } = useRouteMatch();
  const { history } = useRouter();
  const props = {
    userId: user?.userId,
    goBack: () => history.push(path.USER_PROFILE_SYMPTOMS),
  };
  return (
    <Switch>
      <Route
        exact
        component={() => <CreateLogSymptoms {...props} />}
        path={`${p}/${Routes.CREATE}`}
      ></Route>
      <Route
        exact
        component={() => <EditLogSymptoms {...props} />}
        path={`${p}/${Routes.EDIT}/${Routes.SYMPTOMS_LOG_ID}`}
      ></Route>
      <Route path="*" render={() => <Redirect to={`${p}/${Routes.CREATE}`} />} />
    </Switch>
  );
};

export default SymptomContainer;
