import { editCommunity, searchOrganizations } from "api/organization";
import { PaginatedTable } from "atomic/organisms";
import { CommunityTable } from "components/community";
import { useApi, useFilter, useMount, useRouter } from "hooks";
import React, { useCallback, useEffect, useState } from "react";
import { communityListFilterState } from "states";
import path from "path/path";
import localStrings from "localization";
import DeactivateCommunityModalContainer from "../deactivate-community-modal/deactivate-community-modal.container";
import { getLocation } from "utils/map";
import { Toast } from "atomic/molecules";

const CommunityTableContainer = ({
  showDeactivateModal,
  isDeactivateModalVisible,
  closeDeactivateModal,
  deactivateModalState,
}) => {
  const { history, location } = useRouter();

  const [currentSearchKey, setCurrentSearchKey] = useState(
    location?.state?.searchKey ? location.state.searchKey : ""
  );
  const [locations, setLocations] = useState([]);
  const [activeMenu, setActiveMenu] = useState(null);

  const {
    request: searchOrganizationRequest,
    loading,
    result = { data: [], metadata: { total: 0 } },
  } = useApi({
    api: searchOrganizations,
    params: {
      page: 1,
      pageSize: 20,
      searchKey: "",
    },
  });

  const { request: editCommunityRequest } = useApi({
    api: editCommunity,
    handleOwnError: {
      badrequest: true,
    },
  });

  const { modifyFilter, clearFilter, filterState, requestState } = useFilter(
    communityListFilterState()
  );

  useMount(() => {
    fetchCommunities();
  });

  useEffect(() => {
    const getLocations = async () => {
      for (const d of result.data) {
        const { latitude, longitude } = d;

        let locationName = null;

        try {
          locationName = await getLocation({ lat: latitude, lng: longitude });
          // locationName = await new Promise((resolve) =>
          //   setTimeout(() => {
          //     resolve("dummy location");
          //   }, 1000)
          // );
        } catch {
          locationName = "-";
        }

        setLocations((prev) => {
          return { ...prev, [d.organizationId]: locationName };
        });
      }
    };

    getLocations();
  }, [result.data]);

  const fetchCommunities = useCallback(async () => {
    await searchOrganizationRequest(requestState);
  }, [searchOrganizationRequest, requestState]);

  const changePageCb = useCallback(
    async (page) => {
      const { requestState } = modifyFilter({ page });
      await searchOrganizationRequest(requestState);
    },
    [modifyFilter, searchOrganizationRequest]
  );

  const changePageSizeCb = useCallback(
    async (pageSize) => {
      const { requestState } = modifyFilter({ pageSize, page: 1 });
      await searchOrganizationRequest(requestState);
    },
    [modifyFilter, searchOrganizationRequest]
  );

  const searchCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilter({ page: 1, searchKey });
      await searchOrganizationRequest(requestState);
      history.push(path.COMMUNITY_LIST, filterState);
    },
    [modifyFilter, searchOrganizationRequest, history]
  );

  const changeSearchCb = useCallback((searchKey) => {
    setCurrentSearchKey(searchKey);
  }, []);

  const clearCb = useCallback(async () => {
    const { filterState, requestState } = clearFilter();
    setCurrentSearchKey("");
    await searchOrganizationRequest(requestState);
    history.push(path.COMMUNITY_LIST, filterState);
  }, [clearFilter, searchOrganizationRequest, history]);

  const activateCommunity = useCallback(
    async (community) => {
      const { organizationId, assignedManager, latitude, longitude } = community;
      await editCommunityRequest({
        communityId: organizationId,
        params: {
          ...community,
          isActive: true,
          email: assignedManager?.email,
          latitude: latitude ? latitude : 0,
          longitude: longitude ? longitude : 0,
        },
      });
      Toast({
        content: localStrings.changesSaved,
        error: false,
        success: true,
      }).open();
      fetchCommunities();
    },
    [editCommunityRequest, fetchCommunities]
  );

  const getMenuOptions = useCallback(
    (community) => {
      const { isActive, organizationId: communityId } = community;
      let options = [
        {
          label: localStrings.editCommunity,
          onClick: () => {
            history.push(path.COMMUNITY_EDIT(communityId));
          },
        },
      ];

      if (isActive) {
        options.push({
          label: localStrings.deactivateCommunity,
          onClick: (e) => {
            showDeactivateModal(community);
            setActiveMenu(null);
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          },
        });
      } else {
        options.push({
          label: localStrings.activateCommunity,
          onClick: (e) => {
            setActiveMenu(null);
            activateCommunity(community);
            e.stopPropagation();
            e.nativeEvent.stopImmediatePropagation();
          },
        });
      }
      return options;
    },
    [history, showDeactivateModal, activateCommunity]
  );

  return (
    <PaginatedTable
      page={filterState.page}
      pageSize={filterState.pageSize}
      total={result.metadata.total}
      changePage={changePageCb}
      changePageSize={changePageSizeCb}
    >
      <CommunityTable
        communities={result.data}
        total={result.metadata.total}
        loading={loading}
        locations={locations}
        searchKey={currentSearchKey}
        activeMenu={activeMenu}
        search={searchCb}
        changeSearch={changeSearchCb}
        clear={clearCb}
        setActiveMenu={setActiveMenu}
        getMenuOptions={getMenuOptions}
      />
      <DeactivateCommunityModalContainer
        visible={isDeactivateModalVisible}
        community={deactivateModalState}
        close={closeDeactivateModal}
        fetchCommunities={fetchCommunities}
      />
    </PaginatedTable>
  );
};

export default CommunityTableContainer;
