import React from "react";
import useUserActionOptions from "./user-action-options";
import styles from "./member-option-menu.module.scss";
import { MenuOption } from "atomic/atoms";

const MemberOptionMenu = ({
  data,
  dropdown,
  callback,
  organizationId,
  active,
  setActive,
  toggleInviteMemberModal,
}) => {
  const { render, options, loading } = useUserActionOptions({
    callback,
    data,
    fromView: dropdown,
    organizationId,
    toggleInviteMemberModal,
  });
  if (dropdown) {
    return (
      <>
        {render}
        <MenuOption
          loading={loading}
          options={options}
          itemMenuClassNameContainer={styles.itemMenuContainer}
        />
      </>
    );
  }

  return (
    <>
      {render}
      <MenuOption
        loading={loading}
        options={options}
        active={active}
        onClose={() => setActive(null)}
      />
    </>
  );
};

export default MemberOptionMenu;
