import React, { useState } from "react";
import { AuthLayout } from "layouts";
import { useForm, useRouter, useMount, useApi } from "hooks";
import classnames from "classnames";
import initialFormFields from "./initial-form-field";
import styles from "./sign-in-form.module.scss";
import PouchPass from "images/pouch-pass-logo.svg";
import { Mixpanel, Routes } from "enums";
import localStrings from "localization";
import { redirectTo } from "utils";
import { login } from "../../../api/auth";
import { getErrorMessage } from "errors";
import mixpanel from "mixpanel-browser";
import { Image, Link, Message } from "atomic/atoms";
import { Button, InputField, InputFieldPassword } from "atomic/molecules";

const SignIn = () => {
  const { query } = useRouter();
  const [errorMsg, setErrorMsg] = useState(null);
  const { request: signInRequest, loading: signInLoading } = useApi({
    api: login,
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
      unauthorized: true,
    },
  });

  const { formFields, setFormFieldValue, submit } = useForm(
    initialFormFields,
    async (formValues) => {
      try {
        await signInRequest({ email: formValues.email, password: formValues.password });
        mixpanel.track(Mixpanel.LOGIN, { email: formValues.email });
        redirectTo("/");
      } catch (err) {
        const code = err?.metadata?.code;
        if (code) {
          setErrorMsg(getErrorMessage(code));
        }
      }
    }
  );

  useMount(() => {
    if (query.hasOwnProperty("email")) {
      setFormFieldValue(query.email, "email");
    }
  });

  return (
    <AuthLayout>
      <div className={styles.container}>
        <form
          className={styles.form}
          onSubmit={(e) => {
            e.preventDefault();
            submit();
          }}
        >
          <h2 className={styles.title}>{localStrings.welcomeTo}</h2>
          <Image
            className={classnames(styles.image, {
              [`${styles.error}`]: errorMsg,
            })}
            src={PouchPass}
          />
          <Message error className={styles.message} visible={!!errorMsg} text={errorMsg} />

          <p className={styles.instruction}>Enter your password to continue signing in.</p>
          <InputField
            className={styles.input}
            label={localStrings.emailAddress}
            placeholder={localStrings.yourEmailAddress}
            onChange={setFormFieldValue}
            trim
            {...formFields.email}
          />
          <InputFieldPassword
            className={styles.input}
            label={localStrings.password}
            placeholder={localStrings.enterPassword}
            onChange={setFormFieldValue}
            shouldShowToggle
            {...formFields.password}
          />
          <div className={styles.formActions}>
            <Link
              className={styles.forgotPassword}
              to={`/${Routes.AUTH}/${Routes.FORGOT_PASSWORD}`}
              text={`${localStrings.forgotPassword}?`}
            />
          </div>
          <Button
            htmlType="submit"
            loading={signInLoading}
            className={styles.login}
            text={localStrings.login}
          />
        </form>
      </div>
    </AuthLayout>
  );
};

export default SignIn;
