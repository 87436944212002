import React, { memo, useState, useEffect } from "react";
import { Checkbox as AntCheckbox } from "antd";
import PropTypes from "prop-types";

const Checkbox = ({ value, onChange, name, disabled, className }) => {
  const [isChecked, setIsChecked] = useState(value);

  useEffect(() => {
    setIsChecked(value);
  }, [value]);

  const handleOnChange = (e) => {
    const { checked } = e.target;
    setIsChecked(checked);
    onChange(checked, name);
  };

  return (
    <AntCheckbox
      className={className}
      disabled={disabled}
      checked={isChecked}
      onChange={handleOnChange}
    />
  );
};

Checkbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  disabled: PropTypes.bool,
};

Checkbox.defaultProps = {
  value: false,
  onChange: () => null,
};

export default memo(Checkbox);
