import React from "react";

import DiaryPlaceholder from "./diary-placeholder";
import DiaryToday from "./diary-today";
import DiaryPastDays from "./diary-past-days";
import localStrings from "localization";
import { Panel } from "atomic/molecules";
import { Icon, Message } from "atomic/atoms";
import styles from "./diary.module.scss";

const DiaryView = ({
  loading,
  today,
  pastDays,
  strings,
  error,
  title,
  todayError,
  pastDaysError,
  totalData,
  description,
}) => {
  const empty = (header) => {
    return (
      <DiaryPlaceholder
        // header={header}
        title={title}
        strings={strings}
        description={description}
        error={error || todayError}
      />
    );
  };
  const table = (
    <>
      {today.render}
      {pastDays.render}
    </>
  );
  if (loading) {
    return table;
  }
  const emptyTodayWithPastday = (
    <>
      {empty(localStrings.today)}
      {pastDays.render}
    </>
  );
  if (!today?.data.length && pastDays.data && pastDays.data.length) {
    return emptyTodayWithPastday;
  }
  if ((pastDaysError || todayError) && !error) {
    if (todayError) {
      return table;
    } else {
      return emptyTodayWithPastday;
    }
  }
  if ((!totalData.length && !loading) || error) {
    return empty();
  }
  return table;
};

const MemberDiaryTab = ({
  className,
  strings,
  title,
  description,
  today,
  pastDays,
  totalData,
  userId,
  organizationId,
  userPrivacyStatus,
  userPrivacyLoading,
}) => {
  const loading = today.loading || pastDays.loading;
  const error = today.error && pastDays.error;

  // const { locations } = useLocations({
  //   initialize: !loading,
  //   data: totalData,
  // });

  return (
    <Panel className={styles.diary}>
      <div className={styles.headerContainer}>
        <h2 className={styles.headerTitle}>{localStrings.diary}</h2>
        <p>{localStrings.bodyTemperatureDays}</p>
      </div>
      <Message
        visible={
          !userPrivacyStatus.isLocationAllowed &&
          !userPrivacyLoading &&
          userPrivacyStatus.isBodyTemperatureAllowed
        }
        notif
        className={styles.notif}
      >
        <div className={styles.infoContainer}>
          <Icon name="info-fill" className={styles.info} />
          {localStrings.memberLocationPrivate}
        </div>
      </Message>
      {!userPrivacyStatus.isBodyTemperatureAllowed && !userPrivacyLoading ? (
        <div className={styles.privacyContainer}>
          <div>
            <h2>
              <Icon name="lock" /> {localStrings.privateInformation}
            </h2>
            <p>You have no access to this member’s temperature records.</p>
          </div>
        </div>
      ) : (
        <DiaryView
          className={className}
          strings={strings}
          description={description}
          loading={loading}
          today={{
            render: <DiaryToday {...today} />,
            data: today.result,
          }}
          pastDays={{
            render: (
              <DiaryPastDays
                {...pastDays}
                userId={userId}
                organizationId={organizationId}
                userPrivacyStatus={userPrivacyStatus}
                userPrivacyLoading={userPrivacyLoading}
              />
            ),
            data: pastDays.result,
          }}
          error={error}
          todayError={today.error}
          pastDaysError={pastDays.error}
          title={title}
          totalData={totalData}
        />
      )}
    </Panel>
  );
};

export default MemberDiaryTab;
