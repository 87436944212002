import React, { memo } from "react";
import styles from "./message.module.scss";
import classnames from "classnames";
const Message = ({ children, visible, text, className, error, warning, notif }) => {
  return !visible ? null : (
    <div
      className={classnames(styles.container, className, {
        [`${styles.error}`]: error,
        [`${styles.warning}`]: warning,
        [`${styles.notif}`]: notif,
      })}
    >
      <div className={classnames(styles.message, "message")}>{children || text}</div>
    </div>
  );
};

export default memo(Message);
