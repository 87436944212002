const locale = {
  about: "",
  aboutYou: "",
  aboutYouDesc: "",
  acceptInvite: "",
  accessory: "",
  accessoryAlreadyPaired: "Phụ kiện này đã được ghép nối với người dùng khác.",
  accessoryDetails: "chi tiết phụ kiện",
  accessoryId: "ID phụ kiện",
  accessoryIdAlreadyPaired: "",
  accessoryIdDoesNotBelongToOrganization: "",
  accessoryIdIsntAvailable: "Phụ kiện này không có sẵn. Vui lòng thử một ID khác.",
  accessoryOrMacAddress: "",
  accessoryRemoved: "",
  accessoryStatus: "",
  accessoryType: "",
  account: "",
  accountInformation: "",
  accountSettings: "",
  active: "",
  activeAndSyncing: "",
  activeMembers: "",
  activeOraganization: "",
  add: "",
  addAMember: "",
  addANewMember: "",
  addANewUser: "",
  addAccessory: "",
  addAndManagerPouchBands: "",
  addMember: "",
  addMemberAndProfile: "",
  addMembers: "",
  addNew: "",
  addNewMembers: "",
  addPouchband: "",
  addUsers: "",
  addYourContactDetails: "",
  addYourPouchBand: "",
  additionalInfo: "",
  address: "",
  all: "",
  allSet: "",
  allTheInfoCollectedProfile: "",
  allowed: "",
  almostDoneEnterYourNewPassword: "",
  alreadyHaveAnAccount: "",
  alreadyInvitedAssignAsManager: "",
  alreadyInvitedPendingError: "",
  and: "",
  appStore: "",
  applyFilters: "",
  areYouSure: "",
  area: "",
  areaDescription: "",
  areaDuplicateName: "Tên khu vực này đã tồn tại. Vui lòng chọn tên khác",
  areaLocation: "",
  areaLocationDesc: "",
  areaName: "",
  areas: "",
  asThePersonDescribed: "",
  askDeleteArea: "",
  askDeleteMember: "",
  askDeleteUser: "",
  askRemoveAccessory: "",
  askRemoveManager: "",
  askRemoveMember: "",
  askSaveChanges: "",
  askTheUserToWearPouchBand: "",
  askYouToWearPouchBand: "",
  assignAPouchBandToEachUser: "",
  assignAPouchBandToStartMonitorTheirTemp: "",
  assignAsManager: "",
  authorization: "",
  back: "",
  backTo: "",
  backToHome: "",
  backToLogin: "",
  basicInfo: "",
  birthdate: "",
  birthday: "",
  bloodType: "",
  bodyAche: "",
  bodyTemperature: "",
  bodyTemperatureDays: "",
  browsingYourFiles: "",
  cancel: "",
  celciusSymbol: "",
  celsius: "",
  change: "",
  changeAccessory: "",
  changePhoto: "",
  changeYourEmail: "",
  changesSaved: "",
  checkCredentials: "",
  checkEmploymentDetails: "",
  checkWithYourAdminForDetails: "",
  checkingInternet: "",
  chestPains: "",
  chooseBloodType: "",
  chooseGender: "",
  chooseTypeTest: "",
  chooseWhichInformationToShare: "",
  clear: "",
  clearAllFilters: "",
  cm: "",
  company: "",
  companyPlaceholder: "",
  completeProfile: "",
  confirmDeleteMember: "",
  confirmNewPassword: "",
  confirmPassword: "",
  confirmRemoveManager: "",
  confirmRemoveMember: "",
  confirmRevokeManagerInvite: "",
  conjunctivitis: "",
  connectAPouchBandDesc: "",
  contactAndPersonalInfo: "",
  contactHistory: "",
  contactHistoryDesc: "",
  contactInformation: "",
  contactNumber: "",
  contactPersonsName: "",
  contactPouchPass: "",
  contactRelationship: "",
  contactUs: "",
  cough: "",
  countryCurrentlyResidingIn: "",
  createAccount: "",
  createAndManageUser: "",
  createArea: "",
  createMember: "",
  createPPaccount: "",
  createUser: "",
  createWorryFree: "",
  createYourFirstArea: "",
  createYourFirstAreaDesc: "",
  currentPassword: "",
  currentResidentialAddress: "",
  date: "",
  dateOfAvailabilityOfTestResults: "",
  dateOfContact: "",
  dateOfMedicalTest: "",
  decline: "",
  declineInvitation: "",
  declineInvitationDesc: "",
  delete: "",
  deleteArea: "",
  deleteLog: "",
  deleteMember: "",
  deleteSymptomsLog: "",
  deleteUser: "",
  deletedMemeber: "",
  description: "",
  details: "",
  deviceStatus: "",
  diarrhea: "",
  diary: "",
  didntGetACode: "",
  didntReceiveAnything: "",
  difficultyBreathing: "",
  discardChanges: "",
  discardChangesMessage: "",
  discardTitle: "",
  dismiss: "",
  doThisLater: "",
  doYouWantToCreateYourOwnOrg: "",
  dontHavePouchBandYet: "",
  duration: "",
  easilyIdentifyYourProfile: "",
  edit: "",
  editArea: "",
  editLogSymptoms: "",
  editManager: "",
  editMember: "",
  editProfile: "",
  editSettings: "",
  editUser: "",
  egMedicalClinic: "",
  emailAddress: "",
  emailAddressAlreadyTaken: "",
  emailAddressAndMobileNumber: "",
  emailAddresses: "",
  emailAlreadyAssociatedWithThisOrganization: "",
  emailAlreadyExists: "",
  emergencyContact: "",
  emergencyContactDesc: "",
  emergencyContactPerson: "",
  employed: "",
  employeeDetails: "",
  employmentDetails: "",
  employmentDetailsDesc: "",
  employmentStatus: "",
  enterAnEmailAddress: "",
  enterNewPassword: "",
  enterOtpVerifyYourEmailAddress: "",
  enterPassword: "",
  everyoneIsDoingWell: "",
  everyoneIsDoingWellDesc: "",
  everyoneIsSafe: "",
  everyoneIsSafeDesc: "",
  export: "",
  extremelyHigh: "",
  fAQsPage: "",
  fahrenheit: "",
  failedToValidateEmail: "",
  fever: "",
  feverCases: "",
  feverTrend: "",
  feverTrendOvertime: "",
  fillInEmailAddresses: "",
  findOutHowCreateOrg: "",
  findOutHowNow: "",
  findOutMore: "",
  fingerToesDiscoloration: "",
  finishSettingUpAccount: "",
  firstName: "",
  forFurtherQuestions: "",
  forgotPassword: "",
  fullName: "",
  gateway: "",
  gatewayId: "",
  gatewayIdOrMacAddress: "",
  gatewayMacAddress: "",
  gatewayName: "",
  gatewayNamePlaceholder: "",
  gender: "",
  geolocation: "",
  geolocationDesc: "",
  goodJobAllUserActive: "",
  goodJobAllUserActiveDesc: "",
  great: "",
  hasNotBeenInContact: "",
  hasTheUserTakenAnyMedicalTest: "",
  headache: "",
  height: "",
  heightInCm: "",
  hereWhatHappening: "",
  highRisk: "",
  highRiskDesc: "",
  highTemperature: "",
  higherThanNormal: "",
  hypothermia: "",
  iAgreeToPouchTerms: "",
  iAuthorizeOrg: "",
  ifAny: "",
  iformationIsImportantNarrative: "",
  imageMustBeSmallerThan: "",
  in: "",
  inactive: "",
  inactiveDesc: "",
  individualAtRiskDesc: "",
  individualsAtRisk: "",
  inputAccessoryId: "",
  invalidCurrentPassword: "",
  inviteExpiredDesc: "",
  inviteHasBeenSentTo: "",
  inviteManager: "",
  inviteManagerDescription: "",
  inviteMembers: "",
  inviteNewManager: "",
  invitePeopleToCreate: "",
  invitePouchPassDesc: "",
  invitePouchPassNote: "",
  inviteRequest: "",
  inviteRequestDesc: "",
  inviteRequestDescManager: "",
  inviteRequestDescMember: "",
  inviteSent: "",
  inviteSentDetails: "",
  inviteSentMemberDesc: "",
  inviteTeamMembersAndCreateArea: "",
  inviteTeamMembersAndCreateAreaDescription: "",
  inviteToPouchPass: "",
  invitedMembersWouldHave: "",
  invitesSent: "",
  invitingAMember: "",
  joinedCommunities: "",
  joinedOn: "",
  keepThisOrg: "",
  kg: "",
  lastDateOfContact: "",
  lastName: "",
  lastSynced: "",
  lastUpdated: "",
  latestTemp: "",
  learnMore: "",
  leave: "",
  leaveOTPMessage: "",
  leaveOrganization: "",
  leavePage: "",
  leavePageDesc: "",
  lessDetails: "",
  liveMap: "",
  location: "",
  logIn: "",
  logOtherSymptoms: "",
  logSymptoms: "",
  logTheirSymptomsNow: "",
  login: "",
  loginNow: "",
  loginTo: "",
  logout: "",
  lossOfSmellOrTaste: "",
  lossOfSpeechOrMovement: "",
  low: "",
  lowRisk: "",
  lowRiskDesc: "",
  lowTemperature: "",
  manager: "",
  managerRequest: "",
  me: "",
  medicalTest: "",
  member: "",
  memberDetails: "",
  memberDetailsPendingMessage: "",
  memberFromUntil: "",
  memberLocationPrivate: "",
  memberProfileOnly: "",
  memberProfileOnlyDesc: "",
  memberRequest: "",
  memberSince: "",
  memberStatus: "",
  members: "",
  mobileNumber: "",
  moderateRisk: "",
  moderateRiskDesc: "",
  monitorAnySymptoms: "",
  monitorAnySymptomsDescription: "",
  monitorYourBodyTemp: "",
  monitorYourBodyTempDesc: "",
  myOrganizationDescription: "",
  myOrganizations: "",
  name: "",
  nameAndProfilePhoto: "",
  nameOfClinicOrHospital: "",
  nameOfOtherTest: "",
  nameOfSpecificMedicalTest: "",
  nameRemoved: "",
  negative: "",
  networkErrorDesc: "",
  newPassword: "",
  newUserAdded: "",
  next: "",
  noAccessory: "",
  noAccessoryYet: "",
  noContactDetected: "",
  noData: "",
  noDevice: "",
  noLongerAManager: "",
  noMemberYet: "",
  noMembersFound: "",
  noName: "",
  noNeedForApp: "",
  noRecordsYet: "",
  noResults: "",
  noSymptomsLogged: "",
  noUserFound: "",
  noUserYet: "",
  normal: "",
  notAllowed: "",
  notAtRisk: "",
  notRequired: "",
  ohNoYouDontHaveAccess: "",
  okay: "",
  onceYouLeaveOrg: "",
  oopsIncorrectEmailOrPass: "",
  oopsSomethinngWentWrong: "",
  optional: "",
  or: "",
  orCheck: "",
  orderOne: "",
  orgYouManage: "",
  organizationSettings: "",
  otherLocation: "",
  ourAvailablePlans: "",
  overview: "",
  pageNotFound: "",
  pageNotFoundDesc: "",
  paired: "",
  pairingStatus: "",
  password: "",
  passwordReset: "",
  passwordsDoNotMatch: "",
  pastOrganizations: "",
  pastOrganizationsDesc: "",
  past13Days: "",
  pendingInvites: "",
  pendingInvitesDesc: "",
  pendingMemberConfirmation: "",
  permanentResidence: "",
  permissions: "",
  personalInformation: "",
  playStore: "",
  pleaseCheckForTheOneTimePasswordWeSentTo: "",
  pleaseCheckYourEmailAndClickMessage: "",
  pleaseContactOurSupport: "",
  pleaseContactPouchPass: "",
  pleaseEnterAnEmailInFormat: "",
  pleaseEnterAtLeastOneEmail: "",
  pleaseFillUpArea: "",
  pleaseFillUpSymptom: "",
  pleaseFillUpUser: "",
  pleaseInputCurrentPassword: "",
  pleaseRequestThePersonProfile: "",
  pleaseTryAgain: "",
  pleaseUseAtleastNthCharacters: "",
  poor: "",
  positive: "",
  pouchBand: "",
  pouchBandDescription: "",
  pouchBandForEveryone: "",
  pouchBandStep1: "",
  pouchBandStep2: "",
  pouchBands: "",
  pouchnationPhilippines: "",
  privacyPolicy: "",
  privacySymptomsWarning: "",
  private: "",
  privateInformation: "",
  privateOnlyMe: "",
  profile: "",
  profileAvatarSaved: "",
  profileOnly: "",
  profileSettings: "",
  rashes: "",
  recordAndMonitorYourSymptoms: "",
  recovingWithANormalTemp: "",
  refreshPage: "",
  registrationStart: "",
  relationshipPlaceholder: "",
  rememberMe: "",
  rememberYourPassword: "",
  rememberYourPasswordQuestion: "",
  remove: "",
  removeAccessory: "",
  removeAccessoryDesc: "",
  removeAsManager: "",
  removeFromOrganization: "",
  removeManagerSuccess: "",
  removeMember: "",
  removed: "",
  removedMember: "",
  replacePhoto: "",
  required: "",
  resendANewOtp: "",
  resendInvite: "",
  resendInviteSuccess: "",
  resendNow: "",
  resetLinkResent: "",
  resetLinkSent: "",
  resetPassword: "",
  resetTokenExpired: "",
  retry: "",
  retypePassword: "",
  reviewTheInformation: "",
  revoke: "",
  revokeInvite: "",
  revokeInviteSuccess: "",
  riskLevel: "",
  role: "",
  roleAndPermissions: "",
  sampleAreaDescription: "",
  sampleAreaName: "",
  save: "",
  saveAndSendNotif: "",
  saveChanges: "",
  savePermissions: "",
  searchByUserOrLocation: "",
  searchMemberByNameOrAccessoryId: "",
  searchUserByNameOrAccessoryId: "",
  seconds: "",
  sections: "",
  seeMore: "",
  selectARole: "",
  selectBloodType: "",
  selectCountry: "",
  selectEmploymentStatus: "",
  selectFollowingSymptoms: "",
  selectGender: "",
  selectUserTestResult: "",
  selfEmployed: "",
  sendInvite: "",
  sendResetLink: "",
  settings: "",
  setupProfileLater: "",
  sharedInformation: "",
  shopNow: "",
  shopPouchBand: "",
  signIn: "",
  signingUpAggreement: "",
  someEmailsNotRecognized: "",
  somethingWentWrong: "",
  soreThroat: "",
  sorryYouAreNotAuthToAccessThisPage: "",
  startMonitorTeam: "",
  startTour: "",
  startYourPouchBand: "",
  startbyUploading: "",
  statistics: "",
  stayOnThisPage: "",
  stayOnTopOfYourHealthAndGetNotified: "",
  stayOnTopOfYourHealthAndGetNotifiedDesc: "",
  stepNth: "",
  successChangedPassword: "",
  symptomLoggedToDiary: "",
  symptomNotFound: "",
  symptoms: "",
  symptomsDescription: "",
  takeNoteOfTheMedicalTestResultHere: "",
  teamAndRoles: "",
  teamManagers: "",
  tellUsMoreAboutYourself: "",
  temp: "",
  tempCases: "",
  tempHasBeenIncreasingForThePastDays: "",
  temperature: "",
  temperatureCases: "",
  temperatureStatus: "",
  temperatureTrend: "",
  termsAndConditions: "",
  testResultSummaryInfo: "",
  testResults: "",
  testedNegativeWithCovid: "",
  testedPositiveWithCovid: "",
  theOTPYouEnteredIsInvalid: "",
  theyllBeAdded: "",
  thisEmailIsAlreadyAMemberOfYourOrg: "",
  thisMember: "",
  thisUserDoesNotExistOn: "",
  time: "",
  tiredness: "",
  to: "",
  toLearnAboutPouchPass: "",
  today: "",
  totalMembers: "",
  trackEncounter: "",
  trackEncounterDescription: "",
  try: "",
  trySearchingAgain: "",
  tryToSearchAgain: "",
  typeOfMedicalTest: "",
  uhOhEmailNotRegistered: "",
  unableToLocate: "",
  unableToLocateMember: "",
  unableToLocateUser: "",
  unableToRemoveAccessory: "",
  unableToRetrieveData: "",
  unableToRetrieveDataDesc: "",
  unauthorized: "",
  unbaleToUploadMoreThanMb: "",
  unemployed: "",
  unfortunatelyThisInviteIsExpired: "",
  unknowUserContactInformation: "",
  unknowUserUserId: "",
  unverifiedMember: "",
  unverifiedMembersDesc: "",
  uploadAProfile: "",
  uploadCovidTestCerti: "",
  uploadFile: "",
  uploadPhoto: "",
  uploadPhotoDescription: "",
  uploadProfile: "",
  uploadTestCertificate: "",
  usePouchPassForYourFamilyOrBusiness: "",
  usePouchPassForYourFamilyOrBusinessDesc: "",
  userHasPendingInvite: "",
  userIsNowAManager: "",
  users: "",
  usingAMixOfUppercaseAndLowercase: "",
  validateEmailAddress: "",
  verifiedMember: "",
  verifiedMembersDesc: "",
  verifyEmail: "",
  verifyEmailAddress: "",
  veryHigh: "",
  veryLowRisk: "",
  veryLowRiskDesc: "",
  view: "",
  viewAll: "",
  viewAllAreas: "",
  viewAndAddSymptoms: "",
  viewInvite: "",
  viewMyActiveOrg: "",
  viewMyOrgs: "",
  viewProfile: "",
  viewTestResults: "",
  viewYourContactInformation: "",
  viewYourNameAndProfilePhoto: "",
  visitOur: "",
  wantToCreateOrg: "",
  weShouldBack: "",
  weak: "",
  weight: "",
  weightInKg: "",
  welcomeDescription: "",
  welcomeTo: "",
  welcomeToPouchPass: "",
  wellEmbarassing: "",
  wellEmbarassingDesc: "",
  whoCanView: "",
  withFever: "",
  workAddress: "",
  workContactNumber: "",
  worker: "",
  wouldBeReceivingInfo: "",
  yesDecline: "",
  yesLeave: "",
  youAreNotAuthorized: "",
  youAreNotInAnyOrg: "",
  youAreNotInAnyOrgDesc: "",
  youCanOnlyUploadJpgPngGifFile: "",
  youDontHaveOrg: "",
  youMay: "",
  youReInvitedTo: "",
  youReachMaximumOTPAttemps: "",
  yourEmailAddress: "",
  yourPasswordHasBeenChanged: "",
};
export default Object.freeze(locale);
