import React, { useCallback, useContext } from "react";
import { OnboardingLayout, AboutYouForm } from "components/onboarding";
import BackgroundImage from "images/dna.svg";
import { UserContext } from "contexts";
import { useApi, useForm, useMount, useRouter } from "hooks";
import { onboardUser } from "api/user";
import moment from "moment";
import { getUserRequestParam } from "components/users/user-form/user-request-form";
import { aboutYouFormState } from "states";
import path from "path/path";

const AboutYouContainer = () => {
  const { formFields, setFormFieldValue, submit, setFormFieldValues } = useForm(
    aboutYouFormState,
    submitCallback
  );
  const { history } = useRouter();

  const { request: onboardUserRequest, loading } = useApi({ api: onboardUser });

  const { user, refetchUser } = useContext(UserContext);

  useMount(() => {
    const { dob, userDetail, ...rest } = user;
    if (user.userDetail) {
      const { address: currentAddress, ...restUserData } = userDetail;
      setFormFieldValues({
        birthDate: dob ? moment(dob, "MM/DD/YYYY") : null,
        currentAddress,
        ...rest,
        ...restUserData,
      });
    }
  });

  const onBack = useCallback(() => {
    history.push(path.ONBOARDING);
  }, [history]);

  async function submitCallback(value) {
    const { birthDate, gender, bloodType, height, weight, currentAddress: address } = value;
    const obj = {
      isAboutYou: true,
      dob: birthDate ? moment(birthDate).format("MM/DD/YYYY") : null,
      gender,
      bloodType,
      height,
      weight,
      address,
    };
    await onboardUserRequest({
      ...getUserRequestParam({ ...user, ...user?.userDetail }),
      ...obj,
    });

    refetchUser();

    history.push(path.ONBOARDING_EMPLOYMENT);
  }

  return (
    <OnboardingLayout backgroundImage={BackgroundImage}>
      <AboutYouForm
        onBack={onBack}
        formFields={formFields}
        setFormFieldValue={setFormFieldValue}
        submit={submit}
        loading={loading}
      />
    </OnboardingLayout>
  );
};

export default AboutYouContainer;
