import React, { useState, useEffect, memo } from "react";
import styles from "./symptom-button.module.scss";
import { Icon } from "atomic/atoms";
import classnames from "classnames";

const SymptomButton = ({ text, icon, isChecked = false, className, value, onClick }) => {
  const [check, setCheck] = useState(false);
  const handleOnClick = () => {
    setCheck((prev) => !prev);
    if (onClick) {
      onClick(value);
    }
  };
  useEffect(() => {
    setCheck(isChecked);
  }, [isChecked]);

  return (
    <div className={classnames(styles.container, className)} onClick={handleOnClick}>
      <div
        className={classnames(styles.iconContainer, {
          [`${styles.select}`]: check,
        })}
      >
        <Icon
          className={classnames(styles.icon, {
            [`${styles.select}`]: check,
          })}
          name={icon}
        />
        {check && <Icon className={styles.check} name="check-fill" />}
      </div>

      <p className={styles.title}>{text}</p>
    </div>
  );
};

export default memo(SymptomButton);
