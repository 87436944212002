import { Common } from "enums";

export const userListFilterState = ({ organizationId }) => ({
  page: 1,
  pageSize: 20,
  organizationId,
  searchKey: "",
  bodyTemp: Common.ALL,
  deviceStatus: Common.ALL,
  pairingStatus: Common.ALL,
  areaId: Common.ALL,
  accountStatus: Common.ALL,
});
