import React, { useMemo, useContext, useState } from "react";
import styles from "./footer.module.scss";
import { Link } from "atomic/atoms";
import { useRouter } from "hooks";
import { RoleContext } from "contexts";
import { AccountStatus } from "enums";
import { ManagersGuide, MemberGuide } from "components/onboarding";

const Footer = () => {
  const role = useContext(RoleContext);
  const { pathname } = useRouter();
  const shouldVisible = useMemo(() => {
    return !pathname.includes("live-map");
  }, [pathname]);
  const [isGuideVisible, setIsGuideVisible] = useState(false);

  const renderLinks = useMemo(() => {
    const links = [
      {
        text: "FAQs",
        href: "https://pouchpass.com/faqs/",
      },
      {
        text: "Help",
        href: "http://help.pouchpass.com/support/home",
      },
      {
        text: "Privacy",
        href: "https://pouchpass.com/legal/privacy/#web",
      },
      {
        text: "Terms of Use",
        href: "https://pouchpass.com/legal/terms/#web",
      },
      {
        text: "Legal",
        href: "https://pouchpass.com/legal/",
      },
      {
        text: "Quick Guide",
        onClick: (e) => {
          e.preventDefault();
          setIsGuideVisible(true);
        },
      },
      {
        text: "Contact Us",
        href: "https://pouchpass.com/contact/",
      },
      {
        text: "Press",
        href: "https://pouchpass.com/newsroom/",
      },
    ];
    return links.map(({ text, href, onClick }, index) => (
      <Link
        key={`footer-link-${index}`}
        text={text}
        href={href}
        onClick={onClick}
        target="_blank"
      />
    ));
  }, []);

  const renderGuideModal = useMemo(() => {
    if (role === AccountStatus.MANAGER) {
      return <ManagersGuide isVisible={isGuideVisible} onClose={() => setIsGuideVisible(false)} />;
    }
    return <MemberGuide isVisible={isGuideVisible} onClose={() => setIsGuideVisible(false)} />;
  }, [role, isGuideVisible]);

  return (
    shouldVisible && (
      <>
        <div className={styles.container}>
          <span>© 2020 PouchPASS Pte. Ltd.</span>
          {renderLinks}
        </div>
        {renderGuideModal}
      </>
    )
  );
};

export default Footer;
