import React, { useState, useEffect } from "react";
import styles from "./contact-history-filter.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import moment from "moment";
import { debounced } from "utils";
import { Icon } from "atomic/atoms";
import { InputField, DatePickerField } from "atomic/molecules";

const ContactHistoryFilter = ({ search }) => {
  const [searchKey, setSearchKey] = useState("");
  const [dates, setDates] = useState([moment().subtract(30, "days"), moment()]);
  const [error, setError] = useState(false);
  const [dirty, setDirty] = useState(false);

  useEffect(
    debounced(1000, () => {
      if (dirty && !error) {
        search(searchKey, dates);
      }
    }),
    [searchKey, dates, dirty]
  );

  return (
    <>
      <div className={styles.container}>
        <div className={classnames(styles.formItem, styles.searchKey)}>
          <InputField
            value={searchKey}
            onChange={(value) => {
              setDirty(true);
              setSearchKey(value);
            }}
            placeholder={localStrings.searchMemberByNameOrAccessoryId}
            renderSuffix={
              <>
                {searchKey && (
                  <div
                    className={styles.clear}
                    onClick={() => {
                      setSearchKey("");
                      setDirty(true);
                    }}
                  >
                    CLEAR <Icon name="close" className={styles.removeIcon} />
                  </div>
                )}
                <Icon name="search" onClick={() => search(searchKey, dates)} />
              </>
            }
          />
        </div>
        <div className={classnames(styles.formItem)}>
          <DatePickerField
            range
            value={dates}
            onChange={(d) => {
              setDates(d);
              setDirty(true);
              if (d[1].diff(d[0], "days") >= 30) {
                setError(true);
                return false;
              }
              setError(false);
            }}
            format="MMM DD, YYYY"
            error={{
              status: error,
              message: error ? "Maximum of 30 days" : "",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default ContactHistoryFilter;
