import React, { useContext } from "react";
import styles from "./privacy-form.module.scss";
import { ActionButton } from "components/common";
import { Toast } from "atomic/molecules";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { useForm, useApi, useMount, useRouter } from "hooks";
import initialFormState from "./initial-form-state";
import { setPrivacyStatusOrganization, getPrivacyStatusOrganization } from "api/organization";
import { Permission } from "enums";
import localStrings from "localization";
import { UserContext } from "contexts";
import path from "path/path";
import PrivacyForm from "../privacy-form/privacy-form";
import { getPrivacySettingsValue } from "api/organization";

const PrivacySettingsForm = ({ flags, organizationId }) => {
  const { user } = useContext(UserContext);
  const { userId } = user || {};
  const form = useForm(initialFormState);
  const { history } = useRouter();
  const {
    isFormValid,
    formFieldsValue,
    submit,
    setFormFieldValue,
    clearFormFieldsDirty,
    isFormDirty,
  } = form;

  const requirementKeys = {
    isNameAndPhotoAllowed: "isNameAndPhotoRequired",
    isEmailAndMobileNumberAllowed: "isEmailAndMobileNumberRequired",
    isWristbandAllowed: "isWristbandRequired",
    isAboutYouAllowed: "isAboutYouRequired",
    isEmploymentDetailAllowed: "isEmploymentDetailRequired",
    isEmergencyContactAllowed: "isEmergencyContactRequired",
    isBodyTemperatureAllowed: "isBodyTemperatureRequired",
    isAreaLocationAllowed: "isAreaLocationRequired",
    isLocationAllowed: "isLocationRequired",
    isSymptomAllowed: "isSymptomRequired",
  };

  const { request, loading } = useApi({
    api: setPrivacyStatusOrganization,
  });

  const { request: initRequest } = useApi({
    api: () => getPrivacyStatusOrganization({ userId, organizationId }),
  });

  const {
    request: initPrivacySettings,
    loading: loadingPrivacySettings = true,
    result: requirementData,
  } = useApi({
    api: () =>
      getPrivacySettingsValue({
        organizationId,
        currentPrivacy: true,
      }),
  });

  const requirementDataResult = requirementData?.data || {};

  const onSubmit = () => {
    submit();
    if (isFormValid && isFormDirty) {
      const params = {};
      Object.keys(formFieldsValue).forEach((key) => {
        params[key] = formFieldsValue[key] === Permission.Allowed;
      });
      const submitForm = async () => {
        await request({
          userId,
          organizationId,
          form: params,
        });
        Toast({
          content: localStrings.changesSaved,
          success: true,
          visible: true,
          icon: "check-fill",
        }).open();
        clearFormFieldsDirty();
      };
      submitForm();
    }
  };

  useMount(() => {
    const init = async () => {
      const data = await initRequest();
      const requirement = await initPrivacySettings();
      const d = requirement.data;

      Object.keys(data).forEach((key) => {
        if (
          [
            "isLocationAllowed",
            "isSymptomAllowed",
            "isAboutYouAllowed",
            "isEmploymentDetailAllowed",
            "isEmergencyContactAllowed",
            "isWristbandAllowed",
            "isEmailAndMobileNumberAllowed",
            "isNameAndPhotoAllowed",
            "isBodyTemperatureAllowed",
            "isAreaLocationAllowed",
          ].includes(key)
        ) {
          setFormFieldValue(
            data[key] || d[requirementKeys[key]] ? Permission.Allowed : Permission.NotAllowed,
            key
          );
        }
      });
      clearFormFieldsDirty();
    };
    init();
  });

  return (
    <div className={styles.sharedInformation}>
      <PrivacyForm
        form={form}
        title={localStrings.sharedInformation}
        required={requirementDataResult}
        requirementKeys={requirementKeys}
      />
      <div className={styles.submit}>
        <ActionButton
          onSubmit={onSubmit}
          loading={loading || loadingPrivacySettings}
          onCancel={() => history.push(path.SETTINGS_MY_ORGANIZATION)}
          disabled={!isFormDirty}
        />
      </div>
    </div>
  );
};

export default withLDConsumer()(PrivacySettingsForm);
