import React, { useMemo, memo } from "react";
import { Checkbox } from "atomic/atoms";
import styles from "./permission-table.module.scss";
import { v4 as uuidv4 } from "uuid";
import localStrings from "localization";
import initialFormFields from "./initial-form-fields";
import { useForm } from "hooks";

const tableColumns = [
  {
    title: localStrings.sections,
  },
  {
    title: localStrings.add,
    width: 45,
  },
  {
    title: localStrings.edit,
    width: 45,
  },
  {
    title: localStrings.remove,
    width: 45,
  },
  {
    title: localStrings.view,
    width: 45,
  },
];

const permissionsNames = [
  {
    section: <h2 className={styles.permissionHeader}>{localStrings.organizationSettings}</h2>,
    name: "orgSettings",
  },
  {
    section: localStrings.accountInformation,
    name: "accountInfo",
  },
  {
    section: "Team Members",
    name: "teamMembers",
  },
  {
    section: "Devices",
    name: "devices",
    className: styles.permissionFieldEnd,
  },
  {
    section: <h2 className={styles.permissionHeader}>User Management</h2>,
    name: "userManagement",
    className: styles.permissionFieldStart,
  },
  {
    section: "Users and their personal information",
    name: "userPersonalInfo",
  },
  {
    section: "Team Temperature and Location Info",
    name: "teamTempLocationInfo",
    add: false,
    edit: false,
    remove: false,
  },
  {
    section: localStrings.symptoms,
    name: "symptoms",
  },
  {
    section: localStrings.contactHistory,
    name: "contactHistory",
    add: false,
    edit: false,
    remove: false,
  },
  {
    section: <h2 className={styles.permissionHeader}>Area Management</h2>,
    name: "areaManagement",
    className: styles.permissionFieldStart,
  },
  {
    section: "Area Information",
    name: "areaInfo",
  },
];

const PermissionTable = () => {
  const { formFields } = useForm(initialFormFields(permissionsNames));

  const tableData = useMemo(() => {
    return permissionsNames.map((item, i) => {
      const {
        section,
        name,
        add = true,
        edit = true,
        remove = true,
        view = true,
        className,
      } = item;
      return {
        section: section,
        className,
        add: add && `${name}Add`,
        edit: edit && `${name}Edit`,
        remove: remove && `${name}Remove`,
        view: view && `${name}View`,
      };
    });
  }, []);
  return (
    <div className={styles.permissionForm}>
      <h2 className={styles.permissionTitle}>{localStrings.permissions}</h2>
      <table className={styles.permissionFieldsTable}>
        <thead>
          <tr>
            {tableColumns.map((i) => (
              <th width={i.width} key={uuidv4()}>
                {i.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableData.map((data) => (
            <tr key={uuidv4()} className={data["className"]}>
              <td>{data["section"]}</td>
              <td>
                {data["add"] ? (
                  <div className={styles.tableField}>
                    <Checkbox
                      className={styles.checkbox}
                      {...formFields[data["add"]]}
                      name={data["add"]}
                    />
                  </div>
                ) : null}
              </td>
              <td>
                {data["edit"] ? (
                  <div className={styles.tableField}>
                    <Checkbox {...formFields[data["edit"]]} name={data["edit"]} />
                  </div>
                ) : null}
              </td>
              <td>
                {data["remove"] ? (
                  <div className={styles.tableField}>
                    <Checkbox
                      className={styles.checkbox}
                      {...formFields[data["remove"]]}
                      name={data["remove"]}
                    />
                  </div>
                ) : null}
              </td>
              <td>
                {data["view"] ? (
                  <div className={styles.tableField}>
                    <Checkbox
                      className={styles.checkbox}
                      {...formFields[data["view"]]}
                      name={data["view"]}
                    />
                  </div>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default memo(PermissionTable);
