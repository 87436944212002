import React, { useCallback, useContext, useState } from "react";
import { searchUsers } from "api/user-data";
import { getAreaFilters } from "api/area";
import { useApi, useFilter, useMount, useRouter } from "hooks";
import { OrganizationContext } from "contexts";
import { MemberList } from "components/member";
import { userListFilterState } from "states";
import { arrayObjectToSelectData } from "utils";
import localStrings from "localization";
import { cleanTableFilterTracking, getTemperatureType, setTemperatureType } from "services";
import { Common, Mixpanel } from "enums";
import path from "path/path";
import mixpanel from "mixpanel-browser";

const MemberListContainer = () => {
  const { history } = useRouter();

  const [areaFilters, setAreaFilters] = useState([]);
  const [tempType, setTempType] = useState(getTemperatureType());

  const { organizationId } = useContext(OrganizationContext);

  const { modifyFilter, clearFilter, filterState, requestState, isFilterDirty } = useFilter(
    userListFilterState({ organizationId })
  );

  const {
    request: usersRequest,
    loading: loadingUsers,
    result: users = { data: [], total: 0 },
  } = useApi({
    api: searchUsers,
  });

  const { request: areasRequest } = useApi({
    api: getAreaFilters,
  });

  const changeTempTypeCb = useCallback((tempType) => {
    setTempType(tempType);
    setTemperatureType(tempType);

    mixpanel.track(Mixpanel.CHANGE_TEMPERATURE_UNIT);
  }, []);

  const searchCb = useCallback(
    async (searchKey) => {
      const { requestState, filterState } = modifyFilter({ page: 1, searchKey });

      mixpanel.track(Mixpanel.SEARCH_FILTER);
      await usersRequest(requestState);
      history.push(path.MEMBERS, filterState);
    },
    [modifyFilter, usersRequest, history]
  );

  const applyCb = useCallback(async () => {
    const { requestState, filterState } = modifyFilter({ page: 1 });

    mixpanel.track(Mixpanel.APPLY_FILTER, { ...cleanTableFilterTracking({ ...filterState }) });
    await usersRequest(requestState);

    history.push(path.MEMBERS, filterState);
  }, [usersRequest, history, modifyFilter]);

  const changePageCb = useCallback(
    async (page) => {
      const { requestState } = modifyFilter({ page });
      await usersRequest(requestState);
    },
    [modifyFilter, usersRequest]
  );

  const exportUsersCb = useCallback(
    async (callback) => {
      let exportState = {...requestState, page: null, pageSize: null};
      const exportData = await usersRequest(exportState)
      callback(exportData.data)
    },
    [usersRequest, requestState]
  );

  const changePageSizeCb = useCallback(
    async (pageSize) => {
      const { requestState } = modifyFilter({ pageSize, page: 1 });
      await usersRequest(requestState);
    },
    [modifyFilter, usersRequest]
  );

  const clearFilterCb = useCallback(async () => {
    const { requestState } = clearFilter();
    await usersRequest(requestState);
  }, [clearFilter, usersRequest]);

  useMount(() => {
    const init = async () => {
      await usersRequest(requestState);

      const areaRes = await areasRequest({ organizationId });
      setAreaFilters([
        {
          text: localStrings.all,
          value: Common.ALL,
        },
        ...arrayObjectToSelectData(areaRes, "areaName", "areaId"),
        { text: localStrings.otherLocation, value: "0" },
      ]);
    };

    init();
  });

  return (
    <MemberList
      tempType={tempType}
      changeTempType={changeTempTypeCb}
      areaFilters={areaFilters}
      filters={filterState}
      isFilterDirty={isFilterDirty}
      modifyFilter={modifyFilter}
      clearFilter={clearFilterCb}
      members={users.data}
      total={users.total}
      loadingUsers={loadingUsers}
      search={searchCb}
      apply={applyCb}
      changePage={changePageCb}
      changePageSize={changePageSizeCb}
      usersRequest={() => {
        const init = async () => {
          await usersRequest(requestState);
        };
        init();
      }}
      
      exportUsers={exportUsersCb}
      exportingUsers={loadingUsers}
    />
  );
};

export default MemberListContainer;
