import React, { useState } from "react";
import { Modal, ActionButton } from "components/common";
import localStrings from "localization";
// import styles from "./confirmation-modal.module.scss";

export const useConfirmationModal = ({
  onShow,
  onCancel,
  onConfirm,
  title,
  content = localStrings.areYouSure,
  onOkText = localStrings.okay,
  onCancelText = localStrings.cancel,
  className,
  closable = false,
  loading,
}) => {
  const [active, setActive] = useState(false);
  const [value, setValue] = useState({});

  const show = (v = {}) => {
    setActive(true);
    setValue(v);
    if (onShow) {
      onShow();
    }
  };

  const close = () => {
    setActive(false);
    if (onCancel) {
      onCancel(value);
    }
  };

  const render = (
    <Modal
      visible={active}
      onCancel={close}
      className={className}
      title={title}
      closable={closable}
    >
      {content}
      <ActionButton
        loading={loading}
        cancelText={onCancelText}
        submitText={onOkText}
        onSubmit={() => onConfirm(value)}
        onCancel={close}
        type={"danger"}
      />
    </Modal>
  );

  return {
    render,
    show,
    close,
  };
};

export default useConfirmationModal;
