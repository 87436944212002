import React from "react";
import styles from "./privacy-form.module.scss";
import { Select } from "atomic/atoms";
import { withLDConsumer } from "launchdarkly-react-client-sdk";
import { PermissionOptions } from "enums";
import localStrings from "localization";

const InfoContainer = ({
  title = "",
  description = "",
  name,
  formFields,
  setFormFieldValue,
  required,
  requirementKeys,
}) => {
  return (
    <div className={styles.infoContainer}>
      <div className={styles.titleDescContainer}>
        <div className={styles.title}>{title}</div>
        <div className={styles.description}>{description}</div>
      </div>
      <div className={styles.permission}>
        {!required[requirementKeys[name]] ? (
          <Select
            className={styles.select}
            data={PermissionOptions}
            name={name}
            {...formFields[name]}
            onChange={setFormFieldValue}
          />
        ) : (
          localStrings.required
        )}
      </div>
    </div>
  );
};

export default withLDConsumer()(InfoContainer);
