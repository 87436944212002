import React from "react";
import { MenuOption, NotifDot } from "atomic/atoms";
import { Avatar } from "atomic/molecules";
import styles from "./active-organization-item.module.scss";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useRouter } from "hooks";
import Skeleton from "react-loading-skeleton";
import localStrings from "localization";
import { useRouteMatch } from "react-router-dom";

const ActiveOrganizationItem = ({
  organizationLogo,
  firstName,
  lastName,
  dateJoined,
  organizationId,
  organizationName,
  userRole,
  leaveModal,
  loading,
  hasOrganizationPrivacyUpdate,
}) => {
  const { history } = useRouter();
  let { path } = useRouteMatch();

  return (
    <div className={styles.item} key={uuidv4()}>
      <Avatar
        loading={loading}
        image={organizationLogo}
        className={styles.avatar}
        firstName={firstName}
        lastName={lastName}
      />
      <div className={styles.details}>
        <div className={styles.orgNameContainer}>
          <h5 className={styles.orgName}>
            {loading ? <Skeleton width={150} /> : organizationName}
          </h5>
          {hasOrganizationPrivacyUpdate && <NotifDot height={5} width={5} />}
        </div>
        {loading ? (
          <Skeleton width={270} />
        ) : (
          <div className={styles.positionDetails}>
            <span className={styles.orgPosition}>{String(userRole).toUpperCase()}</span>
            <span className={styles.dot}></span>
            <span className={styles.dateJoined}>
              {localStrings.formatString(
                localStrings.joinedOn,
                moment(dateJoined).format("MMMM DD, YYYY")
              )}
            </span>
          </div>
        )}
      </div>
      {!loading && (
        <MenuOption
          options={[
            {
              onClick: () => {
                history.push(`${path}/${organizationId}`);
              },
              label: localStrings.editSettings,
            },
            {
              onClick: leaveModal,
              label: localStrings.leaveOrganization,
            },
          ]}
          className={styles.menu}
        />
      )}
    </div>
  );
};

export default ActiveOrganizationItem;
