import React, { useCallback, useRef } from "react";
import { Input as AntInput } from "antd";
import classnames from "classnames";
import styles from "./input.module.scss";
import PropTypes from "prop-types";

const Input = ({
  placeholder,
  type,
  name,
  maxLength,
  minLength,
  disabled,
  value,
  onChange,
  onFocus,
  onBlur,
  onKeyUp,
  selectAllOnFocus,
  suffix,
  prefix,
  className,
}) => {
  const inputRef = useRef(null);

  const handleOnFocus = useCallback(() => {
    if (onFocus) {
      onFocus();
    }
    if (selectAllOnFocus) {
      inputRef.current.input.select();
    }
  }, [onFocus, selectAllOnFocus]);

  const handleOnBlur = useCallback(
    (e) => {
      if (onBlur) {
        onBlur(e?.target.value.trim());
      }
    },
    [onBlur]
  );

  const handleOnChange = useCallback(
    (e) => {
      if (onChange) {
        onChange(e?.target.value, name);
      }
    },
    [onChange, name]
  );

  const handleOnKeyUp = useCallback(
    (e) => {
      if (onKeyUp) {
        onKeyUp(e?.target.value, e?.key, name);
      }
    },
    [onKeyUp, name]
  );

  return (
    <AntInput
      ref={inputRef}
      disabled={disabled}
      className={classnames(styles.input, className)}
      placeholder={placeholder}
      onChange={handleOnChange}
      type={type}
      maxLength={maxLength}
      minLength={minLength}
      value={value}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onKeyUp={handleOnKeyUp}
      suffix={suffix}
      prefix={prefix}
    />
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
  minLength: PropTypes.number,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  selectAllOnFocus: PropTypes.bool,
  suffix: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.object]),
  className: PropTypes.string,
};

export default Input;
