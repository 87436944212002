import { inviteUser, validateInvite } from "api/user-organization";
import { Toast } from "atomic/molecules";
import { InviteMemberModal } from "components/member";
import { AppContext, OrganizationContext } from "contexts";
import { useApi, useForm } from "hooks";
import React, { useCallback, useContext, useState } from "react";
import { inviteMemberFormState } from "states";
import styles from "./invite-member.module.scss";
import localStrings from "localization";
import { Icon } from "atomic/atoms";
import { updateUser } from "api/user";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "enums";

const InviteMemberContainer = ({ visible, refetch, toggleInviteMemberModal, userId }) => {
  const { dispatch: appDispatch } = useContext(AppContext);

  const [error, setError] = useState(null);

  const { organizationId } = useContext(OrganizationContext);

  const { request: inviteUserRequest, loading: invitingUser } = useApi({
    api: inviteUser,
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
  });
  const { request: editUserRequest, loading: updatingUser } = useApi({
    api: updateUser,
  });
  const { request: validateEmailRequest, loading: validatingEmail } = useApi({
    api: validateInvite,
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
  });

  const { formFields, setFormFieldValue, submit } = useForm(inviteMemberFormState, submitCallBack);

  async function submitCallBack(value) {
    let { email } = value;
    email = email.trim();

    try {
      await validateEmailRequest({
        organizationId,
        email,
        roles: ["MEMBER"],
        userId,
      });
      await inviteUserRequest({
        organizationId,
        email,
        roles: ["MEMBER"],
        userId,
      });
      await editUserRequest({
        userId,
        params: {
          email,
          userId,
          organizationId,
        },
      });
      mixpanel.track(Mixpanel.INVITE_MEMBER);
      refetch();
      Toast({
        className: styles.toastMessage,
        content: (
          <div className={styles.message}>
            <p className={styles.title}>
              <Icon name="check-fill" /> {localStrings.inviteSent}!
            </p>
            <p className={styles.desc}>{localStrings.inviteSentMemberDesc}</p>
          </div>
        ),
        success: true,
      }).open();
      toggleInviteMemberModal(false);
    } catch (e) {
      const { networkError, serverError } = e;
      const error = handleError(e);
      if (error) {
        return false;
      }
      if (serverError) {
        appDispatch({ type: "SERVER_ERROR" });
      }
      if (networkError) {
        appDispatch({ type: "NETWORK_ERROR" });
      }
    }
  }

  const handleError = useCallback((err) => {
    const { metadata } = err;

    if (metadata && metadata.code) {
      const errors = {
        8029: () => {
          setError(localStrings.thisEmailIsAlreadyAMemberOfYourOrg);
        },
        8031: () => {
          setError(localStrings.userHasPendingInvite);
        },
      };
      if (errors[metadata.code]) {
        errors[metadata.code]();
        return true;
      }
    }
  }, []);

  const changeEmailCb = useCallback(
    (value, name) => {
      setFormFieldValue(value, name);
      setError(null);
    },
    [setFormFieldValue]
  );

  return (
    <InviteMemberModal
      error={error}
      visible={visible}
      closeModal={() => toggleInviteMemberModal(false)}
      submit={submit}
      setFormFieldValue={setFormFieldValue}
      formFields={formFields}
      loading={validatingEmail || invitingUser || updatingUser}
      changeEmail={changeEmailCb}
    />
  );
};

export default InviteMemberContainer;
