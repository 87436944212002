import { ApiRoutes, Routes } from "enums";
import { Get, Post } from "./api";

export const getUserDevice = async ({ userId, organizationId }) => {
  const res = await Get(
    `${ApiRoutes.USER_DEVICE_V2}/${Routes.USER}/${userId}/${Routes.ORGANIZATION}/${organizationId}`
  );
  return res.data;
};

export const pairUserDevice = async ({ organizationId, deviceUid, userId }) => {
  const res = await Post(
    `${ApiRoutes.USER_DEVICE_V2}/${Routes.PAIR}/${Routes.ORGANIZATION}/${organizationId}`,
    {
      deviceUid,
      userId,
    }
  );
  return res;
};

export const unPairUserDevice = async ({ organizationId, deviceUid, userId }) => {
  const res = await Post(
    `${ApiRoutes.USER_DEVICE_V2}/${Routes.UNPAIR}/${Routes.ORGANIZATION}/${organizationId}`,
    { deviceUid, userId }
  );
  return res;
};

export const getOwnDevice = async () => {
  const res = await Get(`${ApiRoutes.USER_DEVICE}/${Routes.USER}`);
  return res.data;
};

export const pairOwnDevice = async ({ deviceUid }) => {
  const res = await Post(`${ApiRoutes.USER_DEVICE}/${Routes.PAIR}`, { deviceUid });
  return res.data;
};

export const unpairOwnDevice = async ({ deviceUid }) => {
  const res = await Post(`${ApiRoutes.USER_DEVICE}/${Routes.UNPAIR}`, { deviceUid });
  return res.data;
};

export const searchDevices = async (params) => {
  const res = await Post(`${ApiRoutes.USER_DEVICE}/${Routes.SEARCH}`, params);
  return res;
};

export const createDevices = async ({ accessoryIds }) => {
  const res = await Post(`${ApiRoutes.USER_DEVICE}`, [...accessoryIds]);
  return res.data;
};

export const deleteDevices = async ({ accessoryIds }) => {
  const res = await Post(`${ApiRoutes.USER_DEVICE}/${Routes.DELETE}`, [...accessoryIds]);
  return res.data;
};
