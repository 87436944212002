import React, {
  useState,
  useRef,
  useCallback,
  useImperativeHandle,
  forwardRef,
  useMemo,
} from "react";
import styles from "./input-pill-field.module.scss";
import { Icon } from "atomic/atoms";
import { Pill } from "atomic/molecules";
import classnames from "classnames";
import localTranslation from "localization/localization";

const InputPillField = forwardRef(
  (
    {
      error = {
        errorInput: false,
        errorInputMessage: "",
      },
      onInputValChange = undefined,
      onPillChange = undefined,
      onMultiplePillChange = undefined,
      onDeletePillChange = undefined,
    },
    ref
  ) => {
    const inputEl = useRef(null);
    const inputPillEl = useRef(null);

    const [pills, setPills] = useState([]);
    const [inputVal, setInputVal] = useState("");
    const [focused, setFocused] = useState(false);
    // const [duplicate, setDuplicate] = useState("");

    // Allow parent to set pills manually (eg. validation on parent submit)
    useImperativeHandle(ref, () => ({
      setPillsManually: (pills) => {
        setPills([...pills]);
      },
    }));

    const deleteInputPillValue = useCallback(() => {
      pills.pop();
      return pills;
    }, [pills]);

    // const checkIfDuplicate = useCallback(
    //   (inputValText) => {
    //     const duplicatePill = pills.find((p) => {
    //       return p.value === inputValText;
    //     });
    //     if (duplicatePill) {
    //       // setDuplicate("Duplicate entry.");
    //       return true;
    //     }
    //     // setDuplicate("");
    //     return false;
    //   },
    //   [pills]
    // );

    const focusOnInput = useCallback(
      (e) => {
        inputEl.current.focus();
        setFocused(true);
        e.preventDefault();
      },
      [inputEl]
    );

    const removePill = useCallback(
      (index) => {
        const newPills = [...pills];
        newPills.splice(index, 1);
        if (onDeletePillChange) {
          onDeletePillChange(newPills);
        }
        setPills(newPills);
      },
      [pills, onDeletePillChange]
    );

    const onInputChange = useCallback(
      (e) => {
        let inputVal = e.target.value;
        if (!inputVal) {
          inputVal = "";
        }
        setInputVal(inputVal);
        if (onInputValChange) {
          onInputValChange(inputVal);
        }
      },
      [onInputValChange]
    );

    const isValueOnInputAlreadyExist = useMemo(() => {
      if (
        pills.some((p) => {
          return inputVal.trim().replace(",", "") === p.value;
        })
      ) {
        return true;
      }
      return false;
    }, [inputVal, pills]);

    const addOption = useCallback(
      async (v) => {
        const value = v.replace(",", "").trim();
        if (!value) {
          setInputVal("");
          return false;
        }
        if (isValueOnInputAlreadyExist) {
          return;
        }
        let newPill = { value: value, error: false };

        if (onPillChange) {
          try {
            newPill = await onPillChange(newPill, pills);
            setPills((prev) => {
              const newPills = [...prev, newPill];
              return newPills;
            });
            setInputVal("");
          } catch {
            console.log("Error");
          }
        }
      },
      [onPillChange, pills, isValueOnInputAlreadyExist]
    );

    const onInputBlur = useCallback(() => {
      addOption(inputVal.trim());
      setFocused(false);
    }, [inputVal, addOption]);

    const onKeyUpCb = useCallback(
      async (e) => {
        const trimmedInputVal = inputVal.trim();
        // handler for enter and spacebar
        if (e.key === "Enter" || e.keyCode === 32 || inputVal.includes(",")) {
          const cleanInputVal = inputVal.replace(",", "");
          if (!cleanInputVal.length) {
            e.preventDefault();
          }
          await addOption(trimmedInputVal, e);
        }
      },
      [inputVal, addOption]
    );

    const onKeyDownCb = useCallback(
      (e) => {
        // keyCode 8 is backspace, additional code from onKeyUp backspace to delete pill properly
        if (e.keyCode === 8 && inputVal.length <= 0) {
          if (onDeletePillChange) {
            onDeletePillChange(deleteInputPillValue());
          }
          setPills((prev) => {
            const newPills = [...prev];
            return newPills;
          });
        }
      },
      [inputVal, deleteInputPillValue, onDeletePillChange]
    );

    const onPasteCb = useCallback(
      async (e) => {
        // handling of copy paste from sheets
        let pastedString = e.clipboardData.getData("text");

        if (/\t/.test(pastedString)) {
          pastedString = pastedString.split(/\t/).join("-");
        }
        if (/\n/.test(pastedString)) {
          pastedString = pastedString.split(/\n/).join("-");
        }

        // below code will remove duplicates from the pills
        const splittedString = pastedString.split("-");
        let newPillsArray = splittedString.map((ss) => {
          return { value: ss.trim(), error: false };
        });
        e.preventDefault();
        if (onMultiplePillChange) {
          newPillsArray = await onMultiplePillChange(newPillsArray, pills);
        }
        const combinedPills = [...pills, ...newPillsArray];
        const deduplicatePillsArray = dedupeArray(combinedPills);
        const newPills = [...deduplicatePillsArray];
        setPills(newPills);
      },
      [pills, onMultiplePillChange]
    );

    const dedupeArray = (arr) => {
      const a = arr.reduce((acc, current) => {
        const x = acc.find((item) => item.value === current.value);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);
      return a;
    };

    return (
      <div ref={inputPillEl}>
        <div
          className={classnames(styles.inputPillContainer, {
            [`${styles.inputPillContainerFocused}`]: focused,
          })}
          onClick={(e) => focusOnInput(e)}
        >
          {pills.length > 0 || inputVal.length > 0 || focused ? (
            pills.map((p, i) => {
              return (
                <Pill
                  className={styles.pill}
                  key={i}
                  error={p.error}
                  text={p.value}
                  onClick={() => {
                    removePill(i);
                  }}
                  icon="close"
                />
              );
            })
          ) : (
            <div className={styles.placeholder}>Enter one or many email addresses</div>
          )}
          <div
            className={classnames(styles.inputContainer, {
              [`${styles.inputError}`]: error.errorInput,
            })}
          >
            <input
              ref={inputEl}
              type="text"
              className={classnames(styles.input)}
              value={inputVal}
              maxLength={75}
              onBlur={(e) => onInputBlur(e)}
              onChange={(e) => onInputChange(e)}
              onKeyUp={(e) => onKeyUpCb(e)}
              onKeyDown={(e) => onKeyDownCb(e)}
              onPaste={(e) => onPasteCb(e)}
              size={inputVal.length + 1}
            ></input>
            {inputVal.length > 0 && (
              <Icon
                className={styles.inputClose}
                name="close"
                onClick={(e) => {
                  setInputVal("");
                  onInputChange(e);
                  e.preventDefault();
                }}
              />
            )}
          </div>
          {/* <Icon className={styles.loading} loading={loading}></Icon> */}
        </div>
        <div className={styles.errorContainer}>
          <div className={styles.errorMessage}>{error.errorInputMessage}</div>
          {/* {duplicate.length > 0 && <div className={styles.errorMessage}>{duplicate}</div>} */}
          {isValueOnInputAlreadyExist && (
            <div className={styles.errorMessage}>
              {localTranslation("valueAlreadyExists", [inputVal])}
            </div>
          )}
          {pills.length > 0 &&
            pills.map((p, i) => {
              if (p.errorMessage) {
                if (typeof p.errorMessage === "string") {
                  return (
                    <div key={i} className={styles.errorMessage}>
                      {p.errorMessage}
                    </div>
                  );
                } else {
                  p.errorMessage.map((err, i) => {
                    return (
                      <div key={i} className={styles.errorMessage}>
                        {err}
                      </div>
                    );
                  });
                }
              }
              return null;
            })}
        </div>
      </div>
    );
  }
);

export default InputPillField;
