import React, { memo } from "react";
import styles from "./date-picker-field.module.scss";
import { DatePicker, Loading } from "atomic/atoms";
import classnames from "classnames";

const DatePickerField = ({
  label,
  className,
  disabled,
  error = {},
  message,
  loading = false,
  range = false,
  ...datePickerProps
}) => {
  return (
    <div
      className={classnames(styles.field, className, {
        [`${styles.error}`]: error.status,
        [`${styles.fieldRangeContainer}`]: range,
        // disabled: disabled,
        [`${styles.disabled}`]: disabled,
      })}
    >
      {label && <label className={styles.label}>{label}</label>}
      <div
        className={classnames(styles.datePickerContainer, {
          [`${styles.loading}`]: loading,
          [`${styles.rangeContainer}`]: range,
        })}
      >
        {loading ? (
          <Loading />
        ) : (
          <DatePicker disabled={disabled} range={range} {...datePickerProps} />
        )}
      </div>
      {message || error.status ? (
        <span className={styles.message}>{message || error.message}</span>
      ) : null}
    </div>
  );
};

export default memo(DatePickerField);
