import React, { useCallback, useContext, useState } from "react";
import { TeamManagers } from "components/settings";
import { useApi, useMount } from "hooks";
import {
  removeManager,
  resendInvite,
  revokeInviteManager,
  searchOrganizationMembers,
} from "api/user-organization";
import { OrganizationContext, UserContext } from "contexts";
import { AccountStatus, Mixpanel } from "enums";
import moment from "moment";
import localTranslation from "localization/localization";
import { getFullName, sortByExcelLogic } from "utils";
import localString from "localization";
import { Toast } from "atomic/molecules";
import mixpanel from "mixpanel-browser";

const TeamRolesContainer = () => {
  const actions = {
    Revoke: "REVOKE",
    Remove: "REMOVE",
    Resend: "RESEND",
  };
  const actionModalInitialState = {
    visible: false,
    userId: 0,
    action: null,
    label: "",
    title: "",
    content: "",
    submitText: "",
  };
  const [activeManagers, setActiveManagers] = useState([]);
  const [pendingManagers, setPendingManagers] = useState([]);
  const [actionModal, setActionModal] = useState(actionModalInitialState);

  const { request: orgManagersRequest, loading: loadingOrgManagers } = useApi({
    api: searchOrganizationMembers,
  });
  const { request: removeManagerRequest, loading: removingManager } = useApi({
    api: removeManager,
  });
  const { request: resendInviteRequest, loading: resendingInvite } = useApi({ api: resendInvite });
  const { request: revokeInviteRequest, loading: revokingInvite } = useApi({
    api: revokeInviteManager,
  });

  const { organizationId } = useContext(OrganizationContext);
  const { user } = useContext(UserContext);

  const showModal = useCallback(
    (action, manager) => {
      const modal = {
        [actions.Remove]: {
          title: localString.askRemoveManager,
          content: localTranslation("confirmRemoveManager", [manager.label || ""]),
          submitText: localString.remove,
        },
        [actions.Revoke]: {
          title: localString.revokeInvite,
          content: localTranslation("confirmRevokeManagerInvite", [manager.email || ""]),
          submitText: localString.revoke,
        },
      };

      setActionModal({
        visible: true,
        userId: manager.userId,
        action,
        label: manager.label,
        ...modal[action],
      });
    },
    [actions]
  );

  const closeActionModal = useCallback(() => {
    setActionModal((prev) => {
      return { ...prev, visible: false };
    });
  }, []);

  const filterRemovedManagers = useCallback(
    (userId) => {
      const filteredActiveManagers = activeManagers.filter((am) => userId !== am.userId);
      const filteredPendingManagers = pendingManagers.filter((pm) => userId !== pm.userId);

      setActiveManagers(filteredActiveManagers);
      setPendingManagers(filteredPendingManagers);
    },
    [activeManagers, pendingManagers]
  );

  const submitActionModal = useCallback(
    async (action, manager) => {
      const successMessage = {
        [actions.Remove]: localTranslation("removeManagerSuccess", [actionModal.label]),
        [actions.Revoke]: localTranslation("revokeInviteSuccess", [actionModal.label]),
        [actions.Resend]: localTranslation("resendInviteSuccess", [manager?.email]),
      };

      const actionCall = {
        [actions.Remove]: async () => {
          mixpanel.track(Mixpanel.REMOVE_MANAGER);
          await removeManagerRequest({
            organizationId,
            userId: actionModal.userId,
            roles: [AccountStatus.MANAGER],
          });
          filterRemovedManagers(actionModal.userId);
        },
        [actions.Revoke]: async () => {
          mixpanel.track(Mixpanel.REVOKE_MANAGER_INVITE);
          await revokeInviteRequest({
            organizationId,
            userId: actionModal.userId,
            roles: [AccountStatus.MANAGER],
          });
          filterRemovedManagers(actionModal.userId);
        },
        [actions.Resend]: async () => {
          mixpanel.track(Mixpanel.RESEND_MANAGER_INVITE);
          await resendInviteRequest({
            userId: manager.userId,
            organizationId,
          });
        },
      };

      try {
        await actionCall[action]();
        closeActionModal();
        Toast({
          content: successMessage[action],
          success: true,
          visible: true,
          icon: "check-fill",
        }).open();
      } catch (error) {
        console.log(error);
      }
    },
    [
      actionModal,
      actions,
      organizationId,
      removeManagerRequest,
      resendInviteRequest,
      revokeInviteRequest,
      closeActionModal,
      filterRemovedManagers,
    ]
  );

  useMount(() => {
    const init = async () => {
      const managerResults = await orgManagersRequest({
        organizationId,
        page: 1,
        pageSize: null,
        roles: [AccountStatus.MANAGER],
      });

      const activeManagers = managerResults
        .filter((m) => {
          return !m.pendingRoles || m.pendingRoles?.length <= 0;
        })
        .map((am) => {
          return {
            ...am,
            firstName: am.firstName || "",
            label: `${am.firstName || ""} ${am.lastName || ""}`.trim() || "-",
            value: am.email,
          };
        })
        .sort(sortByExcelLogic("firstName"));

      const currentUser = activeManagers.find((am) => {
        return user.userId === am.userId;
      });

      const willRemoveCurrentUserIndex = activeManagers.findIndex((am) => {
        return user.userId === am.userId;
      });

      if (willRemoveCurrentUserIndex >= 0) {
        activeManagers.splice(willRemoveCurrentUserIndex, 1);
      }

      setActiveManagers([currentUser, ...activeManagers]);

      const pendingManagers = managerResults
        .filter((m) => {
          return m.pendingRoles?.length > 0;
        })
        .map((pm) => {
          return {
            ...pm,
            label: pm.email,
            value: localTranslation("inviteSentDetails", [
              moment(pm.invitedDateCreated).format("MMMM DD, YYYY"),
              getFullName({
                firstName: pm.userInvitedFirstName,
                lastName: pm.userInvitedLastName,
              }),
            ]),
          };
        })
        .sort(sortByExcelLogic("invitedDateCreated"));

      setPendingManagers(pendingManagers);
    };

    init();
  });

  return (
    <TeamManagers
      activeManagers={activeManagers}
      pendingManagers={pendingManagers}
      loading={loadingOrgManagers}
      actions={actions}
      actionModal={actionModal}
      showModal={showModal}
      loadingActionModal={removingManager || resendingInvite || revokingInvite}
      closeActionModal={closeActionModal}
      submitActionModal={submitActionModal}
    />
  );
};

export default TeamRolesContainer;
