import React, { useMemo } from "react";
import { Image, Loading } from "atomic/atoms";
import styles from "./avatar.module.scss";
import classnames from "classnames";
import UserImage from "images/user.svg";
import { getInitials } from "utils";

const Avatar = ({ image, className, loading, phrases = [], showDefault = true, defaultAvatar }) => {
  const initials = getInitials(phrases);
  const defaultImage = defaultAvatar || UserImage;

  const render = useMemo(() => {
    if (!image && !initials && !loading && showDefault) {
      return <Image className={classnames(styles.image, styles.preview)} src={defaultImage} />;
    } else if (image && !loading) {
      return <Image className={styles.image} src={image} />;
    } else {
      return (
        <div
          className={classnames(styles.initials, "initials", {
            [`${styles.loading}`]: loading,
          })}
        >
          {loading ? <Loading height="100%" width="100%" /> : initials}
        </div>
      );
    }
  }, [image, initials, loading, showDefault, defaultImage]);

  return (
    <div
      className={classnames(
        styles.container,
        className,
        !image && !initials && !loading ? styles.imagePreviewContainer : null,
        {
          [`${styles.imageContainer}`]: image,
        }
      )}
    >
      {render}
    </div>
  );
};

export default Avatar;
