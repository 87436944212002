import React from "react";
import { Modal, Image, Link, Icon } from "atomic/atoms";
import localStrings from "localization";
import BackgroundImage from "images/gateway.png";
import styles from "./area-gateway-info-modal.module.scss";
import path from "path/path";
const AreaGatewayInfoModal = ({ modal }) => {
  return (
    <Modal {...modal} title={localStrings.learnMoreTitle} className={styles.container}>
      <div className={styles.header}>
        <h3>{localStrings.whatAreGateways}</h3>
        <p>{localStrings.gatewayDescription}</p>
      </div>
      <div className={styles.content}>
        <div className={styles.list}>
          {[
            {
              text: localStrings.findOutPouchPass,
              url: path.ARTICLE_GATEWAY,
            },
            {
              text: localStrings.howToSetUpPouchPass,
              url: path.ARTICLE_SETUP_GATEWAY,
            },
          ].map((item) => {
            return (
              <div className={styles.item}>
                <Link target="_blank" href={item.url} className={styles.link}>
                  {item.text} <Icon name="right-arrow" />
                </Link>
              </div>
            );
          })}
        </div>
        <div className={styles.background}>
          <Image src={BackgroundImage} />
        </div>
      </div>
    </Modal>
  );
};

export default AreaGatewayInfoModal;
