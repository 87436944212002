import { ApiRoutes, Routes } from "enums";
import { Get, Post } from "./api";

export const searchUsers = async (req) => {
  if (!req.organizationId) {
    console.warn("Organization id is required for getUsers api.");
  }
  const res = await Post(`${ApiRoutes.SEARCH}`, {
    page: 1,
    pageSize: 5,
    ...req,
  });
  const { metadata, data, status } = res || {};
  return {
    data,
    total: metadata?.total,
    status,
  };
};

export const searchUserExport = async (req) => {
  if (!req.organizationId) {
    console.warn("Organization id is required for getUsers api.");
  }
  const res = await Post(`${ApiRoutes.SEARCH}`, {
    page: null,
    pageSize: null,
    ...req,
  });
  const { metadata, data } = res || {};
  return {
    data,
    total: metadata?.total,
  };
};

export const searchUserAdvancedExport = async ({ organizationId, 
  // days 
}) => {
  // const res = await Post(`${Routes.HEALTH}/${Routes.ADVANCED_MEMBERS}/${Routes.EXPORT}/${Routes.ORGANIZATION}/${organizationId}?numberOfDays=${days}`);
  const res = await Post(`${Routes.HEALTH}/${Routes.ADVANCED_MEMBERS}/${Routes.EXPORT}/${Routes.ORGANIZATION}/${organizationId}?numberOfDays=14`);
  const { data } = res;
  return {
    data,
    total: data.length,
  };
};

export const searchUserSymptoms = async (req) => {
  if (!req.organizationId) {
    console.warn("Organization id is required for getUsers api.");
  }
  const res = await Post(`${ApiRoutes.SYMPTOM_EXPORT}`, {
    page: 1,
    pageSize: 5,
    ...req,
  });
  const { data } = res || {};
  return {
    data,
    total: data.total,
  };
};

export const getFeverTrends = async ({ organizationId, timezone }) => {
  const res = await Post(`${ApiRoutes.USER_ORGANIZATION}/${organizationId}/fever-trend`, {
    organizationId,
    timezone,
  });
  return res.data;
};

export const getFeverDetails = async ({ organizationId }) => {
  const res = await Post(`${ApiRoutes.OVERVIEW_FEVER_CASE}`, {
    bodyTemp: "FEVER",
    deviceStatus: "ACTIVE",
    pairingStatus: "PAIRED",
    page: 1,
    pageSize: 5,
    orders: { deviceCreatedDate: "desc" },
    organizationId,
  });
  const { metadata, data } = res;
  return {
    data,
    total: metadata.total,
  };
};

export const getInactiveDetails = async ({ organizationId }) => {
  const res = await Post(`${ApiRoutes.SEARCH}`, {
    deviceStatus: "INACTIVE",
    pairingStatus: "PAIRED",
    page: 1,
    pageSize: 5,
    orders: { deviceCreatedDate: "asc" },
    organizationId,
  });
  const { metadata, data } = res;
  return {
    data,
    total: metadata.total,
  };
};

export const getTotals = async ({ organizationId }) => {
  const res = await Get(`${ApiRoutes.OVERVIEW_ORG}/${organizationId}`);
  return res.data;
};

export const getUserCount = async ({ organizationId }) => {
  const res = await Get(
    `${ApiRoutes.USER}/${Routes.COUNT}/${Routes.ORGANIZATION}/${organizationId}`
  );
  return res.data;
};

export const getTodayData = async ({ userId, organizationId, timezone, startDate, endDate }) => {
  const res = await Post(`${ApiRoutes.USER_DATA}/${Routes.USER}/${userId}`, {
    organizationId,
    timezone,
    startDate,
    endDate,
  });
  return res.data;
};

export const getTodayHourly = async ({ userId, organizationId, timezone, date }) => {
  const res = await Post(`${ApiRoutes.USER_DATA}/${Routes.USER}/${userId}/hour`, {
    organizationId,
    timezone,
    date,
  });
  return res.data;
};

export const getPastDays = async ({ userId, organizationId, timezone, startDate, endDate }) => {
  const res = await Post(`${ApiRoutes.USER_DATA}/${Routes.USER}/${userId}/${Routes.DAY}`, {
    organizationId,
    timezone,
    startDate,
    endDate,
  });
  return res.data;
};

export const getPastDay = async (userId, params) => {
  const res = await Post(`${ApiRoutes.USER_DATA}/${Routes.USER}/${userId}`, params);
  return res.data;
};
