import React, { useState, useCallback } from "react";
import { PouchBandRegistryListContainer } from "containers";
import PouchBandRegistryListingContainer from "./pouchband-registry-listing/pouchband-registry-listing.container";
import { useApi } from "hooks";
import { searchDevices } from "api/user-device";

const PouchBandRegistryContainer = () => {
  const [submittedSearchKey, setSubmittedSearchKey] = useState("");
  const { request, loading = true, result: data = { data: [] } } = useApi({
    api: searchDevices,
  });

  const refreshSearchedList = useCallback(() => {
    request({
      searchKey: submittedSearchKey,
    });
  }, [request, submittedSearchKey]);

  return (
    <div>
      <PouchBandRegistryListContainer
        request={request}
        loading={loading}
        data={data}
        submittedSearchKey={submittedSearchKey}
        setSubmittedSearchKey={setSubmittedSearchKey}
      />
      <PouchBandRegistryListingContainer refreshList={refreshSearchedList} />
    </div>
  );
};

export default PouchBandRegistryContainer;
