import React, { useCallback } from "react";
import { createQuestion } from "api/organization";
import localization from "localization";
import { Modal } from "atomic/atoms";
import { Toast } from "atomic/molecules";
import QuestionForm from "../question-form/question-form-container";
import { QuestionFieldTypes } from "enums";
import styles from "../questions-container.module.scss";

const CreateQuestionFormModalContainer = ({
  visible,
  toggleFormModal,
  fetchQuestions,
  initialValue = undefined,
  customSuccess,
  submitText = localization.saveQuestion,
}) => {
  const handleSuccessSubmit = useCallback(
    (d) => {
      if (customSuccess) {
        customSuccess(d);
      } else {
        Toast({
          content: localization.savedNewQuestion,
          icon: "check-fill",
          success: true,
        }).open();
        toggleFormModal({ visible: false });
        fetchQuestions();
      }
    },
    [toggleFormModal, fetchQuestions, customSuccess]
  );

  return (
    <Modal
      title={localization.newQuestion}
      className={styles.formModal}
      visible={visible}
      onCancel={() => toggleFormModal({ visible: false })}
      destroyOnClose
    >
      <QuestionForm
        submitApi={createQuestion}
        successSubmit={handleSuccessSubmit}
        fieldTypeOptions={QuestionFieldTypes}
        toggleFormModal={toggleFormModal}
        initialValue={initialValue}
        submitText={submitText}
      />
    </Modal>
  );
};

export default CreateQuestionFormModalContainer;
