import React, { useCallback } from "react";
import localStrings from "localization";
import styles from "./upload-avatar.module.scss";
import { Button, Avatar, UploadField, Toast } from "atomic/molecules";
import CommunityImage from "images/community.svg";
import classnames from "classnames";

const UploadAvatar = ({ phrases, avatar = null, setAvatar, loading, className }) => {
  const beforeUpload = useCallback((file) => {
    console.log(file);
    const fileName = file.name.split(".");
    console.log(fileName);
    const fileExt = fileName[fileName.length - 1];
    if (fileName.length === 1) {
      showError();
      return false;
    }
    const isJpgOrPng = ["png", "jpeg", "jpg", "gif"].includes(fileExt.toString().toLowerCase());
    const isLt1M = file.size / 1024;
    const limit = isLt1M >= 4096;
    if (!isJpgOrPng || limit) {
      Toast({
        content: !isJpgOrPng
          ? localStrings.youCanOnlyUploadJpgPngGifFile
          : localStrings.unbaleToUploadMoreThanMb,
        error: true,
        icon: "exclamation-fill",
      }).open();
      return false;
    }
    return true;
  }, []);

  const showError = () => {
    Toast({
      content: localStrings.somethingWentWrong,
      error: true,
      icon: "exclamation-fill",
    }).open();
  };

  const uploadAvatar = useCallback(async (res) => {
    const { onSuccess } = res;
    try {
      if (res && res.fileList) {
        onSuccess(res);
        // const reader = new FileReader();
        // const file = res.fileList[0].originFileObj;
        // reader.readAsDataURL(file);
        // let onload = null;
        // onload = reader.addEventListener(
        //   "load",
        //   function () {
        //     // const isImageValid = beforeUpload(file);
        //     // if (isImageValid) {
        //     // setAvatar(reader.result, source);
        //     // reader.abort();
        //     // reader.removeEventListener("load", onload);
        //     // reader.destroy();
        //     // }
        //     onSuccess({ result: reader.result, source });
        //   },
        //   false
        // );
      }
    } catch {
      showError();
    }
  }, []);

  return (
    <div className={styles.avatarContainer}>
      <Avatar
        className={classnames(styles.avatar, className)}
        phrases={phrases}
        image={avatar}
        loading={loading}
        defaultAvatar={CommunityImage}
      />
      <div className={styles.avatarActions}>
        <UploadField
          className={styles.uploadContainer}
          beforeUpload={() => {
            return true;
          }}
          customRequest={uploadAvatar}
          onChange={(res) => {
            const reader = new FileReader();
            const file = res.fileList[res.fileList.length - 1].originFileObj;
            reader.readAsDataURL(file);
            let onload = null;
            onload = reader.addEventListener(
              "load",
              function () {
                const isImageValid = beforeUpload(file);
                if (isImageValid) {
                  setAvatar(reader.result, res.file);
                  reader.abort();
                  reader.removeEventListener("load", onload);
                }
              },
              false
            );
          }}
        >
          <Button
            className={styles.uploadButton}
            text={avatar ? localStrings.changePhoto : localStrings.uploadPhoto}
          />
        </UploadField>
        {avatar && (
          <Button
            tertiary
            text={localStrings.remove}
            onClick={() => {
              setAvatar("");
            }}
          />
        )}
        <p className={styles.uploadGuide}>JPG, GIF or PNG. Max size of 4MB</p>
      </div>
    </div>
  );
};

export default UploadAvatar;
