import React, { useContext, useState } from "react";
import { useApi, useMount, useRouter } from "hooks";
import { getLocation } from "utils/map";
import { UserContext, UserOrganizationsContext } from "contexts";
import { organizationState } from "states";
import { MyOrganizationDetails } from "components/settings";
import { SettingsLayout } from "layouts";
import localString from "localization";
import { clearUserPrivacyStatusNotif, getPrivacyChanges } from "api/organization";

const MyOrganizationsDetailContainer = ({ leaveModal }) => {
  const [organization, setOrganization] = useState(organizationState);
  const [orgLocation, setOrgLocation] = useState(null);
  const [privacyChanges, setPrivacyChanges] = useState(null);

  const { user } = useContext(UserContext);
  const { userOrganizations } = useContext(UserOrganizationsContext);

  const { query } = useRouter();

  const { request: orgLocationRequest, loading: orgLocationLoading } = useApi({ api: getLocation });

  const { request: clearUserPrivacyStatusNotifRequest } = useApi({
    api: clearUserPrivacyStatusNotif,
  });

  const { request: getPrivacyChangesRequest } = useApi({ api: getPrivacyChanges });

  useMount(() => {
    const init = async (organization) => {
      const { organizationId, latitude, longitude } = organization || {};
      try {
        const address = await orgLocationRequest({
          lat: latitude,
          lng: longitude,
        });
        setOrgLocation(address ? address : "N/A");

        if (organizationId) {
          await clearUserPrivacyStatusNotifRequest({
            organizationId,
            userId: user.userId,
          });
          setPrivacyChanges(await getPrivacyChangesRequest({ organizationId }));
        }
      } catch (e) {
        console.log(e);
      }
    };

    const organization = userOrganizations.find(
      (uo) => uo.organizationId === parseInt(query.organizationId)
    );

    setOrganization(organization);

    init(organization);
  });

  return (
    <SettingsLayout title={localString.myOrganizations}>
      <MyOrganizationDetails
        organization={organization}
        loading={orgLocationLoading}
        orgLocation={orgLocation}
        leaveModal={leaveModal}
        privacyChanges={privacyChanges}
      />
    </SettingsLayout>
  );
};

export default MyOrganizationsDetailContainer;
