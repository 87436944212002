import React from "react";
import styles from "./empty-organization.module.scss";
import localStrings from "localization";
import PersonUsePhoneImage from "images/person-use-phone.png";
import path from "path/path";
import { Image, Link } from "atomic/atoms";

const EmptyOrganization = () => {
  return (
    <div className={styles.emptyContainer}>
      <Image src={PersonUsePhoneImage}></Image>
      <div className={styles.noOrg}>{localStrings.youDontHaveOrg}</div>
      <div className={styles.findOut}>
        {localStrings.findOutHowCreateOrg}{" "}
        <Link
          className={styles.findOutLink}
          text="create a community with PouchPASS."
          href={path.BUSINESS_FOR_BUSINESS}
          target="_blank"
        />
      </div>
    </div>
  );
};

export default EmptyOrganization;
