import React, { useMemo } from "react";
import styles from "./temperature-average.module.scss";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { Temp, TempStatus } from "atomic/atoms";

const TemperatureAverage = ({ data = [], loading, className }) => {
  const temperatures = data.map((d) => {
    return d.bodyTemp;
  });

  const temperature = useMemo(() => {
    if (temperatures) {
      const d = {};
      temperatures.forEach((t) => {
        d[t] = d[t] ? d[t] + 1 : 1;
      });
      return d;
    }
    return {};
  }, [temperatures]);

  const keys = Object.keys(temperature)
    .map((k) => parseFloat(k))
    .sort();

  const average = useMemo(() => {
    // const av = {};
    // keys.forEach((dt) => {
    //   av[temperature[dt]] = dt;
    // });
    // const i = Number(Object.keys(av)[Object.keys(av).length - 1]);
    // return i === 1 ? temperatures[0] : av[i] || "";
    let averageTemp = 0;
    temperatures.forEach((d) => {
      averageTemp = averageTemp + Number(d);
    });
    return averageTemp / temperatures.length;
  }, [temperatures]);

  const highest = useMemo(() => {
    return keys[keys.length - 1] || "";
  }, [keys]);

  const lowest = useMemo(() => {
    return keys[0] || "";
  }, [keys]);

  if (loading) {
    return null;
  }

  return (
    <div className={classnames(styles.container, className)}>
      <div>
        <h6>Average temperature for the day is</h6>
        <h1 className={styles.status}>
          <TempStatus temp={average} />
        </h1>
      </div>
      <div className={styles.items}>
        {[
          {
            text: "Average",
            key: "AVERAGE",
            value: average,
          },
          {
            text: "Highest",
            key: "HIGHEST",
            value: highest,
            removeClass: true,
          },
          {
            text: "Lowest",
            key: "LOWEST",
            value: lowest,
            removeClass: true,
          },
        ].map((v) => {
          return (
            <div key={uuidv4()}>
              <div className={styles.label}>{v.text}</div>
              <div className={styles.temp}>
                <Temp
                  temp={v.value}
                  className={{
                    container: styles.tempContainer,
                    normal: v.key === "AVERAGE" ? styles.normal : styles.black,
                    high: v.removeClass ? styles.black : "",
                    low: v.removeClass ? styles.black : "",
                  }}
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default TemperatureAverage;
