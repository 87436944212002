import React from "react";
import localStrings from "localization";
import styles from "./contact-history-header.module.scss";
import { Loading } from "atomic/atoms";
import { Button } from "atomic/molecules";
import localTranslation from "localization/localization";

const MemberContactHistoryHeader = ({ loading, nickname, hasData, onClickExport }) => {
  return (
    <div className={styles.header}>
      <div className={styles.titleContainer}>
        <h2 className={styles.title}>{localStrings.contactHistory}</h2>
        <p className={styles.desc}>
          {loading ? <Loading width="100%" /> : localTranslation("contactHistoryDesc", [nickname])}
        </p>
      </div>
      {hasData && (
        <Button
          className={styles.export}
          icon="export"
          text={localStrings.export}
          onClick={onClickExport}
        />
      )}
    </div>
  );
};
export default MemberContactHistoryHeader;
