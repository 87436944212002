import moment from "moment";
import * as momentTimezone from "moment-timezone";

export const formatToLocalDate = (date, format = "MMM DD, YYYY, hh:mm A") => {
  return date && moment(date).local().format(format);
};

export const isBetweenDate = (date, amount, unit) => {
  const a = moment().subtract(amount, unit);
  const b = moment().add(amount, unit);
  return moment(date).isBetween(a, b);
};

export const formatToDateTimeRequest = (date) => {
  const tz = moment(date).format("Z").replace(":", "");
  return moment(date).format(`YYYY-MM-DDTHH:mm:00.0${tz}`);
};

export const getBrowserTimezone = momentTimezone.tz.guess();
