import React, { memo, useMemo } from "react";
import { Field, MapLocation } from "atomic/molecules";
import styles from "./map-location-coordinates-field.module.scss";

const MapLocationCoordinatesField = ({ latitude, longitude, onChange }) => {
  const mapCoordinates = useMemo(() => {
    return {
      lat: parseFloat(latitude) || null,
      long: parseFloat(longitude) || null,
    };
  }, [latitude, longitude]);

  return (
    <>
      <Field>
        <MapLocation
          className={styles.mapLocation}
          latitude={mapCoordinates.lat}
          longitude={mapCoordinates.long}
          noMarker
          setCoordinates={(c) => {
            console.log(c);
            onChange({
              latitude: c.latitude,
              longitude: c.longitude,
            });
          }}
        />
      </Field>
    </>
  );
};

export default memo(MapLocationCoordinatesField);
