import React, { useContext, useMemo, memo } from "react";
import { Map } from "atomic/organisms";
import classnames from "classnames";
import styles from "./user-location.module.scss";
import { AppContext, ViewUserContext } from "contexts";
import localStrings from "localization";
import Skeleton from "react-loading-skeleton";
import UserPin from "images/user-pin.svg";
import { getInitials } from "utils";

const UserLocation = ({ className, containerClassName, isMember }) => {
  const { state } = useContext(ViewUserContext);
  const { state: appState } = useContext(AppContext);
  const { lat, lng, loading: organizationLoading } = appState.organization || {};
  const {
    todayDetails,
    pastDayDetails,
    userInfo,
    latestPastDetailsLocation,
    latestTodayDetailsLocation,
    // accessoryDetails,
  } = state;
  const { loading: userInfoLoading, firstName, lastName } = userInfo;
  const { loading: todayLoading, data: todayData } = todayDetails;
  const { loading: pastDayLoading, data: pastDayData } = pastDayDetails;
  // const [isLoading, setIsLoading] = useState(true);
  const initCenter = useMemo(() => {
    if (!organizationLoading) {
      return {
        lat,
        lng,
      };
    }
  }, [lat, lng, organizationLoading]);
  const isLoading = useMemo(() => {
    return todayLoading || pastDayLoading || userInfoLoading || organizationLoading;
  }, [todayLoading, pastDayLoading, userInfoLoading, organizationLoading]);

  const marker = useMemo(() => {
    const initial = getInitials([firstName, lastName]);

    if (!isLoading) {
      if (todayData[0] || pastDayData[0]) {
        const { latitude: lat, longitude: lng } = todayData[0] || pastDayData[0];
        return {
          position: { lat, lng },
          icon: UserPin,
          label: { text: initial, color: "white" },
        };
      }
    }
  }, [firstName, lastName, isLoading, pastDayData, todayData]);

  // useEffect(() => {
  //   setIsLoading(
  //     () =>
  //       todayLoading || pastDayLoading || userInfoLoading || organizationLoading
  //   );
  // }, [todayLoading, pastDayLoading, userInfoLoading, organizationLoading]);

  const render = (() => {
    if (!todayData.length && !pastDayData.length) {
      return isMember ? localStrings.unableToLocateMember : localStrings.unableToLocateUser;
    }
    return latestTodayDetailsLocation.loading && latestPastDetailsLocation.loading ? (
      <Skeleton />
    ) : (
      latestTodayDetailsLocation.location ||
        latestPastDetailsLocation.location ||
        localStrings.unableToLocateMember
    );
  })();

  return (
    <div className={classnames(styles.container, containerClassName)}>
      <Map className={className} marker={marker} initCenter={initCenter} />
      <div className={styles.lastLocation}>{render}</div>
    </div>
  );
};

export default memo(UserLocation);
