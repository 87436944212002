import React, { memo } from "react";
import classnames from "classnames";
import styles from "./textarea-field.module.scss";
import { Icon, Textarea, Loading } from "atomic/atoms";

const Suffix = ({ suffix, renderSuffix }) => {
  if (renderSuffix) {
    return suffix;
  }
  if (suffix) {
    return <Icon {...suffix} />;
  }
  return null;
};

const Prefix = (props) => {
  return props ? <Icon {...props} /> : null;
};

const TextareaField = ({
  className,
  label,
  error = {},
  message,
  disabled,
  value = "",
  suffix = null,
  prefix = null,
  loading,
  renderSuffix,
  showCount,
  maxLength,
  autoSize,
  showRequired,
  ...textAreaProps
}) => {
  return (
    <div
      className={classnames(className, styles.field, "inputField", {
        [`${styles.error}`]: error.status,
        [`${styles.suffixed}`]: loading || suffix,
        [`${styles.prefixed}`]: prefix,
        [`${styles.disabled}`]: disabled,
        [`${styles.loading}`]: loading,
        error: error.status,
      })}
    >
      {label && <label className={styles.label}>{label}</label>}
      {loading && <Loading />}
      {!loading && (
        <>
          <div className={classnames(styles.container, "container")}>
            {showCount && (
              <span className={styles.count}>{`${value ? value.length : 0} / ${maxLength}`}</span>
            )}
            <Textarea
              autoSize={autoSize}
              disabled={disabled}
              className={classnames(styles.input, {
                [`${styles.hasCount}`]: showCount,
              })}
              suffix={
                loading || suffix ? <Suffix suffix={suffix} renderSuffix={renderSuffix} /> : null
              }
              prefix={prefix ? <Prefix {...prefix} /> : null}
              maxLength={maxLength}
              value={value}
              {...textAreaProps}
            />
            {showRequired && (
              <span className={styles.required}>
                <Icon className={styles.requiredIcon} name="asterisk" />
              </span>
            )}
          </div>
        </>
      )}
      {error.message || message ? (
        <span className={styles.message}>{error.message || message}</span>
      ) : null}
    </div>
  );
};

export default memo(TextareaField);
