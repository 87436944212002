import localStrings from "localization";
import React, { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";

const localTranslation = (key, params = []) => {
  let word = localStrings[key];
  if (params.length) {
    params.forEach((text, key) => {
      word = word.replace(`{${key}}`, text);
    });
  }
  return word;
};

export const Text = ({ text }) => {
  return text;
};

export const LocalTranslation = ({ text, items }) => {
  const translated = useMemo(() => {
    const sentence = text.split(" ");
    const buildSentence = [];
    sentence.forEach((word) => {
      let placeholder = false;
      items.forEach((item, key) => {
        if (word.toString().includes(`{${key}}`)) {
          buildSentence.push(item);
          placeholder = true;
        }
      });
      if (!placeholder) {
        buildSentence.push(` ${word} `);
      }
    });
    return buildSentence;
  }, [text, items]);
  return (
    <span>
      {translated.map((t) => {
        return <Text key={uuidv4()} text={t} />;
      })}
    </span>
  );
};

export default localTranslation;
