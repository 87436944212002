import React from "react";
import styles from "./label-items.module.scss";
import classnames from "classnames";
import { Loading, Icon, Tooltip } from "atomic/atoms";
import { LabelItem } from "atomic/molecules";
import { v4 as uuidv4 } from "uuid";
import localStrings from "localization";

const Items = ({ list, loading }) => {
  return (
    <div className={classnames(styles.item, "item")}>
      {list.map((l) => {
        return (
          <LabelItem key={uuidv4()} classname={classnames(styles.item, "item")} label={l.label}>
            {loading ? <Loading /> : l.item}
          </LabelItem>
        );
      })}
    </div>
  );
};

const LabelItems = ({
  title,
  items,
  className,
  loading,
  active = false,
  onClick,
  value,
  content,
  lock,
}) => {
  return (
    <div className={classnames("label-items", styles.container, className)}>
      <div className={styles.header}>
        <h4 className={styles.title}>{title}</h4>
        {lock ? (
          <Tooltip title={localStrings.private}>
            <div>
              <Icon loading={loading} className={styles.icon} name={"lock"} />
            </div>
          </Tooltip>
        ) : (
          <Icon
            loading={loading}
            className={styles.icon}
            name={active ? "down-chevron-thick" : "right-chevron-medium"}
            onClick={() => {
              if (active) {
                onClick(null);
                return;
              }
              onClick(value);
            }}
          />
        )}
      </div>

      {active && (
        <div className={styles.items}>{content || <Items loading={loading} list={items} />}</div>
      )}
    </div>
  );
};

export default LabelItems;
