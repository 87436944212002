import { Loading, MenuOption } from "atomic/atoms";
import { Panel } from "atomic/molecules";
import React, { useMemo } from "react";
import styles from "./area-card.module.scss";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { GatewayStatus } from "enums";
import { useRouter } from "hooks";
import path from "path/path";
import localStrings from "localization";

const AreaCard = ({ area = {}, loading, getMenuOptions }) => {
  const {
    areaGroupName,
    areaGroupDescription,
    totalActiveUsers,
    totalUsersWithFever,
    areas = [],
  } = area;

  const { history } = useRouter();

  const gatewayContent = useMemo(() => {
    if (areas.length > 0) {
      const slicedAreas = areas.slice(0, 3);

      const gateways = slicedAreas.map((area) => {
        let gatewayNameDisplay = "-";

        if (area.gatewayName) {
          gatewayNameDisplay = area.gatewayName;
        } else if (area.gatewayUid) {
          gatewayNameDisplay = area.gatewayUid;
        }
        return (
          <div key={uuidv4()} className={styles.gateway}>
            {(area.gatewayName || area.gatewayUid) && (
              <div
                className={classnames(styles.circle, {
                  [`${styles.connected}`]: area.status === GatewayStatus.CONNECTED,
                  [`${styles.disconnected}`]: area.status === GatewayStatus.DISCONNECTED,
                  [`${styles.notConfigured}`]: area.status === GatewayStatus.NOT_CONFIGURED,
                })}
              ></div>
            )}

            <div className={styles.gatewayName}>{gatewayNameDisplay}</div>
          </div>
        );
      });

      if (areas.length > 3) {
        return (
          <>
            {gateways}
            <div className={styles.andMore}>and more...</div>
          </>
        );
      }
      return gateways;
    }
    return null;
  }, [areas]);

  return (
    <div
      className={styles.container}
      onClick={() => {
        if (areas.length === 1) {
          history.push(path.AREAS_EDIT(areas[0].areaId));
        }
      }}
    >
      <Panel className={styles.panel}>
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.subContainer}>
            <div className={classnames(styles.nameContainer, styles.column)}>
              <div className={styles.name}>{areaGroupName}</div>
              <div className={styles.description}>{areaGroupDescription || "-"}</div>
            </div>
            <div className={classnames(styles.activeMemberContainer, styles.column)}>
              <div
                className={classnames(styles.count, styles.activeCount, {
                  [`${styles.zero}`]: totalActiveUsers === 0,
                })}
              >
                {totalActiveUsers}
              </div>
              <div className={styles.label}>{localStrings.activeMembers}</div>
            </div>
            <div className={classnames(styles.tempCasesContainer, styles.column)}>
              <div
                className={classnames(styles.count, styles.tempCount, {
                  [`${styles.zero}`]: totalUsersWithFever === 0,
                })}
              >
                {totalUsersWithFever}
              </div>
              <div className={styles.label}>{localStrings.tempCases}</div>
            </div>
            <div className={classnames(styles.gatewayContainer, styles.column)}>
              {gatewayContent}
            </div>
            <div
              className={classnames(styles.menuOptionContainer, styles.column, {
                [`${styles.hidden}`]: areas.length > 1,
              })}
            >
              <MenuOption
                options={getMenuOptions(area)}
                itemMenuClassNameContainer={styles.menuOption}
              />
            </div>
          </div>
        )}
      </Panel>
    </div>
  );
};

export default AreaCard;
