import React from "react";
import LayoutA from "../layout-a/layout-a";
import { BackLink, Panel } from "atomic/molecules";
import localString from "localization";
import styles from "./layout-b.module.scss";
import { Loading } from "atomic/atoms";

const LayoutB = ({
  onBackPage = { onClick: () => {}, text: localString.back },
  children,
  title,
  description,
  loading,
  footer,
}) => {
  return (
    <LayoutA className={styles.container}>
      {onBackPage && (
        <BackLink onClick={() => onBackPage.onClick()} text={onBackPage.text || localString.back} />
      )}
      <div className={styles.content}>
        <div className={styles.header}>
          <h2 className={styles.title}>{title}</h2>
          {description}
        </div>
        <div className={styles.panel}>
          {loading ? (
            <>
              <Panel className={styles.panelLoading}>
                <Loading isSkeleton height="100%" width="100%" />
              </Panel>
              <Panel className={styles.panelLoading}>
                <Loading isSkeleton height="100%" width="100%" />
              </Panel>
            </>
          ) : (
            children
          )}
        </div>
      </div>

      {footer}
    </LayoutA>
  );
};

export default LayoutB;
