import React, { useCallback, useState, useContext } from "react";
import Questions from "components/settings/questions/questions";
import { useApi, useMount, useRouter } from "hooks";
import { autoAddQuestion, searchQuestions } from "api/organization";
import { OrganizationContext } from "contexts";
import { Toast } from "atomic/molecules";
import localStrings from "localization";
import path from "path/path";

const QuestionsContainer = () => {
  const { organizationId } = useContext(OrganizationContext);

  const [isDefaultQuestionVisible, setIsDefaultQuestionVisible] = useState(false);
  const [isCustomQuestionVisible, setIsCustomQuestionVisible] = useState(false);
  const [defaultQuestions, setDefaultQuestions] = useState([]);
  const [customQuestions, setCustomQuestions] = useState([]);
  const [loadingQuestionId, setLoadingQuestionId] = useState(null);
  const [questionFormModal, setQuestionFormModal] = useState({
    visible: false,
    isEdit: false,
    title: "New Question",
    isDefaultQuestion: false,
  });
  const [createFormModal, setCreateFormModal] = useState({ visible: false });
  const [editFormModal, setEditFormModal] = useState({
    visible: false,
    isDefaultQuestion: false,
    organizationQuestionId: null,
  });
  const { history } = useRouter();

  const { request: searchQuestionRequest, loading: loadingQuestions } = useApi({
    api: searchQuestions,
    params: {
      organizationId,
      page: 1,
    },
  });

  const { request: autoAddQuestionRequest, loading: isAutoAdding } = useApi({
    api: autoAddQuestion,
    handleOwnError: {
      badrequest: () => {
        Toast({
          content: localStrings.oopsSomethinngWentWrong,
          error: true,
          maxCount: 1,
        }).open();
      },
    },
  });

  useMount(() => {
    fetchQuestions();
  });

  const fetchQuestions = useCallback(async () => {
    const questionResult = await searchQuestionRequest();
    setDefaultQuestions(questionResult.defaultQuestions);
    setCustomQuestions(questionResult.customQuestions);
  }, [searchQuestionRequest]);

  const toggleDefaultQuestion = useCallback(() => {
    setIsDefaultQuestionVisible(!isDefaultQuestionVisible);
  }, [isDefaultQuestionVisible]);

  const toggleCustomQuestion = useCallback(() => {
    setIsCustomQuestionVisible(!isCustomQuestionVisible);
  }, [isCustomQuestionVisible]);

  const toggleQuestionFormModal = useCallback((config) => {
    setQuestionFormModal((prev) => ({ ...prev, ...config }));
  }, []);

  const toggleCreateFormModal = useCallback((config) => {
    setCreateFormModal((prev) => ({ ...prev, ...config }));
  }, []);

  const toggleEditFormModal = useCallback((config) => {
    setEditFormModal((prev) => ({ ...prev, ...config }));
  }, []);

  const modifyQuestion = useCallback(
    ({ questionId, isDefault, values }) => {
      let questionList = [];
      let questionListSetter = null;

      if (isDefault) {
        questionList = [...defaultQuestions];
        questionListSetter = setDefaultQuestions;
      } else {
        questionList = [...customQuestions];
        questionListSetter = setCustomQuestions;
      }

      const toModifyQuestionIndex = questionList.findIndex((q) => {
        return q.organizationQuestionId === questionId;
      });

      if (toModifyQuestionIndex >= 0) {
        let modifiedQuestions = [...questionList];

        modifiedQuestions[toModifyQuestionIndex] = {
          ...modifiedQuestions[toModifyQuestionIndex],
          ...values,
        };

        questionListSetter([...modifiedQuestions]);
      }
    },
    [defaultQuestions, customQuestions]
  );

  const goBack = useCallback(() => {
    history.push(path.SETTINGS_FORM);
  }, [history]);

  const toggleAutoAddQuestion = useCallback(
    async (isAutoAdd, questionId, isDefault) => {
      modifyQuestion({ questionId, isDefault, values: { loading: true } });
      setLoadingQuestionId(questionId);
      await autoAddQuestionRequest({ organizationId, questionId, isAutoAdd });
      modifyQuestion({ questionId, isDefault, values: { isAutoAdd, loading: false } });
      setLoadingQuestionId(null);
      Toast({
        content: localStrings.changesSaved,
        error: false,
        success: true,
        maxCount: 1,
      }).open();
    },
    [organizationId, autoAddQuestionRequest, modifyQuestion]
  );

  return (
    <Questions
      loading={loadingQuestions}
      defaultQuestions={defaultQuestions}
      customQuestions={customQuestions}
      questionFormModal={questionFormModal}
      createFormModal={createFormModal}
      editFormModal={editFormModal}
      isDefaultQuestionVisible={isDefaultQuestionVisible}
      isCustomQuestionVisible={isCustomQuestionVisible}
      isAutoAdding={isAutoAdding}
      toggleQuestionFormModal={toggleQuestionFormModal}
      toggleDefaultQuestion={toggleDefaultQuestion}
      toggleCustomQuestion={toggleCustomQuestion}
      toggleAutoAddQuestion={toggleAutoAddQuestion}
      toggleCreateFormModal={toggleCreateFormModal}
      toggleEditFormModal={toggleEditFormModal}
      goBack={goBack}
      fetchQuestions={fetchQuestions}
      loadingQuestionId={loadingQuestionId}
    />
  );
};

export default QuestionsContainer;
