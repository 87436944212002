import React, { useState, useEffect, useMemo, useCallback } from "react";
import styles from "./quarantine-status.module.scss";
import { getLocation } from "utils/map";
import { isNumber } from "utils";
import classnames from "classnames";
import { Icon, Loading } from "atomic/atoms";
import localStrings from "localization";

const QuarantineStatus = ({ userInfo, location }) => {
  const [currentLocationContent, setCurrentLocationContent] = useState();
  const [quarantineCurrentLocation, setQuarantineCurrentLocation] = useState();
  const [fetchingQuarantineLocation, setFetchingQuarantineLocation] = useState();

  useEffect(() => {
    const getQuarantineCurrentLocation = async () => {
      const {
        quarantineLocation,
        quarantineCurrentLatitude,
        quarantineCurrentLongitude,
      } = userInfo;
      if (
        quarantineLocation &&
        isNumber(quarantineCurrentLatitude) &&
        isNumber(quarantineCurrentLongitude)
      ) {
        setFetchingQuarantineLocation(true);
        try {
          const loc = await getLocation({
            lat: quarantineCurrentLatitude,
            lng: quarantineCurrentLongitude,
            delay: 0,
          });
          setQuarantineCurrentLocation(loc);
        } catch (err) {
          console.warn("No geolocation result from Google.");
        } finally {
          setFetchingQuarantineLocation(false);
        }
      }
    };

    getQuarantineCurrentLocation();
  }, [userInfo]);

  const toggleCurrentLocationContent = useCallback(() => {
    setCurrentLocationContent(!currentLocationContent);
  }, [currentLocationContent]);

  const render = useMemo(() => {
    if (!userInfo.hasOwnProperty("quarantineLocation")) {
      return null;
    }

    if (fetchingQuarantineLocation) {
      return <Loading />;
    }

    const { quarantineOutOfBounds, quarantineLocation, quarantineMeterDifference } = userInfo;

    return (
      <div className={styles.quarantineStatus}>
        {quarantineLocation && (
          <div
            className={classnames(styles.header, {
              [`${styles.inZone}`]: !quarantineOutOfBounds,
              [`${styles.outZone}`]: quarantineOutOfBounds,
            })}
          >
            <Icon name={true ? "check-fill" : "block"} />
            <div className={styles.statusMessage}>
              {quarantineOutOfBounds ? "OUT OF BOUNDS" : "IN QUARANTINE ZONE"}
            </div>
          </div>
        )}
        <div className={styles.currentLocationContent}>
          <div className={styles.currentLocation}>
            <div className={styles.title}>Current Location</div>
            <div className={styles.address}>
              {quarantineCurrentLocation || location || localStrings.unableToLocate}
            </div>
          </div>
          {quarantineLocation && (
            <Icon
              className={classnames(styles.chevron, {
                [`${styles.expand}`]: currentLocationContent,
              })}
              name="right-chevron-medium"
              onClick={toggleCurrentLocationContent}
            />
          )}
        </div>
        {quarantineLocation && currentLocationContent && (
          <div className={styles.expandedContent}>
            <div className={styles.info}>
              <div className={styles.title}>QUARANTINE ADDRESS</div>
              <div className={classnames(styles.description)}>{quarantineLocation}</div>
            </div>
            <div>
              <div className={styles.title}>DISTANCE FROM CENTER</div>
              <div className={styles.description}>{quarantineMeterDifference} m away</div>
            </div>
          </div>
        )}
      </div>
    );
  }, [
    userInfo,
    currentLocationContent,
    location,
    quarantineCurrentLocation,
    fetchingQuarantineLocation,
    toggleCurrentLocationContent,
  ]);

  return render;
};

export default QuarantineStatus;
