import { v4 as uuidv4 } from "uuid";
import { Symptoms } from "enums";
import localStrings from "localization";

const symptomsButtons = [
  {
    text: localStrings.cough,
    icon: "dry-cough",
    value: Symptoms.COUGH,
    key: uuidv4(),
  },
  {
    text: localStrings.tiredness,
    icon: "tiredness",
    value: Symptoms.TIREDNESS,
    key: uuidv4(),
  },
  {
    text: localStrings.bodyAche,
    icon: "bodyache",
    value: Symptoms.BODY_ACHE,
    key: uuidv4(),
  },
  {
    text: localStrings.soreThroat,
    icon: "sore-throat",
    value: Symptoms.SORE_THROAT,
    key: uuidv4(),
  },
  {
    text: localStrings.diarrhea,
    icon: "diarrhea",
    value: Symptoms.DIARRHEA,
    key: uuidv4(),
  },
  {
    text: localStrings.conjunctivitis,
    icon: "conjunctivitis",
    value: Symptoms.CONJUNCTIVITIS,
    key: uuidv4(),
  },
  {
    text: localStrings.headache,
    icon: "headache",
    value: Symptoms.HEADACHE,
    key: uuidv4(),
  },
  {
    text: localStrings.lossOfSmellOrTaste,
    icon: "loss-of-smell-or-taste",
    value: Symptoms.LOSS_OF_TASTE_SMELL,
    key: uuidv4(),
  },
  {
    text: localStrings.rashes,
    icon: "rashes",
    value: Symptoms.RASHES,
    key: uuidv4(),
  },
  {
    text: localStrings.chestPains,
    icon: "chest-pain",
    value: Symptoms.CHEST_PAINS,
    key: uuidv4(),
  },
  {
    text: localStrings.difficultyBreathing,
    icon: "breathing",
    value: Symptoms.DIFFICULTY_BREATHING,
    key: uuidv4(),
  },
  {
    text: localStrings.fingerToesDiscoloration,
    icon: "toes",
    value: Symptoms.FINGER_TOES_DISCOLORATION,
    key: uuidv4(),
  },
  {
    text: localStrings.lossOfSpeechOrMovement,
    icon: "loss-of-speech-movement",
    value: Symptoms.LOSS_OF_SPEECH_MOVEMENT,
    key: uuidv4(),
  },
];

export default symptomsButtons;
