const PrivacyKey = {
  ABOUT: "isAboutYouAllowed",
  EMPLOYEE_DETAILS: "isEmploymentDetailAllowed",
  EMERGENCY_DETAILS: "isEmergencyContactAllowed",
  POUCHBAND: "isWristbandAllowed",
  SYMPTOMS: "isSymptomAllowed",
  AREA_LOCATION: "isAreaLocationAllowed",
  BODY_TEMPERATURE: "isBodyTemperatureAllowed",
  EMAIL_MOBILE_NUMBER: "isEmailAndMobileNumberAllowed",
  LOCATION: "isLocationAllowed",
  NAME_AND_PHOTO: "isNameAndPhotoAllowed",
};

export default Object.freeze(PrivacyKey);
