import React, { useState } from "react";
import { SettingsLayout } from "layouts";
import localString from "localization";
import { Select } from "atomic/atoms";
import { Panel } from "atomic/molecules";
import { Mixpanel, Temperature } from "enums";
import { getTemperature, setLocalItem } from "utils/index";
import styles from "./preference.module.scss";
import mixpanel from "mixpanel-browser";

const PreferenceContainer = () => {
  const temperature = getTemperature();
  const [value, setValue] = useState(temperature);

  return (
    <SettingsLayout title={localString.accountSettings}>
      <div className={styles.container}>
        <Panel className={styles.panel}>
          <h1 className={styles.panelTitle}>Preferences</h1>
          <Select
            className={styles.select}
            label="Temperature"
            data={[
              {
                text: localString.celsius,
                value: Temperature.Celsius,
              },
              {
                text: localString.fahrenheit,
                value: Temperature.Fahrenheit,
              },
            ]}
            onChange={(v) => {
              setLocalItem("temperatureType", v);
              setValue(v);
              mixpanel.track(Mixpanel.CHANGE_TEMPERATURE_UNIT_PREFERENCE);
            }}
            value={value}
          />
        </Panel>
      </div>
    </SettingsLayout>
  );
};

export default PreferenceContainer;
