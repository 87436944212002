import { ApiRoutes, Routes } from "enums";
import { Post } from "./api";

export const contactTracing = async (params) => {
  const res = await Post(`${ApiRoutes.CONTACT_TRACING}/${Routes.SEARCH}`, params);
  return res;
};

export const contactTracingIndividual = async ({ contactId, ...params }) => {
  const res = await Post(`${ApiRoutes.CONTACT_TRACING}/${contactId}`, params);
  return res;
};
