import React from "react";
import localStrings from "localization";
import styles from "./temperature-info-modal.module.scss";
import { Modal } from "atomic/atoms";
import classnames from "classnames";
import { convertTemperature } from "services";

const TemperatureInfoModal = ({ visible, onClose }) => {
  return (
    <Modal
      title={localStrings.temperatureStatus}
      visible={visible}
      onCancel={onClose}
      className={styles.modal}
    >
      <div className={styles.row}>
        <div className={classnames(styles.circle, styles.low)}></div>
        <div className={styles.tempNameContainer}>
          <div className={classnames(styles.info, styles.name)}>{localStrings.lowTemperature}</div>
          <div className={styles.info}>{convertTemperature(35)}° and below</div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={classnames(styles.circle, styles.normal)}></div>
        <div className={styles.tempNameContainer}>
          <div className={classnames(styles.info, styles.name)}>{localStrings.normal}</div>
          <div className={styles.info}>
            {convertTemperature(35.1)}° - {convertTemperature(37.5)}°
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={classnames(styles.circle, styles.higherThan)}></div>
        <div className={styles.tempNameContainer}>
          <div className={classnames(styles.info, styles.name)}>
            {localStrings.higherThanNormal}
          </div>
          <div className={styles.info}>
            {convertTemperature(37.6)}° - {convertTemperature(38.1)}°
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={classnames(styles.circle, styles.high)}></div>
        <div className={styles.tempNameContainer}>
          <div className={classnames(styles.info, styles.name)}>{localStrings.highTemperature}</div>
          <div className={styles.info}>
            {convertTemperature(38.2)}° - {convertTemperature(39.0)}°
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={classnames(styles.circle, styles.veryHigh)}></div>
        <div className={styles.tempNameContainer}>
          <div className={classnames(styles.info, styles.name)}>{localStrings.veryHigh}</div>
          <div className={styles.info}>
            {convertTemperature(39.1)}° - {convertTemperature(41.0)}°
          </div>
        </div>
      </div>
      <div className={styles.row}>
        <div className={classnames(styles.circle, styles.extremelyHigh)}></div>
        <div className={styles.tempNameContainer}>
          <div className={classnames(styles.info, styles.name)}>{localStrings.extremelyHigh}</div>
          <div className={styles.info}>{convertTemperature(41.1)}° and above</div>
        </div>
      </div>
    </Modal>
  );
};

export default TemperatureInfoModal;
