import React from "react";
import { Icon } from "atomic/atoms";
import styles from "./map-controls.module.scss";
import classnames from "classnames";

const Center = ({ onClick }) => {
  return (
    <Icon
      onClick={onClick}
      className={classnames(styles.center, styles.control)}
      name="map-center"
    />
  );
};

const ZoomIn = ({ onClick }) => {
  return (
    <Icon
      onClick={onClick}
      className={classnames(styles.zoomControl, styles.control)}
      name="plus"
    />
  );
};

const ZoomOut = ({ onClick }) => {
  return (
    <Icon
      onClick={onClick}
      className={classnames(styles.zoomControl, styles.control)}
      name="minus"
    />
  );
};

const MapControls = ({ map, initCenter, defaultCenter }) => {
  const handleCenterMap = () => {
    map.setCenter(initCenter || defaultCenter);
  };

  const handleZoomIn = () => {
    map.setZoom(map.getZoom() + 1);
  };

  const handleZoomOut = () => {
    map.setZoom(map.getZoom() - 1);
  };

  return (
    <div className={styles.container}>
      <Center onClick={handleCenterMap} />
      <ZoomIn onClick={handleZoomIn} />
      <ZoomOut onClick={handleZoomOut} />
    </div>
  );
};

export default MapControls;
