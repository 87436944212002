import React, { useContext } from "react";
// import { useMount } from "hooks";
import { ProfileDashboard } from "components/profile";
import { getOwnDevice, unpairOwnDevice, pairOwnDevice } from "api/user-device";
import styles from "./profile.module.scss";
import localStrings from "localization";
import { UserContext } from "contexts";
import { useRouter } from "hooks";
import PouchShopImage from "images/wristband-black.png";
import path from "path/path";
import { Image } from "atomic/atoms";
import { Button } from "atomic/molecules";

const ProfileContainer = () => {
  const { user } = useContext(UserContext);
  const { history } = useRouter();

  const { userId } = user;

  return (
    <div className={styles.container}>
      <ProfileDashboard
        user={user}
        ownAccount={true}
        userInfo={{ ...user, userId }}
        // loading={userLoading}
        // error={userError}
        links={{
          temperature: path.USER_PROFILE,
          symptoms: path.USER_PROFILE_SYMPTOMS,
          contactHistory: path.USER_PROFILE_CONTACT_HISTORY,
        }}
        profileInfo={{
          actionButton: (
            <Button
              secondary
              onClick={() => {
                history.push(path.SETTINGS_PROFILE);
              }}
            >
              {localStrings.editProfile}
            </Button>
          ),
          marketingUi: (
            <div className={styles.pouchShop}>
              <h3>{localStrings.pouchBandForEveryone}</h3>
              <div className={styles.headerPouchShop}>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: localStrings.shopPouchBand,
                    }}
                  />
                  <Button
                    className={styles.shopNoButton}
                    onClick={() => {
                      window.open(path.BUSINESS_LAUNCH, "_blank");
                    }}
                  >
                    {localStrings.shopNow}
                  </Button>
                </div>

                <div className={styles.closePouchShop}>
                  <Image src={PouchShopImage} className={styles.image} />
                  {/* <Icon
                    name="close"
                    className={styles.closePouchShopIcon}
                    onClick={() => {
                      setPouchShop(false);
                    }}
                  /> */}
                </div>
              </div>
            </div>
          ),
        }}
        mapLocation={{
          strings: {
            unableToLocate: localStrings.unableToLocate,
          },
        }}
        diary={{
          strings: {
            wearTheBand: localStrings.askYouToWearPouchBand,
          },
        }}
        symptomsOverview={{
          strings: {
            symptomsDescription: localStrings.recordAndMonitorYourSymptoms,
          },
          actions: {
            editSymptoms: (symptomId) => {
              history.push(path.EDIT_SYMPTOMS(symptomId));
            },
            createSymptoms: () => {
              history.push(path.CREATE_SYMPTOMS);
            },
          },
        }}
        device={{
          api: getOwnDevice,
          unpairApi: unpairOwnDevice,
          pairApi: pairOwnDevice,
          strings: {
            addBand: localStrings.addYourPouchBand,
          },
        }}
      />
    </div>
  );
};

export default ProfileContainer;
