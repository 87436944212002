import { Toast } from "atomic/molecules";
import { useApi } from "hooks";
import React, { useCallback } from "react";
// import localStrings from "localization";
import DeleteAreaModal from "components/area/delete-area-modal/delete-area-modal";
import { deleteArea } from "api/area";
import localTranslation from "localization/localization";

const DeleteAreaModalContainer = ({ visible, close, area = {}, areaId, fetchAreas }) => {
  const { request: deleteAreaRequest, loading } = useApi({ api: deleteArea });
  const deleteAreaCb = useCallback(async () => {
    const { areas, areaName, areaGroupName } = area;
    await deleteAreaRequest({ areaId: areaId || areas[0].areaId });
    Toast({
      content: localTranslation("nameRemoved", [areaName || areaGroupName || "-"]),
      error: true,
    }).open();
    close();
    fetchAreas();
  }, [close, deleteAreaRequest, area, fetchAreas, areaId]);

  return (
    <DeleteAreaModal submit={deleteAreaCb} loading={loading} visible={visible} close={close} />
  );
};

export default DeleteAreaModalContainer;
