import React from "react";
import styles from "./temp.module.scss";
import classnames from "classnames";
// import { Loading } from "atomic/atoms";
// import { formatTemperature, isNormalTemp } from "utils";
import { formatTemperature, getTemperatureStatus } from "services";

const Temp = ({
  temp = null,
  className = {
    container: "",
    normal: "",
    high: "",
    low: "",
  },
  gradient,
}) => {
  if (!temp) {
    return "-";
  }
  const temperatureStatus = getTemperatureStatus(temp);

  return (
    <div
      className={classnames("temp", styles.tempContainer, className.container, {
        [`${styles.danger} ${styles.low} ${className.low}`]: temperatureStatus === "LOW" && temp,
        [`${styles.normal} ${className.normal}`]: temperatureStatus === "NORMAL",
        [`${styles.danger} ${styles.higherThanNormal} ${className.high}`]:
          temperatureStatus === "HIGHER THAN NORMAL" && temp,
        [`${styles.danger} ${styles.high} ${className.high}`]: temperatureStatus === "HIGH" && temp,
        [`${styles.danger} ${styles.veryHigh} ${styles.high} ${className.high}`]:
          temperatureStatus === "VERY HIGH" && temp,
        [`${styles.danger} ${styles.extremelyHigh} ${styles.high} ${className.high}`]:
          temperatureStatus === "EXTREMELY HIGH" && temp,
        [`${styles.gradient}`]: gradient,
      })}
    >
      {formatTemperature(temp || "")}
    </div>
  );
};

export default Temp;
