import React, { useCallback, useEffect, useState } from "react";
import { getLocation } from "utils/map";
import { useRouter } from "hooks";
import { AccountStatus, Mixpanel } from "enums";
import { MemberTable } from "components/member";
import path from "path/path";
import mixpanel from "mixpanel-browser";

const MemberTableContainer = ({
  loading,
  data,
  total,
  isFilterDirty,
  filters,
  changePage,
  changePageSize,
  tempType,
  usersRequest,
}) => {
  const [activeMenu, setActiveMenu] = useState(null);
  const [inviteMemberModalVisible, setInviteMemberModalVisible] = useState(false);
  const [locations, setLocations] = useState([]);

  const { history } = useRouter();

  const redirectToProfile = useCallback(
    (index) => {
      if (data[index].accountStatus !== AccountStatus.PENDING_MEMBER) {
        history.push(path.MEMBER_PROFILE(data[index].userId), { fromList: true });
      }
    },
    [history, data]
  );

  useEffect(() => {
    const getLocations = async () => {
      for (const d of data) {
        const { areaName, gatewayUid, location, latitude, longitude } = d;

        let locationName = null;

        if (areaName) locationName = areaName;
        else if (gatewayUid) locationName = gatewayUid;
        else if (location) locationName = location;
        else {
          try {
            locationName = await getLocation({ lat: latitude, lng: longitude });
          } catch {
            locationName = "-";
          }
          // locationName = null;
        }
        setLocations((prev) => {
          return { ...prev, [d.userId]: locationName };
        });
      }
    };

    getLocations();
  }, [data]);

  const toggleInviteMemberModal = useCallback((show) => {
    if (!show) {
      mixpanel.track(Mixpanel.CANCEL_INVITE_MEMBER);
    }
    setInviteMemberModalVisible(show);
  }, []);

  return (
    <MemberTable
      data={data}
      loading={loading}
      total={total}
      isFilterDirty={isFilterDirty}
      filters={filters}
      changePage={changePage}
      changePageSize={changePageSize}
      locations={locations}
      tempType={tempType}
      onRowClick={redirectToProfile}
      usersRequest={usersRequest}
      activeMenu={activeMenu}
      setActiveMenu={setActiveMenu}
      inviteMemberModalVisible={inviteMemberModalVisible}
      toggleInviteMemberModal={toggleInviteMemberModal}
    />
  );
};

export default MemberTableContainer;
