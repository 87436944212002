import localStrings from "localization";

export const inviteManagerFormState = {
  email: {
    required: true,
    email: {
      message: localStrings.pleaseEnterAnEmailInFormat,
    },
    maxLength: 75,
  },
  role: {
    required: true,
    value: "MANAGER",
  },
};

export default inviteManagerFormState;
