import React from "react";
import styles from "./onboarding-layout.module.scss";
import classnames from "classnames";
import PouchPass from "images/pouch-pass-logo.svg";
import localStrings from "localization";
import { Routes } from "enums";
import { useRouter } from "hooks";
import { Image } from "atomic/atoms";
import { IconLink } from "atomic/molecules";

const WelcomePage = ({ header, description, content }) => {
  return (
    <div className={styles.content}>
      <h1>{header}</h1>
      <p>{description}</p>
      {content}
    </div>
  );
};

const TaskNav = () => {
  const { location } = useRouter();
  let isActive = false;
  return (
    <div className={classnames(styles.taskNav, styles.content)}>
      {[
        {
          to: `/${Routes.ONBOARDING}`,
          text: localStrings.uploadAProfile,
        },
        {
          to: `/${Routes.ONBOARDING}/${Routes.ABOUT_YOU}`,
          text: localStrings.aboutYou,
        },
        {
          to: `/${Routes.ONBOARDING}/${Routes.EMPLOYMENT_DETAILS}`,
          text: localStrings.employmentDetails,
        },
        {
          to: `/${Routes.ONBOARDING}/${Routes.EMERGENCY_CONTACT}`,
          text: localStrings.emergencyContact,
        },
      ].map((link, i) => {
        if (location.pathname === link.to) {
          isActive = true;
        }
        if (isActive) {
          link.className = styles.next;
          link.icon = "circle";
        } else {
          link.className = styles.prev;
          link.icon = "check-fill";
        }

        return (
          <IconLink
            key={i}
            activeClassName={styles.active}
            className={classnames(link.className, styles.link)}
            text={link.text}
            to={link.to}
            exact
            icon={link.icon}
          />
        );
      })}
    </div>
  );
};

const OnboardingLayout = ({ children, className, backgroundImage, content }) => {
  return (
    <div
      className={classnames("onboarding-layout", styles.container, className)}
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className={styles.sidePanel}>
        <Image src={PouchPass} className={styles.logo} />
        {content ? <WelcomePage {...content} /> : <TaskNav />}
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default OnboardingLayout;
