import React from "react";
import { Icon, Loading } from "atomic/atoms";
import styles from "./pouchband-registry-list.module.scss";
import localStrings from "localization";

const PouchBandRegistryList = ({ data = [], searchKey, loading, removeDeviceUid }) => {
  if (loading) {
    return <Loading isSkeleton width={100} />;
  }
  if (!searchKey && !data.length) {
    return null;
  }

  if (searchKey && !data.length) {
    return (
      <div className={styles.emptyContainer}>
        <div>
          <h4>{localStrings.noResults}</h4>
          <p>{localStrings.tryAnotherSearch}</p>
        </div>
      </div>
    );
  }
  return (
    <div className={styles.container}>
      {data.map(({ deviceUid }) => {
        return (
          <div className={styles.item}>
            <div>{deviceUid}</div>
            <div className={styles.close}>
              <Icon
                name="close"
                onClick={() => {
                  removeDeviceUid(deviceUid);
                }}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PouchBandRegistryList;
