import React from "react";
import { Modal } from "atomic/atoms";
import { Button } from "atomic/molecules";
import localStrings from "localization";
import styles from "./leave-modal.module.scss";

const LeaveModal = ({ visible, onClose, onLeave, acceptText, message }) => {
  return (
    <Modal
      className={styles.modal}
      visible={visible}
      title={`${localStrings.leavePage}?`}
      onCancel={onClose}
    >
      <p className={styles.message}>{message || localStrings.leavePageDesc}</p>
      <div className={styles.actions}>
        <Button
          danger
          text={acceptText || localStrings.leave}
          className={styles.action}
          onClick={onLeave}
        />
        <Button
          tertiary
          text={localStrings.stayOnThisPage}
          className={styles.action}
          onClick={onClose}
        />
      </div>
    </Modal>
  );
};

export default LeaveModal;
