import React, { memo, useEffect } from "react";
import styles from "./date-picker.module.scss";
import { DatePicker as AntDatePicker } from "antd";
import classnames from "classnames";
import localStrings from "localization";
import * as moment from "moment";
const { RangePicker } = AntDatePicker;

const DatePicker = ({
  value,
  onChange = () => null,
  placeholder,
  disabled,
  format = "MM/DD/YYYY",
  name,
  suffixIcon,
  disabledDate,
  allowClear = true,
  range = false,
  className,
  minToday = false,
  error,
}) => {
  const handleOnChange = (moment) => {
    onChange(moment, name);
  };
  useEffect(() => {
    const e = document.getElementsByClassName("ant-calendar-range-picker-separator");
    if (e && e[0]) {
      e[0].innerHTML = localStrings.to;
    }
  }, []);
  function disabledDates(current) {
    if (minToday) {
      return current && current < moment().endOf("day").subtract(1, "day");
    }
    if (disabledDate) {
      return disabledDate(current);
    }
    return false;
  }
  return range ? (
    <RangePicker
      disabledDate={disabledDates}
      className={styles.rangeContainer}
      value={value}
      onChange={handleOnChange}
      format={format}
    />
  ) : (
    <AntDatePicker
      dropdownClassName="datePickerDropdown"
      className={classnames(styles.datePicker, className, "datePicker", {
        [`${styles.error}`]: error?.status,
      })}
      placeholder={placeholder}
      onChange={handleOnChange}
      disabledDate={disabledDates}
      value={value}
      disabled={disabled}
      format={format}
      suffixIcon={suffixIcon}
      allowClear={allowClear}
      range={range}
    />
  );
};

export default memo(DatePicker);
