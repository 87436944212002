import React, { useState, useContext, Fragment } from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";
import { Routes } from "enums";
import { useMount } from "hooks";
import { NotificationContext, UserContext } from "contexts";
import { notificationState } from "../../states/notification.state";
import { Translation } from "pages";
import styles from "./dashboard.module.scss";
import { AdminRoute, ManagerRoute, OverviewRoute } from "routes";
import {
  MemberContainer,
  SymptomContainer,
  AreaContainer,
  SettingContainer,
  OrganizationContainer,
  OnboardingContainer,
  HeaderContainer,
  OverviewContainer,
  ProfileContainer,
  LiveMapContainer,
  WatchlistAcceptNotifiModal,
} from "containers";
import { UnauthorizedError, NotFoundError } from "pages";
import { environment } from "../../environments/environment";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import FormContainer from "containers/form.container";
import { Footer } from "atomic/molecules";
//import MobileDashboardMessage from "./mobile-dashboard-message/mobile-dashboard-message";
import AdminContainer from "containers/admin/admin.container";

const DashboardContainer = () => {
  const [notification, setNotification] = useState(notificationState);

  const { user } = useContext(UserContext);

  let { path } = useRouteMatch();

  useMount(() => {
    const init = async () => {
      try {
        const db = firebase.database();
        await firebase.auth().signInAnonymously();
        const userNotifRef = db.ref(`msHealth/${environment.id}/users/${user.userId}`);

        if (userNotifRef) {
          userNotifRef.on("value", (snapshot) => {
            if (snapshot.val()) {
              setNotification(snapshot.val());
            } else {
              setNotification(notificationState);
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    };
    init();
  });

  return (
    <NotificationContext.Provider value={notification}>
      <Fragment>
        <HeaderContainer />
        <WatchlistAcceptNotifiModal />
        <div className={styles.container}>
          <Switch>
            <ManagerRoute path={`${path}${Routes.MEMBERS}`} component={MemberContainer} />
            <ManagerRoute path={`${path}${Routes.AREAS}`} component={AreaContainer} />
            <Route path={`${path}${Routes.SETTINGS}`} component={SettingContainer} />
            <Route path={`${path}${Routes.ORGANIZATION}`} component={OrganizationContainer} />
            <Route path={`${path}${Routes.ONBOARDING}`} component={OnboardingContainer} />
            <Route path={`${path}${Routes.PROFILE}`} component={ProfileContainer} />
            <Route path={`${path}${Routes.SYMPTOMS_LOG}`} component={SymptomContainer} />
            <OverviewRoute exact component={OverviewContainer} path="/" />
            <AdminRoute path={`${path}${Routes.ADMIN}`} component={AdminContainer} />
            <ManagerRoute exact component={LiveMapContainer} path="/live-map" />
            <Route exact component={Translation} path={`/translation`} />
            <Route exact component={FormContainer} path="/form" />
            <Route exact component={UnauthorizedError} path="/unauthorize" />
            <Route render={() => <NotFoundError />} />
          </Switch>
        </div>
        <Footer />
      </Fragment>
    </NotificationContext.Provider>
  );
};

export default DashboardContainer;
