import React from "react";
import NoSymptomImage from "images/no-symptoms.svg";
import styles from "./symptoms-table.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import { Image } from "atomic/atoms";
import { FailedToLoad } from "atomic/organisms";

const NoSymptomsLogged = ({ className, error }) => {
  if (error) {
    return <FailedToLoad />;
  }
  return (
    <div className={classnames(styles.noSymptomsLogged, className)}>
      <Image src={NoSymptomImage} className={styles.noSymptomImage} />
      <h2 className={styles.noSymptomsLoggedTitle}>{localStrings.noSymptomsLogged}</h2>
    </div>
  );
};

export default NoSymptomsLogged;
