import React, { useCallback, useContext, useState } from "react";
import { Route, useRouteMatch, Switch } from "react-router-dom";
import {
  MyOrganizationsListContainer,
  MyOrganizationsDetailContainer,
  InviteRequestContainer,
} from "containers";
import { Mixpanel, Routes } from "enums";
import { NotFoundError } from "pages";
import styles from "./my-organizations.module.scss";
import { useApi } from "hooks";
import { leaveOrganization } from "api/user-organization";
import { UserOrganizationsContext } from "contexts";
import { redirectTo, setLocalItem, removeLocalItem } from "utils";
import { organizationState } from "states";
import { LeaveOrganizationModal } from "atomic/organisms";
import mixpanel from "mixpanel-browser";

const MyOrganizationsContainer = () => {
  let { path } = useRouteMatch();

  const [leaveModal, setLeaveModal] = useState(false);
  const [toLeaveOrganization, setToLeaveOrganization] = useState(organizationState);

  const { request: leaveOrganizationRequest, loading } = useApi({ api: leaveOrganization });

  const { userOrganizations } = useContext(UserOrganizationsContext);

  const leaveModalCb = useCallback((toggle, organization) => {
    setLeaveModal(toggle);
    if (toggle) {
      setToLeaveOrganization(organization);
    }
  }, []);

  const leaveOrgCb = useCallback(async () => {
    try {
      await leaveOrganizationRequest({ organizationId: toLeaveOrganization.organizationId });

      mixpanel.track(Mixpanel.LEAVE_COMMUNITY);

      if (userOrganizations.length > 1) {
        const willBeCurrentOrganization = userOrganizations.find((uo) => {
          return uo.organizationId !== toLeaveOrganization.organizationId;
        });
        setLocalItem("organizationId", willBeCurrentOrganization.organizationId);
        window.location.replace("/settings/my-organization");
      } else {
        removeLocalItem("accessToken");
        redirectTo(`/${Routes.AUTH}`);
      }
    } catch (e) {
      console.log(e);
    }
  }, [userOrganizations, leaveOrganizationRequest, toLeaveOrganization]);

  return (
    <Switch>
      <Route exact component={InviteRequestContainer} path={`${path}/${Routes.INVITE_REQUEST}`} />
      <Route exact path={[path, `${path}/${Routes.ORGANIZATION_ID}`]}>
        <Route
          exact
          render={(props) => <MyOrganizationsListContainer {...props} leaveModal={leaveModalCb} />}
          path={path}
        />
        <Route
          exact
          render={(props) => (
            <MyOrganizationsDetailContainer {...props} leaveModal={leaveModalCb} />
          )}
          path={`${path}/${Routes.ORGANIZATION_ID}`}
        />
        <LeaveOrganizationModal
          visible={leaveModal}
          organizationName={toLeaveOrganization.organizationName}
          onCancel={() => leaveModalCb(false, null)}
          loading={loading}
          leaveOrg={leaveOrgCb}
        />
      </Route>
      <Route render={() => <NotFoundError className={styles.notFoundError} />} />
    </Switch>
  );
};

export default MyOrganizationsContainer;
