import { Button } from "atomic/molecules";
import React from "react";
import InputField from "../input-field/input-field";
import styles from "./search-field.module.scss";
import localStrings from "localization";
import classnames from "classnames";

const SearchField = ({
  searchKey,
  onChange,
  placeholder,
  maxLength,
  disabled,
  onSearch,
  onClear,
  button,
  buttonText = localStrings.search,
  loading,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <InputField
          disabled={disabled}
          className={classnames(styles.input, {
            [`${styles.inputButton}`]: button,
          })}
          placeholder={placeholder}
          trim
          value={searchKey}
          selectAllOnFocus
          onChange={onChange}
          maxLength={maxLength}
          onKeyUp={(value, key) => {
            if (key === "Enter") {
              onSearch(searchKey);
            }
          }}
          suffix={
            !button
              ? {
                  name: "search",
                  onClick: () => onSearch(searchKey),
                }
              : null
          }
        />
        {searchKey && !button && (
          <Button
            iconClassName={styles.clearInputIcon}
            icon="close"
            className={styles.clearInput}
            text={localStrings.clear}
            onClick={onClear}
          />
        )}
        {button && (
          <Button
            primary
            className={styles.submitButton}
            loading={loading}
            disabled={loading}
            onClick={() => onSearch(searchKey)}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export default SearchField;
