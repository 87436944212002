import { ApiRoutes, Routes } from "enums";
import { Delete, Get, Post } from "./api";

export const getUserOrg = async () => {
  const res = await Get(`${ApiRoutes.USER_ORG}`);
  return res.data;
};

export const getOrganizations = async ({ isManagerOnly } = { isManagerOnly: false }) => {
  const res = await Get(`${ApiRoutes.ORGANIZATIONS}${isManagerOnly ? "?isManager=true" : ""}`, {});
  return res.data;
};

export const getPastOrganizations = async () => {
  const res = await Get(`${ApiRoutes.ORGANIZATIONS}/${Routes.PAST_ORGANIZATION}`, {});
  return res.data;
};

export const leaveOrganization = async ({ organizationId }) => {
  const res = await Delete(`${ApiRoutes.LEAVE_ORGANIZATION(organizationId)}`, {});
  return res.data;
};

export const removeIsNewOrganization = async () => {
  const res = await Get(`${ApiRoutes.ORGANIZATIONS}/${Routes.NOTIFICATION}`, {});
  return res;
};

export const removeManager = async (params = {}) => {
  const res = await Post(`${ApiRoutes.USER}/${Routes.ROLE}/${Routes.REMOVE}`, params);
  return res.data;
};

export const inviteUser = async (params) => {
  const res = await Post(`${ApiRoutes.USER}/${Routes.INVITE}`, params);
  return res.data;
};

export const revokeInviteManager = async (params = {}) => {
  const res = await Post(`${ApiRoutes.USER}/${Routes.REVOKE}/${Routes.INVITE}`, params);
  return res.data;
};

export const searchOrganizationMembers = async (params) => {
  const res = await Post(`${ApiRoutes.USER}/${Routes.ROLE}/${Routes.SEARCH}`, params);
  return res.data;
};

export const resendInvite = async ({ userId, organizationId }) => {
  const res = await Post(
    `${ApiRoutes.USER}/${userId}/${Routes.ORGANIZATION}/${organizationId}/${Routes.RESEND}/${Routes.INVITE}`,
    {}
  );
  return res.data;
};

export const validateInvite = async (params) => {
  const res = await Post(
    `${ApiRoutes.USER}/${Routes.INVITE}/${Routes.VALIDATE}/${Routes.EMAIL}`,
    params
  );
  return res.data;
};

export const validateActivateToken = async (params) => {
  const res = await Get(`/${ApiRoutes.USER_V2}/${Routes.INVITE}/validate-token`, params);
  return res.data;
};

export const activateUser = async (params) => {
  const res = await Post(`${ApiRoutes.USER}/${Routes.ACTIVATE}`, params);
  return res.data;
};

export const bulkValidateInvite = async (params) => {
  const res = await Post(
    `${ApiRoutes.USER}/${Routes.BULK}/${Routes.INVITE}/${Routes.VALIDATE}/${Routes.EMAIL}`,
    params
  );
  return res;
};

export const bulkInvite = async (params) => {
  const res = await Post(`${ApiRoutes.USER}/${Routes.BULK}/${Routes.INVITE}`, params);
  return res;
};

export const getInviteRequest = async (userId) => {
  const res = await Get(`${ApiRoutes.USER}/${Routes.INVITE_REQUEST}`, {
    userId,
  });
  return res;
};

export const acceptInviteRequest = async (params) => {
  const res = await Post(`${ApiRoutes.USER}/${Routes.ACTIVATE}/${Routes.INVITE}`, params);
  return res;
};

export const declineInviteRequest = async (params) => {
  const res = await Post(`${ApiRoutes.USER}/${Routes.REVOKE}/${Routes.INVITE}`, params);
  return res;
};

export const clearInviteRequestNotif = async () => {
  const res = await Get(
    `${Routes.HEALTH}/${Routes.USER}/${Routes.INVITE_REQUEST}/${Routes.NOTIFICATION}`
  );
  return res;
};
