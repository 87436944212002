const locale = {
  about: "～について",
  aboutYou: "あなたについて",
  aboutYouDesc: "",
  acceptInvite: "",
  accessory: "アクセサリー",
  accessoryAlreadyPaired: "",
  accessoryDetails: "",
  accessoryId: "",
  accessoryIdAlreadyPaired: "",
  accessoryIdDoesNotBelongToOrganization: "",
  accessoryIdIsntAvailable: "",
  accessoryOrMacAddress: "",
  accessoryRemoved: "",
  accessoryStatus: "",
  accessoryType: "",
  account: "アカウント",
  accountInformation: "",
  accountSettings: "",
  active: "",
  activeAndSyncing: "",
  activeMembers: "",
  activeOraganization: "",
  add: "",
  addAMember: "",
  addANewMember: "",
  addANewUser: "",
  addAccessory: "",
  addAndManagerPouchBands: "",
  addMember: "",
  addMemberAndProfile: "",
  addMembers: "",
  addNew: "",
  addNewMembers: "",
  addPouchband: "",
  addUsers: "",
  addYourContactDetails: "",
  addYourPouchBand: "",
  additionalInfo: "",
  address: "番地、通り名、市区町村、都道府県",
  all: "",
  allSet: "",
  allTheInfoCollectedProfile: "",
  allowed: "",
  almostDoneEnterYourNewPassword: "",
  alreadyHaveAnAccount: "",
  alreadyInvitedAssignAsManager: "",
  alreadyInvitedPendingError: "",
  and: "",
  appStore: "",
  applyFilters: "",
  areYouSure: "",
  area: "",
  areaDescription: "",
  areaDuplicateName: "",
  areaLocation: "",
  areaLocationDesc: "",
  areaName: "",
  areas: "",
  asThePersonDescribed: "",
  askDeleteArea: "",
  askDeleteMember: "",
  askDeleteUser: "",
  askRemoveAccessory: "",
  askRemoveManager: "",
  askRemoveMember: "",
  askSaveChanges: "",
  askTheUserToWearPouchBand: "",
  askYouToWearPouchBand: "",
  assignAPouchBandToEachUser: "",
  assignAPouchBandToStartMonitorTheirTemp: "",
  assignAsManager: "",
  authorization: "",
  back: "",
  backTo: "",
  backToHome: "",
  backToLogin: "",
  basicInfo: "",
  birthdate: "生年月日",
  birthday: "誕生日",
  bloodType: "",
  bodyAche: "体の痛み",
  bodyTemperature: "",
  bodyTemperatureDays: "",
  browsingYourFiles: "",
  cancel: "",
  celciusSymbol: "\\u00B0C",
  celsius: "",
  change: "",
  changeAccessory: "",
  changePhoto: "",
  changeYourEmail: "",
  changesSaved: "変更内容を保存しました。",
  checkCredentials: "",
  checkEmploymentDetails: "",
  checkWithYourAdminForDetails: "",
  checkingInternet: "",
  chestPains: "腰痛",
  chooseBloodType: "",
  chooseGender: "",
  chooseTypeTest: "",
  chooseWhichInformationToShare: "",
  clear: "",
  clearAllFilters: "",
  cm: "cm",
  company: "",
  companyPlaceholder: "",
  completeProfile: "",
  confirmDeleteMember: "",
  confirmNewPassword: "",
  confirmPassword: "",
  confirmRemoveManager: "",
  confirmRemoveMember: "",
  confirmRevokeManagerInvite: "",
  conjunctivitis: "結膜炎",
  connectAPouchBandDesc: "",
  contactAndPersonalInfo: "",
  contactHistory: "",
  contactHistoryDesc: "",
  contactInformation: "連絡先",
  contactNumber: "",
  contactPersonsName: "",
  contactPouchPass: "",
  contactRelationship: "",
  contactUs: "",
  cough: "咳",
  countryCurrentlyResidingIn: "",
  createAccount: "アカウントを作成する",
  createAndManageUser: "",
  createArea: "",
  createMember: "",
  createPPaccount: "新しいPouchパスアカウントを作りますか？",
  createUser: "",
  createWorryFree: "",
  createYourFirstArea: "",
  createYourFirstAreaDesc: "",
  currentPassword: "現在のパスワード",
  currentResidentialAddress: "",
  date: "日付",
  dateOfAvailabilityOfTestResults: "",
  dateOfContact: "",
  dateOfMedicalTest: "",
  decline: "拒否する",
  declineInvitation: "",
  declineInvitationDesc: "",
  delete: "削除する",
  deleteArea: "",
  deleteLog: "",
  deleteMember: "",
  deleteSymptomsLog: "",
  deleteUser: "",
  deletedMemeber: "",
  description: "",
  details: "",
  deviceStatus: "",
  diarrhea: "下痢",
  diary: "日記",
  didntGetACode: "",
  didntReceiveAnything: "何も受け取りませんでしたか？",
  difficultyBreathing: "呼吸困難",
  discardChanges: "変更を削除する",
  discardChangesMessage: "",
  discardTitle: "",
  dismiss: "",
  doThisLater: "",
  doYouWantToCreateYourOwnOrg: "",
  dontHavePouchBandYet: "",
  duration: "",
  easilyIdentifyYourProfile: "",
  edit: "編集する",
  editArea: "",
  editLogSymptoms: "",
  editManager: "",
  editMember: "",
  editProfile: "プロフィールを編集する",
  editSettings: "",
  editUser: "",
  egMedicalClinic: "(例)「メディカルクリニック」",
  emailAddress: "Eメールアドレス",
  emailAddressAlreadyTaken: "",
  emailAddressAndMobileNumber: "Eメールアドレスと携帯電話番号",
  emailAddresses: "",
  emailAlreadyAssociatedWithThisOrganization: "",
  emailAlreadyExists: "",
  emergencyContact: "緊急連絡先",
  emergencyContactDesc: "",
  emergencyContactPerson: "緊急連絡先氏名",
  employed: "被雇用",
  employeeDetails: "雇用詳細",
  employmentDetails: "雇用詳細",
  employmentDetailsDesc: "",
  employmentStatus: "雇用形態",
  enterAnEmailAddress: "Eメールアドレスを入力する",
  enterNewPassword: "",
  enterOtpVerifyYourEmailAddress: "",
  enterPassword: "パスワードを入力する",
  everyoneIsDoingWell: "",
  everyoneIsDoingWellDesc: "",
  everyoneIsSafe: "",
  everyoneIsSafeDesc: "",
  export: "",
  extremelyHigh: "極めて高い",
  fAQsPage: "",
  fahrenheit: "",
  failedToValidateEmail: "",
  fever: "熱",
  feverCases: "",
  feverTrend: "",
  feverTrendOvertime: "",
  fillInEmailAddresses: "",
  findOutHowCreateOrg: "",
  findOutHowNow: "",
  findOutMore: "",
  fingerToesDiscoloration: "手指/足指の変色",
  finishSettingUpAccount: "",
  firstName: "名前",
  forFurtherQuestions: "",
  forgotPassword: "パスワードをお忘れですか",
  fullName: "姓名",
  gateway: "",
  gatewayId: "",
  gatewayIdOrMacAddress: "",
  gatewayMacAddress: "",
  gatewayName: "",
  gatewayNamePlaceholder: "",
  gender: "性別",
  geolocation: "",
  geolocationDesc: "",
  goodJobAllUserActive: "",
  goodJobAllUserActiveDesc: "",
  great: "",
  hasNotBeenInContact: "",
  hasTheUserTakenAnyMedicalTest: "",
  headache: "頭痛",
  height: "身長",
  heightInCm: "身長(cm)",
  hereWhatHappening: "",
  highRisk: "",
  highRiskDesc: "",
  highTemperature: "高温",
  higherThanNormal: "平熱より高い",
  hypothermia: "",
  iAgreeToPouchTerms: "",
  iAuthorizeOrg: "",
  ifAny: "",
  iformationIsImportantNarrative: "",
  imageMustBeSmallerThan: "",
  in: "",
  inactive: "",
  inactiveDesc: "",
  individualAtRiskDesc: "",
  individualsAtRisk: "",
  inputAccessoryId: "",
  invalidCurrentPassword: "",
  inviteExpiredDesc: "",
  inviteHasBeenSentTo: "",
  inviteManager: "",
  inviteManagerDescription: "",
  inviteMembers: "",
  inviteNewManager: "",
  invitePeopleToCreate: "",
  invitePouchPassDesc: "",
  invitePouchPassNote: "",
  inviteRequest: "",
  inviteRequestDesc: "",
  inviteRequestDescManager: "",
  inviteRequestDescMember: "",
  inviteSent: "",
  inviteSentDetails: "",
  inviteSentMemberDesc: "",
  inviteTeamMembersAndCreateArea: "",
  inviteTeamMembersAndCreateAreaDescription: "",
  inviteToPouchPass: "PouchPASSへ招待する",
  invitedMembersWouldHave: "",
  invitesSent: "",
  invitingAMember: "",
  joinedCommunities: "",
  joinedOn: "",
  keepThisOrg: "",
  kg: "kg",
  lastDateOfContact: "",
  lastName: "苗字",
  lastSynced: "",
  lastUpdated: "",
  latestTemp: "",
  learnMore: "",
  leave: "",
  leaveOTPMessage: "",
  leaveOrganization: "",
  leavePage: "ページから離れる",
  leavePageDesc: "",
  lessDetails: "",
  liveMap: "",
  location: "場所",
  logIn: "ログインする",
  logOtherSymptoms: "",
  logSymptoms: "",
  logTheirSymptomsNow: "",
  login: "ログイン",
  loginNow: "",
  loginTo: "",
  logout: "ログアウトする",
  lossOfSmellOrTaste: "味覚/嗅覚がない",
  lossOfSpeechOrMovement: "発話・動作の低下",
  low: "低い",
  lowRisk: "",
  lowRiskDesc: "",
  lowTemperature: "",
  manager: "",
  managerRequest: "",
  me: "",
  medicalTest: "",
  member: "",
  memberDetails: "",
  memberDetailsPendingMessage: "",
  memberFromUntil: "",
  memberLocationPrivate: "",
  memberProfileOnly: "",
  memberProfileOnlyDesc: "",
  memberRequest: "",
  memberSince: "",
  memberStatus: "",
  members: "",
  mobileNumber: "携帯番号",
  moderateRisk: "",
  moderateRiskDesc: "",
  monitorAnySymptoms: "",
  monitorAnySymptomsDescription: "",
  monitorYourBodyTemp: "",
  monitorYourBodyTempDesc: "",
  myOrganizationDescription: "",
  myOrganizations: "",
  name: "名前",
  nameAndProfilePhoto: "名前とプロフィール写真",
  nameOfClinicOrHospital: "",
  nameOfOtherTest: "",
  nameOfSpecificMedicalTest: "",
  nameRemoved: "",
  negative: "陰性の",
  networkErrorDesc: "",
  newPassword: "新しいパスワード",
  newUserAdded: "",
  next: "",
  noAccessory: "アクセサリー無し",
  noAccessoryYet: "",
  noContactDetected: "",
  noData: "",
  noDevice: "",
  noLongerAManager: "",
  noMemberYet: "",
  noMembersFound: "",
  noName: "",
  noNeedForApp: "",
  noRecordsYet: "まだ記録がありません。",
  noResults: "",
  noSymptomsLogged: "記録された症状がありません",
  noUserFound: "",
  noUserYet: "",
  normal: "通常",
  notAllowed: "",
  notAtRisk: "",
  notRequired: "",
  ohNoYouDontHaveAccess: "",
  okay: "OK",
  onceYouLeaveOrg: "",
  oopsIncorrectEmailOrPass: "",
  oopsSomethinngWentWrong: "",
  optional: "任意",
  or: "",
  orCheck: "",
  orderOne: "",
  orgYouManage: "",
  organizationSettings: "",
  otherLocation: "",
  ourAvailablePlans: "",
  overview: "",
  pageNotFound: "",
  pageNotFoundDesc: "",
  paired: "",
  pairingStatus: "",
  password: "パスワード",
  passwordReset: "",
  passwordsDoNotMatch: "パスワードが合っていません。",
  pastOrganizations: "",
  pastOrganizationsDesc: "",
  past13Days: "",
  pendingInvites: "",
  pendingInvitesDesc: "",
  pendingMemberConfirmation: "",
  permanentResidence: "",
  permissions: "許可",
  personalInformation: "個人情報",
  playStore: "",
  pleaseCheckForTheOneTimePasswordWeSentTo: "",
  pleaseCheckYourEmailAndClickMessage:
    "Eメールで受信したリンクをクリックしパスワードをリセットしてください。",
  pleaseContactOurSupport: "",
  pleaseContactPouchPass: "",
  pleaseEnterAnEmailInFormat: "",
  pleaseEnterAtLeastOneEmail: "",
  pleaseFillUpArea: "",
  pleaseFillUpSymptom: "",
  pleaseFillUpUser: "",
  pleaseInputCurrentPassword: "",
  pleaseRequestThePersonProfile: "",
  pleaseTryAgain: "",
  pleaseUseAtleastNthCharacters: "",
  poor: "",
  positive: "陽性の",
  pouchBand: "PouchBAND",
  pouchBandDescription: "",
  pouchBandForEveryone: "",
  pouchBandStep1: "",
  pouchBandStep2: "",
  pouchBands: "",
  pouchnationPhilippines: "",
  privacyPolicy: "プライバシーポリシー",
  privacySymptomsWarning: "",
  private: "",
  privateInformation: "",
  privateOnlyMe: "",
  profile: "プロフィール",
  profileAvatarSaved: "",
  profileOnly: "",
  profileSettings: "",
  rashes: "発疹",
  recordAndMonitorYourSymptoms: "",
  recovingWithANormalTemp: "",
  refreshPage: "",
  registrationStart: "",
  relationshipPlaceholder: "",
  rememberMe: "",
  rememberYourPassword: "",
  rememberYourPasswordQuestion: "パスワードをお忘れですか？",
  remove: "削除する",
  removeAccessory: "",
  removeAccessoryDesc: "",
  removeAsManager: "",
  removeFromOrganization: "",
  removeManagerSuccess: "",
  removeMember: "",
  removed: "",
  removedMember: "",
  replacePhoto: "",
  required: "",
  resendANewOtp: "",
  resendInvite: "招待を再送する",
  resendInviteSuccess: "",
  resendNow: "今再送する",
  resetLinkResent: "",
  resetLinkSent: "",
  resetPassword: "パスワードをリセットする",
  resetTokenExpired: "",
  retry: "リトライ",
  retypePassword: "",
  reviewTheInformation: "",
  revoke: "取り消す",
  revokeInvite: "招待を取り消す",
  revokeInviteSuccess: "",
  riskLevel: "",
  role: "",
  roleAndPermissions: "",
  sampleAreaDescription: "",
  sampleAreaName: "",
  save: "保存する",
  saveAndSendNotif: "",
  saveChanges: "",
  savePermissions: "アクセス許可を保存する",
  searchByUserOrLocation: "",
  searchMemberByNameOrAccessoryId: "",
  searchUserByNameOrAccessoryId: "",
  seconds: "",
  sections: "",
  seeMore: "",
  selectARole: "",
  selectBloodType: "血液型を選択する",
  selectCountry: "",
  selectEmploymentStatus: "雇用形態を選択する",
  selectFollowingSymptoms: "",
  selectGender: "性別を選択する",
  selectUserTestResult: "",
  selfEmployed: "自営業",
  sendInvite: "招待を送る",
  sendResetLink: "リセットのリンクを送る",
  settings: "設定",
  setupProfileLater: "",
  sharedInformation: "",
  shopNow: "",
  shopPouchBand: "",
  signIn: "",
  signingUpAggreement: "",
  someEmailsNotRecognized: "",
  somethingWentWrong: "",
  soreThroat: "喉の痛み",
  sorryYouAreNotAuthToAccessThisPage: "",
  startMonitorTeam: "",
  startTour: "",
  startYourPouchBand: "",
  startbyUploading: "",
  statistics: "",
  stayOnThisPage: "",
  stayOnTopOfYourHealthAndGetNotified: "",
  stayOnTopOfYourHealthAndGetNotifiedDesc: "",
  stepNth: "",
  successChangedPassword: "",
  symptomLoggedToDiary: "",
  symptomNotFound: "",
  symptoms: "症状",
  symptomsDescription: "",
  takeNoteOfTheMedicalTestResultHere: "",
  teamAndRoles: "",
  teamManagers: "",
  tellUsMoreAboutYourself: "",
  temp: "",
  tempCases: "",
  tempHasBeenIncreasingForThePastDays: "",
  temperature: "温度",
  temperatureCases: "",
  temperatureStatus: "体温の状態",
  temperatureTrend: "体温の傾向",
  termsAndConditions: "利用規約",
  testResultSummaryInfo: "",
  testResults: "検査結果",
  testedNegativeWithCovid: "",
  testedPositiveWithCovid: "",
  theOTPYouEnteredIsInvalid: "",
  theyllBeAdded: "",
  thisEmailIsAlreadyAMemberOfYourOrg: "",
  thisMember: "",
  thisUserDoesNotExistOn: "",
  time: "時間",
  tiredness: "疲労",
  to: "",
  toLearnAboutPouchPass: "",
  today: "今日",
  totalMembers: "",
  trackEncounter: "",
  trackEncounterDescription: "",
  try: "",
  trySearchingAgain: "",
  tryToSearchAgain: "",
  typeOfMedicalTest: "医療機関テストの種類",
  uhOhEmailNotRegistered: "",
  unableToLocate: "",
  unableToLocateMember: "",
  unableToLocateUser: "",
  unableToRemoveAccessory: "",
  unableToRetrieveData: "",
  unableToRetrieveDataDesc: "",
  unauthorized: "",
  unbaleToUploadMoreThanMb: "",
  unemployed: "無職",
  unfortunatelyThisInviteIsExpired: "",
  unknowUserContactInformation: "",
  unknowUserUserId: "",
  unverifiedMember: "",
  unverifiedMembersDesc: "",
  uploadAProfile: "",
  uploadCovidTestCerti: "",
  uploadFile: "",
  uploadPhoto: "",
  uploadPhotoDescription: "",
  uploadProfile: "",
  uploadTestCertificate: "",
  usePouchPassForYourFamilyOrBusiness: "",
  usePouchPassForYourFamilyOrBusinessDesc: "",
  userHasPendingInvite: "",
  userIsNowAManager: "",
  users: "",
  usingAMixOfUppercaseAndLowercase: "",
  validateEmailAddress: "",
  verifiedMember: "",
  verifiedMembersDesc: "",
  verifyEmail: "",
  verifyEmailAddress: "",
  veryHigh: "非常に高い",
  veryLowRisk: "",
  veryLowRiskDesc: "",
  view: "",
  viewAll: "",
  viewAllAreas: "",
  viewAndAddSymptoms: "",
  viewInvite: "",
  viewMyActiveOrg: "",
  viewMyOrgs: "",
  viewProfile: "プロフィールを表示する",
  viewTestResults: "",
  viewYourContactInformation: "",
  viewYourNameAndProfilePhoto: "",
  visitOur: "",
  wantToCreateOrg: "",
  weShouldBack: "",
  weak: "",
  weight: "体重",
  weightInKg: "体重（KG）",
  welcomeDescription: "",
  welcomeTo: "",
  welcomeToPouchPass: "",
  wellEmbarassing: "",
  wellEmbarassingDesc: "",
  whoCanView: "",
  withFever: "",
  workAddress: "勤務先住所",
  workContactNumber: "勤務先電話番号",
  worker: "契約社員またはパート・アルバイト",
  wouldBeReceivingInfo: "%@で勤務ている %@",
  yesDecline: "",
  yesLeave: "",
  youAreNotAuthorized: "",
  youAreNotInAnyOrg: "",
  youAreNotInAnyOrgDesc: "",
  youCanOnlyUploadJpgPngGifFile: "",
  youDontHaveOrg: "",
  youMay: "",
  youReInvitedTo: "",
  youReachMaximumOTPAttemps: "",
  yourEmailAddress: "",
  yourPasswordHasBeenChanged: "パスワードが変更されました。",
};
export default Object.freeze(locale);
