import React from "react";
import styles from "./time-picker-field.module.scss";
import classnames from "classnames";
import { TimePicker, Loading } from "atomic/atoms";

const TimePickerField = ({
  label,
  className,
  error = {},
  message,
  loading,
  ...timePickerProps
}) => {
  return (
    <div
      className={classnames(styles.field, className, {
        [`${styles.error}`]: error.status,
      })}
    >
      {label && <label className={styles.label}>{label}</label>}
      <div
        className={classnames(styles.timePickerContainer, {
          [`${styles.loading}`]: loading,
        })}
      >
        {loading ? <Loading /> : <TimePicker {...timePickerProps} />}
      </div>
      {error.message || message ? (
        <span className={styles.message}>{error.message || message}</span>
      ) : null}
    </div>
  );
};

export default TimePickerField;
