import React, { memo, useState, useEffect, useCallback } from "react";
import { Panel, SwitchField, InputField, Button, Field } from "atomic/molecules";
import { UploadAvatar, MapLocationCoordinatesField } from "atomic/organisms";
import { Icon, Input } from "atomic/atoms";
import localStrings from "localization";
import styles from "./community-form.module.scss";
import classnames from "classnames";
import { debounced, isNumber } from "utils";

const CommunityForm = ({
  modifyField,
  fields = {},
  modifyForm,
  isActiveManagerEditable,
  isResendInviteAvailable,
  resendInvite,
  resendingInvite,
  checkIfNameExists,
  validatingName,
}) => {
  const { communityAvatar, communityName, latitude, longitude, email, isActive } = fields;
  const [coordinates, setCoordinates] = useState({
    latitude: latitude.value,
    longitude: longitude.value,
  });

  useEffect(
    debounced(1000, async () => {
      if (latitude.value !== coordinates.latitude || longitude.value !== coordinates.longitude) {
        setCoordinates({
          latitude: latitude.value,
          longitude: longitude.value,
        });
      }
    }),
    [latitude.value, longitude.value]
  );

  useEffect(
    debounced(1000, async () => {
      if (
        latitude.value !== coordinates.latitude ||
        (longitude.value !== coordinates.longitude && coordinates.latitude && coordinates.longitude)
      ) {
        modifyForm({
          latitude: {
            value: coordinates.latitude.toString().substring(0, 15),
          },
          longitude: {
            value: coordinates.longitude.toString().substring(0, 15),
          },
        });
      }
    }),
    [coordinates]
  );

  const onChangeMap = useCallback((value) => {
    setCoordinates(value);
  }, []);

  return (
    <>
      <Panel title={localStrings.communityStatus} className={styles.panel}>
        <SwitchField
          title={localStrings.activeCommunity}
          description={localStrings.activateThisCommunity}
          {...isActive}
          onChange={() => {
            modifyField("isActive", {
              value: !isActive.value,
            });
          }}
        />
      </Panel>
      <Panel title={localStrings.communityInformation} className={styles.panel}>
        <UploadAvatar
          className={styles.avatar}
          avatar={communityAvatar.value.image}
          setAvatar={(image, file) => {
            modifyField("communityAvatar", {
              value: {
                image,
                file,
              },
            });
          }}
        />
        <InputField
          className={styles.inputField}
          {...communityName}
          label={localStrings.communityName}
          placeholder={localStrings.whatWouldYouLikeToNameThisComm}
          onChange={(value) => {
            modifyField("communityName", {
              value,
            });
          }}
          onBlur={checkIfNameExists}
          renderSuffix={validatingName ? <Icon loading={true} /> : <span />}
          showRequired
        />
        <Field label={localStrings.location}>
          <div className={styles.duo}>
            <div>
              <Input
                {...latitude}
                placeholder={localStrings.latitude}
                onChange={(value) => {
                  if (isNumber(value) || value.trim() === "-") {
                    modifyField("latitude", {
                      value,
                    });
                  }
                }}
                className={classnames(styles.coordinates, {
                  [`${styles.error}`]: latitude.error,
                })}
              />
            </div>
            <div>
              <Input
                {...longitude}
                placeholder={localStrings.longitude}
                onChange={(value) => {
                  if (isNumber(value) || value.trim() === "-") {
                    modifyField("longitude", {
                      value,
                    });
                  }
                }}
                className={classnames(styles.coordinates, {
                  [`${styles.error}`]: longitude.error,
                })}
              />
            </div>
          </div>
        </Field>
        <MapLocationCoordinatesField
          latitude={coordinates.latitude}
          longitude={coordinates.longitude}
          onChange={onChangeMap}
        />
      </Panel>
      <Panel
        title={localStrings.assignManager}
        className={styles.panel}
        description={<p>{localStrings.assignAManagerOfThisComm}</p>}
      >
        <InputField
          className={styles.inputField}
          {...email}
          label={localStrings.emailAddress}
          placeholder={localStrings.enterEmailAddress}
          onChange={(value) => {
            modifyField("email", {
              value,
            });
          }}
          disabled={!isActiveManagerEditable}
          showRequired
        />
        {isResendInviteAvailable && (
          <Button
            className={styles.resendButton}
            text={"Resend Invite"}
            loading={resendingInvite}
            onClick={resendInvite}
          />
        )}
      </Panel>
    </>
  );
};

export default memo(CommunityForm);
