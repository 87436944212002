import React, { useContext } from "react";
import localStrings from "localization";
import { Routes } from "enums";
import { NotificationContext, OrganizationContext, UserContext } from "contexts";
import { HeaderNavigation } from "atomic/organisms";

const ManagerHeader = ({ logout, loading }) => {
  const notifications = useContext(NotificationContext);
  const organization = useContext(OrganizationContext);
  const user = useContext(UserContext);

  const isAdmin = user.user?.userPermission?.permissionNames.length;

  const tabs = [
    { text: localStrings.overview, icon: "dashboard", path: "/", exact: true },
    { text: localStrings.areas, icon: "area", path: `/${Routes.AREAS}` },
    { text: localStrings.members, icon: "users", path: `/${Routes.MEMBERS}` },
    { text: localStrings.liveMap, icon: "location", path: `/${Routes.LIVE_MAP}` },
  ];

  const menuOptions = [
    {
      text: "My Dashboard",
      icon: "dashboard-file",
      path: `/${Routes.PROFILE}`,
    },
    {
      text: "Manage Communities",
      icon: "community-building",
      path: `/${Routes.ORGANIZATION}`,
      notif: notifications.notificationCount,
    },
    {
      text: `${localStrings.settings}`,
      icon: "settings",
      path: `/${Routes.SETTINGS}`,
      notif: notifications.unreadInviteRequestCount || notifications.hasOrganizationPrivacyUpdate,
    },
    ...(isAdmin
      ? [
          {
            text: "PouchPASS Admin",
            icon: "pouchnation",
            path: `/${Routes.ADMIN}`,
            divide: true,
            highlighted: true,
          },
        ]
      : []),
    { text: `${localStrings.logout}`, icon: "logout", onClick: logout, divide: true },
  ];

  return (
    <HeaderNavigation
      tabs={tabs}
      menuOptions={menuOptions}
      organizationName={organization?.organizationName}
      loading={loading}
    />
  );
};

export default ManagerHeader;
