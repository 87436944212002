import React from "react";
import { Block, Icon } from "atomic/atoms";
import styles from "./loading-block.module.scss";

const LoadingBlock = ({ visible, ...props }) => {
  return visible ? (
    <Block {...props}>
      <Icon type="loading" className={styles.icon} />
    </Block>
  ) : null;
};

export default LoadingBlock;
