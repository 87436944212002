import React from "react";
import { Modal } from "atomic/atoms";
import localStrings from "localization";
import styles from "./delete-area-modal.module.scss";
import { ActionButton } from "components/common";

const DeleteAreaModal = ({ visible, close, loading, submit }) => {
  return (
    <Modal
      className={styles.modal}
      visible={visible}
      title={`${localStrings.deleteArea}?`}
      onCancel={close}
    >
      <ActionButton
        loading={loading}
        onCancel={close}
        submitText={localStrings.delete}
        isSubmitDanger={true}
        onSubmit={submit}
      />
    </Modal>
  );
};

export default DeleteAreaModal;
