import { Pagination } from "atomic/atoms";
import React from "react";
import styles from "./paginated-table.module.scss";

const PaginatedTable = ({
  page = 0,
  pageSize = 20,
  changePage = () => null,
  changePageSize = () => null,
  children,
  total = 20,
  defaultPageSize,
}) => {
  return (
    <div>
      <Pagination
        className={styles.topPagination}
        total={total}
        pageSize={pageSize}
        onChange={(page) => {
          changePage(page);
        }}
        onShowSizeChange={(_page, pageSize) => {
          changePageSize(pageSize);
        }}
        currentPage={page}
        showSizeChanger={true}
        defaultPageSize={defaultPageSize}
      />
      <div className={styles.tableContainer}>{children}</div>
      <Pagination
        className={styles.bottomPagination}
        total={total}
        pageSize={pageSize}
        onChange={(page) => {
          changePage(page);
        }}
        onShowSizeChange={(_page, pageSize) => {
          changePageSize(pageSize);
        }}
        currentPage={page}
      />
    </div>
  );
};

export default PaginatedTable;
