import React from "react";
import localStrings from "localization";
import styles from "./symptoms-header.module.scss";
import classnames from "classnames";
import { Button } from "atomic/molecules";

const SymptomsHeader = ({ strings, goToLogSymptoms }) => {
  return (
    <div className={styles.tableHeader}>
      <div className={styles.titleContainer}>
        <h3 className={styles.title}>{localStrings.symptoms}</h3>
        <p>{strings.symptomsDescription}</p>
      </div>

      <Button
        className={classnames(styles.action, styles.tableButton)}
        icon="plus"
        right={false}
        onClick={goToLogSymptoms}
      >
        {localStrings.logSymptoms}
      </Button>
    </div>
  );
};

export default SymptomsHeader;
