import { Field } from "enums";
import Validation from "services/validation.service";

const initialFormState = (initialState = {}, { isActiveManagerEditable = true }) => {
  const {
    communityAvatar = {
      image: null,
      file: "",
    },
    isActive = true,
    communityName = "",
    latitude = "0.0",
    longitude = "0.0",
    email = "",
  } = initialState;
  console.log(initialState);
  return {
    communityName: {
      value: communityName,
      type: Field.INPUT,
      validations: [Validation.required()],
      required: true,
      maxLength: 75,
    },
    isActive: { value: isActive, type: Field.CHECKBOX },
    communityAvatar: {
      value: communityAvatar,
      type: Field.ANY,
    },
    latitude: {
      value: latitude,
      type: Field.INPUT,
      validations: [Validation.checkMapCoordinates()],
      maxLength: 15,
    },
    longitude: {
      value: longitude,
      type: Field.INPUT,
      validations: [Validation.checkMapCoordinates()],
      maxLength: 15,
    },
    email: {
      value: email,
      type: Field.INPUT,
      validations: [
        Validation.emailAddress(),
        !isActiveManagerEditable && !email
          ? () => {
              return {};
            }
          : Validation.required(),
      ],
      required: isActiveManagerEditable,
      maxLength: 75,
    },
  };
};

export default initialFormState;
