import Routes from "./routes";

const ApiRoutes = (() => {
  const AuthUser = `${Routes.AUTH}/${Routes.USER}`;
  const UserData = `${Routes.HEALTH}/v2/${Routes.USER_DATA}`;
  const UserDataOverView = `${UserData}/${Routes.OVERVIEW}`;
  return {
    LOGIN: `${AuthUser}/${Routes.LOGIN}`,
    LOGOUT: `${AuthUser}/${Routes.LOGOUT}`,
    USER: `${Routes.HEALTH}/${Routes.USER}`,
    USER_V2: `${Routes.HEALTH}/v2/${Routes.USER}`,
    USER_DATA: UserData,
    USER_ORGANIZATION: `${UserData}/${Routes.ORGANIZATION}`,
    USER_ORG: `${Routes.HEALTH}/${Routes.ORGANIZATION}/${Routes.USER}`,
    OVERVIEW_ORG: `${UserData}/${Routes.OVERVIEW}/${Routes.ORGANIZATION}`,
    SEARCH: `${UserData}/${Routes.SEARCH}`,
    SEARCH_EXPORT: `${UserData}/${Routes.SEARCH}/${Routes.EXPORT}`,
    OVERVIEW_FEVER_CASE: `${UserData}/${Routes.SEARCH}`,
    OVERVIEW_INACTIVE: `${UserDataOverView}/${Routes.INACTIVE}`,
    AREA: `${Routes.HEALTH}/${Routes.AREA}`,
    AREA_V2: `${Routes.HEALTH}/v2/${Routes.AREA}`,
    AREA_GROUP_LEVEL: `${Routes.HEALTH}/${Routes.AREA_GROUP_LEVEL}`,
    USER_DEVICE: `${Routes.HEALTH}/${Routes.USER_DEVICE}`,
    USER_DEVICE_V2: `${Routes.HEALTH}/v2/${Routes.USER_DEVICE}`,
    SYMPTOM: `${Routes.HEALTH}/v2/${Routes.SYMPTOM}`,
    SYMPTOM_EXPORT: `${Routes.HEALTH}/v2/${Routes.USER_DATA}/${Routes.SEARCH}/${Routes.SYMPTOM}/${Routes.EXPORT}`,
    UPLOAD: `${Routes.STORAGE}/${Routes.UPLOAD}`,
    CONTACT_TRACING: `${Routes.HEALTH}/v2/${Routes.CONTACT_TRACING}`,
    ORGANIZATION_V2: (organizationId) =>
      `v2/${Routes.HEALTH}/${Routes.ORGANIZATION}/${organizationId}`,
    ORGANIZATION: (organizationId) => `${Routes.HEALTH}/${Routes.ORGANIZATION}/${organizationId}`,
    ORGANIZATIONS: `${Routes.HEALTH}/${Routes.ORGANIZATION}/${Routes.USER}`,
    LEAVE_ORGANIZATION: (organizationId) =>
      `${Routes.HEALTH}/${Routes.ORGANIZATION}/${organizationId}/${Routes.LEAVE}`,
    QUESTION: `${Routes.HEALTH}/${Routes.ORGANIZATION}/${Routes.QUESTION}`,
    QUESTION_V2: (organizationId) =>
      `${Routes.HEALTH}/${Routes.ORGANIZATION}/${organizationId}/${Routes.QUESTION}`,
  };
})();

export default Object.freeze(ApiRoutes);
