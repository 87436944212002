import React from "react";
import styles from "./toast.module.scss";
import classnames from "classnames";
import { Icon } from "atomic/atoms";
import { message as antMessage } from "antd";

const Toast = ({ className, content, success, children, icon, error, duration = 3, maxCount }) => {
  const render = (
    <div
      className={classnames(styles.container, className, {
        [`${styles.error}`]: error,
        [`${styles.success}`]: success,
      })}
    >
      {icon && <Icon className={classnames(styles.icon, "icon")} name={icon} />}{" "}
      {content || children}
    </div>
  );
  return {
    open: () => {
      if (maxCount && maxCount > 0) {
        antMessage.config({ maxCount });
      }
      antMessage.info({ content: render, duration });
    },
  };
};

export default Toast;
