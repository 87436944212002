export const aboutYouFormState = {
  birthDate: {
    value: null,
  },
  gender: {
    value: null,
  },
  bloodType: {
    value: null,
  },
  height: {
    value: null,
    number: true,
    max: 500,
    decimal: 2,
  },
  weight: {
    value: null,
    number: true,
    max: 999,
    decimal: 2,
  },
  // currentCountry: {
  //   value: null,
  // },
  currentAddress: {
    value: null,
    maxLength: 255,
  },
};
