import React from "react";
import moment from "moment";
import styles from "./covid-result-modal.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import localTranslation from "localization/localization";
import { Common } from "enums";
import { Modal, Icon } from "atomic/atoms";

const Title = ({ dateTime }) => {
  const date = moment(dateTime).format("MMM DD");
  const time = moment(dateTime).format("hh:mm A");
  return (
    <div className={styles.dateTime}>
      <div className={styles.date}>{date}</div>
      <div className={styles.time}>{time}</div>
    </div>
  );
};

const CovidResultModal = ({ visible, onClose, result }) => {
  const testResult = result?.testResult?.toString().toUpperCase().trim();
  const isPositive = Common.POSITIVE === testResult;
  return (
    <Modal visible={visible} onCancel={onClose} title={<Title dateTime={result.dateTime} />}>
      <div className={classnames(styles.container, isPositive ? styles.positive : styles.negative)}>
        {result.testCertificateFile && (
          <div>
            <img
              className={styles.image}
              alt="COVID-19 Test Result"
              src={result.testCertificateFile}
            />
          </div>
        )}

        <div className={styles.tag}>
          <Icon type="check-circle" className={styles.icon} />
          <span>
            {isPositive
              ? localStrings.testedPositiveWithCovid
              : localStrings.testedNegativeWithCovid}
          </span>
        </div>

        {result.medicalTestType && (
          <div className={styles.summary}>
            {localTranslation("testResultSummaryInfo", [
              result.medicalTestType.toString().toUpperCase().replace(/_/g, "-"),
              moment(result.testTakenDate).format("MMM DD, YYYY"),
              result.testAddress,
            ])}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default CovidResultModal;
