import React from "react";
import FailedToLoadImage from "images/failed-to-load.svg";
import styles from "./failed-to-load.module.scss";
import localStrings from "localization";
import classnames from "classnames";

const FailedToLoad = ({ image = true }) => {
  return (
    <div
      className={classnames(styles.container, !image ? styles.noImage : null)}
    >
      <div>
        {image && (
          <div>
            <img src={FailedToLoadImage} alt="Failed to Load" />
          </div>
        )}
        <div className={styles.title}>{localStrings.unableToRetrieveData}</div>
        <div className={styles.description}>
          {localStrings.unableToRetrieveDataDesc}
        </div>
      </div>
    </div>
  );
};

export default FailedToLoad;
