import React, { useState, useMemo, useEffect, useContext } from "react";
import { Image, Table } from "atomic/atoms";
import { Panel, Button } from "atomic/molecules";
import { FailedToLoad } from "atomic/organisms";
import { CovidResult } from "components/users";
import styles from "./symptoms-overview.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import { useRouter } from "hooks";
import { Common } from "enums";
import { v4 as uuidv4 } from "uuid";
import { searchSymptoms } from "api/symptom";
import { getLocalItem } from "utils/index";
import { ViewUserContext, AppContext } from "contexts";
import NoSymptomImage from "images/no-symptoms.svg";
import * as moment from "moment";
import path from "path/path";

const tableColumns = [
  {
    title: localStrings.date,
    dataIndex: "date",
    key: uuidv4(),
    hasColumnAction: true,
    width: 122,
  },
  {
    title: localStrings.symptoms,
    dataIndex: "symptoms",
    key: uuidv4(),
    width: 200,
  },
];

const NoSymptomsLogged = ({ className, visible, error }) => {
  if (error && visible) {
    return <FailedToLoad />;
  }
  return visible ? (
    <div className={classnames(styles.noSymptomsLogged, className)}>
      <Image src={NoSymptomImage} className={styles.noSymptomImage} />
      <h2 className={styles.noSymptomsLoggedTitle}>{localStrings.noSymptomsLogged}</h2>
    </div>
  ) : null;
};

const SymptomsOverview = ({
  className,
  goToSymptoms,
  table,
  scroll,
  isMember,
  userId,
  ownAccount,
}) => {
  const { state, dispatch } = useContext(ViewUserContext);
  const { dispatch: appDispatch } = useContext(AppContext);
  const { data, loading, total, error } = state.symptomsTable;
  const { history } = useRouter();
  const [covidResult, setCovidResult] = useState({
    visible: false,
    result: {},
  });

  const columnActions = useMemo(() => {
    return {
      showMenuAction: (rowIndex) => {
        const d = data[rowIndex];
        if (ownAccount) {
          return true;
        }
        if (isMember) {
          return d?.userLoggedBy !== d?.userId;
        }
        return d?.userLoggedBy === d?.userId;
      },
      date: [
        {
          text: localStrings.edit,
          className: styles.nameActions,
          onClick: (index) => {
            const { symptomId } = data[index];
            if (isMember) {
              history.push(path.MEMBER_EDIT_SYMPTOMS(userId, symptomId));
            } else {
              history.push(path.EDIT_SYMPTOMS(symptomId), {
                userId,
              });
            }
          },
        },
        {
          text: localStrings.delete,
          className: classnames(styles.nameActions, "cDanger"),
          onClick: (index) => {
            const { symptomId } = data[index];
            dispatch({ type: "SHOW_DELETE_SYMPTOM_LOG", symptomId });
          },
        },
      ],
    };
  }, [history, data, userId, dispatch, isMember, ownAccount]);

  useEffect(() => {
    dispatch({ type: "FETCH_SYMPTOM_LOGS" });
    searchSymptoms(userId, {
      organizationId: isMember ? Number(getLocalItem("organizationId")) : null,
      showManagerCreated: true,
    })
      .then((res) => {
        const { data, metadata } = res;
        dispatch({
          type: "SUCCESS_FETCH_SYMPTOM_LOGS",
          data,
          total: metadata.total,
        });
      })
      .catch(() => {
        dispatch({
          type: "ERROR_FETCH_SYMPTOM_LOGS",
        });
      });
  }, [userId, appDispatch, dispatch, isMember]);

  const goToLogSymptoms = () => {
    if (isMember) {
      history.push(path.MEMBER_CREATE_SYMPTOMS(userId));
    } else {
      history.push(path.CREATE_SYMPTOMS, {
        userId,
      });
    }
  };

  const tableData = useMemo(() => {
    let d = [...data];
    if (!table) {
      d = [...data].splice(0, 5);
    }
    return d.map((d) => {
      const { dateTime, symptoms, additionalInfo, medicalTestType, testResult } = d;
      return {
        key: uuidv4(),
        date: dateTime ? moment(dateTime).format("MMM DD hh:mm A") : "-",
        symptoms: (
          <>
            <div className={classnames(styles.symptoms)}>
              {symptoms &&
                symptoms.map((symptom) => {
                  return (
                    <div key={uuidv4()} className={classnames(styles.symptom)}>
                      <div className={classnames(styles.pill)}>
                        {symptom.toString().toLowerCase().replace(/_/g, " ")}
                      </div>
                    </div>
                  );
                })}
            </div>
            {additionalInfo && <p className={styles.note}>{additionalInfo}</p>}
            {medicalTestType !== Common.OTHER && testResult && (
              <div
                className={classnames(styles.testResult)}
                onClick={() => {
                  setCovidResult({
                    visible: true,
                    result: d || {},
                  });
                }}
              >
                {localStrings.viewTestResults}
              </div>
            )}
          </>
        ),
      };
    });
  }, [data, table]);

  return (
    <Panel
      className={classnames(styles.container, className, {
        [`${styles.overviewPanel}`]: !table,
      })}
    >
      <CovidResult
        visible={covidResult.visible}
        result={covidResult.result}
        onClose={() => {
          setCovidResult((prev) => ({ ...prev, visible: false }));
        }}
      />
      <div
        className={classnames({
          [`${styles.tableHeader}`]: table,
          [`${styles.panelHeader}`]: !table,
        })}
      >
        <div className={styles.titleContainer}>
          <h3 className={styles.title}>{localStrings.symptoms}</h3>
          <p>
            {isMember && !ownAccount
              ? localStrings.symptomsDescription
              : localStrings.recordAndMonitorYourSymptoms}
          </p>
        </div>

        <NoSymptomsLogged error={error} visible={!table && !loading && !data.length} />

        <Button
          className={classnames(styles.action, table ? styles.tableButton : "")}
          prefixIcon={{ name: "plus" }}
          onClick={goToLogSymptoms}
        >
          {localStrings.logSymptoms}
        </Button>
      </div>
      {!error && (loading || !!data.length) && (
        <>
          <Table
            className={classnames(styles.table, {
              [`${styles.overviewTable}`]: !table,
            })}
            columns={tableColumns}
            loading={loading}
            data={tableData}
            columnActions={columnActions}
            scroll={scroll}
          />
          {!table && total > 5 && (
            <div className={styles.viewAll} onClick={goToSymptoms}>
              {localStrings.viewAll}
            </div>
          )}
        </>
      )}
      <NoSymptomsLogged
        error={error}
        visible={table && !loading && !data.length}
        className={classnames({ [`${styles.table}`]: table })}
      />
    </Panel>
  );
};

export default SymptomsOverview;
