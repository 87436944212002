import React, { memo } from "react";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import styles from "./link.module.scss";
import PropTypes from "prop-types";

const Link = ({
  activeClassName,
  className,
  to,
  href,
  children,
  text,
  target,
  onClick,
  exact,
  loading,
}) => {
  if (to) {
    return (
      <NavLink
        activeClassName={activeClassName}
        className={classnames(className, styles.link)}
        to={to}
        target={target}
        exact={exact}
        onClick={() => {
          if (onClick && typeof onClick === "function") {
            onClick({ to });
          }
        }}
      >
        <>{children || text}</>
      </NavLink>
    );
  }
  return (
    <a
      className={classnames(className, styles.link, {
        [`${styles.loading}`]: loading,
      })}
      href={href}
      target={target}
      onClick={onClick}
    >
      <>{children || text}</>
    </a>
  );
};

Link.propTypes = {
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  // children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  text: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  exact: PropTypes.bool,
};

Link.defaultProps = {
  exact: false,
  notif: false,
};

export default memo(Link);
