import React, { useContext, useEffect, Fragment, useState, useCallback } from "react";
import classnames from "classnames";
import styles from "./settings-profile-form.module.scss";
import { countryDialingCodeOptions } from "utils/countries";
import { AppContext } from "contexts";
import { useForm } from "hooks";
import initialFormState from "./initial-form-state";
import { updateOwnUser } from "api/user";
import moment from "moment";
import localStrings from "localization";
import { Gender, BloodType } from "enums";
import { uploadImage } from "api/storage";
import { Prompt } from "react-router-dom";
import { useRouter } from "hooks";
import { Upload, Message, Icon } from "atomic/atoms";
import {
  Panel,
  Button,
  Avatar,
  InputField,
  DatePickerField,
  SelectField,
  TextareaField,
  Toast,
} from "atomic/molecules";
import { LeaveModal } from "atomic/organisms";

const countryCodes = countryDialingCodeOptions();
const employeeStatus = [
  {
    text: localStrings.worker,
    value: localStrings.worker,
  },
  {
    text: localStrings.employed,
    value: localStrings.employed,
  },
  {
    text: localStrings.selfEmployed,
    value: localStrings.selfEmployed,
  },
  {
    text: localStrings.unemployed,
    value: localStrings.unemployed,
  },
];

const genderOptions = [
  {
    text: "Male",
    value: Gender.Male,
  },
  {
    text: "Female",
    value: Gender.Female,
  },
  {
    text: "Other",
    value: Gender.Other,
  },
];

const bloodTypeOptions = Object.keys(BloodType).map((bloodType) => {
  return {
    value: BloodType[bloodType],
    text: BloodType[bloodType],
  };
});

const SettingsProfileForm = () => {
  const { history } = useRouter();
  const { state: appState, dispatch: appDispatch } = useContext(AppContext);
  const { user } = appState;
  const { loading, firstName: userFn, lastName: userLn, email, phone, phoneCountryCode } = user;
  const {
    formFields,
    formFieldsValue,
    setFormFieldValues,
    setFormFieldValue,
    submit,
    isFormDirty,
  } = useForm(initialFormState, submitCallback);
  const [leaveModal, setLeaveModal] = useState(false);
  const [confirmLeave, setConfirmLeave] = useState(false);
  const [nextLocation, setNexLocation] = useState(null);

  const {
    firstName,
    lastName,
    dob,
    gender,
    address,
    company,
    employmentStatus,
    workAddress,
    workCountryCode,
    workPhone,
    bloodType,
    height,
    weight,
    contactName,
    contactRelationship,
    contactNumber,
    contactCountryCode,
  } = formFields;
  const { avatar } = formFieldsValue;
  const [message, setMessage] = useState(null);

  useEffect(() => {
    const { dob, ...rest } = user;
    if (!loading) {
      setFormFieldValues({
        dob: dob ? moment(dob, "MM/DD/YYYY") : null,
        ...rest,
      });
    }
  }, [loading, user, setFormFieldValues]);

  function submitCallback(formValues) {
    const { dob, weight, height, ...rest } = formValues;
    appDispatch({ type: "UPDATING_USER", data: formValues });
    setNexLocation(null);
    updateOwnUser({
      dob: dob ? moment(dob).format("MM/DD/YYYY") : null,
      email,
      phone,
      phoneCountryCode,
      weight: Number(weight),
      height: Number(height),
      ...rest,
    })
      .then(() => {
        Toast({
          content: localStrings.changesSaved,
          success: true,
        }).open();
        setConfirmLeave(true);
      })
      .catch((err) => {
        const { networkError, serverError } = err;
        if (serverError) {
          appDispatch({ type: "SERVER_ERROR" });
        }
        if (networkError) {
          appDispatch({ type: "NETWORK_ERROR" });
        }
      });
  }

  const beforeUpload = useCallback((file) => {
    const isJpgOrPng =
      file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/gif";
    const isLt1M = file.size / 1024 / 1024 < 1;
    setMessage("");
    if (!isJpgOrPng) {
      setMessage(localStrings.youCanOnlyUploadJpgPngGifFile);
    }
    if (!isLt1M) {
      setMessage(`${localStrings.imageMustBeSmallerThan} 1MB!`);
    }
    return isJpgOrPng && isLt1M;
  }, []);

  const handleChangeAvatar = (data, name) => {
    const { status, response, name: fileName } = data.file;
    if (status === "uploading") {
      appDispatch({ type: "UPLOADING_AVATAR", avatar: fileName });
      return;
    }

    if (status === "done") {
      const avatar = response.distributionUrl;
      // Get this url from response in real world.
      setMessage("");
      updateOwnUser({
        ...user,
        avatar,
      }).then(() => {
        Toast({
          content: localStrings.profileAvatarSaved,
          success: true,
        }).open();
      });
      appDispatch({ type: "SUCCESS_UPLOAD_AVATAR", avatar });
    }

    if (status === "error") {
      setMessage(localStrings.somethingWentWrong);
      appDispatch({ type: "ERROR_UPLOAD_AVATAR" });
    }
  };

  const handleUploadAvatar = (res) => {
    const { file, onSuccess, onError } = res;
    uploadImage({ file })
      .then((res) => onSuccess(res))
      .catch((err) => onError(err));
  };

  const closeLeaveModal = useCallback(() => {
    setLeaveModal(false);
  }, []);

  const handleBlockedNavigation = (nextLocation) => {
    if (isFormDirty && !confirmLeave) {
      setLeaveModal(true);
      setNexLocation(nextLocation.pathname);
      return false;
    }
    return true;
  };

  const handleOnClickLeave = useCallback(() => {
    setConfirmLeave(true);
  }, []);

  useEffect(() => {
    if (confirmLeave && nextLocation) {
      history.push(nextLocation);
    }
  }, [confirmLeave, nextLocation, history]);

  return (
    <Fragment>
      <Prompt when={isFormDirty} message={handleBlockedNavigation} />
      <LeaveModal visible={leaveModal} onClose={closeLeaveModal} onLeave={handleOnClickLeave} />
      <Panel className={styles.panel}>
        <h2 className={styles.panelTitle}>{localStrings.basicInformation}</h2>
        <Message className={styles.message} visible={!!message} error>
          <Icon className={styles.warningIcon} name="info-fill" />
          {message}
        </Message>

        <div className={styles.avatarContainer}>
          <Avatar
            className={styles.avatar}
            phrases={[userFn, userLn]}
            image={avatar}
            loading={loading}
          />

          <Upload
            className={styles.uploadContainer}
            beforeUpload={beforeUpload}
            onChange={handleChangeAvatar}
            customRequest={handleUploadAvatar}
          >
            <Button
              className={styles.uploadButton}
              text={avatar ? localStrings.changePhoto : localStrings.uploadPhoto}
            />
            <p className={styles.uploadGuide}>JPG, GIF or PNG. Max size of 1MB</p>
          </Upload>
          {avatar && (
            <div className={styles.removePhoto}>
              <Button
                tertiary
                text={localStrings.remove}
                onClick={() => {
                  setFormFieldValue("", "avatar");
                }}
              />
            </div>
          )}
        </div>
        <InputField
          name="firstName"
          className={styles.field}
          label={localStrings.firstName}
          placeholder="John"
          onChange={setFormFieldValue}
          {...firstName}
        />
        <InputField
          name="lastName"
          className={styles.field}
          label={localStrings.lastName}
          placeholder="Doe"
          onChange={setFormFieldValue}
          {...lastName}
        />
        <DatePickerField
          name="dob"
          loading={loading}
          className={styles.field}
          label={localStrings.birthdate}
          placeholder="MM / DD / YYYY"
          onChange={setFormFieldValue}
          {...dob}
        />
        <InputField
          name="address"
          className={styles.field}
          label={localStrings.permanentResidence}
          placeholder={localStrings.addressPlaceholder}
          onChange={setFormFieldValue}
          {...address}
        />
      </Panel>
      <Panel className={styles.panel}>
        <h2 className={styles.panelTitle}>{localStrings.personalInformation}</h2>
        <SelectField
          name="gender"
          data={genderOptions}
          className={styles.field}
          label={localStrings.gender}
          placeholder={localStrings.chooseGender}
          onChange={setFormFieldValue}
          {...gender}
        />
        <SelectField
          name="bloodType"
          data={bloodTypeOptions}
          className={styles.field}
          label={localStrings.bloodType}
          placeholder={localStrings.selectBloodType}
          onChange={setFormFieldValue}
          {...bloodType}
        />
        <InputField
          name="height"
          className={styles.field}
          label={localStrings.heightInCm}
          renderSuffix={<span>cm</span>}
          onChange={setFormFieldValue}
          {...height}
        />
        <InputField
          name="weight"
          className={styles.field}
          label={localStrings.weightInKg}
          renderSuffix={<span>kg</span>}
          onChange={setFormFieldValue}
          {...weight}
        />
      </Panel>
      <Panel className={styles.panel}>
        <h2 className={styles.panelTitle}>{localStrings.employeeDetails}</h2>
        <SelectField
          name="employmentStatus"
          className={styles.field}
          data={employeeStatus}
          label={localStrings.employmentStatus}
          placeholder={localStrings.selectEmploymentStatus}
          onChange={setFormFieldValue}
          {...employmentStatus}
        />
        <InputField
          name="company"
          className={styles.field}
          label={localStrings.company}
          placeholder={localStrings.companyPlaceholder}
          onChange={setFormFieldValue}
          {...company}
        />
        <div className={classnames(styles.inputMobileNumber, styles.field)}>
          <span className={classnames(styles.inputMobileNumberLabel, "compLabel")}>
            {localStrings.workContactNumber}
          </span>
          <SelectField
            name="workCountryCode"
            classNameContainer={styles.mobileNumber}
            data={countryCodes}
            placeholder="+63"
            onChange={setFormFieldValue}
            {...workCountryCode}
          />
          <InputField
            name="workPhone"
            className={styles.workPhone}
            placeholder="916 4567 890"
            onChange={setFormFieldValue}
            {...workPhone}
          />
        </div>
        <TextareaField
          name="workAddress"
          className={styles.field}
          label={localStrings.workAddress}
          placeholder={localStrings.addressPlaceholder}
          onChange={setFormFieldValue}
          {...workAddress}
        />
      </Panel>
      <Panel className={styles.panel}>
        <h2 className={styles.panelTitle}>{localStrings.emergencyContactPerson}</h2>
        <InputField
          name="contactName"
          className={styles.field}
          label={localStrings.contactPersonsName}
          placeholder={localStrings.fullName}
          onChange={setFormFieldValue}
          {...contactName}
        />
        <InputField
          name="contactRelationship"
          className={styles.field}
          label={localStrings.contactRelationship}
          placeholder={localStrings.relationshipPlaceholder}
          onChange={setFormFieldValue}
          {...contactRelationship}
        />
        <div className={classnames(styles.inputMobileNumber, styles.field)}>
          <span className={classnames(styles.inputMobileNumberLabel, "compLabel")}>
            {localStrings.mobileNumber}
          </span>
          <SelectField
            name="contactCountryCode"
            classNameContainer={styles.mobileNumber}
            data={countryCodes}
            placeholder="+63"
            onChange={setFormFieldValue}
            {...contactCountryCode}
          />
          <InputField
            name="contactNumber"
            className={styles.workPhone}
            placeholder="916 4567 890"
            onChange={setFormFieldValue}
            {...contactNumber}
          />
        </div>
      </Panel>
      <div className={styles.actions}>
        <Button text={localStrings.saveChanges} onClick={submit} />
      </div>
    </Fragment>
  );
};

export default SettingsProfileForm;
