import React, { useState, Fragment } from "react";
import { MemberStatusInfoModal } from "components/member";
import localStrings from "localization";
import styles from "./member-filter.module.scss";
import { Common, AccountStatus, Mixpanel } from "enums";
import mixpanel from "mixpanel-browser";
import { Icon } from "atomic/atoms";
import { SelectField } from "atomic/molecules";
import { Filters } from "atomic/organisms";
import TemperatureInfoModal from "../temperature-info-modal/temperature-info-modal";

const MemberFilter = ({
  filters,
  areaFilters,
  isFilterDirty,
  modifyFilter,
  clearFilter,
  search,
  apply,
}) => {
  const { searchKey, pairingStatus, deviceStatus, bodyTemp, areaId, accountStatus } = filters;
  const [memberStatusModal, setMemberStatusModal] = useState(false);
  const [temperatureInfoModal, setTemperatureInfoModal] = useState(false);

  return (
    <Fragment>
      <Filters
        onApplyFilter={apply}
        onApplySearch={(searchKey) => {
          search(searchKey);
        }}
        onClearFilter={clearFilter}
        initialSearchKey={searchKey}
        hasFilters={isFilterDirty}
      >
        <SelectField
          className={styles.filterOption}
          // label={localStrings.temperature}
          data={temperatureData}
          onChange={(bodyTemp) => {
            modifyFilter({ bodyTemp });
          }}
          label={
            <span className={styles.memberStatus}>
              {localStrings.temperature}{" "}
              <Icon
                name="info-fill"
                onClick={() => {
                  mixpanel.track(Mixpanel.CLICK_MEMBER_STATUS_FILTER_INFO);
                  setTemperatureInfoModal(true);
                }}
              />
            </span>
          }
          value={bodyTemp}
        />
        <SelectField
          className={styles.filterOption}
          label={localStrings.accessoryStatus}
          data={accessoryStatusData}
          onChange={(deviceStatus) => {
            modifyFilter({ deviceStatus });
          }}
          value={deviceStatus}
        />
        <SelectField
          className={styles.filterOption}
          label={localStrings.pairingStatus}
          data={pairStatusData}
          onChange={(pairingStatus) => {
            modifyFilter({ pairingStatus });
          }}
          value={pairingStatus}
        />
        <SelectField
          className={styles.filterOption}
          label={localStrings.area}
          data={areaFilters}
          onChange={(areaId) => {
            modifyFilter({ areaId });
          }}
          value={areaId}
        />
        <SelectField
          className={styles.filterOption}
          label={
            <span className={styles.memberStatus}>
              {localStrings.memberStatus}{" "}
              <Icon
                name="info-fill"
                onClick={() => {
                  mixpanel.track(Mixpanel.CLICK_MEMBER_STATUS_FILTER_INFO);
                  setMemberStatusModal(true);
                }}
              />
            </span>
          }
          data={accountStatusData}
          onChange={(accountStatus) => {
            modifyFilter({ accountStatus });
          }}
          value={accountStatus}
        />
      </Filters>
      <MemberStatusInfoModal
        visible={memberStatusModal}
        onClose={() => setMemberStatusModal(false)}
      />
      <TemperatureInfoModal
        visible={temperatureInfoModal}
        onClose={() => setTemperatureInfoModal(false)}
      />
    </Fragment>
  );
};

const pairStatusData = [
  {
    text: localStrings.all,
    value: Common.ALL,
  },
  {
    text: localStrings.paired,
    value: Common.PAIRED,
  },
  {
    text: localStrings.noDevice,
    value: Common.NO_DEVICE,
  },
];

const accessoryStatusData = [
  {
    text: localStrings.all,
    value: Common.ALL,
  },
  {
    text: localStrings.activeAndSyncing,
    value: Common.ACTIVE,
  },
  {
    text: localStrings.inactive,
    value: Common.INACTIVE,
  },
];

const temperatureData = [
  {
    text: localStrings.all,
    value: Common.ALL,
  },
  {
    text: localStrings.lowTemperature,
    value: Common.LOW_TEMPERATURE,
  },
  {
    text: localStrings.normal,
    value: Common.NORMAL,
  },
  {
    text: localStrings.higherThanNormal,
    value: Common.HIGHER_THAN_NORMAL,
  },
  {
    text: localStrings.highTemperature,
    value: Common.HIGH_TEMPERATURE,
  },
  {
    text: localStrings.veryHigh,
    value: Common.VERY_HIGH,
  },
  {
    text: localStrings.extremelyHigh,
    value: Common.EXTREMELY_HIGH,
  },
  {
    text: `All ${localStrings.temperatureCases}`,
    value: Common.FEVER,
  },
];

const accountStatusData = [
  {
    text: localStrings.all,
    value: Common.ALL,
  },
  {
    text: `${localStrings.verifiedMember}s`,
    value: AccountStatus.VERIFIED_MEMBER,
  },
  {
    text: localStrings.unverifiedMember,
    value: AccountStatus.UNVERIFIED_MEMBER,
  },
  {
    text: localStrings.profileOnly,
    value: AccountStatus.PROFILE_ONLY,
  },
  {
    text: localStrings.pendingInvites,
    value: AccountStatus.PENDING_MEMBER,
  },
];

export default MemberFilter;
