import React, { useState } from "react";
import styles from "./member-list.module.scss";
import { MemberListHeader, MemberFilter, MemberAddNewModal } from "components/member";
import { MemberTableContainer } from "containers";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "enums";

const MemberList = ({
  tempType,
  changeTempType,
  areaFilters,
  filters,
  isFilterDirty,
  modifyFilter,
  clearFilter,
  members,
  total,
  loadingUsers,
  search,
  apply,
  changePage,
  changePageSize,
  usersRequest,
  exportUsers,
  exportingUsers,
}) => {
  const [showAddMemberModal, setShowAddMemberModal] = useState(false);

  return (
    <div className={styles.container}>
      
      <MemberListHeader
        changeTempType={changeTempType}
        tempType={tempType}
        setShowAddMemberModal={setShowAddMemberModal}
        exportUsers={exportUsers}
        exportingUsers={exportingUsers}
      />
      <MemberFilter
        filters={filters}
        areaFilters={areaFilters}
        isFilterDirty={isFilterDirty}
        modifyFilter={modifyFilter}
        clearFilter={clearFilter}
        search={search}
        apply={apply}
      />
      <MemberTableContainer
        data={members}
        total={total}
        isFilterDirty={isFilterDirty}
        loading={loadingUsers}
        filters={filters}
        changePage={changePage}
        changePageSize={changePageSize}
        tempType={tempType}
        usersRequest={usersRequest}
      />
      <MemberAddNewModal
        fetchUsers={usersRequest}
        visible={showAddMemberModal}
        closeModal={() => {
          mixpanel.track(Mixpanel.CANCEL_INVITE_MEMBERS);
          setShowAddMemberModal(false);
        }}
      />
    </div>
  );
};

export default MemberList;
