import React, { useMemo, useEffect, useState, useCallback, Fragment } from "react";
import styles from "./contact-history.module.scss";
import { Table, Image, Pagination } from "atomic/atoms";
import { Avatar } from "atomic/molecules";
import { v4 as uuidv4 } from "uuid";
import localStrings from "localization";
import EmptyImage from "images/empty-contact-history.svg";
import { getFullName, formatDate, getLocalItem, tellDuration } from "utils";
import { Tooltip as AntTooltip } from "antd";
import { UnableToRetrieveError } from "pages";
import { ContactHistoryDetails, UnknownUserModal } from "components/users";
import { contactTracing } from "api/contact-tracing";
import moment from "moment";
import momentTimezone from "moment-timezone";
// import {
//   prepareDocDefinition,
//   downloadPDF,
// } from "../contact-history-print/contact-history-print";
import ContactHistoryFilter from "../contact-history-filter/contact-history-filter";
import localTranslation from "localization/localization";

const tableColumns = [
  {
    key: uuidv4(),
    dataIndex: "name",
    title: localStrings.name,
    width: 250,
    ellipsis: true,
  },
  {
    key: uuidv4(),
    dataIndex: "dateOfContact",
    title: localStrings.dateOfContact,
  },
  {
    key: uuidv4(),
    dataIndex: "duration",
    width: 90,
    title: localStrings.duration,
  },
  {
    key: uuidv4(),
    dataIndex: "locationArea",
    title: `${localStrings.location}/${localStrings.area}`,
  },
];

const pageSize = 10;

const ContactHistory = ({ userId, user }) => {
  // const { state } = useContext(ViewUserContext);
  // const { userInfo } = user;
  const { firstName, lastName } = user;
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState({
    active: false,
    data: null,
  });
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [dates, setDates] = useState([moment().subtract(30, "days"), moment()]);
  const [searchKey, setSearchKey] = useState("");
  const [initializing, setInitializing] = useState(true);
  const startDate = useMemo(() => {
    return dates[0];
  }, [dates]);
  const endDate = useMemo(() => {
    return dates[1];
  }, [dates]);
  const [error, setError] = useState(false);
  const [unknownUserModal, setUnknownUserModal] = useState({
    visible: false,
    userId: 0,
  });

  const fetchContactHistory = useCallback(
    (page = 1, d, s, fromFilter) => {
      setLoading(true);
      setError(false);
      contactTracing({
        userId,
        organizationId: getLocalItem("organizationId"),
        timezone: momentTimezone.tz.guess(),
        startDate: d ? d[0].format("MM/DD/YYYY") : startDate.format("MM/DD/YYYY"),
        endDate: d ? d[1].format("MM/DD/YYYY") : endDate.format("MM/DD/YYYY"),
        page,
        pageSize,
        searchKey: fromFilter ? s : searchKey,
      })
        .then((res) => {
          setLoading(false);
          const {
            data,
            metadata: { total },
          } = res;
          setData(data);
          setTotal(total);
        })
        .catch(() => {
          setError(true);
          setLoading(false);
        });
    },
    [userId, searchKey, startDate, endDate]
  );

  const handleFilterHistory = (s, d) => {
    setDates(d);
    setSearchKey(s);
    fetchContactHistory(1, d, s, true);
  };

  useEffect(() => {
    if (initializing && userId) {
      fetchContactHistory();
      setInitializing(false);
    }
  }, [userId, initializing, fetchContactHistory]);

  const emptyRenderer = useMemo(() => {
    return {
      description: (
        <div className={styles.emptyTable}>
          <Image className={styles.emptyImage} src={EmptyImage} />
          <h3 className={styles.emptyTitle}>{localStrings.noContactDetected}</h3>
          <p className={styles.emptyDesc}>
            {localStrings.formatString(
              localStrings.hasNotBeenInContact,
              getFullName({ firstName, lastName, empty: "User" })
            )}
          </p>
        </div>
      ),
    };
  }, [firstName, lastName]);

  const preparedData = useMemo(() => {
    return data.map((d) => {
      const {
        contactUserId,
        contactFirstName: firstName,
        contactLastName: lastName,
        contactStartDate,
        durationInSeconds,
        areaName: locationArea,
        avatar,
        organizationId: userOrganizationId,
        ...rest
      } = d;
      const otherOrg = !userOrganizationId;
      const name = otherOrg
        ? localTranslation("unknowUserUserId", [contactUserId])
        : getFullName({ firstName, lastName });
      return {
        ...rest,
        key: uuidv4(),
        name: (
          <div className={styles.contactNameColumn}>
            <Avatar
              image={otherOrg ? "" : avatar}
              phrases={[otherOrg ? "" : firstName, otherOrg ? "" : lastName]}
              className={styles.contactAvatar}
            />
            <AntTooltip title={name}>
              <span
                className={styles.contactName}
                onMouseDown={(e) => {
                  e.stopPropagation();
                  console.log(d);
                  if (otherOrg) {
                    setUnknownUserModal({
                      visible: true,
                      userId: contactUserId,
                    });
                    return;
                  }
                  window.open(`/users/${contactUserId}`, "_blank");
                }}
              >
                {name}
              </span>
            </AntTooltip>
          </div>
        ),
        dateOfContact: formatDate(contactStartDate, "MMMM DD, YYYY h:mm a"),
        duration: tellDuration(durationInSeconds) || "-",
        locationArea: locationArea || "-",
      };
    });
  }, [data]);

  const showDetailedContactHistory = (index) => {
    const d = { ...data[index], otherOrg: false };
    const { firstName, lastName } = d || {};
    d.contactFullName = d.otherOrg
      ? localTranslation("unknowUserUserId", [d.contactUserId])
      : getFullName({ firstName, lastName });
    if (d.otherOrg) {
      d.contactFirstName = "";
      d.contactLastName = "";
      d.avatar = "";
    }
    setModal({
      active: true,
      data: d,
      total: data.length,
      place: index + 1,
      index,
    });
  };

  const onChangePage = useCallback(
    (currentPage) => {
      fetchContactHistory(currentPage);
      setCurrentPage(currentPage);
    },
    [fetchContactHistory]
  );

  // const handleOnClickExport = () => {
  //   contactTracing({
  //     userId,
  //     organizationId: getLocalItem("organizationId"),
  //     timezone: momentTimezone.tz.guess(),
  //     startDate: startDate.format("MM/DD/YYYY"),
  //     endDate: endDate.format("MM/DD/YYYY"),
  //   }).then((res) => {
  //     const { data } = res;
  //     const tableColumns = [
  //       "NAME",
  //       "LAST DATE OF CONTACT",
  //       "DURATION",
  //       "LOCATION/AREA",
  //     ];
  //     const tableColumsWidths = ["*", 80, 60, "*"];
  //     const tableData = data.map((d) => {
  //       const {
  //         contactFirstName: firstName,
  //         contactLastName: lastName,
  //         lastDateOfContact,
  //         durationInSeconds,
  //         areaName,
  //       } = d;
  //       const name = getFullName({
  //         firstName,
  //         lastName,
  //       });
  //       const lastContactDate = formatDate(
  //         lastDateOfContact,
  //         "MMM DD, YYYY hh:mm a"
  //       );
  //       const duration = tellDuration(durationInSeconds);
  //       return [
  //         { text: name, margin: [0, 16] },
  //         { text: lastContactDate, margin: [0, 16] },
  //         { text: duration, margin: [0, 16] },
  //         { text: areaName, margin: [0, 16] },
  //       ];
  //     });
  //     const def = prepareDocDefinition({
  //       title: getFullName({ firstName, lastName }),
  //       tableColumns,
  //       tableColumsWidths,
  //       tableData,
  //       startDate,
  //       endDate,
  //     });
  //     downloadPDF(def);
  //   });
  // };

  return (
    <Fragment>
      <div className={styles.container}>
        <UnknownUserModal setModal={setUnknownUserModal} modal={unknownUserModal} />
        <ContactHistoryDetails
          user={user}
          dates={dates}
          modal={modal}
          setModal={setModal}
          showUnknowUser={setUnknownUserModal}
          prev={() => {
            const index = modal.index - 1;
            setModal({
              active: true,
              data: data[index],
              total: data.length,
              place: index + 1,
              index,
            });
          }}
          next={() => {
            const index = modal.index + 1;
            setModal({
              active: true,
              data: data[index],
              total: data.length,
              place: index + 1,
              index,
            });
          }}
        />
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <h2 className={styles.title}>{localStrings.contactHistory}</h2>
            <p className={styles.desc}>{localStrings.contactHistoryDesc(firstName)}</p>
          </div>
          {/* {!!preparedData.length && (
            <Button
              icon={{
                name: "export",
              }}
              text={localStrings.export}
              onClick={handleOnClickExport}
            />
          )} */}
        </div>
        <ContactHistoryFilter
          data={data.length}
          search={handleFilterHistory}
          errorPage={error}
          loading={loading}
          searchKeyValue={searchKey}
          renderContent={
            <>
              {error ? (
                <UnableToRetrieveError className={styles.unableToRetrieve} />
              ) : (
                <Table
                  className={styles.table}
                  columns={tableColumns}
                  data={preparedData}
                  emptyRenderer={emptyRenderer}
                  onRowClick={showDetailedContactHistory}
                  loading={loading}
                />
              )}
            </>
          }
        />
      </div>
      <Pagination
        className={styles.pagination}
        total={total}
        defaultPageSize={pageSize}
        pageSize={pageSize}
        showSizeChanger={false}
        onChange={onChangePage}
        currentPage={currentPage}
      />
    </Fragment>
  );
};

export default ContactHistory;
