import LocalizedStrings from "react-localization";
import en from "./en";
import th from "./th";
import vi from "./vi";
import id from "./id";
import ja from "./ja";
import zhCN from "./zh-CN";
import es from "./es";

const cleanTranslation = (translations) => {
  const newTranslation = {};
  for (const [k, translation] of Object.entries(translations)) {
    if (translation) {
      newTranslation[k] = translation;
    }
  }
  return newTranslation;
};

const localStrings = new LocalizedStrings(
  {
    en: cleanTranslation(en),
    th: cleanTranslation(th),
    vi: cleanTranslation(vi),
    id: cleanTranslation(id),
    zh: cleanTranslation(zhCN),
    "zh-HK": cleanTranslation(en),
    "zh-TW": cleanTranslation(en),
    ja: cleanTranslation(ja),
    es: cleanTranslation(es),
  },
  { logsEnabled: false }
);

export default localStrings;
