import React from "react";
import localStrings from "localization";
import styles from "./member-status-info.module.scss";
import { Modal } from "atomic/atoms";

const MemberStatusInfoModal = ({ visible, onClose }) => {
  return (
    <Modal
      title={localStrings.memberStatus}
      visible={visible}
      onCancel={onClose}
      className={styles.modal}
    >
      <h2 className={styles.title}>{localStrings.verifiedMember}s</h2>
      <p className={styles.desc}>{localStrings.verifiedMembersDesc}</p>

      <h2 className={styles.title}>{localStrings.memberProfileOnly}</h2>
      <p className={styles.desc}>{localStrings.memberProfileOnlyDesc}</p>

      <h2 className={styles.title}>{localStrings.unverifiedMember}</h2>
      <p className={styles.desc}>{localStrings.unverifiedMembersDesc}</p>

      <h2 className={styles.title}>{localStrings.pendingInvites}</h2>
      <p className={styles.desc}>{localStrings.pendingInvitesDesc}</p>
    </Modal>
  );
};

export default MemberStatusInfoModal;
