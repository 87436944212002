import React, { useContext } from "react";
import { useRouter } from "hooks";
import { OnboardingLayout } from "components/onboarding";
import localStrings from "localization";
import styles from "./profile-photo.module.scss";
import BackgroundImage from "images/touch-screen.svg";
import { UserContext, UserOrganizationsContext } from "contexts";
import { UploadProfilePhotoContainer } from "containers";
import { setLocalItem } from "utils";
import path from "path/path";
import { SubmitStep } from "components/onboarding";
import { markOnboardUser } from "api/user";

const ProfilePhotoContainer = () => {
  const { refetchUser } = useContext(UserContext);
  const { userOrganizations } = useContext(UserOrganizationsContext);
  const { history } = useRouter();

  return (
    <OnboardingLayout
      backgroundImage={BackgroundImage}
      content={{
        description: localStrings.tellUsMoreAboutYourself,
        header: localStrings.finishSettingUpAccount,
      }}
    >
      <div className={styles.container}>
        <UploadProfilePhotoContainer />
        <SubmitStep
          onBack={async () => {
            setLocalItem("fromOnboarding", 1);
            await markOnboardUser();
            await refetchUser();
            if (userOrganizations.length > 0) {
              history.push(path.ONBOARDING_PRIVACY_SETTINGS);
            } else {
              window.location.replace(path.USER_PROFILE);
            }
          }}
          onBackContent={localStrings.setupProfileLater}
          onSubmit={() => {
            history.push(path.ONBOARDING_ABOUT_YOU);
          }}
        />
      </div>
    </OnboardingLayout>
  );
};

export default ProfilePhotoContainer;
