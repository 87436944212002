import React, { useMemo } from "react";
import { useConfirmationModal } from "components/common";
import { Accessories } from "enums";
import styles from "./device.module.scss";
import { tellTime, isBetweenDate } from "utils";
import localStrings from "localization";
import classnames from "classnames";
import { v4 as uuidv4 } from "uuid";
import { useApi, useMount } from "hooks";
import { Image, Icon, Loading } from "atomic/atoms";
import { Panel, Button, Toast } from "atomic/molecules";
import { FailedToLoad } from "atomic/organisms";

import * as moment from "moment";
const { WristbandEmpty } = Accessories;

const LoadingContainer = () => {
  return (
    <div>
      <Loading width={150} />
      <Loading width={230} />
    </div>
  );
};

const useDevice = ({
  api,
  className,
  isManageable,
  strings,
  unpairApi,
  showPairNewDeviceModal,
  organizationId,
  userId,
  userPrivacyStatus,
  userPrivacyLoading,
  ownAccount,
  isMemberProfile,
}) => {
  const { request: upairDevice, loading: unpairing } = useApi({
    api: unpairApi,
  });
  const { request, loading = true, result: data, error } = useApi({
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
    api: () => api(),
  });
  const resultData =
    ownAccount && !isMemberProfile
      ? {
          userDevices: data || [],
        }
      : data || {
          userDevices: [],
        };
  const result =
    resultData && resultData.userDevices && resultData.userDevices.length
      ? resultData.userDevices
      : [];

  const rl = result
    .map((res) => {
      return res.lastSyncDateTime ? moment(res.lastSyncDateTime).format("X") : "";
    })
    .filter((v) => {
      return v !== "";
    })
    .sort()
    .reverse()[0];
  const latestSync = rl ? tellTime(moment(rl, "X")) : "";

  const {
    show: confirmDeleteDevice,
    render: ConfirmDeleteDevice,
    close: closeConfirmDeleteDevice,
  } = useConfirmationModal({
    title: localStrings.removeAccessory,
    content: localStrings.removeAccessoryDesc,
    onOkText: localStrings.remove,
    loading: unpairing,
    onConfirm: ({ deviceUid }) => {
      const unpair = async () => {
        await upairDevice({ deviceUid, organizationId, userId });
        Toast({
          visible: true,
          content: localStrings.accessoryRemoved,
          icon: "check-fill",
          success: true,
        }).open();
        await request();
      };
      try {
        unpair();
      } catch {
        Toast({
          visible: true,
          content: localStrings.unableToRemoveAccessory,
          icon: "info-fill",
          error: true,
        }).open();
      } finally {
        closeConfirmDeleteDevice();
      }
    },
  });

  const hasDevice = result?.length;
  useMount(() => {
    const init = async () => {
      await request();
    };
    init();
  });

  const preparedItems = useMemo(() => {
    if (!result) {
      return [];
    }
    return result.map((d) => {
      const date = d.lastSyncDateTime;
      return {
        ...d,
        lastSyncDateTime: date ? (
          <span className={styles.lastSynced}>
            {!isBetweenDate(date, 6, "hours") && (
              <Icon className={styles.warningIcon} name="exclamation-fill" />
            )}
            {`${localStrings.lastSynced} ${tellTime(date)}`}
          </span>
        ) : null,
      };
    });
  }, [result]);
  const showActionButton =
    !loading &&
    !error &&
    ((userPrivacyStatus.isWristbandAllowed && !userPrivacyLoading) || ownAccount);

  const render = (
    <Panel className={classnames(styles.container, className)}>
      {ConfirmDeleteDevice}
      <div className={styles.titleContainer}>{isManageable && <p>{strings.addBand}</p>}</div>
      {loading && <LoadingContainer />}
      {!hasDevice && (
        <Image
          className={classnames(styles.accessoryImage, {
            [`${styles.noAccessory}`]: !hasDevice,
            [`${styles.errorImage}`]: error,
          })}
          src={WristbandEmpty}
        />
      )}

      {!hasDevice && <h2 className={styles.noAccessoryTitle}>{localStrings.noAccessoryYet}</h2>}
      {showActionButton ? (
        <Button
          className={classnames(styles.action, styles.noAccessory)}
          onClick={() => showPairNewDeviceModal()}
        >
          <Icon name="plus" className={styles.icon} />
          {localStrings.addPouchband}
        </Button>
      ) : null}
      {/* {!loading && !hasDevice && isManageable ? (
        <Button
          className={classnames(styles.action, styles.noAccessory)}
          icon="plus"
          text={localStrings.addAccessory}
          onClick={() => showPairNewDeviceModal()}
        />
      ) : null} */}
      {error ? (
        <FailedToLoad image={false} />
      ) : (
        <div className={styles.listContainer}>
          {preparedItems.map((item) => {
            return (
              <div className={styles.list} key={uuidv4()}>
                <div>
                  <h4>{item.deviceUid}</h4>
                  {item.lastSyncDateTime && <p>{item.lastSyncDateTime}</p>}
                </div>
                {showActionButton && (
                  <div>
                    <Icon
                      name="close"
                      className={styles.remove}
                      onClick={() => {
                        confirmDeleteDevice({ deviceUid: item.deviceUid });
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </Panel>
  );
  return { render, hasDevice, request, latestSync, lastData: result.length ? result[0] : {} };
};

export default useDevice;
