import React, { useContext, useCallback, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Routes } from "enums";
import { RegisterUser } from "pages";
import { ConfirmLogoutModal } from "components/dashboard";
import { getUser } from "api/user";
import { validateEmail } from "api/user";
import { validateActivateToken } from "api/user-organization";
import { AppContext } from "contexts";
import { useMount, useRouter, useApi } from "hooks";
import { isLoggedIn, setLocalItem } from "utils";

const ActivateContainer = () => {
  const {
    query,
    history,
    match: { path },
  } = useRouter();
  const { email: activatingEmail, token, organizationId } = query;
  const { request: getUserRequest } = useApi({
    api: getUser,
    initialize: isLoggedIn(),
  });
  const { request: validateActivateTokenRequest, loading: validatingToken = true } = useApi({
    api: validateActivateToken,
  });
  const { request: validateEmailRequest, loading: validatingEmail = true } = useApi({
    api: validateEmail,
    params: {
      email: activatingEmail,
    },
  });
  const { dispatch: appDispatch } = useContext(AppContext);
  const [confirmModal, setConfirmModal] = useState({ visible: false });

  const handleCloseModal = () => {
    setConfirmModal((prev) => ({
      ...prev,
      visible: false,
    }));
  };

  const redirectExistingEmail = () => {
    history.replace({
      pathname: `/${Routes.AUTH}`,
      search: `?email=${activatingEmail}`,
    });
  };

  useMount(() => {
    const init = async () => {
      if (!token) {
        appDispatch({ type: "INVITE_EXPIRED_ERROR" });
      }
      try {
        // Check if token active/expired
        const { isActive, usedBy, dateUsed } = await validateActivateTokenRequest({ token });
        const isAlreadyRegistered = usedBy && dateUsed;
        if (isActive || isAlreadyRegistered) {
          // Check if there is currently login
          if (isLoggedIn()) {
            const { email: currentUserEmail } = await getUserRequest();
            // Check for same email
            if (currentUserEmail !== activatingEmail) {
              const isEmailExisting = await validateEmailRequest();
              setConfirmModal((prev) => ({
                ...prev,
                visible: true,
                email: currentUserEmail,
                isEmailExisting,
              }));
              return;
            }
            if (organizationId) {
              setLocalItem("organizationId", organizationId);
            }
            history.replace("/");
          } else {
            const isEmailExisting = await validateEmailRequest();
            if (isEmailExisting) {
              redirectExistingEmail();
            }
          }
          return;
        }
        appDispatch({ type: "INVITE_EXPIRED_ERROR" });
      } catch (err) {
        showError(err);
      }
    };
    init();
  });

  const showError = useCallback(
    (err) => {
      const { metadata } = err;
      if (metadata) {
        if (metadata.code === "8024") {
          appDispatch({ type: "INVITE_EXPIRED_ERROR" });
          return false;
        }
      }
    },
    [appDispatch]
  );

  return validatingToken || validatingEmail ? null : (
    <>
      <ConfirmLogoutModal
        {...confirmModal}
        closeModal={handleCloseModal}
        redirectExistingEmail={redirectExistingEmail}
      />
      <Switch>
        <Route exact component={RegisterUser} path={`${path}/${Routes.USER}`} />
        <Route path="*" render={() => <Redirect to={`/${Routes.AUTH}`} />} />
      </Switch>
    </>
  );
};

export default ActivateContainer;
