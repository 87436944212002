import React, { Fragment, useMemo } from "react";
import InviteRequestItem from "./invite-request-item";
import styles from "./invite-request.module.scss";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "hooks";
import localStrings from "localization";
import ViewInviteRequestModal from "./view-invite-request-modal";
import DeclineInviteModal from "./decline-invite-modal";
import path from "path/path";
import { Icon } from "atomic/atoms";
import { Panel } from "atomic/molecules";

const InviteRequest = ({
  inviteRequests,
  loading,
  viewInviteModal,
  declineModal,
  acceptInvite,
  declineInvite,
  showViewInviteModal,
  closeViewInviteModal,
  showDeclineModal,
  closeDeclineModal,
}) => {
  const { history } = useRouter();

  const renderInviteRequestItems = useMemo(() => {
    if (inviteRequests.length) {
      return inviteRequests.map((d) => (
        <InviteRequestItem
          {...d}
          key={`invite-request-${uuidv4()}`}
          viewRequest={showViewInviteModal}
        />
      ));
    } else {
      return <h2 className={styles.noInviteRequest}>No Invite Request</h2>;
    }
  }, [inviteRequests, showViewInviteModal]);

  const loadingInviteRequestItems = Array(5)
    .fill(null)
    .map(() => <InviteRequestItem loading={loading} key={uuidv4()} />);

  return (
    <Fragment>
      <Panel>
        <div className={styles.header}>
          <Icon
            onClick={() => history.push(path.SETTINGS_MY_ORGANIZATION)}
            className={styles.back}
            name="left-chevron-medium"
          />
          <h3 className={styles.title}>{localStrings.inviteRequest}s</h3>
          <p className={styles.desc}>{localStrings.inviteRequestDesc}</p>
        </div>
        {loading ? loadingInviteRequestItems : renderInviteRequestItems}
      </Panel>
      <ViewInviteRequestModal
        {...viewInviteModal}
        close={closeViewInviteModal}
        accept={({ organizationId }) => acceptInvite(organizationId)}
        decline={({ organizationId, organizationName }) =>
          showDeclineModal({ organizationId, organizationName })
        }
      />
      <DeclineInviteModal
        {...declineModal}
        close={closeDeclineModal}
        decline={(organizationId) => declineInvite(organizationId)}
      />
    </Fragment>
  );
};

export default InviteRequest;
