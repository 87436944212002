import React, { useContext, useCallback } from "react";
import { Redirect, Route } from "react-router-dom";
import { useMount, useRouter, useApi } from "hooks";
import { Icon } from "atomic/atoms";
import { setLocalItem } from "utils";
import { AppContext } from "contexts";
import { validateActivateToken } from "api/user-organization";

const ActivateRoute = ({ component: Component, ...rest }) => {
  const { dispatch: appDispatch } = useContext(AppContext);
  const { query } = useRouter();
  const { type, email, watcherId, token } = query || {};

  const { request: validateActivateTokenRequest, loading: validatingToken = true, result } = useApi(
    {
      api: validateActivateToken,
    }
  );

  const { usedBy, dateUsed } = result || {};
  const isAlreadyRegistered = usedBy && dateUsed;

  console.log(query);

  useMount(() => {
    const init = async () => {
      if (!token) {
        appDispatch({ type: "INVITE_EXPIRED_ERROR" });
      }
      try {
        await validateActivateTokenRequest({ token });
      } catch (err) {
        showError(err);
      }
    };
    init();
  });

  const showError = useCallback(
    (err) => {
      const { metadata } = err;
      if (metadata) {
        if (metadata.code === "8024") {
          appDispatch({ type: "INVITE_EXPIRED_ERROR" });
          return false;
        }
      }
    },
    [appDispatch]
  );

  if (validatingToken) {
    return (
      <Route
        {...rest}
        render={() => {
          return <Icon loading={validatingToken} />;
        }}
      />
    );
  }

  if (type === "ACTIVATE_WATCHLIST" || watcherId) {
    const obj = JSON.stringify({ type: "ACTIVATE_WATCHLIST", email, watcherId });
    console.log("Log Item", { type: "ACTIVATE_WATCHLIST", email, watcherId });
    setLocalItem("redirectQueryParam", obj);
    if (isAlreadyRegistered) {
      return <Redirect to={"/"} />;
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        return <Component {...props} />;
      }}
    />
  );

  // Old Behavior
  // return (
  //   <Route
  //     {...rest}
  //     render={(props) => {
  //       return isLoggedIn() ? <Redirect to="/" /> : <Component {...props} />;
  //     }}
  //   />
  // );
};

export default ActivateRoute;
