import { Get, Post, Put, Delete } from "./api";
import { ApiRoutes, Routes } from "enums";

export const getOrganization = async ({ organizationId }) => {
  const res = await Get(`${ApiRoutes.ORGANIZATION_V2(organizationId)}`, {});
  return res.data;
};

export const searchOrganizations = async (params) => {
  const res = await Post(`${Routes.HEALTH}/${Routes.ORGANIZATION}/${Routes.SEARCH}`, params);
  return res;
};

export const deleteOrganization = async ({ organizationId }) => {
  const res = await Delete(`${ApiRoutes.ORGANIZATION(organizationId)}`, {});
  return res.data;
};

export const getPrivacySettingsValue = async ({ organizationId, currentPrivacy = false }) => {
  const res = await Get(`${ApiRoutes.ORGANIZATION(organizationId)}/privacy`, { currentPrivacy });
  return res;
};

export const setPrivacySettingsValue = async ({ organizationId, form }) => {
  const res = await Post(`${ApiRoutes.ORGANIZATION(organizationId)}/${Routes.PRIVACY}`, form);
  return res;
};

export const getPrivacyStatusOrganization = async ({ organizationId, userId }) => {
  const res = await Get(
    `${ApiRoutes.ORGANIZATION(organizationId)}/${Routes.USER}/${userId}/${Routes.PRIVACY}`,
    { organizationId }
  );
  return res.data;
};

export const setPrivacyStatusOrganization = async ({ organizationId, userId, form }) => {
  const res = await Post(
    `${ApiRoutes.ORGANIZATION(organizationId)}/${Routes.USER}/${userId}/${Routes.PRIVACY}`,
    form
  );
  return res.data;
};

export const clearUserPrivacyStatusNotif = async ({ organizationId, userId }) => {
  const res = await Get(
    `${ApiRoutes.ORGANIZATION(organizationId)}/${Routes.USER}/${userId}/${Routes.PRIVACY}`
  );
  return res.data;
};

export const getPrivacyChanges = async ({ organizationId }) => {
  const res = await Get(
    `${ApiRoutes.ORGANIZATION(organizationId)}/${Routes.PRIVACY}/${Routes.CHANGES}`
  );
  return res.data;
};

export const createQuestion = async ({ organizationId, ...params }) => {
  const res = await Post(`${ApiRoutes.QUESTION_V2(organizationId)}`, params);
  return res.data;
};

export const getQuestion = async ({ organizationId, id }) => {
  const res = await Get(`${ApiRoutes.QUESTION_V2(organizationId)}/${id}`);
  return res.data;
};

export const searchQuestions = async (params) => {
  const res = await Post(
    `${ApiRoutes.ORGANIZATION(params.organizationId)}/${Routes.QUESTION}/${Routes.SEARCH}`,
    params
  );
  return res.data;
};

export const updateQuestion = async ({ id, organizationId, ...params }) => {
  const res = await Post(`${ApiRoutes.QUESTION_V2(organizationId)}/${id}`, params);
  return res.data;
};

export const removeQuestion = async ({ id, organizationId, ...params }) => {
  const res = await Delete(`${ApiRoutes.QUESTION_V2(organizationId)}/${id}`, params);
  return res.data;
};

export const autoAddQuestion = async ({ organizationId, questionId, isAutoAdd }) => {
  const res = await Put(
    `${ApiRoutes.ORGANIZATION(organizationId)}/${
      Routes.QUESTION
    }/${questionId}?isAutoAdd=${isAutoAdd}`,
    {}
  );
  return res.data;
};

export const searchForms = async (params) => {
  const res = await Post(
    `${ApiRoutes.ORGANIZATION(params.organizationId)}/${Routes.FORM}/${Routes.SEARCH}`,
    params
  );
  return res.data;
};

export const getForm = async ({ organizationId, formId }) => {
  const res = await Get(`${ApiRoutes.ORGANIZATION(organizationId)}/${Routes.FORM}/${formId}`, {});
  return res.data;
};

export const createForm = async ({ organizationId, params }) => {
  const res = await Post(`${ApiRoutes.ORGANIZATION(organizationId)}/${Routes.FORM}`, params);
  return res.data;
};

export const editForm = async ({ organizationId, formId, params }) => {
  const res = await Post(
    `${ApiRoutes.ORGANIZATION(organizationId)}/${Routes.FORM}/${formId}`,
    params
  );
  return res.data;
};

export const deleteForm = async ({ organizationId, formId }) => {
  const res = await Delete(
    `${ApiRoutes.ORGANIZATION(organizationId)}/${Routes.FORM}/${formId}`,
    {}
  );
  return res.data;
};

export const createCommunity = async ({ params }) => {
  const res = await Post(`${Routes.HEALTH}/${Routes.ORGANIZATION}/`, params);
  return res.data;
};

export const editCommunity = async ({ params, communityId }) => {
  const res = await Post(`${Routes.HEALTH}/${Routes.ORGANIZATION}/${communityId}`, params);
  return res.data;
};

export const getCommunity = async ({ organizationId }) => {
  const res = await Get(`${ApiRoutes.ORGANIZATION(organizationId)}`, {});
  return res.data;
};
