import React from "react";
import styles from "./emergency-contact-form.module.scss";
import classnames from "classnames";
import localStrings from "localization";
import { useRouter } from "hooks";
import path from "path/path";
import { InputField, SelectField } from "atomic/molecules";
import { SubmitStep } from "components/onboarding";

const EmergencyContactForm = ({
  className,
  setFormFieldValue,
  handleChangeEmergency,
  formFields,
  countryCodes,
  loading,
  onSubmit,
}) => {
  const { history } = useRouter();
  return (
    <div className={classnames(styles.container, className)}>
      <h1>{localStrings.emergencyContact}</h1>
      <p>{localStrings.addYourContactDetails}</p>
      <div className={styles.content}>
        <div className={styles.input}>
          <InputField
            name="contactName"
            label={localStrings.contactPersonsName}
            onChange={(value, name) => {
              setFormFieldValue(value, name);
              handleChangeEmergency(value, name);
            }}
            {...formFields.contactName}
            placeholder={localStrings.fullName}
          />
        </div>
        <div className={styles.input}>
          <InputField
            name="contactRelationship"
            label={localStrings.contactRelationship}
            onChange={(value, name) => {
              setFormFieldValue(value, name);
              handleChangeEmergency(value, name);
            }}
            {...formFields.contactRelationship}
            placeholder={localStrings.relationshipPlaceholder}
          />
        </div>
        <div className={styles.input}>
          <span className={classnames(styles.compLabel, "compLabel")}>
            {localStrings.mobileNumber}
          </span>
          <div className={styles.inputMobileNumber}>
            <SelectField
              showSearch
              classNameContainer={classnames(styles.mobileNumber)}
              data={countryCodes}
              name="contactCountryCode"
              onChange={(value, name) => {
                setFormFieldValue(value, name);
                handleChangeEmergency(value, name);
              }}
              {...formFields.contactCountryCode}
              placeholder="+63"
            />
            <InputField
              name="contactNumber"
              onChange={(value, name) => {
                setFormFieldValue(value, name);
                handleChangeEmergency(value, name);
              }}
              {...formFields.contactNumber}
              placeholder="916 4567 890"
            />
          </div>
        </div>
      </div>
      <SubmitStep
        loading={loading}
        onBack={() => {
          history.push(path.ONBOARDING_EMPLOYMENT);
        }}
        onSubmit={onSubmit}
        submitText={localStrings.completeProfile}
      />
    </div>
  );
};

export default EmergencyContactForm;
