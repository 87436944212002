import React from "react";
import styles from "./edit-member-details-form.module.scss";
import InfoContainer from "./info-container";
import localStrings from "localization";
import { Panel, DatePickerField, TimePickerField } from "atomic/molecules";
import { ActionButton } from "components/common";
import * as moment from "moment";

const EditMemberDetailsForm = ({ form, submit, loading, submitting, onCancel, isFormChanged }) => {
  const { setFormFieldValue, formFields } = form;

  const basicInfoContents = basicInfos.map((bi) => {
    return <InfoContainer key={bi.componentKey} {...bi} {...form} loading={loading} />;
  });

  const statisticInfoContents = statisticInfos.map((si) => {
    return <InfoContainer key={si.componentKey} {...si} {...form} loading={loading} />;
  });

  const profileInfoContents = profileInfos.map((pi) => {
    return <InfoContainer key={pi.componentKey} {...pi} {...form} loading={loading} />;
  });

  return (
    <div>
      <Panel>
        <div className={styles.sharedInformation}>
          <div className={styles.header}>Choose the information to be required:</div>
          <div>{basicInfoContents}</div>
          <div className={styles.subheaderContainer}>
            <div className={styles.subheader}>{localStrings.personalInformation}</div>
            {profileInfoContents}
          </div>
          <div className={styles.subheaderContainer}>
            <div className={styles.subheader}>{localStrings.statistics}</div>
            {statisticInfoContents}
          </div>
          <div className={styles.field}>
            <div className={styles.headerField}>
              <h3>Effective Time and Date</h3>
              <div>
                Please choose a time and date to implement the changes made. Members would be
                notified which of their information would be always shared as long as they are a
                part of the community.
              </div>
            </div>
            <div className={styles.dateAndTime}>
              <div>
                <DatePickerField
                  name="startDate"
                  format={"MMM D, YYYY"}
                  {...formFields.startDate}
                  onChange={setFormFieldValue}
                  className={styles.datePicker}
                  disabledDate={(d) => {
                    return d && d < moment().endOf("day");
                  }}
                  label={localStrings.date}
                  loading={loading}
                />
              </div>
              <div>
                <TimePickerField
                  name="startDate"
                  format={"h:mm A"}
                  {...formFields.startDate}
                  onChange={setFormFieldValue}
                  className={styles.datePicker}
                  disabledDate={(d) => {
                    return d && d < moment().endOf("day");
                  }}
                  label={localStrings.time}
                  loading={loading}
                />
              </div>
            </div>
          </div>
        </div>
      </Panel>
      <ActionButton
        submitText={localStrings.saveChanges}
        disabled={!isFormChanged}
        loading={submitting || loading}
        onSubmit={() => submit()}
        onCancel={onCancel}
      />
    </div>
  );
};

const basicInfos = [
  {
    componentKey: 0,
    title: localStrings.nameAndProfilePhoto,
    permission: "Required",
    description: localStrings.viewYourNameAndProfilePhoto,
    name: "isNameAndPhotoRequired",
  },
  {
    componentKey: 1,
    title: localStrings.emailAddressAndMobileNumber,
    permission: "Required",
    description: localStrings.viewYourContactInformation,
    name: "isEmailAndMobileNumberRequired",
  },
  {
    componentKey: 2,
    title: localStrings.pouchBand,
    description: localStrings.pouchBandDescription,
    permission: "Required",
    name: "isWristbandRequired",
  },
];

const statisticInfos = [
  {
    componentKey: 3,
    title: localStrings.bodyTemperature,
    description: localStrings.bodyTemperatureDays,
    permission: "Required",
    name: "isBodyTemperatureRequired",
  },
  {
    componentKey: 4,
    title: localStrings.areaLocation,
    description: localStrings.areaLocationDesc,
    permission: "Required",
    name: "isAreaLocationRequired",
  },
  {
    componentKey: 5,
    title: localStrings.geolocation,
    description: localStrings.geolocationDesc,
    permission: "Allowed",
    name: "isLocationRequired",
  },
  {
    componentKey: 6,
    title: localStrings.symptoms,
    description: localStrings.viewAndAddSymptoms,
    permission: "Allowed",
    name: "isSymptomRequired",
  },
];

const profileInfos = [
  {
    componentKey: 7,
    title: localStrings.aboutYou,
    description: localStrings.aboutYouDesc,
    permission: "Allowed",
    name: "isAboutYouRequired",
  },
  {
    componentKey: 8,
    title: localStrings.employeeDetails,
    description: localStrings.employmentDetailsDesc,
    permission: "Allowed",
    name: "isEmploymentDetailRequired",
  },
  {
    componentKey: 9,
    title: localStrings.emergencyContact,
    description: localStrings.emergencyContactDesc,
    permission: "Allowed",
    name: "isEmergencyContactRequired",
  },
];

export default EditMemberDetailsForm;
