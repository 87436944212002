import React, { memo } from "react";
import { Checkbox } from "atomic/atoms";
import classnames from "classnames";
import styles from "./checkbox-field.module.scss";

const CheckboxField = ({
  value = false,
  className,
  label,
  children,
  onChange = () => null,
  name,
  disabled,
  isChecked,
}) => {
  return (
    <div className={classnames(className, styles.field, "checkboxField")}>
      <Checkbox
        disabled={disabled}
        checked={isChecked}
        onChange={onChange}
        name={name}
        value={value}
      />
      {(children || label) && (
        <div className={classnames(styles.label, "checkboxLabel")}>{children || label}</div>
      )}
    </div>
  );
};

export default memo(CheckboxField);
