import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { AccountStatus } from "enums";
import { UnauthorizedError } from "pages";
import { RoleContext } from "contexts";

const ManagerRoute = ({ component: Component, componentProps, ...rest }) => {
  const role = useContext(RoleContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        return role === AccountStatus.MANAGER ? (
          <Component {...props} {...componentProps} />
        ) : (
          <UnauthorizedError {...props} />
        );
      }}
    />
  );
};

export default ManagerRoute;
