export const organizationState = {
  organizationId: 0,
  organizationName: null,
  organizationLogo: null,
  organizationContactNumber: null,
  latitude: null,
  longitude: null,
  userInvitedBy: null,
  invitedDateCreated: null,
  dateJoined: null,
  isActive: null,
  isPrivacySet: null,
  isNew: null,
  totalMembers: null,
  totalActiveMembers: null,
  totalFeverCases: null,
  userRoles: [],
};
