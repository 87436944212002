import { AccountStatus } from "enums";
import { organizationState } from "states";
import { getLocalItem } from "utils";

export const getCurrentOrganization = (organizations) => {
  const currentOrganizationId = getLocalItem("organizationId");

  if (currentOrganizationId) {
    const org = organizations.find((o) => {
      return o.organizationId === parseInt(currentOrganizationId);
    });
    if (org) {
      return org;
    }
    return null;
  } else {
    // after login scenario
    if (organizations.length > 0) {
      // return organizations[0];
      return getFallbackOrganization(organizations);
    }
    return organizationState;
  }
};

export const arrangeOrganizationByNewAndCurrent = (organizations) => {
  if (organizations.length <= 1) {
    const currentOrg = organizations.find((r) => {
      return r.organizationId === parseInt(localStorage.getItem("organizationId"));
    });
    if (currentOrg) {
      currentOrg.isCurrent = true;
      return [currentOrg];
    } else {
      return organizations;
    }
  } else {
    let arrangedOrganizations = [];

    const newOrg = organizations.find((o) => {
      return o.isNew === true;
    });

    const currentOrg = organizations.find((r) => {
      return r.organizationId === parseInt(localStorage.getItem("organizationId"));
    });

    if (currentOrg) {
      currentOrg.isCurrent = true;
    }

    // ARRANGE HERE
    if (currentOrg?.organizationId === newOrg?.organizationId) {
      arrangedOrganizations = [currentOrg];
    } else {
      if (newOrg) {
        arrangedOrganizations = [newOrg];
      }

      if (currentOrg) {
        arrangedOrganizations = [...arrangedOrganizations, currentOrg];
      }
    }

    // REMOVE EXCESS ON ORIGINAL ORG LIST
    if (newOrg) {
      const willRemoveNewOrgIndex = organizations.findIndex((r) => {
        return r.isNew === true;
      });

      if (willRemoveNewOrgIndex >= 0) {
        organizations.splice(willRemoveNewOrgIndex, 1);
      }
    }
    if (currentOrg) {
      const willRemoveOrgIndex = organizations.findIndex((r) => {
        return r.organizationId === parseInt(localStorage.getItem("organizationId"));
      });

      if (willRemoveOrgIndex >= 0) {
        organizations.splice(willRemoveOrgIndex, 1);
      }
    }

    // RETURN ARRANGED NEW AND CURRENT WITH THE REST OF THE ORG
    return [...arrangedOrganizations, ...organizations];
  }
};

export const getFallbackOrganization = (organizations) => {
  const organizationsWithManagerRole = organizations.filter((o) => {
    return o.userRoles.some((ur) => {
      return ur.role === AccountStatus.MANAGER;
    });
  });

  if (organizationsWithManagerRole.length > 0) {
    return organizationsWithManagerRole[0];
  }
  return organizations[0];
};
