export const environment = {
  id: "production",
  production: true,
  API_V3: "https://gw.pouchnation.com",
  FIREBASE: {
    apiKey: "AIzaSyBwTQaxZOqLXOCDe0Aytj8vw9qPB1UkJVo",
    authDomain: "ms-communication-ae098.firebaseapp.com",
    databaseURL: "https://ms-communication-ae098.firebaseio.com",
    projectId: "ms-communication-ae098",
    storageBucket: "ms-communication-ae098.appspot.com",
    messagingSenderId: "139918626078",
    appId: "1:139918626078:web:c8900ad0dffdb794538d0f",
    measurementId: "G-BG2Z7LL2BK",
  },
  MIXPANEL_TOKEN: "763af5c0f625347770feb6a1867ec07c",
  FEATURE_FLAG: {
    clientSideID: "5f7ad02fe750890b17de445e",
  },
};
