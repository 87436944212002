import React from "react";
import { Button as AntButton } from "antd";
import classnames from "classnames";
import styles from "./button.module.scss";
import PropTypes from "prop-types";

const Button = ({
  children,
  text,
  className,
  onClick,
  disabled,
  htmlType = "button",
  type = "primary",
  secondary,
  tertiary,
  primary,
  danger,
}) => {
  return (
    <AntButton
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={classnames(
        styles.button,
        {
          [`${styles.secondary}`]: secondary,
          [`${styles.tertiary}`]: tertiary,
          [`${styles.primary}`]: primary,
          [`${styles.danger}`]: danger || type === "danger",
          [`${styles.disabled}`]: disabled,
        },
        className
      )}
      htmlType={htmlType}
    >
      {children || text}
    </AntButton>
  );
};

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  text: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  htmlType: PropTypes.string,
  type: PropTypes.string,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  primary: PropTypes.bool,
  danger: PropTypes.bool,
};

export default Button;
