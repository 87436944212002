import React from "react";
import { useApi, useMount } from "hooks";
import { getTodayHourly, getPastDays } from "api/user-data";
import moment from "moment";
import momentTimezone from "moment-timezone";
import { useMemo } from "react";
import useLocations from "services/location.service";
import Skeleton from "react-loading-skeleton";

const useDiary = ({ user, organizationId = null, userPrivacyStatus, userPrivacyLoading }) => {
  const dateFormat = "MM/DD/YYYY";
  const userId = user?.userId;

  const today = useApi({
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
    api: () =>
      getTodayHourly({
        userId,
        timezone: momentTimezone.tz.guess(),
        date: moment().format(dateFormat),
        organizationId,
      }),
  });
  const {
    request: requestToday,
    loading: todayLoading = true,
    result: todayResultData = [],
  } = today;
  const todayResult = todayResultData;
  const pastDays = useApi({
    handleOwnError: {
      server: true,
      network: true,
      badrequest: true,
    },
    api: () =>
      getPastDays({
        userId,
        timezone: momentTimezone.tz.guess(),
        startDate: moment().subtract(13, "days").format(dateFormat),
        endDate: moment().subtract(1, "days").format(dateFormat),
        organizationId,
      }),
  });

  const {
    request: requestPastDays,
    loading: pastDaysLoading = true,
    result: pastDayResultData = [],
  } = pastDays;
  const pastDayResult = pastDayResultData;
  const loading = todayLoading || pastDaysLoading;
  const error = pastDays.error || today.error;

  const totalData = !loading && !error ? [...todayResult, ...pastDayResult] : [];

  const { locations } = useLocations({
    initialize: !loading && !userPrivacyLoading && userPrivacyStatus.isLocationAllowed,
    data: totalData,
  });

  useMount(() => {
    const rt = async () => {
      await requestToday();
    };
    const rp = async () => {
      await requestPastDays();
    };
    rt();
    rp();
  });

  const todayResultValue = useMemo(() => {
    return todayResult.map((d) => {
      const { latitude, longitude, location: areaLocation, areaId, areaName } = d || {};
      const location = (() => {
        const l = [latitude, longitude].join(",");
        const hasLocation = Boolean(latitude && longitude);
        if (userPrivacyLoading) {
          return <Skeleton />;
        }

        if (areaId && userPrivacyStatus.isAreaLocationAllowed) {
          return areaName || areaLocation || "-";
        }
        if (!userPrivacyStatus.isLocationAllowed) {
          return "-";
        }
        if (!locations[l] || locations[l].loading) {
          if (hasLocation) {
            return <Skeleton />;
          } else {
            return "-";
          }
        } else {
          return locations[l].location;
        }
      })();
      return {
        ...d,
        location,
        locationValue: location,
      };
    });
  }, [todayResult, locations, userPrivacyLoading, userPrivacyStatus]);

  const pastDayResultValue = useMemo(() => {
    return pastDayResult.map((d) => {
      const { latitude, longitude, location: areaLocation, areaId } = d || {};
      const location = (() => {
        const l = [latitude, longitude].join(",");
        const hasLocation = Boolean(latitude && longitude);
        if (userPrivacyLoading) {
          return <Skeleton />;
        }

        if (areaId && userPrivacyStatus.isAreaLocationAllowed) {
          return areaLocation || "-";
        }
        if (!userPrivacyStatus.isLocationAllowed) {
          return "-";
        }
        if (!locations[l] || locations[l].loading) {
          if (hasLocation) {
            return <Skeleton />;
          } else {
            return "-";
          }
        } else {
          return locations[l].location || "-";
        }
      })();
      return {
        ...d,
        location: <div>{location || "-"}</div>,
        locationValue: location,
      };
    });
  }, [pastDayResult, locations, userPrivacyLoading, userPrivacyStatus]);

  const totalDataValue = !loading && !error ? [...todayResultValue, ...pastDayResultValue] : [];

  return {
    latestData: totalDataValue.length ? totalDataValue[0] : {},
    loading,
    today: {
      ...today,
      loading: todayLoading,
      result: todayResultValue,
    },
    pastDays: {
      ...pastDays,
      loading: pastDaysLoading,
      result: pastDayResultValue,
    },
    totalData: totalDataValue,
    refreshDiary: () => {
      requestToday();
      requestPastDays();
    },
  };
};

export default useDiary;
