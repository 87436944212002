import React from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import { Routes } from "enums";
import { ManagerRoute } from "routes";
import {
  AccountSettingsContainer,
  ProfileSettingsContainer,
  MyOrganizationsContainer,
  TeamRolesContainer,
  PreferenceContainer,
  InviteManagerContainer,
  MemberDetailsContainer,
  EditMemberDetailsContainer,
  QuestionsContainer,
} from "containers";
// import FormsContainer from "./forms/forms.container";

const SettingContainer = () => {
  let { path } = useRouteMatch();

  return (
    <Switch>
      <Route component={MyOrganizationsContainer} path={`${path}/${Routes.MY_ORGANIZATION}`} />
      <ManagerRoute
        exact
        component={TeamRolesContainer}
        path={`${path}/${Routes.TEAMS_AND_ROLES}`}
      />
      <ManagerRoute
        exact
        component={InviteManagerContainer}
        path={`${path}/${Routes.TEAMS_AND_ROLES}/${Routes.INVITE_MANAGER}`}
      />
      <Route exact component={ProfileSettingsContainer} path={`${path}/${Routes.PROFILE}`} />
      <Route exact component={PreferenceContainer} path={`${path}/${Routes.PREFERENCE}`} />
      <ManagerRoute
        exact
        component={QuestionsContainer}
        path={`${path}/${Routes.FORMS}/${Routes.QUESTIONS}`}
      />
      {/* <ManagerRoute component={FormsContainer} path={`${path}/${Routes.FORMS}`} /> */}

      <ManagerRoute
        exact
        component={MemberDetailsContainer}
        path={`${path}/${Routes.MEMBER_DETAILS}`}
      />
      <ManagerRoute
        exact
        component={EditMemberDetailsContainer}
        path={`${path}/${Routes.MEMBER_DETAILS}/${Routes.EDIT}`}
      />
      <Route exact component={AccountSettingsContainer} path={path} />
      <Route path="*" render={() => <Redirect to={path} />} />
    </Switch>
  );
};

export default SettingContainer;
