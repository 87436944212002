import React from "react";
import { createSymptomLog } from "api/symptom";
import SymptomsForm from "../symptoms-form/symptoms-form";
import { useApi } from "hooks";
import localStrings from "localization";
import mixpanel from "mixpanel-browser";
import { Mixpanel } from "enums";

const CreateLogSymptoms = ({ organizationId, userId, goBack }) => {
  const { request, loading } = useApi({
    api: createSymptomLog,
    params: {
      userId,
    },
  });

  return (
    <SymptomsForm
      title={localStrings.logSymptoms}
      userId={userId}
      organizationId={organizationId}
      submitting={loading}
      submitCallBack={(params) => {
        const create = async () => {
          mixpanel.track(Mixpanel.CREATE_SYMPTOMS);
          await request({ params: { ...params, organizationId } });
          goBack();
        };
        create();
      }}
      goBack={goBack}
    />
  );
};

export default CreateLogSymptoms;
