import { Get, Put, Post, Delete } from "./api";
import { ApiRoutes, Routes } from "enums";

export const getUser = async () => {
  const res = await Get(`${ApiRoutes.USER}`);
  return res.data;
};

export const createUser = async (params) => {
  const res = await Post(`${ApiRoutes.USER}`, params);
  return res.data;
};

export const updateUser = async ({ userId, params }) => {
  const res = await Post(`${ApiRoutes.USER}/${userId}`, params);
  return res.data;
};

export const deleteUser = async ({ userId, organizationId }) => {
  const res = await Delete(`${ApiRoutes.USER}/${userId}/${Routes.ORGANIZATION}/${organizationId}`);
  return res.data;
};

export const getOrgUser = async ({ userId, organizationId }) => {
  const res = await Get(`${ApiRoutes.USER}/${userId}/${Routes.ORGANIZATION}/${organizationId}`, {});
  return res.data;
};

export const getUserById = async ({ userId }) => {
  const res = await Get(`${ApiRoutes.USER}/${userId}/${Routes.ORGANIZATION}`, {});
  return res.data;
};

export const updateOwnUser = async (params) => {
  const res = await Put(`${ApiRoutes.USER}`, params);
  return res.data;
};

export const updateAccount = async (params) => {
  const res = await Put(`${ApiRoutes.USER}/${Routes.ACCOUNT}`, params);
  return res.data;
};

export const updatePassword = async (params) => {
  const res = await Put(`${Routes.PROFILE}/${Routes.PASSWORD}`, params);
  return res.data;
};

export const validateEmail = async (params) => {
  const res = await Get(`${Routes.PROFILE}/check`, params);
  return res.data;
};

export const onboardUser = async (params) => {
  const res = await Put(`${ApiRoutes.USER}/${Routes.ONBOARD}`, {
    isProfilePhoto: false,
    isAboutYou: false,
    isEmploymentDetails: false,
    isEmergencyContact: false,
    ...params,
  });
  return res.data;
};

export const markOnboardUser = async () => {
  const res = await Get(`${ApiRoutes.USER}/${Routes.ONBOARD}`, {
    isOnboarded: true,
  });
  return res.data;
};

export const updateOtherUser = async (userId, params) => {
  const res = await Post(`${ApiRoutes.USER}/${userId}`, params);
  return res.data;
};

export const acceptPrivacySettings = async (params) => {
  const res = await Get(
    `${Routes.HEALTH}/${Routes.ORGANIZATION}/${params.organizationId}/${Routes.USER}/${params.userId}/privacy`,
    {
      isPrivacySet: true,
    }
  );
  return res.data;
};

export const validateOtp = async (params) => {
  const res = await Get(`${ApiRoutes.USER}/${Routes.INVITE}/${Routes.VALIDATE}-otp`, params);
  return res;
};
