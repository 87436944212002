import React, { useContext } from "react";
import { Switch, Route, Redirect, useRouteMatch } from "react-router-dom";
import CreateLogSymptoms from "components/profile/symptoms-tab/create-log-symptoms/create-log-symptoms";
import EditLogSymptoms from "components/profile/symptoms-tab/edit-log-symptoms/edit-log-symptoms";
import { Routes } from "enums";
import { useRouter } from "hooks";
import { OrganizationContext } from "contexts";
import path from "path/path";

const MemberSymptomContainer = () => {
  const { organizationId } = useContext(OrganizationContext);
  const { history, query } = useRouter();
  const { userId } = query || {};
  let { path: p } = useRouteMatch();
  const props = {
    userId,
    organizationId,
    goBack: () => history.push(`${path.MEMBER_PROFILE_SYMPTOMS(userId)}`),
  };
  console.log(`${path}${Routes.CREATE}`);
  return (
    <Switch>
      <Route
        exact
        component={() => <CreateLogSymptoms {...props} />}
        path={`${p}/${Routes.CREATE}`}
      ></Route>
      <Route
        exact
        component={() => <EditLogSymptoms {...props} />}
        path={`${p}/${Routes.EDIT}/${Routes.SYMPTOMS_LOG_ID}`}
      ></Route>
      <Route path="*" render={() => <Redirect to={`${p}/${Routes.CREATE}`} />} />
    </Switch>
  );
};

export default MemberSymptomContainer;
