import React, { useContext } from "react";
import { UserForm } from "components/users/user-form";
import { getOrgUser, updateUser } from "api/user";
import localStrings from "localization";
import { OrganizationContext } from "contexts";
import { useApi, useMount, useRouter } from "hooks";
import { deleteUser } from "api/user";
import { useConfirmationModal } from "components/common";
import { Toast } from "atomic/molecules";

const EditUser = () => {
  const { organizationId } = useContext(OrganizationContext);
  const { query, history } = useRouter();

  const { userId } = query;
  const { request, loading = true, result: data = {} } = useApi({
    api: getOrgUser,
    params: {
      organizationId,
      userId,
    },
  });
  const name = data ? `${`${data.firstName || ""} ${data.lastName || ""}`.trim() || "-"}` : "";

  useMount(() => {
    const init = async () => {
      await request();
    };
    init();
  });

  const { request: submitForm, loading: submitting } = useApi({
    api: updateUser,
    params: {
      organizationId,
      userId,
    },
  });

  async function onSubmit(form, success, error) {
    try {
      await submitForm({ params: { ...form, organizationId } });
      success();
    } catch (e) {
      error(e);
    }
  }

  const { request: requestDeleteUser, loading: loadingUser } = useApi({
    api: deleteUser,
    params: {
      organizationId,
      userId,
    },
  });

  const { show: showDeleteUserModal, render: renderDeleteUserModal } = useConfirmationModal({
    loading: loadingUser,
    content: (
      <div
        dangerouslySetInnerHTML={{
          __html: localStrings.formatString(
            localStrings.confirmDeleteMember,
            name === "-" ? localStrings.thisMember : name
          ),
        }}
      ></div>
    ),
    onOkText: localStrings.delete,
    title: `${localStrings.deleteMember}?`,
    onConfirm: () => {
      const submit = async () => {
        await requestDeleteUser();
        Toast({
          content: `“${name}” removed.`,
          success: true,
          visible: true,
          icon: "check-fill",
        }).open();
        history.push("/members");
      };
      submit();
    },
  });

  const confirmDeleteUser = () => {
    showDeleteUserModal();
  };

  return (
    <>
      {renderDeleteUserModal}
      <UserForm
        onSubmit={onSubmit}
        data={data}
        loading={loading}
        deleteUser={confirmDeleteUser}
        submitting={submitting}
      ></UserForm>
    </>
  );
};

export default EditUser;
