import React, { useState, useCallback, Fragment, useRef, memo, useEffect, useContext } from "react";
import styles from "./otp-form-modal.module.scss";
import localStrings from "localization";
import { useForm } from "hooks";
import initialFormFields from "./initial-form-fields";
import { OTPForm } from "components/auth";
import { verifyEmailOTP, sendEmailOTP } from "api/auth";
import moment from "moment";
import { setLocalItem, getLocalItem, removeLocalItem } from "utils";
import { AppContext } from "contexts";
import { Modal, Message } from "atomic/atoms";
import { Button } from "atomic/molecules";
import { LeaveModal } from "atomic/organisms";

const OTPFormModal = ({ onClose, visible, email, firstName, successOtpCallback }) => {
  const [isSubmit, setIsSubmit] = useState(false);
  const { isFormValid, setFormFieldValue, clearFormFields, submit } = useForm(
    initialFormFields,
    submitCallBack
  );
  const [leaveModal, setLeaveModal] = useState(false);
  const [countDown, setCountDown] = useState(900);
  const [hasCountDown, setHasCountDown] = useState(false);
  const [isMaxRetryError, setIsMaxRetryError] = useState(false);
  const [incorrectOTP, setIncorrectOTP] = useState(false);
  const [isClearField, setIsClearField] = useState(false);
  const intervalRef = useRef(null);
  const { dispatch: appDispatch } = useContext(AppContext);

  const showLeaveModal = () => {
    setLeaveModal(true);
  };
  const closeLeaveModal = () => {
    setLeaveModal(false);
  };
  const handleOnClickLeave = () => {
    setLeaveModal(false);
    onClose();
  };

  const handleOnChangeOTP = useCallback(
    (value) => {
      setFormFieldValue(value, "otp");
      setIncorrectOTP(false);
    },
    [setFormFieldValue]
  );

  const startCounDown = useCallback(() => {
    const otpcd = Number(getLocalItem("otpcd"));
    clearInterval(intervalRef.current);
    if (!otpcd) {
      setHasCountDown(false);
      setCountDown(900);
      return;
    }
    const then = moment(otpcd);
    setHasCountDown(true);
    intervalRef.current = setInterval(() => {
      const now = moment();
      const cd = then.diff(now, "second");
      setCountDown(cd);
      // console.log(cd);
      if (cd <= 0) {
        setHasCountDown(false);
        setIsMaxRetryError(false);
        removeLocalItem("otpcd");
        clearInterval(intervalRef.current);
      }
    }, 1000);
  }, []);

  const setOTPCountDown = useCallback(() => {
    const then = moment().add(901, "seconds").valueOf();
    setLocalItem("otpcd", then);
  }, []);

  const sendNewOTP = useCallback(() => {
    setIsClearField(true);
    clearInterval(intervalRef.current);
    sendEmailOTP({ email, firstName })
      .then(() => {
        setOTPCountDown();
        setHasCountDown(true);
        startCounDown();
        setLocalItem("emailOTP", email);
        removeLocalItem("maxRetryEmail");
        setIsMaxRetryError(false);
        setIsClearField(false);
      })
      .catch((e) => {
        const { metadata, networkError, serverError } = e;

        if (networkError) {
          appDispatch({ type: "NETWORK_ERROR" });
          return;
        }
        if (serverError) {
          appDispatch({ type: "SERVER_ERROR" });
          return;
        }
        if (metadata && metadata.code === "2006") {
          // Show error message
          setIsMaxRetryError(true);
          setLocalItem("maxRetryEmail", email);
          setOTPCountDown();
          startCounDown();
        }
      });
  }, [email, firstName, startCounDown, setOTPCountDown, appDispatch]);

  const handleOnClickResendNewOTP = useCallback(() => {
    sendNewOTP();
    setIncorrectOTP(false);
  }, [sendNewOTP]);

  async function submitCallBack({ otp: passCode }) {
    setIsSubmit(true);

    try {
      const verifyEmailOTPResult = await verifyEmailOTP({ email, passCode });
      const otpRes = verifyEmailOTPResult.data;
      if (otpRes === "PROFILE_CREATED" || otpRes === "approved" || otpRes === "VALIDATED_ONLY") {
        successOtpCallback(otpRes);
      } else {
        setIncorrectOTP(true);
      }
    } catch (e) {
      const { metadata, networkError, serverError } = e;
      console.log(e);
      if (networkError) {
        appDispatch({ type: "NETWORK_ERROR" });
        return;
      }
      if (serverError) {
        appDispatch({ type: "SERVER_ERROR" });
        return;
      }
      if (metadata && metadata.code === "2006") {
        // Show error message
        setIsMaxRetryError(true);
        setLocalItem("maxRetryEmail", email);
        setOTPCountDown();
        setIsClearField(true);
        startCounDown();
        return;
      }
      if (metadata && metadata.code === "2007") {
        setIncorrectOTP(true);
      }
    } finally {
      setIsSubmit(false);
    }
  }

  useEffect(() => {
    if (visible) {
      clearFormFields();
      const emailOTP = getLocalItem("emailOTP");
      const maxRetryEmail = getLocalItem("maxRetryEmail");
      if (maxRetryEmail === email) {
        setIsMaxRetryError(true);
      }

      if (!emailOTP || (emailOTP && emailOTP !== email)) {
        sendNewOTP();
      } else {
        startCounDown();
      }
    }
    return () => {
      setIsMaxRetryError(false);
      clearInterval(intervalRef.current);
    };
  }, [visible, sendNewOTP, startCounDown, email, clearFormFields]);

  return (
    <Fragment>
      <LeaveModal
        visible={leaveModal}
        onClose={closeLeaveModal}
        onLeave={handleOnClickLeave}
        message={localStrings.leaveOTPMessage}
        acceptText={localStrings.leavePage}
      />
      <Modal
        onCancel={showLeaveModal}
        visible={visible}
        className={styles.modal}
        destroyOnClose={true}
      >
        <h2 className={styles.title}>{localStrings.verifyEmailAddress}</h2>
        <p className={styles.desc}>
          {localStrings.pleaseCheckForTheOneTimePasswordWeSentTo}{" "}
          <span className={styles.email}>
            {email}{" "}
            <Button
              disabled={isSubmit}
              onClick={onClose}
              tertiary
              className={styles.changeEmail}
              text={`(${localStrings.change})`}
            />
          </span>
        </p>
        <p className={styles.desc}>{localStrings.enterOtpVerifyYourEmailAddress}</p>
        <Message className={styles.errorMessage} error visible={isMaxRetryError}>
          {localStrings.youReachMaximumOTPAttemps} {localStrings.pleaseTryAgain} {localStrings.in}{" "}
          {`${900} ${localStrings.seconds}`} {localStrings.or}{" "}
          <Button
            className={styles.changeEmail}
            onClick={onClose}
            tertiary
            text={localStrings.changeYourEmail}
          />
        </Message>
        <OTPForm
          onChangeOTP={handleOnChangeOTP}
          isFormValid={isFormValid}
          submit={submit}
          isSubmit={isSubmit}
          incorrectOTP={incorrectOTP}
          isMaxRetryError={isMaxRetryError}
          isClearField={isClearField}
        />
        <p className={styles.resendDesc}>
          {localStrings.didntGetACode}{" "}
          <Button
            disabled={isSubmit || hasCountDown}
            className={styles.resend}
            tertiary
            text={`${localStrings.resendANewOtp} ${hasCountDown ? `(${countDown}s)` : ""}`}
            onClick={handleOnClickResendNewOTP}
          />
        </p>
      </Modal>
    </Fragment>
  );
};

export default memo(OTPFormModal);
