const formFields = {
  email: {
    value: "",
    name: "email",
    error: {
      status: false,
      message: "",
    },
    required: true,
    email: {
      validate: true,
      message: "Whoops, invalid email format.",
    },
  },
  password: {
    value: "",
    name: "password",
    error: {
      status: false,
      message: "",
    },
    required: true,
  },
};

export default formFields;
