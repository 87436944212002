import React, { memo } from "react";
import classnames from "classnames";
import { Icon, Link } from "atomic/atoms";
import styles from "./icon-link.module.scss";
import PropTypes from "prop-types";

const IconLink = ({ icon, right, notif, children, text, ...props }) => {
  const iconElement = <Icon className={classnames(styles.icon, "icon")} name={icon} />;

  return (
    <Link className={styles.link} {...props}>
      <>
        {icon && !right && iconElement}
        {children || text}
        {notif && <div className={styles.notifMarker}></div>}
        {icon && right && iconElement}
      </>
    </Link>
  );
};

IconLink.propTypes = {
  activeClassName: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
  href: PropTypes.string,
  // children: PropTypes.oneOfType([PropTypes.elementType, PropTypes.string]),
  text: PropTypes.string,
  target: PropTypes.string,
  onClick: PropTypes.func,
  exact: PropTypes.bool,
  notif: PropTypes.bool,
  right: PropTypes.bool,
};

IconLink.defaultProps = {
  exact: false,
  notif: false,
  right: false,
};

export default memo(IconLink);
