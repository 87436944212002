import React, { useContext, useState, useCallback, useMemo } from "react";
import { useMount, useApi } from "hooks";
import { ProfileDashboard } from "components/profile";
import { getUserDevice, pairUserDevice, unPairUserDevice } from "api/user-device";
import { getOrgUser } from "api/user";
import { getPrivacyStatusOrganization } from "api/organization";
import styles from "./member-profile.module.scss";
import localStrings from "localization";
import { OrganizationContext, UserContext } from "contexts";
import { useRouter } from "hooks";
import { AccountStatus, Mixpanel } from "enums";
import { MemberOptionMenu } from "components/member";
import { InviteMemberContainer } from "containers";
import path from "path/path";
import mixpanel from "mixpanel-browser";
import { Icon, MenuOption } from "atomic/atoms";
import { BackLink } from "atomic/molecules";

const MemberProfileContainer = () => {
  const { organizationId } = useContext(OrganizationContext);
  const { user: ownUser } = useContext(UserContext);
  const { userId: ownUserId } = ownUser || {};
  const { history, query, location } = useRouter();
  const { state: locationState } = location;
  const [inviteMemberModalVisible, setInviteMemberModalVisible] = useState(false);
  const { userId } = query || {};
  const verifiedMember = (
    <div className={styles.status}>
      {localStrings.verifiedMember} <Icon name="verified" />
    </div>
  );
  const ownAccount = userId === ownUserId?.toString();
  const accountStatus = {
    [AccountStatus.MANAGER]: verifiedMember,
    [AccountStatus.VERIFIED_MEMBER]: verifiedMember,
    [AccountStatus.PROFILE_ONLY]: <div className={styles.gray}>{localStrings.profileOnly}</div>,
    [AccountStatus.UNVERIFIED_MEMBER]: localStrings.unverifiedMember,
  };
  const {
    request: getUserInfo,
    loading: userLoading,
    result: userInfo,
    error: userError,
  } = useApi({
    api: () =>
      getOrgUser({
        organizationId,
        userId,
      }),
    handleOwnError: {
      server: true,
      badrequest: true,
    },
  });

  const {
    request: requestUserPrivacyStatus,
    result: userPrivacyStatus = undefined,
    loading: userPrivacyLoading = !ownAccount,
  } = useApi({
    api: () => getPrivacyStatusOrganization({ userId, organizationId }),
  });
  const user = userInfo || {};
  useMount(() => {
    mixpanel.track(Mixpanel.VIEW_MEMBER_PROFILE);
    const init = async () => {
      await getUserInfo();
      if (!ownAccount) {
        await requestUserPrivacyStatus();
      }
    };
    init();
  });

  const userInfoData = useMemo(() => {
    return {
      ...user,
      userId,
    };
  }, [user, userId]);

  const toggleInviteMemberModal = useCallback((show) => {
    setInviteMemberModalVisible(show);
  }, []);

  return (
    <div className={styles.container}>
      <BackLink
        className={styles.backlink}
        onClick={() => {
          if (locationState?.fromList) {
            history.goBack();
          } else {
            history.push(path.MEMBERS);
          }
        }}
        text={localStrings.members}
      />
      <ProfileDashboard
        isMemberProfile={true}
        ownAccount={ownAccount}
        userPrivacyLoading={userPrivacyLoading}
        organization={{
          organizationId,
        }}
        loading={userLoading}
        error={userError}
        userInfo={userInfoData}
        userPrivacyStatus={userPrivacyStatus}
        links={{
          temperature: path.MEMBER_PROFILE(userId),
          symptoms: path.MEMBER_PROFILE_SYMPTOMS(userId),
          contactHistory: path.MEMBER_PROFILE_CONTACT_HISTORY(userId),
        }}
        profileInfo={{
          menu: ownAccount ? (
            <MenuOption
              loading={userLoading}
              options={[
                {
                  label: localStrings.viewProfile,
                  text: localStrings.viewProfile,
                  onClick: () => history.push(path.SETTINGS_PROFILE),
                },
              ]}
            />
          ) : (
            <MemberOptionMenu
              fromView
              data={user}
              organizationId={organizationId}
              dropdown
              toggleInviteMemberModal={toggleInviteMemberModal}
              callback={{
                revoke: () => getUserInfo(),
                delete: () => history.push(path.MEMBERS),
                assign: () => getUserInfo(),
                invite: () => getUserInfo(),
                remove: () => history.push(path.MEMBERS),
                removeManager: () => getUserInfo(),
              }}
            />
          ),
          actionButton: userLoading ? null : (
            <div className={styles.optionLabelContainer}>
              <div className={styles.optionLabel}>
                {user?.accountStatus === AccountStatus.MANAGER ? (
                  <>
                    <div className={styles.role}>{localStrings.manager}</div>
                    <div className={styles.mid}>·</div>
                  </>
                ) : null}
                {accountStatus[user?.accountStatus] || localStrings.profileOnly}
              </div>
            </div>
          ),
        }}
        mapLocation={{
          strings: {
            unableToLocate: localStrings.unableToLocateMember,
          },
        }}
        diary={{
          strings: {
            wearTheBand: localStrings.askTheUserToWearPouchBand,
          },
        }}
        symptomsOverview={{
          strings: {
            symptomsDescription: localStrings.symptomsDescription,
          },
          actions: {
            editSymptoms: (symptomId) => {
              history.push(path.MEMBER_EDIT_SYMPTOMS(userId, symptomId));
            },
            createSymptoms: () => {
              history.push(path.MEMBER_CREATE_SYMPTOMS(userId));
            },
          },
        }}
        device={{
          api: () => getUserDevice({ userId, organizationId }),
          unpairApi: unPairUserDevice,
          pairApi: pairUserDevice,
          strings: {
            addBand: localStrings.addAndManagerPouchBands,
          },
        }}
      />
      <InviteMemberContainer
        userId={userId}
        refetch={getUserInfo}
        visible={inviteMemberModalVisible}
        toggleInviteMemberModal={toggleInviteMemberModal}
      />
    </div>
  );
};

export default MemberProfileContainer;
