import React, { useContext } from "react";
import { Icon } from "atomic/atoms";
import { Avatar } from "atomic/molecules";
import styles from "./user-avatar.module.scss";
import { NotificationContext, UserContext } from "contexts";

const UserAvatar = ({ toggleMenu, loading }) => {
  const notifications = useContext(NotificationContext);
  const { user } = useContext(UserContext);
  const { firstName, lastName, avatar } = user;

  return (
    <div className={styles.container} onClick={toggleMenu}>
      <Avatar className={styles.image} phrases={[firstName, lastName]} image={avatar} />
      <Icon className={styles.icon} name="down-chevron-thick" loading={loading} />
      {notifications.unreadInviteRequestCount ||
      notifications.notificationCount ||
      notifications.hasOrganizationPrivacyUpdate ? (
        <div className={styles.notifMarker}></div>
      ) : null}
    </div>
  );
};

export default UserAvatar;
