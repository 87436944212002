import localStrings from "localization";
import localTranslation from "localization/localization";

const initialFormState = () => {
  return {
    oldPassword: {
      value: "",
      required: {
        message: localStrings.pleaseInputCurrentPassword,
      },
      error: {
        status: false,
        message: "",
      },
      min: {
        length: 6,
      },
      custom: {
        invalid: false,
      },
      customValidations: (value, custom) => {
        // if (!value) {
        //   return {
        //     status: true,
        //     message: localStrings.pleaseInputCurrentPassword,
        //   };
        // }
        if (custom.invalid) {
          return {
            status: true,
            message: localStrings.invalidCurrentPassword,
          };
        }

        return null;
      },
    },
    password: {
      value: "",
      required: true,
      error: {
        status: false,
        message: "",
      },
      maxLength: 20,
      customValidations: (value) => {
        if (value && value.length < 8) {
          return {
            status: true,
            message: localTranslation("pleaseUseAtleastNthCharacters", ["8"]),
          };
        }
      },
    },
    retypePassword: {
      value: "",
      required: true,
      error: {
        status: false,
        message: "",
      },
      custom: "",
      customValidations: (value, custom, name, field) => {
        if (value !== custom && value && field.dirty) {
          return {
            status: true,
            message: localStrings.passwordsDoNotMatch,
          };
        }
      },
    },
  };
};

export default initialFormState;
