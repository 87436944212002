import React from "react";
import styles from "./my-organization-list.module.scss";
import localStrings from "localization";
import ActiveOrganizationList from "./active-organization-list/active-organization-list";
import PastOrganizationList from "./past-organization-list/past-organization-list";
import { Panel } from "atomic/molecules";

const MyOrganizationList = ({ activeOrganizations, pastOrganizations, leaveModal, loading }) => {
  return (
    <Panel className={styles.panel}>
      <div className={styles.panelTitle}>
        <h3 className={styles.title}>{localStrings.myOrganizations}</h3>
        <p className={styles.desc}>{localStrings.myOrganizationDescription}</p>
      </div>
      <ActiveOrganizationList
        loading={loading}
        organizations={activeOrganizations}
        leaveModal={leaveModal}
      />
      <PastOrganizationList organizations={pastOrganizations} />
    </Panel>
  );
};

export default MyOrganizationList;
