import { Permission } from "enums";

const initialFormState = {
  isNameAndPhotoAllowed: {
    value: Permission.Allowed,
  },
  isEmailAndMobileNumberAllowed: {
    value: Permission.Allowed,
  },
  isAreaLocationAllowed: {
    value: Permission.Allowed,
  },
  isBodyTemperatureAllowed: {
    value: Permission.Allowed,
  },
  isLocationAllowed: {
    value: Permission.Allowed,
  },
  isSymptomAllowed: {
    value: Permission.Allowed,
  },
  isAboutYouAllowed: {
    value: Permission.Allowed,
  },
  isEmploymentDetailAllowed: {
    value: Permission.Allowed,
  },
  isEmergencyContactAllowed: {
    value: Permission.Allowed,
  },
  isWristbandAllowed: {
    value: Permission.Allowed,
  },
};

export default initialFormState;
