import React, { useCallback, useContext, useState } from "react";
import { SettingsLayout } from "layouts";
import { useApi, useMount } from "hooks";
import { InviteRequest } from "components/settings";
import { UserContext } from "contexts";
import {
  acceptInviteRequest,
  clearInviteRequestNotif,
  declineInviteRequest,
  getInviteRequest,
} from "api/user-organization";
import { setLocalItem } from "utils";
import { Routes } from "enums";

const InviteRequestContainer = () => {
  const [inviteRequests, setInviteRequests] = useState([]);
  const [viewInviteModal, setViewInviteModal] = useState({
    visible: false,
    organizationName: "",
    role: "",
    orgLogo: "",
    organizationId: null,
  });
  const [declineModal, setDeclineModal] = useState({
    visible: false,
    organizationName: "",
  });

  const { user } = useContext(UserContext);
  const { userId } = user;

  const { request, loading } = useApi({ api: getInviteRequest });
  const { request: acceptInviteReq } = useApi({ api: acceptInviteRequest });
  const { request: declineInviteReq } = useApi({ api: declineInviteRequest });

  useMount(() => {
    const init = async () => {
      fetchInviteRequests();
      clearInviteRequestNotif();
    };

    init();
  });

  const fetchInviteRequests = useCallback(async () => {
    const { data } = await request(userId);
    setInviteRequests(data);
  }, [request, userId]);

  const showViewInviteModal = useCallback(
    (organizationId) => {
      const { organizationName, organizationLogo: orgLogo, role } = inviteRequests.filter((d) => {
        return d.organizationId === organizationId;
      })[0];
      setViewInviteModal((prev) => ({
        ...prev,
        visible: true,
        organizationName,
        orgLogo,
        organizationId,
        role,
      }));
    },
    [inviteRequests]
  );

  const closeViewInviteModal = useCallback(() => {
    setViewInviteModal((prev) => ({
      ...prev,
      visible: false,
    }));
  }, []);

  const showDeclineModal = useCallback(({ organizationId, organizationName }) => {
    setDeclineModal((prev) => ({
      ...prev,
      visible: true,
      organizationId,
      organizationName,
    }));
  }, []);

  const closeDeclineModal = useCallback(() => {
    setDeclineModal((prev) => ({
      ...prev,
      visible: false,
    }));
  }, []);

  const acceptInvite = useCallback(
    async (organizationId) => {
      await acceptInviteReq({ organizationId, userId });

      setLocalItem("organizationId", organizationId);
      window.location.replace(`/${Routes.ONBOARDING}/${Routes.PRIVACY_SETTINGS}`, {
        organizationId,
      });
    },
    [acceptInviteReq, userId]
  );

  const declineInvite = useCallback(
    async (organizationId) => {
      await declineInviteReq({ organizationId, userId });
      fetchInviteRequests();
      setDeclineModal((prev) => ({
        ...prev,
        visible: false,
      }));
      setViewInviteModal((prev) => ({
        ...prev,
        visible: false,
      }));
    },
    [declineInviteReq, userId, fetchInviteRequests]
  );

  return (
    <SettingsLayout>
      <InviteRequest
        inviteRequests={inviteRequests}
        loading={loading}
        viewInviteModal={viewInviteModal}
        declineModal={declineModal}
        acceptInvite={acceptInvite}
        declineInvite={declineInvite}
        showViewInviteModal={showViewInviteModal}
        closeViewInviteModal={closeViewInviteModal}
        showDeclineModal={showDeclineModal}
        closeDeclineModal={closeDeclineModal}
      ></InviteRequest>
    </SettingsLayout>
  );
};

export default InviteRequestContainer;
